import { divIcon, latLngBounds, LatLngExpression, map, Marker, marker, svg } from "leaflet";
import { useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import { StoresApi, StoreView } from "../../api";
import { isHttpOk } from "../../services/api";
import { getTooltip } from "../../services/tooltip";
import { getApiConfig } from "../../state/configuration";
import { AzureMapsTiles } from "../world-map/AzureMapsTiles";
import { AuthOptions } from "../world-map/Constants";

// eslint-disable-next-line import/no-internal-modules
import "leaflet/dist/leaflet.css";
import "./StoreLocationMap.scss";

export function StoreLocationMap(props: { classNames?: string[] }) {
  const [stores, setStores] = useState<StoreView[]>([]);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!divRef.current) return;

    const mapObj = map(divRef.current, {
      renderer: svg(),
      minZoom: 1,
    });

    (async () => {
      const layer = new AzureMapsTiles({
        authOptions: AuthOptions,
        tilesetId: "microsoft.base.road",
        language: "en-US",
        hideAttribution: true,
      });
      mapObj.addLayer(layer);
    })();

    const coords: LatLngExpression[] = [];
    const markers: { item: StoreView; marker: Marker }[] = [];
    for (const item of stores) {
      const latLong: LatLngExpression = [item.coordinates?.latitude!, item.coordinates?.longitude!];
      coords.push(latLong);
      const markerObj = marker(latLong, {
        icon: divIcon({
          html: `<svg viewBox="0 0 14 20"><path fill="#fff" d="M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7zm0 9.5A2.5 2.5 0 1 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5z" transform="translate(-5 -2)"/></svg>`,
          className: "marker " + item.status?.toLowerCase(),
        }),
      });
      markerObj.addTo(mapObj);
      markers.push({ item, marker: markerObj });
    }

    if (coords.length > 0) {
      mapObj.fitBounds(latLngBounds(coords), {
        animate: false,
        padding: [0.1, 0.1],
      });
    }

    const rebuildTooltips = () => {
      for (const { item, marker } of markers) {
        const markerEl = marker.getElement();
        if (markerEl) {
          markerEl.setAttribute("data-for", "appTooltip");
          markerEl.setAttribute("data-tip", getTooltip(item.street || "", item.name || ""));
        }
      }
      ReactTooltip.rebuild();
    };

    mapObj.addEventListener("load", rebuildTooltips);
    mapObj.addEventListener("zoomend", rebuildTooltips);
    mapObj.addEventListener("moveend", rebuildTooltips);
    rebuildTooltips();

    return () => {
      mapObj.remove();
    };
  }, [stores]);

  useEffect(() => {
    const config = getApiConfig();
    const storesApi = new StoresApi(config);
    storesApi
      .get({
        onlyActive: false,
        skip: 0,
        limit: 100,
      })
      .then((r) => {
        if (isHttpOk(r) && r.data.items) {
          setStores(r.data.items);
        }
      });
  }, []);

  const classNames = props.classNames?.slice() || [];
  classNames.push("full-world-map");

  return <div ref={divRef} className={classNames.join(" ")}></div>;
}
