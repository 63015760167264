import { yupResolver } from "@hookform/resolvers/yup";
import { cloneDeep } from "lodash";
import { useEffect, useMemo } from "react";
import { DeepMap, ErrorOption, FieldError, FieldPath, useForm } from "react-hook-form";
import { toast as alert } from "react-toastify";
import { ProductCategoriesApi, ProductModel } from "../../api";
import { useEventHandler, useStateEx } from "../../services/hooks";
import { checkErrors } from "../../services/localization";
import { ProductValidator } from "../../services/validators";
import { getApiConfig } from "../../state/configuration";
import { useDeploymentConfig } from "../../state/deployment";
import { AlertMissingValidationErrors } from "../forms/AlertMissingValidationErrors";
import { ImageUploadButton } from "../forms/ImageUpload";
import { FormInput as Input } from "../forms/Input";
import LanguagePicker from "../forms/LanguagePicker";
import { FormSelect, Option } from "../forms/Select";
import { ServerUnavailable, ValidationMessage } from "../widgets/Alerts";
import { FormModalProps, showErrors } from "./utils";

import "./EditProduct.scss";

interface Props extends FormModalProps<ProductModel> {
  existingProduct: boolean;
  categoryId: number | undefined;
}

type State = {
  selectedLanguage: string;
  categoryOptions: Option[];
  backendErrors: DeepMap<Partial<ProductModel>, FieldError>;
};

const emptyLanguages: string[] = [];

function EditProduct(props: Props) {
  const config = useDeploymentConfig().config;
  const onChangeHandler = useEventHandler(props.onChange);
  const allowedLanguages = config.allowedLanguages || emptyLanguages;
  const defaultModel = useMemo(() => {
    return {
      imageUrl: "",
      url: "",
      currency: config.defaultCurrency?.code || "EUR",
      fromPrice: null,
      toPrice: null,
      nameLocalizations: allowedLanguages.map((code) => {
        return { languageCode: code, text: "" };
      }),
      descriptionLocalizations: allowedLanguages.map((code) => {
        return { languageCode: code, text: "" };
      }),
      categoryId: props.categoryId,
    };
  }, [allowedLanguages, config.defaultCurrency?.code, props.categoryId]);

  const { state, mergeState } = useStateEx<State>({
    selectedLanguage: allowedLanguages[0],
    categoryOptions: [],
    backendErrors: {},
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    watch,
  } = useForm<ProductModel>({
    defaultValues: props.initial || defaultModel,
    resolver: yupResolver(ProductValidator(allowedLanguages)),
  });

  useEffect(() => {
    (async () => {
      const config = getApiConfig();
      try {
        const categories = new ProductCategoriesApi(config).getForLookup();
        mergeState({
          categoryOptions:
            (await categories).data.map((c) => ({
              label: c.name!,
              value: c.id,
            })) || [],
        });
      } catch (error) {
        alert.error(<ServerUnavailable message="Unable to fetch Category list! Please check your connection." />);
      }
    })();
  }, [mergeState]);

  useEffect(() => {
    const subscription = watch(() => {
      if (onChangeHandler.current) onChangeHandler.current();
    });
    return () => subscription.unsubscribe();
  }, [watch, onChangeHandler]);

  const nameTranslationIndex = defaultModel.nameLocalizations.findIndex(
    (t) => t.languageCode === state.selectedLanguage
  );
  const descriptionTranslationIndex = defaultModel.descriptionLocalizations.findIndex(
    (t) => t.languageCode === state.selectedLanguage
  );
  const nameField = `nameLocalizations.${nameTranslationIndex}.text` as "nameLocalizations.0.text";
  const descriptionField =
    `descriptionLocalizations.${descriptionTranslationIndex}.text` as "descriptionLocalizations.0.text";

  const trySaveModel = async (model: ProductModel) => {
    if (props.onSave) {
      model = cloneDeep(model);
      const result = await props?.onSave(model);
      if (result && !result.success) {
        showErrors<ProductModel>(result, (path: FieldPath<ProductModel>, error: ErrorOption) => {
          alert.error(<ValidationMessage message={error.message || "Unknown error"} />);
        });
      }
    }
  };

  const getLanguageErrors = (): string[] => {
    if (!errors || (!errors.nameLocalizations && !errors.descriptionLocalizations)) return [];

    const errorList = checkErrors(allowedLanguages, errors.nameLocalizations).concat(
      checkErrors(allowedLanguages, errors.descriptionLocalizations)
    );
    // remove duplicates
    return errorList.filter((item, index) => errorList.indexOf(item) === index);
  };

  const imageUrl = watch("imageUrl");

  return (
    <div className="edit-product">
      <form className="product-form" onSubmit={handleSubmit(trySaveModel)}>
        <AlertMissingValidationErrors control={control} />
        <div className="main">
          <div className="header">
            <h1 className="title">{props.existingProduct ? "Edit Product" : "New Product"}</h1>
          </div>
          <div className="form-row">
            <LanguagePicker
              language={state.selectedLanguage}
              errors={getLanguageErrors()}
              onChange={(value) => {
                mergeState({ selectedLanguage: value });
              }}
            />
          </div>
          <div className="form-row">
            <Input
              key={nameField}
              name={nameField}
              type="text"
              label=""
              placeholder={`Name`}
              errors={errors}
              register={control.register}
            />
          </div>
          <div className="form-row">
            <Input
              key={descriptionField}
              name={descriptionField}
              type="text"
              label=""
              placeholder={`Description (optional)`}
              errors={errors}
              register={control.register}
            />
          </div>
          <div className="image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
          <ImageUploadButton
            control={control}
            name="imageUrl"
            title="Upload Product Image"
            instruction="Image format: PNG or JPG max. 2MB"
          />
          <div className="product-url">
            <div className="form-row">
              <Input name="url" type="text" placeholder={"Url"} errors={errors} register={control.register} />
            </div>
          </div>
          <div className="price-area">
            <div className="form-row">
              <Input
                name="currency"
                type="text"
                placeholder={"Currency"}
                readOnly={true}
                errors={errors}
                register={control.register}
              />
            </div>
            <div className="form-row">
              <Input
                name="fromPrice"
                type="float"
                placeholder={"From Price"}
                errors={errors}
                register={control.register}
                required={false}
              />
            </div>
            <div className="form-row">
              <Input
                name="toPrice"
                type="float"
                placeholder={"To Price"}
                errors={errors}
                register={control.register}
                required={false}
              />
            </div>
          </div>
          <FormSelect
            name="categoryId"
            type="form"
            options={state.categoryOptions}
            placeholder="Select Category"
            required={true}
            control={control}
          />
        </div>
        <div className="footer">
          <button type="submit" disabled={isSubmitting} className="primary save">
            Save
          </button>
          <button type="button" onClick={props.onCancel} className="primary cancel">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditProduct;
