import { FieldErrors } from "react-hook-form";
import { FrequencyCheck, RateOfOccurence } from "../../../../../api";
import { safeParse } from "../../../../../services/number";
import { Select } from "../../../../forms/Select";

import "./RateOfOccurenceInput.scss";

export function RateOfOccurenceInput(props: {
  prefix: string;
  value?: RateOfOccurence;
  onChange: (value: RateOfOccurence) => void;
  error?: FieldErrors<any>;
}) {
  const frequencyOptions = [
    { label: "At Least Once", value: FrequencyCheck.AtLeastOnce },
    { label: "Exactly", value: FrequencyCheck.Exactly },
    { label: "At Least", value: FrequencyCheck.AtLeast },
    { label: "At Most", value: FrequencyCheck.AtMost },
  ];

  const selectedFrequency =
    typeof props.value?.frequencyCheck === "number" ? props.value.frequencyCheck : FrequencyCheck.AtLeastOnce;
  const selectedAmount = props.value?.amount || 1;
  const isAtLeastOnce = selectedFrequency === FrequencyCheck.AtLeastOnce;
  const errorClasses = isAtLeastOnce ? "error" : "error single-input";

  var amountClassName = "form-input amount";
  if (isAtLeastOnce) amountClassName += " reduced";

  const firstClassName = "first";

  return (
    <div className="input-row double rate-of-occurence">
      <span className="prefix">{props.prefix}</span>
      <div className={firstClassName}>
        <Select
          type="form"
          className="frequency"
          required={true}
          options={frequencyOptions}
          value={selectedFrequency}
          onChange={(value) => {
            props.onChange({
              amount: selectedAmount,
              frequencyCheck: value as FrequencyCheck,
            });
          }}
        />
      </div>
      <div className="second">
        {!isAtLeastOnce && (
          <div className="input-row">
            <label className={amountClassName}>
              <input
                placeholder={"#"}
                type="number"
                value={selectedAmount}
                onChange={(e) => {
                  const amount = safeParse(e.target.value);
                  props.onChange({
                    amount: amount || 1,
                    frequencyCheck: selectedFrequency,
                  });
                }}
              />
              {props.error ? <div className={errorClasses}>{props.error.message}</div> : null}
            </label>
            <span className="postfix">{selectedAmount === 1 ? "time" : "times"}</span>
          </div>
        )}
      </div>
    </div>
  );
}
