import clsx, { ClassValue } from "clsx";
import { get, isFunction } from "lodash";
import {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  HTMLInputTypeAttribute,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { FieldError, FieldErrors, FieldValues, Path, UseFormRegister } from "react-hook-form";
import { useEventHandler } from "../../services/hooks";
import { isProvided } from "../../services/objects";
import { ValidationError } from "../widgets/ValidationError";

import "./Input.scss";

type InputType = "multiline" | "text" | "password" | "float" | "integer" | "email";

function getHtmlInputType(type?: "email" | "text" | "password" | "float" | "integer"): HTMLInputTypeAttribute {
  if (type === "integer" || type === "float") return "number";
  return type || "text";
}

function parseValueAs(type: InputType, value: any) {
  if (type === "float") {
    if (typeof value === "string") {
      return value === "" ? null : parseFloat(value);
    } else if (typeof value === "number") {
      return value;
    } else {
      return null;
    }
  } else if (type === "integer") {
    if (typeof value === "string") {
      return value === "" ? null : parseInt(value, 10);
    } else if (typeof value === "number") {
      return Math.round(value);
    } else {
      return null;
    }
  } else {
    return value;
  }
}

interface InputProps {
  name?: string;
  value?: string | number;
  type?: InputType;
  icon?: (props: { className?: string }) => JSX.Element;
  className?: ClassValue;
  readOnly?: boolean;
  label?: string;
  error?: FieldError;
  required?: boolean;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  interceptValue?: (value: string) => string;
}

export const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>((props, ref) => {
  const {
    name,
    value,
    icon: IconComponent,
    className,
    label,
    required = false,
    type = "text",
    readOnly = false,
    placeholder,
    error,
    interceptValue,
    ...otherProps
  } = props;

  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>();

  // freeze event handlers
  const onChangeHandler = useEventHandler(otherProps.onChange);
  const onBlurHandler = useEventHandler(otherProps.onBlur);
  const onFocusHandler = useEventHandler(otherProps.onFocus);

  if (isProvided(value) && isProvided(interceptValue)) {
    throw new Error(
      "Cannot use value and interceptValue at the same time! If using value, move interception outside the component."
    );
  }

  const hasError = isProvided(error);
  const errorNode = useMemo(() => {
    if (hasError) {
      return <ValidationError path={name} message={error.message || "Field is invalid!"} />;
    }
    return null;
  }, [hasError, name, error?.message]);

  const refCallback = useCallback(
    (value: HTMLInputElement | HTMLTextAreaElement | null) => {
      inputRef.current = value || undefined;
      if (ref) {
        if (isFunction(ref)) {
          ref(value);
        } else {
          ref.current = value;
        }
      }
    },
    [ref, inputRef]
  );

  const interceptOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = interceptValue ? interceptValue(event.currentTarget.value) : event.currentTarget.value;
      if (inputRef.current && inputRef.current.value !== value) {
        const { selectionStart, selectionEnd } = inputRef.current;
        const isEnd = selectionStart === inputRef.current.value.length;
        inputRef.current.value = value;
        inputRef.current.selectionStart = isEnd ? value.length : selectionStart;
        inputRef.current.selectionEnd = isEnd ? value.length : selectionEnd;
      }
      if (onChangeHandler.current) {
        onChangeHandler.current(event);
      }
    },
    [interceptValue, onChangeHandler]
  );

  const inputNode = useMemo(() => {
    if (type === "multiline") {
      return (
        <textarea
          name={name}
          value={value}
          ref={refCallback}
          onChange={interceptOnChange}
          onBlur={onBlurHandler.current}
          onFocus={onFocusHandler.current}
          readOnly={readOnly}
          className={hasError ? "invalid" : undefined}
          placeholder={placeholder}
        />
      );
    } else {
      return (
        <input
          name={name}
          value={value}
          ref={refCallback}
          onChange={interceptOnChange}
          onBlur={onBlurHandler.current}
          onFocus={onFocusHandler.current}
          readOnly={readOnly}
          className={hasError ? "invalid" : undefined}
          placeholder={placeholder}
          type={getHtmlInputType(type)}
          step="any"
        />
      );
    }
  }, [
    name,
    value,
    type,
    placeholder,
    readOnly,
    hasError,
    refCallback,
    interceptOnChange,
    onBlurHandler,
    onFocusHandler,
  ]);

  return (
    <label className={clsx("form-input", className)}>
      {label && <span className={`label-text ${required ? "required" : "not-required"}`}>{label}</span>}
      {inputNode}
      {IconComponent ? <IconComponent className="input-icon" /> : null}
      {errorNode}
    </label>
  );
});

interface FormInputProps<TModel extends FieldValues>
  extends Omit<InputProps, "name" | "value" | "onChange" | "onBlur" | "onFocus" | "error"> {
  name: Path<TModel>;
  register: UseFormRegister<TModel>;
  errors?: FieldErrors<TModel>;
  onBlur?: () => void;
  onFocus?: () => void;
}

export function FormInput<TModel extends FieldValues>({
  name,
  register,
  errors,
  required,
  type,
  onBlur,
  ...otherProps
}: FormInputProps<TModel>) {
  // TODO - it seems first argument to validator is not behaving as path but as type
  // check file validators.ts, line 233
  const tryGetError = () => {
    let error = get(errors, name);
    if (!error) {
      error = get(errors, "undefined");
      if (error && error.type === name) {
        return error;
      }
    } else {
      return error;
    }
  };

  const registerProps = useMemo(() => {
    if (type === "multiline") {
      return register(name, {
        onBlur: onBlur,
        required: required,
      });
    } else {
      return register(name, {
        onBlur: onBlur,
        // TODO - causes issues with EditProduct, price not being saved if not edited
        setValueAs: (value) => {
          return parseValueAs(type || "text", value);
        },
        required: required,
      });
    }
  }, [name, type, onBlur, required, register]);

  return <Input required={required} type={type} error={tryGetError()} {...otherProps} {...registerProps} />;
}
