import { useCallback } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { MessagesApi, NotificationMark, NotificationUserView } from "../../../api";
import { logErrorSilently } from "../../../services/alerts";
import { isHttpOk } from "../../../services/api";
import { utcToLocal } from "../../../services/date";
import { formatDateTime } from "../../../services/format";
import { getApiConfig } from "../../../state/configuration";
import { useTimeLocationFilters } from "../../../state/globalFilters";
import Card from "../../cards/Card";
import { Avatar } from "../../widgets/Avatar";
import { PaginatedTable } from "../../widgets/PaginatedTable";

import "./NotificationUsersTable.scss";

type Props = {
  messageId: number;
  flag: NotificationMark;
};

export function NotificationUsersTable(props: Props) {
  const filters = useTimeLocationFilters();
  const dataSource = useCallback(
    async (offset: number, limit: number) => {
      const api = new MessagesApi(getApiConfig());
      const result = await api.getPerformanceNotificationUsers({
        messageId: props.messageId,
        flag: props.flag,
        skip: offset,
        limit: limit,
        ...filters,
      });

      if (isHttpOk(result)) {
        window.setTimeout(() => ReactTooltip.rebuild(), 500);
        return result.data;
      } else {
        logErrorSilently(result);
        return {
          count: 0,
          items: [],
        };
      }
    },
    [filters, props]
  );

  const flagName =
    props.flag === NotificationMark.Delivered ? "RECEIVED" : NotificationMark[props.flag].toString().toUpperCase();
  const dateTitle = `${flagName} DATE`;
  const tableHeaders = [
    { title: "NAME" },
    { title: "PLATFORM" },
    { title: "STORE LOCATION", tooltip: "Store location at the time of sending" },
    { title: dateTitle },
  ];

  const renderRow = (item: NotificationUserView) => {
    var name = item.firstName ? item.firstName + " " + item.lastName : "Anonymous";
    return [
      <div>
        <Link to={item.id ? `/customer/${item.id}/details` : "#"}>
          <Avatar name={name} image={item.pictureUrl} />
          <span className="name-and-email">
            {name}
            <br />
            {item.email}
          </span>
        </Link>
      </div>,
      item.platform || "",
      item.storeLocationAtSend || "",
      formatDateTime(utcToLocal(item.date), "dd.MM.yyyy hh:mm a"),
    ];
  };

  const title = "Notification Users";

  return (
    <Card className="notification-users-table">
      <PaginatedTable
        mainHeader={title}
        tableClassName="with-image"
        minLoadingTimeMs={200}
        initialPageSize={10}
        tableDataSource={dataSource}
        tableHeaders={tableHeaders}
        tableRowFactory={renderRow}
        hideLoadingAnimation={true}
      ></PaginatedTable>
    </Card>
  );
}
