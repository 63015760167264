import { StoresApi, StoreView } from "../../api";
import { getApiConfig } from "../../state/configuration";
import { useDeploymentConfig } from "../../state/deployment";
import { PaginatedTable } from "../widgets/PaginatedTable";

import "./LocationOverviewStores.scss";

export default function LocationOverviewStores() {
  const { config } = useDeploymentConfig();
  const apiConfig = getApiConfig();

  const loadPage = (offset: number, limit: number) => {
    const api = new StoresApi(apiConfig);
    return api.get({ skip: offset, limit });
  };

  const renderCells = (store: StoreView, index: number) => {
    const status = store.status || "";
    const classNames = ["store-status", status.toLowerCase()];

    return [
      store.name,
      store.country,
      store.city,
      store.street,
      store.zipCode,
      <span className={classNames.join(" ")}>{status}</span>,
    ];
  };

  return (
    <section className="location-overview-stores">
      <header>
        <h1>
          <a href="/stores">All {config.translations.store.plural}</a>
        </h1>
      </header>
      <div>
        <PaginatedTable
          initialPageSize={5}
          tableHeaders={[
            { title: config.translations.store.singular.toUpperCase() },
            { title: "COUNTRY" },
            { title: "CITY" },
            { title: "STREET" },
            { title: "ZIP CODE" },
            { title: "STATUS" },
          ]}
          tableRowFactory={renderCells}
          tableDataSource={loadPage}
          minLoadingTimeMs={200}
          hidePagination={false}
        />
      </div>
    </section>
  );
}
