import { PropsWithChildren } from "react";
import { BeaconHourTracking } from "../../pages/contact-tracking/ContactTracking";
import { useFilterDescriptionText, useIsSingleStore } from "../../state/globalFilters";
import { TimeBeacons } from "./TimeBeacons";
import { TimeLocation } from "./TimeLocation";

export function AnalyticsPageHeader(props: { title: string; hideLocationFilters?: boolean }) {
  const filtersText = useFilterDescriptionText();
  const storeDetail = !useIsSingleStore() ? ` - ${filtersText.locations}` : "";
  return (
    <PageHeader title={props.title} subTitle={filtersText.period + storeDetail}>
      <TimeLocation hideLocationFilters={props.hideLocationFilters} />
    </PageHeader>
  );
}

export function PageHeader(
  props: PropsWithChildren<{
    title: string;
    subTitle?: string;
  }>
) {
  return (
    <section className="page-header">
      <div className="header-and-info">
        <h1>{props.title}</h1>
        {props.subTitle && <h2>{props.subTitle}</h2>}
      </div>
      {props.children}
    </section>
  );
}

type BeaconTrackingProps = {
  title: string;
  selectedBeaconHour?: BeaconHourTracking;
  mergeBeaconHour?: (beaconHour: Partial<BeaconHourTracking>) => void;
};

export function BeaconTrackingHeader(props: BeaconTrackingProps) {
  const filtersText = useFilterDescriptionText();
  const storeDetail = !useIsSingleStore() ? ` - ${filtersText.locations}` : "";
  return (
    <PageHeader title={props.title} subTitle={filtersText.period + storeDetail}>
      <TimeBeacons selectedBeaconHour={props.selectedBeaconHour} mergeBeaconHour={props.mergeBeaconHour} />
    </PageHeader>
  );
}
