import Heatmap from "../app/cards/analytics-heatmap/Heatmap";
import Card from "../app/cards/Card";
import { TopNotifications } from "../app/tables/notifications/TopNotifications";
import { TopAreas } from "../app/tables/TopAreas";
import TopBeacons from "../app/tables/TopBeacons";
import TopCustomers from "../app/tables/TopCustomers";
import TopProducts from "../app/tables/TopProducts";
import TopStores from "../app/tables/TopStores";
import { AnalyticsPageHeader } from "../app/widgets/PageHeaders";
import { useLocalizedText } from "../services/localization";
import { useDeploymentConfig } from "../state/deployment";
import { useIsSingleStore } from "../state/globalFilters";
import { BottomRow } from "./analytics/BottomRow";
import { TopRow } from "./analytics/TopRow";

import "./Analytics.scss";

function Analytics() {
  const texts = useLocalizedText();
  const deployment = useDeploymentConfig();
  const isSingleStore = useIsSingleStore();

  return (
    <div className="analytics page-content">
      <AnalyticsPageHeader title={texts.analyticsPage.title} />
      <TopRow />
      <BottomRow />
      <section className="notifications">
        <Card className="notifications-card">
          <TopNotifications />
        </Card>
      </section>

      <section className="top-five-lists">
        {!deployment.config.disablePurchaseHistories ? <TopProducts showFooter={true} /> : null}
        <TopCustomers />
        {isSingleStore ? <TopAreas /> : <TopStores showFooter={true} />}
      </section>
      <section className="top-beacons-and-heatmap">
        <div className="top-beacons">
          <TopBeacons showFooter={true} />
        </div>
        <Card className="heatmap-card">
          <Heatmap />
        </Card>
      </section>
    </div>
  );
}

export default Analytics;
