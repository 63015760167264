import { Option, Select } from "../../../forms/Select";
import { closestValue, editorClassName, EditorProps } from "./common";

export function LiveBeaconContactEditor(props: EditorProps) {
  const durationOptions: Option[] = [
    { label: "any time", value: 0 },
    { label: "more than 10 seconds", value: 10 },
    { label: "more than 20 seconds", value: 20 },
    { label: "more than 40 seconds", value: 40 },
    { label: "more than 1 minute", value: 60 },
    { label: "more than 2 minutes", value: 120 },
    { label: "more than 5 minutes", value: 300 },
    { label: "more than 10 minutes", value: 600 },
    { label: "2 hours", value: 3600 * 2 },
    { label: "4 hours", value: 3600 * 4 },
    { label: "6 hours", value: 3600 * 6 },
    { label: "8 hours", value: 3600 * 8 },
  ];
  const distanceOptions: Option[] = [
    { label: "1 minute", value: 60 },
    { label: "15 minute", value: 60 * 15 },
    { label: "30 minutes", value: 60 * 30 },
    { label: "45 minutes", value: 60 * 45 },
    { label: "1 hour", value: 3600 },
    { label: "2 hours", value: 3600 * 2 },
    { label: "3 hours", value: 3600 * 3 },
    { label: "4 hours", value: 3600 * 4 },
    { label: "5 hours", value: 3600 * 5 },
    { label: "6 hours", value: 3600 * 6 },
    { label: "7 hours", value: 3600 * 7 },
    { label: "8 hours", value: 3600 * 8 },
    { label: "9 hours", value: 3600 * 9 },
    { label: "10 hours", value: 3600 * 10 },
    { label: "11 hours", value: 3600 * 11 },
    { label: "12 hours", value: 3600 * 12 },
  ];

  const beacons = props.value.liveBeaconContact?.beacons || [];
  const minDuration = closestValue(props.value.liveBeaconContact?.minDurationSeconds || 0, durationOptions);
  const maxDistance = closestValue(props.value.liveBeaconContact?.maxDistanceSeconds || 0, distanceOptions);

  return (
    <div className={editorClassName(props, "live-beacon-contact")}>
      <h2>{props.description}</h2>
      <div className="input-row single">
        <span className="prefix">{beacons.length > 1 ? "When near one of" : "When near to"}</span>
        <Select
          type="form"
          error={props.error?.beacons}
          placeholder="Select beacons"
          errorLabel="Beacons"
          multiValueItemLabel="beacons"
          isMulti={true}
          required={true}
          value={beacons}
          options={props.beacons}
          onChange={(values) =>
            props.onChange({
              liveBeaconContact: {
                beacons: values as string[],
                minDurationSeconds: minDuration,
                maxDistanceSeconds: maxDistance,
              },
            })
          }
        />
      </div>

      <div className="input-row single">
        <span className="prefix">And in contact for</span>
        <Select
          type="form"
          error={props.error?.minDurationSeconds}
          placeholder="Select minimum contact duration"
          errorLabel="Minimum duration"
          isMulti={false}
          required={true}
          value={minDuration}
          options={durationOptions}
          onChange={(value) =>
            props.onChange({
              liveBeaconContact: {
                beacons: beacons,
                minDurationSeconds: typeof value === "number" ? value : 0,
                maxDistanceSeconds: maxDistance,
              },
            })
          }
        />
      </div>
    </div>
  );
}
