import { assign, cloneDeep } from "lodash";
import { PropsWithChildren, createContext, useContext, useEffect } from "react";
import { toast as alert } from "react-toastify";
import { ConfigurationApi, WebConfigModel } from "../api";
import { ServerUnavailable } from "../app/widgets/Alerts";
import { UISettings, defaultSettings, deployments } from "../deployments";
import { isHttpOk } from "../services/api";
import { useStateEx } from "../services/hooks";
import { getApiConfig } from "./configuration";

type Context = {
  config: WebConfigModel & UISettings;
  loading: boolean;
};

const context = createContext<Context>({
  loading: true,
  config: cloneDeep(defaultSettings),
});

function buildDeploymentConfig(config: WebConfigModel): UISettings & WebConfigModel {
  let cloned = cloneDeep(defaultSettings);
  assign(cloned, config);
  if (config.suiteVariant && config.suiteVariant in deployments) {
    assign(cloned, deployments[config.suiteVariant]);
  }
  return cloned;
}

export function WebConfigProvider(props: PropsWithChildren<{}>) {
  const { state, mergeState } = useStateEx<Context>({
    loading: true,
    config: cloneDeep(defaultSettings),
  });

  useEffect(() => {
    const config = getApiConfig();
    const api = new ConfigurationApi(config);
    api
      .getWebConfiguration()
      .then((model) => {
        if (isHttpOk(model)) {
          mergeState({
            config: buildDeploymentConfig(model.data),
            loading: false,
          });
        } else {
          alert.error(<ServerUnavailable diagnosticError={`HTTP: ${model.status}`} />);
          mergeState({
            loading: false,
          });
        }
      })
      .catch((reason) => {
        alert.error(<ServerUnavailable diagnosticError={reason} />);
        mergeState({
          loading: false,
        });
      });
  }, [mergeState]);

  return <context.Provider value={state}>{props.children}</context.Provider>;
}

export function useDeploymentConfig() {
  return useContext(context);
}
