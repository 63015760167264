import { AppStore } from "../../../app/icons/Icons";
import { useDeploymentConfig } from "../../../state/deployment";

import "./CustomerAppNotice.scss";

export function CustomerAppNotice() {
  const config = useDeploymentConfig();
  const appStoreUri = config.loading ? "" : config.config.clientApps?.appStoreUri || "";
  const playStoreUri = config.loading ? "" : config.config.clientApps?.playStoreUri || "";
  const showDownloadInfo = appStoreUri.length > 0 || playStoreUri.length > 0;

  return (
    <div className="customer-app-notice">
      {showDownloadInfo && (
        <>
          <h1>App Available!</h1>
          <div className="download-locations">
            {playStoreUri.length > 0 && (
              <a className="google-logo" href={playStoreUri}>
                <img alt="Get it on Google Play" src="/images/google-play.png" />
              </a>
            )}
            {appStoreUri.length > 0 && (
              <a className="apple-logo" href={appStoreUri}>
                <AppStore />
              </a>
            )}
          </div>
        </>
      )}
      {!showDownloadInfo && (
        <div>
          <h1>App will be available soon!</h1>
        </div>
      )}
    </div>
  );
}
