import React from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { WebConfigProvider } from "./state/deployment";
import { LogoutWhenUnauthorized } from "./services/infrastructure";
import * as pdfjs from "./pdf/pdfjs";

import "./index.scss";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdfjs/pdf.worker.js";

const rootElement = document.getElementById("root");
if (rootElement) {
  ReactModal.setAppElement(rootElement);
  ReactDOM.render(
    <React.StrictMode>
      <LogoutWhenUnauthorized>
        <WebConfigProvider>
          <App />
        </WebConfigProvider>
      </LogoutWhenUnauthorized>
    </React.StrictMode>,
    rootElement
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
