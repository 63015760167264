import { isArray } from "lodash";
import { forwardRef, Ref } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { FieldError, FieldValues, Path, useController, UseFormReturn } from "react-hook-form";
import { stringFromDate, stringToDate } from "../../../services/date";
import { AddNew, Calendar, PageNext, PagePrevious } from "../../icons/Icons";
import { ValidationError } from "../../widgets/ValidationError";

import "./DatePicker.Calendar.scss";
import "./DatePicker.scss";

type Props<TModel extends FieldValues> = {
  name: Path<TModel>;
  control: UseFormReturn<TModel>["control"];
  className?: string;
  withTime?: boolean;
  placeholder?: string;
  required?: boolean;
  errorLabel?: string;
  readOnly?: boolean;
  minimumDate?: Date;
};

export function FormDatePicker<TModel extends FieldValues>(props: Props<TModel>) {
  const renderErrors = (error?: FieldError) => {
    if (!error) {
      return null;
    }

    const fieldName = props.errorLabel || "Field";
    let message: string;
    switch (error.type) {
      case "required":
        message = error.message || `${fieldName} is required`;
        break;
      default:
        message = error.message || `${fieldName} is invalid`;
        break;
    }

    return <ValidationError path={props.name} message={message} />;
  };

  const InputWithIcon = forwardRef(({ value, onClick, onClear }: any, ref: Ref<HTMLInputElement>) => {
    const className = fieldState.invalid ? "invalid" : "";
    return (
      <>
        <input
          readOnly
          className={className}
          placeholder={placeholder}
          type="text"
          value={value}
          onClick={onClick}
          ref={ref}
        />
        {value === "" ? (
          <Calendar className="input-icon" onClick={onClick} />
        ) : (
          <AddNew style={{ transform: "rotate(45deg)" }} onClick={onClear} className="input-icon" />
        )}
        {renderErrors(fieldState.error)}
      </>
    );
  });

  const { field, fieldState } = useController({
    name: props.name,
    control: props.control,
    rules: {
      required: props.required,
    },
  });

  const getDate = (value: [Date | null, Date | null] | Date | null) => {
    if (value == null) {
      return null;
    } else if (isArray(value)) {
      return value.length > 0 ? value[0] : null;
    } else {
      return value;
    }
  };

  const tryClearValue = () => {
    if (props.required || props.readOnly) return;
    field.onChange(null);
  };

  const handleChange: ReactDatePickerProps["onChange"] = (value) => {
    const date = getDate(value);
    if (date === null) {
      field.onChange(null);
    } else {
      field.onChange(stringFromDate(date, !!props.withTime));
    }
  };

  const placeholder = props.placeholder || (props.withTime ? "Enter date and time" : "Enter date");
  const dateFormat = props.withTime ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy";
  const className = [props.className, "date-picker", "form-input"].join(" ");
  const value = stringToDate(field.value as string, true);

  return (
    <ReactDatePicker
      wrapperClassName={className}
      readOnly={props.readOnly}
      selectsRange={false}
      selected={value}
      onBlur={field.onBlur}
      showTimeSelect={props.withTime}
      placeholderText={placeholder}
      onChange={handleChange}
      dateFormat={dateFormat}
      required={props.required}
      isClearable={false}
      customInput={<InputWithIcon onClear={tryClearValue} />}
      disabledKeyboardNavigation={true}
      nextMonthButtonLabel={<PageNext />}
      previousMonthButtonLabel={<PagePrevious />}
      minDate={props.minimumDate}
    />
  );
}
