import { yupResolver } from "@hookform/resolvers/yup";
import { addDays } from "date-fns";
import { useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { PocUserModel } from "../../api";
import { stringToDate } from "../../services/date";
import { PocUserValidator } from "../../services/validators";
import { FormInput } from "../forms/Input";
import { FormDatePicker } from "../forms/date-picker/FormDatePicker";
import { FormModalProps, showErrors } from "./utils";

import "./EditPocUser.scss";

export function EditPocUser(props: FormModalProps<PocUserModel>) {
  const submitTitle = "Create";
  const onChangeRef = useRef(props.onChange);
  onChangeRef.current = props.onChange;
  const initial = { ...props.initial };
  const { register, control, handleSubmit, formState, setError, watch } = useForm<PocUserModel>({
    defaultValues: initial,
    resolver: yupResolver(PocUserValidator),
  });

  watch(
    useCallback(() => {
      if (onChangeRef.current) {
        onChangeRef.current();
      }
    }, [onChangeRef])
  );

  const onSubmit = async (data: PocUserModel) => {
    const result = await props?.onSave(data);
    if (!result.success) {
      showErrors<PocUserModel>(result, setError);
    }
  };

  const errors = formState.errors;
  const tomorrow = addDays(new Date(), 1);
  const currentExpDate = props.initial?.expirationDate ? stringToDate(props.initial!.expirationDate) : null;
  const minimumDate = currentExpDate && currentExpDate > tomorrow ? currentExpDate : tomorrow;
  const isNew = !props.initial?.id;
  const title = isNew ? "New POC Account" : `Edit POC User`;

  return (
    <div className="poc-user-form">
      <div className="header">
        <h1 className="title">{title}</h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <FormInput name="firstName" type="text" placeholder="First Name" errors={errors} register={register} />
        </div>
        <div className="form-row">
          <FormInput name="lastName" type="text" placeholder="Last Name" errors={errors} register={register} />
        </div>
        <div className="form-row">
          <FormInput
            readOnly={!isNew}
            name="email"
            type="text"
            placeholder="Email"
            errors={errors}
            register={register}
          />
        </div>
        <div className="form-row">
          <FormDatePicker
            name="expirationDate"
            placeholder="Expiration Date"
            errorLabel="Date"
            control={control}
            minimumDate={minimumDate}
          />
        </div>
        <div className="footer">
          <button type="button" className="primary cancel" onClick={props.onCancel}>
            Cancel
          </button>
          <button type="submit" disabled={formState.isSubmitting} className="primary save">
            {submitTitle}
          </button>
        </div>
      </form>
    </div>
  );
}
