import { ChangeEventHandler, useState } from "react";
import { useController, useForm } from "react-hook-form";
// eslint-disable-next-line import/no-internal-modules
import { yupResolver } from "@hookform/resolvers/yup";
import { cloneDeep, isNaN } from "lodash";
import { Bar, BarChart, XAxis } from "recharts";
import { SegmentGroupModel, SegmentModel } from "../../api";
import { SegmentGroupValidator } from "../../services/validators";
import { FormInput } from "../forms/Input";
import { Select } from "../forms/Select";
import { ValidationError } from "../widgets/ValidationError";
import { FormModalProps, showErrors } from "./utils";

import "./EditCustomerSegment.scss";

const SegmentationOptions = [
  { label: "2 groups", value: 2 },
  { label: "3 groups", value: 3 },
  { label: "4 groups", value: 4 },
];

function getSegments(nmParts: number): SegmentModel[] {
  switch (nmParts) {
    case 3:
      return [
        { name: "Group A", size: 33 },
        { name: "Group B", size: 34 },
        { name: "Group C", size: 33 },
      ];
    case 4:
      return [
        { name: "Group A", size: 25 },
        { name: "Group B", size: 25 },
        { name: "Group C", size: 25 },
        { name: "Group D", size: 25 },
      ];
    default:
    case 2:
      return [
        { name: "Group A", size: 50 },
        { name: "Group B", size: 50 },
      ];
  }
}

interface Props extends FormModalProps<SegmentGroupModel> {
  isNew: boolean;
}

export default function EditCustomerSegment(props: Props) {
  const initial = cloneDeep(props.initial || {});

  const { register, control, handleSubmit, formState, setError, watch } = useForm<SegmentGroupModel>({
    defaultValues: initial,
    resolver: yupResolver(SegmentGroupValidator),
  });
  watch(() => {
    if (props.onChange) props.onChange();
  });

  const { field: segmentsField, fieldState: segmentsFieldState } = useController({
    control: control,
    name: "segments",
    defaultValue: getSegments(2),
  });

  const handleSave = async (model: SegmentGroupModel) => {
    const result = await props.onSave({ ...model });
    if (!result.success) {
      showErrors(result, setError);
    }
  };

  const errors = formState.errors;

  const ReadonlyLabel = {
    formatter: (value: number) => value.toString() + "%",
  };

  function EditableLabel(props: {
    x: number;
    y: number;
    width: number;
    height: number;
    index: number;
    name: string;
    offset: number;
    value: number;
    viewBox: any;
    content: any;
    parentViewBox: any;
    textBreakAll: any;
  }) {
    const [state, setState] = useState(props.value.toString());

    const y = props.y + (props.height - 34) / 2;

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      setState(e.target.value);
    };

    const handleBlur: ChangeEventHandler<HTMLInputElement> = (e) => {
      const valueAsNumber = parseInt(state);
      const values = cloneDeep(segmentsField.value!);
      if (!isNaN(valueAsNumber) && valueAsNumber > 0 && valueAsNumber < 100) {
        if (values[props.index].size !== valueAsNumber) {
          values[props.index].size = valueAsNumber;
          segmentsField.onChange(values);
        }
      } else {
        setState(values[props.index].size.toString());
      }
    };

    return (
      <foreignObject x={props.x} y={y} width={props.width} height={34}>
        <div className="percentage-input">
          <input
            tabIndex={10 + props.index}
            type="text"
            value={state}
            onBlur={handleBlur}
            onChange={handleChange}
          ></input>
          %
        </div>
      </foreignObject>
    );
  }

  return (
    <div className="customer-segment-form">
      <div className="header">
        <h1 className="title">{props.isNew ? "New Customer Segment" : "Edit Customer Segment"}</h1>
      </div>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="form-row">
          <FormInput name="title" placeholder="Title" errors={errors} register={register} />
        </div>
        <div className="form-row">
          <Select
            type="form"
            placeholder="Select number of groups"
            errorLabel="Number of groups"
            required={true}
            isMulti={false}
            options={SegmentationOptions}
            onChange={(value) => {
              if (typeof value === "number") segmentsField.onChange(getSegments(value));
            }}
            value={segmentsField.value?.length || 2}
            readOnly={!props.isNew}
            hint={!props.isNew ? "Changing number of groups is not possible on existing customer segments." : undefined}
          />
        </div>
        <div className="form-row">
          <div className="form-input">
            <BarChart width={400} height={300} className="customer-segment-preview" data={segmentsField.value!}>
              <XAxis dataKey="name"></XAxis>
              <Bar className="segment-bar" dataKey={"size"} label={props.isNew ? EditableLabel : ReadonlyLabel} />
            </BarChart>
            {segmentsFieldState.error ? (
              <ValidationError path="segments" message={segmentsFieldState.error.message || "Groups are invalid"} />
            ) : null}
          </div>
        </div>
        <div className="footer">
          <button type="button" className="primary cancel" onClick={props.onCancel}>
            Cancel
          </button>
          <button type="submit" disabled={formState.isSubmitting} className="primary save">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
