import { useCallback, useState } from "react";
import { CustomerFilter, TimeframeType } from "../api";
import { CustomerDataTable } from "../app/tables/CustomerDataTable";
import { GraphMouseEvent } from "../app/widgets/Graph";
import { AnalyticsPageHeader } from "../app/widgets/PageHeaders";
import { parseDate } from "../services/date";
import { useDeploymentConfig } from "../state/deployment";
import { useGlobalFilters, useTimeLocationFilters } from "../state/globalFilters";
import { CustomerVisitsGraph } from "./CustomerVisitsGraph";
import { InteractionBasedStatistics } from "./customer-data/InteractionBasedStatistics";
import { RevenueBasedStatistics } from "./customer-data/RevenueBasedStatistics";

import "./CustomerData.scss";

function CustomerData() {
  const filters = useTimeLocationFilters();
  const { selectTime: changeTimeFilter } = useGlobalFilters();
  const deployment = useDeploymentConfig();
  const title = !deployment.config.disablePurchaseHistories ? "Customer Tracking" : "User Tracking";
  const [selectedFilter, setFilter] = useState<CustomerFilter | undefined>(undefined);

  const onCustomerCardClick = (filter?: CustomerFilter) => {
    setFilter(filter);
  };
  const onGraphClick = useCallback(
    (event: GraphMouseEvent) => {
      const { rangeStart, rangeEnd, rangeType } = filters;
      if (rangeType === TimeframeType.CustomRange && rangeStart === rangeEnd) return;

      const dateLabel = event.activeLabel;
      if (dateLabel) {
        const selectedDate = parseDate(dateLabel, "dd.MM.yyyy");
        if (selectedDate) {
          setFilter(undefined);
          changeTimeFilter({
            timeframeType: TimeframeType.CustomRange,
            startDate: selectedDate,
            endDate: selectedDate,
          });
        }
      }
    },
    [filters, changeTimeFilter]
  );

  const customerWidgets = !deployment.config.disablePurchaseHistories ? (
    <RevenueBasedStatistics selectedFilter={selectedFilter} onCustomerCardClick={onCustomerCardClick} />
  ) : (
    <InteractionBasedStatistics />
  );

  return (
    <div className="customer-data page-content">
      <AnalyticsPageHeader title={title} />
      <CustomerVisitsGraph onclick={onGraphClick} />
      {customerWidgets}
      <section>
        <CustomerDataTable selectedFilter={selectedFilter} />
      </section>
    </div>
  );
}

export default CustomerData;
