import pDebounce from "p-debounce";
import React, { CSSProperties, useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { HighlightColor } from "../../services/types";

import "./MapWithMarkers.scss";

type IntPercentage = number;

export type Marker = {
  x: IntPercentage;
  y: IntPercentage;
  color: HighlightColor;
  item?: any;
  tooltip?: string;
  htmlId?: string;
  onClick?: () => void;
};

export type Location = {
  x: IntPercentage;
  y: IntPercentage;
};

export type Props = {
  map?: string;
  markers?: Marker[];
  scale?: number;
  tooltip?: string;
  onClick?: (location: Location) => void;
};

function getStyle(p: Marker): CSSProperties {
  return {
    left: p.x + "%",
    top: p.y + "%",
  };
}

export function MapWithMarkers(props: Props) {
  const imgRef = useRef<HTMLImageElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const handleResizing = () => {
    if (imgRef.current && divRef.current) {
      let width = imgRef.current.naturalWidth;
      let height = imgRef.current.naturalHeight;
      const clientHeight = imgRef.current.clientHeight;
      const clientWidth = imgRef.current.clientWidth;

      let scale = clientWidth / width;
      width = scale * width;
      height = scale * height;

      if (height > clientHeight) {
        scale = clientHeight / height;
        width = scale * width;
        height = scale * height;
      }

      divRef.current.style.width = `${Math.round(width)}px`;
      divRef.current.style.height = `${Math.round(height)}px`;
    }

    ReactTooltip.rebuild();
  };

  useEffect(() => {
    const handler = pDebounce(handleResizing, 100);
    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);

  const markerJson = JSON.stringify(props.markers);
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [markerJson]);

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const clientRects = e.currentTarget.getClientRects();
    // eslint-disable-next-line no-console
    if (clientRects.length !== 1) console.error("Expected single rect in getClientRects() for image!");

    const imagePos = clientRects[0];
    const relX = Math.round(((e.clientX - imagePos.left) / imagePos.width) * 1000) / 10;
    const relY = Math.round(((e.clientY - imagePos.top) / imagePos.height) * 1000) / 10;

    if (props.onClick) {
      props.onClick({ x: relX, y: relY });
    }
  };

  const handleLoad: React.ReactEventHandler<HTMLImageElement> = (e) => {
    if (imgRef.current) {
      imgRef.current.style.display = "";
    }
    handleResizing();
  };

  const handleError: React.ReactEventHandler<HTMLImageElement> = (e) => {
    if (imgRef.current) {
      imgRef.current.style.display = "none";
    }
  };

  const getImage = () => {
    if (props.map && props.map.length > 0) {
      return <img ref={imgRef} onError={handleError} onLoad={handleLoad} src={props.map} alt="heatmap" />;
    }
    return undefined;
  };

  const scale = props.scale || 1;
  const innerRadius = 5 * scale;
  const outerRadius = 9 * scale;
  const strokeWidth = 3 * scale;

  return (
    <div className="map-with-markers">
      {getImage()}
      <div ref={divRef} className="markers" onClick={handleOutsideClick} data-for="appTooltip" data-tip={props.tooltip}>
        {props.markers &&
          props.markers.map((p, i) => {
            const size = outerRadius * 2 + strokeWidth;
            const viewBox = `0 0 ${size} ${size}`;
            return (
              <svg
                id={p.htmlId}
                key={i}
                style={getStyle(p)}
                height={size}
                width={size}
                viewBox={viewBox}
                className={"marker " + p.color}
                onClick={(event) => {
                  if (p.onClick) p.onClick();
                  event.stopPropagation();
                  return false;
                }}
              >
                <circle
                  className="stroked"
                  cx="50%"
                  cy="50%"
                  r={outerRadius}
                  strokeWidth={strokeWidth}
                  fill="none"
                  stroke="#000000"
                />
                <circle
                  data-for="appTooltip"
                  data-tip={p.tooltip || ""}
                  className="filled"
                  cx="50%"
                  cy="50%"
                  r={innerRadius}
                  stroke="none"
                  fill="#000000"
                />
              </svg>
            );
          })}
      </div>
    </div>
  );
}
