import React, { Children, cloneElement, ReactChild, ReactElement, ReactFragment, ReactPortal } from "react";
import { TabProps } from "./Tab";
import { TabListProps } from "./TabList";
import { TabPanelProps } from "./TabPanel";

// Get a universally unique identifier
let count = 0;
export function uuid() {
  return `react-tabs-${count++}`;
}

export function reset() {
  count = 0;
}

function makeTypeChecker<Props>(tabsRole: string) {
  return (element: ReactChild | ReactFragment | ReactPortal): element is ReactElement<Props> => {
    const temp = element as ReactElement<any, any>;
    return !!temp.type && temp.type.tabsRole === tabsRole;
  };
}

export const isTab = makeTypeChecker<TabProps>("Tab");
export const isTabList = makeTypeChecker<TabListProps>("TabList");
export const isTabPanel = makeTypeChecker<TabPanelProps>("TabPanel");

function isTabChild(child: any) {
  return isTab(child) || isTabList(child) || isTabPanel(child);
}

export function deepMap(children: React.ReactNode, callback: (child: any) => any): any {
  return Children.map(children, (child: any) => {
    // null happens when conditionally rendering TabPanel/Tab
    // see https://github.com/reactjs/react-tabs/issues/37
    if (child === null) return null;

    if (isTabChild(child)) {
      return callback(child);
    }

    if (child.props && child.props.children && typeof child.props.children === "object") {
      // Clone the child that has children and map them too
      return cloneElement(child, {
        ...child.props,
        children: deepMap(child.props.children, callback),
      });
    }

    return child;
  });
}

export function deepForEach(children: React.ReactNode, callback: (child: any) => void) {
  return Children.forEach(children, (child: any) => {
    // null happens when conditionally rendering TabPanel/Tab
    // see https://github.com/reactjs/react-tabs/issues/37
    if (child === null) return;

    if (isTab(child) || isTabPanel(child)) {
      callback(child);
    } else if (child.props && child.props.children && typeof child.props.children === "object") {
      if (isTabList(child)) callback(child);
      deepForEach(child.props.children, callback);
    }
  });
}

export function getTabsCount(children: React.ReactNode) {
  let tabCount = 0;
  deepForEach(children, (child) => {
    if (isTab(child)) tabCount++;
  });

  return tabCount;
}

export function getPanelsCount(children: React.ReactNode) {
  let panelCount = 0;
  deepForEach(children, (child) => {
    if (isTabPanel(child)) panelCount++;
  });

  return panelCount;
}
