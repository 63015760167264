export function getTooltip(text: string, title?: string): string;
export function getTooltip(tooltip: Tooltip): string;
export function getTooltip(text: string | Tooltip, title?: string) {
  let object: Tooltip;
  if (typeof text === "string") {
    object = {
      title: title || "",
      tooltip: text,
    };
  } else {
    object = text;
  }
  if (!object.tooltip && !object.title) {
    return "";
  }
  return `
    <div class="tooltip-card">
      ${object.title.length > 0 ? "<div class='tooltip-title'>" + object.title + "</div>" : ""}
      ${object.tooltip.length > 0 ? "<div class='tooltip-text'>" + object.tooltip + "</div>" : ""}
    </div>
  `;
}

export const getBulletedTooltip = (tooltip: BulletedTooltip) => {
  if (tooltip.text.length === 0 && tooltip.bulletPoints.length === 0) return "";

  return `
  <div class="tooltip-card">
    ${tooltip.title !== undefined ? "<div class='tooltip-title'>" + tooltip.title + "</div>" : ""}
    <div class='tooltip-text'>${tooltip.text}</div>
    <ul>
      ${tooltip.bulletPoints.map((bp) => `<li>${bp}</li>`).join("")}
    </ul>
  </div>
  `;
};

export const getImageTooltip = (imageUrl: string, text: string, title?: string) => {
  if (text.length === 0 && imageUrl.length === 0) return "";

  return `
  <div class="tooltip-image-card">
    <div class="tooltip-content">
      <div class="tooltip-status">LIVE</div>
      ${title !== undefined ? "<div class='tooltip-title'>" + title + "</div>" : ""}
      <div class='tooltip-text'>${text}</div>
    </div>
    <div class='figure-and-description'>
      <div class='figure'>
        <div class='image' style="background-image: url(${imageUrl});"></div>
        <div class='frame' style="background-image: url(/images/phone-frame.png);"></div>
      </div>
    </div>
  </div>
  `;
};

export interface Tooltip {
  title: string;
  tooltip: string;
}

export interface BulletedTooltip {
  text: string;
  bulletPoints: string[];
  title?: string;
}
