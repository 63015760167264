import { format } from "date-fns";
import { useCallback } from "react";
import { Frequency, TouchpointsStatistic, TouchpointsStatisticsView } from "../../api";
import { useCachedInstance } from "../../services/hooks";
import { useLocalizedText } from "../../services/localization";
import { Header, PaginatedTable } from "../widgets/PaginatedTable";

import "./AppTouchpointsTable.scss";

type Props = {
  touchpointsView: TouchpointsStatisticsView;
};

function AppTouchpointsTable(props: Props) {
  const texts = useLocalizedText();
  const { statistics, frequency } = props.touchpointsView;
  const touchpoints = useCachedInstance(statistics || []);

  const firstHeader =
    frequency === Frequency.Weekly ? texts.appTouchpointsTable.items.startOfWeek : texts.appTouchpointsTable.items.date;

  var secondHeader: Header = { title: "" };
  if (frequency === Frequency.Daily) secondHeader = texts.appTouchpointsTable.items.day;
  else if (frequency === Frequency.Hourly) secondHeader = texts.appTouchpointsTable.items.hour;
  else if (frequency === Frequency.Weekly) secondHeader = texts.appTouchpointsTable.items.endOfWeek;

  const headers = [
    firstHeader,
    secondHeader,
    texts.appTouchpointsTable.items.local,
    texts.appTouchpointsTable.items.global,
    texts.appTouchpointsTable.items.appLogins,
    texts.appTouchpointsTable.items.appRegistrations,
    texts.appTouchpointsTable.items.appOpens,
    texts.appTouchpointsTable.items.beaconContacts,
  ];

  const renderRow = (row: TouchpointsStatistic) => {
    const date = new Date(row.date);

    var secondCell = "";
    if (frequency === Frequency.Weekly) {
      var endOfWeek = new Date(date);
      endOfWeek.setDate(endOfWeek.getDate() + 6);

      const today = new Date();
      if (endOfWeek > today) endOfWeek = today;

      secondCell = format(endOfWeek, "dd.MM.yyyy");
    } else if (frequency === Frequency.Daily) secondCell = format(date, "EEEE");
    else if (frequency === Frequency.Hourly) secondCell = format(date, "h aa");

    return [
      format(date, "dd.MM.yyyy"),
      secondCell,
      row.local,
      row.global,
      row.logins,
      row.registrations,
      row.opens,
      row.beaconContacts,
    ];
  };

  const filterItems = useCallback(
    (offset: number, limit: number) => {
      if (touchpoints.length <= offset)
        return {
          items: [],
          count: touchpoints.length,
        };
      return { items: touchpoints.slice(offset, offset + limit), count: touchpoints.length };
    },
    [touchpoints]
  );

  return (
    <section>
      <header>
        <h1>{texts.appTouchpointsTable.title}</h1>
      </header>
      <PaginatedTable
        className="app-touch-table"
        tableHeaders={headers}
        tableDataSource={filterItems}
        tableRowFactory={renderRow}
        forceShowProgress={touchpoints.length === 0}
        hidePagination={false}
        minLoadingTimeMs={200}
        initialPageSize={10}
      />
    </section>
  );
}

export default AppTouchpointsTable;
