import { useEffect } from "react";
import { VisitorStatisticsApi } from "../../api";
import StatsCardSimple from "../../app/cards/StatsCardSimple";
import { NewAndReturning, NewAppCustomers, RecurringVisit } from "../../app/icons/Icons";
import { isHttpOk } from "../../services/api";
import { formatPercentage } from "../../services/format";
import { useStateEx } from "../../services/hooks";
import { useLocalizedText } from "../../services/localization";
import { isProvided } from "../../services/objects";
import { getApiConfig } from "../../state/configuration";
import { useTimeLocationFilters } from "../../state/globalFilters";

export function TopRowStatistics() {
  const texts = useLocalizedText();
  const filters = useTimeLocationFilters();
  const { state, mergeState } = useStateEx<{
    isLoading: boolean;
    storeVisits?: number;
    recurringVisits?: number;
    newVsReturning?: number;
  }>({ isLoading: true });

  useEffect(() => {
    const config = getApiConfig();
    const visitorApi = new VisitorStatisticsApi(config);
    const promises = [
      visitorApi.getNewVsReturning(filters).then((result) => {
        if (isHttpOk(result))
          mergeState({
            storeVisits: result.data.nmNew + result.data.nmReturning,
            recurringVisits: result.data.nmReturning,
            newVsReturning: result.data.newVsReturning || undefined,
          });
      }),
    ];
    Promise.all(promises).then(() => {
      mergeState({ isLoading: false });
    });
  }, [filters, mergeState]);

  return (
    <>
      <section className="analytics">
        <StatsCardSimple
          value={state.storeVisits}
          tooltipText={texts.visitorStatisticsPage.items.storeVisits.tooltip}
          label={texts.visitorStatisticsPage.items.storeVisits.title}
          color="lightblue"
          figure={<NewAppCustomers />}
        />
        <StatsCardSimple
          value={state.recurringVisits}
          tooltipText={texts.visitorStatisticsPage.items.recurringVisits.tooltip}
          label={texts.visitorStatisticsPage.items.recurringVisits.title}
          color="red"
          figure={<RecurringVisit />}
        />
        <StatsCardSimple
          value={isProvided(state.newVsReturning) ? formatPercentage(state.newVsReturning / 100, 1) : "N/A"}
          tooltipText={texts.visitorStatisticsPage.items.newVsReturning.tooltip}
          label={texts.visitorStatisticsPage.items.newVsReturning.title}
          color="green"
          decimalPlaces={1}
          asPercentage={true}
          figure={<NewAndReturning />}
        />
      </section>
    </>
  );
}
