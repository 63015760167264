import pDebounce from "p-debounce";
import { ChangeEvent, InputHTMLAttributes, useEffect, useMemo } from "react";
import { useStateEx } from "../../services/hooks";
import { AddNew, SpyGlass } from "../icons/Icons";

import "./Search.scss";

type Props = {
  disabled?: boolean;
  value: string;
  onChange: (value: string) => void;
  onChangeDebounceInMs?: number;
};

export function Search(props: Props) {
  const { state, mergeState } = useStateEx({
    value: props.value,
    focused: false,
    over: false,
  });

  useEffect(() => {
    mergeState((state) => {
      if (props.value !== state.value.trim()) {
        return { value: props.value };
      } else {
        return undefined;
      }
    });
  }, [props.value, mergeState]);

  const { onChange, onChangeDebounceInMs } = props;
  const notifyChange = useMemo(
    () => pDebounce(onChange, onChangeDebounceInMs || 200),
    [onChange, onChangeDebounceInMs]
  );

  const inputHandlers: Partial<InputHTMLAttributes<{}>> = {
    onChange: (event: ChangeEvent<HTMLInputElement>) => {
      if (props.disabled) return;
      mergeState({ value: event.target.value });
      notifyChange(event.target.value.trim());
    },
    onFocus: () => {
      if (props.disabled) return;
      mergeState({ focused: true });
    },
    onBlur: () => {
      if (props.disabled) return;
      mergeState({ focused: false });
    },
    onMouseEnter: () => {
      if (props.disabled) return;
      mergeState({ over: true });
    },
    onMouseLeave: () => {
      if (props.disabled) return;
      mergeState({ over: false });
    },
  };

  const hasValue = state.value.trim().length > 0;

  const classNames = ["search"];
  if (props.disabled) {
    classNames.push("search__disabled");
  }
  if (state.over || state.focused || hasValue) {
    classNames.push("search__open");
  }
  if (hasValue) {
    classNames.push("search__with-value");
  }

  return (
    <div className={classNames.join(" ")}>
      <SpyGlass className="spy" onMouseEnter={inputHandlers.onMouseEnter} onMouseLeave={inputHandlers.onMouseLeave} />
      {hasValue && (
        <AddNew
          className="clear"
          onClick={() => {
            if (props.disabled) return;
            mergeState({ value: "" });
            notifyChange("");
          }}
          onMouseEnter={inputHandlers.onMouseEnter}
          onMouseLeave={inputHandlers.onMouseLeave}
        />
      )}
      <input type="text" disabled={props.disabled} value={state.value} {...inputHandlers} />
    </div>
  );
}
