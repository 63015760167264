import { format } from "date-fns";
import { BeaconsApi, BeaconView } from "../../api";
import { getApiConfig } from "../../state/configuration";
import { useDeploymentConfig } from "../../state/deployment";
import { PaginatedTable } from "../widgets/PaginatedTable";

import "./LocationOverviewBeacons.scss";

export default function LocationOverviewBeacons() {
  const { config } = useDeploymentConfig();
  const apiConfig = getApiConfig();

  const loadPage = (offset: number, limit: number) => {
    const api = new BeaconsApi(apiConfig);
    return api.getAll({ skip: offset, limit });
  };

  const renderCells = (model: BeaconView, index: number) => {
    return [
      model.name,
      model.storeName,
      model.lastContact ? (
        <span className="last-activity">{format(new Date(model.lastContact), "dd.MM - HH:mm")}</span>
      ) : (
        <span className="last-activity red">Never</span>
      ),
    ];
  };

  return (
    <section className="location-overview-beacons">
      <header>
        <h1>
          <a href="/beacons">Beacon Activity</a>
        </h1>
      </header>
      <div>
        <PaginatedTable
          initialPageSize={5}
          tableHeaders={[
            { title: "BEACON NAME" },
            { title: config.translations.store.singular.toUpperCase() },
            { title: "LAST CONTACT" },
          ]}
          tableRowFactory={renderCells}
          tableDataSource={loadPage}
          minLoadingTimeMs={200}
          hidePagination={false}
        />
      </div>
    </section>
  );
}
