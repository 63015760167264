import React, { createRef, PureComponent, Ref } from "react";
import PropTypes from "prop-types";
import { makeEventProps, makeCancellable, CancellablePromise } from "../services/objects";
import DocumentContext from "./DocumentContext";
import PageContext, { PageContextType } from "./PageContext";
import Message from "./Message";
import PageCanvas from "./Page/PageCanvas";
import PageSVG from "./Page/PageSVG";
import TextLayer from "./Page/TextLayer";
import AnnotationLayer from "./Page/AnnotationLayer";
import {
  cancelRunningTask,
  EnhancedPage,
  errorOnDev,
  isProvided,
  makePageCallback,
  mergeClassNames,
  mergeRefs,
} from "./shared/utils";
import {
  eventProps,
  isClassName,
  isPageIndex,
  isPageNumber,
  isPdf,
  isRef,
  isRenderMode,
  isRotate,
} from "./shared/propTypes";
import { PDFDocumentProxy, PDFPageProxy } from "./pdfjs";

const defaultScale = 1;

const isFunctionOrNode = PropTypes.oneOfType([PropTypes.func, PropTypes.node]);

type FnOrNode = (() => React.ReactNode) | React.ReactNode;

interface InternalProps extends PageContextType {
  pdf?: PDFDocumentProxy | false;
  unregisterPage?: (pageIndex: number) => void;
  registerPage?: (pageIndex: number, ref: Ref<HTMLDivElement>) => void;
  onLoadSuccess?: (page: EnhancedPage) => void;
  onLoadError?: (error: Error) => void;
  pageNumber?: number;
  pageIndex?: number;
  width?: number;
  height?: number;
  canvasRef?: Ref<HTMLCanvasElement>;
  inputRef?: Ref<HTMLDivElement>;
  renderTextLayer?: boolean;
  renderAnnotationLayer?: boolean;
  noData?: FnOrNode;
  loading?: FnOrNode;
  error?: FnOrNode;
  renderMode?: "none" | "canvas" | "svg";
  className?: string | string[];
}

interface State {
  page: PDFPageProxy | null | false;
}

class PageInternal extends PureComponent<InternalProps, State> {
  static defaultProps = {
    error: "Failed to load the page.",
    loading: "Loading page...",
    noData: "No page specified.",
    renderAnnotationLayer: true,
    renderInteractiveForms: false,
    renderMode: "canvas",
    renderTextLayer: true,
    scale: defaultScale,
  };

  static propTypes = {
    ...eventProps,
    children: PropTypes.node,
    className: isClassName,
    customTextRenderer: PropTypes.func,
    error: isFunctionOrNode,
    height: PropTypes.number,
    imageResourcesPath: PropTypes.string,
    inputRef: isRef,
    loading: isFunctionOrNode,
    noData: isFunctionOrNode,
    onGetTextError: PropTypes.func,
    onGetTextSuccess: PropTypes.func,
    onLoadError: PropTypes.func,
    onLoadSuccess: PropTypes.func,
    onRenderStart: PropTypes.func,
    onRenderError: PropTypes.func,
    onRenderSuccess: PropTypes.func,
    pageIndex: isPageIndex,
    pageNumber: isPageNumber,
    pdf: isPdf,
    registerPage: PropTypes.func,
    renderAnnotationLayer: PropTypes.bool,
    renderInteractiveForms: PropTypes.bool,
    renderMode: isRenderMode,
    renderTextLayer: PropTypes.bool,
    rotate: isRotate,
    scale: PropTypes.number,
    unregisterPage: PropTypes.func,
    width: PropTypes.number,
  };

  private ref: Ref<HTMLDivElement> = createRef();
  private runningTask: CancellablePromise<PDFPageProxy> | null = null;

  constructor(props: InternalProps) {
    super(props);
    this.state = {
      page: null,
    };
  }

  componentDidMount() {
    const { pdf } = this.props;

    if (!pdf) {
      throw new Error("Attempted to load a page, but no document was specified.");
    }

    this.loadPage();
  }

  componentDidUpdate(prevProps: InternalProps) {
    const { pdf } = this.props;

    if ((prevProps.pdf && pdf !== prevProps.pdf) || this.getPageNumber() !== this.getPageNumber(prevProps)) {
      const { unregisterPage } = this.props;

      if (unregisterPage) unregisterPage(this.getPageIndex(prevProps));

      this.loadPage();
    }
  }

  componentWillUnmount() {
    const { unregisterPage } = this.props;

    if (unregisterPage) unregisterPage(this.pageIndex);

    cancelRunningTask(this.runningTask);
  }

  get childContext() {
    const { page } = this.state;

    if (!page) {
      return null;
    }

    const {
      customTextRenderer,
      onGetAnnotationsError,
      onGetAnnotationsSuccess,
      onGetTextError,
      onGetTextSuccess,
      onRenderAnnotationLayerError,
      onRenderAnnotationLayerSuccess,
      onRenderStart,
      onRenderError,
      onRenderSuccess,
      renderInteractiveForms,
    } = this.props;

    return {
      customTextRenderer,
      onGetAnnotationsError,
      onGetAnnotationsSuccess,
      onGetTextError,
      onGetTextSuccess,
      onRenderAnnotationLayerError,
      onRenderAnnotationLayerSuccess,
      onRenderStart,
      onRenderError,
      onRenderSuccess,
      page,
      renderInteractiveForms,
      rotate: this.rotate,
      scale: this.scale,
    };
  }

  /**
   * Called when a page is loaded successfully
   */
  onLoadSuccess = () => {
    const { onLoadSuccess, registerPage } = this.props;
    const { page } = this.state;
    if (page) {
      if (onLoadSuccess) onLoadSuccess(makePageCallback(page, this.scale));
      if (registerPage) registerPage(this.pageIndex, this.ref);
    }
  };

  /**
   * Called when a page failed to load
   */
  onLoadError = (error: Error) => {
    errorOnDev(error);

    const { onLoadError } = this.props;

    if (onLoadError) onLoadError(error);
  };

  getPageIndex(props = this.props) {
    if (isProvided(props.pageNumber)) {
      return props.pageNumber - 1;
    }

    if (isProvided(props.pageIndex)) {
      return props.pageIndex;
    }

    return 0;
  }

  getPageNumber(props = this.props) {
    if (isProvided(props.pageNumber)) {
      return props.pageNumber;
    }

    if (isProvided(props.pageIndex)) {
      return props.pageIndex + 1;
    }

    return 0;
  }

  get pageIndex() {
    return this.getPageIndex();
  }

  get pageNumber() {
    return this.getPageNumber();
  }

  get rotate() {
    const { rotate } = this.props;

    if (isProvided(rotate)) {
      return rotate;
    }

    const { page } = this.state;

    if (!page) {
      return 0;
    }

    return page.rotate as 0 | 90 | 180 | 270;
  }

  get scale() {
    const { page } = this.state;

    if (!page) {
      return 1;
    }

    const { scale, width, height } = this.props;

    // Be default, we'll render page at 100% * scale width.
    let pageScale = 1;

    // Passing scale explicitly null would cause the page not to render
    const scaleWithDefault = typeof scale !== "number" ? defaultScale : scale;

    // If width/height is defined, calculate the scale of the page so it could be of desired width.
    if (width !== undefined || height !== undefined) {
      const viewport = page.getViewport({ scale: 1, rotation: this.rotate || 0 });
      pageScale = width !== undefined ? width / viewport.width : height! / viewport.height;
    }

    return scaleWithDefault * pageScale;
  }

  get eventProps() {
    return makeEventProps(this.props, () => {
      const { page } = this.state;
      if (!page) {
        return page;
      }
      return makePageCallback(page, this.scale);
    });
  }

  get pageKey() {
    return `${this.pageIndex}@${this.scale}/${this.rotate || 0}`;
  }

  get pageKeyNoScale() {
    return `${this.pageIndex}/${this.rotate || 0}`;
  }

  loadPage = async () => {
    const { pdf } = this.props;

    if (!pdf) {
      return;
    }

    const pageNumber = this.getPageNumber();

    if (!pageNumber) {
      return;
    }

    this.setState((prevState) => {
      if (!prevState.page) {
        return null;
      }
      return { page: null };
    });

    try {
      const cancellable = makeCancellable(pdf.getPage(pageNumber));
      this.runningTask = cancellable;
      const page = await cancellable;
      this.setState({ page }, this.onLoadSuccess);
    } catch (error) {
      this.setState({ page: false });
      this.onLoadError(error as Error);
    }
  };

  renderMainLayer() {
    const { canvasRef, renderMode } = this.props;

    switch (renderMode) {
      case "none":
        return null;
      case "svg":
        return <PageSVG key={`${this.pageKeyNoScale}_svg`} />;
      case "canvas":
      default:
        return <PageCanvas key={`${this.pageKey}_canvas`} canvasRef={canvasRef} />;
    }
  }

  renderTextLayer() {
    const { renderTextLayer } = this.props;

    if (!renderTextLayer) {
      return null;
    }

    return <TextLayer key={`${this.pageKey}_text`} />;
  }

  renderAnnotationLayer() {
    const { renderAnnotationLayer } = this.props;

    if (!renderAnnotationLayer) {
      return null;
    }

    /**
     * As of now, PDF.js 2.0.943 returns warnings on unimplemented annotations in SVG mode.
     * Therefore, as a fallback, we render "traditional" AnnotationLayer component.
     */

    return <AnnotationLayer key={`${this.pageKey}_annotations`} />;
  }

  renderChildren() {
    const { children } = this.props;

    return (
      <PageContext.Provider value={this.childContext}>
        {this.renderMainLayer()}
        {this.renderTextLayer()}
        {this.renderAnnotationLayer()}
        {children}
      </PageContext.Provider>
    );
  }

  renderContent() {
    const { pdf } = this.props;
    const { page } = this.state;

    if (!this.pageNumber) {
      const { noData } = this.props;

      return <Message type="no-data">{typeof noData === "function" ? noData() : noData}</Message>;
    }

    if (pdf === null || page === null) {
      const { loading } = this.props;

      return <Message type="loading">{typeof loading === "function" ? loading() : loading}</Message>;
    }

    if (pdf === false || page === false) {
      const { error } = this.props;

      return <Message type="error">{typeof error === "function" ? error() : error}</Message>;
    }

    return this.renderChildren();
  }

  render() {
    return (
      <div
        className={mergeClassNames("react-pdf__Page", this.props.className)}
        data-page-number={this.pageNumber}
        ref={mergeRefs(this.props.inputRef, this.ref)}
        style={{ position: "relative" }}
        {...this.eventProps}
      >
        {this.renderContent()}
      </div>
    );
  }
}

interface Props extends Omit<InternalProps, "page"> {
  page?: PDFPageProxy;
}

function Page(props: Props, ref: Ref<PageInternal>) {
  return (
    <DocumentContext.Consumer>
      {(context) => {
        return <PageInternal ref={ref} {...context} {...props} />;
      }}
    </DocumentContext.Consumer>
  );
}

export default React.forwardRef(Page);
