import clsx, { ClassValue } from "clsx";
import { Draggable } from "react-beautiful-dnd";
import { getTooltip, Tooltip } from "../../../services/tooltip";

import "./DraggableItem.scss";

interface ItemProps {
  draggableId: string;
  draggableIndex: number;
  name: string;
  tooltip: Tooltip;
  className?: ClassValue;
}

export function DraggableItem(props: ItemProps) {
  return (
    <Draggable draggableId={props.draggableId} index={props.draggableIndex}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          className={clsx(props.className, "drag-item")}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <span data-for="appTooltip" data-tip={getTooltip(props.tooltip)}>
            {props.name}
          </span>
        </div>
      )}
    </Draggable>
  );
}
