import clsx from "clsx";
import { useRef } from "react";
import { toast as alert } from "react-toastify";
import { ImageApi, StoreLayoutModel, StoresApi } from "../../api";
import { isHttpOk } from "../../services/api";
import { useStateEx } from "../../services/hooks";
import { getApiConfig } from "../../state/configuration";
import { Upload } from "../icons/Icons";
import { EditFloorplanPdf } from "../modals/EditFloorplanPdf";
import { Modal, useModal } from "../modals/Modal";
import { AlertContent } from "../widgets/Alerts";
import { ValidationError } from "../widgets/ValidationError";

import "./FloorplanUpload.scss";

function validateFile(files: FileList | null | undefined): { valid: boolean; file?: File; isPdf: boolean } {
  if (!files || files.length !== 1) {
    return {
      valid: false,
      isPdf: false,
    };
  }
  const fileType = files[0].type;
  return {
    valid: fileType === "image/png" || fileType === "image/jpeg" || fileType === "application/pdf",
    file: files[0],
    isPdf: fileType.includes("pdf"),
  };
}

type State = {
  error: string;
  invalid: boolean;
  uploadInProgress: boolean;
  file?: File;
};

interface FloorplanUploadProps {
  storeId: number;
  onSuccess?: (updated: StoreLayoutModel) => void;
}

interface Floor {
  title: string;
  blob: Blob | File;
}

export function FloorplanUpload(props: FloorplanUploadProps) {
  const { state, mergeState } = useStateEx<State>({
    error: "",
    invalid: false,
    uploadInProgress: false,
  });
  const { openModal, closeModal } = useModal("floorplan-pdf-editor");
  const inputRef = useRef<HTMLInputElement>(null);
  const wasCancelled = useRef(false);

  const onFileSelect = async (files?: FileList | null) => {
    const file = validateFile(files);
    if (!file.valid) {
      return;
    }
    if (file.isPdf) {
      mergeState({
        file: file.file,
      });
      openModal();
    } else {
      await appendFloorplans([
        {
          blob: file.file!,
          title: "New floor",
        },
      ]);
    }
  };

  const appendFloorplans = async (floors: Floor[]) => {
    try {
      const config = getApiConfig();
      const imageApi = new ImageApi(config);
      const storesApi = new StoresApi(config);

      mergeState({ uploadInProgress: true });

      const layoutPromise = storesApi.getLayout({
        id: props.storeId,
      });

      const uploadPromises = floors.map(async (floor) => {
        const image = await imageApi.create({
          image: floor.blob,
          settings: {
            generateGrayscale: false,
            generateThumbnail: false,
            generateThumbnailGrayscale: false,
            skipResizing: true,
          },
        });

        if (!isHttpOk(image)) {
          throw new Error("Could not upload image for " + floor.title);
        }

        return {
          title: floor.title,
          image: image.data.imagePath!,
        };
      });

      if (wasCancelled.current) {
        return;
      }

      const layout = await layoutPromise;
      if (!isHttpOk(layout)) {
        throw new Error("Could not fetch layout for " + props.storeId);
      }

      const uploaded = await Promise.all(uploadPromises);

      if (wasCancelled.current) {
        return;
      }

      const newFloors = uploaded.map((floor) => ({
        beacons: [],
        imageUrl: floor.image,
        title: floor.title,
      }));

      const model = { ...layout.data, floors: [...layout.data.floors!, ...newFloors] };

      if (props.onSuccess) props.onSuccess(model);
      alert.success(<AlertContent message={"Updated floorplans for " + model.storeName} />);
      closeModal();
    } catch (err) {
      alert.error(<AlertContent diagnosticError={err} message={"Unable to upload floorplan."} />);
    } finally {
      mergeState({ uploadInProgress: false });
      wasCancelled.current = false;
    }
  };

  const buttonLabel = () => {
    if (state.uploadInProgress) {
      return "Uploading";
    } else {
      return "Upload";
    }
  };

  const renderInfoOrError = () => {
    if (state.error && state.error.length > 0) {
      return <ValidationError message={state.error} />;
    } else {
      return <span className="instruction">Please upload a valid image (*.png, *.jpg) or PDF file.</span>;
    }
  };

  return (
    <>
      <div
        className={clsx({
          "form-input": true,
          "floorplan-upload": true,
          "upload-in-progress": state.uploadInProgress,
          invalid: state.invalid,
        })}
      >
        <button className="secondary" type="button" onClick={() => inputRef.current!.click()}>
          <Upload /> {buttonLabel()}
        </button>
        <input
          id="file-input"
          ref={inputRef}
          type="file"
          multiple={false}
          accept="image/*,application/pdf"
          onChange={async (e) => {
            await onFileSelect(inputRef.current!.files);
            inputRef.current!.value = "";
            inputRef.current!.files = null;
          }}
        />
        {renderInfoOrError()}
      </div>
      <Modal id="floorplan-pdf-editor">
        <EditFloorplanPdf
          file={state.file}
          onSave={appendFloorplans}
          onCancel={() => {
            wasCancelled.current = true;
            closeModal();
          }}
        />
      </Modal>
    </>
  );
}
