import { useDeploymentConfig } from "../../../../state/deployment";
import { editorClassName, EditorProps } from "./common";
import { LocationInput } from "./inputs/LocationInput";

export function FavoriteStoreEditor(props: EditorProps) {
  const { config } = useDeploymentConfig();
  const selectedLocations = props.value.favoriteStore?.locations || [];
  return (
    <div className={editorClassName(props, "favorite-store")}>
      <h2>{props.description}</h2>
      <LocationInput
        prefix={`Favorite ${config.translations.store.singular.toLowerCase()} is in`}
        value={selectedLocations}
        onChange={(value) => {
          props.onChange({
            favoriteStore: {
              locations: value,
            },
          });
        }}
        error={props.error?.locations}
      />
    </div>
  );
}
