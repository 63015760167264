import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { toast as alert } from "react-toastify";
import { FormInput } from "../../../app/forms/Input";
import { AlertContent } from "../../../app/widgets/Alerts";
import { LoginFormFields } from "../../../services/types";
import { LoginFormValidator } from "../../../services/validators";
import { login, useAuthentication } from "../../../state/authentication";

export function LoginForm(props: { attemptedPath?: string }) {
  const [isWorking, setWorking] = useState(false);
  const auth = useAuthentication();
  const history = useHistory();
  const { register, handleSubmit, formState } = useForm<LoginFormFields>({
    resolver: yupResolver(LoginFormValidator),
  });
  const errors = formState.errors;

  const onSubmit = useCallback(
    async (data: LoginFormFields) => {
      setWorking(true);
      const result = await login(data.username, data.password, data.remember);
      if (result.success) {
        const path = props.attemptedPath && props.attemptedPath !== "/" ? props.attemptedPath : "/analytics";
        history.push(path);
      } else if (!result.success && result.redirectToApp) {
        history.push("/app");
      } else {
        alert.warn(<AlertContent message={result.message} />);
        setWorking(false);
      }
    },
    [history, props.attemptedPath]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <FormInput
          name="username"
          type="email"
          placeholder="&nbsp;Username"
          required={true}
          errors={errors}
          register={register}
        />
      </div>
      <div className="form-row">
        <FormInput
          name="password"
          type="password"
          placeholder="&nbsp;Password"
          required={true}
          errors={errors}
          register={register}
        />
      </div>
      <div className="form-check">
        <label htmlFor="remember" className="remember">
          <input id="remember" type="checkbox" {...register("remember", {})} defaultChecked={auth.remember} />
          Remember me
        </label>
        <div className="forgot">
          <Link to="/forgot-password">Forgot password?</Link>
        </div>
      </div>
      <button type="submit" className="primary login" disabled={isWorking}>
        Login
      </button>
    </form>
  );
}
