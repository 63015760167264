import { isArray } from "lodash";
import { useCallback, useRef } from "react";
import { ChangeAction, ChangeLogApi, EntityChangeView, Table } from "../api";
import Card from "../app/cards/Card";
import { Select } from "../app/forms/Select";
import { Modal, useModal } from "../app/modals/Modal";
import { PropertyChanges } from "../app/tables/PropertyChanges";
import { highlight } from "../app/widgets/HighlightedString";
import { PageHeader } from "../app/widgets/PageHeaders";
import { PaginatedTable, PaginatedTableApi } from "../app/widgets/PaginatedTable";
import { utcToLocal } from "../services/date";
import { formatDateTime } from "../services/format";
import { useStateEx } from "../services/hooks";
import { addSpaces } from "../services/string";
import { getApiConfig } from "../state/configuration";
import { useGlobalSearch } from "../state/globalSearch";

import "./ChangeLog.scss";

type State = {
  totalCount: number;
  selectedAction: ActionFilter;
  selectedTables: Table[];
  selectedId?: number;
  selectedEntityDescription?: string;
};

enum FilterOption {
  All = -1,
}

type ActionFilter = ChangeAction | FilterOption;

export function ChangeLog() {
  const apiRef = useRef<PaginatedTableApi>(null);
  const { search } = useGlobalSearch();
  const { state, mergeState } = useStateEx<State>({
    totalCount: 0,
    selectedAction: FilterOption.All,
    selectedTables: [],
  });
  const { openModal, closeModal } = useModal();

  const actionOptions = [
    { label: "Action: All", value: FilterOption.All },
    { label: "Action: Added", value: ChangeAction.Added },
    { label: "Action: Updated", value: ChangeAction.Updated },
    { label: "Action: Deleted", value: ChangeAction.Deleted },
  ];
  const isAction = (val: ActionFilter): val is ChangeAction => val !== FilterOption.All;
  const action = isAction(state.selectedAction) ? state.selectedAction : undefined;

  const tableOptions = Object.values(Table)
    .filter((v) => typeof v === "number")
    .map((value) => ({
      label: addSpaces(Table[value as number]),
      value: value as number,
    }));

  const loadPage = useCallback(
    (offset: number, limit: number) => {
      const config = getApiConfig();
      const api = new ChangeLogApi(config);
      return api.getAll({
        search: search,
        skip: offset,
        limit: limit,
        action: action,
        tables: state.selectedTables,
      });
    },
    [search, action, state.selectedTables]
  );

  const renderCells = (model: EntityChangeView, index: number) => {
    const color =
      model.action === ChangeAction.Added ? "green" : model.action === ChangeAction.Deleted ? "red" : "yellow";
    const actioned = <span className={color}>{highlight(ChangeAction[model.action], search)}</span>;

    const entityId = highlight(model.entityId, search);

    const title = highlight(model.title, search);
    const table = addSpaces(Table[model.table]);
    const nameOrId = highlight(model.userNameOrId, search);
    const localDate = formatDateTime(utcToLocal(model.utcDate), "dd.MM.yyyy hh:mm a");

    const clickable = model.action === ChangeAction.Updated;
    const onClick = () => {
      const entityIdDes = `Entity Id(s): ${model.entityId},`;
      const titleDes = `Entity Title: ${model.title || ""}, `;
      const userDes = `User: ${model.userNameOrId}`;
      const description = `Properties that were changed for ${entityIdDes} ${titleDes} by ${userDes}`;
      mergeState({ selectedId: model.id, selectedEntityDescription: description });
      openModal();
    };
    const className = clickable ? "clickable" : "";
    const event = (
      <span className={className} onClick={clickable ? onClick : undefined}>
        {nameOrId}
        {" has "}
        {actioned}
        {` an entry in ${table} table`}
      </span>
    );
    return [localDate, table, entityId, title, event];
  };

  return (
    <div className="change-log page-content dashboard">
      <PageHeader title="Change Log" subTitle={`Found ${state.totalCount} change entries`}>
        <div className="toolbar">
          <Select
            type="filter"
            isMulti={true}
            clearText="All Tables"
            placeholder="All Tables"
            multiValueItemLabel="tables"
            value={state.selectedTables}
            options={tableOptions}
            onChange={(e) => {
              if (isArray(e)) mergeState({ selectedTables: e as Table[] });
            }}
          />
          <Select
            type="filter"
            required={true}
            options={actionOptions}
            value={state.selectedAction}
            onChange={(v) => {
              if (typeof v === "number") mergeState({ selectedAction: v });
            }}
          />
        </div>
      </PageHeader>
      <section>
        <Card>
          <PaginatedTable
            apiRef={apiRef}
            className="table"
            initialPageSize={10}
            tableHeaders={[
              { title: "DATE" },
              { title: "TABLE" },
              { title: "ENTITY ID" },
              { title: "TITLE" },
              { title: "EVENT" },
            ]}
            tableDataSource={loadPage}
            tableRowFactory={renderCells}
            minLoadingTimeMs={200}
            hidePagination={false}
            onTotalCountChange={(count) => mergeState({ totalCount: count })}
          />
        </Card>
        <Modal onRequestClose={closeModal} className="wide-modal">
          {state.selectedId && (
            <PropertyChanges entityChangeId={state.selectedId} description={state.selectedEntityDescription || ""} />
          )}
        </Modal>
      </section>
    </div>
  );
}
