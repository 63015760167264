import clsx from "clsx";
import { divIcon, LatLngExpression, LeafletMouseEvent, map, marker, point, svg } from "leaflet";
import { useCallback, useEffect, useRef, useState } from "react";
import { Coordinates } from "../../api";
import { useEventHandler } from "../../services/hooks";
import { AzureMapsTiles } from "../world-map/AzureMapsTiles";
import { AuthOptions, Constants } from "../world-map/Constants";
import { RequestAuthenticator } from "../world-map/RequestAuthenticator";
import { ErrorResponse, SearchResult } from "../world-map/Types";

// eslint-disable-next-line import/no-internal-modules
import "leaflet/dist/leaflet.css";
import "./LocationPicker.scss";

type Map = ReturnType<typeof map>;

export async function searchAddress(address: string) {
  const auth = RequestAuthenticator.getInstance(AuthOptions);
  const url = `${Constants.DEFAULT_DOMAIN}/search/address/json?api-version=1.0&query=${encodeURI(address)}`;
  const response = await auth.getRequest(url);
  if (response.status === 200) {
    const data: SearchResult = await response.json();
    return data;
  } else {
    const data: ErrorResponse = await response.json();
    return data;
  }
}

export function LocationPicker(props: { value: Coordinates | null; onChange: (position: Coordinates) => void }) {
  const mapRef = useRef<Map>();
  const divRef = useRef<HTMLDivElement>(null);
  const onChange = useEventHandler(props.onChange);
  const [hasView, setHasView] = useState<boolean>(false);
  const value = props.value;

  const setInitialView = useCallback(
    (map: Map) => {
      if (!value) return;
      const ll: LatLngExpression = [value.latitude, value.longitude];
      map.setView(ll, 15, { animate: false });
      setHasView(true);
    },
    [value, setHasView]
  );

  useEffect(() => {
    if (!divRef.current) return;

    const instance = map(divRef.current, {
      renderer: svg(),
      minZoom: 1,
    });

    const layer = new AzureMapsTiles({
      authOptions: AuthOptions,
      tilesetId: "microsoft.base.road",
      language: "en-US",
      hideAttribution: true,
    });
    instance.addLayer(layer);

    if (value) {
      setInitialView(instance);
    }
    instance.addEventListener("click", (e: LeafletMouseEvent) => {
      onChange.current({
        latitude: e.latlng.lat,
        longitude: e.latlng.lng,
      });
    });

    mapRef.current = instance;
    return () => {
      instance.remove();
      mapRef.current = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange]);

  const hasValue = value !== null;
  useEffect(() => {
    if (!mapRef.current || !hasValue) return;
    const map = mapRef.current;
    const ll: LatLngExpression = [value.latitude, value.longitude];
    const instance = marker(ll, {
      icon: divIcon({
        html: `<svg viewBox="0 0 14 20"><path d="M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7zm0 9.5A2.5 2.5 0 1 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5z" transform="translate(-5 -2)"/></svg>`,
        className: "marker",
        iconSize: point(24, 24),
      }),
    });
    instance.addTo(map);

    if (!hasView) {
      setInitialView(map);
    } else if (!map.getBounds().contains(ll)) {
      map.flyTo(ll, undefined, { animate: true });
    }

    return () => {
      instance.removeFrom(map);
    };
  }, [hasValue, value?.latitude, value?.longitude, hasView, setHasView, setInitialView]);

  return <div ref={divRef} className={clsx(["location-picker"])}></div>;
}
