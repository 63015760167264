import { yupResolver } from "@hookform/resolvers/yup";
import { cloneDeep, set } from "lodash";
import { useEffect, useState } from "react";
import { DeepMap, ErrorOption, FieldError, FieldPath, useForm, useFormState } from "react-hook-form";
import { toast as alert } from "react-toastify";
import { BlogPostModel } from "../../api";
import { getUtcDate, stringFromDate } from "../../services/date";
import { useStateEx } from "../../services/hooks";
import { checkErrors } from "../../services/localization";
import { BlogPostValidator } from "../../services/validators";
import { useDeploymentConfig } from "../../state/deployment";
import { AlertMissingValidationErrors } from "../forms/AlertMissingValidationErrors";
import { ImageUploadButton } from "../forms/ImageUpload";
import { FormInput as Input } from "../forms/Input";
import LanguagePicker from "../forms/LanguagePicker";
import { ValidationMessage } from "../widgets/Alerts";
import { FormModalProps, showErrors } from "./utils";

import "./EditBlogPost.scss";

interface Props extends FormModalProps<BlogPostModel> {
  existingBlogPost: boolean;
}

type State = {
  model: Partial<BlogPostModel>;
  backendErrors: DeepMap<Partial<BlogPostModel>, FieldError>;
};

function EditBlogPost(props: Props) {
  const model: Partial<BlogPostModel> = props.initial || {
    imageUrl: undefined,
    authorImageUrl: undefined,
    authorName: "",
    authorDescription: "",
    date: "",
    titleLocalizations: [],
    leadTextLocalizations: [],
    mainTextLocalizations: [],
  };
  const { state, mergeState } = useStateEx<State>({
    model: model,
    backendErrors: {},
  });

  const defaultModel = cloneDeep(state.model);
  const languageList = useDeploymentConfig().config.allowedLanguages || [];
  if (!defaultModel.titleLocalizations || defaultModel.titleLocalizations.length === 0) {
    defaultModel.titleLocalizations = languageList.map((l) => {
      return { languageCode: l, text: "" };
    });
  }
  if (!defaultModel.leadTextLocalizations || defaultModel.leadTextLocalizations.length === 0) {
    defaultModel.leadTextLocalizations = languageList.map((l) => {
      return { languageCode: l, text: "" };
    });
  }
  if (!defaultModel.mainTextLocalizations || defaultModel.mainTextLocalizations.length === 0) {
    defaultModel.mainTextLocalizations = languageList.map((l) => {
      return { languageCode: l, text: "" };
    });
  }
  if (!defaultModel.date) defaultModel.date = stringFromDate(getUtcDate(), true);

  const [selectedLanguage, setSelectedLanguage] = useState<string>(languageList[0]);
  const { handleSubmit, formState, control, getValues, watch } = useForm<BlogPostModel>({
    defaultValues: defaultModel,
    resolver: yupResolver(BlogPostValidator(languageList)),
  });
  useEffect(() => {
    var subscription = watch(() => {
      if (props.onChange) props.onChange();
    });
    return () => subscription.unsubscribe();
  }, [watch, props]);

  const { errors } = useFormState<BlogPostModel>({ control });

  const titleTranslationIndex = defaultModel.titleLocalizations.findIndex((t) => t.languageCode === selectedLanguage);
  const titleField = `titleLocalizations.${titleTranslationIndex}.text` as "titleLocalizations.0.text";
  const leadTranslationIndex = defaultModel.leadTextLocalizations.findIndex((t) => t.languageCode === selectedLanguage);
  const leadField = `leadTextLocalizations.${leadTranslationIndex}.text` as "leadTextLocalizations.0.text";
  const mainTranslationIndex = defaultModel.mainTextLocalizations.findIndex((t) => t.languageCode === selectedLanguage);
  const mainField = `mainTextLocalizations.${mainTranslationIndex}.text` as "mainTextLocalizations.0.text";

  const cancel = () => {
    if (props.onCancel) props.onCancel();
  };

  const trySaveModel = async (model: BlogPostModel) => {
    if (props.onSave) {
      model = cloneDeep(model);
      const result = await props?.onSave(model);
      if (!result.success) {
        const errors = {};
        showErrors<BlogPostModel>(result, (path: FieldPath<BlogPostModel>, error: ErrorOption) => {
          alert.error(<ValidationMessage message={error.message || "Unknown error"} />);
          set(errors, path, error);
        });
      }
    }
  };

  const getLanguageErrors = (): string[] => {
    if (!errors) return [];
    const errorList = checkErrors(languageList, errors.titleLocalizations).concat(
      checkErrors(languageList, errors.leadTextLocalizations),
      checkErrors(languageList, errors.mainTextLocalizations)
    );
    // remove duplicates
    return errorList.filter((item, index) => errorList.indexOf(item) === index);
  };

  const getTitle = () => {
    return props.existingBlogPost ? "Edit Blog Post" : "New Blog Post";
  };

  const getImageUrl = () => {
    return state.model.imageUrl || "/images/image-placeholder.svg";
  };

  const getAuthorImageUrl = () => {
    return state.model.authorImageUrl || "/images/portrait-placeholder.svg";
  };

  const updateImageUrls = () => {
    mergeState({
      model: { ...state.model, imageUrl: getValues().imageUrl, authorImageUrl: getValues().authorImageUrl },
    });
  };

  return (
    <div className="edit-blog-post">
      <form className="blog-post-form" onSubmit={handleSubmit(trySaveModel)}>
        <AlertMissingValidationErrors control={control} />
        <div className="main">
          <div className="header">
            <h1 className="title">{getTitle()}</h1>
          </div>
          <div className="form-row">
            <LanguagePicker
              language={selectedLanguage}
              errors={getLanguageErrors()}
              onChange={(value) => {
                setSelectedLanguage(value);
              }}
            />
          </div>
          <div className="form-row">
            <Input
              key={titleField}
              name={titleField}
              type="text"
              label=""
              placeholder={`Title`}
              errors={errors}
              register={control.register}
            />
          </div>
          <div className="form-row">
            <Input
              key={leadField}
              name={leadField}
              type="text"
              label=""
              placeholder={`Lead Text`}
              errors={errors}
              register={control.register}
            />
          </div>
          <div className="form-row">
            <Input
              key={mainField}
              name={mainField}
              type="multiline"
              label=""
              placeholder={`Main Text`}
              errors={errors}
              register={control.register}
            />
          </div>
          <div className="image" style={{ backgroundImage: `url(${getImageUrl()})` }}></div>
          <ImageUploadButton
            control={control}
            name="imageUrl"
            title="Upload Post Image"
            instruction="Image format: PNG or JPG max. 2MB"
            onUploaded={updateImageUrls}
          />
          <div className="author-area">
            <div className="image author-image col-2" style={{ backgroundImage: `url(${getAuthorImageUrl()})` }}></div>
            <div className="author-inputs col-9">
              <div className="form-row">
                <Input
                  type="text"
                  name="authorName"
                  placeholder={"Author's name"}
                  errors={errors}
                  register={control.register}
                />
              </div>
              <div className="form-row">
                <Input
                  type="text"
                  name="authorDescription"
                  placeholder={"Author's description"}
                  errors={errors}
                  register={control.register}
                />
              </div>
            </div>
          </div>
          <ImageUploadButton
            control={control}
            name="authorImageUrl"
            title="Upload Author's Image (optional)"
            instruction="Image format: PNG or JPG max. 2MB"
            onUploaded={updateImageUrls}
          />
        </div>
        <div className="footer">
          <button type="submit" disabled={formState.isSubmitting} className="primary save">
            Save
          </button>
          <button type="button" onClick={cancel} className="primary cancel">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditBlogPost;
