export default class LinkService {
  externalLinkTarget: null;
  externalLinkRel: null;
  externalLinkEnabled: boolean;
  pdfDocument: any;
  pdfViewer: any;

  constructor() {
    this.externalLinkTarget = null;
    this.externalLinkRel = null;
    this.externalLinkEnabled = true;
  }

  setDocument(pdfDocument: any) {
    this.pdfDocument = pdfDocument;
  }

  setViewer(pdfViewer: any) {
    this.pdfViewer = pdfViewer;
  }

  // eslint-disable-next-line no-empty, no-empty-function, @typescript-eslint/no-empty-function
  setHistory() {}

  get pagesCount() {
    return this.pdfDocument ? this.pdfDocument.numPages : 0;
  }

  get page() {
    return this.pdfViewer.currentPageNumber;
  }

  set page(value) {
    this.pdfViewer.currentPageNumber = value;
  }

  get rotation() {
    return 0;
  }

  set rotation(value) {}

  async goToDestination(dest: [any | number]) {
    const destRef = dest[0];
    let pageNumber;

    if (destRef instanceof Object) {
      try {
        const pageIndex = await this.pdfDocument.getPageIndex(destRef);
        pageNumber = pageIndex + 1;
      } catch (error) {
        throw new Error(`"${destRef}" is not a valid destination reference.`);
      }
    } else if (typeof destRef === "number") {
      pageNumber = destRef + 1;
    } else {
      throw new Error(`"${destRef}" is not a valid destination reference.`);
    }

    if (!pageNumber || pageNumber < 1 || pageNumber > this.pagesCount) {
      throw new Error(`"${pageNumber}" is not a valid page number.`);
    }

    this.pdfViewer.scrollPageIntoView({
      pageNumber,
    });
  }

  navigateTo(dest: [any | number]) {
    this.goToDestination(dest);
  }

  goToPage() {}

  getDestinationHash() {
    return "#";
  }

  getAnchorUrl() {
    return "#";
  }

  setHash() {}

  executeNamedAction() {}

  cachePageRef() {}

  isPageVisible() {
    return true;
  }

  isPageCached() {
    return true;
  }
}
