import { AxiosResponse } from "axios";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { toast as alert } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { ImportResponse, ProblemDetails } from "../../../api";
import Checkbox from "../../../app/forms/Checkbox";
import { RightArrowNext } from "../../../app/icons/Icons";
import { AlertContent, ServerUnavailable } from "../../../app/widgets/Alerts";
import Loading from "../../../app/widgets/Loading";
import { isHttpOk } from "../../../services/api";
import { fillMissingWithEmpty } from "../../../services/array";
import { useStateEx } from "../../../services/hooks";
import { toCsvValue } from "../../../services/string";
import { DraggableItem } from "../drag-and-drop/DraggableItem";
import { DroppableList } from "../drag-and-drop/DroppableList";
import { ExcelColumnHeader, ExcelData, ExcelRow, ExcelTable, readExcel } from "./ExcelTable";
import { UploadButton } from "./UploadButton";

import "./ExcelUpload.scss";

interface List {
  id: string;
  title: string;
  items: ImportField[];
}

interface ColumnMapping {
  title: string;
  destination: number | null;
}
export interface UploadRequest {
  columnMappings: ColumnMapping[];
  rows: ExcelRow[];
}

enum defaultListType {
  requiredFields = "requiredFields",
  optionalFields = "optionalFields",
}

export interface ImportField {
  id: number;
  name: string;
  tooltip?: string;
  required: boolean;
}

export type ExcelUploadProps = {
  enabled?: boolean;
  title: string;
  entityPlural: string;
  fields: ImportField[];
  onSubmit: (uploadRequest: UploadRequest) => Promise<AxiosResponse<ImportResponse>>;
  stepOneExtraRequirement?: JSX.Element;
};

function downloadCsv(data: string[][]) {
  const csv = data.map((row) => row.map(toCsvValue).join(",")).join("\n");
  let blob = new Blob([csv], { type: "text/csv" });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `failed-rows-${format(Date.now(), "yy.MM.dd-HH:mm")}.csv`;
  link.click();
}

export interface UploadData {
  raw: ExcelData;
  hasHeader: boolean;
  headers: ExcelColumnHeader[];
  rows: ExcelRow[];
}

export function ExcelUpload(props: ExcelUploadProps) {
  const { fields, onSubmit, stepOneExtraRequirement } = props;
  const mappingRowRef = useRef<HTMLTableElement>(null);
  const { state, mergeState } = useStateEx<UploadData | null>(null);
  const [isProcessing, setProcessing] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [state]);

  const allRequiredFields = fields.filter((f) => f.required);
  const allOptionalFields = fields.filter((f) => !f.required);
  const initialLists: List[] = [
    {
      id: defaultListType.requiredFields,
      title: "Required fields:",
      items: allRequiredFields,
    },
    {
      id: defaultListType.optionalFields,
      title: "Optional fields:",
      items: allOptionalFields,
    },
  ];
  const [lists, setLists] = useState<List[]>(initialLists);

  const handleFileSelected = async (file: File) => {
    try {
      setProcessing(true);
      const data = await readExcel(file);
      mergeState({
        raw: data,
        hasHeader: false,
        headers: data.headers,
        rows: data.rows,
      });
      const mappingLists = data.headers.map((column) => ({
        id: column,
        title: "",
        items: [],
      }));
      setLists([...initialLists, ...mappingLists]);
    } catch (reason) {
      if (typeof reason === "string") {
        alert.error(reason);
      } else {
        alert.error("Unspecified error occurred!");
      }
    } finally {
      setProcessing(false);
    }
  };

  const isFieldList = (id: string) => id === defaultListType.requiredFields || id === defaultListType.optionalFields;

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (!destination) {
      return null;
    }

    // Make sure we're actually moving the item
    if (source.droppableId === destination.droppableId && destination.index === source.index) {
      return null;
    }

    const sourceList = lists.find((c) => c.id === source.droppableId);
    let destList = lists.find((c) => c.id === destination.droppableId);
    if (!sourceList || !destList) return null;

    const itemToMove = sourceList.items[source.index];

    // If source is the same as destination, moving within the same list
    if (sourceList === destList) {
      // Start by making a new list without the dragged item
      const newItems = sourceList.items.filter((item) => item !== itemToMove);

      // Then insert the item at the right location
      newItems.splice(destination.index, 0, itemToMove);

      // Then update the list in the lists object
      lists.find((c) => c.id === source.droppableId)!.items = newItems;
    } else {
      // Don't move between required and optional field lists
      if (isFieldList(source.droppableId) && isFieldList(destination.droppableId)) return null;

      // User is returning the field item into the wrong list (duh!), change destination to correct list
      if (
        (destination.droppableId === defaultListType.requiredFields && !allRequiredFields.includes(itemToMove)) ||
        (destination.droppableId === defaultListType.optionalFields && !allOptionalFields.includes(itemToMove))
      ) {
        destination.index = 0;
        destination.droppableId =
          destination.droppableId === defaultListType.optionalFields
            ? defaultListType.requiredFields
            : defaultListType.optionalFields;
        destList = lists.find((c) => c.id === destination.droppableId);
      }

      // Splice the removed item from the source list and save it
      const elementToMove = sourceList.items.splice(source.index, 1)[0];

      // Splice the item into the destination list array at the intended destination index
      destList!.items.splice(destination.index, 0, elementToMove);
    }

    // Update the state
    setLists([...lists]);
  };

  const toggleHasHeader = (hasHeader: boolean) => {
    mergeState((prev) => {
      if (!prev) return;
      var firstRow: ExcelRow = fillMissingWithEmpty(prev.raw.rows[0]);
      return {
        hasHeader: hasHeader,
        rows: hasHeader ? prev.raw.rows.slice(1) : prev.raw.rows,
        headers: hasHeader ? firstRow.slice() : prev.raw.headers,
      };
    });
  };

  const handleSubmit = async () => {
    // No file uploaded
    if (!state || state.rows.length === 0 || !mappingLists || mappingLists.length === 0) {
      alert.error(<AlertContent message="Please upload a spreadsheet first" />);
      return;
    }

    // Check all required fields are assigned
    const requiredFieldsAssigned = allRequiredFields.every((requiredField) =>
      mappingLists.some((m) => m.items.length > 0 && m.items[0] === requiredField)
    );
    if (!requiredFieldsAssigned) {
      alert.error(<AlertContent message="Please assign all required fields." />, {
        autoClose: 5000,
        onClose: () => {
          setShowErrors(false);
        },
      });
      setShowErrors(true);
      return;
    }

    const columnMappings = mappingLists.map<ColumnMapping>((list, index) => ({
      title: state.headers[index],
      destination: list.items.length > 0 ? list.items[0].id : null,
    }));
    const uploadRequest: UploadRequest = {
      columnMappings: columnMappings,
      rows: state.rows,
    };

    try {
      setProcessing(true);
      const response = await onSubmit(uploadRequest);

      if (isHttpOk(response)) {
        const data = response.data;
        if (data.failedRows && (data.nmAddedRows > 0 || data.nmUpdatedRows > 0)) {
          const message = `${data.nmAddedRows} new ${props.entityPlural} were added and ${data.nmUpdatedRows} updated. ${data.nmFailedRows} entries failed - Please fix issues in the new csv file and reupload`;
          alert.warn(<AlertContent message={message} />, { autoClose: 10000 });
          downloadCsv(data.failedRows);
        } else if (data.failedRows) {
          const message = `All data failed to process. Please review and fix issues in the new csv file and reupload.`;
          alert.error(<AlertContent message={message} />, { autoClose: 10000 });
          downloadCsv(data.failedRows);
        } else {
          const message = `All data successfully imported: ${data.nmAddedRows} new ${props.entityPlural} were added and ${data.nmUpdatedRows} updated.`;
          alert.success(<AlertContent message={message} />, { autoClose: 10000 });
        }
      } else {
        let details = response.data as ProblemDetails;
        alert.error(<AlertContent message={details?.title || "Unknown error"} />);
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    } finally {
      setProcessing(false);
    }
  };

  const previewRows = state ? state.rows.slice(0, 101) : [];
  const importFieldLists = lists.filter((l) => isFieldList(l.id));
  const mappingLists = lists.filter((l) => !isFieldList(l.id));
  const loadingClasses = isProcessing ? "loading" : "loading hide-loading";
  const loadingText = isProcessing ? "Processing.." : "Uploading";

  return (
    <div className="excel-upload-area">
      <div className={loadingClasses}>
        <Loading text={loadingText} />
      </div>
      <div className="row-container">
        <h3 className="instructions-one">Step 1: upload a spreadsheet (accepts: csv, xls, xlsx)</h3>
        <button className="secondary" type="button" onClick={() => handleSubmit()} disabled={isProcessing}>
          {"Submit"}
          <RightArrowNext />
        </button>
      </div>
      <div className="upload-button">
        <UploadButton disabled={isProcessing} onFileSelected={handleFileSelected} />
      </div>
      {!!stepOneExtraRequirement && stepOneExtraRequirement}
      <h3 className="instructions-two">
        Step 2: Assign the required fields (and any optional) to the table columns, then Submit
      </h3>
      <DragDropContext onDragEnd={onDragEnd}>
        {importFieldLists.map((list) => (
          <div className="default-lists" key={list.id}>
            <DroppableList id={list.id} title={list.title}>
              {list.items.map((item, index) => (
                <DraggableItem
                  key={item.id}
                  draggableId={item.name}
                  draggableIndex={index}
                  className={showErrors && item.required ? "invalid" : ""}
                  name={item.name}
                  tooltip={{
                    title: "",
                    tooltip: item.tooltip || "",
                  }}
                />
              ))}
            </DroppableList>
          </div>
        ))}
        {state && mappingLists.length > 0 && (
          <>
            <div className="settings">
              <Checkbox
                name="hasHeader"
                label="First row is header row"
                value={state.hasHeader}
                onCheckedChange={toggleHasHeader}
              />
            </div>
            <table className="excel-table mapping-lists" ref={mappingRowRef}>
              <tbody>
                <tr>
                  <td></td>
                  {mappingLists.map((list) => (
                    <td key={list.id}>
                      <DroppableList
                        id={list.id}
                        title={list.title}
                        isFixedWidth={true}
                        disableDrop={list.items.length === 1}
                      >
                        {list.items.map((item, index) => (
                          <DraggableItem
                            draggableId={item.name}
                            draggableIndex={index}
                            key={item.id}
                            name={item.name}
                            tooltip={{
                              title: "",
                              tooltip: item.name,
                            }}
                          />
                        ))}
                      </DroppableList>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
            <ExcelTable
              data={previewRows}
              columns={state.headers}
              className="excel-table"
              headerRowClass="excel-headers"
            />
            <div className="footer">The first 100 rows are shown as a preview</div>
          </>
        )}
      </DragDropContext>
    </div>
  );
}
