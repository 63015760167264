import React, { PropsWithChildren, PureComponent } from "react";
import PropTypes from "prop-types";
import { makeEventProps, makeCancellable, CancellablePromise } from "../services/objects";
import DocumentContext, { DocumentContextType } from "./DocumentContext";
import Message from "./Message";
import LinkService from "./LinkService";
import {
  DocumentInitParameters,
  getDocument,
  PDFDataRangeTransport,
  PDFDocumentLoadingTask,
  PDFDocumentProxy,
} from "./pdfjs";
import {
  cancelRunningTask,
  dataURItoByteString,
  displayCORSWarning,
  errorOnDev,
  isArrayBuffer,
  isBlob,
  isBrowser,
  isDataURI,
  isFile,
  loadFromFile,
  mergeClassNames,
  warnOnDev,
} from "./shared/utils";
import { eventProps, isClassName, isFile as isFileProp, isFunctionOrNode, isRef } from "./shared/propTypes";
import { isEqual } from "lodash";

interface LoadingProcessData {
  loaded: number;
  total: number;
}

type RenderFunction = () => JSX.Element;

export const PasswordResponses = {
  NEED_PASSWORD: "1",
  INCORRECT_PASSWORD: "2",
};

type Props = PropsWithChildren<{
  /**
   * Defines custom class name(s), that will be added to rendered element.
   * @default 'react-pdf__Document'
   */
  className?: string | string[] | undefined;

  /**
   * Defines what the component should display in case of an error.
   * @default 'Failed to load PDF file.'
   */
  error?: string | React.ReactElement | RenderFunction | undefined;

  /**
   * Defines link target for external links rendered in annotations.
   * Defaults to unset, which means that default behavior will be used.
   */
  externalLinkTarget?: "_self" | "_blank" | "_parent" | "_top" | undefined;

  /**
   * Defines what PDF should be displayed.
   * Its value can be an URL,
   * a file (imported using import ... from ... or from file input form element),
   * or an object with parameters
   *  (
   *   url - URL;
   *   data - data, preferably Uint8Array;
   *   range - PDFDataRangeTransport;
   *   httpHeaders - custom request headers, e.g. for authorization
   *   withCredentials - a boolean to indicate whether or not to include cookies in the request (defaults to false)
   *  )
   */
  file: any;

  /**
   * A function that behaves like ref,
   * but it's passed to main `<div>` rendered by `<Document>` component.
   */
  inputRef?: React.LegacyRef<HTMLDivElement> | undefined;

  /**
   * The path used to prefix the src attributes of annotation SVGs.
   */
  imageResourcesPath?: string | undefined;

  /**
   * Defines what the component should display while loading.
   * @default 'Loading PDF...'
   */
  loading?: string | React.ReactElement | RenderFunction | undefined;

  /**
   * Defines what the component should display in case of no data.
   * @default 'No PDF file specified.'
   */
  noData?: string | React.ReactElement | RenderFunction | undefined;

  onItemClick?: (({ pageNumber }: { pageNumber: number }) => void) | undefined;

  onLoadError?: ((error: Error) => void) | undefined;

  onLoadProgress?: ((data: LoadingProcessData) => void) | undefined;

  onLoadSuccess?: ((pdf: PDFDocumentProxy) => void) | undefined;

  onPassword?: ((callback: (password: string) => void, reason: string) => void) | undefined;

  onSourceError?: ((error: Error) => void) | undefined;

  onSourceSuccess?: (() => void) | undefined;

  /**
   * An object in which additional parameters to be passed to PDF.js can be defined.
   * For a full list of possible parameters, check PDF.js documentation on DocumentInitParameters.
   */
  options?: DocumentInitParameters;

  renderMode?: "canvas" | "svg" | "none";
  rotate?: 0 | 90 | 180 | 270;
  foreverLoad?: boolean;
}>;

interface State {
  pdf: PDFDocumentProxy | null | false;
}

export default class Document extends PureComponent<Props, State> {
  static propTypes = {
    ...eventProps,
    children: PropTypes.node,
    className: isClassName,
    error: isFunctionOrNode,
    file: isFileProp,
    imageResourcesPath: PropTypes.string,
    inputRef: isRef,
    loading: isFunctionOrNode,
    noData: isFunctionOrNode,
    onItemClick: PropTypes.func,
    onLoadError: PropTypes.func,
    onLoadProgress: PropTypes.func,
    onLoadSuccess: PropTypes.func,
    onPassword: PropTypes.func,
    onSourceError: PropTypes.func,
    onSourceSuccess: PropTypes.func,
    rotate: PropTypes.number,
  };

  static defaultProps: Partial<Props> = {
    error: "Failed to load PDF file.",
    loading: "Loading PDF...",
    noData: "No PDF file specified.",
    onPassword: (callback, reason) => {
      switch (reason) {
        case PasswordResponses.NEED_PASSWORD: {
          // eslint-disable-next-line no-alert
          const password = prompt("Enter the password to open this PDF file.");
          callback(password || "");
          break;
        }
        case PasswordResponses.INCORRECT_PASSWORD: {
          // eslint-disable-next-line no-alert
          const password = prompt("Invalid password. Please try again.");
          callback(password || "");
          break;
        }
        default:
      }
    },
  };

  constructor(props: Props) {
    super(props);
    this.state = { pdf: null };
  }

  linkService = new LinkService();
  pages: any[] = [];
  loadingTask: PDFDocumentLoadingTask | undefined;
  runningTask: CancellablePromise<PDFDocumentProxy> | undefined;

  viewer = {
    scrollPageIntoView: ({ pageNumber }: { pageNumber: number }) => {
      // Handling jumping to internal links target
      const { onItemClick } = this.props;

      // First, check if custom handling of onItemClick was provided
      if (onItemClick) {
        onItemClick({ pageNumber });
        return;
      }

      // If not, try to look for target page within the <Document>.
      const page = this.pages[pageNumber - 1];

      if (page) {
        // Scroll to the page automatically
        page.scrollIntoView();
        return;
      }

      warnOnDev(
        `Warning: An internal link leading to page ${pageNumber} was clicked, but neither <Document> was provided with onItemClick nor it was able to find the page within itself. Either provide onItemClick to <Document> and handle navigating by yourself or ensure that all pages are rendered within <Document>.`
      );
    },
  };

  componentDidMount() {
    this.loadDocument();
    this.setupLinkService();
  }

  componentDidUpdate(prevProps: Props) {
    const { file } = this.props;
    if (file !== prevProps.file) {
      this.loadDocument();
    }
  }

  componentWillUnmount() {
    // If rendering is in progress, let's cancel it
    cancelRunningTask(this.runningTask);

    // If loading is in progress, let's destroy it
    if (this.loadingTask) this.loadingTask.destroy();
  }

  loadDocument = async () => {
    let source = null;
    try {
      source = await this.findDocumentSource();
      this.onSourceSuccess();
    } catch (error) {
      this.onSourceError(error as Error);
    }

    if (!source) {
      return;
    }

    this.setState((prevState) => {
      if (!prevState.pdf) {
        return null;
      }

      return { pdf: null };
    });

    const { options, onLoadProgress, onPassword } = this.props;

    try {
      // If another rendering is in progress, let's cancel it
      cancelRunningTask(this.runningTask);

      // If another loading is in progress, let's destroy it
      if (this.loadingTask) this.loadingTask.destroy();

      this.loadingTask = getDocument({ ...source, ...options });
      this.loadingTask.onPassword = onPassword;
      if (onLoadProgress) {
        this.loadingTask.onProgress = onLoadProgress;
      }
      const cancellable = makeCancellable(this.loadingTask.promise);
      this.runningTask = cancellable;
      const pdf = await cancellable;

      this.setState((prevState) => {
        if (prevState.pdf && isEqual(prevState.pdf.fingerprints, pdf.fingerprints)) {
          return null;
        }
        return { pdf };
      }, this.onLoadSuccess);
    } catch (error) {
      this.onLoadError(error as Error);
    }
  };

  setupLinkService = () => {
    this.linkService.setViewer(this.viewer);
    const getProps = () => this.props;
    Object.defineProperty(this.linkService, "externalLinkTarget", {
      get() {
        const { externalLinkTarget } = getProps();
        switch (externalLinkTarget) {
          case "_self":
            return 1;
          case "_blank":
            return 2;
          case "_parent":
            return 3;
          case "_top":
            return 4;
          default:
            return 0;
        }
      },
    });
  };

  get childContext(): DocumentContextType {
    const { imageResourcesPath, renderMode, rotate } = this.props;
    const { pdf } = this.state;

    return {
      imageResourcesPath,
      linkService: this.linkService,
      pdf: pdf === null ? undefined : pdf,
      registerPage: this.registerPage,
      renderMode,
      rotate,
      unregisterPage: this.unregisterPage,
    };
  }

  get eventProps() {
    return makeEventProps(this.props, () => this.state.pdf);
  }

  onSourceSuccess = () => {
    const { onSourceSuccess } = this.props;
    if (onSourceSuccess) onSourceSuccess();
  };

  onSourceError = (error: Error) => {
    errorOnDev(error);
    const { onSourceError } = this.props;
    if (onSourceError) onSourceError(error);
  };

  onLoadSuccess = () => {
    const { onLoadSuccess } = this.props;
    const { pdf } = this.state;
    if (pdf) {
      if (onLoadSuccess) onLoadSuccess(pdf);
      this.pages = new Array(pdf.numPages);
      this.linkService.setDocument(pdf);
    }
  };

  onLoadError = (error: Error) => {
    this.setState({ pdf: false });
    errorOnDev(error);
    const { onLoadError } = this.props;
    if (onLoadError) onLoadError(error);
  };

  private async findDocumentSource() {
    const { file } = this.props;

    if (!file) {
      return null;
    }

    // File is a string
    if (typeof file === "string") {
      if (isDataURI(file)) {
        const fileByteString = dataURItoByteString(file);
        return { data: fileByteString };
      }

      displayCORSWarning();
      return { url: file };
    }

    // File is PDFDataRangeTransport
    if (file instanceof PDFDataRangeTransport) {
      return { range: file };
    }

    // File is an ArrayBuffer
    if (isArrayBuffer(file)) {
      return { data: file };
    }

    /**
     * The cases below are browser-only.
     * If you're running on a non-browser environment, these cases will be of no use.
     */
    if (isBrowser) {
      // File is a Blob
      if (isBlob(file) || isFile(file)) {
        return { data: await loadFromFile(file) };
      }
    }

    // At this point, file must be an object
    if (typeof file !== "object") {
      throw new Error("Invalid parameter in file, need either Uint8Array, string or a parameter object");
    }

    if (!file.url && !file.data && !file.range) {
      throw new Error("Invalid parameter object: need either .data, .range or .url");
    }

    // File .url is a string
    if (typeof file.url === "string") {
      if (isDataURI(file.url)) {
        const { url, ...otherParams } = file;
        const fileByteString = dataURItoByteString(url);
        return { data: fileByteString, ...otherParams };
      }

      displayCORSWarning();
    }

    return file;
  }

  registerPage = (pageIndex: number, ref: any) => {
    this.pages[pageIndex] = ref;
  };

  unregisterPage = (pageIndex: number) => {
    delete this.pages[pageIndex];
  };

  renderChildren() {
    const { children } = this.props;

    return <DocumentContext.Provider value={this.childContext}>{children}</DocumentContext.Provider>;
  }

  renderContent() {
    const { file } = this.props;
    const { pdf } = this.state;
    if (!file) {
      const { noData } = this.props;
      return <Message type="no-data">{typeof noData === "function" ? noData() : noData}</Message>;
    }
    if (this.props.foreverLoad || pdf === null) {
      const { loading } = this.props;
      return <Message type="loading">{typeof loading === "function" ? loading() : loading}</Message>;
    }
    if (pdf === false) {
      const { error } = this.props;
      return <Message type="error">{typeof error === "function" ? error() : error}</Message>;
    }
    return this.renderChildren();
  }

  render() {
    const { className, inputRef } = this.props;
    return (
      <div className={mergeClassNames("react-pdf__Document", className)} ref={inputRef} {...this.eventProps}>
        {this.renderContent()}
      </div>
    );
  }
}
