import { useCallback, useMemo, useRef } from "react";
import { useController, useForm } from "react-hook-form";
// eslint-disable-next-line import/no-internal-modules
import { yupResolver } from "@hookform/resolvers/yup";
import pDebounce from "p-debounce";
import { LocationsApi, StoreModel } from "../../api";
import { isHttpOk } from "../../services/api";
import { useEventHandler } from "../../services/hooks";
import { StoreValidator } from "../../services/validators";
import { getApiConfig } from "../../state/configuration";
import { useDeploymentConfig } from "../../state/deployment";
import { ImageUploadButton } from "../forms/ImageUpload";
import { FormInput } from "../forms/Input";
import { LocationPicker, searchAddress } from "../forms/LocationPicker";
import { FormSelectAsync } from "../forms/Select";
import { FormDatePicker } from "../forms/date-picker/FormDatePicker";
import { isError } from "../world-map/Types";
import { FormModalProps, showErrors } from "./utils";

import "./EditStore.scss";

interface Props extends FormModalProps<StoreModel> {}

export default function EditStore(props: Props) {
  const initial = { ...props.initial };

  const addressRef = useRef("");
  const { config } = useDeploymentConfig();
  const { register, control, handleSubmit, formState, setError, setValue, getValues, watch } = useForm<StoreModel>({
    defaultValues: initial,
    resolver: yupResolver(StoreValidator),
  });
  const { field: coordinatesField } = useController({
    name: "coordinates",
    control: control,
  });
  const onDeleteHandler = useEventHandler(props.onDelete);

  const updateBasedOnAddress = useMemo(() => {
    return pDebounce((address: string) => {
      searchAddress(address).then((result) => {
        if (!isError(result) && result.results.length > 0) {
          coordinatesField.onChange({
            latitude: result.results[0].position.lat,
            longitude: result.results[0].position.lon,
          });
        } else {
          // eslint-disable-next-line no-console
          console.error(result);
        }
      });
    }, 400);
  }, [coordinatesField]);

  watch(() => {
    if (props.onChange) props.onChange();
  }, initial);

  watch((model) => {
    if (model && model.street && model.city?.label && model.country?.label) {
      const address = `${model.street}, ${model.city.label}, ${model.country.label}`;
      if (addressRef.current !== address) {
        addressRef.current = address;
        updateBasedOnAddress(address);
      }
    }
  }, initial);

  const resetCityHandler = () => {
    setValue("city", null!);
  };

  const loadCountries = async (input: string) => {
    const config = getApiConfig();
    const api = new LocationsApi(config);
    try {
      const response = await api.getCountries({
        search: input,
        limit: 20,
      });
      if (isHttpOk(response)) {
        return response.data.map((c) => ({ label: c.name || "", value: c.id }));
      } else {
        // eslint-disable-next-line no-console
        console.error("Country lookup returned", response);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return [];
  };

  const loadCities = async (input: string) => {
    const config = getApiConfig();
    const api = new LocationsApi(config);
    const selectedCountry = getValues("country.value") || initial.country?.value;
    if (selectedCountry) {
      try {
        const response = await api.getCitiesForCountry({
          countryId: selectedCountry,
          search: input,
          limit: 20,
        });
        if (isHttpOk(response)) {
          return response.data.map((c) => ({ label: c.name || "", value: c.id }));
        } else {
          // eslint-disable-next-line no-console
          console.error("Country lookup returned", response);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
    return [];
  };

  const handleSave = async (model: StoreModel) => {
    const result = await props.onSave({ ...model });
    if (!result.success) {
      showErrors(result, setError);
    }
  };

  const errors = formState.errors;

  const getImageUrl = useCallback(() => {
    return getValues().imageUrl || "/images/image-placeholder.svg";
  }, [getValues]);

  const handleDelete = async () => {
    if (!onDeleteHandler.current) return;
    if (props.initial === null) return;
    const result = await onDeleteHandler.current(props.initial);
    if (!result.success) {
      showErrors(result, setError);
    }
  };
  const title = props.initial?.id ? `Edit ${config.translations.store.singular}` : "New Store";

  return (
    <div className="store-form">
      <div className="header">
        <h1 className="title">{title}</h1>
      </div>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="form-row name-and-location">
          <FormInput name="name" placeholder="Name" errors={errors} register={register} />
        </div>
        <h2>Contact</h2>
        <div className="form-row">
          <FormInput name="phone" placeholder="Phone" errors={errors} register={register} />
          <FormInput name="fax" placeholder="Fax" errors={errors} register={register} />
        </div>
        <div className="form-row">
          <FormInput name="email1" placeholder="Main E-Mail" errors={errors} register={register} />
          <FormInput name="email2" placeholder="Secondary E-Mail" errors={errors} register={register} />
        </div>
        <div className="form-row">
          <FormInput
            className="manager"
            name="storeManager"
            placeholder="Store Manager"
            errors={errors}
            register={register}
          />
        </div>
        <h2>Location</h2>
        <div className="form-row">
          <FormInput name="street" placeholder="Street" errors={errors} register={register} />
          <FormInput name="zipCode" placeholder="ZIP Code" errors={errors} register={register} />
        </div>
        <div className="form-row">
          <FormSelectAsync
            type="form"
            control={control}
            name="country"
            placeholder="Country"
            errorLabel="Country"
            loadOptions={loadCountries}
            debounceLoadOptions={800}
            required={true}
            isMulti={false}
            onChange={resetCityHandler}
          />
          <FormSelectAsync
            type="form"
            control={control}
            name="city"
            placeholder="City"
            errorLabel="City"
            loadOptions={loadCities}
            debounceLoadOptions={800}
            required={true}
            isMulti={false}
          />
        </div>
        <div className="form-row">
          <LocationPicker value={coordinatesField.value || null} onChange={coordinatesField.onChange} />
        </div>
        <h2>Image</h2>
        <div className="image" style={{ backgroundImage: `url(${getImageUrl()})` }}></div>
        <ImageUploadButton control={control} name="imageUrl" instruction="Image format: PNG or JPG max. 2MB" />
        <h2>Social Media</h2>
        <div className="form-row">
          <FormInput name="instagramUrl" placeholder="Instagram URL" errors={errors} register={register} />
        </div>
        <div className="form-row">
          <FormInput name="facebookUrl" placeholder="Facebook URL" errors={errors} register={register} />
        </div>
        <div className="form-row dates">
          <FormDatePicker
            name="openingDate"
            withTime={false}
            placeholder="Opening"
            errorLabel="Opening date"
            required={false}
            control={control}
          />
          <FormDatePicker
            name="closingDate"
            withTime={false}
            placeholder="Closing"
            errorLabel="Closing date"
            required={false}
            control={control}
          />
        </div>
        <div className="form-row">
          <FormInput className="area" name="squareMeter" placeholder="Area (m2)" errors={errors} register={register} />
        </div>
        <div className="footer">
          {onDeleteHandler.current && props.initial?.id && (
            <button onClick={handleDelete} type="button" className="primary delete">
              Delete
            </button>
          )}
          <button type="button" className="primary cancel" onClick={props.onCancel}>
            Cancel
          </button>
          <button type="submit" disabled={formState.isSubmitting} className="primary save">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
