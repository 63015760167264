import { FieldError } from "react-hook-form";
import { TimeUnit } from "../../../../../api";
import { Select } from "../../../../forms/Select";

type RelativeTimeModel = { amount?: number; toAmount?: number; unit: TimeUnit };

export function RelativeTimeInput(props: {
  isRange?: boolean;
  error?: FieldError;
  value?: RelativeTimeModel | null;
  onChange: (value: RelativeTimeModel) => void;
}) {
  function safeParse(value: number | string) {
    if (value === "") return undefined;
    const safeValue = typeof value === "string" ? parseInt(value, 10) : value;
    if (isNaN(safeValue)) return undefined;
    return safeValue;
  }

  const timeUnitOptions = Object.values(TimeUnit)
    .filter((v) => typeof v === "number")
    .map((value) => ({
      label: `${TimeUnit[value as number]}s ${props.isRange ? "ago" : ""}`,
      value: value,
    }));

  const selectedAmount = props.value?.amount || undefined;
  const selectedToAmount = props.value?.toAmount || undefined;
  const selectedTimeUnit = props.value?.unit ?? TimeUnit.Day;
  const errorClasses = props.isRange ? "error" : "error single-input";

  var fromClassName = "form-input amount";
  if (props.isRange) fromClassName += " reduced";

  return (
    <div className="input-row relative-time">
      <label className={fromClassName}>
        <input
          className="padleft-3px"
          placeholder="#"
          type="number"
          value={selectedAmount}
          onChange={(e) => {
            const amount = safeParse(e.target.value);
            props.onChange({
              amount: amount,
              toAmount: selectedToAmount,
              unit: selectedTimeUnit,
            });
          }}
        />
        {props.error ? <div className={errorClasses}>{props.error.message}</div> : null}
      </label>
      {props.isRange && (
        <>
          <div className="between-dash">-</div>
          <label className="form-input amount">
            <input
              className="padleft-3px"
              placeholder={"#"}
              type="number"
              value={selectedToAmount}
              onChange={(e) => {
                const toAmount = safeParse(e.target.value);
                props.onChange({
                  amount: selectedAmount,
                  toAmount: toAmount,
                  unit: selectedTimeUnit,
                });
              }}
            />
          </label>
        </>
      )}
      <Select
        type="form"
        className={props.isRange ? "range-time-unit" : "time-unit"}
        required={true}
        options={timeUnitOptions}
        value={selectedTimeUnit}
        onChange={(value) => {
          props.onChange({
            amount: selectedAmount,
            toAmount: selectedToAmount,
            unit: value as TimeUnit,
          });
        }}
      />
    </div>
  );
}
