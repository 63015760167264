import pDebounce from "p-debounce";
import React, { useCallback, useMemo, useState } from "react";
import { Input } from "../forms/Input";
import { PageNext, PagePrevious } from "../icons/Icons";

import "./Pagination.scss";

type PaginationProps = {
  pageCount: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  alwaysVisible?: boolean;
};

export const Pagination = (props: PaginationProps) => {
  const { pageCount, onPageChange, currentPage } = props;

  const [page, setPage] = useState<number>(currentPage + 1);
  const [inputValue, setInputValue] = useState<number | undefined>(currentPage + 1);

  const debouncedHandle = useMemo(() => pDebounce(onPageChange, 1000), [onPageChange]);

  const changePage = useCallback(
    (newPage: number | undefined, immediate: boolean = false) => {
      setInputValue(newPage);
      if (newPage === undefined) return;

      if (newPage < 1 || newPage > pageCount) {
        newPage = Math.min(newPage, pageCount);
        newPage = Math.max(newPage, 1);
        setInputValue(newPage);
      }

      setPage(newPage);

      const actualPage = newPage - 1;
      if (immediate) onPageChange(actualPage);
      else debouncedHandle(actualPage);
    },
    [pageCount, onPageChange, debouncedHandle]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: number | undefined = event.target.valueAsNumber;
    if (Number.isNaN(value)) value = undefined;

    changePage(value);
  };

  if (pageCount <= 1 && !props.alwaysVisible) return null;

  const previousDisabled = page <= 1;
  const nextDisabled = page >= pageCount;

  return (
    <ul className="pagination">
      <li className={`${previousDisabled ? "previous disabled" : "previous"}`}>
        <button type="button" tabIndex={0} onClick={() => !previousDisabled && changePage(page - 1, true)}>
          <PagePrevious />
        </button>
      </li>
      <span className="jump-text-before">Page</span>
      <li className="jump">
        <Input className="jump-input" value={inputValue} onChange={handleInputChange} type="integer" />
      </li>
      <span className="jump-text-after">of {pageCount}</span>
      <li className={`${nextDisabled ? "next disabled" : "next"}`}>
        <button type="button" onClick={() => !nextDisabled && changePage(page + 1, true)}>
          <PageNext />
        </button>
      </li>
    </ul>
  );
};
