import { MouseEventHandler } from "react";
import * as api from "../../api";
import Card from "./Card";
import { highlight } from "../widgets/HighlightedString";

import "./FeaturedItemCard.scss";

interface ComponentProps extends api.FeaturedItemView {
  highlightedText?: string;
  onEditClick?: MouseEventHandler;
  onDeleteClick?: MouseEventHandler;
}

export function FeaturedItemCard(props: ComponentProps) {
  const text = props.text;
  const imageUrl = props.imageUrl;
  const imageNode = imageUrl && <img alt="featured-item" src={imageUrl}></img>;

  return (
    <Card className="featured-item-card">
      <div className="figure">{imageNode}</div>
      <div className="description">
        <strong className="status "></strong>
        <h1>{highlight(text, props.highlightedText)}</h1>
        <div className="update-buttons">
          <button onClick={props.onEditClick} className="edit">
            EDIT
          </button>
          <button onClick={props.onDeleteClick} className="delete">
            DELETE
          </button>
        </div>
      </div>
    </Card>
  );
}
