import Card from "../app/cards/Card";
import { LocalGlobal } from "../app/tables/notifications/LocalGlobal";
import { CustomAudienceType } from "../api";
import { AnalyticsPageHeader } from "../app/widgets/PageHeaders";

import "./PushNotificationCampaigns.scss";

export default function PushNotificationCampaigns() {
  return (
    <div className="page-content">
      <AnalyticsPageHeader title="Push Notification Campaigns" />
      <section>
        <Card className="notifications-local">
          <h1>Local Push Notification Campaigns</h1>
          <LocalGlobal audienceType={CustomAudienceType.Local} />
        </Card>
      </section>
      <section>
        <Card className="notifications-global">
          <h1>Global Push Notification Campaigns</h1>
          <LocalGlobal audienceType={CustomAudienceType.Global} />
        </Card>
      </section>
    </div>
  );
}
