import { PropsWithChildren, useCallback } from "react";
import ReactTooltip from "react-tooltip";
import {
  MessageInteractionPerformanceSortColumn,
  MessageInteractionPerformanceView,
  MessageRevenuePerformanceSortColumn,
  MessageRevenuePerformanceView,
  MessagesApi,
  NotificationMark,
  SortDirection,
} from "../../../api";
import { formatPercentage, useMoneyFormatter } from "../../../services/format";
import { useStateEx } from "../../../services/hooks";
import { useLocalizedText } from "../../../services/localization";
import { getImageTooltip } from "../../../services/tooltip";
import { getApiConfig } from "../../../state/configuration";
import { useDeploymentConfig } from "../../../state/deployment";
import { useTimeLocationFilters } from "../../../state/globalFilters";
import { Modal, useModal } from "../../modals/Modal";
import { PaginatedTable } from "../../widgets/PaginatedTable";
import { LabelCampaign } from "./LabelCampaign";
import { NotificationUsersTable } from "./NotificationUsersTable";

import "./NotificationsTable.scss";

interface State {
  isEmpty: boolean;
  messageId?: number;
  flag?: NotificationMark;
}

function InteractionNotifications(props: PropsWithChildren<NotificationsTableProps>) {
  const texts = useLocalizedText();
  const filters = useTimeLocationFilters();
  const { state, mergeState } = useStateEx<State>({
    isEmpty: true,
  });
  const modalId = `interaction-notifications`;
  const { openModal, closeModal } = useModal(modalId);

  const loadPage = useCallback(
    async (skip: number, limit: number) => {
      const config = getApiConfig();
      const api = new MessagesApi(config);
      const result = await api.getInteractionPerformance({
        ...filters,
        limit: limit,
        skip: skip,
        sortColumn: MessageInteractionPerformanceSortColumn.Sent,
        sortDirection: SortDirection.Descending,
      });

      window.setTimeout(() => {
        ReactTooltip.rebuild();
      }, 500);

      mergeState({ isEmpty: result.data.count === 0 });
      return result;
    },
    [filters, mergeState]
  );

  const renderCells = useCallback(
    (item: MessageInteractionPerformanceView, i: number) => {
      const renderCount = (flag: NotificationMark, count: number) => {
        if (count === 0) return count;
        return (
          <span
            onClick={() => {
              mergeState({ messageId: item.id, flag: flag });
              openModal();
            }}
            className="clickable"
          >
            {count}
          </span>
        );
      };
      return [
        <span
          data-for="appTooltip"
          data-tip={getImageTooltip(item.thumbnailUrl || "", item.offerRichText || "", item.offerTitle || undefined)}
        >
          {item.thumbnailUrl && <img alt="thumbnail" src={item.thumbnailUrl} />}
          <LabelCampaign
            id={item.id}
            label={item.label}
            messageTitle={item.offerTitle}
            campaignTitle={item.campaignTitle}
          />
        </span>,
        renderCount(NotificationMark.Sent, item.nmSent),
        renderCount(NotificationMark.Delivered, item.nmDelivered),
        renderCount(NotificationMark.Read, item.nmRead),
        item.hasCallToAction ? renderCount(NotificationMark.Clicked, item.nmClicked) : "N/A",
        <span>{item.hasCallToAction ? formatPercentage(item.clickThroughRate, 0) : "N/A"}</span>,
        renderCount(NotificationMark.Redeemed, item.nmRedeemed),
        <span>{formatPercentage(item.redeemedRate, 0)}</span>,
        item.convertingOfferLabel ? renderCount(NotificationMark.Converted, item.nmConversions) : "-",
        <span>{item.convertingOfferLabel ? formatPercentage(item.conversionRate, 0) : "-"}</span>,
      ];
    },
    [mergeState, openModal]
  );

  return (
    <PaginatedTable
      className="push-notifications-table"
      tableClassName="with-image"
      mainHeader={
        <header>
          <h1>{props.title || "Push Notifications"}</h1>
        </header>
      }
      tableHeaders={[
        texts.notificationColumnHeaders.items.labelAndCampaign,
        texts.notificationColumnHeaders.items.sent,
        texts.notificationColumnHeaders.items.received,
        texts.notificationColumnHeaders.items.read,
        texts.notificationColumnHeaders.items.clicked,
        texts.notificationColumnHeaders.items.clickRate,
        texts.notificationColumnHeaders.items.redeemed,
        texts.notificationColumnHeaders.items.redemptionRate,
        texts.notificationColumnHeaders.items.conversion,
        texts.notificationColumnHeaders.items.conversionRate,
      ]}
      tableDataSource={loadPage}
      tableRowFactory={renderCells}
      minLoadingTimeMs={200}
      hidePagination={props.hidePagination}
      initialPageSize={10}
    >
      {!state.isEmpty && props.children}
      {state.messageId && state.flag && (
        <Modal id={modalId} onRequestClose={closeModal} className="wide-modal">
          <section>
            <NotificationUsersTable messageId={state.messageId} flag={state.flag} />
          </section>
        </Modal>
      )}
    </PaginatedTable>
  );
}

function RevenueNotifications(props: PropsWithChildren<NotificationsTableProps>) {
  const texts = useLocalizedText();
  const filters = useTimeLocationFilters();
  const { formatMoney } = useMoneyFormatter();
  const { state, mergeState } = useStateEx<State>({
    isEmpty: true,
  });
  const modalId = `revenue-notifications`;
  const { openModal, closeModal } = useModal(modalId);

  const loadPage = useCallback(
    async (skip: number, limit: number) => {
      const config = getApiConfig();
      const api = new MessagesApi(config);
      const result = await api.getRevenuePerformance({
        ...filters,
        limit: limit,
        skip: skip,
        sortColumn: MessageRevenuePerformanceSortColumn.Sent,
        sortDirection: SortDirection.Descending,
      });

      window.setTimeout(() => {
        ReactTooltip.rebuild();
      }, 500);

      mergeState({ isEmpty: result.data.count === 0 });
      return result;
    },
    [filters, mergeState]
  );

  const renderCells = useCallback(
    (item: MessageRevenuePerformanceView, i: number) => {
      const renderCount = (flag: NotificationMark, count: number) => {
        if (count === 0) return count;
        return (
          <span
            onClick={() => {
              mergeState({ messageId: item.id, flag: flag });
              openModal();
            }}
            className="clickable"
          >
            {count}
          </span>
        );
      };
      return [
        <span
          data-for="appTooltip"
          data-tip={getImageTooltip(item.thumbnailUrl || "", item.offerRichText || "", item.offerTitle || undefined)}
        >
          {item.thumbnailUrl && <img alt="thumbnail" src={item.thumbnailUrl} />}
          <LabelCampaign
            id={item.id}
            label={item.label}
            messageTitle={item.offerTitle}
            campaignTitle={item.campaignTitle}
          />
        </span>,
        renderCount(NotificationMark.Sent, item.nmSent),
        renderCount(NotificationMark.Delivered, item.nmDelivered),
        renderCount(NotificationMark.Read, item.nmRead),
        renderCount(NotificationMark.Clicked, item.nmClicked),
        renderCount(NotificationMark.Redeemed, item.nmRedeemed),
        item.convertingOfferLabel ? renderCount(NotificationMark.Converted, item.nmConversions) : "-",
        <span>{item.nmSales}</span>,
        <span>{formatMoney(item.revenue)}</span>,
        <span>{formatPercentage(item.salesConversionRate)}</span>,
      ];
    },
    [formatMoney, mergeState, openModal]
  );

  return (
    <PaginatedTable
      className="push-notifications-table"
      tableClassName="with-image"
      mainHeader={
        <header>
          <h1>{props.title || "Push Notifications"}</h1>
        </header>
      }
      tableHeaders={[
        texts.notificationColumnHeaders.items.labelAndCampaign,
        texts.notificationColumnHeaders.items.sent,
        texts.notificationColumnHeaders.items.received,
        texts.notificationColumnHeaders.items.read,
        texts.notificationColumnHeaders.items.clicked,
        texts.notificationColumnHeaders.items.redeemed,
        texts.notificationColumnHeaders.items.conversion,
        texts.notificationColumnHeaders.items.transactions,
        texts.notificationColumnHeaders.items.revenue,
        texts.notificationColumnHeaders.items.salesConversionRate,
      ]}
      tableDataSource={loadPage}
      tableRowFactory={renderCells}
      minLoadingTimeMs={200}
      hidePagination={props.hidePagination}
      initialPageSize={10}
    >
      {!state.isEmpty && props.children}
      {state.messageId && state.flag && (
        <Modal id={modalId} onRequestClose={closeModal} className="wide-modal">
          <section>
            <NotificationUsersTable messageId={state.messageId} flag={state.flag} />
          </section>
        </Modal>
      )}
    </PaginatedTable>
  );
}

type NotificationsTableProps = {
  title?: string;
  hidePagination?: boolean;
};

export function NotificationsTable(props: PropsWithChildren<NotificationsTableProps>) {
  const config = useDeploymentConfig();

  if (config.config.disablePurchaseHistories) {
    return <InteractionNotifications {...props} />;
  } else {
    return <RevenueNotifications {...props} />;
  }
}
