import { useCallback } from "react";
import { ChangeLogApi, PropertyChangeView } from "../../api";
import { logErrorSilently } from "../../services/alerts";
import { isHttpOk } from "../../services/api";
import { addSpaces } from "../../services/string";
import { getApiConfig } from "../../state/configuration";
import Card from "../cards/Card";
import { PaginatedTable } from "../widgets/PaginatedTable";

import "./PropertyChanges.scss";

type Props = {
  entityChangeId: number;
  description: string;
};

export function PropertyChanges(props: Props) {
  const dataSource = useCallback(
    async (offset: number, limit: number) => {
      const api = new ChangeLogApi(getApiConfig());
      const result = await api.getPropertyChanges({ skip: offset, limit: limit, entityChangeId: props.entityChangeId });

      if (isHttpOk(result)) return result.data;
      else {
        logErrorSilently(result);
        return {
          count: 0,
          items: [],
        };
      }
    },
    [props.entityChangeId]
  );

  const tableHeaders = [{ title: "NAME" }, { title: "OLD VALUE" }, { title: "NEW VALUE" }];

  const renderRow = (item: PropertyChangeView) => {
    return [addSpaces(item.name || ""), item.originalValue, item.newValue];
  };

  return (
    <Card className="property-changes-table">
      <PaginatedTable
        mainHeader={
          <section>
            <header>
              <div>
                <h1>{"Changed Properties"}</h1>
                <p className="description">{props.description}</p>
              </div>
            </header>
          </section>
        }
        tableClassName="with-image"
        minLoadingTimeMs={200}
        initialPageSize={10}
        tableDataSource={dataSource}
        tableHeaders={tableHeaders}
        tableRowFactory={renderRow}
      ></PaginatedTable>
    </Card>
  );
}
