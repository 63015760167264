import { useEffect, useRef } from "react";
// eslint-disable-next-line import/no-internal-modules
import { yupResolver } from "@hookform/resolvers/yup";
import { cloneDeep, reduce } from "lodash";
import { FieldError, UseFormReturn, useController, useForm, useFormState, useWatch } from "react-hook-form";
import ReactTooltip from "react-tooltip";
import { CampaignDetails, CustomAudienceExpression, MessageModel, MessageStatus } from "../../../api";
import { useStateEx } from "../../../services/hooks";
import { useLocalizedText } from "../../../services/localization";
import { insertTokenIntoInput, insertTokenIntoQuill } from "../../../services/token";
import { getTooltip } from "../../../services/tooltip";
import { MessageValidator } from "../../../services/validators";
import { useDeploymentConfig } from "../../../state/deployment";
import { MessageImage } from "../../cards/MessageImage";
import { AlertMissingValidationErrors } from "../../forms/AlertMissingValidationErrors";
import { ImageUploadButton } from "../../forms/ImageUpload";
import { FormInput as Input } from "../../forms/Input";
import LanguagePicker from "../../forms/LanguagePicker";
import { ReactQuill } from "../../forms/ReactQuill";
import RichText from "../../forms/RichText";
import { renderScheduleModelWithDuration } from "../../forms/SchedulePicker";
import { FormSelect, Option, Select } from "../../forms/Select";
import { ControlledValidityPicker } from "../../forms/ValidityPicker";
import { CalendarWhite } from "../../icons/Icons";
import { AudienceType } from "./AudienceEditors";
import { AudienceDisplay } from "./AudienceForm";
import { ScheduleWarning } from "./ScheduleForm";

import "./MessageForm.scss";

type State = {
  selectedLanguage: string;
  audienceType: AudienceType | null;
  lastCustomAudience: CustomAudienceExpression | null;
  pushTitleFocused?: boolean;
  pushTextFocused?: boolean;
  offerTitleFocused?: boolean;
  offerTextFocused?: boolean;
};

enum Tokens {
  firstName = "{{firstName}}",
  fullName = "{{fullName}}",
}

type Props = {
  title: string;
  description?: string;
  default: Partial<MessageModel>;
  campaigns: Option[];
  stores: Option[];
  beacons: Option[];
  existingMessages: Option[];
  onSave?: (model: MessageModel) => void;
  onEditAudience?: (current: Partial<MessageModel>) => void;
  onEditDeepLink?: (current: Partial<MessageModel>) => void;
  onEditSchedule?: (current: Partial<MessageModel>) => void;
  onCancel?: () => void;
  onChange?: () => void;
  onSaveDraft?: (model: MessageModel) => void;
  onDiscardDraft?: () => void;
  isDraft?: boolean;
  campaign?: CampaignDetails | undefined;
};

export function MessageForm(props: Props) {
  const formTexts = useLocalizedText().messageForm.items;
  const defaultModel = cloneDeep(props.default);
  const languageList = useDeploymentConfig().config.allowedLanguages || [];
  if (!defaultModel.translations || defaultModel.translations.length === 0) {
    defaultModel.translations = languageList.map((l) => {
      return { languageCode: l, title: "", text: { plain: "", rich: "" } };
    });
  }
  const { state, mergeState } = useStateEx<State>({
    selectedLanguage: languageList[0],
    audienceType: null,
    lastCustomAudience: null,
  });
  const { handleSubmit, formState, control, getValues, setValue, watch } = useForm<MessageModel>({
    defaultValues: defaultModel,
    resolver: yupResolver(MessageValidator(languageList)),
  });
  watch(() => {
    if (props.onChange) props.onChange();
  });

  const scheduleModel = watch("schedule");

  const { field: customAudienceField } = useController({
    control: control,
    name: "customAudience",
  });

  const { field: isRedeemableField } = useController({
    control: control,
    name: "isRedeemable",
  });

  const { field: isSilentField } = useController({
    control: control,
    name: "isSilent",
  });

  useEffect(() => {
    const parts = customAudienceField.value?.parts;
    if (parts && parts.length > 0) {
      // if "allUsers" part is present, All Audience was selected
      const hasAllUsers = parts.some((p) => p.sentence?.allUsers) && parts.length === 1;
      var selectedAudience = hasAllUsers ? AudienceType.All : AudienceType.Custom;
      mergeState({ audienceType: selectedAudience });
    }
  }, [customAudienceField.value, mergeState]);

  const { field: payloadField } = useController({
    control: control,
    name: "deepLinkPayload",
  });
  const hasPayload = !!payloadField.value?.keyValues.length;

  const { errors } = useFormState<MessageModel>({ control: control });

  const translationIndex = defaultModel.translations.findIndex((t) => t.languageCode === state.selectedLanguage);
  const translationFieldPrefix = `translations.${translationIndex}`;
  const pushTitleField = `${translationFieldPrefix}.title` as "translations.0.title";
  const pushTextField = `${translationFieldPrefix}.pushMessage` as "translations.0.pushMessage";
  const offerTitleField = `${translationFieldPrefix}.offerTitle` as "translations.0.offerTitle";
  const offerTextField = `${translationFieldPrefix}.text` as "translations.0.text";
  const ctaTitleField = `${translationFieldPrefix}.callToActionTitle` as "translations.0.callToActionTitle";
  const ctaLinkField = `${translationFieldPrefix}.callToActionLink` as "translations.0.callToActionLink";

  const textQuillRef = useRef<ReactQuill>(null);

  const getCurrentModel = () => {
    const formValues = getValues();
    return { ...defaultModel, ...formValues };
  };
  const handleEditAudience = () => {
    if (props.onEditAudience) props.onEditAudience(getCurrentModel());
  };
  const handleEditDeepLink = () => {
    if (props.onEditDeepLink) props.onEditDeepLink(getCurrentModel());
  };
  const handleEditSchedule = () => {
    if (props.onEditSchedule) props.onEditSchedule(getCurrentModel());
  };
  const handleSaveDraft = () => {
    if (props.onSaveDraft) props.onSaveDraft(getCurrentModel());
  };
  const handleSave = (model: MessageModel) => {
    if (props.onSave) props.onSave(model);
  };

  const schedule = defaultModel.schedule;
  const isScheduled =
    schedule?.constrainHours || schedule?.duringPeriod || schedule?.onlyOnce || schedule?.indefiniteFrom;
  const audienceOptions: Option[] = [
    { label: "All", value: AudienceType.All },
    { label: "Custom", value: AudienceType.Custom },
  ];

  const scheduleDateTime = renderScheduleModelWithDuration(schedule).split("|");

  const getLanguageErrors = (): string[] => {
    if (errors && errors.translations) {
      if (Array.isArray(errors.translations)) {
        return reduce(
          errors.translations,
          (invalidLanguages, translation, index) => {
            if (translation && (translation.languageCode || translation.text || translation.title)) {
              invalidLanguages.push(languageList[index]);
            }
            return invalidLanguages;
          },
          [] as string[]
        );
      } else {
        return languageList;
      }
    }
    return [];
  };

  const defaultTokens = [Tokens.firstName, Tokens.fullName];
  const getTokenDisplay = (token: Tokens) => {
    switch (token) {
      case Tokens.fullName:
        return "Full Name";
      case Tokens.firstName:
      default:
        return "First Name";
    }
  };
  const insertToken = (token: string) => {
    if (state.pushTitleFocused || state.pushTextFocused || state.offerTitleFocused) {
      const field = state.pushTitleFocused ? pushTitleField : state.pushTextFocused ? pushTextField : offerTitleField;
      const elements = document.getElementsByName(field);
      if (elements.length > 0) {
        const input = elements[0] as HTMLInputElement;
        const setFieldValue = (value: string) => setValue(field, value);
        insertTokenIntoInput(input, token, setFieldValue);
      }
    } else if (state.offerTextFocused) {
      const quill = textQuillRef.current;
      if (quill) insertTokenIntoQuill(quill, token);
    }
  };
  ReactTooltip.rebuild();

  return (
    <form className="message-form" onSubmit={handleSubmit(handleSave)}>
      <AlertMissingValidationErrors control={control} />
      <div className="left">
        <div className="header">
          <h1 className="title">{props.title}</h1>
          {props.description && <p className="description">{props.description}</p>}
        </div>
        <div className="form-row">
          <AudienceDisplay
            audienceType={state.audienceType ?? AudienceType.Custom}
            audience={customAudienceField.value}
            total={defaultModel.estimatedAudience}
          />
        </div>
        <div className="form-row">
          <Input
            type="text"
            className="top-control"
            name="label"
            placeholder={"Label (Optional)"}
            errors={errors}
            register={control.register}
          />
          <FormSelect
            type="form"
            className="top-controls"
            name="convertingOfferId"
            options={props.existingMessages}
            placeholder="Conversion Tracking (Opt.)"
            required={false}
            clearText="None"
            control={control}
          />
          <FormSelect
            type="form"
            className="top-controls"
            name="campaign.value"
            options={props.campaigns}
            placeholder="Select Campaign"
            required={true}
            control={control}
          />
          <Select
            value={state.audienceType}
            className="top-controls"
            type="form"
            options={audienceOptions}
            placeholder="Select Audience"
            required={true}
            onChange={(value) => {
              const enumValue = value as AudienceType;

              const parts = customAudienceField.value?.parts;
              let customAudienceParts = parts?.filter((c) => !c.sentence || !c.sentence.allUsers) ?? [];
              // Save last custom audience parts in case user is switching between All/Custom
              mergeState({ audienceType: enumValue, lastCustomAudience: { parts: customAudienceParts } });

              if (enumValue === AudienceType.Custom) {
                if (customAudienceParts.length === 0) customAudienceParts = state.lastCustomAudience?.parts ?? [];
                customAudienceField.onChange({
                  parts: customAudienceParts,
                });
                handleEditAudience();
              } else if (enumValue === AudienceType.All) {
                customAudienceField.onChange({
                  parts: [{ type: 1, sentence: { allUsers: {} } }],
                });
              }
            }}
          />
        </div>
        <div className="left-sub-pane">
          <div className="form-row">
            <LanguagePicker
              language={state.selectedLanguage}
              errors={getLanguageErrors()}
              onChange={(value) => {
                mergeState({ selectedLanguage: value });
              }}
            />
          </div>
          <div className="tokens flex-row" data-for="appTooltip" data-tip={getTooltip(formTexts.tokens.tooltip)}>
            <div className="lead-text">Token:</div>
            {defaultTokens.map((token, index) => (
              <div
                key={index}
                className="token drag-item"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => insertToken(token)}
              >
                {getTokenDisplay(token)}
              </div>
            ))}
          </div>
          <div className="form-row" data-for="appTooltip" data-tip={getTooltip(formTexts.title.tooltip)}>
            <Input
              key={pushTitleField}
              name={pushTitleField}
              type="text"
              placeholder={`Push Title`}
              errors={errors}
              onBlur={() => mergeState({ pushTitleFocused: false })}
              onFocus={() => mergeState({ pushTitleFocused: true })}
              register={control.register}
            />
          </div>
          <div className="form-row" data-for="appTooltip" data-tip={getTooltip(formTexts.pushText.tooltip)}>
            <Input
              key={pushTextField}
              name={pushTextField}
              type="text"
              placeholder={`Push Text`}
              errors={errors}
              onBlur={() => mergeState({ pushTextFocused: false })}
              onFocus={() => mergeState({ pushTextFocused: true })}
              register={control.register}
            />
          </div>
          <div className="form-row" data-for="appTooltip" data-tip={getTooltip(formTexts.offerTitle.tooltip)}>
            <Input
              key={offerTitleField}
              name={offerTitleField}
              type="text"
              placeholder={`Offer Title`}
              errors={errors}
              onBlur={() => mergeState({ offerTitleFocused: false })}
              onFocus={() => mergeState({ offerTitleFocused: true })}
              register={control.register}
            />
          </div>
          <div className="form-row" data-for="appTooltip" data-tip={getTooltip(formTexts.offerText.tooltip)}>
            <RichText
              quillRef={textQuillRef}
              key={offerTextField}
              name={offerTextField}
              control={control}
              placeholder={`Full Offer Text`}
              errorLabel={`Full Offer Text`}
              onBlur={() => mergeState({ offerTextFocused: false })}
              onFocus={() => mergeState({ offerTextFocused: true })}
            />
          </div>
          <div className="form-row call-to-action">
            <Input
              type="text"
              key={ctaTitleField}
              name={ctaTitleField}
              placeholder={"Call to action"}
              errors={errors}
              register={control.register}
            />
            <Input
              type="text"
              key={ctaLinkField}
              name={ctaLinkField}
              placeholder={"https://"}
              errors={errors}
              register={control.register}
            />
            <button type="button" onClick={handleEditDeepLink} className="primary">
              {hasPayload ? "Edit Deep Link" : "Add Deep Link"}
            </button>
          </div>
          <div className="schedule">
            <ScheduleWarning schedule={scheduleModel} campaign={props.campaign} />
            <div className="form-row">
              <button className="primary schedule-button" type="button" onClick={handleEditSchedule}>
                <CalendarWhite /> {!isScheduled ? "Set Schedule" : "Edit Schedule"}
              </button>
              <div className="schedule-text">
                Schedule: {!isScheduled && <i className="not-schedule">(Not Selected)</i>}
                {scheduleDateTime[1]}
                {!schedule ? "" : scheduleDateTime[0]}
                <div>{errors.schedule && <span className="error">{(errors.schedule as FieldError).message}</span>}</div>
              </div>
            </div>
          </div>
          <div className="form-row validity-pane">
            <ControlledValidityPicker name="validity" control={control} customIcon="clock" />
          </div>
          <div className="form-row">
            <label className="toggle">
              <input
                className="toggle-checkbox"
                type="checkbox"
                checked={isRedeemableField.value}
                onChange={(e) => isRedeemableField.onChange(e.target.checked)}
              />
              <div className="toggle-switch"></div>
              <span className="toggle-label">Redeem</span>
            </label>
          </div>
          <div className="form-row">
            <label className="toggle">
              <input
                className="toggle-checkbox"
                type="checkbox"
                checked={!isSilentField.value}
                onChange={(e) => isSilentField.onChange(!e.target.checked)}
              />
              <div className="toggle-switch"></div>
              <span className="toggle-label">Notify customer</span>
            </label>
          </div>
          <div className="form-row action-button-row">
            <div className="button-pane">
              {props.isDraft && (
                <button type="button" onClick={props.onDiscardDraft} className="primary">
                  Discard Draft
                </button>
              )}
              <button type="button" onClick={handleSaveDraft} className="primary">
                Save as Draft
              </button>
              <button type="button" onClick={props.onCancel} className="primary cancel">
                Cancel
              </button>
              <button type="submit" disabled={formState.isSubmitting} className="primary save mr-0">
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="form-row"></div>
        <MessagePreview control={control} translationFieldPrefix={translationFieldPrefix} />
        <ImageUploadButton
          control={control}
          name="imageUrl"
          grayscaleName="grayImageUrl"
          thumbnailName="thumbnailUrl"
          grayscaleThumbnailName="grayThumbnailUrl"
          instruction="Image format: PNG or JPG max. 2MB"
        />
      </div>
    </form>
  );
}

function MessagePreview(props: { control: UseFormReturn<MessageModel>["control"]; translationFieldPrefix: string }) {
  const translationPrefix = props.translationFieldPrefix;
  const [imageUrl, grayImageUrl, isRedeemable, pushTitle, pushText, offerTitle, offerText, buttonTitle, buttonUrl] =
    useWatch({
      name: [
        "imageUrl",
        "grayImageUrl",
        "isRedeemable",
        `${translationPrefix}.title` as "translations.0.title",
        `${translationPrefix}.pushMessage` as "translations.0.pushMessage",
        `${translationPrefix}.offerTitle` as "translations.0.offerTitle",
        `${translationPrefix}.text` as "translations.0.text",
        `${translationPrefix}.callToActionTitle` as "translations.0.callToActionTitle",
        `${translationPrefix}.callToActionLink` as "translations.0.callToActionLink",
      ],
      control: props.control,
    });

  return (
    <MessageImage
      status={MessageStatus.Live}
      imageUrl={imageUrl}
      grayImageUrl={grayImageUrl}
      pushTitle={pushTitle}
      pushText={pushText}
      offerTitle={offerTitle}
      offerRichText={offerText?.rich}
      showPageSelector={true}
      callToActionTitle={buttonTitle}
      callToActionLink={buttonUrl}
      isRedeemable={isRedeemable}
      disclaimer="Actual notification and offer may differ on your phone."
    />
  );
}
