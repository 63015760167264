import { AxiosResponse } from "axios";
import clsx from "clsx";
import { isArray, range } from "lodash";
import React, { PropsWithChildren, useCallback, useImperativeHandle, useRef, useState } from "react";
import { SortDirection } from "../../api";
import { isAxiosResponse } from "../../services/api";
import { getTooltip } from "../../services/tooltip";
import { DataPage } from "../../services/types";
import { Select } from "../forms/Select";
import { Sort, SortDown, SortUp } from "../icons/Icons";
import Loading from "../widgets/Loading";
import { PaginatedViewApi, usePaginatedView } from "./PaginatedView";
import { Pagination } from "./Pagination";

import "./PaginatedTable.scss";

export type PaginatedTableApi = PaginatedViewApi;

type DataSourceReturn<TModel> = Promise<AxiosResponse<DataPage<TModel>>> | Promise<DataPage<TModel>> | DataPage<TModel>;

export type Header = {
  title: string;
  tooltip?: string;
  className?: string;
  sortable?: boolean;
  sortDirection?: SortDirection;
};

type PaginatedTableProps<TModel> = {
  apiRef?: React.Ref<PaginatedViewApi>;
  mainHeader?: string | React.ReactNode; //TODO - join this with title and decouple the name from headers
  className?: string;
  initialPageSize?: number | number[];
  tableClassName?: string;
  tableHeaders: Header[];
  tableDataSource: (offset: number, limit: number) => DataSourceReturn<TModel>;
  tableRowFactory: (model: TModel, index: number) => React.ReactNode[];
  onTotalCountChange?: (count: number) => void;
  onHeaderClick?: (index: number) => void;
  minLoadingTimeMs?: number;
  hidePagination?: boolean;
  forceShowProgress?: boolean;
  hideLoadingAnimation?: boolean;
};

function getDefaultPageSize(props: PaginatedTableProps<any>) {
  if (props.initialPageSize === undefined) return 6;
  if (isArray(props.initialPageSize)) {
    return props.initialPageSize.length > 0 ? props.initialPageSize[0] : 6;
  } else {
    return props.initialPageSize;
  }
}

function sortIcon(direction?: SortDirection) {
  return direction === SortDirection.None ? (
    <Sort />
  ) : direction === SortDirection.Descending ? (
    <SortDown />
  ) : direction === SortDirection.Ascending ? (
    <SortUp />
  ) : null;
}

export function PaginatedTable<TModel>(props: PropsWithChildren<PaginatedTableProps<TModel>>) {
  const [pageSize, setPageSize] = useState(getDefaultPageSize(props));

  // freeze reference to headerClick handler
  const headerClick = useRef(props.onHeaderClick);
  headerClick.current = props.onHeaderClick;

  const dataSource = props.tableDataSource;
  const loadCallback = useCallback(
    async (page: number) => {
      const skip = page * pageSize;
      const result = await dataSource(skip, pageSize);
      if (isAxiosResponse(result)) {
        return result.data;
      } else {
        return result;
      }
    },
    [dataSource, pageSize]
  );

  const { state, refreshPage, loadPage, reset } = usePaginatedView({
    load: loadCallback,
    forceShowProgress: props.forceShowProgress,
    minLoadingTimeMs: props.minLoadingTimeMs,
    onTotalCountChange: props.onTotalCountChange,
  });

  useImperativeHandle(
    props.apiRef,
    () => {
      return {
        refreshPage,
        loadPage,
        reset,
      };
    },
    [refreshPage, loadPage, reset]
  );

  const pageCount = Math.ceil(state.totalCount / pageSize);
  const isLoading = state.loading && !props.hideLoadingAnimation;

  const currentPageSize = () => {
    if (state.totalCount === 0) {
      return pageSize;
    } else if (state.currentPage < pageCount - 1) {
      return pageSize;
    } else {
      return state.totalCount - (pageCount - 1) * pageSize;
    }
  };

  const renderFakeRows = () => {
    const rows = range(0, currentPageSize()).map((i) => {
      return props.tableHeaders.map((h, j) => <td key={j}>&nbsp;</td>);
    });
    return rows.map((row, i) => <tr key={i}>{row}</tr>);
  };

  const renderActualRows = () => {
    const rows = state.items.map((rowModel, i) => {
      const cells = props.tableRowFactory(rowModel, i);
      return cells.map((cell, j) => <td key={j}>{cell}</td>);
    });
    return rows.map((row, i) => <tr key={i}>{row}</tr>);
  };

  const renderRows = () => {
    if (isLoading) {
      return renderFakeRows();
    } else {
      return renderActualRows();
    }
  };

  function renderHeader(header: Header, index: number) {
    return (
      <th
        key={index}
        className={clsx(header.className, { sortable: header.sortable })}
        onClick={() => {
          if (header.sortable && headerClick.current) {
            headerClick.current(index);
          }
        }}
      >
        <span data-for="appTooltip" data-tip={getTooltip(header.tooltip ?? header.title)}>
          <span>{header.title}</span>
          {header.sortable ? sortIcon(header.sortDirection) : null}
        </span>
      </th>
    );
  }

  const renderTableHeader = () => {
    if (typeof props.mainHeader !== "string") {
      return props.mainHeader;
    } else if (props.mainHeader.length > 0) {
      return (
        <header className="paginated-table-header">
          <h1>{props.mainHeader}</h1>
        </header>
      );
    }
  };

  const randomElementId = "paginatedTable" + Math.random();
  const sectionRef = useRef<HTMLElement>(null);

  const renderPagination = () => {
    if (props.hidePagination) {
      return null;
    }

    let pageSelector: JSX.Element | null = null;
    if (pageCount > 0 && isArray(props.initialPageSize)) {
      const options = props.initialPageSize.map((p) => ({ label: `${p} / page`, value: p }));
      pageSelector = (
        <Select
          type="form"
          value={pageSize}
          required={true}
          options={options}
          onChange={(value) => typeof value === "number" && setPageSize(value)}
        />
      );
    }

    function pageChangeHandler(page: number) {
      loadPage(page);
      scrollToElementTop();
    }

    // Scrolls the page to the header top of the table.
    function scrollToElementTop() {
      var globalHeaderHeight = 80;
      var element = sectionRef.current;
      var tableTopPosition = element ? element.offsetTop - globalHeaderHeight : 0;

      // Works for tables in a modal.
      element?.scrollIntoView();

      // Works for tables on a page.
      window.scroll(0, tableTopPosition);
    }

    const pagination =
      pageCount > 0 ? (
        <Pagination currentPage={state.currentPage} onPageChange={pageChangeHandler} pageCount={pageCount} />
      ) : null;

    if (!pagination && !pageSelector) {
      return null;
    } else {
      return (
        <>
          {pagination}
          {pageSelector}
        </>
      );
    }
  };

  const classes = ["paginated-table", props.className];
  const tableClasses = [];
  if (isLoading) tableClasses.push("loading");
  if (props.tableClassName) tableClasses.push(props.tableClassName);

  const pagination = renderPagination();
  const showFooter = pagination !== null || props.children !== null;

  return (
    <section id={randomElementId} ref={sectionRef} className={classes.join(" ")}>
      {isLoading ? <Loading /> : null}
      {renderTableHeader()}
      <table className={tableClasses.join(" ")}>
        <thead>
          <tr>{props.tableHeaders.map(renderHeader)}</tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
      {showFooter && (
        <footer>
          {renderPagination()}
          {props.children}
        </footer>
      )}
    </section>
  );
}
