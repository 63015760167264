import { differenceInMilliseconds } from "date-fns";
import { useCallback, useEffect, useRef } from "react";
import { toast as alert } from "react-toastify";
import { useStateEx } from "../../services/hooks";
import { DataPage } from "../../services/types";
import { AlertContent } from "../widgets/Alerts";

export type PaginatedViewOptions<TModel> = {
  load: LoadPage<TModel>;
  onTotalCountChange?: (count: number) => void;
  minLoadingTimeMs?: number;
  forceShowProgress?: boolean;
};

type LoadPage<TModel> = (page: number) => Promise<DataPage<TModel>>;

type State<TModel> = {
  totalCount: number;
  currentPage: number;
  items: TModel[];
  loading: boolean;
};

export interface PaginatedViewApi {
  reset: () => void;
  refreshPage: () => void;
  loadPage: (page: number, force?: boolean) => void;
}

type PaginatedViewReturn<TModel> = {
  state: State<TModel>;
  reset: () => void;
  refreshPage: () => void;
  loadPage: (page: number, force?: boolean) => void;
};

export function usePaginatedView<TModel>({
  load,
  forceShowProgress,
  minLoadingTimeMs,
  onTotalCountChange,
}: PaginatedViewOptions<TModel>): PaginatedViewReturn<TModel> {
  const { state, mergeState } = useStateEx({
    totalCount: 0,
    currentPage: 0,
    items: [] as TModel[],
    loading: true,
  });

  const countTracking = useRef({
    lastCount: -1,
    changeHandler: onTotalCountChange,
  });

  const wasUnmounted = useRef(false);
  useEffect(() => {
    return () => {
      wasUnmounted.current = true;
    };
  }, []);

  const handleLoadedPage = useCallback(
    (loadedPage: number, result: DataPage<TModel>) => {
      if (wasUnmounted.current) return;
      const totalCount = result.count || result.items?.length || 0;
      mergeState((prev) => {
        if (loadedPage === prev.currentPage) {
          return {
            loading: false,
            items: result.items || [],
            totalCount: totalCount,
          };
        } else {
          return prev;
        }
      });

      if (countTracking.current.lastCount !== totalCount) {
        countTracking.current = {
          lastCount: totalCount,
          changeHandler: countTracking.current.changeHandler,
        };
        if (countTracking.current.changeHandler) countTracking.current.changeHandler(totalCount);
      }
    },
    [mergeState]
  );

  const reset = () => {
    mergeState({ currentPage: 0 });
  };

  const resetView = useRef(reset);

  const loadPage = useCallback(
    async (page: number) => {
      mergeState({
        loading: true,
      });
      const loadingStart = Date.now();
      try {
        const result = await load(page);
        if (result.count > 0 && result.items?.length === 0) resetView.current();
        if (!wasUnmounted.current && !forceShowProgress) {
          const minLoadingTime = minLoadingTimeMs || 0;
          const loadingEnd = Date.now();
          const leftover = minLoadingTime - differenceInMilliseconds(loadingEnd, loadingStart);
          if (leftover > 0) {
            window.setTimeout(() => handleLoadedPage(page, result), leftover);
          } else {
            handleLoadedPage(page, result);
          }
        }
      } catch (error) {
        alert.error(<AlertContent message="Server unavailable. Please try again shortly." diagnosticError={error} />);
      }
    },
    [load, minLoadingTimeMs, forceShowProgress, mergeState, handleLoadedPage, resetView]
  );

  useEffect(() => {
    loadPage(state.currentPage);
  }, [state.currentPage, loadPage]);

  const refreshPageApi = async () => {
    try {
      const result = await load(state.currentPage);
      handleLoadedPage(state.currentPage, result);
    } catch (error) {
      alert.error(<AlertContent message="Server unavailable. Please try again shortly." diagnosticError={error} />);
    }
  };

  const loadPageApi = (page: number, force?: boolean) => {
    const shouldReload = state.currentPage === page && force;
    mergeState({ currentPage: page });
    if (shouldReload) loadPage(page);
  };

  return {
    state: state,
    reset: reset,
    refreshPage: refreshPageApi,
    loadPage: loadPageApi,
  };
}
