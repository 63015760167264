import { isArray, union } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { toast as alert } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { DiagnosticsRow, TrackingApi } from "../../api";
import { OptionValueType, Select } from "../../app/forms/Select";
import { HeaderRow } from "../../app/icons/Icons";
import { AlertContent, ValidationMessage } from "../../app/widgets/Alerts";
import { PaginatedTable } from "../../app/widgets/PaginatedTable";
import { confirmDialog } from "../../services/alerts";
import { addSpaces, capitalize } from "../../services/string";
import { getTooltip } from "../../services/tooltip";
import { getApiConfig } from "../../state/configuration";

import "./DiagnosticsTable.scss";

export type DiagnosticsTableProps = {
  userId: string;
  platformDeviceId?: string;
};

export function DiagnosticsTable(props: DiagnosticsTableProps) {
  const localStorageKey = "diagnostics-saved-columns";
  const [headers, setHeaders] = useState<string[]>([]);

  const defaultHeaders = ["UtcTime", "sdkVersion", "battery", "location", "missingPermissions"];
  const initial = loadSavedColumnsOrDefault();
  const [savedHeaders, setSavedHeaders] = useState<string[]>(initial);

  const loadPage = useCallback(
    async (skip: number, limit: number) => {
      const config = getApiConfig();
      const api = new TrackingApi(config);
      const response = await api.getDiagnostics({
        skip: skip,
        limit: limit,
        userId: props.userId,
        platformDeviceId: props.platformDeviceId,
      });
      const result = response.data;
      setHeaders(result.columnHeaders || []);
      window.setTimeout(ReactTooltip.rebuild, 100);
      return result.data || { items: [], count: 0 };
    },
    [props, setHeaders]
  );

  function loadSavedColumnsOrDefault() {
    const saved = localStorage.getItem(localStorageKey);
    const parsed = saved && JSON.parse(saved);
    if (parsed && isArray(parsed)) {
      return parsed as string[];
    }
    return defaultHeaders;
  }

  const changeColumns = (values: OptionValueType[]) => {
    const newSelection = values as string[];
    if (!newSelection || newSelection.length === 0)
      return alert.error(<ValidationMessage message="Table needs at least one column!" />);
    setSavedHeaders(newSelection);
    localStorage.setItem(localStorageKey, JSON.stringify(newSelection));
  };

  const options = union(initial, defaultHeaders, headers).map((h) => ({
    label: addSpaces(capitalize(h)),
    value: h,
  }));
  const columnDropdown = (
    <Select
      type="filter"
      isMulti={true}
      required={true}
      icon={<HeaderRow />}
      className="saved-headers"
      multiValueItemLabel="columns"
      options={options}
      value={savedHeaders}
      onChange={changeColumns}
    />
  );

  const loadPageOnce = useMemo(() => loadPage, [loadPage]);

  const renderCells = useCallback(
    (item: DiagnosticsRow) =>
      savedHeaders.map((h) => {
        const index = headers.indexOf(h);
        if (index === -1) return "";
        const text = item.row?.at(index) || "";
        return (
          <span data-for="appTooltip" data-tip={getTooltip(text)}>
            {text}
          </span>
        );
      }),
    [headers, savedHeaders]
  );

  const deleteDiagnostics = async () => {
    if (props.userId && props.platformDeviceId) {
      const config = getApiConfig();
      const api = new TrackingApi(config);
      try {
        await api.deleteDiagnostics({
          userId: props.userId,
          platformDeviceId: props.platformDeviceId,
        });
        alert.success(<AlertContent message="Device Diagnostics deleted!" />);
        return;
      } catch {}
    }
    alert.error(<AlertContent message="Failed to delete diagnostics." />);
  };

  const title = `Device Diagnostics`;
  const userIdDescription = `User Id: ${props.userId}`;
  const deviceIdDescription = `Platform Device Id: ${props.platformDeviceId}`;

  const deleteButton = (
    <button
      className="primary delete"
      disabled={!props.platformDeviceId}
      onClick={() => confirmDialog("Delete all diagnostics data for this device?", deleteDiagnostics)}
    >
      Delete Diagnostics
    </button>
  );
  return (
    <PaginatedTable
      className="diagnostics-table"
      mainHeader={
        <header>
          <div>
            <h1>{title}</h1>
            <p className="description">{userIdDescription}</p>
            <p className="description" data-for="appTooltip" data-tip={getTooltip(deviceIdDescription)}>
              {deviceIdDescription.length > 20 ? `${deviceIdDescription.substring(0, 20)}...` : deviceIdDescription}
            </p>
          </div>
          <div className="top-right-side">
            {columnDropdown}
            {deleteButton}
          </div>
        </header>
      }
      tableHeaders={savedHeaders.map((h) => ({ title: addSpaces(capitalize(h)) }))}
      tableDataSource={loadPageOnce}
      tableRowFactory={renderCells}
      minLoadingTimeMs={0}
      initialPageSize={10}
      hideLoadingAnimation={true}
    />
  );
}
