import { format } from "date-fns";
import { useEffect } from "react";
import { Frequency, StoreVisitsStatistic, VisitorStatisticsApi } from "../api";
import Card from "../app/cards/Card";
import { Graph, GraphMouseEvent } from "../app/widgets/Graph";
import { isHttpOk } from "../services/api";
import { useStateEx } from "../services/hooks";
import { getApiConfig } from "../state/configuration";
import { useDeploymentConfig } from "../state/deployment";
import { useTimeLocationFilters } from "../state/globalFilters";

import "./CustomerVisitsGraph.scss";

interface State {
  visitStatistics: StoreVisitsStatistic[];
  frequency: Frequency;
}

export function CustomerVisitsGraph(props: { onclick?: (event: GraphMouseEvent) => void }) {
  const filters = useTimeLocationFilters();
  const { config } = useDeploymentConfig();
  const { state, mergeState } = useStateEx<State>({
    visitStatistics: [],
    frequency: Frequency.Daily,
  });

  useEffect(() => {
    const config = getApiConfig();
    const api = new VisitorStatisticsApi(config);

    api.getVisitStatistics(filters).then((result) => {
      if (isHttpOk(result)) {
        mergeState({
          visitStatistics: result.data.storeVisitsStatistics || [],
          frequency: result.data.frequency ?? Frequency.Daily,
        });
      }
    });
  }, [filters, mergeState]);

  const title = !config.disablePurchaseHistories ? "Customer Visits" : "Visitors";
  const tooltip = "Frequency chart of the number of store visits vs date";
  const dateFormat = state.frequency === Frequency.Hourly ? "haa d/M" : "dd.MM.yyyy";
  const chartData =
    state.visitStatistics?.map((item) => {
      return { x: format(new Date(item.date), dateFormat), y: item.nmOfVisits };
    }) ?? [];

  var manualDateTicks: string[] | undefined;
  const nmOfDatapoints = state.visitStatistics?.length ?? 0;
  // If more than 3 months of Daily data, set ticks to first day of month
  const useManualDateTicks = nmOfDatapoints > 90 && state.frequency === Frequency.Daily;
  if (useManualDateTicks) {
    manualDateTicks = state.visitStatistics
      ?.filter((item) => new Date(item.date).getDate() === 1)
      .map((item) => format(new Date(item.date), dateFormat));
  }

  return (
    <Card className="graph">
      <Graph
        title={title}
        data={chartData}
        titleTooltip={tooltip}
        yAxisName="Visits"
        manualTicks={manualDateTicks}
        onClick={props.onclick}
      />
    </Card>
  );
}
