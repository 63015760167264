import { cloneDeep, set } from "lodash";
import { useEffect } from "react";
import { DeepMap, ErrorOption, FieldError, FieldPath } from "react-hook-form";
import { toast as alert } from "react-toastify";
import {
  BeaconLookupView,
  BeaconsApi,
  BrandLookupView,
  CampaignDetails,
  CampaignLookupView,
  CampaignsApi,
  CustomAudienceExpression,
  DeepLinkPayload,
  MessageLookupView,
  MessageModel,
  MessagesApi,
  OfferAudienceApi,
  PurchaseHistoryApi,
  ScheduleModel,
  StoreLookupView,
  StoresApi,
} from "../../api";
import { useStateEx } from "../../services/hooks";
import { isProvided } from "../../services/objects";
import { getApiConfig } from "../../state/configuration";
import { useDeploymentConfig } from "../../state/deployment";
import { ServerUnavailable, ValidationMessage } from "../widgets/Alerts";
import Loading from "../widgets/Loading";
import { AudienceForm } from "./forms/AudienceForm";
import { DeepLinkForm } from "./forms/DeepLinkForm";
import { MessageForm } from "./forms/MessageForm";
import { ScheduleForm } from "./forms/ScheduleForm";
import { FormModalProps, ResultType, showErrors } from "./utils";

import "./EditMessage.scss";

enum WizardState {
  EditMessage,
  EditAudience,
  EditDeepLink,
  EditSchedule,
  Loading,
}

interface Props extends FormModalProps<MessageModel> {
  initialCampaignId?: number;
  existingMessageId?: number;
  existingDraftId?: number;
  onSaveDraft: (model: MessageModel, draftId?: number) => Promise<ResultType>;
  onDiscardDraft: () => Promise<ResultType>;
}

type State = {
  campaigns: CampaignLookupView[];
  brands: BrandLookupView[];
  stores: StoreLookupView[];
  beacons: BeaconLookupView[];
  messages: MessageLookupView[];
  currency: string;
  model: Partial<MessageModel>;
  backendErrors: DeepMap<Partial<MessageModel>, FieldError>;
  wizardState: WizardState;
  selectedCampaign?: CampaignDetails;
};

function EditMessage(props: Props) {
  const model: Partial<MessageModel> = props.initial || {
    campaign:
      props.initialCampaignId !== undefined
        ? {
            label: "",
            value: props.initialCampaignId,
          }
        : undefined,
    customAudience: {
      parts: [],
    },
  };

  const { state, mergeState } = useStateEx<State>({
    campaigns: [],
    brands: [],
    stores: [],
    beacons: [],
    messages: [],
    currency: "",
    wizardState: WizardState.Loading,
    model: model,
    backendErrors: {},
  });
  const deploymentConfig = useDeploymentConfig().config;
  const currency = deploymentConfig.defaultCurrency?.code || "";

  useEffect(() => {
    (async () => {
      const config = getApiConfig();
      try {
        const campaignApi = new CampaignsApi(config);
        const campaigns = campaignApi.getForLookup();
        const campaignId = state.model?.campaign?.value;
        const selectedCampaign = campaignId ? (await campaignApi.getOne({ id: campaignId })).data : undefined;
        const stores = new StoresApi(config).getForLookup();
        const beacons = new BeaconsApi(config).getForLookup();
        const messages = new MessagesApi(config).getForLookup();
        const brands = new PurchaseHistoryApi(config).getBrandsForLookup();
        mergeState({
          campaigns: (await campaigns).data || [],
          brands: (await brands).data || [],
          stores: (await stores).data || [],
          beacons: (await beacons).data || [],
          messages: (await messages).data || [],
          selectedCampaign: selectedCampaign,
          currency: currency,
          wizardState: WizardState.EditMessage,
        });
      } catch (error) {
        alert.error(<ServerUnavailable message="Unable to fetch campaign list! Please check your connection." />);
      }
    })();
  }, [mergeState, currency, state.model?.campaign?.value]);

  const editAudience = (model: Partial<MessageModel>) =>
    mergeState({ model: model, wizardState: WizardState.EditAudience });
  const saveAudience = async (audience: CustomAudienceExpression) => {
    var estimate: number | undefined;
    if (audience?.parts && audience.parts?.length > 0) {
      const config = getApiConfig();
      const api = new OfferAudienceApi(config);
      estimate = (await api.getAudienceEstimate({ customAudienceExpression: audience })).data;
    }
    mergeState((prev) => ({
      wizardState: WizardState.EditMessage,
      model: { ...prev.model, customAudience: audience, estimatedAudience: estimate },
    }));
  };
  const editDeepLink = (model: Partial<MessageModel>) =>
    mergeState({ model: model, wizardState: WizardState.EditDeepLink });
  const saveDeepLink = (payload: DeepLinkPayload) => {
    mergeState((prev) => ({
      wizardState: WizardState.EditMessage,
      model: { ...prev.model, deepLinkPayload: payload },
    }));
  };
  const editSchedule = (model: Partial<MessageModel>) =>
    mergeState({ model: model, wizardState: WizardState.EditSchedule });
  const saveSchedule = (schedule: ScheduleModel) => {
    mergeState((prev) => ({
      wizardState: WizardState.EditMessage,
      model: { ...prev.model, schedule: schedule },
    }));
  };

  const cancelWizardStep = () => {
    if (state.wizardState === WizardState.EditMessage) {
      if (props.onCancel) props.onCancel();
    } else {
      mergeState({ wizardState: WizardState.EditMessage });
    }
  };

  const trySaveModel = async (model: MessageModel) => {
    if (props.onSave) {
      model = cloneDeep(model);
      const result = await props?.onSave(model);
      if (!result.success) {
        const errors = {};
        showErrors<MessageModel>(result, (path: FieldPath<MessageModel>, error: ErrorOption) => {
          alert.error(<ValidationMessage message={error.message || "Unknown error"} />);
          set(errors, path, error);
        });
        mergeState({ wizardState: WizardState.EditMessage });
      }
    }
  };

  const saveDraft = async (model: MessageModel, draftId?: number) => {
    if (props.onSaveDraft) {
      const result = await props.onSaveDraft(model, draftId);
      if (!result.success) {
        alert.error(<ValidationMessage message={"Could not save draft"} />);
        mergeState({ wizardState: WizardState.EditMessage });
      }
    }
  };

  const discardDraft = async () => {
    if (props.onDiscardDraft) {
      const result = await props.onDiscardDraft();
      if (!result.success) {
        alert.error(<ValidationMessage message={"Could not discard draft"} />);
        mergeState({ wizardState: WizardState.EditMessage });
      }
    }
  };

  const getMessageEditorTitle = () => {
    return props.existingMessageId ? "Edit Message" : "New Message";
  };
  const getMessageEditorDescription = () => {
    let description = "";
    if (props.existingDraftId) {
      description += props.existingMessageId ? "Has draft changes" : "Unpublished draft";
    }
    return description;
  };

  const getEditor = () => {
    const campaigns = state.campaigns.map((c) => ({
      label: c.name!,
      value: c.id,
    }));
    const storeOptions = state.stores.map((s) => ({ label: s.name!, value: s.id! }));
    const brandOptions = state.brands.map((s) => ({ label: s.brand!, value: s.brand! }));
    const beaconOptions = state.beacons.map((b) => ({
      label: b.name!,
      value: b.id!,
      description: isProvided(b.area) ? `in ${b.area}, ${b.store}` : `in ${b.store}`,
    }));
    const currentMessageId = props.existingMessageId;
    const messageOptions = state.messages
      .filter((m) => m.id !== currentMessageId && (!m.isAConvertingOffer || m.id === state.model.convertingOfferId))
      .map((msg) => ({
        label: `[${msg.id}] ${msg.name || ""}`,
        description: msg.campaign || undefined,
        value: msg.id,
        deactivated: msg.deactivated,
      }));

    const id = props.existingMessageId ? `[${props.existingMessageId}] ` : "";
    const labelOrTitle = model.label || model.translations?.at(0)?.title || "";
    const messageIdentifier = id + labelOrTitle;

    if (state.wizardState === WizardState.EditMessage) {
      return (
        <MessageForm
          title={getMessageEditorTitle()}
          description={getMessageEditorDescription()}
          default={state.model}
          campaigns={campaigns}
          stores={storeOptions}
          beacons={beaconOptions}
          existingMessages={messageOptions}
          onCancel={props.onCancel}
          onSave={trySaveModel}
          onEditAudience={editAudience}
          onEditDeepLink={editDeepLink}
          onEditSchedule={editSchedule}
          onChange={props.onChange}
          onSaveDraft={saveDraft}
          onDiscardDraft={discardDraft}
          isDraft={!!props.existingDraftId}
          campaign={state.selectedCampaign}
        />
      );
    } else if (state.wizardState === WizardState.EditAudience) {
      return (
        <AudienceForm
          title={"Custom Audience Builder"}
          identifier={messageIdentifier}
          default={state.model.customAudience || undefined}
          beacons={beaconOptions}
          stores={storeOptions}
          brands={brandOptions}
          currency={state.currency}
          onCancel={cancelWizardStep}
          onSave={saveAudience}
        />
      );
    } else if (state.wizardState === WizardState.EditDeepLink) {
      return (
        <DeepLinkForm
          title={"Deep Link Payload"}
          identifier={messageIdentifier}
          default={state.model.deepLinkPayload || undefined}
          onCancel={cancelWizardStep}
          onSave={saveDeepLink}
        />
      );
    } else if (state.wizardState === WizardState.EditSchedule) {
      return (
        <ScheduleForm
          title={"Sending Schedule"}
          identifier={messageIdentifier}
          value={state.model.schedule}
          onCancel={cancelWizardStep}
          onSave={saveSchedule}
          campaign={state.selectedCampaign}
        />
      );
    } else {
      return (
        <div className="loading">
          <Loading />
        </div>
      );
    }
  };

  return <div className="edit-message">{getEditor()}</div>;
}

export default EditMessage;
