import { useEffect } from "react";
import { MessageStatus, MessagesApi, PhoneOS } from "../../api";
import { isHttpOk } from "../../services/api";
import { useStateEx } from "../../services/hooks";
import { getApiConfig } from "../../state/configuration";
import { AndroidLogo, AppleLogo } from "../icons/Icons";

import "./MessageImage.scss";

type State = {
  platform: PhoneOS;
  page: PhonePage;
  previewHtml: string;
};

enum PhonePage {
  OfferPage,
  NotificationPage,
}

export function MessageImage(props: {
  isRedeemable: boolean;
  pushTitle?: string | null;
  pushText?: string | null;
  offerTitle?: string | null;
  offerRichText?: string | null;
  status?: MessageStatus | null;
  imageUrl?: string | null;
  grayImageUrl?: string | null;
  callToActionTitle?: string | null;
  callToActionLink?: string | null;
  disclaimer?: string;
  showPageSelector?: boolean | false;
}) {
  const placeholderImage = "/images/image-placeholder.svg";

  const image = (() => {
    if (
      props.status !== undefined &&
      props.status !== null &&
      props.status !== MessageStatus.Live &&
      props.status !== MessageStatus.Scheduled
    ) {
      return props.grayImageUrl || props.imageUrl || placeholderImage;
    }
    return props.imageUrl || placeholderImage;
  })();

  const defaultPage = props.showPageSelector ? PhonePage.NotificationPage : PhonePage.OfferPage;
  const { state, mergeState } = useStateEx<State>({ platform: PhoneOS.iOS, page: defaultPage, previewHtml: "" });

  const page = state.page;
  const platform = state.platform;

  const iOSSelectClass = "ios-select" + (platform === PhoneOS.iOS ? " selected" : "");
  const androidSelectClass = "android-select" + (platform === PhoneOS.Android ? " selected" : "");
  const notificationPageClass = "notification-page-select" + (page === PhonePage.NotificationPage ? " selected" : "");
  const offerPageClass = "offer-page-select" + (page === PhonePage.OfferPage ? " selected" : "");
  const notificationContentClass = "notification-content" + (platform === PhoneOS.Android ? " android" : " ios");

  const getPushTitle = () => {
    const title = props.pushTitle;
    if (!title) return title;
    switch (platform) {
      case PhoneOS.Android:
        return title.length > 100 ? title.substring(0, 100) + "..." : title;
      case PhoneOS.iOS:
      default:
        return title.length > 100 ? title.substring(0, 100) + "..." : title;
    }
  };
  const getPushText = () => {
    const text = props.pushText;
    if (!text) return text;
    switch (platform) {
      case PhoneOS.Android:
        return text.length > 120 ? text.substring(0, 120) + "..." : text;
      case PhoneOS.iOS:
      default:
        return text.length > 150 ? text.substring(0, 150) + "..." : text;
    }
  };
  const getOfferRichText = () => {
    const richText = props.offerRichText;
    if (!richText) return "";
    const withoutHtmlTags = richText.replace(/(<([^>]+)>)/gi, "");
    if (withoutHtmlTags.length === 0) return "";
    return richText;
  };
  const getOfferPageFrame = () => (platform === PhoneOS.Android ? "samsung-transparent.png" : "iphone-transparent.png");
  const getNotificationPageFrame = () => (platform === PhoneOS.Android ? "pn-samsung.jpg" : "pn-iphone.png");

  const offerRichText = getOfferRichText() || "No offer text";

  const { isRedeemable, callToActionTitle, callToActionLink, offerTitle } = props;

  useEffect(() => {
    const config = getApiConfig();
    const api = new MessagesApi(config);
    var request = {
      imageUrl: image,
      isRedeemable: isRedeemable,
      callToActionTitle: callToActionTitle ?? undefined,
      callToActionLink: callToActionLink ?? undefined,
      offerTitle: offerTitle || "",
      offerText: offerRichText,
      disableOnclicks: true,
    };
    api.getOfferPreview(request).then((result) => {
      if (isHttpOk(result)) {
        mergeState({
          previewHtml: result.data,
        });
      }
    });
  }, [image, isRedeemable, callToActionTitle, callToActionLink, offerTitle, offerRichText, mergeState]);

  return (
    <div className="message-image">
      {props.showPageSelector && (
        <>
          <div className="platform-selector">
            <div className={iOSSelectClass} onClick={() => mergeState({ platform: PhoneOS.iOS })}>
              <AppleLogo />
              Apple
            </div>
            <div className={androidSelectClass} onClick={() => mergeState({ platform: PhoneOS.Android })}>
              <AndroidLogo />
              Android
            </div>
          </div>
          <div className="page-selector">
            <div
              className={notificationPageClass}
              onClick={() => mergeState({ page: PhonePage.NotificationPage })}
            ></div>
            <div className={offerPageClass} onClick={() => mergeState({ page: PhonePage.OfferPage })}></div>
          </div>
        </>
      )}
      <div className="fixed-aspect fixed-frame">
        <canvas width="10" height="20" />
        {page === PhonePage.NotificationPage ? (
          <div className="frame" style={{ backgroundImage: `url(/images/${getNotificationPageFrame()})` }}>
            <div className={notificationContentClass}>
              <div className="app-icon" style={{ backgroundImage: "url(/images/bm.png)" }}></div>
              <div>
                <strong className="title">{getPushTitle() || "No title"}</strong>
                <div className="push-message">{getPushText() || "No push message text"}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="frame">
            <div className="frame-img" style={{ backgroundImage: `url(/images/${getOfferPageFrame()})` }}></div>
            <iframe className="preview" title="preview" srcDoc={state.previewHtml} frameBorder="0"></iframe>
          </div>
        )}
      </div>
      {props.disclaimer && <div className="disclaimer">{props.disclaimer}</div>}
    </div>
  );
}
