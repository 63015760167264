import { editorClassName, EditorProps } from "./common";
import { NumberOrPercentInput } from "./inputs/NumberOrPercentInput";

export function MostActiveCustomersEditor(props: EditorProps) {
  const model = props.value.mostActiveCustomers || {
    topPercentage: 10,
  };

  return (
    <div className={editorClassName(props, "most-active-customers")}>
      <h2>{props.description}</h2>
      <NumberOrPercentInput
        value={{
          value: model.minNumberOfPurchases || model.topPercentage || 0,
          isPercent: typeof model.topPercentage === "number",
        }}
        onChange={(v) => {
          props.onChange({
            mostActiveCustomers: {
              minNumberOfPurchases: v.isPercent ? undefined : v.value,
              topPercentage: v.isPercent ? v.value : undefined,
            },
          });
        }}
        error={props.error?.minNumberOfPurchases || props.error?.topPercentage}
      />
    </div>
  );
}
