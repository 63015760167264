import { debounce } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useStateEx } from "../../services/hooks";
import { useGlobalFilters, useIsSingleStore } from "../../state/globalFilters";
import { DateRange } from "../forms/DateRange";
import { Select } from "../forms/Select";
import { AllStores, BeaconArea, Pin } from "../icons/Icons";

import "./TimeLocation.scss";

const pinIcon = <Pin />;
const allStoresIcon = <AllStores />;
const areaIcon = <BeaconArea />;

type TimeLocationProps = {
  disableStoreMultiSelect?: boolean;
  defaultToFirstStore?: boolean;
  hideLocationFilters?: boolean;
};

export const TimeLocation = (props: TimeLocationProps) => {
  const isSingleStore = useIsSingleStore();
  const { selectCountries, selectStores, selectAreas, selectTime, state: filters } = useGlobalFilters();

  type FilterType = {
    countryIds?: number[];
    storeIds?: number[];
    areaIds?: number[];
  };

  const { state, mergeState } = useStateEx<FilterType>({
    countryIds: filters.selectedCountries,
  });

  const debouncedFilterState = useMemo(
    () =>
      debounce((newFilters: FilterType) => {
        if (newFilters.countryIds) selectCountries(newFilters.countryIds);
        if (newFilters.storeIds) selectStores(newFilters.storeIds);
        if (newFilters.areaIds) selectAreas(newFilters.areaIds);
      }, 700),
    [selectAreas, selectCountries, selectStores]
  );

  const debounceStateHandler = useCallback(
    (newFilters: FilterType) => {
      if (newFilters.countryIds) mergeState({ countryIds: newFilters.countryIds });
      if (newFilters.storeIds) mergeState({ storeIds: newFilters.storeIds });
      if (newFilters.areaIds) mergeState({ areaIds: newFilters.areaIds });

      debouncedFilterState(newFilters);
    },
    [debouncedFilterState, mergeState]
  );

  useEffect(() => {
    const stores = filters.topFilterStores;
    if (props.defaultToFirstStore) {
      if (stores?.length > 0) debounceStateHandler({ storeIds: [stores[0].value as number] });
    }
  }, [props.defaultToFirstStore, selectStores, debounceStateHandler, filters.topFilterStores]);

  const showStoreCountryFilters = !isSingleStore && !props.hideLocationFilters;
  const showAreaFilter = isSingleStore && !props.hideLocationFilters;

  return (
    <div className="time-location-filters">
      <div className="filter-item">
        <DateRange value={filters.selectedTime} onChange={selectTime} />
      </div>
      {showStoreCountryFilters && (
        <>
          <div className="filter-item">
            <Select
              type="filter"
              placeholder={"Location"}
              clearText="All Locations"
              isMulti={true}
              multiValueItemLabel="locations"
              options={filters.topFilterCountries}
              icon={pinIcon}
              value={state.countryIds || []}
              onChange={(ids) => debounceStateHandler({ ...state, countryIds: ids as number[] })}
            />
          </div>
          <div className="filter-item">
            {!props.disableStoreMultiSelect ? (
              <Select
                isMulti={true}
                type="filter"
                icon={allStoresIcon}
                placeholder={"Stores"}
                clearText="All Stores"
                multiValueItemLabel="stores"
                options={filters.topFilterStores}
                value={state.storeIds || []}
                onChange={(ids) => debounceStateHandler({ ...state, storeIds: ids as number[] })}
              />
            ) : (
              <Select
                isMulti={false}
                type="filter"
                required={true}
                icon={allStoresIcon}
                placeholder={"Store"}
                options={filters.topFilterStores}
                value={state.storeIds && state.storeIds.length > 0 ? state.storeIds[0] : null}
                onChange={(id) => debounceStateHandler({ ...state, storeIds: [id as number] })}
              />
            )}
          </div>
        </>
      )}
      {showAreaFilter && (
        <div className="filter-item">
          <Select
            type="filter"
            placeholder={"Areas"}
            clearText="All Areas"
            isMulti={true}
            multiValueItemLabel="areas"
            options={filters.topFilterAreas}
            icon={areaIcon}
            value={state.areaIds || []}
            onChange={(ids) => debounceStateHandler({ ...state, areaIds: ids as number[] })}
          />
        </div>
      )}
    </div>
  );
};
