import React, { Component, PropsWithChildren } from "react";
import cx, { ClassValue } from "clsx";

export type TabListProps = PropsWithChildren<{
  className?: ClassValue;
}>;

export default class TabList extends Component<TabListProps> {
  public static tabsRole = "TabList";

  render() {
    const { children, className, ...attributes } = this.props;

    return (
      <ul {...attributes} className={cx(className)} role="tablist">
        {children}
      </ul>
    );
  }
}
