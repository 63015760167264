import { yupResolver } from "@hookform/resolvers/yup";
import { cloneDeep, set } from "lodash";
import { useEffect, useState } from "react";
import { DeepMap, ErrorOption, FieldError, FieldPath, useForm, useFormState } from "react-hook-form";
import { toast as alert } from "react-toastify";
import { ProductCategoryModel } from "../../api";
import { useStateEx } from "../../services/hooks";
import { checkErrors } from "../../services/localization";
import { ProductCategoryValidator } from "../../services/validators";
import { useDeploymentConfig } from "../../state/deployment";
import { AlertMissingValidationErrors } from "../forms/AlertMissingValidationErrors";
import { ImageUploadButton } from "../forms/ImageUpload";
import { FormInput as Input } from "../forms/Input";
import LanguagePicker from "../forms/LanguagePicker";
import { ValidationMessage } from "../widgets/Alerts";
import { FormModalProps, showErrors } from "./utils";

import "./EditProductCategory.scss";

interface Props extends FormModalProps<ProductCategoryModel> {
  existingCategory: boolean;
}

type State = {
  model: Partial<ProductCategoryModel>;
  backendErrors: DeepMap<Partial<ProductCategoryModel>, FieldError>;
};

function EditProductCategory(props: Props) {
  const model: Partial<ProductCategoryModel> = props.initial || {
    nameLocalizations: [],
  };
  const { state, mergeState } = useStateEx<State>({
    model: model,
    backendErrors: {},
  });

  const defaultModel = cloneDeep(state.model);
  const languageList = useDeploymentConfig().config.allowedLanguages || [];
  if (!defaultModel.nameLocalizations || defaultModel.nameLocalizations.length === 0) {
    defaultModel.nameLocalizations = languageList.map((l) => {
      return { languageCode: l, text: "" };
    });
  }

  const [selectedLanguage, setSelectedLanguage] = useState<string>(languageList[0]);
  const { handleSubmit, formState, control, getValues, watch } = useForm<ProductCategoryModel>({
    defaultValues: defaultModel,
    resolver: yupResolver(ProductCategoryValidator(languageList)),
  });
  useEffect(() => {
    var subscription = watch(() => {
      if (props.onChange) props.onChange();
    });
    return () => subscription.unsubscribe();
  }, [watch, props]);

  const { errors } = useFormState<ProductCategoryModel>({ control });

  const translationIndex = defaultModel.nameLocalizations.findIndex((t) => t.languageCode === selectedLanguage);
  const nameField = `nameLocalizations.${translationIndex}.text` as "nameLocalizations.0.text";

  const cancel = () => {
    if (props.onCancel) props.onCancel();
  };

  const trySaveModel = async (model: ProductCategoryModel) => {
    if (props.onSave) {
      model = cloneDeep(model);
      const result = await props?.onSave(model);
      if (!result.success) {
        const errors = {};
        showErrors<ProductCategoryModel>(result, (path: FieldPath<ProductCategoryModel>, error: ErrorOption) => {
          alert.error(<ValidationMessage message={error.message || "Unknown error"} />);
          set(errors, path, error);
        });
      }
    }
  };

  const getLanguageErrors = (): string[] => (errors ? checkErrors(languageList, errors.nameLocalizations) : []);

  const getTitle = () => (props.existingCategory ? "Edit Category" : "New Category");

  const getImageUrl = () => state.model.imageUrl || "/images/image-placeholder.svg";

  const updateImageUrl = () => {
    mergeState({
      model: { ...state.model, imageUrl: getValues().imageUrl },
    });
  };

  return (
    <div className="edit-category">
      <form className="category-form" onSubmit={handleSubmit(trySaveModel)}>
        <AlertMissingValidationErrors control={control} />
        <div className="main">
          <div className="header">
            <h1 className="title">{getTitle()}</h1>
          </div>
          <div className="form-row">
            <LanguagePicker
              language={selectedLanguage}
              errors={getLanguageErrors()}
              onChange={(value) => {
                setSelectedLanguage(value);
              }}
            />
          </div>
          <div className="form-row">
            <Input
              key={nameField}
              name={nameField}
              type="text"
              label=""
              placeholder={`Name`}
              errors={errors}
              register={control.register}
            />
          </div>
          <div className="form-row">
            <Input name="url" type="text" placeholder={"Url"} errors={errors} register={control.register} />
          </div>
          <div className="image" style={{ backgroundImage: `url(${getImageUrl()})` }}></div>
          <ImageUploadButton
            control={control}
            name="imageUrl"
            instruction="Image format: PNG or JPG max. 2MB"
            onUploaded={updateImageUrl}
          />
        </div>
        <div className="footer">
          <button type="submit" disabled={formState.isSubmitting} className="primary save">
            Save
          </button>
          <button type="button" onClick={cancel} className="primary cancel">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditProductCategory;
