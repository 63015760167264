import { useCallback } from "react";
import { BeaconsApi } from "../../api";
import { logErrorSilently } from "../../services/alerts";
import { isHttpOk } from "../../services/api";
import { formatApproximateDuration } from "../../services/format";
import { useStateEx } from "../../services/hooks";
import { getApiConfig } from "../../state/configuration";
import { useTimeLocationFilters } from "../../state/globalFilters";
import Card from "../cards/Card";
import { Modal, useModal } from "../modals/Modal";
import { PaginatedTable } from "../widgets/PaginatedTable";
import { BeaconDataTable, Metric } from "./BeaconDataTable";

import "./TopBeacons.scss";

function getColumnHeader(selectedValue: Metric) {
  switch (selectedValue) {
    case Metric.MaxTime:
      return "LONGEST TIME SPENT";
    case Metric.AverageTime:
      return `TOP AVERAGE TIME SPENT`;
    default:
      return "SHORTEST TIME SPENT";
  }
}

function TopBeacons(props: { showFooter?: boolean }) {
  const metricOptions = [
    {
      label: "Longest Time Spent",
      value: Metric.MaxTime,
    },
    {
      label: "Average Time Spent",
      value: Metric.AverageTime,
    },
    {
      label: "Shortest Time Spent",
      value: Metric.MinimumTime,
    },
  ];

  const filters = useTimeLocationFilters();
  const { state, mergeState } = useStateEx({ selectedValue: metricOptions[0].value, isEmpty: true });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      mergeState({ selectedValue: value as Metric });
    }
  };

  const dataSource = useCallback(async () => {
    function getData() {
      const api = new BeaconsApi(getApiConfig());
      switch (state.selectedValue) {
        case Metric.MinimumTime:
          return api.getByMinimumTimeSpent({ size: 5, ...filters });
        case Metric.AverageTime:
          return api.getByAverageTimeSpent({ size: 5, ...filters });
        default:
          return api.getByMaximumTimeSpent({ size: 5, ...filters });
      }
    }

    const result = await getData();
    if (isHttpOk(result)) {
      mergeState({ isEmpty: result.data.count === 0 });
      return result.data;
    } else {
      mergeState({ isEmpty: true });
      logErrorSilently(result);
      return {
        count: 0,
        items: [],
      };
    }
  }, [filters, state.selectedValue, mergeState]);

  const tableHeaders = [{ title: "BEACONS" }, { title: getColumnHeader(state.selectedValue) }];

  const modalId = "top-beacons";
  const { openModal, closeModal } = useModal(modalId);

  return (
    <Card>
      <PaginatedTable
        mainHeader={
          <header>
            <h1>Top Beacons</h1>
            <select value={state.selectedValue} onChange={handleChange}>
              {metricOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </header>
        }
        className="beacons-table"
        minLoadingTimeMs={200}
        initialPageSize={5}
        hidePagination={true}
        tableDataSource={dataSource}
        tableHeaders={tableHeaders}
        tableRowFactory={(item, index) => {
          return [item.name, formatApproximateDuration(item.value)];
        }}
      >
        {props.showFooter && !state.isEmpty && (
          <div className="show-all" onClick={() => openModal()}>
            Show all
          </div>
        )}
      </PaginatedTable>
      <Modal id={modalId} onRequestClose={closeModal} className="wide-modal">
        <section>
          <BeaconDataTable selectedMetric={state.selectedValue} />
        </section>
      </Modal>
    </Card>
  );
}

export default TopBeacons;
