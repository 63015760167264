import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import "./Graph.scss";

export type GraphPoint = {
  x: string;
  y: number;
};

type GraphProps = {
  title: string;
  titleTooltip: string;
  data: GraphPoint[];
  yAxisName: string;
  manualTicks?: string[];
  width?: number;
  height?: number;
  onClick?: (event: GraphMouseEvent) => void;
};

export interface GraphMouseEvent {
  chartX: number;
  chartY: number;
  activeTooltipIndex: number;
  // X-axis tick label
  activeLabel: string;
  activePayload?: ActivePayloadEntity[] | null;
  activeCoordinate: ActiveCoordinate;
}
export interface ActivePayloadEntity {
  stroke: string;
  fill: string;
  fillOpacity: number;
  points?: null[] | null;
  dataKey: string;
  name: string;
  color: string;
  value: number;
  payload: Payload;
}
export interface Payload {
  x: string;
  y: number;
}
export interface ActiveCoordinate {
  x: number;
  y: number;
}

export function Graph(props: GraphProps) {
  return (
    <div className="graph">
      <h2 data-for="appTooltip" data-tip={props.titleTooltip}>
        {props.title}
      </h2>
      <ResponsiveContainer>
        <AreaChart data={props.data} width={props.width || 1000} height={props.height || 400} onClick={props.onClick}>
          <XAxis dataKey="x" ticks={props.manualTicks} />
          <YAxis dataKey="y" />
          <Tooltip formatter={(value: number, name: string) => [value, props.yAxisName]} />
          <CartesianGrid stroke="#f5f5f5" />
          <Area type="monotone" dataKey="y" stroke="#A8A6FB" fill="#EAEAFF" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
