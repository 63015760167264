import { addMinutes, getHours, getMinutes } from "date-fns";
import { isDate } from "lodash";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { BeaconLookupView, BeaconsApi, TimeframeType } from "../../api";
import { BeaconHourTracking } from "../../pages/contact-tracking/ContactTracking";
import { isHttpOk } from "../../services/api";
import { formatHour } from "../../services/format";
import { isProvided } from "../../services/objects";
import { getApiConfig } from "../../state/configuration";
import { useGlobalFilters } from "../../state/globalFilters";
import { DateRange } from "../forms/DateRange";
import { Select } from "../forms/Select";
import { Clock, DropdownIcon } from "../icons/Icons";

import "./TimeBeacons.scss";

type TimeBeaconsProps = {
  selectedBeaconHour?: BeaconHourTracking;
  mergeBeaconHour?: (beaconHour: Partial<BeaconHourTracking>) => void;
};
type PickerValueType = Date | [Date | null, Date | null] | null;

export function TimeBeacons(props: TimeBeaconsProps) {
  const { selectTime, state: filters } = useGlobalFilters();

  const [hoursVisible, setVisibility] = useState<boolean>(false);
  const toggleHours = () => setVisibility(!hoursVisible);
  const hideHours = () => setVisibility(false);

  const [beacons, setBeacons] = useState<BeaconLookupView[]>([]);
  useEffect(() => {
    (async () => {
      const config = getApiConfig();
      const request = await new BeaconsApi(config).getForLookup();
      if (isHttpOk(request)) setBeacons(request.data);
    })();
  }, []);

  const beaconOptions = beacons.map((b) => ({
    label: b.name!,
    value: b.id!,
    description: isProvided(b.area) ? `in ${b.area}, ${b.store}` : `in ${b.store}`,
  }));

  const fixedDate = new Date(2020, 1, 1);
  const startHour = props.selectedBeaconHour?.startHour;
  const endHour = props.selectedBeaconHour?.endHour;
  const getHourValue = (hour?: number) => (hour ? addMinutes(fixedDate, hour * 60) : null);
  const timeframe = filters.selectedTime.timeframeType;
  const isNowTimeframe = timeframe === TimeframeType.Now;

  const handleStartHourChange = (value: PickerValueType) => {
    if (isDate(value) && props.mergeBeaconHour)
      props.mergeBeaconHour({ startHour: getHours(value) + getMinutes(value) / 60 });
  };
  const handleEndHourChange = (value: PickerValueType) => {
    const startValue = getHourValue(startHour);
    if (isDate(value) && startValue && props.mergeBeaconHour) {
      if ((timeframe !== TimeframeType.Today && timeframe !== TimeframeType.Yesterday) || startValue < value)
        props.mergeBeaconHour({ endHour: getHours(value) + getMinutes(value) / 60 });
    }
    hideHours();
  };

  const renderHours = () => {
    if (!startHour || !endHour) return "Throughout";
    if (isNowTimeframe) return "Ongoing";
    return `${formatHour(startHour)} - ${formatHour(endHour)}`;
  };

  return (
    <div className="time-location-filters">
      <div className="filter-item">
        <DateRange value={filters.selectedTime} onChange={selectTime} includeNowOption={true} />
      </div>
      <div className="filter-item hour-picker">
        <button className="selector" onClick={toggleHours} type="button">
          <Clock className="icon-left" />
          <span className="value">{renderHours()}</span>
          <DropdownIcon className="icon-right" />
        </button>
        <div className="hours">
          <div className="container">
            <DatePicker
              timeCaption="From"
              timeFormat="HH:mm"
              inline={true}
              timeIntervals={15}
              selected={startHour ? addMinutes(fixedDate, startHour * 60) : null}
              selectsRange={false}
              onChange={handleStartHourChange}
              showTimeSelect={hoursVisible}
              showTimeSelectOnly={true}
              required={true}
              disabled={isNowTimeframe}
              disabledKeyboardNavigation={true}
            />
            <DatePicker
              timeCaption="To"
              timeFormat="HH:mm"
              inline={true}
              timeIntervals={15}
              selected={endHour ? addMinutes(fixedDate, endHour * 60) : null}
              selectsRange={false}
              onChange={handleEndHourChange}
              showTimeSelect={hoursVisible}
              showTimeSelectOnly={true}
              required={true}
              disabled={isNowTimeframe}
              disabledKeyboardNavigation={true}
            />
          </div>
        </div>
      </div>
      <Select
        type="filter"
        placeholder="Select beacons"
        errorLabel="Beacons"
        multiValueItemLabel="beacons"
        isMulti={true}
        required={true}
        value={props.selectedBeaconHour?.beacons || []}
        options={beaconOptions}
        onChange={(ids) => props.mergeBeaconHour && props.mergeBeaconHour({ beacons: ids as string[] })}
      />
    </div>
  );
}
