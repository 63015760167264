import { useState } from "react";
import { DropdownIcon } from "../icons/Icons";

import "./Dropdown.scss";

interface DropdownItemProps {
  label: string;
  onClick: () => void;
}

const DropdownItem = (props: DropdownItemProps) => {
  return (
    <button className="option__elem" type="button" onClick={props.onClick}>
      {props.label}
    </button>
  );
};

interface DropdownProps {
  label: string;
  items: DropdownItemProps[];
}

const Dropdown = (props: DropdownProps) => {
  const [opened, setOpened] = useState(false);

  return (
    <div className="dropdown_container">
      <button className="dropdown_elem" type="button" onClick={() => setOpened((o) => !o)}>
        <span className="dropdown_label">{props.label}</span>
        <span className="dropdown_select">
          <DropdownIcon />
        </span>
      </button>
      {opened && (
        <div className="dropdown__options">
          {props.items.map((item, index) => (
            <DropdownItem
              key={index}
              label={item.label}
              onClick={() => {
                setOpened(false);
                item.onClick();
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
