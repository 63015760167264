import { ReactQuill } from "../app/forms/ReactQuill";

export function insertTokenIntoInput(element: HTMLInputElement, token: string, setValue: (value: string) => void) {
  const position = element.selectionStart;
  if (position != null) {
    const value = element.value;
    const textBeforeCursor = value.substring(0, position);
    const textAfterCursor = value.substring(position, value.length);
    const newValue = `${textBeforeCursor} ${token}${textAfterCursor}`;
    setValue(newValue);
    const newPosition = position + token.length + 1;
    element.setSelectionRange(newPosition, newPosition);
  }
}

export function insertTokenIntoQuill(quill: ReactQuill, token: string) {
  const editor = quill.getEditor();
  const selection = editor.getSelection(true);
  if (selection) editor.insertText(selection.index, ` ${token}`);
}
