import * as df from "date-fns";

export function utcToLocal(date: string | Date): Date;
export function utcToLocal(date: string | Date | null | undefined): Date | null | undefined;
export function utcToLocal(date: string | Date | null | undefined) {
  if (date === null) return null;
  if (date === undefined) return undefined;
  const offset = new Date().getTimezoneOffset() * 60000;
  const real = typeof date === "string" ? stringToDate(date, true) : date;
  return new Date(real.getTime() - offset);
}

export function localToUtc(date: string | Date): Date;
export function localToUtc(date: string | Date | null | undefined): Date | null | undefined;
export function localToUtc(date: string | Date | null | undefined) {
  if (date === null) return null;
  if (date === undefined) return undefined;
  const offset = new Date().getTimezoneOffset() * 60000;
  const real = typeof date === "string" ? stringToDate(date, true) : date;
  return new Date(real.getTime() + offset);
}

export function getUtcDate() {
  return localToUtc(new Date());
}

export function stringFromDate(date: Date, withTime?: false): IsoDateString;
export function stringFromDate(date: Date, withTime?: true): IsoDateTimeString;
export function stringFromDate(date: Date | null, withTime?: false): IsoDateString | null;
export function stringFromDate(date: Date | null, withTime?: true): IsoDateTimeString | null;
export function stringFromDate(date: Date, withTime?: boolean): string;
export function stringFromDate(date: Date | null, withTime?: boolean): string | null;
export function stringFromDate(date: Date | null, withTime?: boolean): string | null {
  if (!date) return null;
  if (withTime) {
    return df.format(date, "yyyy-MM-dd HH:mm:ss");
  } else {
    date = df.startOfDay(date);
    return df.format(date, "yyyy-MM-dd");
  }
}

export function stringToDate(date: IsoDateString, withTime?: false): Date;
export function stringToDate(date: IsoDateTimeString, withTime?: true): Date;
export function stringToDate(date: IsoDateString | null | undefined, withTime?: false): Date | null;
export function stringToDate(date: IsoDateTimeString | null | undefined, withTime?: true): Date | null;
export function stringToDate(date: IsoDateTimeString, withTime?: boolean): Date;
export function stringToDate(
  date: IsoDateString | IsoDateTimeString | null | undefined,
  withTime?: boolean
): Date | null;
export function stringToDate(
  date: IsoDateString | IsoDateTimeString | null | undefined,
  withTime?: boolean
): Date | null {
  if (!date) return null;
  const value = df.parseISO(date);
  if (!withTime) return df.startOfDay(value);
  return value;
}

export function parseDate(date: string | null | undefined, format: string): Date | null {
  if (!date) return null;
  const value = df.parse(date, format, new Date());
  if (!df.isValid(value)) return null;
  return value;
}
