/**
 * Options for specifying how the map control should authenticate with the Azure Maps services.
 */
export interface AuthenticationOptions {
  /** A URL string pointing to the domain of the Azure Maps service, default is `"atlas.microsoft.com"`. */
  azMapsDomain?: string;

  /**
   * Subscription key from your Azure Maps account.
   * Must be specified for subscription key authentication type.
   */
  subscriptionKey?: string;

  /**
   * The Azure Maps client ID, This is an unique identifier used to identify the maps account.
   * Preferred to always be specified, but must be specified for AAD and anonymous authentication types.
   */
  clientId?: string;
}

/** Options for an Azure Maps tile layer. */
export interface AzureMapsTileLayerOptions extends L.TileLayerOptions {
  /** Required. Authentication options for connecting to Azure Maps. */
  authOptions: AuthenticationOptions;

  /** The tile set ID layer to load from the Azure Maps Render V2 service. Custom tileset ID's that return raster tiles that are 256x256 pixels in size can also be specified as a string. Default `'microsoft.base.road'` */
  tilesetId?: string;

  /** Geopolitical view of the map. [Supported views](https://docs.microsoft.com/en-us/azure/azure-maps/supported-languages#sdks) Default: `'Auto'` */
  view?: string;

  /** Language code. [Supported languages](https://docs.microsoft.com/azure/azure-maps/supported-languages) Default: `'en-US'` */
  language?: string;

  /** The desired date and time of the requested tile. This parameter must be specified in the standard date-time format (e.g. 2019-11-14T16:03:00-08:00), as defined by ISO 8601. This parameter is only supported when tilesetId parameter is set to `microsoft.weather.infrared.main` or `microsoft.weather.radar.main`. */
  timeStamp?: string | Date;

  /** The thickness of lines when using the traffic flow tilesets. Default: `5` */
  trafficFlowThickness?: number;

  hideAttribution?: boolean;
}

/**
 * This is the object type expected to be returned by the transformRequest callback.
 */
export class RequestParameters {
  /**
   * Used to specify the cross-origin request (CORs) credentials setting. Can be `'same-origin'` or `'include'`.
   */
  public credentials?: string = undefined;

  /**
   * The headers to be sent with the request.
   */
  public headers?: Record<string, string> = undefined;

  /**
   * The url to be requested.
   */
  public url: string;

  /**
   * @internal
   */
  constructor(credentials?: string, headers?: Record<string, string>, url?: string) {
    this.credentials = credentials;
    this.headers = headers;
    this.url = url || "";
  }
}

export interface SearchResult {
  results: SearchAddressResultItem[];
  summary: SearchSummary;
}

export interface ErrorResponse {
  error: ErrorDetail;
}

export function isError(arg: any | ErrorResponse): arg is ErrorResponse {
  return arg.error && arg.error.code && arg.error.message && arg.error.target;
}

export interface ErrorDetail {
  /** The error additional info. */
  additionalInfo?: { info: any; type: string }[];

  /** The error code. */
  code: string;

  /** The error details. */
  details?: ErrorDetail[];

  /** The error message. */
  message: string;

  /** The error target. */
  target: string;
}

export interface EntryPoint {
  position: LatLongPairAbbreviated;
  type: "main" | "minor";
}

export enum GeographicEntityType {
  /** Country name */
  Country = "Country",
  /** County */
  CountrySecondarySubdivision = "CountrySecondarySubdivision",
  /** State or Province */
  CountrySubdivision = "CountrySubdivision",
  /** Named Area */
  CountryTertiarySubdivision = "CountryTertiarySubdivision",
  /** City / Town */
  Municipality = "Municipality",
  /** Sub / Super City */
  MunicipalitySubdivision = "MunicipalitySubdivision",
  /** Neighbourhood */
  Neighbourhood = "Neighbourhood",
  /** Postal Code / Zip Code */
  PostalCodeArea = "PostalCodeArea",
}

export interface Address {
  /**  //The bounding box of the location. */
  /**  boundingBox: BoundingBoxCompassNotation; */

  /** The building number on the street. DEPRECATED, use streetNumber instead. */
  buildingNumber: string;

  /** Country name */
  country: string;

  /** Country (Note: This is a two-letter code, not a country name.) */
  countryCode: string;

  /** ISO alpha-3 country code */
  countryCodeISO3: string;

  /** County */
  countrySecondarySubdivision: string;

  /** State or Province */
  countrySubdivision: string;

  /** The full name of a first level of country administrative hierarchy. This field appears only in case countrySubdivision is presented in an abbreviated form. Only supported for USA, Canada, and United Kingdom. */
  countrySubdivisionName: string;

  /** Named Area */
  countryTertiarySubdivision: string;

  /** The name of the street being crossed. */
  crossStreet: string;

  /** Extended postal code (availability is dependent on the region). */
  extendedPostalCode: string;

  /** An address line formatted according to the formatting rules of a Result's country of origin, or in the case of a country, its full country name. */
  freeformAddress: string;

  /** An address component which represents the name of a geographic area or locality that groups a number of addressable objects for addressing purposes, without being an administrative unit. This field is used to build the freeformAddress property. */
  localName: string;

  /** City / Town */
  municipality: string;

  /** Sub / Super City */
  municipalitySubdivision: string;

  /** Postal Code / Zip Code */
  postalCode: string;

  /** The codes used to unambiguously identify the street */
  routeNumbers: string[];

  /** The street name. DEPRECATED, use streetName instead. */
  street: string;

  /** The street name. */
  streetName: string;

  /** The street name and number. */
  streetNameAndNumber: string;

  /** The building number on the street. */
  streetNumber: string;
}

export interface AddressRanges {
  from: LatLongPairAbbreviated;
  to: LatLongPairAbbreviated;
  rangeLeft: string;
  rangeRight: string;
}

export interface SearchAddressResultItem {
  /** The address of the result */
  address: Address;

  /** Describes the address range on both sides of the street for a search result. Coordinates for the start and end locations of the address range are included. */
  addressRanges: AddressRanges;

  /**  //Optional section. Reference geometry id for use with the Get Search Polygon API. */
  /**  dataSources: DataSources; */

  /** Detour time in seconds. Only returned for calls to the Search Along Route API. */
  detourTime: number;

  /** Straight line distance between the result and geobias location in meters. */
  dist: number;

  /** Geography entity type. Present only when entityType was requested and is available. */
  entityType: GeographicEntityType;

  /** Array of EntryPoints. Those describe the types of entrances available at the location. The type can be "main" for main entrances such as a front door, or a lobby, and "minor", for side and back doors. */
  entryPoints: EntryPoint[];

  /** Id property */
  id: string;

  /** Information about the original data source of the Result. Used for support requests. */
  info: string;

  /**
   * Information on the type of match.
   * One of:
   *   AddressPoint
   *   HouseNumberRange
   *   Street
   */
  matchType: "AddressPoint" | "HouseNumberRange" | "Street";

  /**  // Details of the returned POI including information such as the name, phone, url address, and classifications. */
  /**  poi: PointOfInterest; */

  /**  A location represented as a latitude and longitude using short names 'lat' & 'lon'. */
  position: LatLongPairAbbreviated;

  /**  The value within a result set to indicate the relative matching score between results. You can use this to determine that result x is twice as likely to be as relevant as result y if the value of x is 2x the value of y. The values vary between queries and is only meant as a relative value for one result set. */
  score: number;

  /** One of:
   *   POI
   *   Street
   *   Geography
   *   Point Address
   *   Address Range
   *   Cross Street
   */
  type: SearchAddressResultType;

  /** The viewport that covers the result represented by the top-left and bottom-right coordinates of the viewport. */
  viewport: BoundingBox;
}

export interface BoundingBox {
  btmRightPoint: LatLongPairAbbreviated;
  topLeftPoint: LatLongPairAbbreviated;
}

export enum SearchAddressResultType {
  POI = "POI",
  Street = "Street",
  Geography = "Geography",
  PointAddress = "Point Address",
  AddressRange = "Address Range",
  CrossStreet = "Cross Street",
}

export interface LatLongPairAbbreviated {
  lat: number;
  lon: number;
}

export enum QueryType {
  NEARBY = "NEARBY",
  NON_NEAR = "NON_NEAR",
}

export interface SearchSummary {
  /** The maximum fuzzy level required to provide Results. */
  fuzzyLevel: number;
  /** Indication when the internal search engine has applied a geospatial bias to improve the ranking of results. In some methods, this can be affected by setting the lat and lon parameters where available. In other cases it is purely internal. */
  geoBias: LatLongPairAbbreviated;
  /** Maximum number of responses that will be returned */
  limit: number;
  /** Number of results in the response. */
  numResults: number;
  /** The starting offset of the returned Results within the full Result set. */
  offset: number;
  /** The query parameter that was used to produce these search results. */
  query: string;
  /** Time spent resolving the query, in milliseconds. */
  queryTime: number;
  /** The type of query being returned: NEARBY or NON_NEAR. */
  queryType: QueryType;
  /** The total number of Results found. */
  totalResults: number;
}
