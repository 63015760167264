import { NavLink, useRouteMatch } from "react-router-dom";
import { Analytics, Locations, Campaigns } from "../icons/Icons";

import "./MainMenu.scss";

const firstUrls = ["/touchpoints", "/sales", "/customers", "/notification", "/visitor-statistics", "/heatmap"];

function MainMenu() {
  const route = useRouteMatch();

  const classes = [];
  if (firstUrls.indexOf(route.path) > -1) {
    classes.push("active");
  }

  return (
    <nav className="main-menu">
      <ul>
        <li>
          <NavLink className={classes.join(" ")} to="/analytics">
            <Analytics /> <span>ANALYTICS</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/locations">
            <Locations /> <span>LOCATIONS</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/campaigns">
            <Campaigns /> <span>CAMPAIGNS</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default MainMenu;
