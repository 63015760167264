import { AuthenticationOptions, RequestParameters } from "./Types";
import { Constants } from "./Constants";
import { Helpers } from "./Helpers";

function cleanDomain(domain?: string) {
  // Remove any domain that might be in the domain.
  if (domain && /^\w+:\/\//.test(domain)) {
    // If the provided url includes a protocol don't change it.
    return domain.replace(/^\w+:\/\//, "");
  }
  return domain;
}

/**
 * A manager for the map control's authentication.
 * Exposed through the authentication property of the atlas.Map class.
 * Cannot be instantiated by the user.
 */

export class RequestAuthenticator {
  private readonly options: AuthenticationOptions;
  private readonly sessionId;
  private static instance: RequestAuthenticator;

  public static getInstance(authOptions: AuthenticationOptions): RequestAuthenticator {
    const options = { ...authOptions, azMapsDomain: cleanDomain(authOptions.azMapsDomain) };

    if (RequestAuthenticator.instance && RequestAuthenticator.instance.compareOptions(options)) {
      return RequestAuthenticator.instance;
    }

    const newInstance = new RequestAuthenticator(options);

    // Cache the instance for faster processing of additional layers and allow reuse of the same instance.
    if (!RequestAuthenticator.instance) {
      RequestAuthenticator.instance = newInstance;
    }

    return newInstance;
  }

  public compareOptions(authOptions: AuthenticationOptions): boolean {
    const opt = this.options;
    return (
      authOptions.azMapsDomain === opt.azMapsDomain &&
      authOptions.clientId === opt.clientId &&
      authOptions.subscriptionKey === opt.subscriptionKey
    );
  }

  /**
   * @internal
   */
  constructor(authOptions: AuthenticationOptions) {
    this.options = authOptions;
    this.sessionId = Helpers.uuid();
  }

  public signRequest(request: RequestParameters): RequestParameters {
    const opt = this.options;
    const h = Constants;

    request.url = request.url.replace("{azMapsDomain}", opt.azMapsDomain || "");

    // Add the headers used for identifying a request is from the map control.
    const headers = request.headers || {};
    headers[h.SESSION_ID] = this.sessionId;
    headers[h.MS_AM_REQUEST_ORIGIN] = h.MS_AM_REQUEST_ORIGIN_VALUE;
    headers[h.MAP_AGENT] = `MapControl/${h.SDK_VERSION} (${h.TARGET_SDK})`;

    if ("url" in request) {
      let prefix = "?";
      if (request.url.indexOf("?") !== -1) {
        prefix = "&";
      }
      request.url += `${prefix}subscription-key=${this.options.subscriptionKey}`;
    } else {
      throw new Error("No URL specified in request.");
    }

    request.headers = headers;

    return request;
  }

  public getRequest(url: string): Promise<Response> {
    const request = this.signRequest({ url: url });

    // Process the request.
    return fetch(request.url!, {
      method: "GET",
      mode: "cors",
      headers: new Headers(request.headers),
    });
  }
}
