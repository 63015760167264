import React, { useMemo } from "react";
import { isProvided } from "../../services/objects";
import { highlightMatches } from "../../services/string";

const HighlightedString = React.memo((props: { text: string; search: string }) => {
  const text = useMemo(() => highlightMatches(props.text, [props.search]), [props.text, props.search]);
  return (
    <span className="highlighted">
      {text.map((span, index) =>
        span.highlighted ? (
          <span key={index}>{span.text}</span>
        ) : (
          <React.Fragment key={index}>{span.text}</React.Fragment>
        )
      )}
    </span>
  );
});

export function highlight(text: string | null | undefined, search: string | null | undefined) {
  if (!isProvided(text)) {
    return null;
  } else if (!isProvided(search) || search.length === 0) {
    return <span>{text}</span>;
  } else {
    return <HighlightedString text={text} search={search} />;
  }
}
