import { useState } from "react";
import { CustomerPurchasesColumn, ImportApi, ProductImportColumn } from "../../api";
import Card from "../../app/cards/Card";
import { PageHeader } from "../../app/widgets/PageHeaders";
import { Tab, TabList, TabPanel, Tabs } from "../../app/widgets/tabs";
import { useLocalizedText } from "../../services/localization";
import { getApiConfig } from "../../state/configuration";
import { useDeploymentConfig } from "../../state/deployment";
import { ExcelUpload, ExcelUploadProps, ImportField, UploadRequest } from "./editors/ExcelUpload";

import "./ImportData.scss";

function defineField(name: string, id: number, required: boolean, tooltip: string): ImportField {
  return {
    name,
    id,
    required,
    tooltip,
  };
}

// TODO: move to config
const customerPurchaseFields: ImportField[] = [
  defineField("Purchase Date", CustomerPurchasesColumn.PurchaseDate, true, "Date of the purchase"),
  defineField("Email", CustomerPurchasesColumn.Email, true, "Customers email, will act as unique customer identifier"),
  defineField("Total Quantity", CustomerPurchasesColumn.TotalQuantity, true, "Total quantity of bought items"),
  defineField("Total Price", CustomerPurchasesColumn.TotalPrice, true, "Total price of the purchase"),
  defineField(
    "Purchase Id",
    CustomerPurchasesColumn.PurchaseUniqueId,
    false,
    "Unique purchase identifier such as EAN13"
  ),
  defineField("First Name", CustomerPurchasesColumn.FirstName, false, "Text up to 100 characters"),
  defineField("Last Name", CustomerPurchasesColumn.LastName, false, "Text up to 100 characters"),
  defineField("Total Discount", CustomerPurchasesColumn.TotalDiscount, false, "decimal"),
  defineField("Join Date", CustomerPurchasesColumn.JoinDatetimeUtc, false, "date"),
  defineField("Gender", CustomerPurchasesColumn.Gender, false, "Text: Male, Female or Other"),
  defineField("Birth Date", CustomerPurchasesColumn.BirthDate, false, "Date"),
  defineField("House Number", CustomerPurchasesColumn.HouseNumber, false, "Text"),
  defineField("Street", CustomerPurchasesColumn.Street, false, "Text"),
  defineField("Zip Code", CustomerPurchasesColumn.ZipCode, false, "Text"),
  defineField("City", CustomerPurchasesColumn.City, false, "Text"),
  defineField("Country", CustomerPurchasesColumn.Country, false, "Text"),
  defineField("Language", CustomerPurchasesColumn.Language, false, "Text"),
  defineField("Phone Number", CustomerPurchasesColumn.PhoneNumber, false, "Text"),
  defineField("Store Id", CustomerPurchasesColumn.StoreId, false, "Integer. Beaconsmind Store Id"),
  defineField("Store Image Url", CustomerPurchasesColumn.StoreImageUrl, false, "Image Url for store"),
  defineField("Sales Person Id", CustomerPurchasesColumn.SalesPersonId, false, "Text"),
  defineField("Loyalty Number", CustomerPurchasesColumn.CustomerClubId, false, "Text up to 16 characters"),
];
const productsFields: ImportField[] = [
  defineField("Product Id", ProductImportColumn.ProductIdentifier, true, "Unique Product Identifier"),
  defineField("Product Name", ProductImportColumn.Name, true, "Name of the product"),
  defineField("Product Description", ProductImportColumn.Description, false, "Product description"),
  defineField("Image Url", ProductImportColumn.ImageUrl, true, "Image Url for the product"),
  defineField("From Price", ProductImportColumn.FromPrice, false, "decimal (optional)"),
  defineField("To Price", ProductImportColumn.ToPrice, false, "decimal (optional)"),
  defineField("Product Url", ProductImportColumn.Url, true, "Url of the product"),
  defineField("Category Name", ProductImportColumn.CategoryName, true, "Product Category Name"),
  defineField("Category Url", ProductImportColumn.CategoryUrl, false, "Url for the Category"),
  defineField("Category Image Url", ProductImportColumn.CategoryImageUrl, true, "Image Url for the Category"),
];

export function ImportData() {
  const [activeTab, setActiveTab] = useState<number>(0);
  const texts = useLocalizedText();

  const config = getApiConfig();
  const api = new ImportApi(config);
  const submitCustomerPurchases = async (uploadRequest: UploadRequest) => {
    return await api.uploadCustomerPurchases({
      uploadCustomerPurchasesRequest: uploadRequest,
    });
  };
  const submitProducts = async (uploadRequest: UploadRequest) => {
    return await api.uploadProducts({
      uploadProductsRequest: { ...uploadRequest, language: selectedLanguage },
    });
  };

  const settings = useDeploymentConfig().config;
  const defaultLanguage = settings.defaultLanguage || "en";
  const [selectedLanguage, setLanguage] = useState(defaultLanguage);
  const productsStepOneExtra = (
    <div className="step-one-extra">
      {"Select language: "}
      <select value={selectedLanguage} onChange={(e) => setLanguage(e.target.value)}>
        {(settings.allowedLanguages || [defaultLanguage]).map((l) => (
          <option key={l} value={l}>
            {l}
          </option>
        ))}
      </select>
    </div>
  );

  const forms: ExcelUploadProps[] = [
    {
      entityPlural: "purchases",
      enabled: settings.enablePurchasesImport,
      title: "Import Customer Purchases",
      fields: customerPurchaseFields,
      onSubmit: submitCustomerPurchases,
    },
    {
      entityPlural: "products",
      enabled: settings.enableProductsImport,
      title: "Import Products",
      fields: productsFields,
      onSubmit: submitProducts,
      stepOneExtraRequirement: productsStepOneExtra,
    },
  ];

  const activeForms = forms.filter((f) => f.enabled);

  return (
    <div className="import-data page-content">
      <PageHeader title={texts.importDataPage.title}></PageHeader>
      <section>
        <Card>
          <Tabs
            selectedIndex={activeTab}
            onSelect={(selected, last) => {
              if (last !== selected) setActiveTab(selected);
              return true;
            }}
          >
            <TabList>
              {activeForms.map((form, index) => (
                <Tab key={index}>
                  <p>{form.title}</p>
                </Tab>
              ))}
            </TabList>
            {activeForms.map((formProps, index) => (
              <TabPanel key={index}>
                <ExcelUpload {...formProps} />
              </TabPanel>
            ))}
          </Tabs>
        </Card>
      </section>
    </div>
  );
}
