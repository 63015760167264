import "./Legend.scss";

interface LegendData {
  color?: string;
  title?: string;
  tooltip?: string;
}

interface LegendProps {
  legendList: LegendData[];
}

enum Symbol {
  Circle,
  Square,
}

function GetSymbol(symbol: Symbol, color: string) {
  let classList: string = "";

  switch (symbol) {
    case Symbol.Circle:
      classList = "base-symbol circle";
      break;
    // default is square
    case Symbol.Square:
    default:
      classList = "base-symbol";
  }

  return <span className={classList} style={{ backgroundColor: color }}></span>;
}

function Legend(props: LegendProps) {
  return (
    <div className="legend-container">
      <ul className="legend-list">
        {props.legendList.map((item, i) => (
          <li key={i} className={`legend-item`}>
            {GetSymbol(Symbol.Circle, item.color || "black")}
            {item.tooltip}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Legend;
