import React, { Component, PropsWithChildren, RefCallback } from "react";
import { getTabsCount } from "./utils";
import UncontrolledTabs from "./UncontrolledTabs";
import { ClassValue } from "clsx";

const MODE_CONTROLLED = 0;
const MODE_UNCONTROLLED = 1;

type TabsProps = PropsWithChildren<{
  direction?: "rtl" | "ltr";
  className?: ClassValue;
  selectedTabClassName?: ClassValue;
  selectedTabPanelClassName?: ClassValue;
  disabledTabClassName?: ClassValue;
  defaultFocus?: boolean;
  defaultIndex?: number;
  disableUpDownKeys?: boolean;
  domRef?: RefCallback<any>;
  forceRenderTabPanel?: boolean;
  onSelect?: (index: number, last: number, event: any) => boolean;
  selectedIndex?: number;
  environment?: any;
}>;

type State = { mode: 0 | 1; focus: boolean; selectedIndex: number | undefined };

export default class Tabs extends Component<TabsProps, State> {
  public static tabsRole = "Tabs";

  constructor(props: TabsProps) {
    super(props);

    this.state = Tabs.copyPropsToState(this.props, {}, props.defaultFocus);
  }

  static getDerivedStateFromProps(props: TabsProps, state: State) {
    return Tabs.copyPropsToState(props, state);
  }

  static getModeFromProps(props: TabsProps) {
    return typeof props.selectedIndex !== "number" ? MODE_UNCONTROLLED : MODE_CONTROLLED;
  }

  handleSelected = (index: number, last: number, event: any) => {
    const { onSelect } = this.props;
    const { mode } = this.state;

    // Call change event handler
    if (typeof onSelect === "function") {
      // Check if the change event handler cancels the tab change
      if (onSelect(index, last, event) === false) return;
    }

    const focus = event.type === "keydown";

    if (mode === MODE_UNCONTROLLED) {
      this.setState({
        selectedIndex: index,
        focus: focus,
      });
    } else {
      this.setState({
        focus: focus,
      });
    }
  };

  // preserve the existing selectedIndex from state.
  // If the state has not selectedIndex, default to the defaultIndex or 0
  static copyPropsToState(props: TabsProps, state: Partial<State>, focus = false) {
    if (
      process.env.NODE_ENV !== "production" &&
      state.mode !== undefined &&
      state.mode !== Tabs.getModeFromProps(props)
    ) {
      throw new Error(
        `Switching between controlled mode (by using \`selectedIndex\`) and uncontrolled mode is not supported in \`Tabs\`.
For more information about controlled and uncontrolled mode of react-tabs see https://github.com/reactjs/react-tabs#controlled-vs-uncontrolled-mode.`
      );
    }

    const newState: State = {
      focus,
      mode: Tabs.getModeFromProps(props),
      selectedIndex: undefined,
    };

    if (newState.mode === MODE_UNCONTROLLED) {
      const maxTabIndex = Math.max(0, getTabsCount(props.children) - 1);
      let selectedIndex = null;

      if (state.selectedIndex != null) {
        selectedIndex = Math.min(state.selectedIndex, maxTabIndex);
      } else {
        selectedIndex = props.defaultIndex || 0;
      }
      newState.selectedIndex = selectedIndex;
    }

    return newState;
  }

  render() {
    const { children, defaultIndex, defaultFocus, selectedIndex, ...props } = this.props;
    const index = typeof selectedIndex === "number" ? selectedIndex : this.state.selectedIndex || 0;
    return (
      <UncontrolledTabs {...props} selectedIndex={index} focus={this.state.focus} onSelect={this.handleSelected}>
        {children}
      </UncontrolledTabs>
    );
  }
}
