import { intersection, isArray } from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { AnalyticsApi, ConversionStatistics, RegistrationsView } from "../../api";
import StatsCardSimple from "../../app/cards/StatsCardSimple";
import { AppDownloads, AppReg, ConversionStats, NewAppCustomers, TotalCustomers } from "../../app/icons/Icons";
import { Modal, useModal } from "../../app/modals/Modal";
import { AppDownloadsTable } from "../../app/tables/analytics/AppDownloadsTable";
import { AppOpensTable } from "../../app/tables/analytics/AppOpensTable";
import { AppRegistrationsTable } from "../../app/tables/analytics/AppRegistrationsTable";
import { ConvertedUsersTable } from "../../app/tables/analytics/ConvertedUsersTable";
import { NewCustomersTable } from "../../app/tables/analytics/NewCustomersTable";
import { TotalAppCustomersTable } from "../../app/tables/analytics/TotalAppCustomersTable";
import { TotalAppUsersTable } from "../../app/tables/analytics/TotalAppUsersTable";
import { isHttpOk } from "../../services/api";
import { useStateEx } from "../../services/hooks";
import { LocalizedText, useLocalizedText } from "../../services/localization";
import { getApiConfig } from "../../state/configuration";
import { useDeploymentConfig } from "../../state/deployment";
import { useTimeLocationFilters } from "../../state/globalFilters";

import "./TopRow.scss";

enum Widgets {
  appDownloads = "appDownloads",
  appRegistrations = "appRegistrations",
  newAppCustomers = "newAppCustomers",
  totalAppCustomers = "totalAppCustomers",
  appOpenings = "totalAppOpenings",
  totalAppUsers = "totalAppUsers",
  conversionStats = "conversionStats",
}

function createWidget(
  texts: LocalizedText,
  model: State,
  widget: Widgets,
  dragHandleProps?: DraggableProvidedDragHandleProps,
  onClick?: () => void
) {
  switch (widget) {
    case Widgets.appDownloads:
      return (
        <StatsCardSimple
          key={widget}
          value={model.downloads}
          tooltipText={texts.analyticsPage.items.appDownloads.tooltip}
          label={texts.analyticsPage.items.appDownloads.title}
          color="green"
          figure={<AppDownloads />}
          showDots={true}
          dragHandleProps={dragHandleProps}
          onClick={onClick}
        />
      );
    case Widgets.appRegistrations:
      const androidRatio =
        model.registrations.nmRegistrations > 0
          ? model.registrations.nmAndroid / model.registrations.nmRegistrations
          : 0;
      const iosRatio =
        model.registrations.nmRegistrations > 0 ? model.registrations.nmIos / model.registrations.nmRegistrations : 0;

      return (
        <StatsCardSimple
          key={widget}
          value={model.registrations.nmRegistrations}
          tooltipText={texts.analyticsPage.items.appRegistrations.tooltip}
          label={texts.analyticsPage.items.appRegistrations.title}
          color="purple"
          figure={<AppReg percentage={iosRatio} />}
          showDots={true}
          dragHandleProps={dragHandleProps}
          items={[
            { label: "iOS", percentage: Math.round(iosRatio * 100) },
            { label: "Android", percentage: Math.round(androidRatio * 100) },
          ]}
          onClick={onClick}
        />
      );
    case Widgets.newAppCustomers:
      return (
        <StatsCardSimple
          key={widget}
          showDots={true}
          value={model.newCustomers}
          tooltipText={texts.analyticsPage.items.newAppCustomers.tooltip}
          label={texts.analyticsPage.items.newAppCustomers.title}
          color="lightblue"
          figure={<NewAppCustomers />}
          dragHandleProps={dragHandleProps}
          onClick={onClick}
        />
      );
    case Widgets.totalAppCustomers:
      return (
        <StatsCardSimple
          key={widget}
          showDots={true}
          value={model.totalCustomers}
          tooltipText={texts.analyticsPage.items.totalAppCustomers.tooltip}
          label={texts.analyticsPage.items.totalAppCustomers.title}
          color="blue"
          figure={<TotalCustomers />}
          dragHandleProps={dragHandleProps}
          onClick={onClick}
        />
      );
    case Widgets.appOpenings:
      return (
        <StatsCardSimple
          key={widget}
          showDots={true}
          value={model.appOpenings}
          tooltipText={texts.analyticsPage.items.appOpenings.tooltip}
          label={texts.analyticsPage.items.appOpenings.title}
          color="blue"
          figure={<TotalCustomers />}
          dragHandleProps={dragHandleProps}
          onClick={onClick}
        />
      );
    case Widgets.totalAppUsers:
      return (
        <StatsCardSimple
          key={widget}
          showDots={true}
          value={model.totalUsers}
          tooltipText={texts.analyticsPage.items.totalAppUsers.tooltip}
          label={texts.analyticsPage.items.totalAppUsers.title}
          color="blue"
          figure={<NewAppCustomers />}
          dragHandleProps={dragHandleProps}
          onClick={onClick}
        />
      );
    case Widgets.conversionStats:
      const rate = model.conversions.conversionRate;
      const subItem = { label: "Rate", percentage: Math.round(rate * 100) };
      return (
        <StatsCardSimple
          key={widget}
          value={model.conversions.total}
          tooltipText={texts.analyticsPage.items.conversionStats.tooltip}
          label={texts.analyticsPage.items.conversionStats.title}
          color="pink"
          figure={<ConversionStats />}
          showDots={true}
          dragHandleProps={dragHandleProps}
          items={[subItem]}
          onClick={onClick}
        />
      );
  }
}

interface State {
  downloads: number;
  registrations: RegistrationsView;
  newCustomers: number;
  totalCustomers: number;
  appOpenings: number;
  totalUsers: number;
  conversions: ConversionStatistics;
  selectedWidget: Widgets;
}

function loadWidgetsFromLocalStorage(allowedWidgets: Widgets[]) {
  const savedWidgets = localStorage.getItem("draggableItems");
  const parsedWidgets = savedWidgets && JSON.parse(savedWidgets);
  if (parsedWidgets && isArray(parsedWidgets)) {
    const validated = intersection(allowedWidgets, parsedWidgets);
    if (validated.length === allowedWidgets.length) {
      return parsedWidgets;
    }
  }
  return allowedWidgets;
}

export function TopRow() {
  const texts = useLocalizedText();
  const filters = useTimeLocationFilters("Analytics");
  const deployment = useDeploymentConfig();
  const posDisabled = deployment.config.disablePurchaseHistories;

  const defaultWidgets = [
    Widgets.appDownloads,
    Widgets.appRegistrations,
    posDisabled ? Widgets.appOpenings : Widgets.newAppCustomers,
    posDisabled ? Widgets.totalAppUsers : Widgets.totalAppCustomers,
    Widgets.conversionStats,
  ];

  const [widgets, setWidgets] = useState<Widgets[]>(() => {
    return loadWidgetsFromLocalStorage(defaultWidgets);
  });

  const { state, mergeState } = useStateEx<State>({
    downloads: 0,
    registrations: {
      nmAndroid: 0,
      nmIos: 0,
      nmRegistrations: 0,
    },
    newCustomers: 0,
    totalCustomers: 0,
    appOpenings: 0,
    totalUsers: 0,
    conversions: {
      total: 0,
      conversionRate: 0,
    },
    selectedWidget: Widgets.appDownloads,
  });

  const { openModal, closeModal } = useModal();

  const widgetOnClick = (w: Widgets) => {
    mergeState({ selectedWidget: w });
    openModal();
  };

  const renderModal = useCallback(() => {
    switch (state.selectedWidget) {
      case Widgets.appRegistrations:
        return <AppRegistrationsTable />;
      case Widgets.newAppCustomers:
        return <NewCustomersTable />;
      case Widgets.totalAppCustomers:
        return <TotalAppCustomersTable />;
      case Widgets.appOpenings:
        return <AppOpensTable />;
      case Widgets.totalAppUsers:
        return <TotalAppUsersTable />;
      case Widgets.conversionStats:
        return <ConvertedUsersTable />;
      case Widgets.appDownloads:
      default:
        return <AppDownloadsTable />;
    }
  }, [state.selectedWidget]);

  useEffect(() => {
    const api = new AnalyticsApi(getApiConfig());
    api.getDownloads({ includeTrend: false, ...filters }).then((result) => {
      if (isHttpOk(result)) mergeState({ downloads: result.data.current });
    });
    api.getRegistrationsByDevice(filters).then((result) => {
      if (isHttpOk(result)) mergeState({ registrations: result.data });
    });
    api.getConversionStatistics(filters).then((result) => {
      if (isHttpOk(result)) mergeState({ conversions: result.data });
    });

    if (posDisabled) {
      api.getTotalUsersCount(filters).then((result) => {
        if (isHttpOk(result)) mergeState({ totalUsers: result.data });
      });
      api.getOpenings({ includeTrend: false, ...filters }).then((result) => {
        if (isHttpOk(result)) mergeState({ appOpenings: result.data.current });
      });
    } else {
      api.getNewCustomersCount(filters).then((result) => {
        if (isHttpOk(result)) mergeState({ newCustomers: result.data });
      });
      api.getTotalCustomersCount(filters).then((result) => {
        if (isHttpOk(result)) mergeState({ totalCustomers: result.data });
      });
    }
  }, [filters, posDisabled, mergeState]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder(widgets, result.source.index, result.destination.index);
    setWidgets(reorderedItems);
    localStorage.setItem("draggableItems", JSON.stringify(reorderedItems));
  };

  const reorder = (list: Widgets[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <section className="analytics-top-row featured-cards" ref={provided.innerRef} {...provided.droppableProps}>
              {widgets.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided, snapshot) => (
                    <div className="draggable-card" ref={provided.innerRef} {...provided.draggableProps}>
                      {createWidget(texts, state, item, provided.dragHandleProps, () => widgetOnClick(item))}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </section>
          )}
        </Droppable>
      </DragDropContext>
      <Modal onRequestClose={closeModal} className="wide-modal">
        <section>{renderModal()}</section>
      </Modal>
    </>
  );
}
