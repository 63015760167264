import { useCallback } from "react";
import { ProductsApi } from "../../api";
import Card from "../../app/cards/Card";
import { PaginatedTable } from "../../app/widgets/PaginatedTable";
import { logErrorSilently } from "../../services/alerts";
import { isHttpOk } from "../../services/api";
import { useMoneyFormatter } from "../../services/format";
import { getApiConfig } from "../../state/configuration";
import { useTimeLocationFilters } from "../../state/globalFilters";

import "./ShopperTrends.scss";

function Products(props: { id: number }) {
  const url = "/dummy/articles/" + props.id.toString() + ".jpg";
  return <img alt="item" src={url} />;
}

export function ShopperTrends() {
  const filters = useTimeLocationFilters();
  const { formatMoney } = useMoneyFormatter();
  const dataSource = useCallback(async () => {
    const api = new ProductsApi(getApiConfig());
    const result = await api.getByRevenue({ limit: 5, ...filters });

    if (isHttpOk(result)) return result.data;
    else {
      logErrorSilently(result);
      return {
        count: 0,
        items: [],
      };
    }
  }, [filters]);

  return (
    <Card className="shopper-trends">
      <PaginatedTable
        mainHeader="Shopper Trends"
        tableClassName="with-image"
        minLoadingTimeMs={200}
        initialPageSize={5}
        hidePagination={true}
        tableDataSource={dataSource}
        tableHeaders={[{ title: "IMAGE" }, { title: "PRODUCT NAME" }, { title: "REVENUE" }]}
        tableRowFactory={(item, index) => {
          return [<Products id={index + 1} />, item.name, formatMoney(item.revenueGenerated)];
        }}
      />
    </Card>
  );
}
