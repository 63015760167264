import { useCallback } from "react";
import { useTimeLocationFilters } from "../../state/globalFilters";
import { BeaconsApi } from "../../api";
import { getApiConfig } from "../../state/configuration";
import { isHttpOk } from "../../services/api";
import { logErrorSilently } from "../../services/alerts";
import { PaginatedTable } from "../widgets/PaginatedTable";
import Card from "../cards/Card";

import "./TopAreas.scss";

export function TopAreas(props: {}) {
  const filters = useTimeLocationFilters();

  const dataSource = useCallback(async () => {
    try {
      const api = new BeaconsApi(getApiConfig());
      const result = await api.getTopAreas({
        size: 5,
        ...filters,
      });
      if (isHttpOk(result)) {
        return {
          count: result.data.length,
          items: result.data,
        };
      } else {
        logErrorSilently(result);
      }
    } catch (err) {
      logErrorSilently(err);
    }
    return {
      count: 0,
      items: [],
    };
  }, [filters]);

  return (
    <Card>
      <PaginatedTable
        mainHeader={
          <header>
            <h1>Top Areas</h1>
          </header>
        }
        className="areas-table"
        minLoadingTimeMs={200}
        initialPageSize={5}
        hidePagination={true}
        tableDataSource={dataSource}
        tableHeaders={[
          { title: "AREA" },
          { title: "BEACON CONTACTS" },
        ]}
        tableRowFactory={(item, index) => {
          return [item.name, item.numberOfContacts];
        }}
      />
    </Card>
  );
}
