export function isProvided<T>(i: T | undefined | null): i is T {
  return i !== undefined && i !== null;
}

export function tryParseJson<T>(text: T | string | undefined | null): T | null {
  try {
    if (text && typeof text === "string") {
      return JSON.parse(text);
    } else if (text && typeof text === "object") {
      return text;
    }
  } catch {
    return null;
  }
  return null;
}

export function imageExists(url: string | undefined | null) {
  return new Promise<boolean>((resolve) => {
    if (url && url.length > 0) {
      const img = document.createElement("img");
      img.onload = () => {
        img.remove();
        resolve(true);
      };
      img.onerror = () => {
        img.remove();
        resolve(false);
      };
      img.setAttribute("src", url);
    } else {
      resolve(false);
    }
  });
}

export interface CancellablePromise<T> extends Promise<T> {
  cancel: () => void;
}

export function makeCancellable<T>(promise: Promise<T>): CancellablePromise<T> {
  const state = { isCancelled: false };

  const enriched = new Promise<T>((resolve, reject) => {
    promise
      .then((...args) => !state.isCancelled && resolve(...args))
      .catch((error) => !state.isCancelled && reject(error));
  });

  Object.defineProperty(enriched, "cancel", {
    enumerable: false,
    configurable: false,
    writable: false,
    value: () => {
      state.isCancelled = true;
    },
  });

  return enriched as CancellablePromise<T>;
}

export const mouseEvents = [
  "onClick",
  "onContextMenu",
  "onDoubleClick",
  "onDrag",
  "onDragEnd",
  "onDragEnter",
  "onDragExit",
  "onDragLeave",
  "onDragOver",
  "onDragStart",
  "onDrop",
  "onMouseDown",
  "onMouseEnter",
  "onMouseLeave",
  "onMouseMove",
  "onMouseOut",
  "onMouseOver",
  "onMouseUp",
];
export const touchEvents = ["onTouchCancel", "onTouchEnd", "onTouchMove", "onTouchStart"];
export const keyboardEvents = ["onKeyDown", "onKeyPress", "onKeyUp"];
export const focusEvents = ["onFocus", "onBlur"];

/**
 * Returns an object with on-event callback props curried with provided args.
 * @param {Object} props Props passed to a component.
 * @param {Function=} getArgs A function that returns argument(s) on-event callbacks
 *   shall be curried with.
 */
export const makeEventProps = (props: any, getArgs: (event: string) => any) => {
  const eventProps: any = {};

  [...mouseEvents, ...touchEvents, ...keyboardEvents, ...focusEvents].forEach((eventName) => {
    if (props[eventName]) {
      eventProps[eventName] = (event: any) =>
        getArgs ? props[eventName](event, getArgs(eventName)) : props[eventName](event);
    }
  });

  return eventProps;
};
