import { useCallback, useState } from "react";
import {
  CustomAudienceType,
  MessageInteractionPerformanceSortColumn,
  MessageInteractionPerformanceView,
  MessageRevenuePerformanceSortColumn,
  MessageRevenuePerformanceView,
  MessagesApi,
  NotificationMark,
  SortDirection,
} from "../../../api";
import { formatDateTime, formatPercentage, useMoneyFormatter } from "../../../services/format";
import { useStateEx } from "../../../services/hooks";
import { useLocalizedText } from "../../../services/localization";
import { generateSortableHeader, toggleSortForHeader } from "../../../services/table";
import { getApiConfig } from "../../../state/configuration";
import { useDeploymentConfig } from "../../../state/deployment";
import { useTimeLocationFilters } from "../../../state/globalFilters";
import { Modal, useModal } from "../../modals/Modal";
import { Header, PaginatedTable } from "../../widgets/PaginatedTable";
import { LabelCampaign } from "./LabelCampaign";
import { NotificationUsersTable } from "./NotificationUsersTable";

import "./LocalGlobal.scss";

type Props = {
  audienceType?: CustomAudienceType;
  pageSize?: number | number[];
};

interface State {
  messageId?: number;
  flag?: NotificationMark;
}

function LocalGlobalRevenue({ audienceType, pageSize }: Props) {
  const texts = useLocalizedText();
  const filters = useTimeLocationFilters();
  const { formatMoney } = useMoneyFormatter();
  const [headers, setHeaders] = useState<Header[]>([
    generateSortableHeader(texts.notificationColumnHeaders.items.labelAndCampaign),
    generateSortableHeader(texts.notificationColumnHeaders.items.startDate),
    generateSortableHeader(texts.notificationColumnHeaders.items.sent),
    generateSortableHeader(texts.notificationColumnHeaders.items.received),
    generateSortableHeader(texts.notificationColumnHeaders.items.read),
    generateSortableHeader(texts.notificationColumnHeaders.items.clicked),
    generateSortableHeader(texts.notificationColumnHeaders.items.redeemed),
    generateSortableHeader(texts.notificationColumnHeaders.items.conversion),
    texts.notificationColumnHeaders.items.transactions,
    texts.notificationColumnHeaders.items.revenue,
    texts.notificationColumnHeaders.items.salesConversionRate,
  ]);

  const sortedHeader = headers.find((h) => h.sortable && h.sortDirection !== SortDirection.None);
  const sortTitle = sortedHeader?.title;
  const sortDirection = sortedHeader?.sortDirection ?? SortDirection.Descending;
  const sortColumn = (() => {
    const items = texts.notificationColumnHeaders.items;
    switch (sortTitle) {
      case items.labelAndCampaign.title:
        return MessageRevenuePerformanceSortColumn.Label;
      case items.startDate.title:
        return MessageRevenuePerformanceSortColumn.CampaignStartDate;
      case items.sent.title:
        return MessageRevenuePerformanceSortColumn.Sent;
      case items.received.title:
        return MessageRevenuePerformanceSortColumn.Delivered;
      case items.read.title:
        return MessageRevenuePerformanceSortColumn.Read;
      case items.clicked.title:
        return MessageRevenuePerformanceSortColumn.Clicked;
      case items.redeemed.title:
        return MessageRevenuePerformanceSortColumn.Redeemed;
      case items.conversion.title:
        return MessageRevenuePerformanceSortColumn.NmConversions;
      default:
        return MessageRevenuePerformanceSortColumn.Sent;
    }
  })();

  const toggleSort = useCallback(
    (index: number) => {
      setHeaders((prev) => toggleSortForHeader(prev, index));
    },
    [setHeaders]
  );

  const loadPage = useCallback(
    (skip: number, limit: number) => {
      const config = getApiConfig();
      const api = new MessagesApi(config);

      return api.getRevenuePerformance({
        audienceType: audienceType,
        sortDirection: sortDirection,
        sortColumn: sortColumn,
        skip: skip,
        limit: limit,
        ...filters,
      });
    },
    [filters, audienceType, sortDirection, sortColumn]
  );

  const modalId = `revenue-push-notifications-${audienceType || "all"}`;
  const { openModal, closeModal } = useModal(modalId);
  const { state, mergeState } = useStateEx<State>({});

  const renderCells = useCallback(
    (item: MessageRevenuePerformanceView, _: number): React.ReactNode[] => {
      const renderCount = (flag: NotificationMark, count: number) => {
        if (count === 0) return count;
        return (
          <span
            onClick={() => {
              mergeState({ messageId: item.id, flag: flag });
              openModal();
            }}
            className="clickable"
          >
            {count}
          </span>
        );
      };
      return [
        <LabelCampaign
          id={item.id}
          label={item.label}
          messageTitle={item.offerTitle}
          campaignTitle={item.campaignTitle}
          convertingOfferLabel={item.convertingOfferLabel}
        />,
        formatDateTime(item.campaignStartDate, "dd.MM.yyyy"),
        renderCount(NotificationMark.Sent, item.nmSent),
        renderCount(NotificationMark.Delivered, item.nmDelivered),
        renderCount(NotificationMark.Read, item.nmRead),
        renderCount(NotificationMark.Clicked, item.nmClicked),
        renderCount(NotificationMark.Redeemed, item.nmRedeemed),
        item.convertingOfferLabel ? renderCount(NotificationMark.Converted, item.nmConversions) : "-",
        item.nmSales,
        formatMoney(item.revenue),
        formatPercentage(item.salesConversionRate, 0),
      ];
    },
    [formatMoney, mergeState, openModal]
  );

  return (
    <section className="notification-campaigns">
      <PaginatedTable
        className="local-global-table"
        tableClassName="with-image"
        tableHeaders={headers}
        tableDataSource={loadPage}
        tableRowFactory={renderCells}
        minLoadingTimeMs={200}
        hidePagination={false}
        initialPageSize={pageSize || [5, 10]}
        onHeaderClick={toggleSort}
      />
      {state.messageId && state.flag && (
        <Modal id={modalId} onRequestClose={closeModal} className="wide-modal">
          <section>
            <NotificationUsersTable messageId={state.messageId} flag={state.flag} />
          </section>
        </Modal>
      )}
    </section>
  );
}

function LocalGlobalInteractions({ audienceType, pageSize }: Props) {
  const texts = useLocalizedText();
  const filters = useTimeLocationFilters();
  const [headers, setHeaders] = useState<Header[]>([
    generateSortableHeader(texts.notificationColumnHeaders.items.labelAndCampaign),
    generateSortableHeader(texts.notificationColumnHeaders.items.startDate),
    generateSortableHeader(texts.notificationColumnHeaders.items.sent),
    generateSortableHeader(texts.notificationColumnHeaders.items.received),
    generateSortableHeader(texts.notificationColumnHeaders.items.read),
    generateSortableHeader(texts.notificationColumnHeaders.items.clicked),
    texts.notificationColumnHeaders.items.clickRate,
    generateSortableHeader(texts.notificationColumnHeaders.items.redeemed),
    texts.notificationColumnHeaders.items.redemptionRate,
    generateSortableHeader(texts.notificationColumnHeaders.items.conversion),
    texts.notificationColumnHeaders.items.conversionRate,
  ]);

  const sortedHeader = headers.find((h) => h.sortable && h.sortDirection !== SortDirection.None);
  const sortTitle = sortedHeader?.title;
  const sortDirection = sortedHeader?.sortDirection ?? SortDirection.Descending;
  const sortColumn = (() => {
    const items = texts.notificationColumnHeaders.items;
    switch (sortTitle) {
      case items.labelAndCampaign.title:
        return MessageInteractionPerformanceSortColumn.Label;
      case items.startDate.title:
        return MessageInteractionPerformanceSortColumn.CampaignStartDate;
      case items.sent.title:
        return MessageInteractionPerformanceSortColumn.Sent;
      case items.read.title:
        return MessageInteractionPerformanceSortColumn.Read;
      case items.clicked.title:
        return MessageInteractionPerformanceSortColumn.Clicked;
      case items.redeemed.title:
        return MessageInteractionPerformanceSortColumn.Redeemed;
      case items.received.title:
        return MessageInteractionPerformanceSortColumn.Delivered;
      case items.conversion.title:
        return MessageInteractionPerformanceSortColumn.NmConversions;
      default:
        return MessageInteractionPerformanceSortColumn.Sent;
    }
  })();

  const toggleSort = useCallback(
    (index: number) => {
      setHeaders((prev) => toggleSortForHeader(prev, index));
    },
    [setHeaders]
  );

  const loadPage = useCallback(
    (skip: number, limit: number) => {
      const config = getApiConfig();
      const api = new MessagesApi(config);
      return api.getInteractionPerformance({
        audienceType: audienceType,
        sortDirection: sortDirection,
        sortColumn: sortColumn,
        skip: skip,
        limit: limit,
        ...filters,
      });
    },
    [filters, audienceType, sortColumn, sortDirection]
  );

  const modalId = `interaction-push-notifications-${audienceType || "all"}`;
  const { openModal, closeModal } = useModal(modalId);
  const { state, mergeState } = useStateEx<State>({});

  const renderCells = useCallback(
    (item: MessageInteractionPerformanceView, index: number): React.ReactNode[] => {
      const renderCount = (flag: NotificationMark, count: number) => {
        if (count === 0) return count;
        return (
          <span
            onClick={() => {
              mergeState({ messageId: item.id, flag: flag });
              openModal();
            }}
            className="clickable"
          >
            {count}
          </span>
        );
      };
      return [
        <LabelCampaign
          id={item.id}
          label={item.label}
          messageTitle={item.offerTitle}
          campaignTitle={item.campaignTitle}
          convertingOfferLabel={item.convertingOfferLabel}
        />,
        formatDateTime(item.campaignStartDate, "dd.MM.yyyy"),
        renderCount(NotificationMark.Sent, item.nmSent),
        renderCount(NotificationMark.Delivered, item.nmDelivered),
        renderCount(NotificationMark.Read, item.nmRead),
        item.hasCallToAction ? renderCount(NotificationMark.Clicked, item.nmClicked) : "N/A",
        item.hasCallToAction ? formatPercentage(item.clickThroughRate, 0) : "N/A",
        renderCount(NotificationMark.Redeemed, item.nmRedeemed),
        formatPercentage(item.redeemedRate, 0),
        item.convertingOfferLabel ? renderCount(NotificationMark.Converted, item.nmConversions) : "-",
        item.convertingOfferLabel ? formatPercentage(item.conversionRate, 0) : "-",
      ];
    },
    [mergeState, openModal]
  );

  const renderModal = useCallback(
    (messageId: number, flag: NotificationMark) => (
      <Modal id={modalId} onRequestClose={closeModal} className="wide-modal">
        <section>
          <NotificationUsersTable messageId={messageId} flag={flag} />
        </section>
      </Modal>
    ),
    [modalId, closeModal]
  );

  return (
    <section className="notification-campaigns">
      <PaginatedTable
        className="local-global-table"
        tableClassName="with-image"
        tableHeaders={headers}
        tableDataSource={loadPage}
        tableRowFactory={renderCells}
        minLoadingTimeMs={200}
        hidePagination={false}
        initialPageSize={pageSize || [5, 10]}
        onHeaderClick={toggleSort}
      />
      {state.messageId && state.flag && renderModal(state.messageId, state.flag)}
    </section>
  );
}

export function LocalGlobal(props: Props) {
  const deployment = useDeploymentConfig();
  if (deployment.config.disablePurchaseHistories) {
    return <LocalGlobalInteractions {...props} />;
  } else {
    return <LocalGlobalRevenue {...props} />;
  }
}
