import { Translations } from "../services/localization";

export function localizeUserInterfaceText(translations: Translations) {
  const words = {
    Store: translations.store.singular,
    STORE: translations.store.singular.toUpperCase(),
    store: translations.store.singular.toLowerCase(),
    Stores: translations.store.plural,
    STORES: translations.store.plural.toUpperCase(),
    stores: translations.store.plural.toLowerCase(),
  };

  return {
    analyticsPage: {
      title: `Analytics`,
      items: {
        appDownloads: {
          title: `App Downloads`,
          tooltip: `The total number of times your app has been installed on mobile devices.`,
        },
        appRegistrations: {
          title: `App Registrations`,
          tooltip: `The total number of new users that registered within the selected timeframe.`,
        },
        newAppCustomers: {
          title: `New App Customers`,
          tooltip: `The total number of registered users that made their first purchase within the selected timeframe and location.`,
        },
        totalAppCustomers: {
          title: `Total App Customers`,
          tooltip: `The total number of registered users who made a purchase in your ${words.stores} within the selected timeframe and location.`,
        },
        appOpenings: {
          title: `App Openings`,
          tooltip: `Number of times app was opened in the selected time`,
        },
        totalAppUsers: {
          title: `Total App Users`,
          tooltip: `The total number of registered users who created an account in your mobile app until selected timeframe.`,
        },
        conversionStats: {
          title: `Conversions`,
          tooltip: `The total number of offer conversations in the selected timeframe, plus the rate at which offers are converted.`,
        },
        appRevenue: {
          title: `App Revenue`,
          tooltip: `The total amount of revenue generated by your app customers`,
        },
        appTransactions: {
          title: `App Transactions`,
          tooltip: `The total number of purchases made by your app customers`,
        },
        avgAppTransactions: {
          title: `Average App Transaction Value`,
          tooltip: `The average value of your app transactions`,
        },
        avgQtyPerAppTransactions: {
          title: `Avg. Quantity / App Transaction`,
          tooltip: `The average quantity purchased per app transaction`,
        },
        customerVisits: {
          title: `Customer Visits`, // TODO - figure out how to customize this based on translations (useDeploymentConfig)
          tooltip: `The total number of visits by customers`,
        },
        salesConvRate: {
          title: `App Sales Conv. Rate`,
          tooltip: `The ratio of the ${words.store} visits to app transactions`,
        },
        avgRedemptionRate: {
          title: `Avg Redemption Rate`,
          tooltip: `The ratio of opened messages compared to redeemed messages`,
        },
        appInteractionRate: {
          title: `App Interaction Rate`,
          tooltip: `The percentage of new users that interacted with the App`,
        },
        userVisits: {
          title: `User Visits`, // TODO - figure out how to customize this based on translations (useDeploymentConfig)
          tooltip: `The total number of visits by app users`,
        },
        avgTimeSpent: {
          title: `Avg Time Spent`,
          tooltip: `Average time spent by visitors`,
        },
        topPerformingArea: {
          title: `Top Performing Area`,
          tooltip: `Area with most visitors`,
        },
      },
    },
    appCustomersPage: {
      title: `App Customers`,
      items: {
        newCustomers: {
          title: `First-time Customers`,
          tooltip: `The total number of customers that made their first purchase within the selected timeframe and location.`,
        },
        vipCustomers: {
          title: `VIP Customers`,
          tooltip: `Your top customers by generated revenue.`,
        },
        mostActiveCustomers: {
          title: `Most Active Customers`,
          tooltip: `Number of customers who actively interact with messages received based on beacon contact.`,
        },
        loyalCustomers: {
          title: `Loyal Customers`,
          tooltip: `Number of customers with repeat purchases.`,
        },
        newVisitors: {
          title: `First-time Visitors`,
          tooltip: `The total number of customers that made their first visit in the selected timeframe and location.`,
        },
        visitors: {
          title: `Visitors`,
          tooltip: `The total number of customers that visited within the selected timeframe and location.`,
        },
        convertedVisitors: {
          title: `Converted Visitors`,
          tooltip: `The total number of customers that were converted within the selected timeframe and location.`,
        },
        convertedVisitorRate: {
          title: `Converted Visitors Rate`,
          tooltip: `The rate of tracked messages that were converted within the selected timeframe and location.`,
        },
      },
    },
    revenueStatisticsPage: {
      title: `Revenue Statistics`,
      items: {
        recurringTransactions: {
          title: `Recurring Transactions`,
          tooltip: `The number of purchases where a customer made at least 2 transactions at the same ${words.store}`,
        },
        avgTransactionValueReturningCustomers: {
          title: `Average Transaction Value`,
          tooltip: `The average value of your app transactions from returning customers`,
        },
        transactions: {
          title: `Transactions`,
          tooltip: `The total number of purchases made by your app customers`,
        },
        revenue: {
          title: `Revenue`,
          tooltip: `The total amount of revenue generated by your app customers`,
        },
        newVsReturning: {
          title: `New vs. Returning Customers`,
          tooltip:
            `Percentage of new customers coming into ${words.stores}.<br/>` +
            `New = the number of first-time customers (customer is defined as a visitor who ` +
            `made a purchase) during the selected time frame.` +
            `<br/>Returning = any customer who made his second/third/fourth/etc. ` +
            `purchase during the selected time frame`,
        },
      },
    },
    appTouchpointsPage: {
      title: `App Touchpoints`,
      bulletedItems: {
        touchpointsGraph: {
          text: `The total number of contacts your app customers had with your brand. App touchpoints include any of the following:`,
          bulletPoints: [
            `Were sent a push notification (local or global)`,
            `Opened the mobile app`,
            `Logged into the mobile app`,
            `Registered in the mobile app`,
            `Had a Beacon contact`,
          ],
          title: `App Touchpoints`,
        },
      },
      items: {
        appRevenue: {
          title: `App Revenue`,
          tooltip: `The total amount of revenue generated by your app customers`,
        },
        appTransactions: {
          title: `App Transactions`,
          tooltip: `The total number of purchases made by your app customers`,
        },
        conversionUplift: {
          title: `Conversion Uplift`,
          tooltip: `The percentage of total increase in your conversion rate thanks to the beaconsmind solution`,
        },
        transactionValueUplift: {
          title: `Avg. Transaction Value Uplift`,
          tooltip: `The percentage of total increase in your average transaction value thanks to the beaconsmind solution`,
        },
        interactionUplift: {
          title: `Interaction Uplift`,
          tooltip: `The percentage of total increase in your interaction rate thanks to the beaconsmind solution`,
        },
        interactionRate: {
          title: `Interaction Rate`,
          tooltip: `The percentage of users that are engaging with the app`,
        },
        storeVisitUplift: {
          title: `${words.Store} Visit Uplift`,
          tooltip: `The percentage of total increase in your ${words.store} visits thanks to the beaconsmind solution`,
        },
        appRegistrations: {
          title: `App Registrations`,
          tooltip: `The total number of registrations coming into the app`,
        },
        appLogins: {
          title: `App Logins`,
          tooltip: `The total times user logged in into the app`,
        },
        appOpenings: {
          title: `App Openings`,
          tooltip: `The total times app was opened by user`,
        },
      },
    },
    customerDetailsPage: {
      title: `Customer Details`,
      items: {
        totalRevenue: {
          title: `Total Revenue`,
          tooltip: `The total revenue generated by this customers`,
        },
        sales: {
          title: `Sales`,
          tooltip: `The total number of purchases made by this customer`,
        },
        productsPerBasket: {
          title: `Products Per Basket`,
          tooltip: `The average quantity purchased per transaction`,
        },
        storeVisits: {
          title: `${words.Store} Visits`,
          tooltip: `The total number of ${words.store} visits by this customers`,
        },
        name: {
          title: `NAME`,
          tooltip: `Label or Id+Title of Push Notification`,
        },
        sent: {
          title: `SENT`,
          tooltip: `Date that Push Notification was sent out from our Suite`,
        },
        received: {
          title: `RECEIVED`,
          tooltip: `After Push Notification was sent, time until it was received on App user's device`,
        },
        read: {
          title: `READ`,
          tooltip: `After Push Notification was sent, time until it was read by App user`,
        },
        clicked: {
          title: `CLICKED`,
          tooltip: `After Push Notification was sent, time until App user clicked on the call to action link in the Push Notification`,
        },
        redeemed: {
          title: `REDEEMED`,
          tooltip: `After Push Notification was sent, time until App user redeemed a coupon`,
        },
      },
    },
    visitorStatisticsPage: {
      title: `Visitor Statistics`,
      items: {
        minVisitingTime: {
          title: `Minimum Visiting Time`,
          tooltip: `The minimum time app customers spent in your ${words.store}(s)`,
        },
        maxVisitingTime: {
          title: `Maximum Visiting Time`,
          tooltip: `The maximum time app customers spent in your ${words.store}(s)`,
        },
        avgVisitingTime: {
          title: `Average Visiting Time`,
          tooltip: `The average time app customers spent in your ${words.store}(s)`,
        },
        peakVisitingHour: {
          title: `Peak Visiting Hour`,
          tooltip: `The hour of the day with the highest number of ${words.store} visitors`,
        },
        peakVisitingDay: {
          title: `Peak Visiting Day`,
          tooltip: `The day of the week with the highest number of ${words.store} visitors`,
        },
        storeVisits: {
          title: `${words.Store} Visits`,
          tooltip: `The total number of ${words.store} visits by app customers`,
        },
        recurringVisits: {
          title: `Recurring Visits`,
          tooltip: `The total number of visits from app customers who came back to your ${words.stores}`,
        },
        avgVisitDuration: {
          title: `Avg. Visit Duration`,
          tooltip: `The average amount of time app visitors spent inside your ${words.store} during a visit`,
        },
        newVsReturning: {
          title: `New vs. Returning Vistors`,
          tooltip:
            `Percentage of new visitors coming into ${words.stores}.<br/>` +
            `New = the number of first-time visitors during the selected time frame.` +
            `<br/>Returning = any visitor who made his second/third/fourth/etc. visit to your ${words.store}`,
        },
        date: {
          title: `DATE`,
          tooltip: `Date`,
        },
        day: {
          title: `DAY`,
          tooltip: `Day of the week`,
        },
      },
    },
    heatmapPage: {
      title: `Heatmap`,
      noStoreWarning: `There are no configured ${words.stores}. Please add at least one ${words.store}.`,
      noSelectionWarning: `Please select a ${words.store} to show its layout.`,
    },
    appTouchpointsTable: {
      title: `App Touchpoints`,
      items: {
        date: {
          title: `DATE`,
          tooltip: `Date`,
        },
        day: {
          title: `DAY`,
          tooltip: `Day of the week`,
        },
        hour: {
          title: `HOUR`,
          tooltip: `Hour of the day`,
        },
        startOfWeek: {
          title: `WEEK FROM`,
          tooltip: `Start of the week`,
        },
        endOfWeek: {
          title: `TILL`,
          tooltip: `End of the week`,
        },
        local: {
          title: `LOCAL`,
          tooltip: `Local`,
        },
        global: {
          title: `GLOBAL`,
          tooltip: `Global`,
        },
        appLogins: {
          title: `APP LOGINS`,
          tooltip: `The number of times users logged in into the app`,
        },
        appRegistrations: {
          title: `APP REGISTRATIONS`,
          tooltip: `The number of registrations coming into the app`,
        },
        appOpens: {
          title: `APP OPENS`,
          tooltip: `The number of times app was opened by users`,
        },
        beaconContacts: {
          title: `BEACON CONTACTS`,
          tooltip: `Beacon Contacts`,
        },
      },
    },
    notificationColumnHeaders: {
      items: {
        labelAndCampaign: {
          title: `LABEL/TITLE`,
          tooltip: `Message Label or Id & Title. Campaign Name below`,
        },
        startDate: {
          title: `START DATE`,
          tooltip: `Start of Campaign Validity`,
        },
        conversion: {
          title: `CONVERSION`,
          tooltip: `No. of Conversions`,
        },
        conversionRate: {
          title: `CONVERSION RATE`,
          tooltip: `No. of Conversion / No. of Sent`,
        },
        sent: {
          title: `SENT`,
          tooltip: `Push Notification was sent out from our Suite`,
        },
        received: {
          title: `RECEIVED`,
          tooltip: `Push Notification was received on App user's device`,
        },
        redeemed: {
          title: `REDEEMED`,
          tooltip: `App user redeemed a coupon`,
        },
        revenue: {
          title: `REVENUE`,
          tooltip: `Revenue generated correlated to the Push Notification`,
        },
        transactions: {
          title: `TRANSACTIONS`,
          tooltip: `No. of Transactions correlated to the Push Notification`,
        },
        avgTransactionValue: {
          title: `AVG. T. VALUE`,
          tooltip: `Average Transaction Value`,
        },
        avgQuantity: {
          title: `AVG. QUANTITY`,
          tooltip: `Average Quantity`,
        },
        noOfVisits: {
          title: `NO. OF VISITS`,
          tooltip: `Number of Visits`,
        },
        salesConversionRate: {
          title: `SALES CONV. RATE`,
          tooltip: `Sales Conversion Rate`,
        },
        read: {
          title: `READ`,
          tooltip: `Push Notification was read by App user`,
        },
        clicked: {
          title: `CLICKED`,
          tooltip: `App user clicked on the call to action link in the Push Notification`,
        },
        clickRate: {
          title: `CLICK RATE`,
          tooltip: `No. of Clicked / No. of Sent`,
        },
        redemptionRate: {
          title: `REDEMPTION RATE`,
          tooltip: `No. of Redeemed / No. of Sent`,
        },
      },
    },
    heatmap: {
      title: `Heatmap`,
      items: {
        newVisitors: {
          title: `New Visitors`,
          tooltip: `The number of app customers coming to your ${words.store} for the first time`,
        },
        returningVisitors: {
          title: `Returning Visitors`,
          tooltip: `The number of app customers coming back to your ${words.store}`,
        },
        avgVisitDuration: {
          title: `Average Visit Duration`,
          tooltip: `The average amount of time app visitors spent inside your ${words.store} during a visit`,
        },
        bounceRate: {
          title: `Bounce Rate`,
          tooltip: `The percentage of app customers who leave the ${words.store} without making a purchase`,
        },
      },
      storeLegend: [
        {
          // green
          color: "#4ad991",
          title: `Best Performing ${words.Stores}`,
          tooltip: `${words.Stores} with a high number of visits (>70% of the best ${words.store})`,
        },
        {
          // yellow
          color: "#FFDA82",
          title: `Average Performing ${words.Stores}`,
          tooltip: `${words.Stores} with moderate visits (20% to 70% of the best ${words.store})`,
        },
        {
          // red
          color: "#f55e7b",
          title: `Poorly Performing ${words.Stores}`,
          tooltip: `${words.Stores} with very few visits (less than 20% of the best ${words.store})`,
        },
      ],
      beaconLegend: [
        {
          color: "#4ad991",
          title: `Best Performing Beacons`,
          tooltip: `Beacons with a high number of contacts (>70% of the best beacon)`,
        },
        {
          color: "#FFDA82",
          title: `Average Performing Beacons`,
          tooltip: `Beacons with moderate contacts (20% to 70% of the best beacon)`,
        },
        {
          color: "#f55e7b",
          title: `Poorly Performing Beacons`,
          tooltip: `Beacons with very few contacts (less than 20% of the best beacon)`,
        },
      ],
    },
    usersTable: {
      title: `Users`,
      items: {
        name: {
          title: `NAME`,
          tooltip: `Name`,
        },
        email: {
          title: `EMAIL`,
          tooltip: `Email`,
        },
        role: {
          title: `ROLE`,
          tooltip: `Role`,
        },
        status: {
          title: `STATUS`,
          tooltip: `Status`,
        },
      },
    },
    appSalesByCustomerType: {
      title: `App Sales`,
      newCustomerTooltip: {
        title: "New Customers",
        tooltip:
          "Shows customers who made their first purchase during the selected timeframe, and who had no prior visits.",
      },
      returningCustomerTooltip: {
        title: "Returning Customers",
        tooltip: `Shows customers who made a purchase before selected timeframe, or who visited the ${words.store} before.`,
      },
      items: {
        customerType: {
          title: `CUSTOMER TYPE`,
          tooltip: `Customer Type`,
        },
        revenue: {
          title: `REVENUE`,
          tooltip: `Revenue`,
        },
        transactions: {
          title: `TRANSACTION`,
          tooltip: `Transaction`,
        },
        avgTransactionValue: {
          title: `AVG. TRANSACTION VALUE`,
          tooltip: `Average Transaction Value`,
        },
        averageQuantity: {
          title: `AVG. QUANTITY`,
          tooltip: `Average Quantity`,
        },
        storeVisits: {
          title: `${words.STORE} VISITS`,
          tooltip: `${words.Store} Visits`,
        },
        salesConversionRate: {
          title: `SALES CONV. RATE`,
          tooltip: `Sales Conversion Rate`,
        },
      },
    },
    messages: {
      forceSend: {
        title: "FOR DEVELOPERS",
        tooltip: "Sends a message to customer immediately.",
      },
    },
    audienceEditor: {
      title: "Audience",
      items: {
        newRegistrations: {
          title: "New Registrations",
          tooltip: "Matches all customers who registered in the mobile app for the first time.",
        },
        withBeaconContact: {
          title: "With Beacon Contact",
          tooltip: `The message is sent to customers who had beacon contact in any of the locations during specified time.
          If the customer had beacon contact both in and out of specified time, the message will be sent to them as well.`,
        },
        liveBeaconContact: {
          title: "Live Beacon Contact",
          tooltip: `Message is sent (once) to the customer who approached any of the selected beacons during the selected time.
          Minimum required duration of contact can be defined.`,
        },
        lastBeaconContact: {
          title: "Last Beacon Contact",
          tooltip: `Matches customers whose last contact was any time before the specified time and in the specified location.`,
        },
        specificBeaconContact: {
          title: "Specific Beacon Contact",
          tooltip: `Matches customers whose have had a contact within the specified time and in the specified location.`,
        },
        productAndPurchases: {
          title: "Product and Purchases",
          tooltip: `Target customers who purchased specific products, orders with minimum Total or Discount, or made no purchases.`,
        },
        mostStoreVisits: {
          title: `Most ${words.Store} Visits`,
          tooltip: `Matches customers who had the most ${words.store} visits (at least one contact per day) across selected locations.`,
        },
        favoriteStore: {
          title: `Favorite ${words.Store}`,
          tooltip: `Matches customers whose selected favorite ${words.store} in the mobile app matches selected locations.`,
        },
        specificGender: {
          title: "Gender",
          tooltip: `Matches customers with specified gender.`,
        },
        specificAge: {
          title: "Age",
          tooltip: `Matches customers whose age belongs to selected category.`,
        },
        birthdayToday: {
          title: "Birthday",
          tooltip: `Matches customers whose birthday is on the day the message is being sent.`,
        },
        productPreference: {
          title: "Product Preference",
          tooltip: `Matches customers base on product categories available from the POS system, depends on the availability of and
          details of customer purchase data.`,
        },
        clickAndCollect: {
          title: "Click And Collect",
          tooltip: `Matches customer with an open click and collect order. This feature depends on the POS capabilities of the
          Beaconsmind client.`,
        },
        lastPurchase: {
          title: "Last Purchase",
          tooltip: `Matches customers who made their last purchase before the specified timeframe.`,
        },
        returningCustomers: {
          title: "Returning Customers",
          tooltip: `Matches customers who made a purchase and visited the ${words.store} again in the last 12 months. Messages will be sent
          only once per customer.`,
        },
        loyalCustomers: {
          title: "Loyal Customers",
          tooltip: `Matches customers customers with repeat purchases.`,
        },
        mostActiveCustomers: {
          title: "Most Active Customers",
          tooltip: `Number of customers who actively interact with messages received based on beacon contact.`,
        },
        vipCustomers: {
          title: "VIP Customers",
          tooltip: `Matches VIP customers.`,
        },
        visitWithoutPurchase: {
          title: "Omnichannel",
          tooltip: `Matches customers who visited a ${words.store}, but did not make a purchase in the selected timeframe.`,
        },
        customerEngagement: {
          title: "Customer Engagement",
          tooltip: `Matches customers with a Beacon contact which occurred while the ${words.store} was not open. Message is sent immediately
          during beacon contact, but only once per day.`,
        },
        customerSegment: {
          title: "Customer Segment",
          tooltip: `Matches customers who were places in the selected group. Groups can be configured using Customer Segment page.`,
        },
        receivedExistingOffer: {
          title: "Existing Offer",
          tooltip: `Matches customers who did / did not receive selected message in selected period.`,
        },
      },
    },
    payloadEditor: {
      title: "Deep Link payload",
      items: {
        keyValue: {
          title: "Payload item",
          tooltip: "Please enter the key and value.",
        },
      },
    },
    importDataPage: {
      title: `Import data`,
      items: {
        name: {
          title: `NAME`,
          tooltip: `Name`,
        },
      },
    },
    customersTable: {
      //title: `Customer Data`,
      items: {
        fullName: {
          title: `FULL NAME`,
          tooltip: `First and Last Names if known`,
        },
        email: {
          title: `EMAIL`,
          tooltip: `Email`,
        },
        loyaltyNumber: {
          title: `LOYALTY NM.`,
          tooltip: `Loyalty Number`,
        },
        nmTouchPoints: {
          title: `TOUCHPOINTS`,
          tooltip: `Number of Touchpoints`,
        },
        nmStoreVisits: {
          title: `${words.STORE} VISITS`,
          tooltip: `Number of ${words.Store} Visits`,
        },
        nmBeaconVisits: {
          title: `BEACON CONTACTS`,
          tooltip: `Number of Beacon Contact`,
        },
        totalRevenue: {
          title: `REVENUE`,
          tooltip: `Total amount of all purchases made by the customer`,
        },
        transactions: {
          title: `TRANSACTIONS`,
          tooltip: `Total transactions made by the customer`,
        },
      },
    },
    contactUsersTable: {
      title: `Users with Contact During selected time and in selected Beacons`,
      items: {
        id: {
          title: `ID`,
          tooltip: `User Id`,
        },
        username: {
          title: `USERNAME`,
          tooltip: `Username`,
        },
        platformDeviceId: {
          title: `PLATFORM DEVICE ID`,
          tooltip: `Platform Device Id`,
        },
      },
    },
    storesTable: {
      title: `Top ${words.Stores}`,
      items: {
        name: {
          title: `NAME`,
          tooltip: `Name`,
        },
        city: {
          title: `CITY`,
          tooltip: `City`,
        },
        area: {
          title: `AREA`,
          tooltip: `Area`,
        },
        nmOfBeacons: {
          title: `BEACONS INSTALLED`,
          tooltip: `Beacons Installed`,
        },
        revenue: {
          title: `REVENUE`,
          tooltip: `Revenue`,
        },
        customerVisits: {
          title: `CUSTOMER VISITS`,
          tooltip: `Customer Visits`,
        },
        beaconVisits: {
          title: `BEACON VISITS`,
          tooltip: `Beacon Visits`,
        },
        mostFavorited: {
          title: `MOST FAVORITED`,
          tooltip: `Most Favorited`,
        },
      },
    },
    beaconDataTable: {
      title: `Top Beacons`,
      items: {
        name: {
          title: `NAME`,
          tooltip: `Name`,
        },
        description: {
          title: `DESCRIPTION`,
          tooltip: `Description`,
        },
        areaAndStore: {
          title: `AREA, ${words.STORE}`,
          tooltip: `Area Name, ${words.Store} Name`,
        },
        maxVisitDurationSeconds: {
          title: `MAX CONTACT DURATION`,
          tooltip: `Max Contact Duration (in Seconds)`,
        },
        longestTimeSpent: {
          title: `LONGEST TIME SPENT`,
          tooltip: `Longest Time Spent`,
        },
        leastTimeSpent: {
          title: `LEAST TIME SPENT`,
          tooltip: `Least Time Spent`,
        },
        averageTimeSpent: {
          title: `AVERAGE TIME SPENT`,
          tooltip: `Average Time Spent`,
        },
      },
    },
    appInteractionUsersTable: {
      title: "Interaction Users",
      items: {
        name: { title: "NAME" },
        email: { title: "EMAIL" },
        nmOfInteractions: {
          title: "NO. OF INTERACTIONS",
          tooltip:
            "The number of interactions such as opening the App and reading, clicking or redeeming a notification.",
        },
      },
    },
    healthCheckPage: {
      title: `Health Checks`,
      items: {
        totalAppUsers: {
          title: `Total App Users`,
          tooltip: `The total number of registered users who created an account in your mobile app until selected timeframe.`,
        },
        allPermissionsEnabled: {
          title: `All Permissions Granted`,
          tooltip: `User has granted all permissions (Push notification, Bluetooth, and location).`,
        },
        pushNotificationEnabled: {
          title: `Push Notification Enabled`,
          tooltip: `The user has granted permission for the app to send push notifications.`,
        },
        bluetoothEnabled: {
          title: `Bluetooth Granted`,
          tooltip: `Bluetooth was enabled at the most recent health check.`,
        },
        locationAlwaysAllowed: {
          title: `Location Always Allowed`,
          tooltip: `User has granted 'Always Allow' location permission`,
        },
        locationAllowedWhileUsing: {
          title: `Location Allowed While Using App`,
          tooltip: `User has granted 'Allow While Using the App' location permission`,
        },
        locationFullPrecision: {
          title: `Full Precision`,
          tooltip: `User has granted Full/Fine location accuracy`,
        },
        locationEnabled: {
          title: `Total Location Enabled`,
          tooltip: `All Users who have granted a location permission`,
        },
      },
    },
    messageForm: {
      items: {
        tokens: {
          title: `Tokens`,
          tooltip: `Personalize your message by adding tokens to the title or text.`,
        },
        title: {
          title: `Title`,
          tooltip: `The title of the push notification and the offer inside the app.`,
        },
        pushText: {
          title: `Push Text`,
          tooltip: `The body text of the push notification that is sent to the phone.`,
        },
        offerTitle: {
          title: `Offer Title`,
          tooltip: `The title shown in the offer inside the app after clicking on the push notification.`,
        },
        offerText: {
          title: `Offer Text`,
          tooltip: `The full text shown in the offer inside the app after clicking on the push notification.`,
        },
      },
    },
  };
}
