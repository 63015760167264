import { AxiosResponse } from "axios";
import { ResultStatus } from "../api";
import { ServiceResult } from "./types";

export function isAxiosResponse<T>(value: any): value is AxiosResponse<T> {
  const partial = value as Partial<AxiosResponse<T>>;
  return (
    partial.data !== undefined &&
    typeof partial.status === "number" &&
    typeof partial.statusText === "string" &&
    partial.headers !== undefined &&
    partial.config !== undefined
  );
}

export function isServiceResult(obj?: any): obj is ServiceResult<{}> {
  if (obj === undefined) return false;
  const result = obj as ServiceResult<{}>;
  return (
    result.status !== undefined &&
    result.success !== undefined &&
    typeof result.status === "number" &&
    typeof result.success === "boolean"
  );
}

export function isHttpOk(result: AxiosResponse<ServiceResult<{}> | {} | void>) {
  const isHttpOk = result.status >= 200 && result.status <= 299;
  if (!isHttpOk) return false;
  const data = result.data;
  if (isServiceResult(data)) {
    return data.status === ResultStatus.Success;
  } else {
    return true;
  }
}
