import { AxiosResponse } from "axios";
import { useCallback, useRef } from "react";
import { useHistory } from "react-router";
import { toast as alert } from "react-toastify";
import { CampaignDetails, CampaignModel, CampaignsApi, CampaignSort, CampaignStatus, CampaignView } from "../api";
import { CampaignCard } from "../app/cards/CampaignCard";
import Card from "../app/cards/Card";
import { Select } from "../app/forms/Select";
import { Download } from "../app/icons/Icons";
import { EditCampaign } from "../app/modals/EditCampaign";
import { Modal, useModal } from "../app/modals/Modal";
import { Result } from "../app/modals/utils";
import { AlertContent, ServerUnavailable } from "../app/widgets/Alerts";
import Loading from "../app/widgets/Loading";
import { PageHeader } from "../app/widgets/PageHeaders";
import { PaginatedCards, PaginatedCardsApi } from "../app/widgets/PaginatedCards";
import { confirmDialog } from "../services/alerts";
import { isHttpOk } from "../services/api";
import { useStateEx } from "../services/hooks";
import { ServiceResult } from "../services/types";
import { getApiConfig } from "../state/configuration";
import { useGlobalSearch } from "../state/globalSearch";

import "./Campaigns.scss";

type State = {
  formModified: boolean;
  model: CampaignDetails | null;
  isNew: boolean;
  sortOrder: CampaignSort;
  totalCount: number;
};

function Campaigns() {
  const sortOptions = [
    { label: "Sort: Newest", value: CampaignSort.Newest },
    { label: "Sort: Oldest", value: CampaignSort.Oldest },
  ];

  const pageApi = useRef<PaginatedCardsApi>(null);
  const history = useHistory();
  const { search } = useGlobalSearch();
  const { openModal, closeModal } = useModal();
  const { state, mergeState } = useStateEx<State>({
    formModified: false,
    totalCount: 0,
    model: null,
    isNew: true,
    sortOrder: sortOptions[0].value,
  });

  const openAddModal = () => {
    mergeState({ isNew: true });
    openModal();
  };

  const handleCancel = () => {
    if (state.formModified) confirmDialog("Form has unsaved changes. Discard all?", closeModalAndReset);
    else closeModalAndReset();
  };

  const closeModalAndReset = () => {
    mergeState({ model: null, formModified: false });
    closeModal();
  };

  const load = useCallback(
    (skip: number, limit: number) => {
      const config = getApiConfig();
      const api = new CampaignsApi(config);
      return api.get({
        search: search,
        sort: state.sortOrder,
        skip: skip,
        limit: limit,
      });
    },
    [search, state.sortOrder]
  );

  const loadingCardFactory = (key: number) => {
    return (
      <Card className="campaign-card loading" key={key}>
        <Loading />
      </Card>
    );
  };

  const loadedCardFactory = (model: CampaignView, key: number) => {
    const id = model.id;

    const editCampaign = async () => {
      const config = getApiConfig();
      const api = new CampaignsApi(config);
      try {
        const response = await api.getOne({ id: id });
        mergeState({
          model: response.data,
          isNew: false,
        });
        openModal();
      } catch (error) {
        alert.error(<ServerUnavailable diagnosticError={error} />);
      }
    };

    const deleteCampaign = async () => {
      const config = getApiConfig();
      const api = new CampaignsApi(config);
      const deletedMessage = `Deleted campaign: ${model.name}`;
      try {
        await api._delete({ id: id });
        if (pageApi.current) pageApi.current.refreshPage();
        alert.success(<AlertContent message={deletedMessage} />);
      } catch (error) {
        alert.error(<ServerUnavailable diagnosticError={error} />);
      }
    };
    const handleDeleteCampaign = async () => {
      const onConfirm = async () => await deleteCampaign();
      confirmDialog("Are you sure you want to delete this campaign? Once deleted you cannot restore it.", onConfirm);
    };

    const addMessage = () => {
      history.push(`/campaign/${id}/messages/new`);
    };

    return (
      <CampaignCard
        key={key}
        highlightedText={search}
        onAddMessageClick={addMessage}
        onDeleteClick={handleDeleteCampaign}
        onEditClick={editCampaign}
        {...model}
      />
    );
  };

  const saveCampaign = async (model: CampaignDetails) => {
    const config = getApiConfig();
    const api = new CampaignsApi(config);

    try {
      let message: string;
      let result: AxiosResponse<ServiceResult<CampaignModel>>;

      if (model.id === 0) {
        message = `Campaign '${model.name}' created.`;
        result = await api.create({
          createCampaignCommand: model,
        });
      } else {
        if (model.status === CampaignStatus.Deleted) {
          message = `Campaign '${model.name}' deleted.`;
          result = await api._delete({
            id: model.id,
          });
        } else {
          message = `Campaign '${model.name}' saved.`;
          result = await api.update({
            id: model.id,
            updateCampaignCommand: model,
          });
        }
      }

      if (isHttpOk(result)) {
        closeModalAndReset();
        alert.success(<AlertContent message={message} />);
        if (pageApi.current) pageApi.current.refreshPage();
        return Result.Ok;
      } else {
        return Result.Invalid(result);
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }

    return Result.Invalid();
  };

  return (
    <div className="page-content campaign-dashboard">
      <Modal onRequestClose={handleCancel}>
        <EditCampaign
          initial={state.model}
          onSave={saveCampaign}
          onCancel={handleCancel}
          onChange={() => {
            mergeState({ formModified: true });
          }}
        />
      </Modal>
      <PageHeader title="Campaigns" subTitle={`Found ${state.totalCount} campaigns`}>
        <div className="toolbar">
          <button className="new" onClick={openAddModal}>
            + New Campaign
          </button>
          <Select
            type="filter"
            required={true}
            value={state.sortOrder}
            options={sortOptions}
            onChange={(value) => {
              var valueNumber = value?.valueOf();
              if (typeof valueNumber === "number" && valueNumber in CampaignSort) {
                mergeState({ sortOrder: valueNumber });
              }
              if (pageApi.current) {
                pageApi.current.loadPage(0, true);
              }
            }}
          />
          <button className="download">
            <Download />
          </button>
        </div>
      </PageHeader>
      <PaginatedCards
        apiRef={pageApi}
        dataSource={load}
        minLoadingTimeMs={200}
        onAddNew={openAddModal}
        hideAddNew={false}
        hidePagination={false}
        loadingCardFactory={loadingCardFactory}
        loadedCardFactory={loadedCardFactory}
        onTotalCountChange={(count) => mergeState({ totalCount: count })}
      />
    </div>
  );
}

export default Campaigns;
