import { useCallback } from "react";
import { ProductsApi } from "../../api";
import { logErrorSilently } from "../../services/alerts";
import { isHttpOk } from "../../services/api";
import { useMoneyFormatter } from "../../services/format";
import { getApiConfig } from "../../state/configuration";
import { useTimeLocationFilters } from "../../state/globalFilters";
import Card from "../cards/Card";
import { PaginatedTable } from "../widgets/PaginatedTable";
import { Products } from "./TopProducts";

import "./ProductsTable.scss";

export function ProductsTable() {
  const { formatMoney } = useMoneyFormatter();
  const filters = useTimeLocationFilters();
  const dataSource = useCallback(
    async (offset: number, limit: number) => {
      const api = new ProductsApi(getApiConfig());
      const result = await api.getByRevenue({ skip: offset, limit: limit, ...filters });

      if (isHttpOk(result)) return result.data;
      else {
        logErrorSilently(result);
        return {
          count: 0,
          items: [],
        };
      }
    },
    [filters]
  );

  const tableHeaders = [{ title: "IMAGE" }, { title: "PRODUCT NAME" }, { title: "REVENUE" }];

  return (
    <Card className="all-products-table">
      <PaginatedTable
        mainHeader="All Top Products"
        tableClassName="with-image"
        minLoadingTimeMs={200}
        initialPageSize={10}
        tableDataSource={dataSource}
        tableHeaders={tableHeaders}
        tableRowFactory={(item, index) => {
          return [<Products id={index + 1} />, item.name, formatMoney(item.revenueGenerated)];
        }}
      ></PaginatedTable>
    </Card>
  );
}
