import { Select } from "../../../forms/Select";
import { editorClassName, EditorProps } from "./common";
import { LocationInput } from "./inputs/LocationInput";
import { TimeSpanRangeInput } from "./inputs/TimeSpanRangeInput";

export function MostStoreVisitsEditor(props: EditorProps) {
  const selectedLocations = props.value.mostStoreVisits?.locations || [];
  const selectedTimerange = props.value.mostStoreVisits?.time || undefined;
  const selectedPercentage = props.value.mostStoreVisits?.topPercent || 1;

  const percentageOptions = [
    { label: "top 1% visitors", value: 1 },
    { label: "top 10% visitors", value: 10 },
    { label: "top 25% visitors", value: 25 },
  ];

  return (
    <div className={editorClassName(props, "most-store-visits")}>
      <h2>{props.description}</h2>
      <div className="input-row single">
        <span className="prefix">For</span>
        <Select
          type="form"
          isMulti={false}
          required={true}
          placeholder="Top customers"
          errorLabel="Percentage"
          value={selectedPercentage}
          options={percentageOptions}
          onChange={(value) =>
            props.onChange({
              mostStoreVisits: {
                locations: selectedLocations,
                topPercent: typeof value === "number" ? value : 1,
                time: selectedTimerange,
              },
            })
          }
          error={props.error?.topPercent}
        />
      </div>
      <TimeSpanRangeInput
        prefix="During"
        rangeSemantics={true}
        value={selectedTimerange}
        onChange={(value) =>
          props.onChange({
            mostStoreVisits: {
              locations: selectedLocations,
              topPercent: selectedPercentage,
              time: value,
            },
          })
        }
        error={props.error?.time}
      />
      <LocationInput
        value={selectedLocations}
        onChange={(value) => {
          props.onChange({
            mostStoreVisits: {
              locations: value,
              topPercent: selectedPercentage,
              time: selectedTimerange,
            },
          });
        }}
        error={props.error?.locations}
      />
    </div>
  );
}
