import { WebConfigModel } from "./api";
import { Beaconsmind } from "./app/icons/Icons";
import { Translations } from "./services/localization";

import "./deployments.scss";

export interface UISettings {
  title: string;
  cssClass: string;
  loginLogo: React.ReactNode;
  navbarLogo: React.ReactNode;
  loginWallpaperSrc: string;
  translations: Translations;
}

export const defaultSettings: UISettings & WebConfigModel = {
  title: "beaconsmind Suite",
  loginLogo: <Beaconsmind />,
  loginWallpaperSrc: "images/wallpaper-login.jpg",
  navbarLogo: <Beaconsmind />,
  cssClass: "",
  translations: {
    store: {
      singular: "Store",
      plural: "Stores",
    },
  },
  disablePurchaseHistories: true,
  enablePurchasesImport: false,
  enableProductsImport: false,
  enableWhitelabelFeatures: false,
  enablePocFeatures: false,
  enableChangeLog: false,
};

export const deployments: Record<string, Partial<UISettings>> = {
  suxxess: {
    title: "Suxxess Suite",
    cssClass: "deployment-suxxess",
    loginLogo: <img src="variants/suxxess/suxxess-login-logo.png" alt="logo"></img>,
    navbarLogo: <img src="variants/suxxess/suxxess-navbar-logo.png" alt="logo"></img>,
  },
  skycell: {
    cssClass: "deployment-skycell",
    loginLogo: <img src="variants/skycell/logo.svg" alt="logo"></img>,
  },
  demo: {
    cssClass: "deployment-demo",
    loginWallpaperSrc: "variants/demo/login-wallpaper.jpg",
  },
  sportfive: {
    translations: {
      store: {
        singular: "Venue",
        plural: "Venues",
      },
    },
  },
};
