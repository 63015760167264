import clsx, { ClassValue } from "clsx";
import { cloneDeep } from "lodash";
import { PropsWithChildren, useCallback, useEffect } from "react";
import ReactModal from "react-modal";
import { createStore, useStore } from "../../services/streams";

import "./Modal.scss";

const modalStore = createStore<Record<string, any>>({}, "modal");

type ModalReturn<State> = {
  id: string;
  state: State | undefined;
  isOpen: boolean;
  isAnyOpen: boolean;
  openModal: (state: State) => void;
  closeModal: () => void;
  closeAllModals: () => void;
};

export function useModal<State = void>(modalId?: string): ModalReturn<State> {
  const id = modalId || "default";
  const [state, setState] = useStore(modalStore);
  const openIds = Object.keys(state);
  const isOpen = openIds.includes(id);

  return {
    id: id,
    isOpen: isOpen,
    state: isOpen ? (state[id] as State) : undefined,
    isAnyOpen: openIds.length > 0,
    openModal: useCallback(
      (state: State) => {
        setState((prev) => {
          const cloned = cloneDeep(prev);
          cloned[id] = state || true;
          return cloned;
        });
      },
      [id, setState]
    ),
    closeModal: useCallback(() => {
      setState((prev) => {
        const cloned = cloneDeep(prev);
        delete cloned[id];
        return cloned;
      });
    }, [id, setState]),
    closeAllModals: useCallback(() => {
      setState({});
    }, [setState]),
  };
}

export function Modal(
  props: PropsWithChildren<{
    id?: string;
    className?: ClassValue;
    onRequestClose?: () => void;
  }>
) {
  const id = props.id || "default";
  const [state, setState] = useStore(modalStore);
  const isOpen = id in state;

  const customModalStyle: ReactModal.Styles = {
    overlay: {
      inset: undefined,
      zIndex: 99,
    },
    content: {
      inset: undefined,
      position: undefined,
      padding: "0px",
      background: "white",
      border: "0px",
      overflow: "visible",
    },
  };

  useEffect(() => {
    if (!isOpen) return;

    const freezeWidth = () => {
      const width = window.innerWidth;
      document.body.style.width = `${width}px`;
    };

    document.body.classList.add("hide-scrollbars");
    freezeWidth();

    window.addEventListener("resize", freezeWidth);
    return () => {
      window.removeEventListener("resize", freezeWidth);
      document.body.classList.remove("hide-scrollbars");
      document.body.style.width = "";
    };
  }, [isOpen]);

  const tryClose = () => {
    if (typeof props.onRequestClose === "function") {
      props.onRequestClose();
    } else {
      setState((prev) => {
        const cloned = cloneDeep(prev);
        delete cloned[id];
        return cloned;
      });
    }
  };

  return (
    <ReactModal
      className={clsx(props.className)}
      isOpen={isOpen}
      onRequestClose={tryClose}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      preventScroll={true}
      style={customModalStyle}
    >
      {isOpen && props.children}
    </ReactModal>
  );
}
