import { useMemo, useState } from "react";
import { isProvided } from "../../services/objects";
import { extractInitials } from "../../services/string";
import { AvatarPlaceholder } from "../icons/Icons";

import "./Avatar.scss";

export function Avatar({ name, image }: { name: string; image?: string | null }) {
  const [invalid, setInvalid] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const avatar = useMemo(() => {
    const showImage = isProvided(image) && image.length && !invalid;
    if (!showImage) {
      const initials = extractInitials(name);
      return <div className="initials">{initials}</div>;
    }
    return (
      <>
        {!loaded && <AvatarPlaceholder className="placeholder" />}
        <img
          title={name}
          className={loaded ? "image" : "image loading"}
          src={image!}
          onLoad={() => setLoaded(true)}
          onError={() => setInvalid(true)}
          alt={`Profile for ${name}`}
        />
      </>
    );
  }, [name, image, loaded, invalid]);

  return <div className="avatar">{avatar}</div>;
}
