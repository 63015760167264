import React, { PropsWithChildren } from "react";
import { MapProvider } from "./MapProvider";
import { GeoProjection } from "d3-geo";

type Props = {
  width?: number;
  height?: number;
  projection?: string | GeoProjection;
  projectionConfig?: any;
  className?: string;
};

const ComposableMap = ({
  width = 800,
  height = 600,
  projection = "geoEqualEarth",
  projectionConfig = {},
  className = "",
  ...restProps
}: PropsWithChildren<Props>) => {
  return (
    <MapProvider width={width} height={height} projection={projection} projectionConfig={projectionConfig}>
      <svg viewBox={`0 0 ${width} ${height}`} className={`rsm-svg ${className}`} {...restProps} />
    </MapProvider>
  );
};

export default ComposableMap;
