import { differenceInSeconds, format, formatDuration } from "date-fns";
import { round } from "lodash";
import { useCallback } from "react";
import { isProvided } from "../pdf/shared/utils";
import { useDeploymentConfig } from "../state/deployment";
import { stringToDate } from "./date";

export function useMoneyFormatter() {
  const {
    config: { defaultCurrency },
  } = useDeploymentConfig();

  const postfix = defaultCurrency?.symbol || "€";
  const format = useCallback(
    (value: number | undefined) => {
      if (value === undefined) return undefined;
      if (value === 0 || value > 10000) {
        return formatNumber(value, 0) + " " + postfix;
      } else {
        return formatNumber(value, 2) + " " + postfix;
      }
    },
    [postfix]
  );

  return {
    formatMoney: format,
    convertMoney: (valueInDefaultCurrency: number | undefined) => valueInDefaultCurrency,
    currentPostfix: postfix,
  };
}

export function calcTrend({ current, previous }: { current: number | undefined; previous: number | undefined }) {
  if (typeof current === "number" && typeof previous === "number") {
    return previous === 0 ? undefined : ((current - previous) / previous) * 100;
  }
  return undefined;
}

function formatNumber(value: number, digits: number) {
  if (value.toLocaleString) {
    return value.toLocaleString("en-US", { maximumFractionDigits: digits });
  } else {
    return value.toFixed(digits);
  }
}

export function formatPercentage(value: number | undefined, digits?: number) {
  if (value === undefined) return undefined;
  return formatNumber(value * 100, isProvided(digits) ? digits : 0) + "%";
}

export function formatDecimal(value: number | undefined, digits?: number) {
  if (value === undefined) return undefined;
  return formatNumber(value, isProvided(digits) ? digits : 2);
}

export function formatApproximateDuration(seconds: number | undefined) {
  if (seconds === undefined) return "N/A";
  const [value, unit] = approximateDuration(seconds);
  if (value !== 1) {
    return formatNumber(value, 0) + " " + unit + "s";
  } else {
    return formatNumber(value, 0) + " " + unit;
  }
}

export function formatHour(hour: number) {
  const wholeHours = Math.floor(hour);
  let hoursText = wholeHours.toString(10);
  if (hoursText.length === 1) hoursText = "0" + hoursText;
  let minuteText = Math.round((hour - wholeHours) * 60).toString(10);
  if (minuteText.length === 1) minuteText = "0" + minuteText;
  return `${hoursText}:${minuteText}`;
}

export function approximateDuration(
  seconds: number,
  explicitUnit?: "sec" | "min" | "hour"
): [number, "sec" | "min" | "hour"] {
  if (!explicitUnit) {
    if (seconds < 2 * 60) {
      explicitUnit = "sec";
    } else if (seconds < 2 * 60 * 60) {
      explicitUnit = "min";
    } else {
      explicitUnit = "hour";
    }
  }
  if (explicitUnit === "sec") {
    return [round(seconds, 0), "sec"];
  } else if (explicitUnit === "min") {
    return [round(seconds / 60, 0), "min"];
  } else if (explicitUnit === "hour") {
    return [round(seconds / 3600, 0), "hour"];
  } else {
    return [round(seconds, 0), "sec"];
  }
}

export function formatHourAmPm(value: number | undefined) {
  if (value === undefined) return undefined;
  const formattedValue = value === 12 || value === 0 ? 12 : value > 12 ? value - 12 : value;
  if (value < 12) return formattedValue + " AM";
  if (value >= 12) return formattedValue + " PM";
}

export function formatDayOfWeek(value: number | undefined) {
  if (value === undefined || value <= 0 || value >= 8) return undefined;
  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  return days[value - 1];
}

export function formatDateTime(value?: Date | string | null, formatStr?: string) {
  if (!formatStr) {
    formatStr = "dd.MM.yyyy HH:mm:ss";
  }
  if (typeof value === "string") {
    return format(new Date(value), formatStr);
  } else if (value) {
    return format(value, formatStr);
  }
  return "";
}

export function formatDurationBetweenDates(
  start?: Date | string | null,
  end?: Date | string | null,
  referencePoint?: string
) {
  if (!start || !end) return "";

  const realStart = typeof start === "string" ? stringToDate(start, true) : start;
  const realEnd = typeof end === "string" ? stringToDate(end, true) : end;

  const totalSeconds = differenceInSeconds(realStart, realEnd);
  let adjustedSeconds = Math.abs(totalSeconds);
  let adjustedMinutes = 0;
  let adjustedHours = 0;
  let adjustedDays = 0;
  while (adjustedSeconds >= 60) {
    adjustedMinutes++;
    adjustedSeconds -= 60;
  }
  while (adjustedMinutes >= 60) {
    adjustedHours++;
    adjustedMinutes -= 60;
  }
  while (adjustedHours >= 24) {
    adjustedHours -= 24;
    adjustedDays++;
  }

  if (adjustedDays > 0) {
    adjustedMinutes = 0;
    adjustedSeconds = 0;
  }

  if (adjustedHours > 0) {
    adjustedSeconds = 0;
  }

  const duration = formatDuration({
    seconds: adjustedSeconds,
    minutes: adjustedMinutes,
    hours: adjustedHours,
    days: adjustedDays,
  });

  if (typeof referencePoint === "string" && referencePoint.length > 0) {
    if (totalSeconds < 0) {
      return duration + " after " + referencePoint;
    } else if (totalSeconds > 0) {
      return duration + " before " + referencePoint;
    } else {
      return "when " + referencePoint;
    }
  } else {
    if (totalSeconds < 0) {
      return "after " + duration;
    } else if (totalSeconds > 0) {
      return duration + " ago";
    } else {
      return "immediate";
    }
  }
}

export function formatPreciseDuration(seconds: number) {
  let h = Math.floor(seconds / 3600);
  seconds -= h * 3600;
  let m = Math.floor(seconds / 60);
  seconds -= m * 60;
  return formatDuration({
    hours: h,
    minutes: m,
    seconds: seconds,
  });
}
