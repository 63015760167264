import { useCallback, useEffect, useRef } from "react";
import { useStateEx } from "./hooks";
import useBorderAutoScroll from "./useBorderAutoScroll";

export interface Rectangle {
  top: number;
  left: number;
  bottom: number;
  right: number;
}

function getRelativeMousePosition(e: globalThis.MouseEvent) {
  const element = e.currentTarget as HTMLElement;
  const elementRect = element.getBoundingClientRect();
  const elementPageX = elementRect.left + window.scrollX;
  const elementPageY = elementRect.top + window.scrollY;
  const x = Math.round(((e.pageX - elementPageX) / (elementRect.right - elementRect.left)) * 1000) / 10;
  const y = Math.round(((e.pageY - elementPageY) / (elementRect.bottom - elementRect.top)) * 1000) / 10;
  return { x, y };
}

type RectangleSelectorOptions = {
  disabled?: boolean;
  onStart?: () => void;
  onFinish?: (selection: Rectangle | null) => void;
  container?: HTMLElement;
};

export default function useRectangleSelector({ disabled, onStart, onFinish, container }: RectangleSelectorOptions) {
  const { state, mergeState } = useStateEx<{
    selection: Rectangle | null;
    selecting: boolean;
  }>({ selecting: false, selection: null });

  const callbacks = useRef({ onStart, onFinish });
  callbacks.current = {
    onStart,
    onFinish,
  };

  useBorderAutoScroll(container, disabled || !state.selecting);

  const startSelecting = useCallback(
    (event: globalThis.MouseEvent) => {
      const coords = getRelativeMousePosition(event);
      mergeState({
        selecting: true,
        selection: { left: coords.x, top: coords.y, right: coords.x, bottom: coords.y },
      });
      if (callbacks.current.onStart) callbacks.current.onStart();
    },
    [mergeState]
  );

  const expandSelection = useCallback(
    (event: globalThis.MouseEvent) => {
      const coords = getRelativeMousePosition(event);
      mergeState((prev) => {
        return {
          selection: { ...prev.selection!, right: coords.x, bottom: coords.y },
        };
      });
    },
    [mergeState]
  );

  const { left: selectionLeft, top: selectionTop } = state.selection || { left: 0, top: 0 };

  const stopSelecting = useCallback(
    (event: globalThis.MouseEvent) => {
      const coords = getRelativeMousePosition(event);
      let next: Rectangle | null = {
        left: selectionLeft,
        top: selectionTop,
        right: coords.x,
        bottom: coords.y,
      };
      if (next.top === next.bottom || next.left === next.right) {
        next = null;
      }
      mergeState({
        selecting: false,
        selection: next,
      });
      if (callbacks.current.onFinish) callbacks.current.onFinish(next);
    },
    [mergeState, selectionLeft, selectionTop]
  );

  useEffect(() => {
    if (!container) return;
    if (!state.selecting) {
      container.addEventListener("mousedown", startSelecting);
      return () => {
        container.removeEventListener("mousedown", startSelecting);
      };
    } else {
      container.addEventListener("mousemove", expandSelection);
      container.addEventListener("mouseup", stopSelecting);
      container.addEventListener("mouseleave", stopSelecting);
      return () => {
        container.removeEventListener("mousemove", expandSelection);
        container.removeEventListener("mouseup", stopSelecting);
        container.removeEventListener("mouseleave", stopSelecting);
      };
    }
  }, [container, state.selecting, startSelecting, expandSelection, stopSelecting]);

  const clearSelection = useCallback(() => {
    mergeState({ selecting: false, selection: null });
  }, [mergeState]);

  return {
    selection: state.selection,
    clearSelection: clearSelection,
  };
}
