import { Fragment, useEffect } from "react";
import { Path, UseFormReturn, useWatch, useFormState } from "react-hook-form";
import { useCachedInstance } from "../../services/hooks";
import { pick } from "lodash";

/* eslint-disable no-console */
export function ShowFormInConsole<TModel>(props: {
  asText?: boolean;
  pick?: Path<TModel>[];
  control: UseFormReturn<TModel>["control"];
}) {
  const model = useWatch({ control: props.control });
  const picked = useCachedInstance(props.pick ? pick(model, ...props.pick) : model);

  useEffect(() => {
    if (props.asText) {
      const json = picked ? JSON.stringify(picked) : "undefined";
      console.log(json);
    } else {
      console.log(picked);
    }
  }, [props.asText, picked]);

  return <Fragment />;
}

export function ShowFormInDiv<TModel>(props: {
  asText?: boolean;
  pick?: Path<TModel>[];
  control: UseFormReturn<TModel>["control"];
}) {
  const model = useWatch({ control: props.control });
  const { errors } = useFormState({
    control: props.control,
  });
  const picked = props.pick ? pick(model, ...props.pick) : model;
  const valueJson = picked ? JSON.stringify(picked, undefined, 2) : "undefined";
  const errorJson = JSON.stringify(errors, undefined, 2);
  return (
    <div className="debug">
      <pre>value: {valueJson}</pre>
      <pre style={{ color: "red" }}>error: {errorJson}</pre>
    </div>
  );
}

export function ShowStateInDiv<TModel>(props: { state: TModel; asText?: boolean }) {
  const { state } = props;
  const stateJson = JSON.stringify(state, undefined, 2);
  return (
    <div className="debug">
      <pre>state: {stateJson}</pre>
    </div>
  );
}
