import Quill from "quill";
import quillEmoji from "quill-emoji";
import { RefObject, useEffect, useRef, useState } from "react";
import { FieldValues, Path, UseFormReturn, useController } from "react-hook-form";
import { ValidationError } from "../widgets/ValidationError";
import { ReactQuill } from "./ReactQuill";

// eslint-disable-next-line import/no-internal-modules
import "quill/dist/quill.snow.css";
// eslint-disable-next-line import/no-internal-modules
import "quill-emoji/dist/quill-emoji.css";
import "./RichText.scss";

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register(
  {
    "formats/emoji": EmojiBlot,
    "modules/emoji-shortname": ShortNameEmoji,
    "modules/emoji-toolbar": ToolbarEmoji,
    "modules/emoji-textarea": TextAreaEmoji,
  },
  true
);

type RichText = { rich?: string; plain?: string };

type RichTextProps<TModel extends FieldValues, TPath extends Path<TModel>> = {
  control: UseFormReturn<TModel>["control"];
  name: TPath;
  quillRef: RefObject<ReactQuill>;
  readOnly?: boolean;
  errorLabel?: string;
  placeholder?: string;
  onBlur?: () => void;
  onFocus?: () => void;
};

export default function RichTextComponent<TModel extends FieldValues, TPath extends Path<TModel>>(
  props: RichTextProps<TModel, TPath>
) {
  const { field, fieldState } = useController({ name: props.name, control: props.control });
  const [focused, setFocused] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { onBlur, onFocus, quillRef } = props;

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // Emoji menu element can only be accessed after it's opened (from quillEmoji)
      const emojiMenu = document.getElementById("emoji-palette");

      if (emojiMenu && event.target instanceof Node && !emojiMenu.contains(event.target))
        emojiMenu.parentNode?.removeChild(emojiMenu);
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => document.removeEventListener("mousedown", handleOutsideClick);
  });

  const toolbar = {
    container: ["bold", "italic", "underline", "link", "emoji", "clean"],
  };

  const focusItem = () => {
    setFocused(true);
    if (onFocus) onFocus();
  };
  const blurItem = () => {
    field.onBlur();
    setFocused(false);
    if (onBlur) onBlur();
  };

  const renderError = () => {
    if (!fieldState.error) {
      return null;
    }
    const error = fieldState.error;
    const fieldName = props.errorLabel || "Field";
    let message = error.message;
    if (!message) {
      switch (error.type) {
        case "required":
          message = `${fieldName} is required`;
          break;
        default:
          message = `${fieldName} is invalid`;
          break;
      }
    }
    return <ValidationError path={props.name} message={message} />;
  };

  const className = ["rich-text", "form-input"];
  if (focused) className.push("focused");
  if (fieldState.invalid) className.push("invalid");

  const value = field.value as RichText | undefined;
  const text = typeof value?.rich === "string" ? value.rich : "";
  const wrapper = wrapperRef.current || undefined;

  return (
    <div ref={wrapperRef} className={className.join(" ")}>
      <ReactQuill
        ref={quillRef}
        bounds={wrapper}
        theme="snow"
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        value={text}
        modules={{
          toolbar: toolbar,
          "emoji-toolbar": true,
        }}
        onFocus={focusItem}
        onBlur={blurItem}
        formats={[
          "emoji",
          "blockquote",
          "header",
          "ident",
          "list",
          "align",
          "direction",
          "bold",
          "italic",
          "strike",
          "underline",
          "link",
        ]}
        onChange={(content, delta, source, editor) => {
          const plainTextDiv = document.createElement("div");
          plainTextDiv.innerHTML = editor.getHTML();
          const plain = plainTextDiv.innerText;
          plainTextDiv.remove();
          field.onChange({
            rich: content,
            plain: plain,
          });
        }}
      />
      {renderError()}
    </div>
  );
}
