import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { MapLike } from "typescript";
import { CampaignDetails, CampaignStatus } from "../../api";
import { CampaignValidator } from "../../services/validators";
import { FormDatePicker } from "../forms/date-picker/FormDatePicker";
import { FormInput } from "../forms/Input";
import { FormSelect, Option } from "../forms/Select";
import { FormModalProps, showErrors } from "./utils";

import "./EditCampaign.scss";

const ScheduledOption = {
  label: "Scheduled",
  value: CampaignStatus.Scheduled,
};
const LiveOption = { label: "Live", value: CampaignStatus.Live };
const ExpiredOption = {
  label: "Expired",
  value: CampaignStatus.Expired,
};
const PausedOption = {
  label: "Paused",
  value: CampaignStatus.Paused,
};
const DeletedOption = {
  label: "Deleted",
  value: CampaignStatus.Deleted,
};

const ValidStatuses: MapLike<Option[]> = {
  [CampaignStatus.Scheduled]: [ScheduledOption, PausedOption, DeletedOption],
  [CampaignStatus.Paused]: [PausedOption, LiveOption, DeletedOption],
  [CampaignStatus.Live]: [LiveOption, PausedOption, DeletedOption],
  [CampaignStatus.Expired]: [ExpiredOption, DeletedOption],
  [CampaignStatus.Deleted]: [DeletedOption],
};

export function EditCampaign(props: FormModalProps<CampaignDetails>) {
  const initialModel = props.initial || { id: 0, name: "", status: CampaignStatus.Scheduled };
  const headerTitle = props.initial === null ? "New Campaign" : "Edit Campaign";
  const submitTitle = props.initial === null ? "Add Campaign" : "Save Changes";
  const onChangeRef = useRef(props.onChange);
  onChangeRef.current = props.onChange;
  const { register, control, handleSubmit, formState, setError, watch } = useForm<CampaignDetails>({
    defaultValues: { ...initialModel },
    resolver: yupResolver(CampaignValidator),
  });

  const statusEnabled = props.initial !== null;
  const statusOptions = statusEnabled ? ValidStatuses[props.initial!.status] : [];

  watch(
    useCallback(() => {
      if (onChangeRef.current) {
        onChangeRef.current();
      }
    }, [onChangeRef])
  );

  const onSubmit = async (data: CampaignDetails) => {
    data.id = initialModel.id;
    if (!statusEnabled) {
      data.status = initialModel.status;
    }
    const result = await props?.onSave(data);
    if (!result.success) {
      showErrors<CampaignDetails>(result, setError);
    }
  };

  const errors = formState.errors;

  return (
    <div className="campaign-form">
      <div className="header">
        <h1 className="title">{headerTitle}</h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <FormInput name="name" type="text" placeholder="Campaign Name" errors={errors} register={register} />
        </div>
        <div className="form-row">
          <FormInput name="description" type="text" placeholder="Description" errors={errors} register={register} />
        </div>
        <div className="form-row">
          <FormDatePicker name="validFrom" placeholder="Valid From" errorLabel="Date" control={control} />
          <FormDatePicker name="validTo" placeholder="Valid To" errorLabel="Date" control={control} />
        </div>
        {statusEnabled && (
          <div className="form-row status">
            <FormSelect
              name="status"
              control={control}
              type="form"
              placeholder="Status"
              required={true}
              options={statusOptions}
            />
          </div>
        )}
        <div className="footer">
          <button type="button" className="primary cancel" onClick={props.onCancel}>
            Cancel
          </button>
          <button type="submit" disabled={formState.isSubmitting} className="primary save">
            {submitTitle}
          </button>
        </div>
      </form>
    </div>
  );
}
