import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { CustomersApi, CustomerSort, CustomerSummaryView } from "../../api";
import { isHttpOk } from "../../services/api";
import { getApiConfig } from "../../state/configuration";
import Card from "../cards/Card";
import { Avatar } from "../widgets/Avatar";
import { PaginatedTable } from "../widgets/PaginatedTable";

import "./EarlyBirdVisitorsData.scss";

const SortOptions = [
  {
    label: "Full Name",
    value: CustomerSort.Name,
  },
  {
    label: "Loyalty number",
    value: CustomerSort.LoyaltyNumber,
  },
];

export function EarlyBirdVisitorsData() {
  const [sort, setSort] = useState(CustomerSort.Name);

  const loadData = useCallback(
    async (skip: number, limit: number) => {
      const config = getApiConfig();
      const api = new CustomersApi(config);
      const result = await api.getAll({
        sort: sort,
        skip: skip,
        limit: limit,
      });
      if (isHttpOk(result)) {
        return result.data.customerSummary!;
      } else {
        return { items: [], count: 0 };
      }
    },
    [sort]
  );

  const renderRow = (item: CustomerSummaryView) => {
    return [
      <Link to={`/customer/${item.id}/details`}>
        <Avatar name={item.name || "Anonymous"} image={item.pictureUrl} />
        <span className="name">{item.name}</span>
      </Link>,
      item.loyaltyNumber,
    ];
  };

  const changeSort = (value: string) => {
    const sort = parseInt(value, 10);
    if (!isNaN(sort)) {
      setSort(sort);
    }
  };

  return (
    <Card className="early-bird-visitors-data-table">
      <PaginatedTable
        mainHeader={
          <header>
            <h1>Early Bird Visitors</h1>
            <select value={sort} onChange={(e) => changeSort(e.target.value)}>
              {SortOptions.map((option, i) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </header>
        }
        initialPageSize={5}
        minLoadingTimeMs={200}
        tableClassName="with-image"
        tableHeaders={[{ title: "FULL NAME" }, { title: "LOYALTY NM." }]}
        tableDataSource={loadData}
        tableRowFactory={renderRow}
      />
    </Card>
  );
}
