import React from "react";
import { OptionValueType, Select } from "../forms/Select";
import { range } from "lodash";
import { endOfMonth, endOfYear, format } from "date-fns";
import { useStateEx } from "../../services/hooks";

type State = {
  selectedYear: number;
  selectedMonth: number;
};

export function YearMonthPicker(props: { onChange: (from: Date | null, to: Date | null) => void }) {
  const { state, setState } = useStateEx<State>({
    selectedMonth: -1,
    selectedYear: -1,
  });

  const notifyChange = (value: State) => {
    if (value.selectedMonth === -1) {
      if (value.selectedYear === -1) {
        props.onChange(null, null);
      } else {
        const date = new Date(value.selectedYear, 0, 1);
        props.onChange(date, endOfYear(date));
      }
    } else {
      const year = value.selectedYear > -1 ? value.selectedYear : new Date().getFullYear();
      const date = new Date(year, value.selectedMonth, 1);
      props.onChange(date, endOfMonth(date));
    }
  };

  const currentYear = new Date().getFullYear();
  const yearOptions = range(currentYear, currentYear - 10).map((y) => ({
    label: `Year: ` + y,
    value: y,
  }));
  yearOptions.splice(0, 0, { label: "Year: All", value: -1 });
  const monthOptions = range(0, 12).map((m) => ({
    label: `Month: ` + format(new Date(currentYear, m, 1), "MMMM"),
    value: m,
  }));
  monthOptions.splice(0, 0, { label: "Month: All", value: -1 });

  const setYear = (year: OptionValueType | null) => {
    if (typeof year !== "number") return;
    const month = year === -1 ? -1 : state.selectedMonth;
    const next = {
      selectedYear: year,
      selectedMonth: month,
    };
    setState(next);
    notifyChange(next);
  };

  const setMonth = (month: OptionValueType | null) => {
    if (typeof month !== "number") return;
    const year = state.selectedYear > -1 ? state.selectedYear : new Date().getFullYear();
    const next = {
      selectedMonth: month,
      selectedYear: year,
    };
    setState(next);
    notifyChange(next);
  };

  return (
    <>
      <Select type="filter" required={true} value={state.selectedYear} onChange={setYear} options={yearOptions} />
      <Select type="filter" required={true} value={state.selectedMonth} onChange={setMonth} options={monthOptions} />
    </>
  );
}
