import { format, startOfYear } from "date-fns";
import { memoize } from "lodash";
import { useCallback } from "react";
import { Configuration, LocationsApi, TimeframeType } from "../api";
import { Option, OptionValueType } from "../app/forms/Select";
import { isHttpOk } from "../services/api";
import { stringFromDate } from "../services/date";
import { isProvided } from "../services/objects";
import { createStore, useMappedStore, useStore } from "../services/streams";
import { getApiConfig } from "./configuration";

interface FilterState {
  topFilterCountries: Option[];
  topFilterBeacons: Option[];
  topFilterStores: Option[];
  topFilterAreas: Option[];
  selectedTime: DateRange;
  selectedCountries?: number[];
  selectedBeacons?: string[];
  selectedStores?: number[];
  selectedAreas?: number[];
}

export interface DateRange {
  timeframeType: TimeframeType;
  startDate?: Date;
  endDate?: Date;
}

export const globalFilters = createStore<FilterState>(
  {
    topFilterAreas: [],
    topFilterStores: [],
    topFilterBeacons: [],
    topFilterCountries: [],
    selectedTime: {
      startDate: startOfYear(new Date()),
      endDate: new Date(),
      timeframeType: TimeframeType.ThisYear,
    },
  },
  "topFilters"
);

async function loadCountries(config: Configuration) {
  if (config.accessToken) {
    const api = new LocationsApi(config);
    try {
      const response = await api.getLocations({
        excludeAreas: true,
        excludeBeacons: true,
        excludeCities: true,
        excludeStores: true,
        limit: 1000,
      });
      if (isHttpOk(response)) {
        return response.data
          .filter((s) => typeof s.countryId === "number")
          .map((s) => ({ label: s.name || "", value: s.countryId! }));
      } else {
        // eslint-disable-next-line no-console
        console.error("Store lookup returned", response);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}

async function loadStores(config: Configuration, countryIds?: number[]) {
  if (config.accessToken) {
    const api = new LocationsApi(config);
    try {
      const response = await api.getLocations({
        excludeAreas: true,
        excludeBeacons: true,
        excludeCities: true,
        excludeCountries: true,
        countries: countryIds,
        limit: 1000,
      });
      if (isHttpOk(response)) {
        return response.data
          .filter((s) => typeof s.storeId === "number")
          .map((s) => ({ label: s.name || "", value: s.storeId! }));
      } else {
        // eslint-disable-next-line no-console
        console.error("Store lookup returned", response);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}

async function loadAreas(config: Configuration, storeIds?: number[]) {
  if (config.accessToken) {
    const api = new LocationsApi(config);
    try {
      const response = await api.getLocations({
        excludeCities: true,
        excludeBeacons: true,
        excludeCountries: true,
        excludeStores: true,
        stores: storeIds,
        limit: 1000,
      });
      if (isHttpOk(response)) {
        return response.data
          .filter((s) => typeof s.areaId === "number")
          .map((s) => ({ label: s.name || "", value: s.areaId! }));
      } else {
        // eslint-disable-next-line no-console
        console.error("Beacon area lookup returned", response);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}

const loadFilters = memoize(
  (config: Configuration) => {
    const filters = globalFilters();
    Promise.all([
      loadCountries(config),
      loadStores(config, filters.selectedCountries),
      loadAreas(config, filters.selectedStores),
    ]).then(([countries, stores, areas]) => {
      const state = globalFilters();
      globalFilters({
        ...state,
        topFilterCountries: countries || state.topFilterCountries,
        topFilterStores: stores || state.topFilterStores,
        topFilterAreas: areas || state.topFilterAreas,
      });
    });
  },
  (config) => config.accessToken
);

export function reloadFilters() {
  if (loadFilters.cache.clear) loadFilters.cache.clear();
  const config = getApiConfig();
  loadFilters(config);
}

export function useGlobalFilters() {
  const config = getApiConfig();
  loadFilters(config);

  const [state, , mergeState] = useStore(globalFilters);

  return {
    state,
    selectTime: useCallback(
      (time: DateRange) => {
        mergeState({
          selectedTime: time,
        });
      },
      [mergeState]
    ),
    selectAreas: useCallback(
      (areaIds: number[]) => {
        mergeState({
          selectedAreas: areaIds,
        });
      },
      [mergeState]
    ),
    selectBeacons: useCallback(
      (beaconIds: string[]) => {
        mergeState({
          selectedBeacons: beaconIds,
        });
      },
      [mergeState]
    ),
    selectStores: useCallback(
      (storeIds: number[]) => {
        mergeState({
          selectedStores: storeIds,
        });
      },
      [mergeState]
    ),
    selectCountries: useCallback(
      (countryIds: number[]) => {
        const config = getApiConfig();
        mergeState({
          selectedCountries: countryIds,
        });
        loadStores(config, countryIds).then((stores) => {
          mergeState((prev) => ({
            topFilterStores: stores || prev.topFilterStores,
          }));

          const storeIds = stores?.map((s) => s.value);
          loadAreas(config, storeIds).then((areas) => {
            mergeState((prev) => ({
              topFilterAreas: areas || prev.topFilterAreas,
            }));
          });
        });
      },
      [mergeState]
    ),
  };
}

export type TimeLocationFilters = {
  areas?: number[];
  stores?: number[];
  cities?: number[];
  beacons?: string[];
  countries?: number[];
  rangeType: TimeframeType;
  rangeStart?: IsoDateTimeString;
  rangeEnd?: IsoDateTimeString;
};

function isSingleStore(input: FilterState) {
  return input.topFilterCountries.length <= 1 && input.topFilterStores.length <= 1;
}

function createTimeLocationFilters(input: FilterState): TimeLocationFilters {
  return {
    areas: input.selectedAreas,
    stores: input.selectedStores,
    beacons: input.selectedBeacons,
    countries: input.selectedCountries,
    rangeType: input.selectedTime.timeframeType ?? TimeframeType.AllTime,
    rangeStart: stringFromDate(input.selectedTime.startDate || null, false) || undefined,
    rangeEnd: stringFromDate(input.selectedTime.endDate || null, false) || undefined,
  };
}

export function useIsSingleStore() {
  const config = getApiConfig();
  loadFilters(config);

  return useMappedStore(globalFilters, isSingleStore, true);
}

export function useTimeLocationFilters(component?: string): TimeLocationFilters {
  return useMappedStore(globalFilters, createTimeLocationFilters, true, component);
}

function formatPeriods(start: Date, end: Date) {
  const startText = format(start, "d. MMMM, yyyy");
  const endText = format(end, "d. MMMM, yyyy");
  return startText === endText ? startText : `${startText} - ${endText}`;
}

function formatOptions(options: Option[], value: OptionValueType[], delimiter: string) {
  const labels = value
    .map((v) => options.find((o) => o.value === v))
    .filter(isProvided)
    .map((o) => o.label);
  return labels.join(delimiter);
}

export function useFilterDescriptionText() {
  const { state: topFilters } = useGlobalFilters();
  const start = topFilters.selectedTime.startDate;
  const end = topFilters.selectedTime.endDate;
  const period = !!start && !!end ? formatPeriods(start, end) : "All Time";

  let locations = "All Stores";
  if (topFilters.selectedAreas?.length) {
    locations = formatOptions(topFilters.topFilterAreas, topFilters.selectedAreas, ", ");
  } else if (topFilters.selectedStores?.length) {
    locations = formatOptions(topFilters.topFilterStores, topFilters.selectedStores, ", ");
  } else if (topFilters.selectedCountries?.length) {
    locations = formatOptions(topFilters.topFilterCountries, topFilters.selectedCountries, " or ");
    if (locations.length !== 0) locations = "Stores in " + locations;
  }

  return {
    period: period,
    locations: locations,
  };
}
