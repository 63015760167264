import { useCallback, useEffect } from "react";

function getScrollParentOrBody(node: HTMLElement): HTMLElement {
  if (node.ownerDocument.body === node) {
    return node;
  } else if (node.scrollHeight > node.clientHeight || node.scrollWidth > node.clientWidth) {
    return node;
  } else {
    return getScrollParentOrBody(node.parentNode as HTMLElement);
  }
}

export default function useBorderAutoScroll(container?: HTMLElement, disabled?: boolean) {
  const scroller = container && getScrollParentOrBody(container);

  const scrollWhenNearBounds = useCallback(
    (e: globalThis.MouseEvent) => {
      if (!scroller) return;

      const scrollerRect = scroller!.getBoundingClientRect();
      const visibleX = e.pageX - scrollerRect.left;
      const visibleY = e.pageY - scrollerRect.top;

      // console.log("Mouse moving", visibleX, visibleY);
      // console.log("Element scroll", scroller!.scrollLeft, scroller!.scrollTop);
      // console.log("-----");

      const margin = 20;
      if (visibleX < margin) {
        // console.log("Scrolling left");
        scroller.scrollBy({
          behavior: "smooth",
          left: -margin,
        });
      } else if (visibleX > scrollerRect.width - margin) {
        // console.log("Scrolling right");
        scroller.scrollBy({
          behavior: "smooth",
          left: margin,
        });
      }
      if (visibleY < margin) {
        // console.log("Scrolling up");
        scroller.scrollBy({
          behavior: "smooth",
          top: -margin,
        });
      } else if (visibleY > scrollerRect.height - margin) {
        // console.log("Scrolling down");
        scroller.scrollBy({
          behavior: "smooth",
          top: margin,
        });
      }
    },
    [scroller]
  );

  useEffect(() => {
    if (disabled) return;
    if (scroller) {
      scroller.addEventListener("mousemove", scrollWhenNearBounds);
      return () => {
        scroller.removeEventListener("mousemove", scrollWhenNearBounds);
      };
    }
  }, [disabled, scrollWhenNearBounds, scroller]);
}
