import { assign, max, omit } from "lodash";
import { FieldError, FieldErrors, useController, useFieldArray, useForm, UseFormReturn } from "react-hook-form";
import { DeepLinkPayload } from "../../../api";
import { useLocalizedText } from "../../../services/localization";
import { isProvided } from "../../../services/objects";
import { useDeploymentConfig } from "../../../state/deployment";
import { AlertMissingValidationErrors } from "../../forms/AlertMissingValidationErrors";
import { Input } from "../../forms/Input";

import "./DeepLinkForm.scss";

export type DeepLinkModel = {
  keyValues: KeyValueModel[];
};

type KeyValueModel = {
  id: number;
  key: string;
  value: string;
};

type PayloadEditorProps = {
  name: string;
  control: UseFormReturn<DeepLinkModel>["control"];
  onDelete: () => void;
  defaultValue: KeyValueModel;
};

function PayloadEditor(props: PayloadEditorProps) {
  const texts = useLocalizedText();
  const { name, control, defaultValue, onDelete } = props;

  const { field: sentenceField, fieldState: sentenceState } = useController({
    control: control,
    name: `${name}` as "keyValues.0",
    defaultValue: defaultValue,
  });

  const error = sentenceState.error ? (sentenceState.error as any) : undefined;

  const editorClassName = () => {
    return "editor" + (error ? " invalid" : "");
  };

  const key = sentenceField.value.key ?? "";
  const currentValue = sentenceField.value.value ?? "";

  return (
    <>
      <div className="item-row">
        <div className={editorClassName()}>
          <h2>{texts.payloadEditor.items.keyValue.title}</h2>
        </div>
        <div className="form-row input-row">
          <Input
            type="text"
            value={key}
            placeholder={"Please enter key"}
            required={true}
            error={error?.key}
            onChange={(e) => {
              sentenceField.onChange({
                key: e.target.value,
                value: currentValue,
              });
            }}
          />
          <Input
            type="text"
            value={currentValue}
            placeholder={"Please enter value"}
            required={true}
            error={error?.value}
            onChange={(e) => {
              sentenceField.onChange({
                key: key,
                value: e.target.value,
              });
            }}
          />
          <div className="row-buttons">
            <button onClick={onDelete} type="button" className="delete">
              Delete
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function validateKeyValue(keyValue: KeyValueModel): FieldErrors<KeyValueModel> {
  const keyError = !keyValue.key ? { type: "validate", message: "Key is missing" } : undefined;

  var valueError: FieldError | undefined;
  if (!keyValue.value) valueError = { type: "validate", message: "Value is missing" };
  const jsonStartOrEnd = /^(\{|\[)|(\}|\])$/;
  // If value has opening or closing of a JSON object or array, check that it is valid
  if (keyValue.value?.match(jsonStartOrEnd)) {
    try {
      JSON.parse(keyValue.value);
      // no error means it is a valid JSON
      valueError = undefined;
    } catch {
      valueError = { type: "validate", message: "Value is not a valid JSON object." };
    }
  }

  return { key: keyError, value: valueError };
}

type Props = {
  title: string;
  identifier?: string;
  default?: DeepLinkPayload;
  onCancel?: () => void;
  onSave?: (value: DeepLinkPayload) => void;
};

export const DeepLinkFormResolver = () => {
  return (model: DeepLinkModel) => {
    var keyValues = model.keyValues;
    // trim strings
    keyValues = model.keyValues.map((kv) => {
      kv.key = kv.key?.trim();
      kv.value = kv.value?.trim();
      return kv;
    });

    var errors = keyValues.map((pair) =>
      keyValues.find((pair2) => pair.key?.toLowerCase() === pair2.key?.toLowerCase() && pair.id !== pair2.id)
        ? { key: { message: "Duplicate Key detected", type: "validate" }, value: undefined }
        : validateKeyValue(pair)
    );

    if (errors.some((e) => isProvided(e?.key?.message || e?.value?.message))) {
      const response = {
        values: {},
        errors: {
          keyValues: errors,
        },
      };
      return response;
    }
    return { values: model, errors: {} };
  };
};

export function DeepLinkForm(props: Props) {
  const deployment = useDeploymentConfig();
  const defaultPayload = deployment.config.defaultDeepLinkPayload;
  var defaultModel: DeepLinkModel = { keyValues: [] };
  var i = 0;
  for (const key in defaultPayload) {
    defaultModel.keyValues.push({
      id: i++,
      key: key,
      value: defaultPayload[key],
    });
  }
  if (defaultModel.keyValues.length === 0) defaultModel.keyValues.push({ id: 0, key: "", value: "" });

  const savedModel = props.default
    ? { keyValues: props.default.keyValues.map((kv, index) => assign(kv, { id: index })) }
    : undefined;
  const defaultValues = savedModel ?? defaultModel;
  const { formState, control, handleSubmit } = useForm<DeepLinkModel>({
    defaultValues: defaultValues,
    resolver: DeepLinkFormResolver(),
  });

  const { append, remove, fields } = useFieldArray({
    name: "keyValues",
    control: control,
  });

  const addItem = () => {
    const item: KeyValueModel = {
      id: fields.length > 0 ? max(fields.map((f) => f.id))! + 1 : 0,
      key: "",
      value: "",
    };
    append(item);
  };

  const handleSave = (model: DeepLinkModel) => {
    const payload = { keyValues: model.keyValues.map((kv) => omit(kv, "id")) };
    if (props.onSave) props.onSave(payload);
  };

  const editors = fields.map((item, index) => {
    return (
      <PayloadEditor
        key={item.id}
        defaultValue={item}
        name={`keyValues.${index}`}
        control={control}
        onDelete={() => remove(index)}
      />
    );
  });

  return (
    <>
      <div className="header">
        <h1 className="title">{props.title}</h1>
        {props.identifier && <h5>For {props.identifier}</h5>}
        <p className="description">Define data to be included in the notification's payload for use by deep links.</p>
      </div>
      <form className="deep-link-form" onSubmit={handleSubmit(handleSave)}>
        <AlertMissingValidationErrors control={control} />
        {editors}
        <button type="button" className="primary condition" onClick={addItem}>
          Add new key value pair
        </button>
        <div className="buttons">
          <button type="button" onClick={props.onCancel} className="primary cancel">
            Cancel
          </button>
          <button type="submit" disabled={formState.isSubmitting} className="primary save">
            Save
          </button>
        </div>
      </form>
    </>
  );
}
