import { useEffect } from "react";
import { AnalyticsApi, PreviousCurrentValueView, TouchpointsStatisticsView } from "../../api";
import Card from "../../app/cards/Card";
import StatsCardSimple from "../../app/cards/StatsCardSimple";
import StatsCardWithTrend from "../../app/cards/StatsCardWithTrend";
import { Conversion, NewAppCustomers, StoreVisit } from "../../app/icons/Icons";
import AppTouchpointsTable from "../../app/tables/AppTouchpointsTable";
import { isHttpOk } from "../../services/api";
import { calcTrend, formatDecimal } from "../../services/format";
import { useStateEx } from "../../services/hooks";
import { useLocalizedText } from "../../services/localization";
import { getBulletedTooltip } from "../../services/tooltip";
import { getApiConfig } from "../../state/configuration";
import { useTimeLocationFilters } from "../../state/globalFilters";
import { TouchpointGraph } from "./TouchpointGraph";

interface StateWithoutRevenue {
  isLoading: true;
  touchpointsView: TouchpointsStatisticsView;
  appOpenings: PreviousCurrentValueView;
  registrations: PreviousCurrentValueView;
  logins: PreviousCurrentValueView;
  interactionUplift: number;
  interactionRate: number;
  storeVisitUplift: number;
}

export function TouchpointsWithoutRevenue() {
  const texts = useLocalizedText();
  const filters = useTimeLocationFilters();
  const { state, mergeState } = useStateEx<StateWithoutRevenue>({
    isLoading: true,
    touchpointsView: { statistics: [], frequency: 0 },
    appOpenings: { current: 0, previous: 0 },
    registrations: { current: 0, previous: 0 },
    logins: { current: 0, previous: 0 },
    interactionUplift: 0,
    interactionRate: 0,
    storeVisitUplift: 0,
  });

  useEffect(() => {
    mergeState({ isLoading: true });

    const config = getApiConfig();
    const api = new AnalyticsApi(config);

    api.getOpenings({ includeTrend: true, ...filters }).then((result) => {
      if (isHttpOk(result)) mergeState({ appOpenings: result.data });
    });

    api.getRegistrations({ includeTrend: true, ...filters }).then((result) => {
      if (isHttpOk(result)) mergeState({ registrations: result.data });
    });

    api.getLogins({ includeTrend: true, ...filters }).then((result) => {
      if (isHttpOk(result)) mergeState({ logins: result.data });
    });

    api.getTouchpointsStatistics(filters).then((result) => {
      if (isHttpOk(result) && result.data) {
        mergeState({ touchpointsView: result.data });
      }
    });

    api.getInteractionUpliftStatistics(filters).then((result) => {
      if (isHttpOk(result)) {
        mergeState({
          interactionUplift: result.data.interactionUplift,
          interactionRate: result.data.interactionRate,
        });
      }
    });

    api.getStoreVisitsUplift(filters).then((result) => {
      if (isHttpOk(result)) {
        mergeState({
          storeVisitUplift: result.data,
        });
      }
    });
  }, [filters, mergeState]);

  return (
    <>
      <section className="graph-and-stats">
        <TouchpointGraph
          tooltip={getBulletedTooltip(texts.appTouchpointsPage.bulletedItems.touchpointsGraph)}
          touchpointsView={state.touchpointsView}
        />
        <div className="graph-stats">
          <StatsCardWithTrend
            currentValue={formatDecimal(state.registrations.current, 0)}
            previousValue={formatDecimal(state.registrations.previous, 0)}
            trendChangePercent={calcTrend(state.registrations)}
            tooltipText={texts.appTouchpointsPage.items.appRegistrations.tooltip}
            label={texts.appTouchpointsPage.items.appRegistrations.title}
            color="purple"
            hideDots
          />
          <StatsCardWithTrend
            currentValue={formatDecimal(state.logins.current, 0)}
            previousValue={formatDecimal(state.logins.previous, 0)}
            trendChangePercent={calcTrend(state.logins)}
            tooltipText={texts.appTouchpointsPage.items.appLogins.tooltip}
            label={texts.appTouchpointsPage.items.appLogins.title}
            color="green"
            hideDots
          />
          <StatsCardWithTrend
            currentValue={formatDecimal(state.appOpenings.current, 0)}
            previousValue={formatDecimal(state.appOpenings.previous, 0)}
            trendChangePercent={calcTrend(state.appOpenings)}
            tooltipText={texts.appTouchpointsPage.items.appOpenings.tooltip}
            label={texts.appTouchpointsPage.items.appOpenings.title}
            color="orange"
            hideDots
          />
        </div>
      </section>
      <section className="touchpoint-simple-cards">
        <StatsCardSimple
          asPercentage={true}
          value={state.interactionUplift}
          label={texts.appTouchpointsPage.items.interactionUplift.title}
          tooltipText={texts.appTouchpointsPage.items.interactionUplift.tooltip}
          color="red"
          figure={<Conversion />}
        />
        <StatsCardSimple
          asPercentage={true}
          value={state.interactionRate}
          label={texts.appTouchpointsPage.items.interactionRate.title}
          tooltipText={texts.appTouchpointsPage.items.interactionRate.tooltip}
          color="pink"
          figure={<NewAppCustomers />}
        />
        <StatsCardSimple
          asPercentage={true}
          value={state.storeVisitUplift}
          label={texts.appTouchpointsPage.items.storeVisitUplift.title}
          tooltipText={texts.appTouchpointsPage.items.storeVisitUplift.tooltip}
          color="blue"
          figure={<StoreVisit />}
        />
      </section>
      <section className="app-touchpoint-table">
        <Card>
          <AppTouchpointsTable touchpointsView={state.touchpointsView} />
        </Card>
      </section>
    </>
  );
}
