import { format } from "date-fns";
import { isArray } from "lodash";
import DatePicker from "react-datepicker";
import { FieldError, FieldErrors, Path, useController, UseFormReturn } from "react-hook-form";
import { ValidityModel } from "../../api";
import { stringFromDate, stringToDate } from "../../services/date";
import { isProvided } from "../../services/objects";
import { PageNext, PagePrevious } from "../icons/Icons";
import { ComplexOptions, ComplexSelector, EditorProps } from "./ComplexSelector";

import "./ValidityPicker.scss";

const DateFormat = "dd.MM.yyyy";

function AbsolutePicker(props: EditorProps<ValidityModel>) {
  const handleChange = (value: Date | [Date, Date] | null) => {
    if (!isArray(value)) {
      props.onSave({
        monthsAfterSent: undefined,
        absoluteDateTime: stringFromDate(value, false),
      });
    }
  };

  return (
    <DatePicker
      inline={true}
      selected={stringToDate(props.value?.absoluteDateTime, false)}
      selectsRange={false}
      onChange={handleChange}
      required={true}
      disabledKeyboardNavigation={true}
      nextMonthButtonLabel={<PageNext />}
      previousMonthButtonLabel={<PagePrevious />}
      onClickOutside={props.onCancel}
      onCalendarClose={props.onCancel}
    />
  );
}

function formatDate(isoDate: string | undefined | null) {
  if (isoDate) {
    const value = stringToDate(isoDate, false);
    return format(value, DateFormat);
  } else {
    return "";
  }
}

const options: ComplexOptions<ValidityModel>[] = [
  {
    getOptionLabel: () => "1 month after sent",
    isSelected: (model) => model.monthsAfterSent === 1,
    useDefault: () => ({ monthsAfterSent: 1 }),
  },
  {
    getOptionLabel: () => "3 months after sent",
    isSelected: (model) => model.monthsAfterSent === 3,
    useDefault: () => ({ monthsAfterSent: 3 }),
  },
  {
    getOptionLabel: () => "6 months after sent",
    isSelected: (model) => model.monthsAfterSent === 6,
    useDefault: () => ({ monthsAfterSent: 6 }),
  },
  {
    getOptionLabel: () => "12 months after sent",
    isSelected: (model) => model.monthsAfterSent === 12,
    useDefault: () => ({ monthsAfterSent: 12 }),
  },
  {
    getOptionLabel: (model) =>
      model?.absoluteDateTime ? `Custom (${formatDate(model.absoluteDateTime)})` : "Custom...",
    getRenderedValue: (model) => formatDate(model.absoluteDateTime),
    isSelected: (model) => !isProvided(model.monthsAfterSent) && isProvided(model.absoluteDateTime),
    useEditor: AbsolutePicker,
  },
];

type ValidityPickerProps = {
  value: ValidityModel | null;
  onChange: (value: ValidityModel | null) => void;
  readOnly?: boolean;
  required?: boolean;
  errorLabel?: string;
  error?: FieldError;
  customIcon?: string;
};

export function ValidityPicker(props: ValidityPickerProps) {
  return <ComplexSelector {...props} className="validity-picker" placeholder="Select validity..." options={options} />;
}

export function ControlledValidityPicker<Model extends FieldErrors>(props: {
  name: Path<Model>;
  control: UseFormReturn<Model>["control"];
  customIcon?: string;
}) {
  const { field, fieldState } = useController({
    name: props.name,
    control: props.control,
  });
  return (
    <ValidityPicker
      error={fieldState.error}
      value={(field.value || null) as any}
      onChange={(value) => {
        field.onChange(value);
      }}
      customIcon={props.customIcon}
    />
  );
}
