/* eslint-disable no-console */
import { confirmAlert } from "react-confirm-alert";

import "react-confirm-alert/src/react-confirm-alert.css";

export function logErrorSilently(error: any, message?: string) {
  if (!message && typeof error.message === "string") {
    message = error.message;
  }
  if (message) {
    console.error(message, error);
  } else {
    console.error(error);
  }
}

export function confirmDialog(
  message?: string,
  onConfirm?: () => void,
  onReject?: () => void,
  confirmText?: string,
  rejectText?: string
) {
  confirmAlert({
    message: message || "Are you sure?",
    buttons: [
      {
        label: confirmText || "Yes",
        onClick: () => onConfirm && onConfirm(),
      },
      {
        label: rejectText || "No",
        onClick: () => onReject && onReject(),
      },
    ],
  });
}
