import { AxiosResponse } from "axios";
import { format, parseJSON } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast as alert } from "react-toastify";
import { AreaView, BeaconModel, BeaconView, BeaconsApi, StoreLookupView, StoresApi } from "../api";
import Card from "../app/cards/Card";
import { Select } from "../app/forms/Select";
import { Download } from "../app/icons/Icons";
import EditBeacon from "../app/modals/EditBeacon";
import { Modal, useModal } from "../app/modals/Modal";
import { Result } from "../app/modals/utils";
import { AlertContent, ServerUnavailable } from "../app/widgets/Alerts";
import { highlight } from "../app/widgets/HighlightedString";
import { PageHeader } from "../app/widgets/PageHeaders";
import { PaginatedTable, PaginatedTableApi } from "../app/widgets/PaginatedTable";
import { confirmDialog } from "../services/alerts";
import { isHttpOk } from "../services/api";
import { formatPreciseDuration } from "../services/format";
import { useCachedInstance, useStateEx } from "../services/hooks";
import { getTooltip } from "../services/tooltip";
import { ServiceResult } from "../services/types";
import { useAccessControl } from "../state/authentication";
import { getApiConfig } from "../state/configuration";
import { useDeploymentConfig } from "../state/deployment";
import { useGlobalFilters, useIsSingleStore } from "../state/globalFilters";
import { useGlobalSearch } from "../state/globalSearch";

import "./Beacons.scss";

type State = {
  model: BeaconModel | null;
  isNew: boolean;
  totalCount: number;
  stores: StoreLookupView[];
  areas: AreaView[];
  storeFilter: number[];
};

function Beacons() {
  const apiRef = useRef<PaginatedTableApi>(null);
  const { config } = useDeploymentConfig();
  const { search } = useGlobalSearch();
  const { openModal, closeModal } = useModal();
  const { state, mergeState } = useStateEx<State>({
    totalCount: 0,
    stores: [],
    areas: [],
    storeFilter: [],
    model: null,
    isNew: true,
  });
  const cachedStoreFilter = useCachedInstance(state.storeFilter);

  const permissions = useAccessControl();
  const canAddBeacon = permissions.beacons?.create;
  const canUpdateBeacon = permissions.beacons?.update;

  const loadStoresAndAreas = useCallback(() => {
    const config = getApiConfig();
    const storesApi = new StoresApi(config);
    const beaconsApi = new BeaconsApi(config);
    storesApi.getForLookup().then((result) => {
      if (isHttpOk(result)) mergeState({ stores: result.data });
    });
    beaconsApi.getAllAreas().then((result) => {
      if (isHttpOk(result)) mergeState({ areas: result.data });
    });
  }, [mergeState]);

  const loadPage = useCallback(
    (offset: number, limit: number) => {
      const config = getApiConfig();
      const api = new BeaconsApi(config);
      const stores = cachedStoreFilter.length > 0 ? cachedStoreFilter : undefined;
      return api.getAll({ search: search, skip: offset, limit: limit, stores: stores });
    },
    [search, cachedStoreFilter]
  );

  useEffect(() => {
    loadStoresAndAreas();
  }, [loadStoresAndAreas]);

  const [formModified, setFormModified] = useState<boolean>(false);
  const changed = () => setFormModified(true);

  const renderCells = (model: BeaconView, index: number) => {
    const editBeacon = async () => {
      const config = getApiConfig();
      const api = new BeaconsApi(config);
      try {
        const response = await api.getOne({
          beaconId: model.id!,
        });
        mergeState({
          model: response.data,
          isNew: false,
        });
        openModal();
      } catch (error) {
        alert.error(<ServerUnavailable diagnosticError={error} />);
      }
    };

    const lastContact = model.lastContact ? (
      <div>
        <span className="last-activity top-half">{format(parseJSON(model.lastContact), "hh:mm a")}</span>
        <br />
        <span className="last-activity bottom-half">{format(parseJSON(model.lastContact), "dd MMM yyyy")}</span>
      </div>
    ) : (
      <span className="last-activity red">Never</span>
    );

    const classNames = ["beacon-name"];
    if (canUpdateBeacon) classNames.push("link");

    return [
      <span className={classNames.join(" ")} onClick={canUpdateBeacon ? editBeacon : undefined}>
        {highlight(model.name, search)}
      </span>,
      <span>
        {highlight(model.areaName, search)}
        <br />
        {highlight(model.storeName, search)}
      </span>,
      highlight(model.description, search),
      highlight(model.uuid, search),
      highlight(model.major, search),
      highlight(model.minor, search),
      formatPreciseDuration(model.maxVisitDurationSeconds),
      model.visitsLast7Days,
      lastContact,
    ];
  };

  const openAddModal = () => {
    mergeState({ isNew: true });
    openModal();
  };

  const handleCancel = () => {
    if (formModified) confirmDialog("Form has unsaved changes. Discard all?", closeModalAndReset);
    else closeModalAndReset();
  };

  const closeModalAndReset = () => {
    setFormModified(false);
    mergeState({ model: null });
    closeModal();
  };

  const deleteBeacon = async (model: BeaconModel) => {
    const config = getApiConfig();
    const api = new BeaconsApi(config);
    if (!model.id) return Result.Invalid();
    try {
      const message = `'${model.name}' marked for deletion. Beacon and contacts will be deleted soon.`;
      const result = await api._delete({
        beaconId: model.id,
      });
      if (isHttpOk(result)) {
        mergeState({ model: null });
        closeModal();
        loadStoresAndAreas();
        alert.success(<AlertContent message={message} />);
        if (apiRef.current) apiRef.current.refreshPage();
        return Result.Ok;
      } else {
        return Result.Invalid(result);
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }
    return Result.Ok;
  };

  const saveBeacon = async (model: BeaconModel) => {
    const config = getApiConfig();
    const api = new BeaconsApi(config);

    try {
      let message: string;
      let result: AxiosResponse<ServiceResult<BeaconModel>>;

      if (model.id === undefined || model.id === null) {
        message = `Beacon '${model.name}' created.`;
        result = await api.create({
          beaconModel: model,
        });
      } else {
        message = `Beacon '${model.name}' saved.`;
        result = await api.update({
          beaconId: model.id,
          beaconModel: model,
        });
      }

      if (!isHttpOk(result)) {
        return Result.Invalid(result);
      }

      closeModalAndReset();
      loadStoresAndAreas();
      if (apiRef.current) apiRef.current.refreshPage();

      const warnings = await api.getWarnings();

      alert.success(<AlertContent message={message} />);
      if (isHttpOk(warnings)) {
        if (warnings.data.showTooManyRegionsWarning) {
          alert.warn(
            <AlertContent
              message={
                "Warning! You have more than 20 beacon regions. Adding more regions might not work on iOS devices."
              }
            />
          );
        }
      }

      return Result.Ok;
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }

    return Result.Invalid();
  };

  // store options for new beacons, contains only open stores
  const storeOptions = state.stores.map((s) => ({
    label: s.name || "Unknown",
    value: s.id,
  }));
  const disableAdd = state.stores.length === 0;
  const addButtonTooltip = disableAdd ? "Add at least one store before adding beacons." : "Click to add new beacon.";

  // store options for top filter
  const { state: filters } = useGlobalFilters();
  const filterStores = filters.topFilterStores;

  const areaOptions = state.areas.map((a) => ({
    label: a.name || "Unknown",
    value: a.id,
  }));
  const isSingleStore = useIsSingleStore();

  return (
    <div className="page-content beacon-dashboard">
      <Modal onRequestClose={handleCancel}>
        <EditBeacon
          initial={state.model}
          onDelete={deleteBeacon}
          onCancel={handleCancel}
          onSave={saveBeacon}
          stores={storeOptions}
          areas={areaOptions}
          onChange={changed}
          hideDeleteButton={state.isNew}
        />
      </Modal>
      <PageHeader title="Beacons" subTitle={`Found ${state.totalCount} beacons`}>
        <div className="toolbar">
          {canAddBeacon && (
            <button
              data-tip={getTooltip(addButtonTooltip)}
              data-for="appTooltip"
              disabled={disableAdd}
              onClick={openAddModal}
              className="new"
            >
              + New Beacon
            </button>
          )}
          {!isSingleStore && (
            <Select
              type="filter"
              placeholder={"Filter: All Stores"}
              clearText="All Stores"
              isMulti={true}
              multiValueItemLabel="locations"
              options={filterStores}
              value={state.storeFilter || []}
              onChange={(values) => mergeState({ storeFilter: values as number[] })}
            />
          )}
          <button className="download">
            <Download />
          </button>
        </div>
      </PageHeader>
      <section>
        <Card>
          <PaginatedTable
            apiRef={apiRef}
            className="beacons"
            initialPageSize={10}
            tableHeaders={[
              { title: "NAME" },
              { title: "AREA, " + config.translations.store.singular.toUpperCase() },
              { title: "DESCRIPTION" },
              { title: "UUID" },
              { title: "MAJOR" },
              { title: "MINOR" },
              { title: "MAX CONTACT DURATION" },
              { title: "7 DAY CONTACTS" },
              { title: "LAST CONTACT" },
            ]}
            tableDataSource={loadPage}
            tableRowFactory={renderCells}
            minLoadingTimeMs={200}
            hidePagination={false}
            onTotalCountChange={(count) => mergeState({ totalCount: count })}
          />
        </Card>
      </section>
    </div>
  );
}

export default Beacons;
