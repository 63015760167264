import * as XLSX from "xlsx";
import { getTooltip } from "../../../services/tooltip";

export type ExcelRow = string[];
export type ExcelColumnHeader = string;

export type ExcelData = {
  rows: ExcelRow[];
  headers: ExcelColumnHeader[];
};

export interface ExcelTableProps {
  data: ExcelRow[];
  columns: ExcelColumnHeader[];
  className: string;
  headerRowClass: string;
}

export function ExcelTable(props: ExcelTableProps) {
  return (
    <table className={props.className}>
      <tbody>
        <tr className={props.headerRowClass}>
          <th></th>
          {props.columns.map((column, index) => (
            <th key={`col-${index}`} data-for="appTooltip" data-tip={getTooltip(column, "")}>
              {column}
            </th>
          ))}
        </tr>
        {props.data.map((row, index) => (
          <tr key={`row-${index}`}>
            <td className={props.headerRowClass} data-for="appTooltip" data-tip={getTooltip(`${index}. row`, "")}>
              {index}
            </td>
            {props.columns.map((_, index) => (
              <td key={`col-${index}`} data-for="appTooltip" data-tip={getTooltip(row[index] || "", "")}>
                {row[index]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function readExcel(file: File) {
  return new Promise<ExcelData>((resolve, reject) => {
    let readType: XLSX.ParsingOptions["type"] = "binary";
    let startReading = () => {};
    if (file.type === "text/csv") {
      readType = "binary";
      startReading = () => reader.readAsText(file, "utf8");
    } else {
      readType = "buffer";
      startReading = () => reader.readAsArrayBuffer(file);
    }

    const reader = new FileReader();
    reader.onload = function (event) {
      if (event.target && event.target.result) {
        const result = event.target.result;
        const workbook = XLSX.read(result, { type: readType, cellText: false, cellDates: true });
        if (workbook.SheetNames.length === 0) {
          reject("File contains 0 sheets!");
        } else {
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          var json: string[][] = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            raw: false,
            dateNF: "yyyy-mm-dd HH:mm:ss",
          });
          // Post process numeric cells to remove exponential notation for large numbers
          json = json.map((row) => {
            for (const key in row)
              if (row.hasOwnProperty(key) && typeof row[key] === "string" && !isNaN(Number(row[key])))
                row[key] = Number(row[key]) as any;
            return row;
          });
          const columnHeaders = getColumnHeaders(worksheet["!ref"]!);
          const data = { rows: json, headers: columnHeaders };
          resolve(data);
        }
      } else {
        reject("File is empty!");
      }
    };
    reader.onerror = (e) => {
      reject("Error while reading file!");
    };

    startReading();
  });
}

function getColumnHeaders(text: string) {
  var columns: string[] = [];
  var length = XLSX.utils.decode_range(text).e.c + 1;
  for (var i = 0; i < length; ++i) {
    columns[i] = XLSX.utils.encode_col(i);
  }
  return columns;
}
