import * as df from "date-fns";
import { MouseEventHandler } from "react";
import { useHistory } from "react-router";
import { CampaignStatus, CampaignView } from "../../api";
import { stringToDate } from "../../services/date";
import { HighlightColor } from "../../services/types";
import { highlight } from "../widgets/HighlightedString";
import Card from "./Card";

import "./CampaignCard.scss";

interface ComponentProps extends CampaignView {
  highlightedText?: string;
  onEditClick?: MouseEventHandler;
  onDeleteClick?: MouseEventHandler;
  onAddMessageClick?: MouseEventHandler;
}

export function CampaignCard(props: ComponentProps) {
  const history = useHistory();

  function getExpires() {
    if (props.validTo) {
      const validTo = stringToDate(props.validTo, false);
      const word = props.status === CampaignStatus.Expired ? "Expired " : "Expires ";
      return word + df.format(validTo, "MMM dd, yyyy");
    }
    return "Never expires";
  }

  function getStatusText() {
    switch (props.status) {
      case CampaignStatus.Deleted:
        return "DELETED";
      case CampaignStatus.Expired:
        return "EXPIRED";
      case CampaignStatus.Live:
        return "LIVE";
      case CampaignStatus.Paused:
        return "PAUSED";
      default:
        return "SCHEDULED";
    }
  }

  function getStatusColor(): HighlightColor {
    switch (props.status) {
      case CampaignStatus.Deleted:
        return "black";
      case CampaignStatus.Expired:
        return "red";
      case CampaignStatus.Live:
        return "green";
      case CampaignStatus.Paused:
        return "orange";
      default:
        return "blue";
    }
  }

  const getImageUrl = () => {
    if (props.status === CampaignStatus.Expired) {
      return props.grayImageUrl;
    } else {
      return props.imageUrl;
    }
  };

  const imageUrl = getImageUrl();
  const imageNode = imageUrl && <img alt="campaign" src={imageUrl}></img>;

  return (
    <Card className="campaign-card">
      <div className="figure">{imageNode}</div>
      <div className="description">
        <strong className={"status " + getStatusColor()}>{getStatusText()}</strong>
        <h1>{highlight(props.name, props.highlightedText)}</h1>
        <p>{highlight(props.description, props.highlightedText)}</p>
        <span className="validity">{getExpires()}</span>
        <div className="bottom-row">
          <div className="message-buttons">
            <button onClick={() => history.push(`/campaign/${props.id}/messages`)} className="view">
              VIEW MESSAGES
            </button>
            <button onClick={props.onAddMessageClick} className="add">
              + ADD MESSAGE
            </button>
          </div>
          <div className="edit-delete-buttons">
            <button onClick={props.onDeleteClick} className="delete">
              DELETE
            </button>
            <button onClick={props.onEditClick} className="edit">
              EDIT
            </button>
          </div>
        </div>
      </div>
    </Card>
  );
}
