import { AxiosRequestConfig } from "axios";
import decodeJwt from "jwt-decode";
import { AccessControlApi, AuthApi, Configuration, UserPermissions } from "../api";
import { isHttpOk } from "../services/api";
import { tryParseJson } from "../services/objects";
import { createStore, listen, useMappedStore, useStore } from "../services/streams";
import { getApiConfig, getApiHost } from "./configuration";

interface TokenPayload {
  nameid: string;
  aud: string;
  email: string;
  family_name: string;
  given_name: string;
  iss: string;
  exp: number;
  nbf: number;
}

interface AuthenticationState {
  remember: boolean;
  accessToken: string;
  localExpiration: Date;
  userId?: string;
  firstName?: string;
  lastName?: string;
  permissions?: UserPermissions;
}

const emptyPermissions = (): UserPermissions => ({
  beacons: null,
  users: null,
  suiteAccess: false,
  readSelfUserData: false,
  createSuperAdmin: false,
  createAdmin: false,
  readLogs: false,
  accessHangfire: false,
  pocManagementAccess: false,
  loginViaSocialProvider: false,
  deleteContactsAndVisits: false,
  deleteMessageStatistics: false,
  forceSendMessage: false,
});
const LOCAL_STORAGE_KEY = "authentication";
const LOCAL_TOKEN_REFRESH_SECONDS = 600;
const LOCAL_TOKEN_LIFETIME_SECONDS = 3600;
function empty(): AuthenticationState {
  return {
    remember: false,
    accessToken: "",
    localExpiration: new Date(),
    permissions: emptyPermissions(),
  };
}

function load(): AuthenticationState {
  const auth = tryParseJson<AuthenticationState>(localStorage.getItem(LOCAL_STORAGE_KEY));
  if (auth) {
    if (auth.remember) {
      // console.log("Found remembered token!");
      return auth;
    } else if (new Date(auth.localExpiration) > new Date()) {
      // console.log("Found unexpired token!");
      return auth;
    }
  }
  return empty();
}

let keepAliveHandle: number;
function keepAliveWhilePageOpen() {
  // console.log("Queueing token refresh!");
  window.clearTimeout(keepAliveHandle);
  keepAliveHandle = window.setTimeout(() => {
    const localExpiration = new Date();
    localExpiration.setSeconds(localExpiration.getSeconds() + LOCAL_TOKEN_LIFETIME_SECONDS);
    store({
      ...store(),
      localExpiration: localExpiration,
    });
  }, LOCAL_TOKEN_REFRESH_SECONDS * 1000);
}

function persistToLocalStorage(state?: AuthenticationState) {
  if (!state) {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  } else {
    const text = JSON.stringify(state);
    localStorage.setItem(LOCAL_STORAGE_KEY, text);
    if (state.accessToken && !state.remember) {
      keepAliveWhilePageOpen();
    }
  }
}

const store = createStore(() => {
  const auth = load();
  persistToLocalStorage(auth);
  loadPermissions(auth.accessToken).then((reloadedPermissions) => {
    // eslint-disable-next-line no-console
    console.log("Refreshed permissions!");
    store({ ...auth, permissions: reloadedPermissions });
  });
  return auth;
});

listen(store, persistToLocalStorage);

async function loadPermissions(accessToken: string | undefined) {
  const accessControlApi = new AccessControlApi(
    new Configuration({
      basePath: getApiHost(),
      accessToken: accessToken,
    })
  );
  const permissions = await accessControlApi.getPermissions();
  if (isHttpOk(permissions)) {
    return permissions.data;
  } else {
    return emptyPermissions();
  }
}

type LoginResult = { success: true } | { success: false; redirectToApp: boolean; message: string };

export async function login(username: string, password: string, remember: boolean): Promise<LoginResult> {
  const invalidCredentialsMessage = "Invalid credentials. Please try again.";
  const config = getApiConfig();
  const authenticationApi = new AuthApi(config);
  try {
    const options: AxiosRequestConfig = {
      disableUnauthorizedInterception: true,
    };
    const response = await authenticationApi.token(
      {
        grantType: "password",
        password: password,
        username: username,
      },
      options
    );
    if (isHttpOk(response) && response.data.access_token && response.data.access_token.length > 0) {
      const token = response.data.access_token;
      const payload = decodeJwt<TokenPayload>(token);

      if (payload.nameid !== response.data.user_id) {
        // eslint-disable-next-line no-console
        console.error("User id mismatch in access token!");
        return { success: false, redirectToApp: false, message: invalidCredentialsMessage };
      }

      // check permissions for suite access
      const permissions = await loadPermissions(token);
      if (permissions.suiteAccess) {
        const localExpiration = new Date();
        localExpiration.setSeconds(localExpiration.getSeconds() + LOCAL_TOKEN_LIFETIME_SECONDS);
        store({
          accessToken: token,
          remember: remember,
          localExpiration: localExpiration,
          firstName: payload.given_name,
          lastName: payload.family_name,
          userId: payload.nameid,
          permissions: permissions,
        });
        return { success: true };
      } else {
        return { success: false, redirectToApp: true, message: "" };
      }
    } else {
      const message = typeof response.data === "string" ? response.data : invalidCredentialsMessage;
      return { success: false, redirectToApp: false, message: message };
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return { success: false, redirectToApp: false, message: "Unknown error ocurred!" };
  }
}

export async function logout() {
  await loadPermissions(undefined);
  store(empty());
}

export function getAccessToken() {
  return store().accessToken;
}

function mapToPermissions(state: AuthenticationState) {
  return state.permissions || emptyPermissions();
}

export function useAuthentication() {
  const [state] = useStore(store);
  return state;
}

export function useAccessControl() {
  return useMappedStore(store, mapToPermissions);
}
