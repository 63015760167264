import React, { useState } from "react";
import { Download } from "../app/icons/Icons";
import Card from "../app/cards/Card";
import LocationOverviewStores from "../app/tables/LocationOverviewStores";
import LocationOverviewBeacons from "../app/tables/LocationOverviewBeacons";
import { useModal, Modal } from "../app/modals/Modal";
import EditStore from "../app/modals/EditStore";
import { Result } from "../app/modals/utils";
import { getApiConfig } from "../state/configuration";
import { toast as alert } from "react-toastify";
import { StoresApi, StoreModel } from "../api";
import { AlertContent, ServerUnavailable } from "../app/widgets/Alerts";
import { reloadFilters } from "../state/globalFilters";
import { StoreLocationMap } from "../app/widgets/StoreLocationMap";
import { PageHeader } from "../app/widgets/PageHeaders";
import { isHttpOk } from "../services/api";
import { useDeploymentConfig } from "../state/deployment";
import { confirmDialog } from "../services/alerts";

import "./LocationOverview.scss";

export default function LocationOverview() {
  const { config } = useDeploymentConfig();
  const { openModal, closeModal } = useModal();
  const apiConfig = getApiConfig();
  const [formModified, setFormModified] = useState<boolean>(false);
  const changed = () => setFormModified(true);
  const handleCancel = () => {
    if (formModified) confirmDialog("Form has unsaved changes. Discard all?", closeModalAndReset);
    else closeModalAndReset();
  };
  const closeModalAndReset = () => {
    setFormModified(false);
    closeModal();
  };

  const saveStore = async (model: StoreModel) => {
    const api = new StoresApi(apiConfig);

    try {
      const result = await api.create({
        storeModel: model,
      });
      if (isHttpOk(result)) {
        alert.success(<AlertContent message={`Store ${model.name} created.`} />);
        closeModalAndReset();
        reloadFilters();
        return Result.Ok;
      } else {
        return Result.Invalid(result);
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }

    return Result.Ok;
  };

  return (
    <div className="page-content location-dashboard">
      <Modal onRequestClose={handleCancel}>
        <EditStore initial={null} onCancel={handleCancel} onSave={saveStore} onChange={changed} />
      </Modal>
      <PageHeader title="Locations">
        <div className="toolbar">
          <button onClick={() => openModal()} className="new primary">
            + New {config.translations.store.singular}
          </button>
          <button className="download">
            <Download />
          </button>
        </div>
      </PageHeader>
      <section className="map">
        <Card>
          <StoreLocationMap />
        </Card>
      </section>
      <section className="stores-and-beacons">
        <Card className="stores">
          <LocationOverviewStores />
        </Card>
        <Card className="beacons">
          <LocationOverviewBeacons />
        </Card>
      </section>
    </div>
  );
}
