import { useTimeLocationFilters } from "../../state/globalFilters";
import { useCallback } from "react";
import { StoresApi } from "../../api";
import { getApiConfig } from "../../state/configuration";
import { isHttpOk } from "../../services/api";
import { logErrorSilently } from "../../services/alerts";
import { PaginatedTable } from "../widgets/PaginatedTable";
import { useDeploymentConfig } from "../../state/deployment";
import Card from "../cards/Card";

import "./TopCity.scss";

function TopCity() {
  const deployment = useDeploymentConfig();
  const filters = useTimeLocationFilters();
  const dataSource = useCallback(async () => {
    try {
      const api = new StoresApi(getApiConfig());
      const result = await api.getCitiesByVisits({ size: 5, ...filters });
      if (isHttpOk(result)) {
        return {
          count: result.data.length,
          items: result.data,
        };
      } else {
        logErrorSilently(result);
      }
    } catch (error) {
      logErrorSilently(error);
    }
    return {
      count: 0,
      items: [],
    };
  }, [filters]);

  const headers = [
    { title: "CITY" },
    { title: deployment.config.disablePurchaseHistories ? "VISITORS" : "CUSTOMERS" }
  ];

  return (
    <Card className="top-cities">
      <PaginatedTable
        mainHeader="Top City"
        minLoadingTimeMs={200}
        initialPageSize={5}
        hidePagination={true}
        tableDataSource={dataSource}
        tableHeaders={headers}
        tableRowFactory={(item, index) => {
          return [item.name, item.count];
        }}
      />
    </Card>
  );
}

export default TopCity;
