import { MouseEventHandler } from "react";
import * as api from "../../api";
import { highlight } from "../widgets/HighlightedString";
import Card from "./Card";

import { useHistory } from "react-router";
import "./ProductCategoryCard.scss";

interface ComponentProps extends api.ProductCategoryView {
  highlightedText?: string;
  onEditClick?: MouseEventHandler;
  onDeleteClick?: MouseEventHandler;
}

export function ProductCategoryCard(props: ComponentProps) {
  const name = props.name;
  const imageUrl = props.imageUrl;
  const imageNode = imageUrl && <img alt="campaign" src={imageUrl}></img>;
  const history = useHistory();

  const onAddMessageClick = () => {
    history.push(`/product-category/${props.id}/products/new`);
  };

  return (
    <Card className="category-card">
      <div className="figure">{imageNode}</div>
      <div className="description">
        <strong className="status "></strong>
        <h1>{highlight(name, props.highlightedText)}</h1>
        <div className="bottom-row">
          <div className="product-buttons">
            <button onClick={() => history.push(`/product-category/${props.id}/products`)} className="view">
              VIEW PRODUCTS
            </button>
            <button onClick={onAddMessageClick} className="add">
              + ADD PRODUCT
            </button>
          </div>
          <div className="update-buttons">
            <button onClick={props.onEditClick} className="edit">
              EDIT
            </button>
            <button onClick={props.onDeleteClick} className="delete">
              DELETE
            </button>
          </div>
        </div>
      </div>
    </Card>
  );
}
