import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast as alert } from "react-toastify";
import { ForgotPasswordRequest, PasswordApi } from "../../../api";
import { FormInput as Input } from "../../../app/forms/Input";
import { AlertContent, UnexpectedError } from "../../../app/widgets/Alerts";
import { isHttpOk } from "../../../services/api";
import { ForgotPasswordValidator } from "../../../services/validators";
import { getApiConfig } from "../../../state/configuration";

export function ForgotPasswordForm(props: { forCustomer: boolean }) {
  const [isWorking, setWorking] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, formState } = useForm<ForgotPasswordRequest>({
    resolver: yupResolver(ForgotPasswordValidator),
  });
  const errors = formState.errors;

  const onSubmit = async (data: ForgotPasswordRequest) => {
    setWorking(true);
    try {
      const config = getApiConfig();
      const api = new PasswordApi(config);
      const response = await api.forgotPassword({
        forgotPasswordRequest: {
          email: data.email,
          doNotSendEmail: false,
        },
      });
      if (isHttpOk(response)) {
        alert.info(<AlertContent message="Password reset link was successfully sent. Please check your email." />, {
          onClose: () => {
            if (props.forCustomer || response.data.isCustomer) {
              history.replace("/app");
            } else {
              history.replace("/");
            }
          },
        });
      }
    } catch (error) {
      alert.error(<UnexpectedError />);
    }
    setWorking(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <Input
          name="email"
          type="email"
          required={true}
          placeholder="&nbsp;Email"
          errors={errors}
          register={register}
        />
      </div>
      <button type="submit" className="primary" disabled={isWorking}>
        Reset Password
      </button>
    </form>
  );
}
