import L from "leaflet";
import { AzureMapsTileLayerOptions } from "./Types";
import { RequestAuthenticator } from "./RequestAuthenticator";
import { Constants } from "./Constants";

const renderV2TileUrl =
  "https://{azMapsDomain}/map/tile?api-version=2.0&tilesetId={tilesetId}&zoom={z}&x={x}&y={y}&tileSize={tileSize}&language={language}&view={view}";
const trafficFlowTileUrl =
  "https://{azMapsDomain}/traffic/flow/tile/png?api-version=1.0&style={style}&zoom={z}&x={x}&y={y}";
const trafficIncidentTileUrl =
  "https://{azMapsDomain}/traffic/incident/tile/png?api-version=1.0&style={style}&zoom={z}&x={x}&y={y}";

/**
 * A tile layer that connects to the Azure Maps Render V2 service.
 */
export class AzureMapsTiles extends L.TileLayer {
  /************************
   * Private properties
   ***********************/

  private exOptions: AzureMapsTileLayerOptions;
  private authManager: RequestAuthenticator;
  private baseUrl: string = renderV2TileUrl;

  /************************
   * Constructor
   ***********************/

  /**
   * A tile layer that connects to the Azure Maps Render V2 service.
   * @param options Azure Maps Tile layer options.
   */
  constructor(options: AzureMapsTileLayerOptions) {
    super(renderV2TileUrl, {
      tileSize: 256,
      language: "en-US",
      view: "Auto",
      tilesetId: "microsoft.base.road",
      trafficFlowThickness: 5,
      ...options,
    });

    this.exOptions = this.options as AzureMapsTileLayerOptions;
    const au = this.exOptions.authOptions || {};

    if (!au.azMapsDomain) {
      au.azMapsDomain = Constants.SHORT_DOMAIN;
    }

    const am = RequestAuthenticator.getInstance(au);
    this.authManager = am;

    this.setTilesetId(this.exOptions.tilesetId);
  }

  /**
   * Gets the attributions for the tile layer.
   */
  public getAttribution(): string {
    if (this.exOptions.hideAttribution) return "";
    const attr = this.exOptions.attribution;
    const id = this.exOptions.tilesetId;
    if (id) {
      let partner: string | undefined;
      if (id.startsWith("microsoft.base.") || id.startsWith("microsoft.traffic.")) {
        partner = "TomTom";
      } else if (id.startsWith("microsoft.weather.")) {
        partner = "AccuWeather";
      } else if (id === "microsoft.imagery") {
        partner = "DigitalGlobe";
      }
      if (partner) {
        return `© ${new Date().getFullYear()} ${partner}, Microsoft`;
      } else if (!attr) {
        return `© ${new Date().getFullYear()} Microsoft`;
      }
    }
    return attr || "";
  }

  /**
   * Gets the tile URL for the specified map tile coordinates.
   * @param coords Map tile coordinates.
   */
  public getTileUrl(coords: L.Coords): string {
    return this.getFormattedUrl()
      .replace("{x}", coords.x.toString())
      .replace("{y}", coords.y.toString())
      .replace("{z}", this._getZoomForUrl().toString());
  }

  /**
   * Creates a map tile for the layer.
   * @param coords Map tile coordinates.
   * @param done Callback function for when the map tile has finished loading.
   */
  public createTile(coords: L.Coords, done: L.DoneCallback) {
    const img = document.createElement("img");
    img.setAttribute("role", "presentation");

    if (this.exOptions.tilesetId) {
      this.authManager.getRequest(this.getTileUrl(coords)).then((result) => {
        result.blob().then((blobResponse) => {
          const reader = new FileReader();
          reader.onload = () => {
            img.src = reader.result as string;
            img.style.visibility = "visible";
            done();
          };
          reader.readAsDataURL(blobResponse);
        });
      });
    }

    return img;
  }

  /** Gets the geopolitical view setting of the layer. */
  public getView() {
    return this.exOptions.view;
  }

  /** Sets the geopolitical view setting of the layer. */
  public setView(view: string): void {
    this.exOptions.view = view;
  }

  /** Gets the language code used by the layer. */
  public getLanguage() {
    return this.exOptions.language;
  }

  /**
   * Sets the language code to append to the request.
   * @param language The language code to set.
   */
  public setLanguage(language: string): void {
    // @ts-ignore
    this.options.language = language;
    this.refresh();
  }

  /** Gets the tileset ID of the layer. */
  public getTilesetId() {
    return this.exOptions.tilesetId;
  }

  /**
   * Sets the tileset ID of the layer.
   * @param tilesetId The tileset to change to.
   */
  public setTilesetId(tilesetId?: string): void {
    this.exOptions.tilesetId = tilesetId;
    this.baseUrl = renderV2TileUrl;
    if (tilesetId) {
      if (tilesetId.startsWith("microsoft.traffic.flow")) {
        this.baseUrl = trafficFlowTileUrl;
      } else if (tilesetId.startsWith("microsoft.traffic.incident")) {
        this.baseUrl = trafficIncidentTileUrl;
      }
    }

    this.refresh();
  }

  /**
   * Gets the time stamp value setting.
   */
  public getTimeStamp() {
    return this.exOptions.timeStamp;
  }

  /**
   * Sets the time stamp option of the request.
   * @param timeStamp Time stamp value.
   */
  public setTimeStamp(timeStamp: string | Date): void {
    this.exOptions.timeStamp = timeStamp;
    this.refresh();
  }

  /**
   * Gets the traffic flow thickness setting.
   */
  public getTrafficFlowThickness() {
    return this.exOptions.trafficFlowThickness;
  }

  /**
   * sets the traffic flow thickness setting.
   */
  public setTrafficFlowThickness(thickness: number): void {
    this.exOptions.trafficFlowThickness = thickness;
    this.refresh();
  }

  private refresh(): void {
    super.setUrl(this.getFormattedUrl());
  }

  private getFormattedUrl(): string {
    const opt = this.exOptions;

    let url = this.baseUrl
      .replace("{tileSize}", this.getAzureTileSize())
      .replace("{language}", opt.language || "")
      .replace("{view}", opt.view || "")
      .replace("{tilesetId}", opt.tilesetId || "");

    if (opt.tilesetId && opt.tilesetId.startsWith("microsoft.traffic")) {
      url = url.replace("{style}", this.getTrafficStyle() || "");

      if (opt.tilesetId.indexOf("flow") > 0) {
        url += "&thickness=" + opt.trafficFlowThickness;
      }
    }

    if (opt.timeStamp) {
      let ts = opt.timeStamp as string;
      if (opt.timeStamp instanceof Date) {
        // Create an ISO 8601 timestamp string.
        // JavaScripts format for ISO string includes decimal seconds and the letter "Z" at the end that is not supported. Use slice to remove this.
        ts = opt.timeStamp.toISOString().slice(0, 19);
      }
      url = url.replace("{timeStamp}", ts);
    }

    return url;
  }

  private getAzureTileSize(): string {
    const ts = this.options.tileSize;
    return !!ts ? (typeof ts === "number" ? ts : ts.x).toString() : "";
  }

  private getTrafficStyle(): string | null {
    const ts = this.exOptions.tilesetId;
    if (ts && ts.indexOf("microsoft.traffic.") > -1) {
      return ts.replace("microsoft.traffic.incident.", "").replace("microsoft.traffic.flow.", "");
    }
    return null;
  }
}
