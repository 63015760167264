import Card from "../../app/cards/Card";
import { EarlyBirdVisitorsData } from "../../app/tables/EarlyBirdVisitorsData";
import FavoriteStores from "../../app/tables/FavoriteStores";
import { TopAreas } from "../../app/tables/TopAreas";
import TopCity from "../../app/tables/TopCity";
import TopCountry from "../../app/tables/TopCountry";
import TopStores from "../../app/tables/TopStores";
import { PageHeader } from "../../app/widgets/PageHeaders";
import { TimeLocation } from "../../app/widgets/TimeLocation";
import { useLocalizedText } from "../../services/localization";
import { useGlobalFilters, useIsSingleStore } from "../../state/globalFilters";
import LayoutEditor from "./LayoutEditor";

import "./Heatmap.scss";

export default function Heatmap() {
  const texts = useLocalizedText();
  const isSingleStore = useIsSingleStore();
  const { state: filters } = useGlobalFilters();

  const anyStores = filters.topFilterStores.length > 0;
  const selectedStore = (() => {
    if (filters.topFilterStores.length === 1) return filters.topFilterStores[0].value;
    if (filters.selectedStores && filters.selectedStores.length === 1) return filters.selectedStores[0];
    return null;
  })();

  const layoutEditor = () => {
    if (!anyStores) {
      return <div className="not-available-warning">{texts.heatmapPage.noStoreWarning}</div>;
    } else if (typeof selectedStore === "number") {
      return <LayoutEditor storeId={selectedStore} />;
    } else {
      return <div className="not-available-warning">{texts.heatmapPage.noSelectionWarning}</div>;
    }
  };

  return (
    <div className="location-heatmap page-content">
      <PageHeader title={texts.heatmapPage.title}>
        <TimeLocation disableStoreMultiSelect={true} defaultToFirstStore={true} />
      </PageHeader>
      <section className="map">
        <Card>{layoutEditor()}</Card>
      </section>
      <section>
        {!isSingleStore ? <TopStores showFooter={true} includeAllStores={true} /> : <TopAreas />}
        {!isSingleStore ? <FavoriteStores showFooter={true} /> : <EarlyBirdVisitorsData />}
      </section>
      {!isSingleStore && (
        <section>
          <TopCity />
          <TopCountry />
        </section>
      )}
    </div>
  );
}
