import { isProvided } from "../../services/objects";
import { getTooltip } from "../../services/tooltip";
import { HighlightColor } from "../../services/types";
import { GreenUpArrow, RedDownArrow, ThreeDots } from "../icons/Icons";
import Card from "./Card";

import "./StatsCardWithTrend.scss";

interface ComponentProps {
  currentValue?: string;
  previousValue?: string;
  trendChangePercent?: number;
  label: string;
  color?: HighlightColor;
  hideDots?: boolean;
  tooltipText?: string;
  onClick?: () => void;
}

function StatsCardWithTrend({
  currentValue,
  previousValue,
  trendChangePercent,
  label,
  color,
  hideDots,
  tooltipText,
  onClick,
}: ComponentProps) {
  const classNames = ["trend"];
  const showTrendChange = isProvided(trendChangePercent);
  const showPrevValue = isProvided(previousValue);
  const cardClassNames = "stats-card-with-trend " + (onClick ? "clickable" : "");

  return (
    <Card className={cardClassNames} onClick={onClick && onClick}>
      <div className="outer" data-for="appTooltip" data-tip={getTooltip(tooltipText || "", label)}>
        <span className="card-label">{label}</span>
        <strong className={color || "black"}>
          {currentValue != null && <div className="total-and-dots">{currentValue}</div>}
          {!hideDots && <ThreeDots />}
        </strong>
        {(showTrendChange || showPrevValue) && (
          <div>
            <span>
              {trendChangePercent! > 0 && <GreenUpArrow />}
              {trendChangePercent! < 0 && <RedDownArrow />}
              {trendChangePercent! === 0 && "- "}{" "}
            </span>
            {showTrendChange && <span className={classNames.join(" ")}>{trendChangePercent!.toFixed(0)}%</span>}{" "}
            {showPrevValue && <span className="avgStat">({previousValue})</span>}
          </div>
        )}
      </div>
    </Card>
  );
}

export default StatsCardWithTrend;
