import { find } from "lodash";
import { useEffect, useState } from "react";
import { SegmentGroupsApi, SegmentGroupView } from "../../../../api";
import { isHttpOk } from "../../../../services/api";
import { getApiConfig } from "../../../../state/configuration";
import { Select } from "../../../forms/Select";
import { editorClassName, EditorProps } from "./common";

export function CustomerSegmentEditor(props: EditorProps) {
  const [groups, setGroups] = useState<SegmentGroupView[]>([]);

  const model = props.value.customerSegment || {};
  const groupId = model.segmentGroupId?.id;
  const segmentId = model.segmentIndex?.id;
  const segments = find(groups, (g) => g.id === groupId)?.segments || [];

  const groupOptions = groups.map((g) => ({
    label: g.title || "",
    value: g.id,
  }));

  const segmentOptions = segments.map((s) => ({
    label: s.name || "",
    value: s.id,
  }));

  useEffect(() => {
    const config = getApiConfig();
    const api = new SegmentGroupsApi(config);
    api
      .getAll({
        limit: 1000,
        skip: 0,
      })
      .then((result) => {
        if (isHttpOk(result)) {
          setGroups(result.data.items || []);
        }
      });
  }, []);

  return (
    <div className={editorClassName(props, "customer-segment")}>
      <h2>{props.description}</h2>
      <div className="input-row single">
        <span className="prefix">Group</span>
        <Select
          type="form"
          placeholder={groups.length === 0 ? "Loading..." : "Select group"}
          errorLabel="Group"
          isMulti={false}
          required={true}
          value={groupId || null}
          options={groupOptions}
          error={props.error}
          onChange={(value) => {
            const option = find(groupOptions, (o) => o.value === value);
            if (option) {
              props.onChange({
                customerSegment: {
                  segmentGroupId: {
                    id: option.value,
                    name: option.label,
                  },
                  segmentIndex: model.segmentIndex,
                },
              });
            }
          }}
        />
      </div>
      <div className="input-row single">
        <span className="prefix">Segment</span>
        <Select
          type="form"
          placeholder={groups.length === 0 ? "Loading..." : "Select segment"}
          errorLabel="Segment"
          isMulti={false}
          required={true}
          value={segmentId || null}
          options={segmentOptions}
          error={props.error}
          onChange={(value) => {
            const option = find(segmentOptions, (o) => o.value === value);
            if (option) {
              props.onChange({
                customerSegment: {
                  segmentGroupId: model.segmentGroupId,
                  segmentIndex: {
                    id: option.value,
                    name: option.label,
                  },
                },
              });
            }
          }}
        />
      </div>
    </div>
  );
}
