import { AxiosResponse } from "axios";
import { orderBy } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast as alert } from "react-toastify";
import { LocationView, LocationsApi, StoreModel, StoreView, StoresApi } from "../api";
import Card from "../app/cards/Card";
import { Option, Select } from "../app/forms/Select";
import { Download } from "../app/icons/Icons";
import EditStore from "../app/modals/EditStore";
import { Modal, useModal } from "../app/modals/Modal";
import { Result } from "../app/modals/utils";
import { AlertContent, ServerUnavailable } from "../app/widgets/Alerts";
import { highlight } from "../app/widgets/HighlightedString";
import { PageHeader } from "../app/widgets/PageHeaders";
import { PaginatedTable, PaginatedTableApi } from "../app/widgets/PaginatedTable";
import { confirmDialog } from "../services/alerts";
import { isHttpOk } from "../services/api";
import { useCachedInstance, useStateEx } from "../services/hooks";
import { isProvided } from "../services/objects";
import { renderAmount } from "../services/string";
import { ServiceResult } from "../services/types";
import { getApiConfig } from "../state/configuration";
import { useDeploymentConfig } from "../state/deployment";
import { reloadFilters, useIsSingleStore } from "../state/globalFilters";
import { useGlobalSearch } from "../state/globalSearch";

import "./Stores.scss";

type State = {
  model: StoreModel | null;
  isNew: boolean;
  totalCount: number;
  selectedLocations: string[];
  locationOptions: Option[];
};

async function loadCountriesAndCities() {
  const config = getApiConfig();
  const api = new LocationsApi(config);
  const response = await api.getLocations({
    excludeAreas: true,
    excludeStores: true,
    limit: 1000,
  });
  if (isHttpOk(response)) {
    const countries = orderBy(
      response.data.filter((s) => isProvided(s.countryId)),
      ["name"]
    ).map((o) => ({ label: "Country: " + o.name, value: JSON.stringify(o) }));
    const cities = orderBy(
      response.data.filter((s) => isProvided(s.cityId)),
      ["name"]
    ).map((o) => ({ label: "City: " + o.name, value: JSON.stringify(o) }));

    return [...countries, ...cities];
  } else {
    return [];
  }
}

function Stores() {
  const { config } = useDeploymentConfig();
  const { search } = useGlobalSearch();
  const isSingleStore = useIsSingleStore();
  const { openModal, closeModal } = useModal();
  const { state, mergeState } = useStateEx<State>({
    locationOptions: [],
    selectedLocations: [],
    totalCount: 0,
    model: null,
    isNew: true,
  });
  const apiRef = useRef<PaginatedTableApi>(null);
  const cachedSelectedLocations = useCachedInstance(state.selectedLocations);

  useEffect(() => {
    loadCountriesAndCities().then((v) =>
      mergeState({
        locationOptions: v,
      })
    );
  }, [mergeState]);

  const [formModified, setFormModified] = useState<boolean>(false);
  const changed = () => setFormModified(true);

  const openAddModal = () => {
    mergeState({ isNew: true });
    openModal();
  };

  const handleCancel = () => {
    if (formModified) confirmDialog("Form has unsaved changes. Discard all?", closeModalAndReset);
    else closeModalAndReset();
  };

  const closeModalAndReset = () => {
    mergeState({ model: null });
    closeModal();
    setFormModified(false);
  };

  const loadPage = useCallback(
    (skip: number, limit: number) => {
      const values = cachedSelectedLocations.map((v) => JSON.parse(v)) as LocationView[];
      const cities = values.map((v) => v.cityId).filter(isProvided);
      const countries = values.map((v) => v.countryId).filter(isProvided);
      const config = getApiConfig();
      const api = new StoresApi(config);
      return api.get({
        skip: skip,
        limit: limit,
        search: search.length === 0 ? undefined : search,
        countries: countries.length > 0 ? countries : undefined,
        cities: cities.length > 0 ? cities : undefined,
      });
    },
    [cachedSelectedLocations, search]
  );

  const saveStore = async (model: StoreModel) => {
    const config = getApiConfig();
    const api = new StoresApi(config);

    try {
      let message: string;
      let result: AxiosResponse<ServiceResult<StoreModel>>;

      if (model.id === undefined || model.id === null) {
        message = `Store '${model.name}' created.`;
        result = await api.create({
          storeModel: model,
        });
      } else {
        message = `Store '${model.name}' saved.`;
        result = await api.update({
          id: model.id,
          storeModel: model,
        });
      }

      if (isHttpOk(result)) {
        reloadFilters();
        closeModalAndReset();
        alert.success(<AlertContent message={message} />);
        if (apiRef.current) apiRef.current.refreshPage();
        return Result.Ok;
      } else {
        return Result.Invalid(result);
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }

    return Result.Invalid();
  };

  const deleteStore = async (model: StoreModel) => {
    const config = getApiConfig();
    const api = new StoresApi(config);
    if (!model.id) return Result.Invalid();
    try {
      const processDeletion = async () => {
        const message = `'${model.name}' marked for deletion. Store and its Beacons will be deleted soon.`;
        const result = await api._delete({
          storeId: model.id!,
        });
        if (isHttpOk(result)) {
          mergeState({ model: null });
          reloadFilters();
          closeModal();
          alert.success(<AlertContent message={message} />);
          if (apiRef.current) apiRef.current.refreshPage();
          return Result.Ok;
        } else {
          return Result.Invalid(result);
        }
      };

      // If the store has visitor data, show confirm dialog
      const result = await api.visitorData({ storeId: model.id });
      if (!isHttpOk(result)) return Result.Invalid();
      const showConfirmDialog = result.data.exists;

      if (showConfirmDialog) {
        confirmDialog("There is visitor data that belongs to beacons of this store. Are you sure?", async () => {
          await processDeletion();
        });
      } else {
        return await processDeletion();
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }
    return Result.Ok;
  };

  const renderCells = (store: StoreView, index: number): React.ReactNode[] => {
    const editStore = async () => {
      const config = getApiConfig();
      const api = new StoresApi(config);
      try {
        const response = await api.getOne({
          id: store.id,
        });
        mergeState({
          model: response.data,
          isNew: false,
        });
        openModal();
      } catch {
        alert.error(<ServerUnavailable />);
      }
    };

    const status = store.status || "";
    const classNames = ["store-status", status.toLowerCase()];

    return [
      <span title={store.name || ""} className="link" onClick={editStore}>
        {highlight(store.name, search)}
      </span>,
      <span title={store.country || ""}>{highlight(store.country, search)}</span>,
      <span title={store.city || ""}>{highlight(store.city, search)}</span>,
      <span title={store.street || ""}>{highlight(store.street, search)}</span>,
      <span title={store.zipCode || ""}>{highlight(store.zipCode, search)}</span>,
      <span className={classNames.join(" ")}>{highlight(status, search)}</span>,
    ];
  };

  const tableHeaders = [
    { title: "NAME" },
    { title: "COUNTRY" },
    { title: "CITY" },
    { title: "STREET" },
    { title: "ZIP CODE" },
    { title: "STATUS" },
  ];

  return (
    <div className="page-content location-dashboard">
      <Modal onRequestClose={handleCancel}>
        <EditStore
          initial={state.model}
          onDelete={deleteStore}
          onSave={saveStore}
          onCancel={handleCancel}
          onChange={changed}
        />
      </Modal>
      <PageHeader
        title={config.translations.store.plural}
        subTitle={`Found ${renderAmount(state.totalCount, config.translations.store)}`}
      >
        <div className="toolbar">
          <button onClick={openAddModal} className="new">
            + New {config.translations.store.singular}
          </button>
          {!isSingleStore && (
            <Select
              type="filter"
              placeholder={"Filter: All Location"}
              clearText="All Locations"
              isMulti={true}
              multiValueItemLabel="locations"
              options={state.locationOptions}
              value={state.selectedLocations || []}
              onChange={(values) => mergeState({ selectedLocations: values as string[] })}
            />
          )}
          <button className="download">
            <Download />
          </button>
        </div>
      </PageHeader>
      <section>
        <Card>
          <PaginatedTable
            apiRef={apiRef}
            className="stores"
            initialPageSize={10}
            tableHeaders={tableHeaders}
            tableDataSource={loadPage}
            tableRowFactory={renderCells}
            minLoadingTimeMs={200}
            hidePagination={false}
            onTotalCountChange={(count) => mergeState({ totalCount: count })}
          />
        </Card>
      </section>
    </div>
  );
}

export default Stores;
