import { AxiosResponse } from "axios";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { toast as alert } from "react-toastify";
import { ProductCategoriesApi, ProductModel, ProductView, ProductsApi } from "../api";
import Card from "../app/cards/Card";
import { ProductCard } from "../app/cards/ProductCard";
import { Option, OptionValueType, Select } from "../app/forms/Select";
import EditProduct from "../app/modals/EditProduct";
import { Modal, useModal } from "../app/modals/Modal";
import { Result } from "../app/modals/utils";
import { AlertContent, ServerUnavailable } from "../app/widgets/Alerts";
import Loading from "../app/widgets/Loading";
import { PageHeader } from "../app/widgets/PageHeaders";
import { PaginatedCards, PaginatedCardsApi } from "../app/widgets/PaginatedCards";
import { confirmDialog } from "../services/alerts";
import { isHttpOk } from "../services/api";
import { useStateEx } from "../services/hooks";
import { isProvided } from "../services/objects";
import { ServiceResult } from "../services/types";
import useBreakpoint from "../services/useBreakpoint";
import { getApiConfig } from "../state/configuration";
import { useGlobalSearch } from "../state/globalSearch";

import "./Products.scss";

type State = {
  model: ProductModel | null;
  isNew: boolean;
  totalCount: number;
  formModified: boolean;
  categoryId: number;
  categoryOptions: Option[];
};

const breakpoints = { "columns-2": 0, "columns-3": 1200, "columns-4": 2000 };

function Products(props: { openNew: boolean }) {
  const route = useRouteMatch<{ productCategoryId: string }>();

  const categoryIdParam = useMemo(() => {
    if (route.params.productCategoryId === undefined) {
      return undefined;
    } else {
      const categoryId = parseInt(route.params.productCategoryId, 10);
      if (isNaN(categoryId)) {
        alert.error(<AlertContent message="Selected product category could not be found" />);
        return undefined;
      }
      return categoryId;
    }
  }, [route.params.productCategoryId]);

  const pageApi = useRef<PaginatedCardsApi>(null);
  const history = useHistory();
  const { search } = useGlobalSearch();
  const { openModal, closeModal } = useModal();
  const { state, mergeState } = useStateEx<State>({
    totalCount: 0,
    model: null,
    isNew: true,
    formModified: false,
    categoryId: categoryIdParam ?? 0,
    categoryOptions: [],
  });
  const breakpoint = useBreakpoint(breakpoints).breakpoint;
  const pageSize = breakpoint === "columns-4" ? 12 : breakpoint === "columns-3" ? 9 : 6;

  const changed = () => mergeState({ formModified: true });

  useEffect(() => {
    (async () => {
      const config = getApiConfig();
      try {
        const categories = await new ProductCategoriesApi(config).getForLookup();
        const options =
          categories.data?.map((c) => ({
            label: c.name!,
            value: c.id,
          })) || [];
        options.splice(0, 0, { label: "All Categories", value: 0 });
        mergeState({
          categoryOptions: options,
        });
      } catch (error) {
        alert.error(<ServerUnavailable message="Unable to fetch Category list! Please check your connection." />);
      }
    })();
  }, [mergeState]);

  const openAddModal = useCallback(() => {
    mergeState({ isNew: true });
    openModal();
  }, [openModal, mergeState]);

  const handleCancel = () => {
    if (state.formModified) confirmDialog("Form has unsaved changes. Discard all?", closeModalAndReset);
    else closeModalAndReset();
  };

  const closeModalAndReset = () => {
    mergeState({ model: null, formModified: false });
    closeModal();
  };

  const load = useCallback(
    (skip: number, limit: number) => {
      const config = getApiConfig();
      const api = new ProductsApi(config);
      return api.get({
        search: search,
        skip: skip,
        limit: limit,
        categoryIds: state.categoryId ? [state.categoryId] : undefined,
      });
    },
    [search, state.categoryId]
  );

  useEffect(() => {
    if (props.openNew) {
      const handle = window.setTimeout(() => {
        openAddModal();
      }, 100);
      return () => {
        window.clearTimeout(handle);
      };
    }
  }, [props.openNew, openAddModal]);
  const loadingCardFactory = (key: number) => {
    return (
      <Card className="product-card loading" key={key}>
        <Loading />
      </Card>
    );
  };

  const setCategoryFilter = (value: OptionValueType | null) => {
    if (typeof value === "number") {
      mergeState({ categoryId: value });
      if (pageApi.current) pageApi.current.reset();

      const path = value === 0 ? "/products" : `/product-category/${value}/products`;
      history.replace(path);
    }
  };

  const loadedCardFactory = (model: ProductView, key: number) => {
    const id = model.id;

    const editProduct = async () => {
      const config = getApiConfig();
      const api = new ProductsApi(config);

      try {
        const response = await api.getOne({ id: id });

        if (isHttpOk(response) && response.data) {
          mergeState({
            model: response.data,
            isNew: false,
          });
          openModal();
        }
      } catch (error) {
        alert.error(<ServerUnavailable diagnosticError={error} />);
      }
    };

    const deleteProduct = async () => {
      const onConfirm = async () => {
        const config = getApiConfig();
        const api = new ProductsApi(config);

        try {
          var result = await api._delete({ id: id });

          if (isHttpOk(result)) {
            alert.success(<AlertContent message="Product deleted." />);
            if (pageApi.current) pageApi.current.refreshPage();

            return Result.Ok;
          }
        } catch (error) {
          alert.error(<ServerUnavailable diagnosticError={error} />);
        }
      };

      confirmDialog("Delete Product?", onConfirm);
    };

    return (
      <ProductCard
        key={key}
        highlightedText={search}
        onDeleteClick={deleteProduct}
        onEditClick={editProduct}
        {...model}
      />
    );
  };

  const saveProduct = async (model: ProductModel) => {
    const config = getApiConfig();
    const api = new ProductsApi(config);

    try {
      let message: string;
      let result: AxiosResponse<ServiceResult<ProductModel>>;

      if (!model.id) {
        message = `New Product created.`;

        result = await api.create({
          productModel: model,
        });
      } else {
        message = `Product updated.`;

        result = await api.update({
          id: model.id,
          productModel: model,
        });
      }

      if (isHttpOk(result)) {
        closeModalAndReset();
        alert.success(<AlertContent message={message} />);
        if (pageApi.current) pageApi.current.refreshPage();
        return Result.Ok;
      } else {
        return Result.Invalid(result);
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }

    return Result.Invalid();
  };

  return (
    <div className={clsx("page-content", "products", breakpoint)}>
      <Modal onRequestClose={handleCancel}>
        <EditProduct
          initial={state.model}
          onSave={saveProduct}
          onCancel={handleCancel}
          onChange={changed}
          existingProduct={isProvided(state.model)}
          categoryId={categoryIdParam}
        />
      </Modal>
      <PageHeader title="Products" subTitle={`Found ${state.totalCount} products`}>
        <div className="toolbar">
          <button className="new" onClick={openAddModal}>
            + New Product
          </button>
          <Select
            type="filter"
            required={true}
            className="category-filter"
            value={state.categoryId}
            onChange={setCategoryFilter}
            options={state.categoryOptions}
          />
        </div>
      </PageHeader>
      <PaginatedCards
        apiRef={pageApi}
        dataSource={load}
        pageSize={pageSize}
        minLoadingTimeMs={200}
        onAddNew={openAddModal}
        hideAddNew={true}
        hidePagination={false}
        loadingCardFactory={loadingCardFactory}
        loadedCardFactory={loadedCardFactory}
        onTotalCountChange={(count) => mergeState({ totalCount: count })}
      />
    </div>
  );
}

export default Products;
