import { useCallback } from "react";
import { createStore, useStore } from "../services/streams";

const globalSearch = createStore(
  {
    search: "",
    isVisible: false,
  },
  "globalSearch"
);

export function useGlobalSearch() {
  const [state, setState, mergeState] = useStore(globalSearch);
  return {
    ...state,
    setText: useCallback(
      (value: string) => {
        mergeState({ search: value });
      },
      [mergeState]
    ),
    enableAndClear: useCallback(
      (enabled: boolean) => {
        setState((prev) => ({
          search: enabled ? "" : prev.search,
          isVisible: enabled,
        }));
      },
      [setState]
    ),
  };
}
