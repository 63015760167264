import clsx from "clsx";
import { isEmpty, isFunction, range } from "lodash";
import pDebounce from "p-debounce";
import { MutableRefObject, Ref, useCallback, useEffect, useRef } from "react";
import { FieldError } from "react-hook-form";
import { toast as alert } from "react-toastify";
import { Document, Page } from "../../pdf";
import { PDFDocumentProxy, PDFPageProxy, renderToImage } from "../../pdf/pdfjs";
import { useStateEx, useWasUnmountedRef } from "../../services/hooks";
import useRectangleSelector, { Rectangle } from "../../services/useRectangleSelector";
import { Input } from "../forms/Input";
import { AlertContent } from "../widgets/Alerts";
import Loading from "../widgets/Loading";

import "./EditFloorplanPdf.scss";

function toClipPath(numbers: [number, number][]) {
  return `polygon(${numbers.map((n) => `${n[0]}% ${n[1]}%`).join(", ")})`;
}

function rectMaskPoly(rect: Rectangle) {
  if (rect.top === rect.bottom && rect.left === rect.right) {
    return [];
  } else {
    if (rect.bottom < rect.top) {
      rect = { ...rect, top: rect.bottom, bottom: rect.top };
    }
    if (rect.right < rect.left) {
      rect = { ...rect, left: rect.right, right: rect.left };
    }
    const end: [number, number] = [-1, -1];
    const show = rectToPoly({ top: -1, left: -1, right: 101, bottom: 101 });
    const hide = rectToPoly(rect).reverse();
    return [...show, ...hide, end];
  }
}

function rectToPoly(rect: Rectangle): [number, number][] {
  return [
    [rect.left, rect.top],
    [rect.right, rect.top],
    [rect.right, rect.bottom],
    [rect.left, rect.bottom],
    [rect.left, rect.top],
  ];
}

interface FloorPreviewProps {
  pageNm: number;
  error?: FieldError;
  pageRef?: Ref<PDFPageProxy>;
  onChange: (plan: { title: string; selection: Rectangle } | null) => void;
}

interface FloorPreviewState {
  loaded: boolean;
  rendered: boolean;
  width?: number;
  title: string;
}

function FloorPreview({ pageNm, error, onChange, pageRef }: FloorPreviewProps) {
  const mouseDetectorRef = useRef<HTMLDivElement>(null);
  const { state, mergeState } = useStateEx<FloorPreviewState>({
    loaded: false,
    rendered: false,
    title: "",
  });
  const { selection, clearSelection } = useRectangleSelector({
    disabled: !state.loaded || !state.rendered,
    container: mouseDetectorRef.current || undefined,
    onFinish: (selection) => {
      if (selection === null) {
        onChange(null);
      } else {
        onChange({
          title: state.title,
          selection: selection,
        });
      }
    },
  });
  const hasSelection = selection !== null;

  const changeName = (value: string) => {
    mergeState({ title: value });
    onChange(
      hasSelection
        ? {
            title: value,
            selection: selection!,
          }
        : null
    );
  };

  const editorRef = useRef<HTMLDivElement>();
  const setEditorAndWidth = useCallback(
    (editor: HTMLDivElement | null | undefined) => {
      if (!editor) return;
      editorRef.current = editor;
      const clientRect = editor.getBoundingClientRect();
      const width = clientRect.width;
      mergeState({
        width: Math.round(width <= 0 ? 400 : width),
      });
    },
    [mergeState]
  );

  useEffect(() => {
    if (!editorRef.current) return;

    const handleResize = pDebounce(() => {
      const clientRect = editorRef.current!.getBoundingClientRect();
      const width = clientRect.width;
      mergeState({
        width: Math.round(width <= 0 ? 400 : width),
      });
    }, 100);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mergeState]);

  const renderToolbar = () => {
    if (selection !== null) {
      return (
        <>
          <Input
            required
            error={error}
            name={`name${pageNm}`}
            value={state.title}
            onChange={(e) => changeName(e.target.value)}
            placeholder="Floorplan name"
          />
          <button onClick={clearSelection}>Remove</button>
        </>
      );
    } else {
      return <span>Please select region to use as floor plan (optional).</span>;
    }
  };

  const setPageRef = (page: PDFPageProxy) => {
    if (isFunction(pageRef)) {
      pageRef(page);
    } else if (pageRef) {
      (pageRef as MutableRefObject<PDFPageProxy>).current = page;
    }
  };

  const renderPage = () => {
    if (state.width === undefined) return null;
    const clipPath = selection !== null ? toClipPath(rectMaskPoly(selection)) : undefined;
    return (
      <>
        <div ref={mouseDetectorRef} style={{ width: `${state.width}px`, zIndex: 2, position: "absolute", inset: 0 }} />
        {clipPath && (
          <div
            className="overlay"
            style={{
              width: `${state.width}px`,
              position: "absolute",
              zIndex: 1,
              inset: 0,
              clipPath: clipPath,
              opacity: hasSelection ? 1 : 0,
            }}
          />
        )}
        <Page
          loading={<></>}
          className={clsx({
            loading: !state.loaded,
          })}
          scale={1}
          width={state.width}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          renderInteractiveForms={false}
          pageNumber={pageNm}
          renderMode="canvas"
          onLoadSuccess={(page) => {
            mergeState({ loaded: true });
            setPageRef(page);
          }}
          onRenderStart={(page) => {
            mergeState({ rendered: false });
            setPageRef(page);
          }}
          onRenderSuccess={(page) => {
            mergeState({ rendered: true });
            setPageRef(page);
          }}
        />
      </>
    );
  };

  return (
    <div
      className={clsx({
        "floor-preview": true,
        loading: !state.loaded || !state.rendered,
        selected: hasSelection,
        invalid: error !== undefined,
      })}
    >
      <div className="toolbar">{renderToolbar()}</div>
      <div className="editor" ref={setEditorAndWidth}>
        {!state.rendered && <Loading />}
        {renderPage()}
      </div>
    </div>
  );
}

interface Floor {
  title: string;
  blob: Blob;
}

interface Props {
  file: any;
  onError?: (error: Error) => void;
  onSave?: (floors: Floor[]) => Promise<void>;
  onCancel?: () => void;
}

type State = {
  numPages: number;
  saving: boolean;
  saveAttempted: boolean;
  floors: ({ title: string; selection: Rectangle } | null)[];
};

export function EditFloorplanPdf(props: Props) {
  const { state, mergeState } = useStateEx<State>(
    {
      numPages: 0,
      saving: false,
      saveAttempted: false,
      floors: [],
    },
    EditFloorplanPdf.name
  );
  const wasCancelled = useRef(false);
  const documentProxy = useRef<PDFDocumentProxy>();
  const wasUnmounted = useWasUnmountedRef();

  const onDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
    documentProxy.current = pdf;
    mergeState({
      numPages: pdf.numPages,
      floors: range(0, pdf.numPages).map(() => null),
    });
  };

  const getError = (index: number) => {
    const floor = state.floors[index];
    if (floor == null) return;
    if (isEmpty(floor.title)) {
      return {
        type: "",
        message: "Floor name is required",
      };
    }
  };

  const saveFloorplans = async () => {
    if (documentProxy.current === undefined) return;

    if (state.floors.some((_, i) => getError(i) !== undefined)) {
      mergeState({ saveAttempted: true });
      return;
    }

    if (props.onSave) {
      mergeState({ saving: true, saveAttempted: true });
      try {
        const floorPromises = state.floors
          .map((floor, index) => ({ floor, page: index + 1 }))
          .filter((req) => req.floor !== null)
          .map(async (req) => {
            if (wasCancelled.current) {
              return {
                title: req.floor!.title,
                blob: new Blob(),
              };
            }

            const blob = await renderToImage(documentProxy.current!, {
              selection: req.floor!.selection,
              pageNm: req.page,
              maxWidth: 2400,
              maxHeight: 1200,
              asDataString: false,
            });

            return {
              title: req.floor!.title,
              blob: blob as Blob,
            };
          });

        if (wasCancelled.current) return;

        const results = await Promise.all(floorPromises);
        await props.onSave(results);
      } catch (err) {
        alert.error(<AlertContent diagnosticError={err} message="Unable to convert PDF to floorplan." />);
      } finally {
        wasCancelled.current = false;
        if (!wasUnmounted.current) {
          mergeState({ saving: false });
        }
      }
    }
  };

  const renderPages = () => {
    const pages = [];
    for (let pageNm = 1; pageNm <= state.numPages; pageNm++) {
      pages.push(
        <FloorPreview
          key={pageNm}
          error={state.saveAttempted ? getError(pageNm - 1) : undefined}
          pageNm={pageNm}
          onChange={(plan) => {
            mergeState((prev) => {
              const floors = [...prev.floors];
              floors[pageNm - 1] = plan;
              return {
                floors: floors,
              };
            });
          }}
        />
      );
    }
    return pages;
  };

  const handleError = (error: Error) => {
    if (props.onError) props.onError(error);
  };

  const handleCancel = () => {
    wasCancelled.current = true;
    if (props.onCancel) props.onCancel();
  };

  const loadingContent = () => {
    return (
      <>
        <div className="header">
          <h1 className="title">Loading PDF</h1>
        </div>
        <div className="footer">
          <button type="button" className="primary cancel" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </>
    );
  };

  const errorContent = (message: string) => {
    return () => (
      <>
        <div className="header">
          <h1 className="title">{message}</h1>
        </div>
        <div className="footer">
          <button type="button" className="primary cancel" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </>
    );
  };

  return (
    <Document
      file={props.file}
      className="edit-floorplan-pdf"
      onLoadError={handleError}
      onSourceError={handleError}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={loadingContent}
      error={errorContent("Unknown error occurred.")}
      noData={errorContent("Error loading PDF document!")}
    >
      {state.saving ? <Loading text="Saving" className="full-progress" /> : null}
      <div className="header">
        <h1 className="title">Select from PDF</h1>
      </div>
      {renderPages()}
      <div className="footer">
        <button type="button" className="primary cancel" onClick={handleCancel}>
          Cancel
        </button>
        <button type="button" className="primary save" onClick={saveFloorplans}>
          Save
        </button>
      </div>
    </Document>
  );
}
