import { PropsWithChildren } from "react";
import { Droppable } from "react-beautiful-dnd";

import "./DroppableList.scss";

interface DroppableListProps {
  id: string;
  title: string;
  disableDrop?: boolean;
  isFixedWidth?: boolean;
}

export function DroppableList(props: PropsWithChildren<DroppableListProps>) {
  const dropListClasses = "drop-list" + (props.isFixedWidth ? " fixed-width" : " flex-row");
  return (
    <Droppable droppableId={props.id} direction="horizontal" isDropDisabled={props.disableDrop}>
      {(provided) => (
        <div className="drop-box">
          {props.title.length > 0 && <p className="list-header">{props.title}</p>}
          <div className={dropListClasses} {...provided.droppableProps} ref={provided.innerRef}>
            {props.children}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
}
