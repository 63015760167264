import { FieldErrors } from "react-hook-form";
import { RuleSentence } from "../../../../api";
import { Option } from "../../../forms/Select";

export type EditorProps = {
  description: string;
  currency: string;
  brands: Option[];
  beacons: Option[];
  error?: FieldErrors<any>;
  value: RuleSentence;
  onChange: (value: RuleSentence) => void;
};

export function editorClassName(props: EditorProps, additional: string) {
  if (props.error) return "editor invalid " + additional;
  return "editor " + additional;
}

export function closestValue(value: number, predefined: Option[]) {
  let minDistance = Infinity;
  let selected = value;
  for (const option of predefined) {
    if (typeof option.value !== "number") continue;
    const currentDistance = Math.abs(option.value - value);
    if (currentDistance < minDistance) {
      minDistance = currentDistance;
      selected = option.value;
    }
  }
  return selected;
}
