import { cloneDeep, range } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import Checkbox from "../../app/forms/Checkbox";
import { ShowFormInDiv, ShowStateInDiv } from "../../app/forms/Debug";
import {
  FormSelect,
  FormSelectAsync,
  Option,
  OptionValueType,
  ReadonlyOptions,
  Select,
  SelectAsync,
} from "../../app/forms/Select";
import { AllStores } from "../../app/icons/Icons";
import { useStateEx } from "../../services/hooks";

import "./DebugSelect.scss";

/* eslint-disable no-console */

const initialSelection = { label: "Normal Item 1", value: "1" };

type State = {
  singleOption: Option | null;
  multiOption: Option[];
  singleValue: OptionValueType | null;
  multiValue: OptionValueType[];
};

const defaultFormValues = {
  filter: false,
  required: false,
  readonly: false,
  grouped: false,
  withDescription: false,
  icon: false,
  singleOption: initialSelection,
  multiOption: [initialSelection],
  singleValue: "1",
  multiValue: ["1"],
};

export default function Debug() {
  const { state, mergeState } = useStateEx<State>({
    singleOption: initialSelection,
    multiOption: [initialSelection],
    singleValue: "1",
    multiValue: ["1"],
  });

  const [menuState, setMenuState] = useState<Array<boolean | undefined>>([]);

  const isMenuOpen = (index: number) => {
    return menuState[index];
  };

  const setMenuOpen = (index: number) => {
    return (value: boolean) => {
      const clone = menuState.slice();
      if (value === true) {
        clone[index] = true;
      } else {
        clone[index] = undefined;
      }
      setMenuState(clone);
    };
  };

  function load(): typeof defaultFormValues {
    const json = localStorage.getItem("debug-select-state");
    if (json && json.length > 0) return JSON.parse(json);
    return cloneDeep(defaultFormValues);
  }

  const initial = load();
  const { register, control, watch } = useForm({
    defaultValues: initial,
  });
  const settings = { ...initial, ...watch() };
  localStorage.setItem("debug-select-state", JSON.stringify(settings));

  const createOptions = useCallback(
    (prefix: string, count: number, index: number = 1) => {
      return range(index, index + count).map((i) => {
        return {
          label: prefix + " item " + i,
          value: i.toString(),
          description: settings.withDescription
            ? `Description for ${prefix} item ${i}\nNew line of the description`
            : undefined,
        };
      });
    },
    [settings.withDescription]
  );

  const createOptionsModel = useCallback(
    (prefix: string) => {
      if (settings.grouped) {
        return [
          { label: "Group 1", options: createOptions(prefix, 10) },
          { label: "Group 2", options: createOptions(prefix, 10, 11) },
        ];
      } else {
        return createOptions(prefix, 20);
      }
    },
    [settings.grouped, createOptions]
  );

  const normalOptions = createOptionsModel("normal");

  const fetchOptions = useCallback(
    (input: string) => {
      return new Promise<ReadonlyOptions>((resolve) => {
        window.setTimeout(() => {
          resolve(createOptionsModel(input + " async"));
        }, 1000);
      });
    },
    [createOptionsModel]
  );

  const type = settings.filter ? "filter" : "form";
  const icon = useMemo(() => (settings.icon ? <AllStores /> : undefined), [settings.icon]);

  const SelectOptions = ({ title, index }: { title: string; index: number }) => {
    return (
      <div className="example-description">
        <label className="form-label">{title}</label>
        <Checkbox
          name={`menu-${index}`}
          label="Open Menu"
          value={isMenuOpen(index)}
          onCheckedChange={setMenuOpen(index)}
        />
      </div>
    );
  };

  return (
    <div className="page-content debug-page">
      <form>
        <div className="form-row">
          <Checkbox {...register("readonly")} label="Readonly" />
          <Checkbox {...register("required")} label="Required" />
          <Checkbox {...register("withDescription")} label="With Description" />
          <Checkbox {...register("filter")} label="Filter Design" />
          <Checkbox {...register("icon")} label="With Icon" />
          <Checkbox {...register("grouped")} label="Grouped" />
        </div>

        <div className="form-row">
          <h2>Normal</h2>
        </div>
        <div>
          <div className="form-row">
            <SelectOptions title="Select:" index={0} />
            <Select
              isMenuOpen={isMenuOpen(0)}
              value={state.singleValue}
              required={settings.required}
              readOnly={settings.readonly}
              options={normalOptions}
              type={type}
              icon={icon}
              onChange={(value) => mergeState({ singleValue: value })}
            />
            <ShowStateInDiv state={state.singleValue} />
          </div>
          <div className="form-row">
            <SelectOptions title="Select (multi):" index={1} />
            <Select
              isMenuOpen={isMenuOpen(1)}
              isMulti={true}
              value={state.multiValue}
              required={settings.required}
              readOnly={settings.readonly}
              options={normalOptions}
              type={type}
              icon={icon}
              onChange={(values) => mergeState({ multiValue: values })}
            />
            <ShowStateInDiv state={state.multiValue} />
          </div>
          <div className="form-row">
            <SelectOptions title="FormSelect:" index={2} />
            <FormSelect
              isMenuOpen={isMenuOpen(2)}
              name="singleValue"
              control={control}
              required={settings.required}
              readOnly={settings.readonly}
              options={normalOptions}
              type={type}
              icon={icon}
            />
            <ShowFormInDiv control={control} pick={["singleValue"]} />
          </div>
          <div className="form-row">
            <SelectOptions title="FormSelect (multi):" index={3} />
            <FormSelect
              isMenuOpen={isMenuOpen(3)}
              isMulti={true}
              name="multiValue"
              control={control}
              required={settings.required}
              readOnly={settings.readonly}
              options={normalOptions}
              type={type}
              icon={icon}
            />
            <ShowFormInDiv control={control} pick={["multiValue"]} />
          </div>
        </div>
        <div>
          <div className="form-row">
            <h2>Async</h2>
          </div>
          <div className="form-row">
            <SelectOptions title="SelectAsync:" index={4} />
            <SelectAsync
              isMenuOpen={isMenuOpen(4)}
              value={state.singleOption}
              required={settings.required}
              readOnly={settings.readonly}
              loadOptions={fetchOptions}
              type={type}
              icon={icon}
              onChange={(opt) => mergeState({ singleOption: opt })}
            />
            <ShowStateInDiv state={state.singleOption} />
          </div>
          <div className="form-row">
            <SelectOptions title="SelectAsync (multi):" index={5} />
            <SelectAsync
              isMenuOpen={isMenuOpen(5)}
              isMulti={true}
              value={state.multiOption}
              required={settings.required}
              readOnly={settings.readonly}
              loadOptions={fetchOptions}
              type={type}
              icon={icon}
              onChange={(opts) => mergeState({ multiOption: [...opts] })}
            />
            <ShowStateInDiv state={state.multiOption} />
          </div>
          <div className="form-row">
            <SelectOptions title="FormSelectAsync:" index={6} />
            <FormSelectAsync
              isMenuOpen={isMenuOpen(6)}
              name="singleOption"
              control={control}
              required={settings.required}
              readOnly={settings.readonly}
              loadOptions={fetchOptions}
              type={type}
              icon={icon}
            />
            <ShowFormInDiv control={control} pick={["singleOption"]} />
          </div>
          <div className="form-row">
            <SelectOptions title="FormSelectAsync (multi):" index={7} />
            <FormSelectAsync
              isMenuOpen={isMenuOpen(7)}
              isMulti={true}
              name="multiOption"
              control={control}
              required={settings.required}
              readOnly={settings.readonly}
              loadOptions={fetchOptions}
              type={type}
              icon={icon}
            />
            <ShowFormInDiv control={control} pick={["multiOption"]} />
          </div>
        </div>
        <div className="form-row">
          <h2>Raw</h2>
        </div>
        <div>
          <div className="form-row">
            <SelectOptions title="Select:" index={8} />
            <ReactSelect
              menuIsOpen={isMenuOpen(8)}
              className="react-select"
              value={state.singleOption}
              isClearable={!settings.required}
              isDisabled={settings.readonly}
              options={normalOptions}
              onChange={(opt) => mergeState({ singleOption: opt })}
            />
            <ShowStateInDiv state={state.singleOption} />
          </div>
          <div className="form-row">
            <SelectOptions title="Select (multi):" index={9} />
            <ReactSelect
              menuIsOpen={isMenuOpen(9)}
              className="react-select"
              isMulti={true}
              value={state.multiOption}
              isClearable={!settings.required}
              isDisabled={settings.readonly}
              options={normalOptions}
              closeMenuOnSelect={false}
              onChange={(opts) => mergeState({ multiOption: [...opts] })}
            />
            <ShowStateInDiv state={state.multiOption} />
          </div>
          <div className="form-row">
            <SelectOptions title="SelectAsync (multi):" index={10} />
            <AsyncSelect
              menuIsOpen={isMenuOpen(10)}
              className="react-select"
              isMulti={true}
              value={state.multiOption}
              isClearable={!settings.required}
              isDisabled={settings.readonly}
              defaultOptions={state.multiOption}
              closeMenuOnSelect={false}
              loadOptions={fetchOptions}
              onChange={(opts) => mergeState({ multiOption: [...opts] })}
            />
            <ShowStateInDiv state={state.multiOption} />
          </div>
        </div>
      </form>
    </div>
  );
}
