import clsx from "clsx";
import { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import { useDeploymentConfig } from "../../state/deployment";

import "./Login.scss";

function Login(props: PropsWithChildren<{ hideLogo?: boolean; hideSSL?: boolean; forCustomers?: boolean }>) {
  const deployment = useDeploymentConfig();
  let loginWallpaperSrc = deployment.config.loginWallpaperSrc;
  if (!loginWallpaperSrc.startsWith("/")) loginWallpaperSrc = "/" + loginWallpaperSrc;
  return (
    <div
      className={clsx({ "page-login": true, "for-customers": !!props.forCustomers, "for-admins": !props.forCustomers })}
    >
      <div className={clsx({ "login-form": true })}>
        <NavLink to="/">
          <h1 className="beacon-title">{deployment.config.loginLogo}</h1>
        </NavLink>
        <div className="form-notice">
          {props.children}
          {!props.hideSSL && (
            <>
              <div className="ssl-pic">
                <img style={{ width: "76px" }} src="/images/ssl.png" alt="ssl"></img>
              </div>
              <p>Copyright © beaconsmind AG.</p>
              <p>beaconsmind is a registered trademark. All rights reserved.</p>
              <p className="terms-of-use">Terms of use. Privacy policy.</p>
            </>
          )}
        </div>
      </div>
      {!props.forCustomers && (
        <div className="login-pic">
          <img alt="wallpaper" src={loginWallpaperSrc} />
        </div>
      )}
    </div>
  );
}

export default Login;
