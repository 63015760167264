import { sumBy } from "lodash";
import * as yup from "yup";
import {
  BeaconModel,
  BlogPostModel,
  CampaignModel,
  ConstrainHoursModel,
  CustomAudienceExpression,
  DuringPeriodModel,
  FeaturedItemModel,
  ForgotPasswordRequest,
  IndefiniteModel,
  LabelValue,
  LocalizationTextModel,
  MessageModel,
  MessageResend,
  OnlyOnceModel,
  PocUserModel,
  ProductCategoryModel,
  ProductModel,
  ResendInterval,
  RichText,
  ScheduleModel,
  SegmentGroupModel,
  SegmentModel,
  StoreModel,
  TranslationModel,
  ValidityModel,
} from "../api";
import { ResetPasswordModel } from "../pages/login/forms/ResetPasswordForm";
import { isProvided } from "./objects";
import { LoginFormFields } from "./types";

function newError(path: string, message: string) {
  return new yup.ValidationError(message, undefined, path);
}

export const StoreValidator = yup
  .object<Partial<StoreModel>>({
    name: yup.string().max(100).required("Name is required").label("Name"),
    country: yup.object<LabelValue>().required("Country is required").label("Country").typeError("Country is required"),
    city: yup.object<LabelValue>().required("City is required").label("City").typeError("City is required"),
    squareMeter: yup.number().integer().required().label("Area").typeError("Area should be a integer"),
    openingDate: yup
      .string()
      .required("Opening date is required")
      .label("Opening date")
      .typeError("Opening date is required"),
    closingDate: yup.string().notRequired().nullable().label("Closing date"),
    email1: yup.string().label("Email").email().max(100).notRequired().nullable(),
    email2: yup.string().label("Email").email().max(100).notRequired().nullable(),
    phone: yup.string().label("Phone").max(100).nullable(),
    fax: yup.string().label("Phone").max(100).nullable(),
    zipCode: yup.string().label("Zip code").max(100).nullable(),
    storeManager: yup.string().label("Store manager").max(100).nullable(),
    street: yup.string().label("Street").max(100).nullable(),
    instagramUrl: yup.string().label("Instagram url").max(100).nullable(),
    facebookUrl: yup.string().label("Facebook url").max(100).nullable(),
  })
  .defined();

export const BeaconValidator = yup.object<Partial<BeaconModel>>({
  name: yup.string().required("Name is required").label("Name"),
  uuid: yup
    .string()
    .required("UUID is required")
    .typeError("UUID is required")
    .matches(/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/, {
      excludeEmptyString: true,
      message: "UUID should be valid",
    })
    .label("UUID"),
  major: yup.string().required("Major is required").max(16).label("Major"),
  minor: yup.string().required("Minor is required").max(16).label("Minor"),
  store: yup
    .object<LabelValue>({
      value: yup.number().required("Please assign to a Store"),
    })
    .required("Store is required")
    .label("Store"),
  beaconType: yup.string().required("Type is required").typeError("Type is required").max(100).label("Type"),
  maxVisitDurationSeconds: yup
    .number()
    .integer()
    .required()
    .label("Max Contact Duration")
    .typeError("Duration should be a integer"),
  area: yup
    .object<LabelValue>()
    .nullable()
    .label("Area")
    .test("area", "Area should have a name", (model) => {
      if (typeof model?.value === "number") {
        return typeof model.label === "string" && model.label.length > 0;
      }
      return true;
    }),
});

export const CampaignValidator = yup
  .object<Partial<CampaignModel>>({
    name: yup.string().max(100).required("Name is required").typeError("Name is required").label("Name"),
    description: yup.string().notRequired().nullable().label("Description"),
    validFrom: yup.string().nullable(),
    validTo: yup.string().nullable(),
  })
  .defined();

function richTextValidator(name: string, message: string) {
  return yup
    .object<RichText>()
    .test(name, message, (model) => {
      if (!!model && typeof model.plain === "string" && typeof model.rich === "string") {
        return model.plain.length > 0 && model.rich.length > 0;
      } else {
        return false;
      }
    })
    .required();
}

export function MessageValidator(languages: string[]) {
  return yup.object<Partial<MessageModel>>({
    campaign: yup.object<LabelValue>({
      value: yup
        .number()
        .label("Campaign")
        .required()
        .test("campaign", "Campaign is required", (c) => !!c),
    }),
    translations: yup
      .array(
        yup
          .object<TranslationModel>({
            languageCode: yup.string().required("Language is required"),
            title: yup.string().label("Title").max(100).required("Title is required"),
            pushMessage: yup.string().label("Push Message").max(100).required("Push message is required"),
            offerTitle: yup.string().label("Offer Title").max(100).required("Offer Title is required"),
            text: richTextValidator("Text", "Text is required"),
            callToActionTitle: yup.string().label("Call to action text").notRequired().max(100).nullable(),
            callToActionLink: yup.string().label("Url").notRequired().nullable(),
          })
          .required()
          .test("translations", "Call to action Text and Url must both have values or be empty", (model) => {
            if (!model) return false;
            const bothHaveValues = !!model.callToActionTitle && !!model.callToActionLink;
            const bothEmpty = !model.callToActionTitle && !model.callToActionLink;
            return bothHaveValues || bothEmpty;
          })
      )
      .required("Missing translations")
      .test("translations", "Missing translations", (model?: TranslationModel[] | null) => {
        if (!model || model.length < languages.length) return false;
        const receivedCodes = model.map((t) => t.languageCode);
        for (const expectedCode of languages) {
          if (!receivedCodes.includes(expectedCode)) return false;
        }
        return true;
      }),
    imageUrl: yup.string().label("Image").required("Image is required").typeError("Image is required"),
    grayImageUrl: yup.string().label("Image").required("Image is required").typeError("Image is required"),
    thumbnailUrl: yup.string().label("Image").required("Image is required").typeError("Image is required"),
    grayThumbnailUrl: yup.string().label("Image").required("Image is required").typeError("Image is required"),
    schedule: ScheduleValidator.required("Schedule is required").typeError("Schedule is required"),
    validity: yup
      .object<ValidityModel>({
        absoluteDateTime: yup.string().nullable().notRequired(),
        monthsAfterSent: yup.number().nullable().notRequired(),
      })
      .required("Validity has to be configured")
      .typeError("Validity has to be configured")
      .test("validity", "Validity has to be configured", (model) => {
        if (!model) return false;
        const hasAbs = typeof model.absoluteDateTime === "string";
        const hasRel = typeof model.monthsAfterSent === "number";
        return hasAbs !== hasRel;
      }),
    customAudience: yup.object<CustomAudienceExpression>().test("customAudience", "Audience is required", (model) => {
      const length = model?.parts?.length || 0;
      return length > 0;
    }),
  });
}

export const ScheduleValidator = yup
  .object<Partial<ScheduleModel>>({
    onlyOnce: yup
      .object<OnlyOnceModel>({
        dateTime: yup.string().required(),
      })
      .default(undefined)
      .nullable()
      .notRequired(),
    indefiniteFrom: yup
      .object<IndefiniteModel>({
        dateTime: yup.string().required(),
      })
      .default(undefined)
      .nullable()
      .notRequired(),
    duringPeriod: yup
      .object<DuringPeriodModel>({
        from: yup.string().label("From").required("From is required"),
        to: yup.string().label("To").required("To is required"),
      })
      .default(undefined)
      .nullable()
      .notRequired(),
    constrainHours: yup
      .object<ConstrainHoursModel>({
        from: yup.number().required(),
        to: yup.number().required(),
      })
      .default(undefined)
      .nullable()
      .notRequired(),
    messageResend: yup
      .object<MessageResend>({
        resendInterval: yup.number().label("Resend Interval").required(),
        customNumberOfDays: yup
          .number()
          .integer()
          .min(1)
          .label("Custom Days")
          .typeError("Must be specified")
          .nullable(true)
          .transform((_, val) => (val === Number(val) ? val : null)),
      })
      .nullable()
      .notRequired(),
  })
  .test("", "", (model) => {
    if (!model) return true;
    if (!model.onlyOnce && !model.duringPeriod && !model.indefiniteFrom)
      return newError("schedule", "Schedule is required");
    return true;
  })
  .test("", "", (model) => {
    if (!model) return true;
    if (!!model.onlyOnce && !!model.duringPeriod && !model.indefiniteFrom)
      return newError("schedule", "Schedule cannot be only once and during period");
    return true;
  })
  .test("", "", (model) => {
    if (model?.messageResend?.resendInterval === ResendInterval.CustomDays) {
      const nmDays = model.messageResend?.customNumberOfDays;
      if (!isProvided(nmDays) || nmDays <= 0) {
        return newError("messageResend.customNumberOfDays", "Custom days must be specified");
      }
    }
    return true;
  });

export const SegmentGroupValidator = yup
  .object<Partial<SegmentGroupModel>>({
    title: yup.string().max(100).required("Title is required").typeError("Title is required").label("Title"),
    segments: yup
      .array<SegmentModel>()
      .test("segments", "Customer distribution should total 100%", (model) => {
        if (!model || model.length === 0) return false;
        const total = sumBy(model, (m) => m.size);
        return total === 100;
      })
      .required()
      .defined(),
  })
  .defined();

export const ForgotPasswordValidator = yup.object<Partial<ForgotPasswordRequest>>({
  email: yup.string().label("Email").required().email().max(100).typeError("Email is required"),
});

export const LoginFormValidator = yup.object<Partial<LoginFormFields>>({
  username: yup.string().label("Username").email().typeError("Username is required").max(100),
  password: yup.string().label("Password"),
});

export const ResetPasswordValidator = yup
  .object<Partial<ResetPasswordModel>>({
    password: yup
      .string()
      .label("Password")
      .required("Password is required")
      .typeError("Password is required")
      .test("password", "Password should be at least 6 characters", (value, context) => {
        value = value?.trim() || "";
        if (value.length < 6) return false;
        return true;
      }),
    confirmPassword: yup
      .string()
      .label("Password")
      .required("Please confirm password")
      .typeError("Please confirm password"),
  })
  .test("confirmPassword", "Passwords should match", (value, context) => {
    if (value && value.password !== value.confirmPassword) {
      return newError("confirmPassword", "Passwords should match");
    }
    return true;
  });

export const ProductCategoryValidator = (languages: string[]) => {
  return yup.object<Partial<ProductCategoryModel>>({
    url: yup
      .string()
      .label("Url")
      .notRequired()
      .nullable()
      .test("url", "Url must start with http:// or https://", (model) => {
        if (model) {
          return model.startsWith("http://") || model.startsWith("https://");
        }
        return true;
      }),
    imageUrl: yup
      .string()
      .label("Image Url")
      .required("Image is required")
      .test("url", "Image Url must start with http:// or https://", (model) => {
        if (model) {
          return model.startsWith("http://") || model.startsWith("https://");
        }
        return true;
      }),
    nameLocalizations: LocalizationValidator(languages, "Name"),
  });
};

export const FeaturedItemValidator = (languages: string[]) => {
  return yup.object<Partial<FeaturedItemModel>>({
    url: yup
      .string()
      .label("URL")
      .notRequired()
      .nullable()
      .test("url", "Url must start with http:// or https://", (model) => {
        if (model) {
          return model.startsWith("http://") || model.startsWith("https://");
        }
        return true;
      }),
    imageUrl: yup
      .string()
      .label("Image Url")
      .required()
      .typeError("Image is required")
      .test("url", "Url must start with http:// or https://", (model) => {
        if (model) {
          return model.startsWith("http://") || model.startsWith("https://");
        }
        return true;
      }),
    textLocalizations: LocalizationValidator(languages, "Text", true),
  });
};

export const BlogPostValidator = (languages: string[]) => {
  return yup.object<Partial<BlogPostModel>>({
    imageUrl: yup.string().label("Image Url").notRequired().nullable(),
    authorImageUrl: yup.string().label("Author Image Url").notRequired().nullable(),
    date: yup.string().label("Date").required().typeError("Date is required"),
    authorName: yup.string().label("Author Name").required().typeError("Author name is required"),
    authorDescription: yup.string().label("Author Description").required().typeError("Author description is required"),
    titleLocalizations: LocalizationValidator(languages, "Title"),
    leadTextLocalizations: LocalizationValidator(languages, "Lead Text"),
    mainTextLocalizations: LocalizationValidator(languages, "Main Text"),
  });
};

export const ProductValidator = (languages: string[]) => {
  return yup
    .object<Partial<ProductModel>>({
      imageUrl: yup
        .string()
        .label("Image Url")
        .required("Image Url is a required field")
        .test("imageUrl", "Image Url must start with http:// or https://", (model) => {
          if (model) {
            return model.startsWith("http://") || model.startsWith("https://");
          }
          return true;
        }),
      url: yup
        .string()
        .label("Url")
        .required()
        .typeError("Url is required")
        .test("url", "Url must start with http:// or https://", (model) => {
          if (model) {
            return model.startsWith("http://") || model.startsWith("https://");
          }
          return true;
        }),
      categoryId: yup.number().label("Category").required().typeError("Category is required"),
      currency: yup.string().label("Currency").required().typeError("Currency is required"),
      fromPrice: yup.number().label("From Price").min(0, "From should be greater than 0").notRequired().nullable(true),
      toPrice: yup.number().label("To Price").min(0, "To should be greater than 0").notRequired().nullable(true),
      nameLocalizations: LocalizationValidator(languages, "Name"),
      descriptionLocalizations: LocalizationValidator(languages, "Description", true),
    })
    .test("custom", "", (model) => {
      if (typeof model?.fromPrice === "number" && typeof model?.toPrice === "number") {
        if (model.fromPrice >= model.toPrice) return newError("toPrice", "To price must be higher");
      }
      return true;
    });
};

const LocalizationValidator = (requiredLanguages: string[], field: string, optional: boolean = false) => {
  const testText = (text: string | null | undefined) => !!text || optional;
  return yup
    .array(
      yup
        .object<LocalizationTextModel>({
          languageCode: yup.string().required(`Language is required for ${field}`),
          text: yup.string().label(field).optional().test(field, `${field} is required`, testText),
        })
        .required()
    )
    .required(`Missing translations for ${field}`)
    .test("translations", `Missing translations for ${field}`, (model?: LocalizationTextModel[] | null) => {
      let temp = model || [];
      return requiredLanguages.every((r) => temp.some((m) => m.languageCode === r));
    })
    .test("translations", `Optional ${field} requires all languages to be filled or empty!`, (model) => {
      if (!optional) return true;
      if (!model) return false;

      const texts = model.map((m) => m.text);
      const allTextsEmpty = texts.every((txt) => !txt);
      const allTextsProvided = texts.every((txt) => txt);

      return allTextsEmpty || allTextsProvided;
    });
};

export const PocUserValidator = yup
  .object<Partial<PocUserModel>>({
    firstName: yup.string().required("First Name is required").typeError("First Name is required").label("First Name"),
    lastName: yup.string().required("Last Name is required").typeError("Last Name is required").label("Last Name"),
    email: yup.string().label("Email").required().email().max(100).typeError("Email is required"),
    expirationDate: yup
      .string()
      .label("Expiration Date")
      .required()
      .typeError("Expiration Date is required")
      .test("expirationDate", "Expiration date must be in the future", (value, context) => {
        if (value) {
          const date = new Date(value);
          if (date) {
            const now = new Date();
            const isValid = now < date;
            return isValid;
          }
        }
        return false;
      }),
  })
  .defined();
