import { find } from "lodash";
import { useEffect, useState } from "react";
import { MessageLookupView, MessagesApi, ReceptionPeriod, RecipientSelection } from "../../../../api";
import { isHttpOk } from "../../../../services/api";
import { getApiConfig } from "../../../../state/configuration";
import { Select } from "../../../forms/Select";
import { editorClassName, EditorProps } from "./common";

export function ExistingOfferEditor(props: EditorProps) {
  const [messages, setMessages] = useState<MessageLookupView[]>([]);

  useEffect(() => {
    const config = getApiConfig();
    const api = new MessagesApi(config);
    api.getForLookup().then((result) => {
      if (isHttpOk(result)) {
        setMessages(result.data || []);
      }
    });
  }, []);

  const model = props.value.receivedExistingOffer || {
    period: ReceptionPeriod.Ever,
    selection: RecipientSelection.Exclude,
  };
  const messageOptions = messages.map((msg) => ({
    label: `[${msg.id}] ${msg.name || ""}`,
    description: msg.campaign || undefined,
    value: msg.id,
  }));
  const periodOptions = [
    { label: "today", value: ReceptionPeriod.Today },
    { label: "yesterday", value: ReceptionPeriod.SinceYesterday },
    { label: "last 7 days", value: ReceptionPeriod.InLastSevenDays },
    { label: "last 30 days", value: ReceptionPeriod.InLastThirtyDays },
    { label: "last 3 months", value: ReceptionPeriod.InLastThreeMonths },
    { label: "last 6 months", value: ReceptionPeriod.InLastSixMonths },
    { label: "any time", value: ReceptionPeriod.Ever },
  ];

  return (
    <div className={editorClassName(props, "customer-segment")}>
      <h2>{props.description}</h2>
      <div className="input-row single">
        <span className="prefix">Customers who</span>
        <Select
          type="form"
          placeholder="Selection"
          errorLabel="Selection"
          isMulti={false}
          required={true}
          value={model.selection}
          options={[
            { label: "received", value: RecipientSelection.Include },
            { label: "did not receive", value: RecipientSelection.Exclude },
          ]}
          error={props.error}
          onChange={(value) => {
            if (typeof value === "number")
              props.onChange({
                receivedExistingOffer: {
                  ...model,
                  selection: value,
                },
              });
          }}
        />
      </div>
      <div className="input-row single">
        <span className="prefix">message</span>
        <Select
          type="form"
          placeholder={messages.length === 0 ? "Loading..." : "select message"}
          errorLabel="Message"
          isMulti={false}
          required={true}
          value={model.offer?.id || null}
          options={messageOptions}
          error={props.error}
          onChange={(value) => {
            const selectedMessage = find(messages, (o) => o.id === value);
            if (selectedMessage) {
              props.onChange({
                receivedExistingOffer: {
                  ...model,
                  offer: {
                    name: selectedMessage.name,
                    id: selectedMessage.id,
                  },
                },
              });
            }
          }}
        />
      </div>
      <div className="input-row single">
        <span className="prefix">
          {model.period === ReceptionPeriod.Ever
            ? "at"
            : model.period === ReceptionPeriod.SinceYesterday
            ? "since"
            : "during"}
        </span>
        <Select
          type="form"
          placeholder="select period"
          errorLabel="Period"
          isMulti={false}
          required={true}
          value={model.period}
          options={periodOptions}
          error={props.error}
          onChange={(value) => {
            if (typeof value === "number") {
              props.onChange({
                receivedExistingOffer: {
                  ...model,
                  period: value,
                },
              });
            }
          }}
        />
      </div>
    </div>
  );
}
