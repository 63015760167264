import { isArray } from "lodash";
import { forwardRef, Ref } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { FieldError } from "react-hook-form";
import { stringFromDate, stringToDate } from "../../../services/date";
import { AddNew, Calendar, PageNext, PagePrevious } from "../../icons/Icons";

import "./DatePicker.Calendar.scss";
import "./DatePicker.scss";

type Props = {
  value: IsoDateTimeString | null | undefined;
  isRange?: boolean;
  toValue?: IsoDateTimeString | null;
  onChange: (value?: IsoDateTimeString, toValue?: IsoDateTimeString) => void;
  errorLabel?: string;
  error?: FieldError;
  className?: string;
  withTime?: boolean;
  placeholder?: string;
  required?: boolean;
  readOnly?: boolean;
  shortPreview?: boolean;
};

export function DatePicker(props: Props) {
  const { value, toValue, withTime } = props;

  const handleChange: ReactDatePickerProps["onChange"] = (value: Date | [Date | null, Date | null] | null) => {
    if (isArray(value)) {
      props.onChange(
        value[0] ? stringFromDate(value[0], withTime) : undefined,
        value[1] ? stringFromDate(value[1], withTime) : undefined
      );
    } else {
      props.onChange(value ? stringFromDate(value, withTime) : undefined);
    }
  };

  const from = stringToDate(value, withTime);
  const to = stringToDate(toValue, withTime);

  const InputWithIcon = forwardRef(({ value, onClick, onClear }: any, ref: Ref<HTMLInputElement>) => {
    const error = props.error?.message;
    const className = error ? "invalid" : "";
    return (
      <div>
        <label className="date-picker-label">
          <input
            readOnly
            className={className}
            placeholder={placeholder}
            type="text"
            value={value}
            onClick={onClick}
            ref={ref}
          />
          {value === "" ? (
            <Calendar className="input-icon" onClick={onClick} />
          ) : (
            <AddNew style={{ transform: "rotate(45deg)" }} onClick={onClear} className="input-icon" />
          )}
          {error ? <div className="error">{error}</div> : null}
        </label>
      </div>
    );
  });

  const tryClearValue = () => {
    if (props.readOnly) return;
    props.onChange(undefined, undefined);
  };

  const placeholder = props.placeholder || (props.isRange ? "Enter date range" : "Enter date");
  const yearFormat = props.shortPreview ? "yy" : "yyyy";
  const dateFormat = withTime ? `dd.MM.${yearFormat} HH:mm` : `dd.MM.${yearFormat}`;
  const className = [props.className, "date-picker", "form-input"].join(" ");
  const selected = stringToDate(value, withTime);

  return (
    <ReactDatePicker
      wrapperClassName={className}
      readOnly={props.readOnly}
      selected={selected}
      {...(props.isRange ? { selectsRange: true, startDate: from, endDate: to } : {})}
      showTimeSelect={withTime}
      placeholderText={placeholder}
      onChange={handleChange}
      dateFormat={dateFormat}
      required={props.required}
      isClearable={false}
      customInput={<InputWithIcon onClear={tryClearValue} />}
      disabledKeyboardNavigation={true}
      nextMonthButtonLabel={<PageNext />}
      previousMonthButtonLabel={<PagePrevious />}
    />
  );
}
