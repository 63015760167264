import { FieldError } from "react-hook-form";
import { DatePicker } from "../../../../forms/date-picker/DatePicker";

type FixedDateModel = { from?: IsoDateTimeString; to?: IsoDateTimeString };

export function FixedDateInput(props: {
  isRange?: boolean;
  error?: FieldError;
  value?: FixedDateModel | null;
  onChange: (value: FixedDateModel) => void;
}) {
  const from = props.value?.from;
  const to = props.value?.to;

  const onChange = (value?: string, toValue?: string) => {
    props.onChange({
      from: value,
      to: toValue,
    });
  };

  return (
    <div className="fixed-date">
      <DatePicker
        value={from}
        {...(props.isRange ? { isRange: true, shortPreview: true, toValue: to } : {})}
        onChange={onChange}
        required={true}
        error={props.error}
      />
    </div>
  );
}
