import { useEffect } from "react";
import { FieldError, UseFormReturn, useFormState } from "react-hook-form";
import { toast as alert } from "react-toastify";
import { useStateEx } from "../../services/hooks";
import { ValidationMessage } from "../widgets/Alerts";

type FieldErrorPath = FieldError & { path: string };

type state = {
  error: FieldErrorPath | undefined;
  shouldDisplay: boolean;
};

function isFieldError(item: any): item is FieldError {
  return "type" in item && ("message" in item || "ref" in item || "types" in item);
}

function firstError(item?: FieldError | any, path?: string): FieldErrorPath | undefined {
  if (!item) return undefined;
  if (isFieldError(item)) {
    return {
      ...item,
      path: path || "",
    };
  }
  for (const key of Object.keys(item)) {
    const maybeError = item[key];
    if (maybeError) {
      const deepError = firstError(maybeError, path ? `${path}.${key}` : key);
      if (deepError) return deepError;
    }
  }
  return undefined;
}

type Props<TModel> = {
  control: UseFormReturn<TModel>["control"];
};

export function AlertMissingValidationErrors<TModel>(props: Props<TModel>) {
  const { errors, isSubmitting } = useFormState<TModel>({
    control: props.control,
  });

  const { state, mergeState } = useStateEx<state>({
    error: undefined,
    shouldDisplay: true,
  });

  useEffect(() => {
    // Allow validation alert to be repeated if user submits
    if (isSubmitting) mergeState({ shouldDisplay: true });
  }, [isSubmitting, mergeState]);

  // If error has changed, save it
  const optionalError = firstError(errors);
  if (optionalError?.path !== state.error?.path) mergeState({ error: optionalError, shouldDisplay: false });

  useEffect(() => {
    const error = state.error;
    if (!error) return;

    // Show alert if no validation errors in form
    const visibleFormErrors = [...document.querySelectorAll<HTMLDivElement>(".error")];
    if (visibleFormErrors.length === 0 && state.shouldDisplay) {
      const text = error.message || error.path + " is invalid!";
      alert.error(<ValidationMessage message={text} />);
      mergeState({ shouldDisplay: false });
    }
  }, [state, mergeState]);

  return <></>;
}
