import { useEffect } from "react";
import { AnalyticsApi, VisitorStatisticsApi } from "../../api";
import StatsCardSimple from "../../app/cards/StatsCardSimple";
import { ConversionStats, LoyalCustom, NewAppCustomers, VipCustom } from "../../app/icons/Icons";
import { isHttpOk } from "../../services/api";
import { formatPercentage } from "../../services/format";
import { useStateEx } from "../../services/hooks";
import { useLocalizedText } from "../../services/localization";
import { getApiConfig } from "../../state/configuration";
import { useTimeLocationFilters } from "../../state/globalFilters";

export function InteractionBasedStatistics() {
  type State = {
    isLoading: boolean;
    newVisitors?: number;
    visitors?: number;
    convertedVisits?: number;
    convertedVisitRate?: number;
  };

  const texts = useLocalizedText();
  const filters = useTimeLocationFilters();
  const { state, mergeState } = useStateEx<State>({
    isLoading: true,
  });

  useEffect(() => {
    mergeState({ isLoading: true });

    const apiConfig = getApiConfig();
    const analyticsApi = new AnalyticsApi(apiConfig);
    const visitorStatsApi = new VisitorStatisticsApi(apiConfig);

    const promises = [
      visitorStatsApi.getNewVisitorsCount(filters).then((result) => {
        if (isHttpOk(result)) mergeState({ newVisitors: result.data });
      }),

      visitorStatsApi.getVisitorsCount(filters).then((result) => {
        if (isHttpOk(result)) mergeState({ visitors: result.data });
      }),

      analyticsApi.getConversionStatistics(filters).then((result) => {
        if (isHttpOk(result))
          mergeState({
            convertedVisits: result.data.total,
            convertedVisitRate: result.data.conversionRate,
          });
      }),
    ];

    Promise.all(promises).finally(() => {
      mergeState({ isLoading: false });
    });
  }, [filters, mergeState]);

  return (
    <section className="revenue-statistics">
      <StatsCardSimple
        value={state.newVisitors}
        tooltipText={texts.appCustomersPage.items.newVisitors.tooltip}
        label={texts.appCustomersPage.items.newVisitors.title}
        color="lightblue"
        figure={<NewAppCustomers />}
      />
      <StatsCardSimple
        value={state.visitors}
        tooltipText={texts.appCustomersPage.items.visitors.tooltip}
        label={texts.appCustomersPage.items.visitors.title}
        color="orange"
        figure={<VipCustom />}
      />
      <StatsCardSimple
        value={state.convertedVisits}
        tooltipText={texts.appCustomersPage.items.convertedVisitors.tooltip}
        label={texts.appCustomersPage.items.convertedVisitors.title}
        color="pink"
        figure={<LoyalCustom />}
      />
      <StatsCardSimple
        value={formatPercentage(state.convertedVisitRate)}
        tooltipText={texts.appCustomersPage.items.convertedVisitorRate.tooltip}
        label={texts.appCustomersPage.items.convertedVisitorRate.title}
        color="pink"
        figure={<ConversionStats />}
      />
    </section>
  );
}
