import { yupResolver } from "@hookform/resolvers/yup";
import { cloneDeep, set } from "lodash";
import { useEffect, useState } from "react";
import { DeepMap, ErrorOption, FieldError, FieldPath, useForm, useFormState } from "react-hook-form";
import { toast as alert } from "react-toastify";
import { FeaturedItemModel } from "../../api";
import { useStateEx } from "../../services/hooks";
import { checkErrors } from "../../services/localization";
import { FeaturedItemValidator } from "../../services/validators";
import { useDeploymentConfig } from "../../state/deployment";
import { AlertMissingValidationErrors } from "../forms/AlertMissingValidationErrors";
import { ImageUploadButton } from "../forms/ImageUpload";
import { FormInput as Input } from "../forms/Input";
import LanguagePicker from "../forms/LanguagePicker";
import { ValidationMessage } from "../widgets/Alerts";
import { FormModalProps, showErrors } from "./utils";

import "./EditFeaturedItem.scss";

interface Props extends FormModalProps<FeaturedItemModel> {
  existingItem: boolean;
}

type State = {
  model: Partial<FeaturedItemModel>;
  backendErrors: DeepMap<Partial<FeaturedItemModel>, FieldError>;
};

function EditFeaturedItem(props: Props) {
  const model: Partial<FeaturedItemModel> = props.initial || {
    textLocalizations: [],
  };
  const { state, mergeState } = useStateEx<State>({
    model: model,
    backendErrors: {},
  });

  const defaultModel = cloneDeep(state.model);
  const languageList = useDeploymentConfig().config.allowedLanguages || [];
  if (!defaultModel.textLocalizations || defaultModel.textLocalizations.length === 0) {
    defaultModel.textLocalizations = languageList.map((l) => {
      return { languageCode: l, text: "" };
    });
  }

  const [selectedLanguage, setSelectedLanguage] = useState<string>(languageList[0]);
  const { handleSubmit, formState, control, getValues, watch } = useForm<FeaturedItemModel>({
    defaultValues: defaultModel,
    resolver: yupResolver(FeaturedItemValidator(languageList)),
  });
  useEffect(() => {
    var subscription = watch(() => {
      if (props.onChange) props.onChange();
    });
    return () => subscription.unsubscribe();
  }, [watch, props]);

  const { errors } = useFormState<FeaturedItemModel>({ control });

  const translationIndex = defaultModel.textLocalizations.findIndex((t) => t.languageCode === selectedLanguage);
  const textField = `textLocalizations.${translationIndex}.text` as "textLocalizations.0.text";

  const cancel = () => {
    if (props.onCancel) props.onCancel();
  };

  const trySaveModel = async (model: FeaturedItemModel) => {
    if (props.onSave) {
      model = cloneDeep(model);
      const result = await props?.onSave(model);
      if (!result.success) {
        const errors = {};
        showErrors<FeaturedItemModel>(result, (path: FieldPath<FeaturedItemModel>, error: ErrorOption) => {
          alert.error(<ValidationMessage message={error.message || "Unknown error"} />);
          set(errors, path, error);
        });
      }
    }
  };

  const getLanguageErrors = (): string[] => (errors ? checkErrors(languageList, errors.textLocalizations) : []);

  const getTitle = () => (props.existingItem ? "Edit Featured Item" : "New Featured Item");

  const getImageUrl = () => state.model.imageUrl || "/images/image-placeholder.svg";

  const updateImageUrl = () => {
    mergeState({
      model: { ...state.model, imageUrl: getValues().imageUrl },
    });
  };

  return (
    <div className="edit-featured-item">
      <form className="featured-item-form" onSubmit={handleSubmit(trySaveModel)}>
        <AlertMissingValidationErrors control={control} />
        <div className="main">
          <div className="header">
            <h1 className="title">{getTitle()}</h1>
          </div>
          <div className="form-row">
            <LanguagePicker
              language={selectedLanguage}
              errors={getLanguageErrors()}
              onChange={(value) => {
                setSelectedLanguage(value);
              }}
            />
          </div>
          <div className="form-row">
            <Input
              key={textField}
              name={textField}
              type="text"
              label=""
              placeholder={`Text (optional)`}
              errors={errors}
              register={control.register}
            />
          </div>
          <div className="image" style={{ backgroundImage: `url(${getImageUrl()})` }}></div>
          <ImageUploadButton
            control={control}
            name="imageUrl"
            instruction="Image format: PNG or JPG max. 2MB"
            onUploaded={updateImageUrl}
          />
          <div className="form-row">
            <Input
              type="text"
              name="url"
              placeholder={"Click link Url (optional)"}
              errors={errors}
              register={control.register}
            />
          </div>
        </div>
        <div className="footer">
          <button type="submit" disabled={formState.isSubmitting} className="primary save">
            Save
          </button>
          <button type="button" onClick={cancel} className="primary cancel">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditFeaturedItem;
