import { addMinutes, format, getHours, getMinutes } from "date-fns";
import { isArray, isDate, isNumber } from "lodash";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { FieldError, FieldValues, Path, UseFormReturn, useController } from "react-hook-form";
import { ScheduleModel } from "../../api";
import { stringFromDate, stringToDate } from "../../services/date";
import { formatHour } from "../../services/format";
import { PageNext, PagePrevious } from "../icons/Icons";
import { ComplexOptions, ComplexSelector, EditorProps } from "./ComplexSelector";

import "./SchedulePicker.scss";

function OncePicker(props: EditorProps<ScheduleModel>) {
  const isNew = !props.value?.onlyOnce?.dateTime;
  const [value, setValue] = useState(stringToDate(props.value?.onlyOnce?.dateTime, true));

  const handleSave = (value: Date | undefined | null, closeAfterSave: boolean) => {
    if (value) {
      props.onSave(
        {
          onlyOnce: {
            dateTime: stringFromDate(value, true),
          },
          indefiniteFrom: null,
          constrainHours: null,
          duringPeriod: null,
        },
        closeAfterSave
      );
    }
  };

  const handleDateChange = (val: Date | [Date, Date] | null) => {
    if (!isArray(val)) {
      setValue(val);
      if (isNew && !!value) handleSave(val, true);
    }
  };
  const handleTimeChange = (val: Date | [Date, Date] | null) => {
    if (!isArray(val)) {
      setValue(val);
      if (isNew && !!value) handleSave(val, true);
    }
  };

  return (
    <div className="schedule-date-picker once" ref={props.wrapperRef}>
      <div className="container">
        <DatePicker
          inline={true}
          selected={value}
          selectsRange={false}
          onChange={handleDateChange}
          required={true}
          disabledKeyboardNavigation={true}
          nextMonthButtonLabel={<PageNext />}
          previousMonthButtonLabel={<PagePrevious />}
        />
        <DatePicker
          timeFormat="HH:mm"
          inline={true}
          selected={value}
          selectsRange={false}
          onChange={handleTimeChange}
          showTimeSelect={true}
          showTimeSelectOnly={true}
          required={true}
          disabledKeyboardNavigation={true}
        />
      </div>
      <button type="button" className="save" onClick={() => handleSave(value, true)}>
        Save
      </button>
      <button type="button" className="close" onClick={props.onCancel}>
        Cancel
      </button>
    </div>
  );
}

function IndefinitePicker(props: EditorProps<ScheduleModel>) {
  const isNew = !props.value?.indefiniteFrom?.dateTime;
  const [value, setValue] = useState(stringToDate(props.value?.indefiniteFrom?.dateTime, true));

  const handleSave = (value: Date | undefined | null, closeAfterSave: boolean) => {
    if (value) {
      props.onSave(
        {
          indefiniteFrom: {
            dateTime: stringFromDate(value, true),
          },
          onlyOnce: null,
          constrainHours: null,
          duringPeriod: null,
        },
        closeAfterSave
      );
    }
  };

  const handleDateChange = (val: Date | [Date, Date] | null) => {
    if (!isArray(val)) {
      setValue(val);
      if (isNew && !!value) handleSave(val, true);
    }
  };
  const handleTimeChange = (val: Date | [Date, Date] | null) => {
    if (!isArray(val)) {
      setValue(val);
      if (isNew && !!value) handleSave(val, true);
    }
  };

  return (
    <div className="schedule-date-picker once" ref={props.wrapperRef}>
      <div className="container">
        <DatePicker
          inline={true}
          selected={value}
          selectsRange={false}
          onChange={handleDateChange}
          required={true}
          disabledKeyboardNavigation={true}
          nextMonthButtonLabel={<PageNext />}
          previousMonthButtonLabel={<PagePrevious />}
        />
        <DatePicker
          timeFormat="HH:mm"
          inline={true}
          selected={value}
          selectsRange={false}
          onChange={handleTimeChange}
          showTimeSelect={true}
          showTimeSelectOnly={true}
          required={true}
          disabledKeyboardNavigation={true}
        />
      </div>
      <button type="button" className="save" onClick={() => handleSave(value, true)}>
        Save
      </button>
      <button type="button" className="close" onClick={props.onCancel}>
        Cancel
      </button>
    </div>
  );
}

function PeriodPicker(props: EditorProps<ScheduleModel>) {
  const isNew = !props.value?.duringPeriod?.from && !props.value?.duringPeriod?.to;
  const [dates, setDates] = useState([
    stringToDate(props.value?.duringPeriod?.from, false) || null,
    stringToDate(props.value?.duringPeriod?.to, false) || null,
  ]);

  const handleSave = (start: Date | null, end: Date | null, closeAfterSave: boolean) => {
    const date1 = start || dates[0];
    const date2 = end || dates[1];
    if (date1 && date2)
      props.onSave(
        {
          onlyOnce: null,
          constrainHours: null,
          indefiniteFrom: null,
          duringPeriod: {
            from: stringFromDate(date1, false),
            to: stringFromDate(date2, false),
          },
        },
        closeAfterSave
      );
  };

  const handleChange = (value: Date | [Date | null, Date | null] | null) => {
    if (isArray(value)) {
      setDates(value);
      if (isNew && value[0] && value[1]) handleSave(value[0], value[1], true);
    }
  };

  return (
    <div className="schedule-date-picker period" ref={props.wrapperRef}>
      <div className="container">
        <DatePicker
          inline={true}
          selected={dates[0]}
          startDate={dates[0]}
          endDate={dates[1]}
          selectsRange={true}
          onChange={handleChange}
          required={true}
          disabledKeyboardNavigation={true}
          nextMonthButtonLabel={<PageNext />}
          previousMonthButtonLabel={<PagePrevious />}
        />
      </div>
      <button type="button" className="save" onClick={() => handleSave(dates[0], dates[1], true)}>
        Save
      </button>
      <button type="button" className="close" onClick={props.onCancel}>
        Cancel
      </button>
    </div>
  );
}

function PeriodPickerWithHours(props: EditorProps<ScheduleModel>) {
  const isNew = !props.value?.duringPeriod?.from && !props.value?.duringPeriod?.to;
  const [dates, setDates] = useState([
    stringToDate(props.value?.duringPeriod?.from, false) || null,
    stringToDate(props.value?.duringPeriod?.to, false) || null,
  ]);
  const [hours, setHourState] = useState([
    props.value?.constrainHours?.from || null,
    props.value?.constrainHours?.to || null,
  ]);
  const [datesSelected, setDatesSelected] = useState(false);
  const [hour1Selected, setHour1Selected] = useState(false);
  const [hour2Selected, setHour2Selected] = useState(false);

  const handleSave = (date1: Date, date2: Date, time1: number, time2: number) => {
    props.onSave({
      onlyOnce: null,
      indefiniteFrom: null,
      constrainHours: {
        from: time1,
        to: time2,
      },
      duringPeriod: {
        from: stringFromDate(date1, false),
        to: stringFromDate(date2, false),
      },
    });
  };

  const handleSaveBtn = () => {
    if (dates[0] && dates[1] && isNumber(hours[0]) && isNumber(hours[1]))
      handleSave(dates[0], dates[1], hours[0], hours[1]);
  };

  const handleHourChange = ([a, b]: (number | null)[]) => {
    let hour1 = a;
    let hour2 = b;
    if (a !== null && b !== null) {
      if (a > b) {
        setHourState([b, a]);
        hour1 = b;
        hour2 = a;
      } else {
        setHourState([a, b]);
      }
    } else {
      setHourState([a, b]);
    }
    if (a !== null) setHour1Selected(true);
    if (a !== null && b !== null) {
      setHour2Selected(true);
      if (isNew && datesSelected) handleSave(dates[0]!, dates[1]!, hour1!, hour2!);
    }
  };

  type PickerValueType = Date | [Date | null, Date | null] | null;

  const handleDateChange = (value: PickerValueType) => {
    if (isArray(value)) {
      setDates(value);

      if (!value[1]) {
        setDatesSelected(true);
        if (isNew && hour1Selected && hour2Selected) handleSave(dates[0]!, dates[1]!, hours[0]!, hours[1]!);
      }
    }
  };

  const handleStartHourChange = (value: PickerValueType) => {
    if (isDate(value)) {
      const newHours = [getHours(value) + getMinutes(value) / 60, hours[1]];
      handleHourChange(newHours);
    }
  };

  const handleEndHourChange = (value: PickerValueType) => {
    if (isDate(value)) {
      const newHours = [hours[0], getHours(value) + getMinutes(value) / 60];
      handleHourChange(newHours);
    }
  };

  const fixedDate = new Date(2020, 1, 1);

  return (
    <div className="schedule-date-picker period-hours" ref={props.wrapperRef}>
      <div className="container">
        <DatePicker
          inline={true}
          selected={dates[0]}
          startDate={dates[0]}
          endDate={dates[1]}
          selectsRange={true}
          onChange={handleDateChange}
          required={true}
          disabledKeyboardNavigation={true}
          nextMonthButtonLabel={<PageNext />}
          previousMonthButtonLabel={<PagePrevious />}
        />
        <DatePicker
          timeCaption="From"
          timeFormat="HH:mm"
          inline={true}
          timeIntervals={15}
          selected={hours[0] != null ? addMinutes(fixedDate, hours[0] * 60) : null}
          selectsRange={false}
          onChange={handleStartHourChange}
          showTimeSelect={true}
          showTimeSelectOnly={true}
          required={true}
          disabledKeyboardNavigation={true}
        />
        <DatePicker
          timeCaption="To"
          timeFormat="HH:mm"
          inline={true}
          timeIntervals={15}
          selected={hours[1] !== null ? addMinutes(fixedDate, hours[1] * 60) : null}
          selectsRange={false}
          onChange={handleEndHourChange}
          showTimeSelect={true}
          showTimeSelectOnly={true}
          required={true}
          disabledKeyboardNavigation={true}
        />
      </div>
      <button type="button" className="save" onClick={handleSaveBtn}>
        Save
      </button>
      <button type="button" className="close" onClick={props.onCancel}>
        Cancel
      </button>
    </div>
  );
}

export function renderScheduleModel(model: ScheduleModel | null) {
  if (model) {
    if (model.onlyOnce) {
      const value = stringToDate(model.onlyOnce.dateTime, true);
      return "At " + format(value, "dd.MM.yyyy HH:mm");
    } else if (model.indefiniteFrom) {
      const value = stringToDate(model.indefiniteFrom.dateTime, true);
      return "From " + format(value, "dd.MM.yyyy HH:mm");
    } else if (model.duringPeriod?.from && model.duringPeriod?.to) {
      let dates = "";
      const fromDate = stringToDate(model.duringPeriod.from, false);
      const toDate = stringToDate(model.duringPeriod.to, false);
      if (fromDate.getFullYear() === toDate.getFullYear()) {
        dates = `${format(fromDate, "dd.MM.")} - ${format(toDate, "dd.MM.yyyy")}`;
      } else {
        dates = `${format(fromDate, "dd.MM.yyyy")} - ${format(toDate, "dd.MM.yyyy")}`;
      }
      if (model.constrainHours) {
        dates += ` (${formatHour(model.constrainHours.from)} to ${formatHour(model.constrainHours.to)})`;
      }
      return dates;
    }
  }
  return "";
}

export function renderScheduleModelWithDuration(model: ScheduleModel | null | undefined) {
  if (model) {
    if (model.onlyOnce) {
      const value = stringToDate(model.onlyOnce.dateTime, true);
      return "At " + format(value, "HH:mm dd.MM.yyyy");
    } else if (model.indefiniteFrom) {
      const value = stringToDate(model.indefiniteFrom.dateTime, true);
      return "From " + format(value, "HH:mm dd.MM.yyyy");
    } else if (model.duringPeriod?.from && model.duringPeriod?.to) {
      let dates = "";
      const fromDate = stringToDate(model.duringPeriod.from, false);
      const toDate = stringToDate(model.duringPeriod.to, false);
      if (fromDate.getFullYear() === toDate.getFullYear()) {
        dates = `${format(fromDate, "dd.MM.")} - ${format(toDate, "dd.MM.yyyy")}`;
      } else {
        dates = `${format(fromDate, "dd.MM.yyyy")} - ${format(toDate, "dd.MM.yyyy")}`;
      }
      if (model.constrainHours) {
        dates += ` |At (${formatHour(model.constrainHours.from)} to ${formatHour(model.constrainHours.to)}) `;
      }
      return dates;
    }
  }
  return "";
}

export type SchedulePickerProps = {
  value: ScheduleModel | null;
  error?: FieldError;
  onChange: (value: ScheduleModel | null) => void;
};

const ScheduleTypes: ComplexOptions<ScheduleModel>[] = [
  {
    getOptionLabel: () => "At Specific Date",
    getRenderedValue: renderScheduleModel,
    isSelected: (model) => !!model.onlyOnce,
    useEditor: OncePicker,
  },
  {
    getOptionLabel: () => "From Specific Date",
    getRenderedValue: renderScheduleModel,
    isSelected: (model) => !!model.indefiniteFrom,
    useEditor: IndefinitePicker,
  },
  {
    getOptionLabel: () => "Between Dates",
    getRenderedValue: renderScheduleModel,
    isSelected: (model) => !!(model.duringPeriod && !model.constrainHours),
    useEditor: PeriodPicker,
  },
  {
    getOptionLabel: () => "Between Dates and Time",
    getRenderedValue: renderScheduleModel,
    isSelected: (model) => !!(model.duringPeriod && model.constrainHours),
    useEditor: PeriodPickerWithHours,
  },
];

export function SchedulePicker(props: SchedulePickerProps) {
  return (
    <ComplexSelector<ScheduleModel>
      {...props}
      className="schedule-picker"
      placeholder="Select schedule..."
      options={ScheduleTypes}
    />
  );
}

export function ControlledSchedulePicker<Model extends FieldValues>(props: {
  name: Path<Model>;
  control: UseFormReturn<Model>["control"];
}) {
  const { field, fieldState } = useController({
    name: props.name,
    control: props.control,
  });
  return (
    <SchedulePicker
      error={fieldState.error}
      value={(field.value || null) as any}
      onChange={(value) => {
        field.onChange(value);
      }}
    />
  );
}

export function StateSchedulePicker(props: {
  value?: ScheduleModel | null;
  error?: FieldError | undefined;
  onChange?: (value: ScheduleModel) => void;
}) {
  const { value: defaultValue, onChange, error } = props;
  return (
    <SchedulePicker
      error={error}
      value={defaultValue || null}
      onChange={(value) => {
        const empty = {
          onlyOnce: undefined,
          duringPeriod: undefined,
          constrainHours: undefined,
          indefiniteFrom: undefined,
        };
        if (onChange) onChange(value || empty);
      }}
    />
  );
}
