import { useCallback, useMemo, useState } from "react";
import { StoresApi, TimeframeType, TopStoreGridView } from "../../api";
import { logErrorSilently } from "../../services/alerts";
import { isHttpOk } from "../../services/api";
import { useMoneyFormatter } from "../../services/format";
import { useLocalizedText } from "../../services/localization";
import { getApiConfig } from "../../state/configuration";
import { useDeploymentConfig } from "../../state/deployment";
import { useFilterDescriptionText, useIsSingleStore, useTimeLocationFilters } from "../../state/globalFilters";
import Card from "../cards/Card";
import { Header, PaginatedTable } from "../widgets/PaginatedTable";

import "./StoresTable.scss";

export enum Metric {
  Revenue = 1,
  StoreVisits = 2,
  BeaconContacts = 3,
  CustomerFavorite = 4,
}

type StoreMetricOption = {
  label: string;
  value: Metric;
};

export type StoresTableProps = {
  selectedMetric?: Metric;
  includeAllStores?: boolean;
  customOptions?: StoreMetricOption[];
};

export function StoresTable(props: StoresTableProps) {
  const { config } = useDeploymentConfig();
  const storesWord = config.translations.store.plural;

  const hasPurchaseHistories = !config.disablePurchaseHistories;

  const options = props.customOptions || [
    {
      label: "Customer Visits",
      value: Metric.StoreVisits,
    },
    {
      label: "Beacon Visits",
      value: Metric.BeaconContacts,
    },
    {
      label: "Most Favorited",
      value: Metric.CustomerFavorite,
    },
  ];
  if (!props.customOptions && hasPurchaseHistories) {
    options.splice(0, 0, {
      label: "Revenue",
      value: Metric.Revenue,
    });
  }

  const texts = useLocalizedText();
  const filters = useTimeLocationFilters();
  const { formatMoney } = useMoneyFormatter();
  const filterTexts = useFilterDescriptionText();
  const timefilterText = filterTexts.period;
  const locationFilterText = !useIsSingleStore() && filterTexts.locations !== "All Stores" ? filterTexts.locations : "";

  const [headers, setHeaders] = useState<Header[]>([
    texts.storesTable.items.name,
    texts.storesTable.items.city,
    texts.storesTable.items.area,
    texts.storesTable.items.nmOfBeacons,
    texts.storesTable.items.revenue,
  ]);

  const getMetricTexts = useCallback(
    (metric: Metric) => {
      const items = texts.storesTable.items;
      switch (metric) {
        case Metric.StoreVisits:
          return items.customerVisits;
        case Metric.BeaconContacts:
          return items.beaconVisits;
        case Metric.CustomerFavorite:
          return items.mostFavorited;
        default:
          return items.revenue;
      }
    },
    [texts]
  );

  const updateLastHeader = useCallback(
    (metric: Metric) => {
      const newHeader = getMetricTexts(metric);
      setHeaders((prev) => [...prev.slice(0, -1), newHeader]);
    },
    [getMetricTexts]
  );

  const selectedMetric = useMemo(() => {
    if (props.selectedMetric) updateLastHeader(props.selectedMetric);
    return props.selectedMetric;
  }, [updateLastHeader, props.selectedMetric]);

  const defaultValue = selectedMetric ?? options[0].value;
  const [metric, setMetric] = useState(defaultValue);

  const loadData = useCallback(
    async (skip: number, limit: number) => {
      const config = getApiConfig();
      const api = new StoresApi(config);
      const payLoad = {
        skip,
        size: limit,
        includeAllStores: props.includeAllStores,
        ...filters,
      };
      function getData() {
        switch (metric) {
          case Metric.StoreVisits:
            return api.getByVisits(payLoad);
          case Metric.BeaconContacts:
            return api.getByBeaconContacts(payLoad);
          case Metric.CustomerFavorite:
            return api.getByFavoriteCustomers(payLoad);
          default:
            return api.getByRevenue(payLoad);
        }
      }
      const result = await getData();

      if (isHttpOk(result)) return result.data;
      else {
        logErrorSilently(result);
        return {
          count: 0,
          items: [],
        };
      }
    },
    [metric, filters, props.includeAllStores]
  );

  const renderRow = (item: TopStoreGridView) => {
    return [
      item.name,
      item.city,
      item.squareMeter <= 0 ? "N/A" : `${item.squareMeter} m2`,
      item.nmOfBeacons,
      formatValue(item.value),
    ];
  };

  const formatValue = (value: number) => (metric === Metric.Revenue ? formatMoney(value) : value);

  const changeMetric = (stringValue: string) => {
    const value = parseInt(stringValue, 10);
    if (!isNaN(value)) {
      const metric = value as Metric;
      setMetric(metric);
      updateLastHeader(metric);
    }
  };

  const metricDropdown =
    options.length > 1 ? (
      <select value={metric} onChange={(e) => changeMetric(e.target.value)}>
        {options.map((option, i) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    ) : null;

  const title = `Top ${storesWord}`;
  var description = "";
  if (filters.rangeType !== TimeframeType.AllTime && metric !== Metric.CustomerFavorite) {
    description = metric === Metric.Revenue ? "Purchased " : "Visiting ";
    description += timefilterText;
  }
  if (locationFilterText) {
    if (description) description += " - ";
    description += locationFilterText;
  }

  return (
    <Card className="stores-data-table">
      <PaginatedTable
        mainHeader={
          <section>
            <header>
              <div>
                <h1>{title}</h1>
                <p className="description">{description}</p>
              </div>
              {metricDropdown}
            </header>
          </section>
        }
        initialPageSize={10}
        minLoadingTimeMs={200}
        tableHeaders={headers}
        tableDataSource={loadData}
        tableRowFactory={renderRow}
      />
    </Card>
  );
}
