import { useEffect } from "react";
import { AnalyticsApi, CustomerFilter } from "../../api";
import StatsCardSimple from "../../app/cards/StatsCardSimple";
import { LoyalCustom, NewAppCustomers, Prospect, VipCustom } from "../../app/icons/Icons";
import { isHttpOk } from "../../services/api";
import { useStateEx } from "../../services/hooks";
import { useLocalizedText } from "../../services/localization";
import { getApiConfig } from "../../state/configuration";
import { useTimeLocationFilters } from "../../state/globalFilters";

type Props = {
  selectedFilter?: CustomerFilter;
  onCustomerCardClick?: (filter?: CustomerFilter) => void;
};

export function RevenueBasedStatistics(props: Props) {
  type State = {
    isLoading: boolean;
    newCustomers?: number;
    vipCustomers?: number;
    mostActiveCustomers?: number;
    loyalCustomers?: number;
  };

  const texts = useLocalizedText();
  const filters = useTimeLocationFilters();
  const { state, mergeState } = useStateEx<State>({
    isLoading: true,
  });

  useEffect(() => {
    mergeState({ isLoading: true });

    const apiConfig = getApiConfig();
    const analyticsApi = new AnalyticsApi(apiConfig);

    const promises = [
      analyticsApi.getNewCustomersCount(filters).then((result) => {
        if (isHttpOk(result)) mergeState({ newCustomers: result.data });
      }),

      analyticsApi.getVipCustomersCount(filters).then((result) => {
        if (isHttpOk(result)) mergeState({ vipCustomers: result.data });
      }),

      analyticsApi.getMostActiveCustomersCount(filters).then((result) => {
        if (isHttpOk(result)) mergeState({ mostActiveCustomers: result.data });
      }),

      analyticsApi.getLoyalCustomersCount(filters).then((result) => {
        if (isHttpOk(result)) mergeState({ loyalCustomers: result.data });
      }),
    ];

    Promise.all(promises).finally(() => {
      mergeState({ isLoading: false });
    });
  }, [filters, mergeState]);

  const handleClick = (filter: CustomerFilter) => {
    if (!props.onCustomerCardClick) return;
    // reset if the same filter is selected again
    var selectedFilter = filter !== props.selectedFilter ? filter : undefined;
    props.onCustomerCardClick(selectedFilter);
  };

  return (
    <section className="revenue-statistics">
      <StatsCardSimple
        value={state.newCustomers}
        tooltipText={texts.appCustomersPage.items.newCustomers.tooltip}
        label={texts.appCustomersPage.items.newCustomers.title}
        color="lightblue"
        figure={<NewAppCustomers />}
        onClick={() => handleClick(CustomerFilter.New)}
        selected={props.selectedFilter === CustomerFilter.New}
      />
      <StatsCardSimple
        value={state.vipCustomers}
        tooltipText={texts.appCustomersPage.items.vipCustomers.tooltip}
        label={texts.appCustomersPage.items.vipCustomers.title}
        color="orange"
        figure={<VipCustom />}
        onClick={() => handleClick(CustomerFilter.Vip)}
        selected={props.selectedFilter === CustomerFilter.Vip}
      />
      <StatsCardSimple
        value={state.mostActiveCustomers}
        tooltipText={texts.appCustomersPage.items.mostActiveCustomers.tooltip}
        label={texts.appCustomersPage.items.mostActiveCustomers.title}
        color="green"
        figure={<Prospect />}
        onClick={() => handleClick(CustomerFilter.MostActive)}
        selected={props.selectedFilter === CustomerFilter.MostActive}
      />
      <StatsCardSimple
        value={state.loyalCustomers}
        tooltipText={texts.appCustomersPage.items.loyalCustomers.tooltip}
        label={texts.appCustomersPage.items.loyalCustomers.title}
        color="pink"
        figure={<LoyalCustom />}
        onClick={() => handleClick(CustomerFilter.Loyal)}
        selected={props.selectedFilter === CustomerFilter.Loyal}
      />
    </section>
  );
}
