// eslint-disable-next-line import/no-internal-modules
import flags from "country-flag-icons/modules/react/1x1";
import { useDeploymentConfig } from "../../state/deployment";
import { ValidationError } from "../widgets/ValidationError";

import "./LanguagePicker.scss";

let languages: string[];

// map https://en.wikipedia.org/wiki/ISO_639-1 to https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
const languageFlagMap = {
  en: flags.US,
  de: flags.DE,
  fr: flags.FR,
  it: flags.IT,
  da: flags.DK,
  hu: flags.HU,
  cs: flags.CZ,
  ar: flags.AE,
  ru: flags.RU,
};

function renderFlag(code: string) {
  if (code in languageFlagMap) {
    const Flag = languageFlagMap[code as keyof typeof languageFlagMap];
    return <Flag />;
  } else {
    code = code.toUpperCase();
    if (code in flags) {
      const Flag = flags[code as keyof typeof flags];
      return <Flag />;
    }
  }
  return <></>;
}

type Props = {
  language: string;
  errors: string[];
  onChange: (language: string) => void;
};

export default function LanguagePicker(props: Props) {
  languages = useDeploymentConfig().config.allowedLanguages || [];

  const className = (language: string) => {
    const classes = [];
    if (props.language === language) classes.push("selected");
    if (props.errors.includes(language)) classes.push("invalid");
    return classes.join(" ");
  };

  const renderAdditionalError = () => {
    if (props.errors.length > 0 && !props.errors.includes(props.language)) {
      return <ValidationError message="One or more language is missing." />;
    } else {
      return null;
    }
  };

  return (
    <div className="form-input language-picker">
      {languages.map((l: string) => {
        return (
          <button
            key={`languageBtn${l}`}
            onClick={() => {
              props.onChange(l);
            }}
            type="button"
            className={className(l)}
          >
            {renderFlag(l)}
            {l.toUpperCase()}
          </button>
        );
      })}
      {renderAdditionalError()}
    </div>
  );
}
