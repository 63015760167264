import { createContext } from "react";
import { PDFPageProxy } from "./pdfjs";
import { EnhancedPage } from "./shared/utils";

export interface PageContextType {
  onGetAnnotationsError?: (error: Error) => void;
  onGetAnnotationsSuccess?: () => void;
  onGetTextError?: (error: Error) => void;
  onGetTextSuccess?: () => void;
  onRenderAnnotationLayerError?: (error: Error) => void;
  onRenderAnnotationLayerSuccess?: () => void;
  onRenderStart?: (page: EnhancedPage) => void;
  onRenderError?: (error: Error) => void;
  onRenderSuccess?: (page: EnhancedPage) => void;
  page?: PDFPageProxy;
  renderInteractiveForms?: boolean;
  rotate?: 0 | 90 | 180 | 270;
  scale?: number;
  customTextRenderer?: any;
}

export default createContext<PageContextType | null>(null);
