import { reduce } from "lodash";
import { useMemo } from "react";
import { FieldError } from "react-hook-form";
import { localizeUserInterfaceText } from "../data/texts";
import { useDeploymentConfig } from "../state/deployment";
import { useCachedInstance } from "./hooks";

export type Word = {
  singular: string;
  plural: string;
};

export interface Translations {
  store: Word;
}

export type LocalizedText = ReturnType<typeof localizeUserInterfaceText>;

export function useLocalizedText(): LocalizedText {
  const { config } = useDeploymentConfig();
  const cachedTranslations = useCachedInstance(config.translations);
  return useMemo(() => {
    return localizeUserInterfaceText(cachedTranslations);
  }, [cachedTranslations]);
}

export const checkErrors = (
  languageList: string[],
  localizationErrors: FieldError | LocalizationErrors[] | undefined
) => {
  if (localizationErrors) {
    if (Array.isArray(localizationErrors)) {
      return reduce(
        localizationErrors,
        (invalidLanguages, localization, index) => {
          if (localization && (localization.languageCode || localization.text)) {
            invalidLanguages.push(languageList[index]);
          }
          return invalidLanguages;
        },
        [] as string[]
      );
    } else {
      return languageList;
    }
  }
  return [];
};

export type LocalizationErrors = {
  languageCode?: FieldError;
  text?: FieldError;
};
