import { useCallback, useEffect, useState } from "react";
import { PocAccountApi } from "../../api";
import { PageHeader } from "../../app/widgets/PageHeaders";
import { isHttpOk } from "../../services/api";
import { getApiConfig } from "../../state/configuration";

import "./QRSample.scss";

export function QRSample() {
  const [qrCode, setQRCode] = useState<string>("");

  const getQRCode = useCallback(async () => {
    const config = getApiConfig();
    const api = new PocAccountApi(config);
    const response = await api.getQRCodeSample();

    if (isHttpOk(response) && response.data) {
      setQRCode(response.data);
    }
  }, []);

  useEffect(() => {
    getQRCode();
  }, [getQRCode]);

  return (
    <div className="qr-sample page-content">
      <PageHeader title="QR Sample"></PageHeader>
      <section>
        <div className="customer-utils-card m-1 m-sm-2 px-0 px-sm-3 py-2 py-sm-4">
          <div className="container-fluid">
            {qrCode && <img src={`data:image/png;base64, ${qrCode}`} alt="Poc Activation QR Code" />}
          </div>
        </div>
      </section>
    </div>
  );
}
