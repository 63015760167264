import { ChangeEvent, useRef, useState } from "react";
import { Upload } from "../../../app/icons/Icons";

export function UploadButton(props: { disabled: boolean; onFileSelected: (file: File) => Promise<void> }) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setUploading] = useState<boolean>(false);

  const onUploadClick = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files.length > 0) {
      setUploading(true);
      const file = e.target.files![0];
      props.onFileSelected(file).finally(() => {
        setUploading(false);
      });
    }
  };

  return (
    <button
      className="secondary"
      type="button"
      onClick={() => inputRef.current?.click()}
      disabled={props.disabled || isUploading}
    >
      <Upload /> {"Upload"}
      <input
        id="file-input"
        ref={inputRef}
        type="file"
        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={onUploadClick}
      />
    </button>
  );
}
