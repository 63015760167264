import { PurchaseAction, TimeSpanRange } from "../../../../api";
import { Select } from "../../../forms/Select";
import { editorClassName, EditorProps } from "./common";
import { PurchaseCheckInput } from "./inputs/PurchaseCheckInput";
import { RateOfOccurenceInput } from "./inputs/RateOfOccurenceInput";
import { TimeSpanRangeInput } from "./inputs/TimeSpanRangeInput";

export function ProductAndPurchasesEditor(props: EditorProps) {
  const customGeneralOptions = [
    { label: "this month", value: TimeSpanRange.ThisMonth },
    { label: "last month", value: TimeSpanRange.LastMonth },
  ];

  var brands = [{ label: "All", value: "" }, ...props.brands];

  const model = props.value;
  const selectedBrand = model.productAndPurchases?.brand || "";
  const selectedKeywords = model.productAndPurchases?.descriptionKeywords || [];
  const keywordsAsString = selectedKeywords.join(" ");
  const selectedPurchaseCheck = model.productAndPurchases?.purchaseCheck || undefined;
  const selectedRateOfOccurence = model.productAndPurchases?.rateOfOccurence || undefined;
  const selectedTimerange = model.productAndPurchases?.time || undefined;
  const type = selectedPurchaseCheck?.purchaseAction || PurchaseAction.SpecificProduct;
  const hidePurchaseInputs = type !== PurchaseAction.SpecificProduct;

  return (
    <div className={editorClassName(props, "specific-purchase")}>
      <h2>{props.description}</h2>
      <PurchaseCheckInput
        prefix="Customer Purchased"
        currency={props.currency}
        value={selectedPurchaseCheck}
        error={props.error}
        onChange={(value) => {
          props.onChange({
            productAndPurchases: {
              purchaseCheck: value,
              rateOfOccurence: selectedRateOfOccurence,
              brand: selectedBrand,
              time: selectedTimerange,
              descriptionKeywords: selectedKeywords,
            },
          });
        }}
      />
      {!hidePurchaseInputs && (
        <>
          <div className="input-row single">
            <span className="prefix">Product Brand</span>
            <Select
              type="form"
              placeholder="Brand"
              errorLabel="Brand"
              required={true}
              value={selectedBrand}
              options={brands}
              error={props.error}
              onChange={(value) => {
                if (typeof value === "string")
                  props.onChange({
                    productAndPurchases: {
                      brand: value,
                      time: selectedTimerange,
                      purchaseCheck: selectedPurchaseCheck,
                      rateOfOccurence: selectedRateOfOccurence,
                      descriptionKeywords: selectedKeywords,
                    },
                  });
              }}
            />
          </div>
          <div className="input-row single">
            <span className="prefix">Product Description</span>
            <label className="form-input description">
              <input
                placeholder="(Optional) Keywords to match"
                type="text"
                value={keywordsAsString}
                onChange={(e) => {
                  const value = e.target.value;
                  props.onChange({
                    productAndPurchases: {
                      descriptionKeywords: value ? value.split(" ") : [],
                      brand: selectedBrand,
                      time: selectedTimerange,
                      purchaseCheck: selectedPurchaseCheck,
                      rateOfOccurence: selectedRateOfOccurence,
                    },
                  });
                }}
              />
            </label>
          </div>
          <RateOfOccurenceInput
            prefix="Frequency"
            value={selectedRateOfOccurence}
            error={props.error?.rateOfOccurence}
            onChange={(value) => {
              props.onChange({
                productAndPurchases: {
                  rateOfOccurence: value,
                  brand: selectedBrand,
                  time: selectedTimerange,
                  descriptionKeywords: selectedKeywords,
                  purchaseCheck: selectedPurchaseCheck,
                },
              });
            }}
          />
        </>
      )}
      <TimeSpanRangeInput
        prefix="Time period"
        rangeSemantics={false}
        customGeneralOptions={customGeneralOptions}
        includeOptionAny={true}
        includeOptionNever={false}
        includeRelativeGroup={true}
        includeFixedGroup={false}
        value={selectedTimerange}
        onChange={(value) =>
          props.onChange({
            productAndPurchases: {
              time: value,
              brand: selectedBrand,
              purchaseCheck: selectedPurchaseCheck,
              rateOfOccurence: selectedRateOfOccurence,
              descriptionKeywords: selectedKeywords,
            },
          })
        }
        error={props.error}
      />
    </div>
  );
}
