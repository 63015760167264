import { PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";
import { createStore, useStore } from "../../services/streams";
import { useAccessControl } from "../../state/authentication";
import { getApiHost } from "../../state/configuration";
import { useDeploymentConfig } from "../../state/deployment";
import {
  AddNew,
  Analytics,
  BeaconMenu,
  Beaconsmind,
  BlogPost,
  Campaigns,
  Categories,
  ChangeLog,
  Clock,
  FeaturedItems,
  Health,
  Locations,
  MenuAppCustomers,
  MenuCustomerSegments,
  MenuHeatmap,
  MenuHouse,
  MenuNotification,
  MenuSettings,
  MenuStore,
  Product,
  QRCode,
  StoreMenu,
  Upload,
  UserManagement,
} from "../icons/Icons";

import "./Sidebar.scss";

const sidebar = createStore({ isOpen: false }, "sidebar");

export function useSidebar() {
  const [state, setState] = useStore(sidebar);
  return {
    isSidebarOpen: state.isOpen,
    closeSidebar: useCallback(() => {
      setState((prev) => ({ isOpen: false }));
    }, [setState]),
    toggleSidebar: useCallback(() => {
      setState((prev) => ({ isOpen: !prev.isOpen }));
    }, [setState]),
  };
}

interface SidebarItemProps {
  image?: JSX.Element;
  text: string;
  link?: string;
  onClick?: () => void;
}

function SidebarItem(props: PropsWithChildren<SidebarItemProps>) {
  const { link, image, text, children, onClick } = props;

  const renderLink = () => {
    if (typeof link === "string") {
      if (link.startsWith("http://") || link.startsWith("https://")) {
        return (
          <a className="power-menu-link" href={link}>
            <span className="icon">{image}</span>
            <span>{text}</span>
          </a>
        );
      } else {
        return (
          <NavLink
            to={link}
            className="power-menu-link"
            isActive={(match, location) => {
              if (link === "/") {
                return location.pathname === link;
              }
              if (location.pathname === link) {
                return true;
              } else {
                return false;
              }
            }}
          >
            <span className="icon">{image}</span>
            <span>{text}</span>
          </NavLink>
        );
      }
    } else if (typeof onClick === "function") {
      return (
        <span className="power-menu-link clickable" onClick={onClick}>
          <span className="icon">{image}</span>
          <span>{text}</span>
        </span>
      );
    } else {
      return (
        <span className="power-menu-link">
          <span className="icon">{image}</span>
          <span>{text}</span>
        </span>
      );
    }
  };

  return (
    <li className={"power-menu-item"}>
      {renderLink()}
      {children}
    </li>
  );
}

export function Sidebar() {
  const [{ isOpen: isSidebarOpen }, setSidebar] = useStore(sidebar);
  const route = useRouteMatch();
  const apiHost = getApiHost();
  const { config } = useDeploymentConfig();
  const overlayRef = useRef<HTMLDivElement>(null);
  const permissions = useAccessControl();
  const showUtils =
    permissions.forceSendMessage || permissions.deleteContactsAndVisits || permissions.deleteMessageStatistics;

  useEffect(() => {
    const closeOnClick = (event: MouseEvent) => {
      if (event.target === overlayRef.current) {
        setSidebar({ isOpen: false });
      }
    };

    if (isSidebarOpen) {
      const cw = document.body.clientWidth;
      document.body.classList.add("hide-scrollbars");
      document.body.style.width = `${cw}px`;
      document.addEventListener("click", closeOnClick);
      return () => {
        document.body.classList.remove("hide-scrollbars");
        document.body.style.width = "";
        document.removeEventListener("click", closeOnClick);
      };
    }
  }, [isSidebarOpen, setSidebar]);

  const cssClass = `sidebar ${isSidebarOpen ? "open" : "closed"}`;

  const group1 = [
    "/analytics",
    "/touchpoints",
    "/sales",
    "/customers",
    "/notification",
    "/visitor-statistics",
    "/heatmap",
  ];
  const group2 = ["/locations", "/stores", "/beacons"];
  const group3 = ["/campaigns", "/messages", "/messages/new"];

  const getClassnames = (urls: string[]) => {
    if (urls.indexOf(route.path) > -1) {
      return "active-group visible";
    }
    return "active-group visible";
  };

  function decorate(url: string) {
    return url;
  }

  return (
    <>
      {isSidebarOpen ? <div ref={overlayRef} className="sidebar-overlay"></div> : null}
      <div className={cssClass}>
        <div className="logo">
          <h1>
            <Beaconsmind />
          </h1>
        </div>
        <ul>
          <SidebarItem image={<MenuHouse />} text={"Analytics"} link={decorate("/analytics")}>
            <ul className={getClassnames(group1)}>
              <SidebarItem image={<Analytics />} text={"App Touchpoints"} link={decorate("/touchpoints")} />
              {!config.disablePurchaseHistories && (
                <SidebarItem image={<Campaigns />} text={"Revenue Statistics"} link={decorate("/revenue")} />
              )}
              <SidebarItem
                image={<MenuNotification />}
                text={"Push Notification Campaigns"}
                link={decorate("/notification")}
              />
              <SidebarItem image={<MenuStore />} text={"Visitor Statistics"} link={decorate("/visitor-statistics")} />
              <SidebarItem image={<MenuHeatmap />} text={"Heatmap"} link={decorate("/heatmap")} />
              <SidebarItem
                image={<MenuAppCustomers />}
                text={config.disablePurchaseHistories ? "User Data" : "Customer Data"}
                link={decorate("/customers")}
              />
              <SidebarItem image={<Health />} text={"Health Check"} link={decorate("/health-check")} />
            </ul>
          </SidebarItem>
          <SidebarItem image={<Locations />} text={"Locations"} link={decorate("/locations")}>
            <ul className={getClassnames(group2)}>
              <SidebarItem
                image={<StoreMenu />}
                text={config.translations.store.plural}
                link={decorate(`/${config.translations.store.plural.toLowerCase()}`)}
              />
              <SidebarItem image={<BeaconMenu />} text={"Beacons"} link={decorate("/beacons")} />
            </ul>
          </SidebarItem>
          <SidebarItem image={<Campaigns />} text={"Campaigns"} link={decorate("/campaigns")}>
            <ul className={getClassnames(group3)}>
              <SidebarItem image={<MenuNotification />} text={"Messages"} link={decorate("/messages")} />
              <SidebarItem image={<AddNew />} text={"Message Editor"} link={decorate("/messages/new")} />
              <SidebarItem image={<MenuCustomerSegments />} text={"Customer Segments"} link={decorate("/segments")} />
            </ul>
          </SidebarItem>
          {/* <SidebarItem image={<MenuHelp />} text={"Help Center"} link={decorate("/help-center")} /> */}
          {/* <SidebarItem image={<MenuSettings />} text={"Settings"} link={decorate("/settings")} /> */}
          <SidebarItem image={<MenuSettings />} text={"Tools"}>
            <ul className="active-group visible">
              {config.enableChangeLog && (
                <SidebarItem image={<ChangeLog />} text={"Change Log"} link={decorate("/change-log")} />
              )}
              {(config.enablePurchasesImport || config.enableProductsImport) && (
                <SidebarItem image={<Upload />} text={"Import Data"} link={decorate("/import-data")} />
              )}
              {permissions.pocManagementAccess && (
                <SidebarItem
                  image={<UserManagement />}
                  text={"Poc User Management"}
                  link={decorate("/poc-user-management")}
                />
              )}
              {showUtils && (
                <SidebarItem
                  image={<MenuNotification />}
                  text="Customer Utilities"
                  link={decorate("/customer-utilities")}
                />
              )}
              <SidebarItem image={<BeaconMenu />} text="Contact Tracking" link={decorate("/contact-tracking")} />
              {permissions.accessHangfire && (
                <SidebarItem image={<Clock />} text="Background Jobs" link={decorate(apiHost + "/hangfire")} />
              )}
              {permissions.pocManagementAccess && (
                <SidebarItem image={<QRCode />} text="POC QR Code Sample" link={decorate("/qr-sample")} />
              )}
            </ul>
          </SidebarItem>
          {config.enableWhitelabelFeatures && (
            <SidebarItem image={<MenuNotification />} text={"WhiteLabel App"}>
              <ul className="active-group visible">
                <SidebarItem image={<Categories />} text="Product Categories" link={decorate("/product-categories")} />
                <SidebarItem image={<Product />} text="Products" link={decorate("/products")} />
                <SidebarItem image={<FeaturedItems />} text="Featured Items" link={decorate("/featured-items")} />
                <SidebarItem image={<BlogPost />} text="Blog Posts" link={decorate("/blog-posts")} />
              </ul>
            </SidebarItem>
          )}
        </ul>
      </div>
    </>
  );
}
