import { format } from "date-fns";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AppTransactionUserView, RevenueStatisticsApi } from "../../../api";
import { logErrorSilently } from "../../../services/alerts";
import { isHttpOk } from "../../../services/api";
import { toCsvValue } from "../../../services/string";
import { getApiConfig } from "../../../state/configuration";
import { useTimeLocationFilters } from "../../../state/globalFilters";
import Card from "../../cards/Card";
import { AlertContent } from "../../widgets/Alerts";
import { Avatar } from "../../widgets/Avatar";
import { PaginatedTable } from "../../widgets/PaginatedTable";

import "./AppTransactionUsersTable.scss";

export function AppTransactionUsersTable() {
  const filters = useTimeLocationFilters();
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const dataSource = useCallback(
    async (offset: number, limit: number) => {
      const api = new RevenueStatisticsApi(getApiConfig());
      const result = await api.getAppTransactionUsers({ skip: offset, limit: limit, ...filters });

      if (isHttpOk(result)) return result.data;
      else {
        logErrorSilently(result);
        return {
          count: 0,
          items: [],
        };
      }
    },
    [filters]
  );

  const title = "App Transaction Customers";
  const tableHeaders = [{ title: "NAME" }, { title: "EMAIL" }, { title: "NO. OF TRANSACTIONS" }];

  const renderRow = (item: AppTransactionUserView) => {
    var name = item.firstName ? item.firstName + " " + item.lastName : "Anonymous";
    return [
      <Link to={item.id ? `/customer/${item.id}/details` : "#"}>
        <Avatar name={name} image={item.pictureUrl} />
        <span className="name">{name}</span>
      </Link>,
      item.email,
      item.transactions,
    ];
  };

  async function downloadCsv() {
    setIsExporting(true);
    try {
      const data = await dataSource(0, 0);
      const csvHeaders = ["PictureUrl", "Name", "Email", "No. Of Transactions"].join(",");
      let rows = data.items?.map((item) =>
        [
          toCsvValue(item.pictureUrl || ""),
          toCsvValue(item.firstName ? item.firstName + " " + item.lastName : "Anonymous"),
          toCsvValue(item.email),
          item.transactions,
        ].join(",")
      );
      rows = [csvHeaders, ...(rows || [])];
      const csv = rows.join("\n");
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `app-transaction-customers-${format(Date.now(), "yy.MM.dd-HH:mm")}.csv`;
      link.click();
    } catch {
      toast.error(<AlertContent message="Unexpected error while exporting data" />);
    } finally {
      setIsExporting(false);
    }
  }

  return (
    <Card className="app-transaction-users-table">
      <PaginatedTable
        mainHeader={
          <section className="header-row">
            <header>
              <div>
                <h1>{title}</h1>
              </div>
              <button className="secondary export-data-btn" disabled={isExporting} onClick={downloadCsv}>
                Export Data
              </button>
            </header>
          </section>
        }
        tableClassName="with-image"
        minLoadingTimeMs={200}
        initialPageSize={10}
        tableDataSource={dataSource}
        tableHeaders={tableHeaders}
        tableRowFactory={renderRow}
      ></PaginatedTable>
    </Card>
  );
}
