import { MouseEventHandler } from "react";
import * as api from "../../api";
import Card from "./Card";
import { highlight } from "../widgets/HighlightedString";

import "./BlogPostCard.scss";

interface ComponentProps extends api.BlogPostView {
  highlightedText?: string;
  onEditClick?: MouseEventHandler;
  onDeleteClick?: MouseEventHandler;
}

export function BlogPostCard(props: ComponentProps) {
  const title = props.title;
  const leadText = props.leadText;
  const articleText = props.articleText;
  const imageUrl = props.imageUrl;
  const imageNode = imageUrl && <img alt="Blog post" src={imageUrl}></img>;

  return (
    <Card className="blog-post-card">
      <div className="figure">{imageNode}</div>
      <div className="description">
        <strong className="status"></strong>
        <h1>{highlight(title, props.highlightedText)}</h1>
        <h3 className="lead-text">{highlight(leadText, props.highlightedText)}</h3>
        <h4 className="main-text">{highlight(articleText, props.highlightedText)}</h4>
        <p className="author">By: {highlight(props.authorName, props.highlightedText)}, {highlight(props.authorDescription, props.highlightedText)}</p>
        <div className="update-buttons">
          <button onClick={props.onEditClick} className="edit">
            EDIT
          </button>
          <button onClick={props.onDeleteClick} className="delete">
            DELETE
          </button>
        </div>
      </div>
    </Card>
  );
}
