import { editorClassName, EditorProps } from "./common";
import { LocationInput } from "./inputs/LocationInput";
import { TimeSpanRangeInput } from "./inputs/TimeSpanRangeInput";

export function LastBeaconContactEditor(props: EditorProps) {
  const selectedLocations = props.value.lastBeaconContact?.locations || [];
  const selectedTimerange = props.value.lastBeaconContact?.time || undefined;

  return (
    <div className={editorClassName(props, "last-beacon-contact")}>
      <h2>{props.description}</h2>
      <TimeSpanRangeInput
        prefix="Last contact was"
        rangeSemantics={false}
        includeOptionAny={true}
        includeOptionNever={true}
        value={selectedTimerange}
        onChange={(value) =>
          props.onChange({
            lastBeaconContact: {
              locations: selectedLocations,
              time: value,
            },
          })
        }
        error={props.error?.time}
      />
      <LocationInput
        includeBeacons={true}
        error={props.error?.locations}
        value={selectedLocations}
        onChange={(value) => {
          props.onChange({
            lastBeaconContact: {
              locations: value,
              time: selectedTimerange,
            },
          });
        }}
      />
    </div>
  );
}
