import { AgeGroup } from "../../../../api";
import { isProvided } from "../../../../services/objects";
import { Option, OptionValueType, Select } from "../../../forms/Select";
import { editorClassName, EditorProps } from "./common";

export function AgeEditor(props: EditorProps) {
  const selectOptions: (AgeGroup & Option)[] = [
    { from: 18, to: 24, label: "18-24", value: 1 },
    { from: 25, to: 34, label: "25-34", value: 2 },
    { from: 35, to: 44, label: "35-44", value: 3 },
    { from: 45, to: 54, label: "45-54", value: 4 },
    { from: 55, to: 64, label: "55-64", value: 5 },
    { from: 65, to: undefined, label: "65+", value: 6 },
    { from: -1, to: undefined, label: "Unknown", value: 7 },
  ];

  const ageGroups = props.value.specificAge?.ageGroups || [];
  const selectValues = ageGroups
    .map((ag) => selectOptions.find((g) => g.from === ag.from))
    .filter(isProvided)
    .map((g) => g.value);

  const setAgeGroups = (values: OptionValueType[]) => {
    const groups = selectOptions.filter((ag) => values.includes(ag.value)).map((ag) => ({ from: ag.from, to: ag.to }));
    props.onChange({
      specificAge: {
        ageGroups: groups,
      },
    });
  };

  return (
    <div className={editorClassName(props, "specific-age")}>
      <h2>{props.description}</h2>
      <div className="input-row single">
        <span className="prefix">Age falls in</span>
        <Select
          type="form"
          placeholder="Select age"
          errorLabel="Age"
          isMulti={true}
          required={true}
          value={selectValues}
          options={selectOptions}
          onChange={setAgeGroups}
          error={props.error?.ageGroups}
        />
      </div>
    </div>
  );
}
