import { useCallback, useMemo, useState } from "react";
import ReactTooltip from "react-tooltip";
import { BeaconsApi, TimeframeType, TopBeaconView } from "../../api";
import { logErrorSilently } from "../../services/alerts";
import { isHttpOk } from "../../services/api";
import { formatApproximateDuration } from "../../services/format";
import { useLocalizedText } from "../../services/localization";
import { getApiConfig } from "../../state/configuration";
import { useFilterDescriptionText, useIsSingleStore, useTimeLocationFilters } from "../../state/globalFilters";
import Card from "../cards/Card";
import { Header, PaginatedTable } from "../widgets/PaginatedTable";

import "./BeaconDataTable.scss";

export enum Metric {
  MaxTime = 1,
  AverageTime = 2,
  MinimumTime = 3,
}

export type BeaconDataTableProps = {
  selectedMetric?: Metric;
};

export function BeaconDataTable(props: BeaconDataTableProps) {
  const options = [
    {
      label: "Longest Time Spent",
      value: Metric.MaxTime,
    },
    {
      label: "Average Time Spent",
      value: Metric.AverageTime,
    },
    {
      label: "Least Time Spent",
      value: Metric.MinimumTime,
    },
  ];

  const texts = useLocalizedText();
  const filters = useTimeLocationFilters();
  const filterTexts = useFilterDescriptionText();
  const timefilterText = filterTexts.period;
  const locationFilterText = !useIsSingleStore() && filterTexts.locations !== "All Stores" ? filterTexts.locations : "";

  const [headers, setHeaders] = useState<Header[]>([
    texts.beaconDataTable.items.name,
    texts.beaconDataTable.items.areaAndStore,
    texts.beaconDataTable.items.description,
    texts.beaconDataTable.items.maxVisitDurationSeconds,
    texts.beaconDataTable.items.longestTimeSpent,
  ]);

  const getMetricTexts = useCallback(
    (metric: Metric) => {
      const items = texts.beaconDataTable.items;
      switch (metric) {
        case Metric.MaxTime:
          return items.longestTimeSpent;
        case Metric.MinimumTime:
          return items.leastTimeSpent;
        default:
          return items.averageTimeSpent;
      }
    },
    [texts]
  );

  const updateLastHeader = useCallback(
    (metric: Metric) => {
      const newHeader = getMetricTexts(metric);
      setHeaders((prev) => [...prev.slice(0, -1), newHeader]);
    },
    [getMetricTexts]
  );

  const selectedMetric = useMemo(() => {
    if (props.selectedMetric) updateLastHeader(props.selectedMetric);
    return props.selectedMetric;
  }, [updateLastHeader, props.selectedMetric]);

  const defaultValue = selectedMetric ?? options[0].value;
  const [metric, setMetric] = useState(defaultValue);

  const loadData = useCallback(
    async (skip: number, limit: number) => {
      const config = getApiConfig();
      const api = new BeaconsApi(config);
      const payLoad = {
        skip,
        size: limit,
        ...filters,
      };
      function getData() {
        switch (metric) {
          case Metric.MaxTime:
            return api.getByMaximumTimeSpent(payLoad);
          case Metric.MinimumTime:
            return api.getByMinimumTimeSpent(payLoad);
          default:
            return api.getByAverageTimeSpent(payLoad);
        }
      }
      const result = await getData();

      if (isHttpOk(result)) {
        window.setTimeout(ReactTooltip.rebuild, 500);
        return result.data;
      } else {
        logErrorSilently(result);
        return {
          count: 0,
          items: [],
        };
      }
    },
    [metric, filters]
  );

  const renderRow = (item: TopBeaconView) => {
    return [
      item.name,
      <span>
        {item.areaName || ""}
        <br />
        {item.storeName}
      </span>,
      <span data-for="appTooltip" data-tip={item.description || ""}>
        {item.description || ""}
      </span>,
      `${item.maxVisitDurationSeconds}s`,
      formatApproximateDuration(item.value),
    ];
  };

  const changeMetric = (stringValue: string) => {
    const value = parseInt(stringValue, 10);
    if (!isNaN(value)) {
      const metric = value as Metric;
      setMetric(metric);
      updateLastHeader(metric);
    }
  };

  const metricDropdown = (
    <select value={metric} onChange={(e) => changeMetric(e.target.value)}>
      {options.map((option, i) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );

  const title = `Top Beacons`;
  var description = "";
  if (filters.rangeType !== TimeframeType.AllTime) {
    description = timefilterText;
  }
  if (locationFilterText) {
    if (description) description += " - ";
    description += locationFilterText;
  }

  return (
    <Card className="beacon-data-table">
      <PaginatedTable
        mainHeader={
          <section>
            <header>
              <div>
                <h1>{title}</h1>
                <p className="description">{description}</p>
              </div>
              {metricDropdown}
            </header>
          </section>
        }
        initialPageSize={10}
        minLoadingTimeMs={200}
        tableHeaders={headers}
        tableDataSource={loadData}
        tableRowFactory={renderRow}
      />
    </Card>
  );
}
