import { useCallback } from "react";
import ReactTooltip from "react-tooltip";
import { RevenueStatisticsApi } from "../../api";
import { logErrorSilently } from "../../services/alerts";
import { isHttpOk } from "../../services/api";
import { formatDecimal, formatPercentage, useMoneyFormatter } from "../../services/format";
import { useLocalizedText } from "../../services/localization";
import { getTooltip } from "../../services/tooltip";
import { getApiConfig } from "../../state/configuration";
import { useTimeLocationFilters } from "../../state/globalFilters";
import Card from "../cards/Card";
import { PaginatedTable } from "../widgets/PaginatedTable";

import "./AppSalesByCustomerType.scss";

export function AppSalesByCustomerType() {
  const texts = useLocalizedText();
  const filters = useTimeLocationFilters();
  const { formatMoney } = useMoneyFormatter();
  const dataSource = useCallback(async () => {
    try {
      const api = new RevenueStatisticsApi(getApiConfig());
      const result = await api.getAppSalesByCustomerType(filters);
      if (isHttpOk(result)) {
        // we have tooltips in the table itself
        window.setTimeout(() => {
          ReactTooltip.rebuild();
        }, 200);

        return {
          count: 2,
          items: [
            {
              tooltip: texts.appSalesByCustomerType.newCustomerTooltip,
              ...result.data.newCustomers,
            },
            {
              tooltip: texts.appSalesByCustomerType.returningCustomerTooltip,
              ...result.data.returningCustomers,
            },
          ],
        };
      } else {
        logErrorSilently(result);
      }
    } catch (err) {
      logErrorSilently(err);
    }
    return {
      count: 0,
      items: [],
    };
  }, [filters, texts]);

  const headers = [
    {
      className: "customer-type",
      ...texts.appSalesByCustomerType.items.customerType,
    },
    {
      className: "revenue",
      ...texts.appSalesByCustomerType.items.revenue,
    },
    {
      className: "transactions",
      ...texts.appSalesByCustomerType.items.transactions,
    },
    {
      className: "avg-transaction",
      ...texts.appSalesByCustomerType.items.avgTransactionValue,
    },
    {
      className: "avg-quantity",
      ...texts.appSalesByCustomerType.items.averageQuantity,
    },
    {
      className: "store-visits",
      ...texts.appSalesByCustomerType.items.storeVisits,
    },
    {
      className: "sales-rate",
      ...texts.appSalesByCustomerType.items.salesConversionRate,
    },
  ];

  return (
    <Card className="sales-table">
      <PaginatedTable
        mainHeader={texts.appSalesByCustomerType.title}
        tableDataSource={dataSource}
        minLoadingTimeMs={200}
        tableHeaders={headers}
        tableRowFactory={(item) => {
          return [
            <div data-for="appTooltip" data-tip={getTooltip(item.tooltip)}>
              {item.tooltip.title}
            </div>,
            <span>{formatMoney(item?.revenue)}</span>,
            <span>{item?.transactionCount}</span>,
            <span>{formatMoney(item?.avgTransactionValue)}</span>,
            <span>{formatDecimal(item?.avgTransactionQuantity)}</span>,
            <span>{item?.storeVisitsCount}</span>,
            <span>{formatPercentage(item?.salesConversionRate)}</span>,
          ];
        }}
      />
    </Card>
  );
}
