import { useEffect } from "react";
import { AnalyticsApi, VisitorStatisticsView } from "../../../api";
import { isHttpOk } from "../../../services/api";
import { approximateDuration, formatPercentage } from "../../../services/format";
import { useStateEx } from "../../../services/hooks";
import { useLocalizedText } from "../../../services/localization";
import { getTooltip } from "../../../services/tooltip";
import { getApiConfig } from "../../../state/configuration";
import { useDeploymentConfig } from "../../../state/deployment";
import { useTimeLocationFilters } from "../../../state/globalFilters";
import { AvgVisitDuration, BounceRate, NewVisitors, ReturningVisitors } from "../../icons/Icons";
import Legend from "../../widgets/Legend";
import { StoreLayoutHeatmap } from "./StoreLayoutHeatmap";
import { WorldHeatmap } from "./WorldHeatmap";

import "./Heatmap.scss";

export default function Heatmap() {
  const texts = useLocalizedText();
  const deployment = useDeploymentConfig();
  const filters = useTimeLocationFilters();

  const { state, mergeState } = useStateEx<{
    isLoading: boolean;
    visitors: VisitorStatisticsView;
  }>({
    isLoading: true,
    visitors: {
      averageVisitDurationSeconds: 0,
      bounceRate: 0,
      newVisitors: 0,
      returningVisitors: 0,
    },
  });

  useEffect(() => {
    const apiConfig = getApiConfig();
    const analyticsApi = new AnalyticsApi(apiConfig);
    analyticsApi.getVisitorStatistics(filters).then((result) => {
      if (isHttpOk(result)) {
        mergeState({
          isLoading: false,
          visitors: result.data,
        });
      } else {
        mergeState({
          isLoading: false,
        });
      }
    });
  }, [filters, mergeState]);

  const oneStoreSelected = filters.stores?.length === 1;

  const renderHeatmap = () => {
    if (oneStoreSelected) {
      return <StoreLayoutHeatmap storeId={filters.stores![0]} />;
    } else {
      return <WorldHeatmap markerSize={0.05} />;
    }
  };

  const [avgVisit, avgVisitUnit] = approximateDuration(state.visitors.averageVisitDurationSeconds);

  const legendList = oneStoreSelected ? texts.heatmap.beaconLegend : texts.heatmap.storeLegend;

  const newVisitors = state.visitors.newVisitors > 0 ? state.visitors.newVisitors : "N/A";
  const returningVisitors = state.visitors.returningVisitors > 0 ? state.visitors.returningVisitors : "N/A";
  const avgVisitText = avgVisit > 0 ? avgVisit.toFixed(0) + " " + avgVisitUnit : "N/A";
  const bounceRate = state.visitors.bounceRate > 0 ? formatPercentage(state.visitors.bounceRate) : "N/A";

  return (
    <div className="heatmap">
      <div className="title-and-map">
        <h1 className="title">{texts.heatmap.title}</h1>
        {renderHeatmap()}
      </div>

      <div className="legend-and-stats">
        <Legend legendList={legendList}></Legend>
        <div className="stats">
          <div>
            <NewVisitors />
            <strong>{newVisitors}</strong>
            <span data-for="appTooltip" data-tip={getTooltip(texts.heatmap.items.newVisitors)}>
              {texts.heatmap.items.newVisitors.title}
            </span>
          </div>
          <div>
            <ReturningVisitors />
            <strong>{returningVisitors}</strong>
            <span data-for="appTooltip" data-tip={getTooltip(texts.heatmap.items.returningVisitors)}>
              {texts.heatmap.items.returningVisitors.title}
            </span>
          </div>
          <div>
            <AvgVisitDuration />
            <strong>{avgVisitText}</strong>
            <span data-for="appTooltip" data-tip={getTooltip(texts.heatmap.items.avgVisitDuration)}>
              {texts.heatmap.items.avgVisitDuration.title}
            </span>
          </div>
          {!deployment.config.disablePurchaseHistories && (
            <div>
              <BounceRate />
              <strong>{bounceRate}</strong>
              <span data-for="appTooltip" data-tip={getTooltip(texts.heatmap.items.bounceRate)}>
                {texts.heatmap.items.bounceRate.title}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
