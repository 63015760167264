import { useCallback, useEffect } from "react";
import { toast as alert } from "react-toastify";
import { BeaconVisitCountView, StoreLayoutModel, StoresApi } from "../../api";
import { ServerUnavailable, ValidationMessage } from "../../app/widgets/Alerts";
import Loading from "../../app/widgets/Loading";
import { isHttpOk } from "../../services/api";
import { useStateEx } from "../../services/hooks";
import { imageExists } from "../../services/objects";
import { getApiConfig } from "../../state/configuration";
import { useTimeLocationFilters } from "../../state/globalFilters";
import { FloorplanOverview } from "./editor/FloorplanOverview";

type LayoutEditorProps = {
  storeId: number;
};

async function validateLayout(model: StoreLayoutModel) {
  const images = model.floors?.map((f) => imageExists(f.imageUrl));
  if (images) {
    const imageOk = await Promise.all(images);
    imageOk.forEach((ok, index) => {
      if (!ok) model.floors![index].imageUrl = null;
    });
  }
  return model;
}

export default function LayoutEditor(props: LayoutEditorProps) {
  const filters = useTimeLocationFilters();
  const { state, mergeState } = useStateEx({
    loading: true,
    allBeacons: [] as BeaconVisitCountView[],
    layout: null as StoreLayoutModel | null,
  });

  const saveLayout = async (model: StoreLayoutModel) => {
    const config = getApiConfig();
    const storesApi = new StoresApi(config);
    try {
      const response = await storesApi.updateLayout({
        id: props.storeId,
        storeLayoutModel: model,
      });
      if (isHttpOk(response) && response.data.data) {
        mergeState({
          layout: response.data.data,
        });
      } else {
        alert.error(<ValidationMessage message="Unexpected error ocurred. Please try again." />);
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }
  };

  const loadHeatmap = useCallback(async () => {
    mergeState({ loading: true });
    const config = getApiConfig();
    const storesApi = new StoresApi(config);
    try {
      const beaconResponse = storesApi.getStoreBeaconVisits({
        id: props.storeId,
        ...filters,
      });
      const layoutResponse = storesApi.getLayout({
        id: props.storeId,
      });

      const [beacons, layout] = await Promise.all([beaconResponse, layoutResponse]);

      if (isHttpOk(layout) && isHttpOk(beacons) && layout.data && beacons.data) {
        mergeState({
          allBeacons: beacons.data || [],
          layout: await validateLayout(layout.data),
        });
      } else {
        alert.error(<ValidationMessage message="Unable to find store!" />);
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    } finally {
      mergeState({ loading: false });
    }
  }, [props.storeId, mergeState, filters]);

  useEffect(() => {
    loadHeatmap();
  }, [loadHeatmap]);

  if (state.loading) {
    return <Loading />;
  } else if (state.layout) {
    return <FloorplanOverview model={state.layout} allBeacons={state.allBeacons} onChange={saveLayout} />;
  } else {
    return null;
  }
}
