import "./LabelCampaign.scss";

type Props = {
  id: number;
  label?: string | null;
  messageTitle?: string | null;
  campaignTitle?: string | null;
  convertingOfferLabel?: string | null;
};

export function LabelCampaign(props: Props) {
  const label = `[${props.id}] ${props.label || props.messageTitle || ""}`;
  return (
    <div className="label-and-campaign">
      <div className="label">{label}</div>
      <div className="campaign">{`${props.campaignTitle}`}</div>
      {props.convertingOfferLabel && <div className="converting-offer">Converted by: {props.convertingOfferLabel}</div>}
    </div>
  );
}
