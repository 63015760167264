import { Word } from "./localization";

export function initialOrEmpty(name?: string | null) {
  if (name && name.length > 0) return name[0].toUpperCase();
  return "";
}

export function renderAmount(amount: number, word: Word) {
  if (amount === 1) {
    return `${amount} ${word.singular.toLowerCase()}`;
  } else {
    return `${amount} ${word.plural.toLowerCase()}`;
  }
}

export function extractInitials(name: string): string {
  if (!name || name.length === 0) {
    return "-";
  } else {
    const parts = name
      .trim()
      .split(/\s+/)
      .filter((p) => p.length > 0);
    if (parts.length === 0) {
      return "-";
    } else if (parts.length === 1) {
      return initialOrEmpty(parts[0]);
    } else {
      return initialOrEmpty(parts[0]) + initialOrEmpty(parts[1]);
    }
  }
}

export function highlightMatches(text: string, search: string[]) {
  if (text.length === 0) return [];
  const deconstructed = [{ highlighted: false, text: text }];
  if (search.length === 0) return deconstructed;

  search = search.filter((s) => s.length > 0).sort((a, b) => b.length - a.length);

  function push(text: string, highlighted: boolean) {
    if (text.length > 0) {
      if (
        deconstructed.length > 1 &&
        deconstructed[deconstructed.length - 2].highlighted === highlighted &&
        deconstructed[deconstructed.length - 1].text === " "
      ) {
        deconstructed.pop();
        deconstructed[deconstructed.length - 1].text += " " + text;
      } else if (deconstructed.length > 0 && deconstructed[deconstructed.length - 1].highlighted === highlighted) {
        deconstructed[deconstructed.length - 1].text += text;
      } else {
        deconstructed.push({ highlighted, text });
      }
    }
  }

  for (const token of search) {
    for (const part of deconstructed.splice(0, deconstructed.length)) {
      if (part.text.length > 0) {
        if (!part.highlighted) {
          const matches = [...part.text.matchAll(new RegExp(token, "gi"))];
          const highlights = matches.map((m, i, all) => {
            const prev = all[i - 1];
            const firstPart = part.text.substring(prev ? prev.index! + prev[0].length : 0, m.index);
            const secondPart = m.toString();
            return [firstPart, secondPart];
          });
          const lastMatch = matches[matches.length - 1];
          const lastIndex = lastMatch ? (lastMatch.index || 0) + lastMatch[0].length : 0;
          highlights.push([part.text.substring(lastIndex)]);
          highlights.flatMap((e) => e).forEach((v, i) => push(v, i % 2 === 1));
        } else {
          push(part.text, true);
        }
      }
    }
  }
  return deconstructed;
}

export const addSpaces = (h: string) => {
  return h.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3");
};

export const capitalize = (h: string) => h.replace(/^./, (firstChar) => firstChar.toUpperCase());

export const toCsvValue = (val?: string | null) => (val?.includes(",") ? `"${val.replace('"', '""')}"` : val || "");
