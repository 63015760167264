import React, { useCallback, useRef, useState } from "react";
import { useStateEx } from "../services/hooks";
import useBreakpoint from "../services/useBreakpoint";
import { Modal, useModal } from "../app/modals/Modal";
import { PaginatedCards, PaginatedCardsApi } from "../app/widgets/PaginatedCards";
import { SegmentGroupModel, SegmentGroupsApi, SegmentGroupView } from "../api";
import { getApiConfig } from "../state/configuration";
import { useGlobalSearch } from "../state/globalSearch";
import { AxiosResponse } from "axios";
import { ServiceResult } from "../services/types";
import { isHttpOk } from "../services/api";
import { toast as alert } from "react-toastify";
import { AlertContent, ServerUnavailable } from "../app/widgets/Alerts";
import { Result } from "../app/modals/utils";
import { PageHeader } from "../app/widgets/PageHeaders";
import { CustomerSegmentCard } from "../app/cards/CustomerSegmentCard";
import Card from "../app/cards/Card";
import Loading from "../app/widgets/Loading";
import EditCustomerSegment from "../app/modals/EditCustomerSegment";
import { confirmDialog } from "../services/alerts";

import "./CustomerSegments.scss";

interface State {
  segmentId: number | null;
  model: SegmentGroupModel | null;
  totalCount: number;
}

const breakpoints = { "columns-1": 0, "columns-2": 700, "columns-3": 1200 };

export function CustomerSegments(props: {}) {
  const { search } = useGlobalSearch();
  const { openModal, closeModal } = useModal();
  const breakpoint = useBreakpoint(breakpoints).breakpoint;
  const { state, mergeState } = useStateEx<State>({
    totalCount: 0,
    model: null,
    segmentId: null,
  });
  const pageApi = useRef<PaginatedCardsApi>(null);
  const pageSize = breakpoint === "columns-3" ? 9 : breakpoint === "columns-2" ? 6 : 4;

  const [formModified, setFormModified] = useState<boolean>(false);
  const changed = () => setFormModified(true);

  const loadData = useCallback(
    (offset: number, limit: number) => {
      const config = getApiConfig();
      const api = new SegmentGroupsApi(config);
      return api.getAll({
        skip: offset,
        limit: limit,
        search: search,
      });
    },
    [search]
  );

  const editSegment = async (id: number) => {
    try {
      const config = getApiConfig();
      const api = new SegmentGroupsApi(config);
      const response = await api.getOne({
        segmentGroupId: id,
      });
      if (isHttpOk(response)) {
        mergeState({ model: response.data.data, segmentId: id });
        openModal();
      } else if (response.status === 404) {
        alert.error(<AlertContent message="Customer segment not found." />);
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }
  };

  const newSegment = () => {
    mergeState({
      model: {
        id: 0,
        segments: [
          {
            size: 50,
            name: "Group A",
          },
          {
            size: 50,
            name: "Group B",
          },
        ],
      },
      segmentId: null,
    });
    openModal();
  };

  const saveSegment = async (model: SegmentGroupModel) => {
    let message: string;
    let result: AxiosResponse<ServiceResult<SegmentGroupModel>>;
    const config = getApiConfig();
    const api = new SegmentGroupsApi(config);

    try {
      if (state.segmentId === null) {
        message = `Customer segment '${model.title}' created.`;
        result = await api.create({
          segmentGroupModel: model,
        });
      } else {
        message = `Customer segment '${model.title}' saved.`;
        result = await api.update({
          segmentGroupId: state.segmentId,
          segmentGroupModel: model,
        });
      }

      if (isHttpOk(result)) {
        mergeState({ model: null });
        closeModal();
        setFormModified(false);
        alert.success(<AlertContent message={message} />);
        if (pageApi.current) pageApi.current.refreshPage();
        return Result.Ok;
      } else {
        return Result.Invalid(result);
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }

    return Result.Invalid();
  };

  const handleDelete = async (id: number, title?: string | null) => {
    const onConfirm = () => deleteSegment(id, title);
    confirmDialog("Delete this segment?", onConfirm);
  };

  const deleteSegment = async (id: number, title?: string | null) => {
    try {
      const config = getApiConfig();
      const api = new SegmentGroupsApi(config);
      const result = await api._delete({
        segmentGroupId: id,
      });
      if (isHttpOk(result)) {
        const message = `Customer segment '${title}' deleted.`;
        mergeState({ model: null });
        closeModal();
        alert.success(<AlertContent message={message} />);
        if (pageApi.current) pageApi.current.refreshPage();
        return Result.Ok;
      } else {
        return Result.Invalid(result);
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }

    return Result.Invalid();
  };

  const handleCancel = () => {
    if (formModified) confirmDialog("Form has unsaved changes. Discard all?", closeModalAndReset);
    else closeModalAndReset();
  };

  const closeModalAndReset = () => {
    setFormModified(false);
    mergeState({ model: null, segmentId: null });
    closeModal();
  };

  const loadingCardFactory = (index: number) => {
    return (
      <Card className="customer-segment-card loading" key={index}>
        <Loading />
      </Card>
    );
  };

  const cardFactory = (item: SegmentGroupView, index: number) => {
    return (
      <CustomerSegmentCard
        key={item.id}
        model={item}
        onClickEdit={() => editSegment(item.id)}
        onClickDelete={() => handleDelete(item.id, item.title)}
        highlightText={search}
      />
    );
  };

  return (
    <div className={`page-content segments ` + breakpoint}>
      <Modal onRequestClose={handleCancel}>
        <EditCustomerSegment
          isNew={state.segmentId === null}
          initial={state.model}
          onSave={saveSegment}
          onCancel={handleCancel}
          onChange={changed}
        />
      </Modal>
      <PageHeader title="Customer Segments" subTitle={`Found ${state.totalCount} customer segments`}>
        <div className="toolbar">
          <button onClick={newSegment} type="button" className="new">
            + New Customer Segment
          </button>
        </div>
      </PageHeader>
      <PaginatedCards
        apiRef={pageApi}
        minLoadingTimeMs={200}
        hideAddNew={true}
        pageSize={pageSize}
        loadedCardFactory={cardFactory}
        loadingCardFactory={loadingCardFactory}
        dataSource={loadData}
        onTotalCountChange={(count) => mergeState({ totalCount: count })}
      />
    </div>
  );
}
