/* tslint:disable */
/* eslint-disable */
/**
 * Suite Beaconsmind API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AgeGroup
 */
export interface AgeGroup {
    /**
     * 
     * @type {number}
     * @memberof AgeGroup
     */
    from?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AgeGroup
     */
    to?: number | null;
}
/**
 * 
 * @export
 * @interface AgeGroupView
 */
export interface AgeGroupView {
    /**
     * 
     * @type {number}
     * @memberof AgeGroupView
     */
    yearsFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AgeGroupView
     */
    yearsTo?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AgeGroupView
     */
    women: number;
    /**
     * 
     * @type {number}
     * @memberof AgeGroupView
     */
    men: number;
    /**
     * 
     * @type {number}
     * @memberof AgeGroupView
     */
    other: number;
}
/**
 * 
 * @export
 * @interface AppConfigurationResponse
 */
export interface AppConfigurationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AppConfigurationResponse
     */
    loggingEnabled: boolean;
    /**
     * 
     * @type {LoggingLevel}
     * @memberof AppConfigurationResponse
     */
    loggingLevel: LoggingLevel;
    /**
     * 
     * @type {string}
     * @memberof AppConfigurationResponse
     */
    bugFenderKey?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AppConfigurationResponse
     */
    metricsEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof AppConfigurationResponse
     */
    metricsIntervalSeconds?: number | null;
    /**
     * 
     * @type {CurrencyView}
     * @memberof AppConfigurationResponse
     */
    defaultCurrency?: CurrencyView | null;
    /**
     * 
     * @type {string}
     * @memberof AppConfigurationResponse
     */
    pocPublicKey?: string | null;
}
/**
 * 
 * @export
 * @interface AppDownloadUserView
 */
export interface AppDownloadUserView {
    /**
     * 
     * @type {string}
     * @memberof AppDownloadUserView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppDownloadUserView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppDownloadUserView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppDownloadUserView
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppDownloadUserView
     */
    platform?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppDownloadUserView
     */
    email?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AppDownloadUserView
     */
    downloadDate: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface AppDownloadUserViewDataPage
 */
export interface AppDownloadUserViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof AppDownloadUserViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AppDownloadUserViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<AppDownloadUserView>}
     * @memberof AppDownloadUserViewDataPage
     */
    items?: Array<AppDownloadUserView> | null;
}
/**
 * 
 * @export
 * @interface AppInteractionUpliftStatistics
 */
export interface AppInteractionUpliftStatistics {
    /**
     * 
     * @type {number}
     * @memberof AppInteractionUpliftStatistics
     */
    interactionUplift: number;
    /**
     * 
     * @type {number}
     * @memberof AppInteractionUpliftStatistics
     */
    interactionRate: number;
}
/**
 * 
 * @export
 * @interface AppInteractionUserView
 */
export interface AppInteractionUserView {
    /**
     * 
     * @type {string}
     * @memberof AppInteractionUserView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInteractionUserView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInteractionUserView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInteractionUserView
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInteractionUserView
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AppInteractionUserView
     */
    nmOfInteractions: number;
}
/**
 * 
 * @export
 * @interface AppInteractionUserViewDataPage
 */
export interface AppInteractionUserViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof AppInteractionUserViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AppInteractionUserViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<AppInteractionUserView>}
     * @memberof AppInteractionUserViewDataPage
     */
    items?: Array<AppInteractionUserView> | null;
}
/**
 * Values represent:     1 (All)     2 (Interacted)     3 (NoInteractions)
 * @export
 * @enum {string}
 */
export enum AppInteractionUsersFilter {
    /**
    * All
    */
    All = 1,
    /**
    * Interacted
    */
    Interacted = 2,
    /**
    * NoInteractions
    */
    NoInteractions = 3
}

/**
 * 
 * @export
 * @interface AppOpenUserView
 */
export interface AppOpenUserView {
    /**
     * 
     * @type {string}
     * @memberof AppOpenUserView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppOpenUserView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppOpenUserView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppOpenUserView
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppOpenUserView
     */
    appVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppOpenUserView
     */
    email?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AppOpenUserView
     */
    openDate: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface AppOpenUserViewDataPage
 */
export interface AppOpenUserViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof AppOpenUserViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AppOpenUserViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<AppOpenUserView>}
     * @memberof AppOpenUserViewDataPage
     */
    items?: Array<AppOpenUserView> | null;
}
/**
 * 
 * @export
 * @interface AppRegistrationUserView
 */
export interface AppRegistrationUserView {
    /**
     * 
     * @type {string}
     * @memberof AppRegistrationUserView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppRegistrationUserView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppRegistrationUserView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppRegistrationUserView
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppRegistrationUserView
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppRegistrationUserView
     */
    platform?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AppRegistrationUserView
     */
    registrationDate: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface AppRegistrationUserViewDataPage
 */
export interface AppRegistrationUserViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof AppRegistrationUserViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AppRegistrationUserViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<AppRegistrationUserView>}
     * @memberof AppRegistrationUserViewDataPage
     */
    items?: Array<AppRegistrationUserView> | null;
}
/**
 * 
 * @export
 * @interface AppSalesByCustomerTypeView
 */
export interface AppSalesByCustomerTypeView {
    /**
     * 
     * @type {CustomerStatistics}
     * @memberof AppSalesByCustomerTypeView
     */
    newCustomers?: CustomerStatistics | null;
    /**
     * 
     * @type {CustomerStatistics}
     * @memberof AppSalesByCustomerTypeView
     */
    returningCustomers?: CustomerStatistics | null;
}
/**
 * 
 * @export
 * @interface AppTransactionUserView
 */
export interface AppTransactionUserView {
    /**
     * 
     * @type {string}
     * @memberof AppTransactionUserView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppTransactionUserView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppTransactionUserView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppTransactionUserView
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppTransactionUserView
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AppTransactionUserView
     */
    transactions: number;
}
/**
 * 
 * @export
 * @interface AppTransactionUserViewDataPage
 */
export interface AppTransactionUserViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof AppTransactionUserViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AppTransactionUserViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<AppTransactionUserView>}
     * @memberof AppTransactionUserViewDataPage
     */
    items?: Array<AppTransactionUserView> | null;
}
/**
 * 
 * @export
 * @interface AppUpliftStatistics
 */
export interface AppUpliftStatistics {
    /**
     * 
     * @type {number}
     * @memberof AppUpliftStatistics
     */
    conversion: number;
    /**
     * 
     * @type {number}
     * @memberof AppUpliftStatistics
     */
    avgTransactionValue: number;
    /**
     * 
     * @type {number}
     * @memberof AppUpliftStatistics
     */
    storeVisits: number;
}
/**
 * 
 * @export
 * @interface AreaView
 */
export interface AreaView {
    /**
     * 
     * @type {number}
     * @memberof AreaView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AreaView
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface AvgTransactionQuantityUserView
 */
export interface AvgTransactionQuantityUserView {
    /**
     * 
     * @type {string}
     * @memberof AvgTransactionQuantityUserView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AvgTransactionQuantityUserView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AvgTransactionQuantityUserView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AvgTransactionQuantityUserView
     */
    lastName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AvgTransactionQuantityUserView
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof AvgTransactionQuantityUserView
     */
    transactions: number;
    /**
     * 
     * @type {number}
     * @memberof AvgTransactionQuantityUserView
     */
    avgTransactionQuantity: number;
}
/**
 * 
 * @export
 * @interface AvgTransactionQuantityUserViewDataPage
 */
export interface AvgTransactionQuantityUserViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof AvgTransactionQuantityUserViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AvgTransactionQuantityUserViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<AvgTransactionQuantityUserView>}
     * @memberof AvgTransactionQuantityUserViewDataPage
     */
    items?: Array<AvgTransactionQuantityUserView> | null;
}
/**
 * 
 * @export
 * @interface AvgTransactionValueUserView
 */
export interface AvgTransactionValueUserView {
    /**
     * 
     * @type {string}
     * @memberof AvgTransactionValueUserView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AvgTransactionValueUserView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AvgTransactionValueUserView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AvgTransactionValueUserView
     */
    lastName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AvgTransactionValueUserView
     */
    revenue: number;
    /**
     * 
     * @type {number}
     * @memberof AvgTransactionValueUserView
     */
    transactions: number;
    /**
     * 
     * @type {number}
     * @memberof AvgTransactionValueUserView
     */
    avgTransactionValue: number;
}
/**
 * 
 * @export
 * @interface AvgTransactionValueUserViewDataPage
 */
export interface AvgTransactionValueUserViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof AvgTransactionValueUserViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AvgTransactionValueUserViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<AvgTransactionValueUserView>}
     * @memberof AvgTransactionValueUserViewDataPage
     */
    items?: Array<AvgTransactionValueUserView> | null;
}
/**
 * 
 * @export
 * @interface BeaconLookupView
 */
export interface BeaconLookupView {
    /**
     * 
     * @type {string}
     * @memberof BeaconLookupView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconLookupView
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconLookupView
     */
    area?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconLookupView
     */
    store?: string | null;
}
/**
 * 
 * @export
 * @interface BeaconModel
 */
export interface BeaconModel {
    /**
     * 
     * @type {string}
     * @memberof BeaconModel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BeaconModel
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof BeaconModel
     */
    major: string;
    /**
     * 
     * @type {string}
     * @memberof BeaconModel
     */
    minor: string;
    /**
     * 
     * @type {string}
     * @memberof BeaconModel
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeaconModel
     */
    maxVisitDurationSeconds: number;
    /**
     * 
     * @type {LabelValue}
     * @memberof BeaconModel
     */
    area?: LabelValue | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconModel
     */
    beaconType: string;
    /**
     * 
     * @type {boolean}
     * @memberof BeaconModel
     */
    cashbox?: boolean | null;
    /**
     * 
     * @type {LabelValue}
     * @memberof BeaconModel
     */
    store: LabelValue;
}
/**
 * 
 * @export
 * @interface BeaconModelServiceResult
 */
export interface BeaconModelServiceResult {
    /**
     * 
     * @type {string}
     * @memberof BeaconModelServiceResult
     */
    message?: string | null;
    /**
     * 
     * @type {ResultStatus}
     * @memberof BeaconModelServiceResult
     */
    status: ResultStatus;
    /**
     * 
     * @type {boolean}
     * @memberof BeaconModelServiceResult
     */
    success: boolean;
    /**
     * 
     * @type {BeaconModel}
     * @memberof BeaconModelServiceResult
     */
    data?: BeaconModel | null;
}
/**
 * 
 * @export
 * @interface BeaconReference
 */
export interface BeaconReference {
    /**
     * 
     * @type {string}
     * @memberof BeaconReference
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconReference
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface BeaconView
 */
export interface BeaconView {
    /**
     * 
     * @type {string}
     * @memberof BeaconView
     */
    id?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeaconView
     */
    storeId: number;
    /**
     * 
     * @type {string}
     * @memberof BeaconView
     */
    storeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconView
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconView
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconView
     */
    uuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconView
     */
    major?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconView
     */
    minor?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof BeaconView
     */
    lastContact?: IsoDateTimeString | null;
    /**
     * 
     * @type {number}
     * @memberof BeaconView
     */
    maxVisitDurationSeconds: number;
    /**
     * 
     * @type {string}
     * @memberof BeaconView
     */
    areaName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeaconView
     */
    visitsLast7Days: number;
    /**
     * 
     * @type {Strength}
     * @memberof BeaconView
     */
    strength: Strength;
}
/**
 * 
 * @export
 * @interface BeaconViewDataPage
 */
export interface BeaconViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof BeaconViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeaconViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<BeaconView>}
     * @memberof BeaconViewDataPage
     */
    items?: Array<BeaconView> | null;
}
/**
 * 
 * @export
 * @interface BeaconVisitCountView
 */
export interface BeaconVisitCountView {
    /**
     * 
     * @type {string}
     * @memberof BeaconVisitCountView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconVisitCountView
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeaconVisitCountView
     */
    nmVisits: number;
    /**
     * 
     * @type {string}
     * @memberof BeaconVisitCountView
     */
    areaName?: string | null;
}
/**
 * 
 * @export
 * @interface BeaconVisitView
 */
export interface BeaconVisitView {
    /**
     * 
     * @type {number}
     * @memberof BeaconVisitView
     */
    storeId: number;
    /**
     * 
     * @type {string}
     * @memberof BeaconVisitView
     */
    userId?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof BeaconVisitView
     */
    utcStart: IsoDateTimeString;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof BeaconVisitView
     */
    utcEnd?: IsoDateTimeString | null;
    /**
     * 
     * @type {number}
     * @memberof BeaconVisitView
     */
    durationSeconds?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconVisitView
     */
    beaconId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconVisitView
     */
    beaconName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconVisitView
     */
    beaconUuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconVisitView
     */
    beaconMajor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeaconVisitView
     */
    beaconMinor?: string | null;
}
/**
 * 
 * @export
 * @interface BeaconVisitViewDataPage
 */
export interface BeaconVisitViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof BeaconVisitViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeaconVisitViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<BeaconVisitView>}
     * @memberof BeaconVisitViewDataPage
     */
    items?: Array<BeaconVisitView> | null;
}
/**
 * 
 * @export
 * @interface BeaconVisitsStatistic
 */
export interface BeaconVisitsStatistic {
    /**
     * 
     * @type {string}
     * @memberof BeaconVisitsStatistic
     */
    durationTick?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeaconVisitsStatistic
     */
    nmOfVisits: number;
}
/**
 * 
 * @export
 * @interface BeaconVisitsStatisticsView
 */
export interface BeaconVisitsStatisticsView {
    /**
     * 
     * @type {Array<BeaconVisitsStatistic>}
     * @memberof BeaconVisitsStatisticsView
     */
    beaconVisitsStatistics?: Array<BeaconVisitsStatistic> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BeaconVisitsStatisticsView
     */
    predefinedTicks?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface BeaconWarningModel
 */
export interface BeaconWarningModel {
    /**
     * 
     * @type {boolean}
     * @memberof BeaconWarningModel
     */
    showTooManyRegionsWarning: boolean;
}
/**
 * 
 * @export
 * @interface BlogPostModel
 */
export interface BlogPostModel {
    /**
     * 
     * @type {string}
     * @memberof BlogPostModel
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostModel
     */
    authorImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostModel
     */
    authorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostModel
     */
    authorDescription?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof BlogPostModel
     */
    date: IsoDateTimeString;
    /**
     * 
     * @type {Array<LocalizationTextModel>}
     * @memberof BlogPostModel
     */
    titleLocalizations?: Array<LocalizationTextModel> | null;
    /**
     * 
     * @type {Array<LocalizationTextModel>}
     * @memberof BlogPostModel
     */
    leadTextLocalizations?: Array<LocalizationTextModel> | null;
    /**
     * 
     * @type {Array<LocalizationTextModel>}
     * @memberof BlogPostModel
     */
    mainTextLocalizations?: Array<LocalizationTextModel> | null;
    /**
     * 
     * @type {number}
     * @memberof BlogPostModel
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BlogPostModel
     */
    titleLocalizationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BlogPostModel
     */
    leadTextLocalizationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BlogPostModel
     */
    mainTextLocalizationId?: number | null;
}
/**
 * 
 * @export
 * @interface BlogPostModelServiceResult
 */
export interface BlogPostModelServiceResult {
    /**
     * 
     * @type {string}
     * @memberof BlogPostModelServiceResult
     */
    message?: string | null;
    /**
     * 
     * @type {ResultStatus}
     * @memberof BlogPostModelServiceResult
     */
    status: ResultStatus;
    /**
     * 
     * @type {boolean}
     * @memberof BlogPostModelServiceResult
     */
    success: boolean;
    /**
     * 
     * @type {BlogPostModel}
     * @memberof BlogPostModelServiceResult
     */
    data?: BlogPostModel | null;
}
/**
 * 
 * @export
 * @interface BlogPostView
 */
export interface BlogPostView {
    /**
     * 
     * @type {number}
     * @memberof BlogPostView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BlogPostView
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostView
     */
    authorImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostView
     */
    authorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostView
     */
    authorDescription?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof BlogPostView
     */
    date: IsoDateTimeString;
    /**
     * 
     * @type {string}
     * @memberof BlogPostView
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostView
     */
    leadText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostView
     */
    articleText?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BlogPostView
     */
    deleted: boolean;
}
/**
 * 
 * @export
 * @interface BlogPostViewDataPage
 */
export interface BlogPostViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof BlogPostViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BlogPostViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<BlogPostView>}
     * @memberof BlogPostViewDataPage
     */
    items?: Array<BlogPostView> | null;
}
/**
 * 
 * @export
 * @interface BrandLookupView
 */
export interface BrandLookupView {
    /**
     * 
     * @type {string}
     * @memberof BrandLookupView
     */
    brand?: string | null;
}
/**
 * 
 * @export
 * @interface CampaignDetails
 */
export interface CampaignDetails {
    /**
     * 
     * @type {number}
     * @memberof CampaignDetails
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignDetails
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignDetails
     */
    description?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CampaignDetails
     */
    validTo?: IsoDateTimeString | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CampaignDetails
     */
    validFrom?: IsoDateTimeString | null;
    /**
     * 
     * @type {CampaignStatus}
     * @memberof CampaignDetails
     */
    status: CampaignStatus;
}
/**
 * 
 * @export
 * @interface CampaignLookupView
 */
export interface CampaignLookupView {
    /**
     * 
     * @type {number}
     * @memberof CampaignLookupView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignLookupView
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface CampaignModel
 */
export interface CampaignModel {
    /**
     * 
     * @type {number}
     * @memberof CampaignModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignModel
     */
    description?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CampaignModel
     */
    validTo?: IsoDateTimeString | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CampaignModel
     */
    validFrom?: IsoDateTimeString | null;
}
/**
 * 
 * @export
 * @interface CampaignModelServiceResult
 */
export interface CampaignModelServiceResult {
    /**
     * 
     * @type {string}
     * @memberof CampaignModelServiceResult
     */
    message?: string | null;
    /**
     * 
     * @type {ResultStatus}
     * @memberof CampaignModelServiceResult
     */
    status: ResultStatus;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignModelServiceResult
     */
    success: boolean;
    /**
     * 
     * @type {CampaignModel}
     * @memberof CampaignModelServiceResult
     */
    data?: CampaignModel | null;
}
/**
 * Values represent:     0 (Newest)     1 (Oldest)
 * @export
 * @enum {string}
 */
export enum CampaignSort {
    /**
    * Newest
    */
    Newest = 0,
    /**
    * Oldest
    */
    Oldest = 1
}

/**
 * Values represent:     0 (Scheduled)     1 (Live)     2 (Expired)     3 (Paused)     4 (Deleted)
 * @export
 * @enum {string}
 */
export enum CampaignStatus {
    /**
    * Scheduled
    */
    Scheduled = 0,
    /**
    * Live
    */
    Live = 1,
    /**
    * Expired
    */
    Expired = 2,
    /**
    * Paused
    */
    Paused = 3,
    /**
    * Deleted
    */
    Deleted = 4
}

/**
 * 
 * @export
 * @interface CampaignView
 */
export interface CampaignView {
    /**
     * 
     * @type {number}
     * @memberof CampaignView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignView
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignView
     */
    description?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CampaignView
     */
    validFrom?: IsoDateTimeString | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CampaignView
     */
    validTo?: IsoDateTimeString | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignView
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignView
     */
    grayImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignView
     */
    grayThumbnailUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignView
     */
    thumbnailUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignView
     */
    nmMessages: number;
    /**
     * 
     * @type {CampaignStatus}
     * @memberof CampaignView
     */
    status: CampaignStatus;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CampaignView
     */
    actualExpiration: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface CampaignViewDataPage
 */
export interface CampaignViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof CampaignViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<CampaignView>}
     * @memberof CampaignViewDataPage
     */
    items?: Array<CampaignView> | null;
}
/**
 * Values represent:     1 (Added)     2 (Updated)     3 (Deleted)
 * @export
 * @enum {string}
 */
export enum ChangeAction {
    /**
    * Added
    */
    Added = 1,
    /**
    * Updated
    */
    Updated = 2,
    /**
    * Deleted
    */
    Deleted = 3
}

/**
 * 
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    oldPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    newPassword?: string | null;
}
/**
 * 
 * @export
 * @interface CityView
 */
export interface CityView {
    /**
     * 
     * @type {number}
     * @memberof CityView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CityView
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CityView
     */
    countryId: number;
}
/**
 * 
 * @export
 * @interface ClientApps
 */
export interface ClientApps {
    /**
     * 
     * @type {string}
     * @memberof ClientApps
     */
    playStoreUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientApps
     */
    appStoreUri?: string | null;
    /**
     * 
     * @type {PrivacyPolicySettings}
     * @memberof ClientApps
     */
    privacyPolicy?: PrivacyPolicySettings | null;
}
/**
 * 
 * @export
 * @interface ConstrainDaysModel
 */
export interface ConstrainDaysModel {
    /**
     * 
     * @type {Array<DayOfWeek>}
     * @memberof ConstrainDaysModel
     */
    daysAllowed?: Array<DayOfWeek> | null;
}
/**
 * 
 * @export
 * @interface ConstrainHoursModel
 */
export interface ConstrainHoursModel {
    /**
     * 
     * @type {number}
     * @memberof ConstrainHoursModel
     */
    from: number;
    /**
     * 
     * @type {number}
     * @memberof ConstrainHoursModel
     */
    to: number;
}
/**
 * 
 * @export
 * @interface ContactView
 */
export interface ContactView {
    /**
     * 
     * @type {number}
     * @memberof ContactView
     */
    storeId: number;
    /**
     * 
     * @type {string}
     * @memberof ContactView
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactView
     */
    event?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof ContactView
     */
    utcTime: IsoDateTimeString;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof ContactView
     */
    suiteTime: IsoDateTimeString;
    /**
     * 
     * @type {string}
     * @memberof ContactView
     */
    beaconId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactView
     */
    beaconName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactView
     */
    beaconUuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactView
     */
    beaconMajor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactView
     */
    beaconMinor?: string | null;
}
/**
 * 
 * @export
 * @interface ContactViewDataPage
 */
export interface ContactViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof ContactViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContactViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<ContactView>}
     * @memberof ContactViewDataPage
     */
    items?: Array<ContactView> | null;
}
/**
 * 
 * @export
 * @interface ConversionStatistics
 */
export interface ConversionStatistics {
    /**
     * 
     * @type {number}
     * @memberof ConversionStatistics
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof ConversionStatistics
     */
    conversionRate: number;
}
/**
 * 
 * @export
 * @interface ConvertedUserView
 */
export interface ConvertedUserView {
    /**
     * 
     * @type {string}
     * @memberof ConvertedUserView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedUserView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedUserView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedUserView
     */
    lastName?: string | null;
    /**
     * 
     * @type {TrackedOfferView}
     * @memberof ConvertedUserView
     */
    trackedOffer?: TrackedOfferView | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof ConvertedUserView
     */
    conversionDate: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface ConvertedUserViewDataPage
 */
export interface ConvertedUserViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof ConvertedUserViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ConvertedUserViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<ConvertedUserView>}
     * @memberof ConvertedUserViewDataPage
     */
    items?: Array<ConvertedUserView> | null;
}
/**
 * 
 * @export
 * @interface Coordinates
 */
export interface Coordinates {
    /**
     * 
     * @type {number}
     * @memberof Coordinates
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof Coordinates
     */
    longitude: number;
}
/**
 * 
 * @export
 * @interface CountryView
 */
export interface CountryView {
    /**
     * 
     * @type {number}
     * @memberof CountryView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CountryView
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryView
     */
    iso2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryView
     */
    iso3?: string | null;
    /**
     * 
     * @type {PhoneCode}
     * @memberof CountryView
     */
    phoneCode?: PhoneCode | null;
}
/**
 * 
 * @export
 * @interface CreateCampaignCommand
 */
export interface CreateCampaignCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignCommand
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignCommand
     */
    description?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CreateCampaignCommand
     */
    validTo?: IsoDateTimeString | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CreateCampaignCommand
     */
    validFrom?: IsoDateTimeString | null;
}
/**
 * 
 * @export
 * @interface CrudAccessModel
 */
export interface CrudAccessModel {
    /**
     * 
     * @type {boolean}
     * @memberof CrudAccessModel
     */
    create: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CrudAccessModel
     */
    read: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CrudAccessModel
     */
    update: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CrudAccessModel
     */
    remove: boolean;
}
/**
 * 
 * @export
 * @interface CurrencyView
 */
export interface CurrencyView {
    /**
     * 
     * @type {string}
     * @memberof CurrencyView
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrencyView
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrencyView
     */
    symbol?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CurrencyView
     */
    unitValueInEuro: number;
}
/**
 * 
 * @export
 * @interface CustomAudienceExpression
 */
export interface CustomAudienceExpression {
    /**
     * 
     * @type {Array<CustomAudienceExpressionPart>}
     * @memberof CustomAudienceExpression
     */
    parts?: Array<CustomAudienceExpressionPart> | null;
}
/**
 * 
 * @export
 * @interface CustomAudienceExpressionPart
 */
export interface CustomAudienceExpressionPart {
    /**
     * 
     * @type {RuleExpressionPartType}
     * @memberof CustomAudienceExpressionPart
     */
    type: RuleExpressionPartType;
    /**
     * 
     * @type {RuleSentence}
     * @memberof CustomAudienceExpressionPart
     */
    sentence?: RuleSentence | null;
}
/**
 * Values represent:     0 (Global)     1 (Local)
 * @export
 * @enum {string}
 */
export enum CustomAudienceType {
    /**
    * Global
    */
    Global = 0,
    /**
    * Local
    */
    Local = 1
}

/**
 * 
 * @export
 * @interface CustomerDailyPurchasesView
 */
export interface CustomerDailyPurchasesView {
    /**
     * 
     * @type {number}
     * @memberof CustomerDailyPurchasesView
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDailyPurchasesView
     */
    label?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDailyPurchasesView
     */
    date?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerDailyPurchasesView
     */
    nmPurchases: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDailyPurchasesView
     */
    revenue: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDailyPurchasesView
     */
    quantity: number;
}
/**
 * 
 * @export
 * @interface CustomerDailyPurchasesViewDataPage
 */
export interface CustomerDailyPurchasesViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof CustomerDailyPurchasesViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerDailyPurchasesViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<CustomerDailyPurchasesView>}
     * @memberof CustomerDailyPurchasesViewDataPage
     */
    items?: Array<CustomerDailyPurchasesView> | null;
}
/**
 * 
 * @export
 * @interface CustomerDataView
 */
export interface CustomerDataView {
    /**
     * 
     * @type {CustomerSummaryViewDataPage}
     * @memberof CustomerDataView
     */
    customerSummary?: CustomerSummaryViewDataPage | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerDataView
     */
    sumOfStatistics: number;
}
/**
 * 
 * @export
 * @interface CustomerDetailsView
 */
export interface CustomerDetailsView {
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    lastName?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof CustomerDetailsView
     */
    gender: Gender;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    houseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    language?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    landlinePhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    avatarUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    avatarThumbnailUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    customerType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    birthDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    modifiedProfile?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDetailsView
     */
    disablePushNotifications: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    lastDisabledPushNotificationsTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsView
     */
    joinDate?: string | null;
    /**
     * 
     * @type {StoreView}
     * @memberof CustomerDetailsView
     */
    favoriteStore?: StoreView | null;
}
/**
 * 
 * @export
 * @interface CustomerEngagement
 */
export interface CustomerEngagement {
    /**
     * 
     * @type {Array<Location>}
     * @memberof CustomerEngagement
     */
    locations?: Array<Location> | null;
}
/**
 * Values represent:     1 (New)     2 (Vip)     3 (MostActive)     4 (Loyal)
 * @export
 * @enum {string}
 */
export enum CustomerFilter {
    /**
    * New
    */
    New = 1,
    /**
    * Vip
    */
    Vip = 2,
    /**
    * MostActive
    */
    MostActive = 3,
    /**
    * Loyal
    */
    Loyal = 4
}

/**
 * 
 * @export
 * @interface CustomerLookupView
 */
export interface CustomerLookupView {
    /**
     * 
     * @type {string}
     * @memberof CustomerLookupView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerLookupView
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerLookupView
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerLookupView
     */
    loyaltyNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerLookupView
     */
    pictureUrl?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerLookupViewDataPage
 */
export interface CustomerLookupViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof CustomerLookupViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerLookupViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<CustomerLookupView>}
     * @memberof CustomerLookupViewDataPage
     */
    items?: Array<CustomerLookupView> | null;
}
/**
 * Values represent:     0 (Email)     1 (FirstName)     2 (LastName)     3 (JoinDatetimeUtc)     4 (Gender)     5 (BirthDate)     6 (HouseNumber)     7 (Street)     8 (ZipCode)     9 (City)     10 (Country)     11 (Language)     12 (PhoneNumber)     13 (CustomerClubId)     14 (PurchaseDate)     15 (PurchaseUniqueId)     101 (TotalQuantity)     102 (TotalPrice)     103 (TotalDiscount)     104 (SalesPersonId)     105 (StoreId)     106 (StoreImageUrl)     107 (ArticleEan)     108 (ArticleName)     109 (ArticleDescription)     110 (ArticleQuantity)     111 (ArticleFullPrice)
 * @export
 * @enum {string}
 */
export enum CustomerPurchasesColumn {
    /**
    * Email
    */
    Email = 0,
    /**
    * FirstName
    */
    FirstName = 1,
    /**
    * LastName
    */
    LastName = 2,
    /**
    * JoinDatetimeUtc
    */
    JoinDatetimeUtc = 3,
    /**
    * Gender
    */
    Gender = 4,
    /**
    * BirthDate
    */
    BirthDate = 5,
    /**
    * HouseNumber
    */
    HouseNumber = 6,
    /**
    * Street
    */
    Street = 7,
    /**
    * ZipCode
    */
    ZipCode = 8,
    /**
    * City
    */
    City = 9,
    /**
    * Country
    */
    Country = 10,
    /**
    * Language
    */
    Language = 11,
    /**
    * PhoneNumber
    */
    PhoneNumber = 12,
    /**
    * CustomerClubId
    */
    CustomerClubId = 13,
    /**
    * PurchaseDate
    */
    PurchaseDate = 14,
    /**
    * PurchaseUniqueId
    */
    PurchaseUniqueId = 15,
    /**
    * TotalQuantity
    */
    TotalQuantity = 101,
    /**
    * TotalPrice
    */
    TotalPrice = 102,
    /**
    * TotalDiscount
    */
    TotalDiscount = 103,
    /**
    * SalesPersonId
    */
    SalesPersonId = 104,
    /**
    * StoreId
    */
    StoreId = 105,
    /**
    * StoreImageUrl
    */
    StoreImageUrl = 106,
    /**
    * ArticleEan
    */
    ArticleEan = 107,
    /**
    * ArticleName
    */
    ArticleName = 108,
    /**
    * ArticleDescription
    */
    ArticleDescription = 109,
    /**
    * ArticleQuantity
    */
    ArticleQuantity = 110,
    /**
    * ArticleFullPrice
    */
    ArticleFullPrice = 111
}

/**
 * 
 * @export
 * @interface CustomerPurchasesMapping
 */
export interface CustomerPurchasesMapping {
    /**
     * 
     * @type {string}
     * @memberof CustomerPurchasesMapping
     */
    title?: string | null;
    /**
     * 
     * @type {CustomerPurchasesColumn}
     * @memberof CustomerPurchasesMapping
     */
    destination?: CustomerPurchasesColumn | null;
}
/**
 * 
 * @export
 * @interface CustomerSegment
 */
export interface CustomerSegment {
    /**
     * 
     * @type {Reference}
     * @memberof CustomerSegment
     */
    segmentGroupId?: Reference | null;
    /**
     * 
     * @type {Reference}
     * @memberof CustomerSegment
     */
    segmentIndex?: Reference | null;
}
/**
 * Values represent:     1 (Email)     2 (Name)     3 (LoyaltyNumber)     4 (Statistic)
 * @export
 * @enum {string}
 */
export enum CustomerSort {
    /**
    * Email
    */
    Email = 1,
    /**
    * Name
    */
    Name = 2,
    /**
    * LoyaltyNumber
    */
    LoyaltyNumber = 3,
    /**
    * Statistic
    */
    Statistic = 4
}

/**
 * 
 * @export
 * @interface CustomerStatistics
 */
export interface CustomerStatistics {
    /**
     * 
     * @type {number}
     * @memberof CustomerStatistics
     */
    nmCustomers: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerStatistics
     */
    revenue: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerStatistics
     */
    transactionCount: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerStatistics
     */
    avgTransactionValue: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerStatistics
     */
    avgTransactionQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerStatistics
     */
    storeVisitsCount: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerStatistics
     */
    salesConversionRate: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerStatistics
     */
    isZero: boolean;
}
/**
 * 
 * @export
 * @interface CustomerStatisticsView
 */
export interface CustomerStatisticsView {
    /**
     * 
     * @type {number}
     * @memberof CustomerStatisticsView
     */
    nmSales: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerStatisticsView
     */
    totalRevenue: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerStatisticsView
     */
    avgQuantityPerSale: number;
    /**
     * 
     * @type {Array<CustomerStoreVisitView>}
     * @memberof CustomerStatisticsView
     */
    storeVisits?: Array<CustomerStoreVisitView> | null;
}
/**
 * 
 * @export
 * @interface CustomerStoreVisitView
 */
export interface CustomerStoreVisitView {
    /**
     * 
     * @type {string}
     * @memberof CustomerStoreVisitView
     */
    storeName?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomerStoreVisitView
     */
    visitDate: IsoDateTimeString;
    /**
     * 
     * @type {number}
     * @memberof CustomerStoreVisitView
     */
    storeId: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerStoreVisitView
     */
    nmOfVisits: number;
}
/**
 * Values represent:     1 (TotalPurchaseAmount)     2 (NmOfBeaconContacts)     3 (NmOfStoreVisits)     4 (NmOfTouchpoints)     5 (Transactions)
 * @export
 * @enum {string}
 */
export enum CustomerSummaryStatistic {
    /**
    * TotalPurchaseAmount
    */
    TotalPurchaseAmount = 1,
    /**
    * NmOfBeaconContacts
    */
    NmOfBeaconContacts = 2,
    /**
    * NmOfStoreVisits
    */
    NmOfStoreVisits = 3,
    /**
    * NmOfTouchpoints
    */
    NmOfTouchpoints = 4,
    /**
    * Transactions
    */
    Transactions = 5
}

/**
 * 
 * @export
 * @interface CustomerSummaryView
 */
export interface CustomerSummaryView {
    /**
     * 
     * @type {string}
     * @memberof CustomerSummaryView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSummaryView
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSummaryView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSummaryView
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSummaryView
     */
    loyaltyNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerSummaryView
     */
    statisticValue: number;
}
/**
 * 
 * @export
 * @interface CustomerSummaryViewDataPage
 */
export interface CustomerSummaryViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof CustomerSummaryViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerSummaryViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<CustomerSummaryView>}
     * @memberof CustomerSummaryViewDataPage
     */
    items?: Array<CustomerSummaryView> | null;
}
/**
 * 
 * @export
 * @interface CustomerTrackingGridView
 */
export interface CustomerTrackingGridView {
    /**
     * 
     * @type {string}
     * @memberof CustomerTrackingGridView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerTrackingGridView
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerTrackingGridView
     */
    platformDeviceId?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerTrackingGridViewDataPage
 */
export interface CustomerTrackingGridViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof CustomerTrackingGridViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerTrackingGridViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<CustomerTrackingGridView>}
     * @memberof CustomerTrackingGridViewDataPage
     */
    items?: Array<CustomerTrackingGridView> | null;
}
/**
 * Values represent:     0 (Sunday)     1 (Monday)     2 (Tuesday)     3 (Wednesday)     4 (Thursday)     5 (Friday)     6 (Saturday)
 * @export
 * @enum {string}
 */
export enum DayOfWeek {
    /**
    * Sunday
    */
    Sunday = 0,
    /**
    * Monday
    */
    Monday = 1,
    /**
    * Tuesday
    */
    Tuesday = 2,
    /**
    * Wednesday
    */
    Wednesday = 3,
    /**
    * Thursday
    */
    Thursday = 4,
    /**
    * Friday
    */
    Friday = 5,
    /**
    * Saturday
    */
    Saturday = 6
}

/**
 * 
 * @export
 * @interface DeepLinkPayload
 */
export interface DeepLinkPayload {
    /**
     * 
     * @type {Array<KeyValue>}
     * @memberof DeepLinkPayload
     */
    keyValues: Array<KeyValue>;
}
/**
 * 
 * @export
 * @interface DemographicsView
 */
export interface DemographicsView {
    /**
     * 
     * @type {number}
     * @memberof DemographicsView
     */
    women: number;
    /**
     * 
     * @type {number}
     * @memberof DemographicsView
     */
    men: number;
    /**
     * 
     * @type {number}
     * @memberof DemographicsView
     */
    other: number;
    /**
     * 
     * @type {Array<AgeGroupView>}
     * @memberof DemographicsView
     */
    ageGroups?: Array<AgeGroupView> | null;
}
/**
 * 
 * @export
 * @interface DepreactedChangePasswordRequest
 */
export interface DepreactedChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof DepreactedChangePasswordRequest
     */
    oldPassword: string;
    /**
     * 
     * @type {string}
     * @memberof DepreactedChangePasswordRequest
     */
    newPassword: string;
    /**
     * 
     * @type {string}
     * @memberof DepreactedChangePasswordRequest
     */
    confirmPassword: string;
}
/**
 * 
 * @export
 * @interface DeprecatedResetPasswordRequest
 */
export interface DeprecatedResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof DeprecatedResetPasswordRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof DeprecatedResetPasswordRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof DeprecatedResetPasswordRequest
     */
    confirmPassword: string;
    /**
     * 
     * @type {string}
     * @memberof DeprecatedResetPasswordRequest
     */
    code: string;
}
/**
 * 
 * @export
 * @interface DeviceId
 */
export interface DeviceId {
    /**
     * 
     * @type {string}
     * @memberof DeviceId
     */
    platformId?: string | null;
    /**
     * 
     * @type {PlatformType}
     * @memberof DeviceId
     */
    platformType: PlatformType;
}
/**
 * 
 * @export
 * @interface DeviceView
 */
export interface DeviceView {
    /**
     * 
     * @type {number}
     * @memberof DeviceView
     */
    id: number;
    /**
     * 
     * @type {PlatformType}
     * @memberof DeviceView
     */
    platformType: PlatformType;
    /**
     * 
     * @type {string}
     * @memberof DeviceView
     */
    platformDeviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceView
     */
    phoneManufacturer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceView
     */
    phoneModel?: string | null;
    /**
     * 
     * @type {PhoneOS}
     * @memberof DeviceView
     */
    phoneOSType: PhoneOS;
    /**
     * 
     * @type {string}
     * @memberof DeviceView
     */
    phoneOSVersion?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof DeviceView
     */
    utcActivated: IsoDateTimeString;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof DeviceView
     */
    utcDeactivated?: IsoDateTimeString | null;
}
/**
 * 
 * @export
 * @interface DiagnosticsGridDataModel
 */
export interface DiagnosticsGridDataModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof DiagnosticsGridDataModel
     */
    columnHeaders?: Array<string> | null;
    /**
     * 
     * @type {DiagnosticsRowDataPage}
     * @memberof DiagnosticsGridDataModel
     */
    data?: DiagnosticsRowDataPage | null;
}
/**
 * 
 * @export
 * @interface DiagnosticsRow
 */
export interface DiagnosticsRow {
    /**
     * 
     * @type {Array<string>}
     * @memberof DiagnosticsRow
     */
    row?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface DiagnosticsRowDataPage
 */
export interface DiagnosticsRowDataPage {
    /**
     * 
     * @type {string}
     * @memberof DiagnosticsRowDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DiagnosticsRowDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<DiagnosticsRow>}
     * @memberof DiagnosticsRowDataPage
     */
    items?: Array<DiagnosticsRow> | null;
}
/**
 * 
 * @export
 * @interface DuringPeriodModel
 */
export interface DuringPeriodModel {
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof DuringPeriodModel
     */
    from: IsoDateTimeString;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof DuringPeriodModel
     */
    to: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface EmailSentResponse
 */
export interface EmailSentResponse {
    /**
     * 
     * @type {boolean}
     * @memberof EmailSentResponse
     */
    isCustomer: boolean;
}
/**
 * 
 * @export
 * @interface EntityChangeView
 */
export interface EntityChangeView {
    /**
     * 
     * @type {number}
     * @memberof EntityChangeView
     */
    id: number;
    /**
     * 
     * @type {ChangeAction}
     * @memberof EntityChangeView
     */
    action: ChangeAction;
    /**
     * 
     * @type {Table}
     * @memberof EntityChangeView
     */
    table: Table;
    /**
     * 
     * @type {string}
     * @memberof EntityChangeView
     */
    entityId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EntityChangeView
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EntityChangeView
     */
    userNameOrId?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof EntityChangeView
     */
    utcDate: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface EntityChangeViewDataPage
 */
export interface EntityChangeViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof EntityChangeViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EntityChangeViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<EntityChangeView>}
     * @memberof EntityChangeViewDataPage
     */
    items?: Array<EntityChangeView> | null;
}
/**
 * 
 * @export
 * @interface FavoriteStore
 */
export interface FavoriteStore {
    /**
     * 
     * @type {Array<Location>}
     * @memberof FavoriteStore
     */
    locations?: Array<Location> | null;
}
/**
 * 
 * @export
 * @interface FeaturedItemModel
 */
export interface FeaturedItemModel {
    /**
     * 
     * @type {string}
     * @memberof FeaturedItemModel
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeaturedItemModel
     */
    url?: string | null;
    /**
     * 
     * @type {Array<LocalizationTextModel>}
     * @memberof FeaturedItemModel
     */
    textLocalizations?: Array<LocalizationTextModel> | null;
    /**
     * 
     * @type {number}
     * @memberof FeaturedItemModel
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FeaturedItemModel
     */
    textLocalizationId?: number | null;
}
/**
 * 
 * @export
 * @interface FeaturedItemModelServiceResult
 */
export interface FeaturedItemModelServiceResult {
    /**
     * 
     * @type {string}
     * @memberof FeaturedItemModelServiceResult
     */
    message?: string | null;
    /**
     * 
     * @type {ResultStatus}
     * @memberof FeaturedItemModelServiceResult
     */
    status: ResultStatus;
    /**
     * 
     * @type {boolean}
     * @memberof FeaturedItemModelServiceResult
     */
    success: boolean;
    /**
     * 
     * @type {FeaturedItemModel}
     * @memberof FeaturedItemModelServiceResult
     */
    data?: FeaturedItemModel | null;
}
/**
 * 
 * @export
 * @interface FeaturedItemView
 */
export interface FeaturedItemView {
    /**
     * 
     * @type {number}
     * @memberof FeaturedItemView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FeaturedItemView
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeaturedItemView
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeaturedItemView
     */
    text?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FeaturedItemView
     */
    deleted: boolean;
}
/**
 * 
 * @export
 * @interface FeaturedItemViewDataPage
 */
export interface FeaturedItemViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof FeaturedItemViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FeaturedItemViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<FeaturedItemView>}
     * @memberof FeaturedItemViewDataPage
     */
    items?: Array<FeaturedItemView> | null;
}
/**
 * 
 * @export
 * @interface FixedDate
 */
export interface FixedDate {
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof FixedDate
     */
    from: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface FixedDateRange
 */
export interface FixedDateRange {
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof FixedDateRange
     */
    from: IsoDateTimeString;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof FixedDateRange
     */
    to: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface ForceSendMessageRequest
 */
export interface ForceSendMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof ForceSendMessageRequest
     */
    customerId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ForceSendMessageRequest
     */
    delaySeconds: number;
}
/**
 * 
 * @export
 * @interface ForgotPasswordRequest
 */
export interface ForgotPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordRequest
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ForgotPasswordRequest
     */
    doNotSendEmail?: boolean | null;
}
/**
 * Values represent:     1 (Hourly)     2 (Daily)     3 (Weekly)
 * @export
 * @enum {string}
 */
export enum Frequency {
    /**
    * Hourly
    */
    Hourly = 1,
    /**
    * Daily
    */
    Daily = 2,
    /**
    * Weekly
    */
    Weekly = 3
}

/**
 * Values represent:     1 (Exactly)     2 (AtLeastOnce)     3 (AtLeast)     4 (AtMost)
 * @export
 * @enum {string}
 */
export enum FrequencyCheck {
    /**
    * Exactly
    */
    Exactly = 1,
    /**
    * AtLeastOnce
    */
    AtLeastOnce = 2,
    /**
    * AtLeast
    */
    AtLeast = 3,
    /**
    * AtMost
    */
    AtMost = 4
}

/**
 * Values represent:     0 (Other)     1 (Male)     2 (Female)
 * @export
 * @enum {string}
 */
export enum Gender {
    /**
    * Other
    */
    Other = 0,
    /**
    * Male
    */
    Male = 1,
    /**
    * Female
    */
    Female = 2
}

/**
 * 
 * @export
 * @interface HealthChecksView
 */
export interface HealthChecksView {
    /**
     * 
     * @type {number}
     * @memberof HealthChecksView
     */
    totalUsers: number;
    /**
     * 
     * @type {TotalAndRateView}
     * @memberof HealthChecksView
     */
    allPermissionsEnabled?: TotalAndRateView | null;
    /**
     * 
     * @type {TotalAndRateView}
     * @memberof HealthChecksView
     */
    pushNotificationEnabled?: TotalAndRateView | null;
    /**
     * 
     * @type {TotalAndRateView}
     * @memberof HealthChecksView
     */
    bluetoothEnabled?: TotalAndRateView | null;
    /**
     * 
     * @type {TotalAndRateView}
     * @memberof HealthChecksView
     */
    locationEnabled?: TotalAndRateView | null;
    /**
     * 
     * @type {TotalAndRateView}
     * @memberof HealthChecksView
     */
    locationAlwaysAllowed?: TotalAndRateView | null;
    /**
     * 
     * @type {TotalAndRateView}
     * @memberof HealthChecksView
     */
    locationAllowedWhileUsing?: TotalAndRateView | null;
    /**
     * 
     * @type {TotalAndRateView}
     * @memberof HealthChecksView
     */
    locationFullPrecision?: TotalAndRateView | null;
}
/**
 * 
 * @export
 * @interface ImageUploadResponse
 */
export interface ImageUploadResponse {
    /**
     * 
     * @type {string}
     * @memberof ImageUploadResponse
     */
    imagePath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageUploadResponse
     */
    imageGrayscalePath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageUploadResponse
     */
    thumbnailPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageUploadResponse
     */
    thumbnailGrayscalePath?: string | null;
}
/**
 * 
 * @export
 * @interface ImageUploadSettings
 */
export interface ImageUploadSettings {
    /**
     * 
     * @type {boolean}
     * @memberof ImageUploadSettings
     */
    generateGrayscale: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImageUploadSettings
     */
    generateThumbnail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImageUploadSettings
     */
    generateThumbnailGrayscale: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImageUploadSettings
     */
    skipResizing?: boolean | null;
}
/**
 * 
 * @export
 * @interface ImportResponse
 */
export interface ImportResponse {
    /**
     * 
     * @type {number}
     * @memberof ImportResponse
     */
    nmAddedRows: number;
    /**
     * 
     * @type {number}
     * @memberof ImportResponse
     */
    nmUpdatedRows: number;
    /**
     * 
     * @type {number}
     * @memberof ImportResponse
     */
    nmFailedRows: number;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof ImportResponse
     */
    failedRows?: Array<Array<string>> | null;
}
/**
 * 
 * @export
 * @interface IndefiniteModel
 */
export interface IndefiniteModel {
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof IndefiniteModel
     */
    dateTime: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    grant_type?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {ImageUploadSettings}
     * @memberof InlineObject1
     */
    settings?: ImageUploadSettings;
    /**
     * 
     * @type {any}
     * @memberof InlineObject1
     */
    image?: any;
}
/**
 * 
 * @export
 * @interface KeyValue
 */
export interface KeyValue {
    /**
     * 
     * @type {string}
     * @memberof KeyValue
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof KeyValue
     */
    value: string;
}
/**
 * 
 * @export
 * @interface LabelValue
 */
export interface LabelValue {
    /**
     * 
     * @type {string}
     * @memberof LabelValue
     */
    label?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LabelValue
     */
    value: number;
}
/**
 * 
 * @export
 * @interface LastBeaconContact
 */
export interface LastBeaconContact {
    /**
     * 
     * @type {PredefinedTimeSpan}
     * @memberof LastBeaconContact
     */
    time?: PredefinedTimeSpan | null;
    /**
     * 
     * @type {Array<Location>}
     * @memberof LastBeaconContact
     */
    locations?: Array<Location> | null;
}
/**
 * 
 * @export
 * @interface LiveBeaconContact
 */
export interface LiveBeaconContact {
    /**
     * 
     * @type {number}
     * @memberof LiveBeaconContact
     */
    minDurationSeconds?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LiveBeaconContact
     */
    maxDistanceSeconds?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LiveBeaconContact
     */
    beacons?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface LocalizationTextModel
 */
export interface LocalizationTextModel {
    /**
     * 
     * @type {string}
     * @memberof LocalizationTextModel
     */
    languageCode: string;
    /**
     * 
     * @type {string}
     * @memberof LocalizationTextModel
     */
    text?: string | null;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    areaId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    beaconId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    storeId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    cityId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    countryId?: number | null;
}
/**
 * 
 * @export
 * @interface LocationView
 */
export interface LocationView {
    /**
     * 
     * @type {string}
     * @memberof LocationView
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LocationView
     */
    cityId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LocationView
     */
    countryId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LocationView
     */
    storeId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LocationView
     */
    areaId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LocationView
     */
    beaconId?: string | null;
}
/**
 * Values represent:     1 (Verbose)     2 (Debug)     3 (Info)     4 (Warning)     5 (Error)     999 (Silent)
 * @export
 * @enum {string}
 */
export enum LoggingLevel {
    /**
    * Verbose
    */
    Verbose = 1,
    /**
    * Debug
    */
    Debug = 2,
    /**
    * Info
    */
    Info = 3,
    /**
    * Warning
    */
    Warning = 4,
    /**
    * Error
    */
    Error = 5,
    /**
    * Silent
    */
    Silent = 999
}

/**
 * 
 * @export
 * @interface MessageDetails
 */
export interface MessageDetails {
    /**
     * 
     * @type {LabelValue}
     * @memberof MessageDetails
     */
    campaign?: LabelValue | null;
    /**
     * 
     * @type {string}
     * @memberof MessageDetails
     */
    label?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageDetails
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageDetails
     */
    thumbnailUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageDetails
     */
    grayImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageDetails
     */
    grayThumbnailUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageDetails
     */
    isRedeemable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MessageDetails
     */
    isSilent: boolean;
    /**
     * 
     * @type {number}
     * @memberof MessageDetails
     */
    estimatedAudience?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MessageDetails
     */
    convertingOfferId?: number | null;
    /**
     * 
     * @type {ScheduleModel}
     * @memberof MessageDetails
     */
    schedule?: ScheduleModel | null;
    /**
     * 
     * @type {ValidityModel}
     * @memberof MessageDetails
     */
    validity?: ValidityModel | null;
    /**
     * 
     * @type {CustomAudienceExpression}
     * @memberof MessageDetails
     */
    customAudience?: CustomAudienceExpression | null;
    /**
     * 
     * @type {DeepLinkPayload}
     * @memberof MessageDetails
     */
    deepLinkPayload?: DeepLinkPayload | null;
    /**
     * 
     * @type {Array<TranslationModel>}
     * @memberof MessageDetails
     */
    translations?: Array<TranslationModel> | null;
    /**
     * 
     * @type {number}
     * @memberof MessageDetails
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof MessageDetails
     */
    draftId?: number | null;
}
/**
 * Values represent:     0 (Label)     1 (CampaignStartDate)     2 (Sent)     3 (Read)     4 (Clicked)     5 (Redeemed)     6 (Delivered)     7 (NmConversions)
 * @export
 * @enum {string}
 */
export enum MessageInteractionPerformanceSortColumn {
    /**
    * Label
    */
    Label = 0,
    /**
    * CampaignStartDate
    */
    CampaignStartDate = 1,
    /**
    * Sent
    */
    Sent = 2,
    /**
    * Read
    */
    Read = 3,
    /**
    * Clicked
    */
    Clicked = 4,
    /**
    * Redeemed
    */
    Redeemed = 5,
    /**
    * Delivered
    */
    Delivered = 6,
    /**
    * NmConversions
    */
    NmConversions = 7
}

/**
 * 
 * @export
 * @interface MessageInteractionPerformanceView
 */
export interface MessageInteractionPerformanceView {
    /**
     * 
     * @type {number}
     * @memberof MessageInteractionPerformanceView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MessageInteractionPerformanceView
     */
    label?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MessageInteractionPerformanceView
     */
    campaignId: number;
    /**
     * 
     * @type {string}
     * @memberof MessageInteractionPerformanceView
     */
    campaignTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageInteractionPerformanceView
     */
    pushTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageInteractionPerformanceView
     */
    pushText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageInteractionPerformanceView
     */
    offerTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageInteractionPerformanceView
     */
    offerText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageInteractionPerformanceView
     */
    offerRichText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageInteractionPerformanceView
     */
    callToActionTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageInteractionPerformanceView
     */
    callToActionLink?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageInteractionPerformanceView
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageInteractionPerformanceView
     */
    grayImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageInteractionPerformanceView
     */
    thumbnailUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageInteractionPerformanceView
     */
    grayThumbnailUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageInteractionPerformanceView
     */
    isRedeemable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MessageInteractionPerformanceView
     */
    hasCallToAction: boolean;
    /**
     * 
     * @type {number}
     * @memberof MessageInteractionPerformanceView
     */
    nmSent: number;
    /**
     * 
     * @type {number}
     * @memberof MessageInteractionPerformanceView
     */
    nmDelivered: number;
    /**
     * 
     * @type {number}
     * @memberof MessageInteractionPerformanceView
     */
    nmRead: number;
    /**
     * 
     * @type {number}
     * @memberof MessageInteractionPerformanceView
     */
    nmClicked: number;
    /**
     * 
     * @type {number}
     * @memberof MessageInteractionPerformanceView
     */
    nmConversions: number;
    /**
     * 
     * @type {number}
     * @memberof MessageInteractionPerformanceView
     */
    conversionRate: number;
    /**
     * 
     * @type {number}
     * @memberof MessageInteractionPerformanceView
     */
    clickThroughRate: number;
    /**
     * 
     * @type {number}
     * @memberof MessageInteractionPerformanceView
     */
    nmRedeemed: number;
    /**
     * 
     * @type {number}
     * @memberof MessageInteractionPerformanceView
     */
    redeemedRate: number;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof MessageInteractionPerformanceView
     */
    campaignStartDate?: IsoDateTimeString | null;
    /**
     * 
     * @type {string}
     * @memberof MessageInteractionPerformanceView
     */
    convertingOfferLabel?: string | null;
}
/**
 * 
 * @export
 * @interface MessageInteractionPerformanceViewDataPage
 */
export interface MessageInteractionPerformanceViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof MessageInteractionPerformanceViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MessageInteractionPerformanceViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<MessageInteractionPerformanceView>}
     * @memberof MessageInteractionPerformanceViewDataPage
     */
    items?: Array<MessageInteractionPerformanceView> | null;
}
/**
 * 
 * @export
 * @interface MessageLookupView
 */
export interface MessageLookupView {
    /**
     * 
     * @type {number}
     * @memberof MessageLookupView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MessageLookupView
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageLookupView
     */
    campaign?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageLookupView
     */
    isAConvertingOffer: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MessageLookupView
     */
    deactivated: boolean;
}
/**
 * 
 * @export
 * @interface MessageModel
 */
export interface MessageModel {
    /**
     * 
     * @type {LabelValue}
     * @memberof MessageModel
     */
    campaign?: LabelValue | null;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    label?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    thumbnailUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    grayImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    grayThumbnailUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageModel
     */
    isRedeemable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MessageModel
     */
    isSilent: boolean;
    /**
     * 
     * @type {number}
     * @memberof MessageModel
     */
    estimatedAudience?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MessageModel
     */
    convertingOfferId?: number | null;
    /**
     * 
     * @type {ScheduleModel}
     * @memberof MessageModel
     */
    schedule?: ScheduleModel | null;
    /**
     * 
     * @type {ValidityModel}
     * @memberof MessageModel
     */
    validity?: ValidityModel | null;
    /**
     * 
     * @type {CustomAudienceExpression}
     * @memberof MessageModel
     */
    customAudience?: CustomAudienceExpression | null;
    /**
     * 
     * @type {DeepLinkPayload}
     * @memberof MessageModel
     */
    deepLinkPayload?: DeepLinkPayload | null;
    /**
     * 
     * @type {Array<TranslationModel>}
     * @memberof MessageModel
     */
    translations?: Array<TranslationModel> | null;
}
/**
 * 
 * @export
 * @interface MessageResend
 */
export interface MessageResend {
    /**
     * 
     * @type {ResendInterval}
     * @memberof MessageResend
     */
    resendInterval: ResendInterval;
    /**
     * 
     * @type {number}
     * @memberof MessageResend
     */
    customNumberOfDays?: number | null;
}
/**
 * Values represent:     0 (Label)     1 (CampaignStartDate)     2 (Sent)     3 (Read)     4 (Clicked)     5 (Redeemed)     6 (Delivered)     7 (NmConversions)
 * @export
 * @enum {string}
 */
export enum MessageRevenuePerformanceSortColumn {
    /**
    * Label
    */
    Label = 0,
    /**
    * CampaignStartDate
    */
    CampaignStartDate = 1,
    /**
    * Sent
    */
    Sent = 2,
    /**
    * Read
    */
    Read = 3,
    /**
    * Clicked
    */
    Clicked = 4,
    /**
    * Redeemed
    */
    Redeemed = 5,
    /**
    * Delivered
    */
    Delivered = 6,
    /**
    * NmConversions
    */
    NmConversions = 7
}

/**
 * 
 * @export
 * @interface MessageRevenuePerformanceView
 */
export interface MessageRevenuePerformanceView {
    /**
     * 
     * @type {number}
     * @memberof MessageRevenuePerformanceView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MessageRevenuePerformanceView
     */
    label?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MessageRevenuePerformanceView
     */
    campaignId: number;
    /**
     * 
     * @type {string}
     * @memberof MessageRevenuePerformanceView
     */
    campaignTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRevenuePerformanceView
     */
    pushTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRevenuePerformanceView
     */
    pushText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRevenuePerformanceView
     */
    offerTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRevenuePerformanceView
     */
    offerText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRevenuePerformanceView
     */
    offerRichText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRevenuePerformanceView
     */
    callToActionTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRevenuePerformanceView
     */
    callToActionLink?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRevenuePerformanceView
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRevenuePerformanceView
     */
    grayImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRevenuePerformanceView
     */
    thumbnailUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRevenuePerformanceView
     */
    grayThumbnailUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageRevenuePerformanceView
     */
    isRedeemable: boolean;
    /**
     * 
     * @type {number}
     * @memberof MessageRevenuePerformanceView
     */
    nmSent: number;
    /**
     * 
     * @type {number}
     * @memberof MessageRevenuePerformanceView
     */
    nmDelivered: number;
    /**
     * 
     * @type {number}
     * @memberof MessageRevenuePerformanceView
     */
    nmRead: number;
    /**
     * 
     * @type {number}
     * @memberof MessageRevenuePerformanceView
     */
    nmClicked: number;
    /**
     * 
     * @type {number}
     * @memberof MessageRevenuePerformanceView
     */
    nmRedeemed: number;
    /**
     * 
     * @type {number}
     * @memberof MessageRevenuePerformanceView
     */
    nmVisits: number;
    /**
     * 
     * @type {number}
     * @memberof MessageRevenuePerformanceView
     */
    nmSales: number;
    /**
     * 
     * @type {number}
     * @memberof MessageRevenuePerformanceView
     */
    nmConversions: number;
    /**
     * 
     * @type {number}
     * @memberof MessageRevenuePerformanceView
     */
    revenue: number;
    /**
     * 
     * @type {number}
     * @memberof MessageRevenuePerformanceView
     */
    salesConversionRate: number;
    /**
     * 
     * @type {number}
     * @memberof MessageRevenuePerformanceView
     */
    avgTransactionValue: number;
    /**
     * 
     * @type {number}
     * @memberof MessageRevenuePerformanceView
     */
    avgQuantity: number;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof MessageRevenuePerformanceView
     */
    campaignStartDate?: IsoDateTimeString | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRevenuePerformanceView
     */
    convertingOfferLabel?: string | null;
}
/**
 * 
 * @export
 * @interface MessageRevenuePerformanceViewDataPage
 */
export interface MessageRevenuePerformanceViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof MessageRevenuePerformanceViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MessageRevenuePerformanceViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<MessageRevenuePerformanceView>}
     * @memberof MessageRevenuePerformanceViewDataPage
     */
    items?: Array<MessageRevenuePerformanceView> | null;
}
/**
 * Values represent:     1 (Newest)     2 (Oldest)     3 (TitleAsc)     4 (TitleDesc)
 * @export
 * @enum {string}
 */
export enum MessageSort {
    /**
    * Newest
    */
    Newest = 1,
    /**
    * Oldest
    */
    Oldest = 2,
    /**
    * TitleAsc
    */
    TitleAsc = 3,
    /**
    * TitleDesc
    */
    TitleDesc = 4
}

/**
 * Values represent:     1 (Scheduled)     2 (Live)     3 (Expired)     4 (Deleted)     5 (Paused)
 * @export
 * @enum {string}
 */
export enum MessageStatus {
    /**
    * Scheduled
    */
    Scheduled = 1,
    /**
    * Live
    */
    Live = 2,
    /**
    * Expired
    */
    Expired = 3,
    /**
    * Deleted
    */
    Deleted = 4,
    /**
    * Paused
    */
    Paused = 5
}

/**
 * 
 * @export
 * @interface MessageView
 */
export interface MessageView {
    /**
     * 
     * @type {number}
     * @memberof MessageView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    label?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MessageView
     */
    campaignId: number;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    campaignTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    pushTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    pushText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    offerTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    offerText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    offerRichText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    callToActionTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    callToActionLink?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    grayImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    thumbnailUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    grayThumbnailUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageView
     */
    isRedeemable: boolean;
    /**
     * 
     * @type {CampaignStatus}
     * @memberof MessageView
     */
    campaignStatus: CampaignStatus;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof MessageView
     */
    dateAt?: IsoDateTimeString | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof MessageView
     */
    dateFrom?: IsoDateTimeString | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof MessageView
     */
    dateTo?: IsoDateTimeString | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageView
     */
    deactivated: boolean;
    /**
     * 
     * @type {MessageStatus}
     * @memberof MessageView
     */
    status: MessageStatus;
    /**
     * 
     * @type {CustomAudienceExpression}
     * @memberof MessageView
     */
    audienceConfig?: CustomAudienceExpression | null;
    /**
     * 
     * @type {CustomAudienceType}
     * @memberof MessageView
     */
    audienceType: CustomAudienceType;
    /**
     * 
     * @type {number}
     * @memberof MessageView
     */
    estimatedAudience?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageView
     */
    isSilent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MessageView
     */
    isConvertingMessage: boolean;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    convertedOfferLabel?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageView
     */
    isConvertedMessage: boolean;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    convertingOfferLabel?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MessageView
     */
    draftId?: number | null;
    /**
     * 
     * @type {DeepLinkPayload}
     * @memberof MessageView
     */
    deepLinkConfig?: DeepLinkPayload | null;
}
/**
 * 
 * @export
 * @interface MessageViewDataPage
 */
export interface MessageViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof MessageViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MessageViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<MessageView>}
     * @memberof MessageViewDataPage
     */
    items?: Array<MessageView> | null;
}
/**
 * 
 * @export
 * @interface MostActiveCustomers
 */
export interface MostActiveCustomers {
    /**
     * 
     * @type {number}
     * @memberof MostActiveCustomers
     */
    minNumberOfPurchases?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MostActiveCustomers
     */
    topPercentage?: number | null;
}
/**
 * 
 * @export
 * @interface MostStoreVisits
 */
export interface MostStoreVisits {
    /**
     * 
     * @type {PredefinedTimeSpan}
     * @memberof MostStoreVisits
     */
    time?: PredefinedTimeSpan | null;
    /**
     * 
     * @type {Array<Location>}
     * @memberof MostStoreVisits
     */
    locations?: Array<Location> | null;
    /**
     * 
     * @type {number}
     * @memberof MostStoreVisits
     */
    topPercent: number;
}
/**
 * 
 * @export
 * @interface NewCustomerView
 */
export interface NewCustomerView {
    /**
     * 
     * @type {string}
     * @memberof NewCustomerView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewCustomerView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewCustomerView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewCustomerView
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewCustomerView
     */
    platform?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewCustomerView
     */
    revenue: number;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof NewCustomerView
     */
    firstPurchaseDate: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface NewCustomerViewDataPage
 */
export interface NewCustomerViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof NewCustomerViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewCustomerViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<NewCustomerView>}
     * @memberof NewCustomerViewDataPage
     */
    items?: Array<NewCustomerView> | null;
}
/**
 * 
 * @export
 * @interface NewVsReturningView
 */
export interface NewVsReturningView {
    /**
     * 
     * @type {number}
     * @memberof NewVsReturningView
     */
    newVsReturning?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NewVsReturningView
     */
    nmNew: number;
    /**
     * 
     * @type {number}
     * @memberof NewVsReturningView
     */
    nmReturning: number;
}
/**
 * Values represent:     1 (Sent)     2 (Delivered)     3 (Read)     4 (Clicked)     5 (Redeemed)     6 (Converted)
 * @export
 * @enum {string}
 */
export enum NotificationMark {
    /**
    * Sent
    */
    Sent = 1,
    /**
    * Delivered
    */
    Delivered = 2,
    /**
    * Read
    */
    Read = 3,
    /**
    * Clicked
    */
    Clicked = 4,
    /**
    * Redeemed
    */
    Redeemed = 5,
    /**
    * Converted
    */
    Converted = 6
}

/**
 * 
 * @export
 * @interface NotificationUserView
 */
export interface NotificationUserView {
    /**
     * 
     * @type {string}
     * @memberof NotificationUserView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationUserView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationUserView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationUserView
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationUserView
     */
    platform?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationUserView
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationUserView
     */
    storeLocationAtSend?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof NotificationUserView
     */
    date: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface NotificationUserViewDataPage
 */
export interface NotificationUserViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof NotificationUserViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationUserViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<NotificationUserView>}
     * @memberof NotificationUserViewDataPage
     */
    items?: Array<NotificationUserView> | null;
}
/**
 * 
 * @export
 * @interface OfferDraftModel
 */
export interface OfferDraftModel {
    /**
     * 
     * @type {number}
     * @memberof OfferDraftModel
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferDraftModel
     */
    offerId?: number | null;
    /**
     * 
     * @type {MessageModel}
     * @memberof OfferDraftModel
     */
    message?: MessageModel | null;
}
/**
 * 
 * @export
 * @interface OnlyOnceModel
 */
export interface OnlyOnceModel {
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof OnlyOnceModel
     */
    dateTime: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface PasswordChangedResponse
 */
export interface PasswordChangedResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PasswordChangedResponse
     */
    isAdmin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordChangedResponse
     */
    isSuperAdmin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordChangedResponse
     */
    isCustomer: boolean;
}
/**
 * 
 * @export
 * @interface PhoneCode
 */
export interface PhoneCode {
    /**
     * 
     * @type {string}
     * @memberof PhoneCode
     */
    countryCode?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PhoneCode
     */
    validPrefixes?: Array<string> | null;
}
/**
 * Values represent:     0 (Other)     1 (Android)     2 (iOS)
 * @export
 * @enum {string}
 */
export enum PhoneOS {
    /**
    * Other
    */
    Other = 0,
    /**
    * Android
    */
    Android = 1,
    /**
    * iOS
    */
    iOS = 2
}

/**
 * 
 * @export
 * @interface PlacedBeacon
 */
export interface PlacedBeacon {
    /**
     * 
     * @type {string}
     * @memberof PlacedBeacon
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlacedBeacon
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PlacedBeacon
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof PlacedBeacon
     */
    y: number;
}
/**
 * Values represent:     0 (Unknown)     1 (Fcm)     2 (Apns)
 * @export
 * @enum {string}
 */
export enum PlatformType {
    /**
    * Unknown
    */
    Unknown = 0,
    /**
    * Fcm
    */
    Fcm = 1,
    /**
    * Apns
    */
    Apns = 2
}

/**
 * 
 * @export
 * @interface PocUserModel
 */
export interface PocUserModel {
    /**
     * 
     * @type {string}
     * @memberof PocUserModel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PocUserModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PocUserModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PocUserModel
     */
    email: string;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof PocUserModel
     */
    expirationDate: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface PocUserView
 */
export interface PocUserView {
    /**
     * 
     * @type {string}
     * @memberof PocUserView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PocUserView
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PocUserView
     */
    email?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof PocUserView
     */
    expirationDateLocal: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface PocUserViewDataPage
 */
export interface PocUserViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof PocUserViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PocUserViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<PocUserView>}
     * @memberof PocUserViewDataPage
     */
    items?: Array<PocUserView> | null;
}
/**
 * 
 * @export
 * @interface PredefinedTimeSpan
 */
export interface PredefinedTimeSpan {
    /**
     * 
     * @type {TimeSpanRange}
     * @memberof PredefinedTimeSpan
     */
    range: TimeSpanRange;
    /**
     * 
     * @type {number}
     * @memberof PredefinedTimeSpan
     */
    hour?: number | null;
    /**
     * 
     * @type {RelativeTime}
     * @memberof PredefinedTimeSpan
     */
    relativeTime?: RelativeTime | null;
    /**
     * 
     * @type {RelativeTimeRange}
     * @memberof PredefinedTimeSpan
     */
    relativeTimeRange?: RelativeTimeRange | null;
    /**
     * 
     * @type {FixedDate}
     * @memberof PredefinedTimeSpan
     */
    fixedDate?: FixedDate | null;
    /**
     * 
     * @type {FixedDateRange}
     * @memberof PredefinedTimeSpan
     */
    fixedDateRange?: FixedDateRange | null;
}
/**
 * 
 * @export
 * @interface PreviousCurrentValueView
 */
export interface PreviousCurrentValueView {
    /**
     * 
     * @type {number}
     * @memberof PreviousCurrentValueView
     */
    current: number;
    /**
     * 
     * @type {number}
     * @memberof PreviousCurrentValueView
     */
    previous: number;
}
/**
 * 
 * @export
 * @interface PrivacyPolicySettings
 */
export interface PrivacyPolicySettings {
    /**
     * 
     * @type {string}
     * @memberof PrivacyPolicySettings
     */
    clientLegalName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PrivacyPolicySettings
     */
    clientAppName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PrivacyPolicySettings
     */
    beaconsmindLegalName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PrivacyPolicySettings
     */
    privacySupportEmail?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PrivacyPolicySettings
     */
    requiresUserPhoneNumber: boolean;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    instance?: string | null;
}
/**
 * 
 * @export
 * @interface ProductAndPurchases
 */
export interface ProductAndPurchases {
    /**
     * 
     * @type {PurchaseCheck}
     * @memberof ProductAndPurchases
     */
    purchaseCheck?: PurchaseCheck | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAndPurchases
     */
    brand?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductAndPurchases
     */
    descriptionKeywords?: Array<string> | null;
    /**
     * 
     * @type {RateOfOccurence}
     * @memberof ProductAndPurchases
     */
    rateOfOccurence?: RateOfOccurence | null;
    /**
     * 
     * @type {PredefinedTimeSpan}
     * @memberof ProductAndPurchases
     */
    time?: PredefinedTimeSpan | null;
}
/**
 * 
 * @export
 * @interface ProductCategoryLookupView
 */
export interface ProductCategoryLookupView {
    /**
     * 
     * @type {number}
     * @memberof ProductCategoryLookupView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryLookupView
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface ProductCategoryModel
 */
export interface ProductCategoryModel {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryModel
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryModel
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {Array<LocalizationTextModel>}
     * @memberof ProductCategoryModel
     */
    nameLocalizations?: Array<LocalizationTextModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ProductCategoryModel
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductCategoryModel
     */
    nameLocalizationId?: number | null;
}
/**
 * 
 * @export
 * @interface ProductCategoryModelServiceResult
 */
export interface ProductCategoryModelServiceResult {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryModelServiceResult
     */
    message?: string | null;
    /**
     * 
     * @type {ResultStatus}
     * @memberof ProductCategoryModelServiceResult
     */
    status: ResultStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategoryModelServiceResult
     */
    success: boolean;
    /**
     * 
     * @type {ProductCategoryModel}
     * @memberof ProductCategoryModelServiceResult
     */
    data?: ProductCategoryModel | null;
}
/**
 * 
 * @export
 * @interface ProductCategoryView
 */
export interface ProductCategoryView {
    /**
     * 
     * @type {number}
     * @memberof ProductCategoryView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryView
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryView
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryView
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategoryView
     */
    deleted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategoryView
     */
    hasProducts: boolean;
}
/**
 * 
 * @export
 * @interface ProductCategoryViewDataPage
 */
export interface ProductCategoryViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductCategoryViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<ProductCategoryView>}
     * @memberof ProductCategoryViewDataPage
     */
    items?: Array<ProductCategoryView> | null;
}
/**
 * Values represent:     1 (ProductIdentifier)     2 (ImageUrl)     3 (FromPrice)     4 (ToPrice)     5 (Name)     6 (Description)     7 (Url)     101 (CategoryName)     102 (CategoryImageUrl)     103 (CategoryUrl)
 * @export
 * @enum {string}
 */
export enum ProductImportColumn {
    /**
    * ProductIdentifier
    */
    ProductIdentifier = 1,
    /**
    * ImageUrl
    */
    ImageUrl = 2,
    /**
    * FromPrice
    */
    FromPrice = 3,
    /**
    * ToPrice
    */
    ToPrice = 4,
    /**
    * Name
    */
    Name = 5,
    /**
    * Description
    */
    Description = 6,
    /**
    * Url
    */
    Url = 7,
    /**
    * CategoryName
    */
    CategoryName = 101,
    /**
    * CategoryImageUrl
    */
    CategoryImageUrl = 102,
    /**
    * CategoryUrl
    */
    CategoryUrl = 103
}

/**
 * 
 * @export
 * @interface ProductModel
 */
export interface ProductModel {
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    currency?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    fromPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    toPrice?: number | null;
    /**
     * 
     * @type {Array<LocalizationTextModel>}
     * @memberof ProductModel
     */
    nameLocalizations?: Array<LocalizationTextModel> | null;
    /**
     * 
     * @type {Array<LocalizationTextModel>}
     * @memberof ProductModel
     */
    descriptionLocalizations?: Array<LocalizationTextModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    categoryId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    nameLocalizationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    descriptionLocalizationId?: number | null;
}
/**
 * 
 * @export
 * @interface ProductModelServiceResult
 */
export interface ProductModelServiceResult {
    /**
     * 
     * @type {string}
     * @memberof ProductModelServiceResult
     */
    message?: string | null;
    /**
     * 
     * @type {ResultStatus}
     * @memberof ProductModelServiceResult
     */
    status: ResultStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ProductModelServiceResult
     */
    success: boolean;
    /**
     * 
     * @type {ProductModel}
     * @memberof ProductModelServiceResult
     */
    data?: ProductModel | null;
}
/**
 * 
 * @export
 * @interface ProductPreference
 */
export interface ProductPreference {
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductPreference
     */
    productCategory?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPreference
     */
    minPurchases: number;
}
/**
 * 
 * @export
 * @interface ProductView
 */
export interface ProductView {
    /**
     * 
     * @type {number}
     * @memberof ProductView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductView
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductView
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductView
     */
    currency?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductView
     */
    fromPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductView
     */
    toPrice?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductView
     */
    categoryImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductView
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductView
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductView
     */
    categoryName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductView
     */
    deleted: boolean;
}
/**
 * 
 * @export
 * @interface ProductViewDataPage
 */
export interface ProductViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof ProductViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<ProductView>}
     * @memberof ProductViewDataPage
     */
    items?: Array<ProductView> | null;
}
/**
 * 
 * @export
 * @interface ProductsMapping
 */
export interface ProductsMapping {
    /**
     * 
     * @type {string}
     * @memberof ProductsMapping
     */
    title?: string | null;
    /**
     * 
     * @type {ProductImportColumn}
     * @memberof ProductsMapping
     */
    destination?: ProductImportColumn | null;
}
/**
 * 
 * @export
 * @interface ProfileResponse
 */
export interface ProfileResponse {
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    favoriteStore: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    gender: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    houseNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    landlinePhone: string;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof ProfileResponse
     */
    birthDate?: IsoDateTimeString | null;
    /**
     * 
     * @type {number}
     * @memberof ProfileResponse
     */
    favoriteStoreId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    avatarUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    avatarThumbnailUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     * @deprecated
     */
    clubId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileResponse
     */
    disablePushNotifications: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileResponse
     * @deprecated
     */
    newsLetterSubscription: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    phoneNumber: string;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof ProfileResponse
     */
    joinDate: IsoDateTimeString;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileResponse
     */
    roles?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileResponse
     */
    claims?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface PropertyChangeView
 */
export interface PropertyChangeView {
    /**
     * 
     * @type {string}
     * @memberof PropertyChangeView
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyChangeView
     */
    originalValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyChangeView
     */
    newValue?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyChangeView
     */
    isPrimaryKey: boolean;
}
/**
 * 
 * @export
 * @interface PropertyChangeViewDataPage
 */
export interface PropertyChangeViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof PropertyChangeViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PropertyChangeViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<PropertyChangeView>}
     * @memberof PropertyChangeViewDataPage
     */
    items?: Array<PropertyChangeView> | null;
}
/**
 * Values represent:     1 (SpecificProduct)     2 (HasNotPurchased)     3 (OrderWithTotalAmount)     4 (OrderWithDiscountAmount)
 * @export
 * @enum {string}
 */
export enum PurchaseAction {
    /**
    * SpecificProduct
    */
    SpecificProduct = 1,
    /**
    * HasNotPurchased
    */
    HasNotPurchased = 2,
    /**
    * OrderWithTotalAmount
    */
    OrderWithTotalAmount = 3,
    /**
    * OrderWithDiscountAmount
    */
    OrderWithDiscountAmount = 4
}

/**
 * 
 * @export
 * @interface PurchaseCheck
 */
export interface PurchaseCheck {
    /**
     * 
     * @type {PurchaseAction}
     * @memberof PurchaseCheck
     */
    purchaseAction: PurchaseAction;
    /**
     * 
     * @type {number}
     * @memberof PurchaseCheck
     */
    amount?: number | null;
}
/**
 * 
 * @export
 * @interface RateOfOccurence
 */
export interface RateOfOccurence {
    /**
     * 
     * @type {FrequencyCheck}
     * @memberof RateOfOccurence
     */
    frequencyCheck: FrequencyCheck;
    /**
     * 
     * @type {number}
     * @memberof RateOfOccurence
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface ReceivedExistingOffer
 */
export interface ReceivedExistingOffer {
    /**
     * 
     * @type {RecipientSelection}
     * @memberof ReceivedExistingOffer
     */
    selection: RecipientSelection;
    /**
     * 
     * @type {Reference}
     * @memberof ReceivedExistingOffer
     */
    offer?: Reference | null;
    /**
     * 
     * @type {ReceptionPeriod}
     * @memberof ReceivedExistingOffer
     */
    period: ReceptionPeriod;
}
/**
 * Values represent:     0 (Today)     1 (SinceYesterday)     2 (InLastSevenDays)     3 (InLastThirtyDays)     4 (InLastThreeMonths)     5 (InLastSixMonths)     6 (Ever)
 * @export
 * @enum {string}
 */
export enum ReceptionPeriod {
    /**
    * Today
    */
    Today = 0,
    /**
    * SinceYesterday
    */
    SinceYesterday = 1,
    /**
    * InLastSevenDays
    */
    InLastSevenDays = 2,
    /**
    * InLastThirtyDays
    */
    InLastThirtyDays = 3,
    /**
    * InLastThreeMonths
    */
    InLastThreeMonths = 4,
    /**
    * InLastSixMonths
    */
    InLastSixMonths = 5,
    /**
    * Ever
    */
    Ever = 6
}

/**
 * Values represent:     0 (Include)     1 (Exclude)
 * @export
 * @enum {string}
 */
export enum RecipientSelection {
    /**
    * Include
    */
    Include = 0,
    /**
    * Exclude
    */
    Exclude = 1
}

/**
 * 
 * @export
 * @interface RedemptionUserView
 */
export interface RedemptionUserView {
    /**
     * 
     * @type {string}
     * @memberof RedemptionUserView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RedemptionUserView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RedemptionUserView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RedemptionUserView
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RedemptionUserView
     */
    message?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RedemptionUserView
     */
    sentDate?: IsoDateTimeString | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RedemptionUserView
     */
    redemptionDate?: IsoDateTimeString | null;
}
/**
 * 
 * @export
 * @interface RedemptionUserViewDataPage
 */
export interface RedemptionUserViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof RedemptionUserViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RedemptionUserViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<RedemptionUserView>}
     * @memberof RedemptionUserViewDataPage
     */
    items?: Array<RedemptionUserView> | null;
}
/**
 * Values represent:     1 (All)     2 (Redeemed)     3 (NonRedeemed)
 * @export
 * @enum {string}
 */
export enum RedemptionUsersFilter {
    /**
    * All
    */
    All = 1,
    /**
    * Redeemed
    */
    Redeemed = 2,
    /**
    * NonRedeemed
    */
    NonRedeemed = 3
}

/**
 * 
 * @export
 * @interface Reference
 */
export interface Reference {
    /**
     * 
     * @type {number}
     * @memberof Reference
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Reference
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface RegistrationsView
 */
export interface RegistrationsView {
    /**
     * 
     * @type {number}
     * @memberof RegistrationsView
     */
    nmRegistrations: number;
    /**
     * 
     * @type {number}
     * @memberof RegistrationsView
     */
    nmAndroid: number;
    /**
     * 
     * @type {number}
     * @memberof RegistrationsView
     */
    nmIos: number;
}
/**
 * 
 * @export
 * @interface RelativeTime
 */
export interface RelativeTime {
    /**
     * 
     * @type {number}
     * @memberof RelativeTime
     */
    amount: number;
    /**
     * 
     * @type {TimeUnit}
     * @memberof RelativeTime
     */
    unit: TimeUnit;
    /**
     * 
     * @type {number}
     * @memberof RelativeTime
     */
    explicitHour?: number | null;
}
/**
 * 
 * @export
 * @interface RelativeTimeRange
 */
export interface RelativeTimeRange {
    /**
     * 
     * @type {number}
     * @memberof RelativeTimeRange
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof RelativeTimeRange
     */
    toAmount: number;
    /**
     * 
     * @type {TimeUnit}
     * @memberof RelativeTimeRange
     */
    unit: TimeUnit;
}
/**
 * Values represent:     0 (None)     1 (Daily)     2 (Weekly)     3 (Monthly)     4 (Yearly)     5 (CustomDays)
 * @export
 * @enum {string}
 */
export enum ResendInterval {
    /**
    * None
    */
    None = 0,
    /**
    * Daily
    */
    Daily = 1,
    /**
    * Weekly
    */
    Weekly = 2,
    /**
    * Monthly
    */
    Monthly = 3,
    /**
    * Yearly
    */
    Yearly = 4,
    /**
    * CustomDays
    */
    CustomDays = 5
}

/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    password?: string | null;
}
/**
 * Values represent:     0 (Success)     1 (InternalServerError)     2 (NotFound)     3 (IncorrectData)
 * @export
 * @enum {string}
 */
export enum ResultStatus {
    /**
    * Success
    */
    Success = 0,
    /**
    * InternalServerError
    */
    InternalServerError = 1,
    /**
    * NotFound
    */
    NotFound = 2,
    /**
    * IncorrectData
    */
    IncorrectData = 3
}

/**
 * 
 * @export
 * @interface ReturningCustomers
 */
export interface ReturningCustomers {
    /**
     * 
     * @type {Array<Location>}
     * @memberof ReturningCustomers
     */
    locations?: Array<Location> | null;
}
/**
 * 
 * @export
 * @interface RichText
 */
export interface RichText {
    /**
     * 
     * @type {string}
     * @memberof RichText
     */
    rich?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RichText
     */
    plain?: string | null;
}
/**
 * Values represent:     1 (Sentence)     2 (And)     3 (Or)
 * @export
 * @enum {string}
 */
export enum RuleExpressionPartType {
    /**
    * Sentence
    */
    Sentence = 1,
    /**
    * And
    */
    And = 2,
    /**
    * Or
    */
    Or = 3
}

/**
 * 
 * @export
 * @interface RuleSentence
 */
export interface RuleSentence {
    /**
     * 
     * @type {object}
     * @memberof RuleSentence
     */
    newRegistrations?: object | null;
    /**
     * 
     * @type {LiveBeaconContact}
     * @memberof RuleSentence
     */
    liveBeaconContact?: LiveBeaconContact | null;
    /**
     * 
     * @type {LastBeaconContact}
     * @memberof RuleSentence
     */
    lastBeaconContact?: LastBeaconContact | null;
    /**
     * 
     * @type {SpecificBeaconContact}
     * @memberof RuleSentence
     */
    specificBeaconContact?: SpecificBeaconContact | null;
    /**
     * 
     * @type {MostStoreVisits}
     * @memberof RuleSentence
     */
    mostStoreVisits?: MostStoreVisits | null;
    /**
     * 
     * @type {FavoriteStore}
     * @memberof RuleSentence
     */
    favoriteStore?: FavoriteStore | null;
    /**
     * 
     * @type {SpecificGender}
     * @memberof RuleSentence
     */
    specificGender?: SpecificGender | null;
    /**
     * 
     * @type {SpecificAge}
     * @memberof RuleSentence
     */
    specificAge?: SpecificAge | null;
    /**
     * 
     * @type {object}
     * @memberof RuleSentence
     */
    birthdayToday?: object | null;
    /**
     * 
     * @type {ProductPreference}
     * @memberof RuleSentence
     */
    productPreference?: ProductPreference | null;
    /**
     * 
     * @type {object}
     * @memberof RuleSentence
     */
    clickAndCollect?: object | null;
    /**
     * 
     * @type {ProductAndPurchases}
     * @memberof RuleSentence
     */
    productAndPurchases?: ProductAndPurchases | null;
    /**
     * 
     * @type {ReturningCustomers}
     * @memberof RuleSentence
     */
    returningCustomers?: ReturningCustomers | null;
    /**
     * 
     * @type {object}
     * @memberof RuleSentence
     */
    loyalCustomers?: object | null;
    /**
     * 
     * @type {MostActiveCustomers}
     * @memberof RuleSentence
     */
    mostActiveCustomers?: MostActiveCustomers | null;
    /**
     * 
     * @type {VipCustomers}
     * @memberof RuleSentence
     */
    vipCustomers?: VipCustomers | null;
    /**
     * 
     * @type {VisitWithoutPurchase}
     * @memberof RuleSentence
     */
    visitWithoutPurchase?: VisitWithoutPurchase | null;
    /**
     * 
     * @type {CustomerEngagement}
     * @memberof RuleSentence
     */
    customerEngagement?: CustomerEngagement | null;
    /**
     * 
     * @type {CustomerSegment}
     * @memberof RuleSentence
     */
    customerSegment?: CustomerSegment | null;
    /**
     * 
     * @type {ReceivedExistingOffer}
     * @memberof RuleSentence
     */
    receivedExistingOffer?: ReceivedExistingOffer | null;
    /**
     * 
     * @type {object}
     * @memberof RuleSentence
     */
    allUsers?: object | null;
}
/**
 * 
 * @export
 * @interface SalePerDayView
 */
export interface SalePerDayView {
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof SalePerDayView
     */
    date: IsoDateTimeString;
    /**
     * 
     * @type {number}
     * @memberof SalePerDayView
     */
    totalSales: number;
    /**
     * 
     * @type {number}
     * @memberof SalePerDayView
     */
    totalRevenue: number;
    /**
     * 
     * @type {number}
     * @memberof SalePerDayView
     */
    prevYearTotalSales: number;
    /**
     * 
     * @type {number}
     * @memberof SalePerDayView
     */
    prevYearTotalRevenue: number;
}
/**
 * 
 * @export
 * @interface SalesConversionUserView
 */
export interface SalesConversionUserView {
    /**
     * 
     * @type {string}
     * @memberof SalesConversionUserView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesConversionUserView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesConversionUserView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesConversionUserView
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesConversionUserView
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SalesConversionUserView
     */
    totalRevenue: number;
}
/**
 * 
 * @export
 * @interface SalesConversionUserViewDataPage
 */
export interface SalesConversionUserViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof SalesConversionUserViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SalesConversionUserViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<SalesConversionUserView>}
     * @memberof SalesConversionUserViewDataPage
     */
    items?: Array<SalesConversionUserView> | null;
}
/**
 * Values represent:     1 (All)     2 (VisitedOnly)     3 (VisitedAndPurchased)
 * @export
 * @enum {string}
 */
export enum SalesConversionUsersFilter {
    /**
    * All
    */
    All = 1,
    /**
    * VisitedOnly
    */
    VisitedOnly = 2,
    /**
    * VisitedAndPurchased
    */
    VisitedAndPurchased = 3
}

/**
 * 
 * @export
 * @interface ScheduleModel
 */
export interface ScheduleModel {
    /**
     * 
     * @type {OnlyOnceModel}
     * @memberof ScheduleModel
     */
    onlyOnce?: OnlyOnceModel | null;
    /**
     * 
     * @type {IndefiniteModel}
     * @memberof ScheduleModel
     */
    indefiniteFrom?: IndefiniteModel | null;
    /**
     * 
     * @type {DuringPeriodModel}
     * @memberof ScheduleModel
     */
    duringPeriod?: DuringPeriodModel | null;
    /**
     * 
     * @type {ConstrainHoursModel}
     * @memberof ScheduleModel
     */
    constrainHours?: ConstrainHoursModel | null;
    /**
     * 
     * @type {ConstrainDaysModel}
     * @memberof ScheduleModel
     */
    constrainDays?: ConstrainDaysModel | null;
    /**
     * 
     * @type {MessageResend}
     * @memberof ScheduleModel
     */
    messageResend?: MessageResend | null;
}
/**
 * 
 * @export
 * @interface SegmentGroupModel
 */
export interface SegmentGroupModel {
    /**
     * 
     * @type {number}
     * @memberof SegmentGroupModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SegmentGroupModel
     */
    title?: string | null;
    /**
     * 
     * @type {Array<SegmentModel>}
     * @memberof SegmentGroupModel
     */
    segments?: Array<SegmentModel> | null;
}
/**
 * 
 * @export
 * @interface SegmentGroupModelServiceResult
 */
export interface SegmentGroupModelServiceResult {
    /**
     * 
     * @type {string}
     * @memberof SegmentGroupModelServiceResult
     */
    message?: string | null;
    /**
     * 
     * @type {ResultStatus}
     * @memberof SegmentGroupModelServiceResult
     */
    status: ResultStatus;
    /**
     * 
     * @type {boolean}
     * @memberof SegmentGroupModelServiceResult
     */
    success: boolean;
    /**
     * 
     * @type {SegmentGroupModel}
     * @memberof SegmentGroupModelServiceResult
     */
    data?: SegmentGroupModel | null;
}
/**
 * 
 * @export
 * @interface SegmentGroupView
 */
export interface SegmentGroupView {
    /**
     * 
     * @type {number}
     * @memberof SegmentGroupView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SegmentGroupView
     */
    title?: string | null;
    /**
     * 
     * @type {Array<SegmentView>}
     * @memberof SegmentGroupView
     */
    segments?: Array<SegmentView> | null;
}
/**
 * 
 * @export
 * @interface SegmentGroupViewDataPage
 */
export interface SegmentGroupViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof SegmentGroupViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SegmentGroupViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<SegmentGroupView>}
     * @memberof SegmentGroupViewDataPage
     */
    items?: Array<SegmentGroupView> | null;
}
/**
 * 
 * @export
 * @interface SegmentModel
 */
export interface SegmentModel {
    /**
     * 
     * @type {string}
     * @memberof SegmentModel
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SegmentModel
     */
    size: number;
}
/**
 * 
 * @export
 * @interface SegmentView
 */
export interface SegmentView {
    /**
     * 
     * @type {number}
     * @memberof SegmentView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SegmentView
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SegmentView
     */
    size: number;
}
/**
 * 
 * @export
 * @interface SentMessageView
 */
export interface SentMessageView {
    /**
     * 
     * @type {number}
     * @memberof SentMessageView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SentMessageView
     */
    label?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SentMessageView
     */
    campaignId: number;
    /**
     * 
     * @type {string}
     * @memberof SentMessageView
     */
    campaignTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentMessageView
     */
    pushTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentMessageView
     */
    pushText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentMessageView
     */
    offerTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentMessageView
     */
    offerText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentMessageView
     */
    offerRichText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentMessageView
     */
    callToActionTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentMessageView
     */
    callToActionLink?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentMessageView
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentMessageView
     */
    grayImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentMessageView
     */
    thumbnailUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentMessageView
     */
    grayThumbnailUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SentMessageView
     */
    isRedeemable: boolean;
    /**
     * 
     * @type {number}
     * @memberof SentMessageView
     */
    sentOfferId: number;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof SentMessageView
     */
    utcQueued: IsoDateTimeString;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof SentMessageView
     */
    utcSent?: IsoDateTimeString | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof SentMessageView
     */
    utcReceived?: IsoDateTimeString | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof SentMessageView
     */
    utcRead?: IsoDateTimeString | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof SentMessageView
     */
    utcClicked?: IsoDateTimeString | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof SentMessageView
     */
    utcRedeemed?: IsoDateTimeString | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof SentMessageView
     */
    failedAt?: IsoDateTimeString | null;
    /**
     * 
     * @type {number}
     * @memberof SentMessageView
     */
    failedTimes: number;
    /**
     * 
     * @type {string}
     * @memberof SentMessageView
     */
    failureReason?: string | null;
}
/**
 * 
 * @export
 * @interface SentMessageViewDataPage
 */
export interface SentMessageViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof SentMessageViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SentMessageViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<SentMessageView>}
     * @memberof SentMessageViewDataPage
     */
    items?: Array<SentMessageView> | null;
}
/**
 * 
 * @export
 * @interface ServiceResult
 */
export interface ServiceResult {
    /**
     * 
     * @type {string}
     * @memberof ServiceResult
     */
    message?: string | null;
    /**
     * 
     * @type {ResultStatus}
     * @memberof ServiceResult
     */
    status: ResultStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceResult
     */
    success: boolean;
}
/**
 * 
 * @export
 * @interface ShoppingStatistics
 */
export interface ShoppingStatistics {
    /**
     * 
     * @type {number}
     * @memberof ShoppingStatistics
     */
    minTimeInSec: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingStatistics
     */
    maxTimeInSec: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingStatistics
     */
    avgTimeInSec: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingStatistics
     */
    totalTimeInSec: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingStatistics
     */
    peakHour: number;
}
/**
 * 
 * @export
 * @interface ShoppingStatisticsByDay
 */
export interface ShoppingStatisticsByDay {
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof ShoppingStatisticsByDay
     */
    date: IsoDateTimeString;
    /**
     * 
     * @type {ShoppingStatistics}
     * @memberof ShoppingStatisticsByDay
     */
    statistics?: ShoppingStatistics | null;
}
/**
 * 
 * @export
 * @interface ShoppingStatisticsView
 */
export interface ShoppingStatisticsView {
    /**
     * 
     * @type {ShoppingStatistics}
     * @memberof ShoppingStatisticsView
     */
    statisticsByDayAverages?: ShoppingStatistics | null;
    /**
     * 
     * @type {Array<ShoppingStatisticsByDay>}
     * @memberof ShoppingStatisticsView
     */
    statisticsByDay?: Array<ShoppingStatisticsByDay> | null;
    /**
     * 
     * @type {DayOfWeek}
     * @memberof ShoppingStatisticsView
     */
    peakDayOfWeek: DayOfWeek;
}
/**
 * Values represent:     1 (None)     2 (Ascending)     3 (Descending)
 * @export
 * @enum {string}
 */
export enum SortDirection {
    /**
    * None
    */
    None = 1,
    /**
    * Ascending
    */
    Ascending = 2,
    /**
    * Descending
    */
    Descending = 3
}

/**
 * 
 * @export
 * @interface SpecificAge
 */
export interface SpecificAge {
    /**
     * 
     * @type {Array<AgeGroup>}
     * @memberof SpecificAge
     */
    ageGroups?: Array<AgeGroup> | null;
}
/**
 * 
 * @export
 * @interface SpecificBeaconContact
 */
export interface SpecificBeaconContact {
    /**
     * 
     * @type {PredefinedTimeSpan}
     * @memberof SpecificBeaconContact
     */
    time?: PredefinedTimeSpan | null;
    /**
     * 
     * @type {Array<Location>}
     * @memberof SpecificBeaconContact
     */
    locations?: Array<Location> | null;
}
/**
 * 
 * @export
 * @interface SpecificGender
 */
export interface SpecificGender {
    /**
     * 
     * @type {Gender}
     * @memberof SpecificGender
     */
    gender: Gender;
}
/**
 * 
 * @export
 * @interface StoreFloorModel
 */
export interface StoreFloorModel {
    /**
     * 
     * @type {string}
     * @memberof StoreFloorModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreFloorModel
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {Array<PlacedBeacon>}
     * @memberof StoreFloorModel
     */
    beacons?: Array<PlacedBeacon> | null;
}
/**
 * 
 * @export
 * @interface StoreLayoutModel
 */
export interface StoreLayoutModel {
    /**
     * 
     * @type {number}
     * @memberof StoreLayoutModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StoreLayoutModel
     */
    storeName?: string | null;
    /**
     * 
     * @type {Array<StoreFloorModel>}
     * @memberof StoreLayoutModel
     */
    floors?: Array<StoreFloorModel> | null;
    /**
     * 
     * @type {Array<BeaconReference>}
     * @memberof StoreLayoutModel
     */
    unplacedBeacons?: Array<BeaconReference> | null;
}
/**
 * 
 * @export
 * @interface StoreLayoutModelServiceResult
 */
export interface StoreLayoutModelServiceResult {
    /**
     * 
     * @type {string}
     * @memberof StoreLayoutModelServiceResult
     */
    message?: string | null;
    /**
     * 
     * @type {ResultStatus}
     * @memberof StoreLayoutModelServiceResult
     */
    status: ResultStatus;
    /**
     * 
     * @type {boolean}
     * @memberof StoreLayoutModelServiceResult
     */
    success: boolean;
    /**
     * 
     * @type {StoreLayoutModel}
     * @memberof StoreLayoutModelServiceResult
     */
    data?: StoreLayoutModel | null;
}
/**
 * 
 * @export
 * @interface StoreLookupView
 */
export interface StoreLookupView {
    /**
     * 
     * @type {number}
     * @memberof StoreLookupView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StoreLookupView
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StoreLookupView
     */
    cityId: number;
    /**
     * 
     * @type {string}
     * @memberof StoreLookupView
     */
    cityName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StoreLookupView
     */
    countryId: number;
    /**
     * 
     * @type {string}
     * @memberof StoreLookupView
     */
    countryName?: string | null;
}
/**
 * 
 * @export
 * @interface StoreModel
 */
export interface StoreModel {
    /**
     * 
     * @type {string}
     * @memberof StoreModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StoreModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreModel
     */
    email1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreModel
     */
    email2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreModel
     */
    fax?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreModel
     */
    storeManager?: string | null;
    /**
     * 
     * @type {LabelValue}
     * @memberof StoreModel
     */
    city: LabelValue;
    /**
     * 
     * @type {LabelValue}
     * @memberof StoreModel
     */
    country: LabelValue;
    /**
     * 
     * @type {string}
     * @memberof StoreModel
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreModel
     */
    zipCode?: string | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof StoreModel
     */
    coordinates?: Coordinates | null;
    /**
     * 
     * @type {boolean}
     * @memberof StoreModel
     */
    wireless: boolean;
    /**
     * 
     * @type {number}
     * @memberof StoreModel
     */
    numberOfCameras: number;
    /**
     * 
     * @type {string}
     * @memberof StoreModel
     */
    instagramUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreModel
     */
    facebookUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreModel
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StoreModel
     */
    squareMeter: number;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoreModel
     */
    openingDate: IsoDateTimeString;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoreModel
     */
    closingDate?: IsoDateTimeString | null;
    /**
     * 
     * @type {number}
     * @memberof StoreModel
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StoreModel
     */
    timezone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreModel
     */
    status?: string | null;
}
/**
 * 
 * @export
 * @interface StoreModelServiceResult
 */
export interface StoreModelServiceResult {
    /**
     * 
     * @type {string}
     * @memberof StoreModelServiceResult
     */
    message?: string | null;
    /**
     * 
     * @type {ResultStatus}
     * @memberof StoreModelServiceResult
     */
    status: ResultStatus;
    /**
     * 
     * @type {boolean}
     * @memberof StoreModelServiceResult
     */
    success: boolean;
    /**
     * 
     * @type {StoreModel}
     * @memberof StoreModelServiceResult
     */
    data?: StoreModel | null;
}
/**
 * 
 * @export
 * @interface StoreView
 */
export interface StoreView {
    /**
     * 
     * @type {number}
     * @memberof StoreView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StoreView
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreView
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreView
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreView
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreView
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreView
     */
    name?: string | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof StoreView
     */
    coordinates?: Coordinates | null;
    /**
     * 
     * @type {boolean}
     * @memberof StoreView
     * @deprecated
     */
    isFavorite: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoreView
     */
    imageUrl?: string | null;
}
/**
 * 
 * @export
 * @interface StoreViewDataPage
 */
export interface StoreViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof StoreViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StoreViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<StoreView>}
     * @memberof StoreViewDataPage
     */
    items?: Array<StoreView> | null;
}
/**
 * 
 * @export
 * @interface StoreVisitView
 */
export interface StoreVisitView {
    /**
     * 
     * @type {number}
     * @memberof StoreVisitView
     */
    storeId: number;
    /**
     * 
     * @type {string}
     * @memberof StoreVisitView
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreVisitView
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreVisitView
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreVisitView
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreVisitView
     */
    userId?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoreVisitView
     */
    utcStart: IsoDateTimeString;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoreVisitView
     */
    utcEnd?: IsoDateTimeString | null;
    /**
     * 
     * @type {number}
     * @memberof StoreVisitView
     */
    durationSeconds?: number | null;
}
/**
 * 
 * @export
 * @interface StoreVisitViewDataPage
 */
export interface StoreVisitViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof StoreVisitViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StoreVisitViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<StoreVisitView>}
     * @memberof StoreVisitViewDataPage
     */
    items?: Array<StoreVisitView> | null;
}
/**
 * 
 * @export
 * @interface StoreVisitorDataResponse
 */
export interface StoreVisitorDataResponse {
    /**
     * 
     * @type {boolean}
     * @memberof StoreVisitorDataResponse
     */
    exists: boolean;
}
/**
 * 
 * @export
 * @interface StoreVisitsStatistic
 */
export interface StoreVisitsStatistic {
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoreVisitsStatistic
     */
    date: IsoDateTimeString;
    /**
     * 
     * @type {number}
     * @memberof StoreVisitsStatistic
     */
    nmOfVisits: number;
}
/**
 * 
 * @export
 * @interface StoreVisitsStatisticsView
 */
export interface StoreVisitsStatisticsView {
    /**
     * 
     * @type {Array<StoreVisitsStatistic>}
     * @memberof StoreVisitsStatisticsView
     */
    storeVisitsStatistics?: Array<StoreVisitsStatistic> | null;
    /**
     * 
     * @type {Frequency}
     * @memberof StoreVisitsStatisticsView
     */
    frequency: Frequency;
}
/**
 * Values represent:     0 (Low)     50 (Medium)     100 (High)
 * @export
 * @enum {string}
 */
export enum Strength {
    /**
    * Low
    */
    Low = 0,
    /**
    * Medium
    */
    Medium = 50,
    /**
    * High
    */
    High = 100
}

/**
 * Values represent:     1 (Campaign)     2 (Beacon)     3 (Store)     4 (Offer)     5 (OfferTranslation)     6 (BlogPost)     7 (FeaturedItem)     8 (Product)     9 (ProductCategory)     10 (PurchaseHistory)
 * @export
 * @enum {string}
 */
export enum Table {
    /**
    * Campaign
    */
    Campaign = 1,
    /**
    * Beacon
    */
    Beacon = 2,
    /**
    * Store
    */
    Store = 3,
    /**
    * Offer
    */
    Offer = 4,
    /**
    * OfferTranslation
    */
    OfferTranslation = 5,
    /**
    * BlogPost
    */
    BlogPost = 6,
    /**
    * FeaturedItem
    */
    FeaturedItem = 7,
    /**
    * Product
    */
    Product = 8,
    /**
    * ProductCategory
    */
    ProductCategory = 9,
    /**
    * PurchaseHistory
    */
    PurchaseHistory = 10
}

/**
 * Values represent:     0 (Today)     1 (Yesterday)     2 (SevenDaysAgo)     3 (ThirtyDaysAgo)     4 (ThreeMonthsAgo)     5 (SixMonthsAgo)     9 (AnyTime)     10 (Never)     11 (ThisMonth)     12 (LastMonth)     20 (RelativeTime)     21 (RelativeTimeRange)     22 (FixedDate)     23 (FixedDateRange)
 * @export
 * @enum {string}
 */
export enum TimeSpanRange {
    /**
    * Today
    */
    Today = 0,
    /**
    * Yesterday
    */
    Yesterday = 1,
    /**
    * SevenDaysAgo
    */
    SevenDaysAgo = 2,
    /**
    * ThirtyDaysAgo
    */
    ThirtyDaysAgo = 3,
    /**
    * ThreeMonthsAgo
    */
    ThreeMonthsAgo = 4,
    /**
    * SixMonthsAgo
    */
    SixMonthsAgo = 5,
    /**
    * AnyTime
    */
    AnyTime = 9,
    /**
    * Never
    */
    Never = 10,
    /**
    * ThisMonth
    */
    ThisMonth = 11,
    /**
    * LastMonth
    */
    LastMonth = 12,
    /**
    * RelativeTime
    */
    RelativeTime = 20,
    /**
    * RelativeTimeRange
    */
    RelativeTimeRange = 21,
    /**
    * FixedDate
    */
    FixedDate = 22,
    /**
    * FixedDateRange
    */
    FixedDateRange = 23
}

/**
 * 
 * @export
 * @interface TimeSpentUserView
 */
export interface TimeSpentUserView {
    /**
     * 
     * @type {string}
     * @memberof TimeSpentUserView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimeSpentUserView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimeSpentUserView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimeSpentUserView
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimeSpentUserView
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TimeSpentUserView
     */
    nmOfVisits: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpentUserView
     */
    totalVisitDurationSeconds: number;
}
/**
 * 
 * @export
 * @interface TimeSpentUserViewDataPage
 */
export interface TimeSpentUserViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof TimeSpentUserViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TimeSpentUserViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<TimeSpentUserView>}
     * @memberof TimeSpentUserViewDataPage
     */
    items?: Array<TimeSpentUserView> | null;
}
/**
 * Values represent:     0 (Minute)     1 (Hour)     2 (Second)     3 (Day)     4 (Month)
 * @export
 * @enum {string}
 */
export enum TimeUnit {
    /**
    * Minute
    */
    Minute = 0,
    /**
    * Hour
    */
    Hour = 1,
    /**
    * Second
    */
    Second = 2,
    /**
    * Day
    */
    Day = 3,
    /**
    * Month
    */
    Month = 4
}

/**
 * Values represent:     1 (Today)     2 (Yesterday)     3 (Last7Days)     4 (Last30Days)     5 (ThisMonth)     6 (PreviousMonth)     7 (ThisYear)     8 (PreviousYear)     9 (AllTime)     10 (CustomRange)     11 (Now)
 * @export
 * @enum {string}
 */
export enum TimeframeType {
    /**
    * Today
    */
    Today = 1,
    /**
    * Yesterday
    */
    Yesterday = 2,
    /**
    * Last7Days
    */
    Last7Days = 3,
    /**
    * Last30Days
    */
    Last30Days = 4,
    /**
    * ThisMonth
    */
    ThisMonth = 5,
    /**
    * PreviousMonth
    */
    PreviousMonth = 6,
    /**
    * ThisYear
    */
    ThisYear = 7,
    /**
    * PreviousYear
    */
    PreviousYear = 8,
    /**
    * AllTime
    */
    AllTime = 9,
    /**
    * CustomRange
    */
    CustomRange = 10,
    /**
    * Now
    */
    Now = 11
}

/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    user_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    access_token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    token_type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    expires_in?: string | null;
}
/**
 * 
 * @export
 * @interface TopAreaView
 */
export interface TopAreaView {
    /**
     * 
     * @type {number}
     * @memberof TopAreaView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TopAreaView
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TopAreaView
     */
    numberOfContacts: number;
}
/**
 * 
 * @export
 * @interface TopBeaconView
 */
export interface TopBeaconView {
    /**
     * 
     * @type {string}
     * @memberof TopBeaconView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopBeaconView
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TopBeaconView
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof TopBeaconView
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopBeaconView
     */
    areaName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopBeaconView
     */
    storeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TopBeaconView
     */
    maxVisitDurationSeconds: number;
}
/**
 * 
 * @export
 * @interface TopBeaconViewDataPage
 */
export interface TopBeaconViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof TopBeaconViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TopBeaconViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<TopBeaconView>}
     * @memberof TopBeaconViewDataPage
     */
    items?: Array<TopBeaconView> | null;
}
/**
 * 
 * @export
 * @interface TopCustomerGridView
 */
export interface TopCustomerGridView {
    /**
     * 
     * @type {string}
     * @memberof TopCustomerGridView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopCustomerGridView
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TopCustomerGridView
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof TopCustomerGridView
     */
    pictureUrl?: string | null;
}
/**
 * 
 * @export
 * @interface TopLocationGridView
 */
export interface TopLocationGridView {
    /**
     * 
     * @type {string}
     * @memberof TopLocationGridView
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TopLocationGridView
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof TopLocationGridView
     */
    iso2?: string | null;
}
/**
 * 
 * @export
 * @interface TopProductView
 */
export interface TopProductView {
    /**
     * 
     * @type {string}
     * @memberof TopProductView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopProductView
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TopProductView
     */
    revenueGenerated: number;
    /**
     * 
     * @type {number}
     * @memberof TopProductView
     */
    piecesSold: number;
}
/**
 * 
 * @export
 * @interface TopProductViewDataPage
 */
export interface TopProductViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof TopProductViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TopProductViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<TopProductView>}
     * @memberof TopProductViewDataPage
     */
    items?: Array<TopProductView> | null;
}
/**
 * 
 * @export
 * @interface TopStoreGridView
 */
export interface TopStoreGridView {
    /**
     * 
     * @type {number}
     * @memberof TopStoreGridView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TopStoreGridView
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TopStoreGridView
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof TopStoreGridView
     */
    city?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TopStoreGridView
     */
    squareMeter: number;
    /**
     * 
     * @type {number}
     * @memberof TopStoreGridView
     */
    nmOfBeacons: number;
}
/**
 * 
 * @export
 * @interface TopStoreGridViewDataPage
 */
export interface TopStoreGridViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof TopStoreGridViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TopStoreGridViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<TopStoreGridView>}
     * @memberof TopStoreGridViewDataPage
     */
    items?: Array<TopStoreGridView> | null;
}
/**
 * 
 * @export
 * @interface TotalAndRateView
 */
export interface TotalAndRateView {
    /**
     * 
     * @type {number}
     * @memberof TotalAndRateView
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof TotalAndRateView
     */
    rate: number;
}
/**
 * 
 * @export
 * @interface TotalAppCustomerView
 */
export interface TotalAppCustomerView {
    /**
     * 
     * @type {string}
     * @memberof TotalAppCustomerView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TotalAppCustomerView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TotalAppCustomerView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TotalAppCustomerView
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TotalAppCustomerView
     */
    platform?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TotalAppCustomerView
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TotalAppCustomerView
     */
    revenue: number;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof TotalAppCustomerView
     */
    joinDate: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface TotalAppCustomerViewDataPage
 */
export interface TotalAppCustomerViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof TotalAppCustomerViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TotalAppCustomerViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<TotalAppCustomerView>}
     * @memberof TotalAppCustomerViewDataPage
     */
    items?: Array<TotalAppCustomerView> | null;
}
/**
 * 
 * @export
 * @interface TotalAppUserView
 */
export interface TotalAppUserView {
    /**
     * 
     * @type {string}
     * @memberof TotalAppUserView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TotalAppUserView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TotalAppUserView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TotalAppUserView
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TotalAppUserView
     */
    email?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof TotalAppUserView
     */
    joinDate: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface TotalAppUserViewDataPage
 */
export interface TotalAppUserViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof TotalAppUserViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TotalAppUserViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<TotalAppUserView>}
     * @memberof TotalAppUserViewDataPage
     */
    items?: Array<TotalAppUserView> | null;
}
/**
 * 
 * @export
 * @interface TouchpointsStatistic
 */
export interface TouchpointsStatistic {
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof TouchpointsStatistic
     */
    date: IsoDateTimeString;
    /**
     * 
     * @type {number}
     * @memberof TouchpointsStatistic
     */
    local: number;
    /**
     * 
     * @type {number}
     * @memberof TouchpointsStatistic
     */
    global: number;
    /**
     * 
     * @type {number}
     * @memberof TouchpointsStatistic
     */
    beaconContacts: number;
    /**
     * 
     * @type {number}
     * @memberof TouchpointsStatistic
     */
    registrations: number;
    /**
     * 
     * @type {number}
     * @memberof TouchpointsStatistic
     */
    logins: number;
    /**
     * 
     * @type {number}
     * @memberof TouchpointsStatistic
     */
    opens: number;
    /**
     * 
     * @type {number}
     * @memberof TouchpointsStatistic
     */
    total: number;
}
/**
 * 
 * @export
 * @interface TouchpointsStatisticsView
 */
export interface TouchpointsStatisticsView {
    /**
     * 
     * @type {Array<TouchpointsStatistic>}
     * @memberof TouchpointsStatisticsView
     */
    statistics?: Array<TouchpointsStatistic> | null;
    /**
     * 
     * @type {Frequency}
     * @memberof TouchpointsStatisticsView
     */
    frequency: Frequency;
}
/**
 * 
 * @export
 * @interface TrackedOfferView
 */
export interface TrackedOfferView {
    /**
     * 
     * @type {number}
     * @memberof TrackedOfferView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TrackedOfferView
     */
    label?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackedOfferView
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackedOfferView
     */
    campaignTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackedOfferView
     */
    convertingOfferLabel?: string | null;
}
/**
 * 
 * @export
 * @interface TrafficView
 */
export interface TrafficView {
    /**
     * 
     * @type {number}
     * @memberof TrafficView
     */
    newBuyers: number;
    /**
     * 
     * @type {number}
     * @memberof TrafficView
     */
    recurrentBuyers: number;
    /**
     * 
     * @type {number}
     * @memberof TrafficView
     */
    nonBuyers: number;
}
/**
 * 
 * @export
 * @interface TranslationModel
 */
export interface TranslationModel {
    /**
     * 
     * @type {string}
     * @memberof TranslationModel
     */
    languageCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TranslationModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TranslationModel
     */
    pushMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TranslationModel
     */
    offerTitle?: string | null;
    /**
     * 
     * @type {RichText}
     * @memberof TranslationModel
     */
    text?: RichText | null;
    /**
     * 
     * @type {string}
     * @memberof TranslationModel
     */
    callToActionTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TranslationModel
     */
    callToActionLink?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCampaignCommand
 */
export interface UpdateCampaignCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateCampaignCommand
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCampaignCommand
     */
    description?: string | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof UpdateCampaignCommand
     */
    validTo?: IsoDateTimeString | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof UpdateCampaignCommand
     */
    validFrom?: IsoDateTimeString | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCampaignCommand
     */
    id?: number | null;
    /**
     * 
     * @type {CampaignStatus}
     * @memberof UpdateCampaignCommand
     */
    status: CampaignStatus;
}
/**
 * 
 * @export
 * @interface UploadCustomerPurchasesRequest
 */
export interface UploadCustomerPurchasesRequest {
    /**
     * 
     * @type {Array<CustomerPurchasesMapping>}
     * @memberof UploadCustomerPurchasesRequest
     */
    columnMappings?: Array<CustomerPurchasesMapping> | null;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof UploadCustomerPurchasesRequest
     */
    rows?: Array<Array<string>> | null;
}
/**
 * 
 * @export
 * @interface UploadProductsRequest
 */
export interface UploadProductsRequest {
    /**
     * 
     * @type {Array<ProductsMapping>}
     * @memberof UploadProductsRequest
     */
    columnMappings?: Array<ProductsMapping> | null;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof UploadProductsRequest
     */
    rows?: Array<Array<string>> | null;
    /**
     * 
     * @type {string}
     * @memberof UploadProductsRequest
     */
    language?: string | null;
}
/**
 * 
 * @export
 * @interface UserPermissions
 */
export interface UserPermissions {
    /**
     * 
     * @type {CrudAccessModel}
     * @memberof UserPermissions
     */
    beacons?: CrudAccessModel | null;
    /**
     * 
     * @type {CrudAccessModel}
     * @memberof UserPermissions
     */
    users?: CrudAccessModel | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    suiteAccess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    loginViaSocialProvider: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    pocManagementAccess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    readSelfUserData: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    createSuperAdmin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    createAdmin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    readLogs: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    accessHangfire: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    deleteMessageStatistics: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    forceSendMessage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    deleteContactsAndVisits: boolean;
}
/**
 * 
 * @export
 * @interface ValidityModel
 */
export interface ValidityModel {
    /**
     * 
     * @type {number}
     * @memberof ValidityModel
     */
    monthsAfterSent?: number | null;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof ValidityModel
     */
    absoluteDateTime?: IsoDateTimeString | null;
}
/**
 * 
 * @export
 * @interface VerifyTokenRequest
 */
export interface VerifyTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyTokenRequest
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyTokenRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyTokenRequest
     */
    tokenPurpose?: string | null;
}
/**
 * 
 * @export
 * @interface VipCustomers
 */
export interface VipCustomers {
    /**
     * 
     * @type {number}
     * @memberof VipCustomers
     */
    minNumberOfPurchases?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VipCustomers
     */
    topPercentage?: number | null;
}
/**
 * 
 * @export
 * @interface VisitWithoutPurchase
 */
export interface VisitWithoutPurchase {
    /**
     * 
     * @type {PredefinedTimeSpan}
     * @memberof VisitWithoutPurchase
     */
    time?: PredefinedTimeSpan | null;
    /**
     * 
     * @type {Array<Location>}
     * @memberof VisitWithoutPurchase
     */
    locations?: Array<Location> | null;
}
/**
 * 
 * @export
 * @interface VisitingUserView
 */
export interface VisitingUserView {
    /**
     * 
     * @type {string}
     * @memberof VisitingUserView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisitingUserView
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisitingUserView
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisitingUserView
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisitingUserView
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisitingUserView
     */
    storeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VisitingUserView
     */
    visitDurationSeconds: number;
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof VisitingUserView
     */
    visitStart: IsoDateTimeString;
}
/**
 * 
 * @export
 * @interface VisitingUserViewDataPage
 */
export interface VisitingUserViewDataPage {
    /**
     * 
     * @type {string}
     * @memberof VisitingUserViewDataPage
     */
    nextPageLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VisitingUserViewDataPage
     */
    count: number;
    /**
     * 
     * @type {Array<VisitingUserView>}
     * @memberof VisitingUserViewDataPage
     */
    items?: Array<VisitingUserView> | null;
}
/**
 * 
 * @export
 * @interface VisitorPerDayView
 */
export interface VisitorPerDayView {
    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof VisitorPerDayView
     */
    date: IsoDateTimeString;
    /**
     * 
     * @type {number}
     * @memberof VisitorPerDayView
     */
    totalVisitors: number;
    /**
     * 
     * @type {number}
     * @memberof VisitorPerDayView
     */
    appVisitors: number;
}
/**
 * 
 * @export
 * @interface VisitorStatisticsView
 */
export interface VisitorStatisticsView {
    /**
     * 
     * @type {number}
     * @memberof VisitorStatisticsView
     */
    newVisitors: number;
    /**
     * 
     * @type {number}
     * @memberof VisitorStatisticsView
     */
    returningVisitors: number;
    /**
     * 
     * @type {number}
     * @memberof VisitorStatisticsView
     */
    averageVisitDurationSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof VisitorStatisticsView
     */
    bounceRate: number;
}
/**
 * 
 * @export
 * @interface WebConfigModel
 */
export interface WebConfigModel {
    /**
     * 
     * @type {string}
     * @memberof WebConfigModel
     */
    startTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebConfigModel
     */
    suiteDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebConfigModel
     */
    buildDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebConfigModel
     */
    appVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebConfigModel
     */
    runtimeVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebConfigModel
     */
    suiteVariant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebConfigModel
     */
    currentEnvironment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WebConfigModel
     */
    disablePurchaseHistories: boolean;
    /**
     * 
     * @type {CurrencyView}
     * @memberof WebConfigModel
     */
    defaultCurrency?: CurrencyView | null;
    /**
     * 
     * @type {Array<CurrencyView>}
     * @memberof WebConfigModel
     */
    supportedCurrencies?: Array<CurrencyView> | null;
    /**
     * 
     * @type {string}
     * @memberof WebConfigModel
     */
    defaultLanguage?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebConfigModel
     */
    allowedLanguages?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof WebConfigModel
     */
    enableWhitelabelFeatures: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebConfigModel
     */
    enablePocFeatures: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebConfigModel
     */
    enablePurchasesImport: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebConfigModel
     */
    enableProductsImport: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebConfigModel
     */
    enableChangeLog: boolean;
    /**
     * 
     * @type {ClientApps}
     * @memberof WebConfigModel
     */
    clientApps?: ClientApps | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof WebConfigModel
     */
    defaultDeepLinkPayload?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface WorldHeatmapMarkerView
 */
export interface WorldHeatmapMarkerView {
    /**
     * 
     * @type {number}
     * @memberof WorldHeatmapMarkerView
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WorldHeatmapMarkerView
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorldHeatmapMarkerView
     */
    nmVisits: number;
    /**
     * 
     * @type {number}
     * @memberof WorldHeatmapMarkerView
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof WorldHeatmapMarkerView
     */
    longitude: number;
}

/**
 * AccessControlApi - axios parameter creator
 * @export
 */
export const AccessControlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/access-control/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessControlApi - functional programming interface
 * @export
 */
export const AccessControlApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessControlApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccessControlApi - factory interface
 * @export
 */
export const AccessControlApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessControlApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissions(options?: any): AxiosPromise<UserPermissions> {
            return localVarFp.getPermissions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccessControlApi - object-oriented interface
 * @export
 * @class AccessControlApi
 * @extends {BaseAPI}
 */
export class AccessControlApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControlApi
     */
    public getPermissions(options?: any) {
        return AccessControlApiFp(this.configuration).getPermissions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppDownloadUsers: async (skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/app-downloads-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns ratio of new users for given date range vs active users for the same date range.  Active user is user who either opened the app or read, clicked or redeemed a push notification after joining.  Values will be percentage from 0 to 1.
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppInteractionRate: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/appInteractionRate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {AppInteractionUsersFilter} [filter] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppInteractionUsers: async (skip?: number, limit?: number, filter?: AppInteractionUsersFilter, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/app-interaction-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppOpenUsers: async (skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/app-open-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppRegistrationUsers: async (skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/app-registrations-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageTransactionQuantity: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/averageTransactionQuantity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageTransactionValue: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/averageTransactionValue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ratio of delivered vouchers compared to redeemed vouchers.  Returns percentage from 0 to 1.
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvgRedemptionRate: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/avgRedemptionRate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns average shopping time in seconds.
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {boolean} [includeTrend] Default: False
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvgShoppingTime: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, includeTrend?: boolean, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/avgShoppingTime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (includeTrend !== undefined) {
                localVarQueryParameter['includeTrend'] = includeTrend;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvgTransactionQuantityUsers: async (skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/avg-transaction-quantity-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvgTransactionValueUsers: async (skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/avg-transaction-value-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversionStatistics: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/conversion-statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConvertedUsers: async (skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/converted-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {boolean} [includeTrend] Default: False
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloads: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, includeTrend?: boolean, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/touchpoints/downloads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (includeTrend !== undefined) {
                localVarQueryParameter['includeTrend'] = includeTrend;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInteractionUpliftStatistics: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/interactionUpliftStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {boolean} [includeTrend] Default: False
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogins: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, includeTrend?: boolean, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/touchpoints/logins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (includeTrend !== undefined) {
                localVarQueryParameter['includeTrend'] = includeTrend;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoyalCustomersCount: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/loyalCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMostActiveCustomersCount: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/mostActiveCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewCustomers: async (skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/new-app-customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewCustomersCount: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/newCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {boolean} [includeTrend] Default: False
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenings: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, includeTrend?: boolean, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/touchpoints/openings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (includeTrend !== undefined) {
                localVarQueryParameter['includeTrend'] = includeTrend;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {RedemptionUsersFilter} [filter] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedemptionUsers: async (skip?: number, limit?: number, filter?: RedemptionUsersFilter, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/redemption-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {boolean} [includeTrend] Default: False
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrations: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, includeTrend?: boolean, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/touchpoints/registrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (includeTrend !== undefined) {
                localVarQueryParameter['includeTrend'] = includeTrend;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationsByDevice: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/touchpoints/registrations-by-device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesConversionRate: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/salesConversionRate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {SalesConversionUsersFilter} [filter] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesConversionUsers: async (skip?: number, limit?: number, filter?: SalesConversionUsersFilter, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/sales-conversion-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesPerDay: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/sales/per-day`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingStatistics: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/shoppingStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreVisitsUplift: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/storeVisitsUplift`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeSpentUsers: async (skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/time-spent-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalAppCustomers: async (skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/total-app-customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalAppUsers: async (skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/total-app-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalCustomersCount: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/totalCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalUsersCount: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/totalUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTouchpointsStatistics: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/touchpoints/frequency-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTraffic: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/traffic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpliftStatistics: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/upliftStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVipCustomersCount: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/vipCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitorStatistics: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/visitorStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitorsPerDay: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/visitors/per-day`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppDownloadUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppDownloadUserViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppDownloadUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns ratio of new users for given date range vs active users for the same date range.  Active user is user who either opened the app or read, clicked or redeemed a push notification after joining.  Values will be percentage from 0 to 1.
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppInteractionRate(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviousCurrentValueView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppInteractionRate(rangeType, rangeStart, rangeEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {AppInteractionUsersFilter} [filter] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppInteractionUsers(skip?: number, limit?: number, filter?: AppInteractionUsersFilter, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppInteractionUserViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppInteractionUsers(skip, limit, filter, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppOpenUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppOpenUserViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppOpenUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppRegistrationUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppRegistrationUserViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppRegistrationUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageTransactionQuantity(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviousCurrentValueView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageTransactionQuantity(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageTransactionValue(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviousCurrentValueView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageTransactionValue(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ratio of delivered vouchers compared to redeemed vouchers.  Returns percentage from 0 to 1.
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvgRedemptionRate(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviousCurrentValueView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvgRedemptionRate(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns average shopping time in seconds.
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {boolean} [includeTrend] Default: False
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvgShoppingTime(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, includeTrend?: boolean, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviousCurrentValueView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvgShoppingTime(rangeType, rangeStart, rangeEnd, includeTrend, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvgTransactionQuantityUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvgTransactionQuantityUserViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvgTransactionQuantityUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvgTransactionValueUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvgTransactionValueUserViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvgTransactionValueUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversionStatistics(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversionStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversionStatistics(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConvertedUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConvertedUserViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConvertedUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {boolean} [includeTrend] Default: False
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownloads(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, includeTrend?: boolean, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviousCurrentValueView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloads(rangeType, rangeStart, rangeEnd, includeTrend, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInteractionUpliftStatistics(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppInteractionUpliftStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInteractionUpliftStatistics(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {boolean} [includeTrend] Default: False
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogins(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, includeTrend?: boolean, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviousCurrentValueView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogins(rangeType, rangeStart, rangeEnd, includeTrend, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoyalCustomersCount(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoyalCustomersCount(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMostActiveCustomersCount(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMostActiveCustomersCount(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewCustomers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewCustomerViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewCustomers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewCustomersCount(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewCustomersCount(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {boolean} [includeTrend] Default: False
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpenings(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, includeTrend?: boolean, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviousCurrentValueView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenings(rangeType, rangeStart, rangeEnd, includeTrend, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {RedemptionUsersFilter} [filter] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRedemptionUsers(skip?: number, limit?: number, filter?: RedemptionUsersFilter, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedemptionUserViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRedemptionUsers(skip, limit, filter, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {boolean} [includeTrend] Default: False
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistrations(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, includeTrend?: boolean, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviousCurrentValueView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegistrations(rangeType, rangeStart, rangeEnd, includeTrend, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistrationsByDevice(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegistrationsByDevice(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesConversionRate(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviousCurrentValueView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesConversionRate(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {SalesConversionUsersFilter} [filter] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesConversionUsers(skip?: number, limit?: number, filter?: SalesConversionUsersFilter, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesConversionUserViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesConversionUsers(skip, limit, filter, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesPerDay(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SalePerDayView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesPerDay(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShoppingStatistics(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingStatisticsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShoppingStatistics(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreVisitsUplift(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreVisitsUplift(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeSpentUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSpentUserViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeSpentUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalAppCustomers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalAppCustomerViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalAppCustomers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalAppUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalAppUserViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalAppUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalCustomersCount(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalCustomersCount(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalUsersCount(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalUsersCount(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTouchpointsStatistics(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TouchpointsStatisticsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTouchpointsStatistics(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTraffic(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrafficView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTraffic(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUpliftStatistics(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppUpliftStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUpliftStatistics(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVipCustomersCount(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVipCustomersCount(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitorStatistics(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitorStatisticsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitorStatistics(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitorsPerDay(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VisitorPerDayView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitorsPerDay(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppDownloadUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<AppDownloadUserViewDataPage> {
            return localVarFp.getAppDownloadUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns ratio of new users for given date range vs active users for the same date range.  Active user is user who either opened the app or read, clicked or redeemed a push notification after joining.  Values will be percentage from 0 to 1.
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppInteractionRate(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, options?: any): AxiosPromise<PreviousCurrentValueView> {
            return localVarFp.getAppInteractionRate(rangeType, rangeStart, rangeEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {AppInteractionUsersFilter} [filter] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppInteractionUsers(skip?: number, limit?: number, filter?: AppInteractionUsersFilter, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<AppInteractionUserViewDataPage> {
            return localVarFp.getAppInteractionUsers(skip, limit, filter, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppOpenUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<AppOpenUserViewDataPage> {
            return localVarFp.getAppOpenUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppRegistrationUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<AppRegistrationUserViewDataPage> {
            return localVarFp.getAppRegistrationUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageTransactionQuantity(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<PreviousCurrentValueView> {
            return localVarFp.getAverageTransactionQuantity(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageTransactionValue(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<PreviousCurrentValueView> {
            return localVarFp.getAverageTransactionValue(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ratio of delivered vouchers compared to redeemed vouchers.  Returns percentage from 0 to 1.
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvgRedemptionRate(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<PreviousCurrentValueView> {
            return localVarFp.getAvgRedemptionRate(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns average shopping time in seconds.
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {boolean} [includeTrend] Default: False
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvgShoppingTime(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, includeTrend?: boolean, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<PreviousCurrentValueView> {
            return localVarFp.getAvgShoppingTime(rangeType, rangeStart, rangeEnd, includeTrend, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvgTransactionQuantityUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<AvgTransactionQuantityUserViewDataPage> {
            return localVarFp.getAvgTransactionQuantityUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvgTransactionValueUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<AvgTransactionValueUserViewDataPage> {
            return localVarFp.getAvgTransactionValueUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversionStatistics(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<ConversionStatistics> {
            return localVarFp.getConversionStatistics(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConvertedUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<ConvertedUserViewDataPage> {
            return localVarFp.getConvertedUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {boolean} [includeTrend] Default: False
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloads(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, includeTrend?: boolean, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<PreviousCurrentValueView> {
            return localVarFp.getDownloads(rangeType, rangeStart, rangeEnd, includeTrend, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInteractionUpliftStatistics(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<AppInteractionUpliftStatistics> {
            return localVarFp.getInteractionUpliftStatistics(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {boolean} [includeTrend] Default: False
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogins(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, includeTrend?: boolean, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<PreviousCurrentValueView> {
            return localVarFp.getLogins(rangeType, rangeStart, rangeEnd, includeTrend, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoyalCustomersCount(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.getLoyalCustomersCount(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMostActiveCustomersCount(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.getMostActiveCustomersCount(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewCustomers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<NewCustomerViewDataPage> {
            return localVarFp.getNewCustomers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewCustomersCount(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.getNewCustomersCount(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {boolean} [includeTrend] Default: False
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenings(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, includeTrend?: boolean, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<PreviousCurrentValueView> {
            return localVarFp.getOpenings(rangeType, rangeStart, rangeEnd, includeTrend, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {RedemptionUsersFilter} [filter] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedemptionUsers(skip?: number, limit?: number, filter?: RedemptionUsersFilter, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<RedemptionUserViewDataPage> {
            return localVarFp.getRedemptionUsers(skip, limit, filter, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {boolean} [includeTrend] Default: False
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrations(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, includeTrend?: boolean, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<PreviousCurrentValueView> {
            return localVarFp.getRegistrations(rangeType, rangeStart, rangeEnd, includeTrend, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationsByDevice(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<RegistrationsView> {
            return localVarFp.getRegistrationsByDevice(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesConversionRate(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<PreviousCurrentValueView> {
            return localVarFp.getSalesConversionRate(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {SalesConversionUsersFilter} [filter] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesConversionUsers(skip?: number, limit?: number, filter?: SalesConversionUsersFilter, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<SalesConversionUserViewDataPage> {
            return localVarFp.getSalesConversionUsers(skip, limit, filter, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesPerDay(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<Array<SalePerDayView>> {
            return localVarFp.getSalesPerDay(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingStatistics(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<ShoppingStatisticsView> {
            return localVarFp.getShoppingStatistics(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreVisitsUplift(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.getStoreVisitsUplift(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeSpentUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<TimeSpentUserViewDataPage> {
            return localVarFp.getTimeSpentUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalAppCustomers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<TotalAppCustomerViewDataPage> {
            return localVarFp.getTotalAppCustomers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalAppUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<TotalAppUserViewDataPage> {
            return localVarFp.getTotalAppUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalCustomersCount(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.getTotalCustomersCount(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalUsersCount(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.getTotalUsersCount(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTouchpointsStatistics(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<TouchpointsStatisticsView> {
            return localVarFp.getTouchpointsStatistics(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTraffic(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<TrafficView> {
            return localVarFp.getTraffic(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpliftStatistics(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<AppUpliftStatistics> {
            return localVarFp.getUpliftStatistics(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVipCustomersCount(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.getVipCustomersCount(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitorStatistics(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<VisitorStatisticsView> {
            return localVarFp.getVisitorStatistics(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitorsPerDay(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<Array<VisitorPerDayView>> {
            return localVarFp.getVisitorsPerDay(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAppDownloadUsers operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetAppDownloadUsersRequest
 */
export interface AnalyticsApiGetAppDownloadUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetAppDownloadUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetAppDownloadUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetAppDownloadUsers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAppDownloadUsers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAppDownloadUsers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAppDownloadUsers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAppDownloadUsers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAppDownloadUsers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAppDownloadUsers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetAppDownloadUsers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getAppInteractionRate operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetAppInteractionRateRequest
 */
export interface AnalyticsApiGetAppInteractionRateRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetAppInteractionRate
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAppInteractionRate
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAppInteractionRate
     */
    readonly rangeEnd?: IsoDateTimeString
}

/**
 * Request parameters for getAppInteractionUsers operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetAppInteractionUsersRequest
 */
export interface AnalyticsApiGetAppInteractionUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetAppInteractionUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetAppInteractionUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {AppInteractionUsersFilter}
     * @memberof AnalyticsApiGetAppInteractionUsers
     */
    readonly filter?: AppInteractionUsersFilter

    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetAppInteractionUsers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAppInteractionUsers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAppInteractionUsers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAppInteractionUsers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAppInteractionUsers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAppInteractionUsers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAppInteractionUsers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetAppInteractionUsers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getAppOpenUsers operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetAppOpenUsersRequest
 */
export interface AnalyticsApiGetAppOpenUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetAppOpenUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetAppOpenUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetAppOpenUsers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAppOpenUsers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAppOpenUsers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAppOpenUsers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAppOpenUsers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAppOpenUsers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAppOpenUsers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetAppOpenUsers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getAppRegistrationUsers operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetAppRegistrationUsersRequest
 */
export interface AnalyticsApiGetAppRegistrationUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetAppRegistrationUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetAppRegistrationUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetAppRegistrationUsers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAppRegistrationUsers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAppRegistrationUsers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAppRegistrationUsers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAppRegistrationUsers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAppRegistrationUsers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAppRegistrationUsers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetAppRegistrationUsers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getAverageTransactionQuantity operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetAverageTransactionQuantityRequest
 */
export interface AnalyticsApiGetAverageTransactionQuantityRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetAverageTransactionQuantity
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAverageTransactionQuantity
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAverageTransactionQuantity
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAverageTransactionQuantity
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAverageTransactionQuantity
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAverageTransactionQuantity
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAverageTransactionQuantity
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetAverageTransactionQuantity
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getAverageTransactionValue operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetAverageTransactionValueRequest
 */
export interface AnalyticsApiGetAverageTransactionValueRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetAverageTransactionValue
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAverageTransactionValue
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAverageTransactionValue
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAverageTransactionValue
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAverageTransactionValue
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAverageTransactionValue
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAverageTransactionValue
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetAverageTransactionValue
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getAvgRedemptionRate operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetAvgRedemptionRateRequest
 */
export interface AnalyticsApiGetAvgRedemptionRateRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetAvgRedemptionRate
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAvgRedemptionRate
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAvgRedemptionRate
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAvgRedemptionRate
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAvgRedemptionRate
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAvgRedemptionRate
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAvgRedemptionRate
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetAvgRedemptionRate
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getAvgShoppingTime operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetAvgShoppingTimeRequest
 */
export interface AnalyticsApiGetAvgShoppingTimeRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetAvgShoppingTime
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAvgShoppingTime
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAvgShoppingTime
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * Default: False
     * @type {boolean}
     * @memberof AnalyticsApiGetAvgShoppingTime
     */
    readonly includeTrend?: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAvgShoppingTime
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAvgShoppingTime
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAvgShoppingTime
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAvgShoppingTime
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetAvgShoppingTime
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getAvgTransactionQuantityUsers operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetAvgTransactionQuantityUsersRequest
 */
export interface AnalyticsApiGetAvgTransactionQuantityUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetAvgTransactionQuantityUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetAvgTransactionQuantityUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetAvgTransactionQuantityUsers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAvgTransactionQuantityUsers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAvgTransactionQuantityUsers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAvgTransactionQuantityUsers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAvgTransactionQuantityUsers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAvgTransactionQuantityUsers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAvgTransactionQuantityUsers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetAvgTransactionQuantityUsers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getAvgTransactionValueUsers operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetAvgTransactionValueUsersRequest
 */
export interface AnalyticsApiGetAvgTransactionValueUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetAvgTransactionValueUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetAvgTransactionValueUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetAvgTransactionValueUsers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAvgTransactionValueUsers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetAvgTransactionValueUsers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAvgTransactionValueUsers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAvgTransactionValueUsers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAvgTransactionValueUsers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetAvgTransactionValueUsers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetAvgTransactionValueUsers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getConversionStatistics operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetConversionStatisticsRequest
 */
export interface AnalyticsApiGetConversionStatisticsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetConversionStatistics
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetConversionStatistics
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetConversionStatistics
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetConversionStatistics
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetConversionStatistics
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetConversionStatistics
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetConversionStatistics
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetConversionStatistics
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getConvertedUsers operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetConvertedUsersRequest
 */
export interface AnalyticsApiGetConvertedUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetConvertedUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetConvertedUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetConvertedUsers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetConvertedUsers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetConvertedUsers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetConvertedUsers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetConvertedUsers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetConvertedUsers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetConvertedUsers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetConvertedUsers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getDownloads operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetDownloadsRequest
 */
export interface AnalyticsApiGetDownloadsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetDownloads
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetDownloads
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetDownloads
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * Default: False
     * @type {boolean}
     * @memberof AnalyticsApiGetDownloads
     */
    readonly includeTrend?: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetDownloads
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetDownloads
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetDownloads
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetDownloads
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetDownloads
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getInteractionUpliftStatistics operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetInteractionUpliftStatisticsRequest
 */
export interface AnalyticsApiGetInteractionUpliftStatisticsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetInteractionUpliftStatistics
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetInteractionUpliftStatistics
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetInteractionUpliftStatistics
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetInteractionUpliftStatistics
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetInteractionUpliftStatistics
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetInteractionUpliftStatistics
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetInteractionUpliftStatistics
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetInteractionUpliftStatistics
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getLogins operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetLoginsRequest
 */
export interface AnalyticsApiGetLoginsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetLogins
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetLogins
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetLogins
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * Default: False
     * @type {boolean}
     * @memberof AnalyticsApiGetLogins
     */
    readonly includeTrend?: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetLogins
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetLogins
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetLogins
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetLogins
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetLogins
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getLoyalCustomersCount operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetLoyalCustomersCountRequest
 */
export interface AnalyticsApiGetLoyalCustomersCountRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetLoyalCustomersCount
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetLoyalCustomersCount
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetLoyalCustomersCount
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetLoyalCustomersCount
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetLoyalCustomersCount
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetLoyalCustomersCount
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetLoyalCustomersCount
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetLoyalCustomersCount
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getMostActiveCustomersCount operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetMostActiveCustomersCountRequest
 */
export interface AnalyticsApiGetMostActiveCustomersCountRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetMostActiveCustomersCount
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetMostActiveCustomersCount
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetMostActiveCustomersCount
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetMostActiveCustomersCount
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetMostActiveCustomersCount
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetMostActiveCustomersCount
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetMostActiveCustomersCount
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetMostActiveCustomersCount
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getNewCustomers operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetNewCustomersRequest
 */
export interface AnalyticsApiGetNewCustomersRequest {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetNewCustomers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetNewCustomers
     */
    readonly limit?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetNewCustomers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetNewCustomers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetNewCustomers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetNewCustomers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetNewCustomers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetNewCustomers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetNewCustomers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetNewCustomers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getNewCustomersCount operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetNewCustomersCountRequest
 */
export interface AnalyticsApiGetNewCustomersCountRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetNewCustomersCount
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetNewCustomersCount
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetNewCustomersCount
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetNewCustomersCount
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetNewCustomersCount
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetNewCustomersCount
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetNewCustomersCount
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetNewCustomersCount
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getOpenings operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetOpeningsRequest
 */
export interface AnalyticsApiGetOpeningsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetOpenings
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetOpenings
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetOpenings
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * Default: False
     * @type {boolean}
     * @memberof AnalyticsApiGetOpenings
     */
    readonly includeTrend?: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetOpenings
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetOpenings
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetOpenings
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetOpenings
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetOpenings
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getRedemptionUsers operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetRedemptionUsersRequest
 */
export interface AnalyticsApiGetRedemptionUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetRedemptionUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetRedemptionUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {RedemptionUsersFilter}
     * @memberof AnalyticsApiGetRedemptionUsers
     */
    readonly filter?: RedemptionUsersFilter

    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetRedemptionUsers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetRedemptionUsers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetRedemptionUsers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetRedemptionUsers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetRedemptionUsers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetRedemptionUsers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetRedemptionUsers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetRedemptionUsers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getRegistrations operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetRegistrationsRequest
 */
export interface AnalyticsApiGetRegistrationsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetRegistrations
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetRegistrations
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetRegistrations
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * Default: False
     * @type {boolean}
     * @memberof AnalyticsApiGetRegistrations
     */
    readonly includeTrend?: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetRegistrations
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetRegistrations
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetRegistrations
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetRegistrations
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetRegistrations
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getRegistrationsByDevice operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetRegistrationsByDeviceRequest
 */
export interface AnalyticsApiGetRegistrationsByDeviceRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetRegistrationsByDevice
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetRegistrationsByDevice
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetRegistrationsByDevice
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetRegistrationsByDevice
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetRegistrationsByDevice
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetRegistrationsByDevice
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetRegistrationsByDevice
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetRegistrationsByDevice
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getSalesConversionRate operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetSalesConversionRateRequest
 */
export interface AnalyticsApiGetSalesConversionRateRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetSalesConversionRate
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetSalesConversionRate
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetSalesConversionRate
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetSalesConversionRate
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetSalesConversionRate
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetSalesConversionRate
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetSalesConversionRate
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetSalesConversionRate
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getSalesConversionUsers operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetSalesConversionUsersRequest
 */
export interface AnalyticsApiGetSalesConversionUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetSalesConversionUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetSalesConversionUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {SalesConversionUsersFilter}
     * @memberof AnalyticsApiGetSalesConversionUsers
     */
    readonly filter?: SalesConversionUsersFilter

    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetSalesConversionUsers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetSalesConversionUsers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetSalesConversionUsers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetSalesConversionUsers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetSalesConversionUsers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetSalesConversionUsers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetSalesConversionUsers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetSalesConversionUsers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getSalesPerDay operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetSalesPerDayRequest
 */
export interface AnalyticsApiGetSalesPerDayRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetSalesPerDay
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetSalesPerDay
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetSalesPerDay
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetSalesPerDay
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetSalesPerDay
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetSalesPerDay
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetSalesPerDay
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetSalesPerDay
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getShoppingStatistics operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetShoppingStatisticsRequest
 */
export interface AnalyticsApiGetShoppingStatisticsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetShoppingStatistics
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetShoppingStatistics
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetShoppingStatistics
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetShoppingStatistics
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetShoppingStatistics
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetShoppingStatistics
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetShoppingStatistics
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetShoppingStatistics
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getStoreVisitsUplift operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetStoreVisitsUpliftRequest
 */
export interface AnalyticsApiGetStoreVisitsUpliftRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetStoreVisitsUplift
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetStoreVisitsUplift
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetStoreVisitsUplift
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetStoreVisitsUplift
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetStoreVisitsUplift
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetStoreVisitsUplift
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetStoreVisitsUplift
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetStoreVisitsUplift
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getTimeSpentUsers operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetTimeSpentUsersRequest
 */
export interface AnalyticsApiGetTimeSpentUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetTimeSpentUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetTimeSpentUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetTimeSpentUsers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetTimeSpentUsers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetTimeSpentUsers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTimeSpentUsers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTimeSpentUsers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTimeSpentUsers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTimeSpentUsers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetTimeSpentUsers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getTotalAppCustomers operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetTotalAppCustomersRequest
 */
export interface AnalyticsApiGetTotalAppCustomersRequest {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetTotalAppCustomers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetTotalAppCustomers
     */
    readonly limit?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetTotalAppCustomers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetTotalAppCustomers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetTotalAppCustomers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTotalAppCustomers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTotalAppCustomers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTotalAppCustomers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTotalAppCustomers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetTotalAppCustomers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getTotalAppUsers operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetTotalAppUsersRequest
 */
export interface AnalyticsApiGetTotalAppUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetTotalAppUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AnalyticsApiGetTotalAppUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetTotalAppUsers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetTotalAppUsers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetTotalAppUsers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTotalAppUsers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTotalAppUsers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTotalAppUsers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTotalAppUsers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetTotalAppUsers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getTotalCustomersCount operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetTotalCustomersCountRequest
 */
export interface AnalyticsApiGetTotalCustomersCountRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetTotalCustomersCount
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetTotalCustomersCount
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetTotalCustomersCount
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTotalCustomersCount
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTotalCustomersCount
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTotalCustomersCount
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTotalCustomersCount
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetTotalCustomersCount
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getTotalUsersCount operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetTotalUsersCountRequest
 */
export interface AnalyticsApiGetTotalUsersCountRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetTotalUsersCount
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetTotalUsersCount
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetTotalUsersCount
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTotalUsersCount
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTotalUsersCount
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTotalUsersCount
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTotalUsersCount
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetTotalUsersCount
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getTouchpointsStatistics operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetTouchpointsStatisticsRequest
 */
export interface AnalyticsApiGetTouchpointsStatisticsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetTouchpointsStatistics
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetTouchpointsStatistics
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetTouchpointsStatistics
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTouchpointsStatistics
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTouchpointsStatistics
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTouchpointsStatistics
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTouchpointsStatistics
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetTouchpointsStatistics
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getTraffic operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetTrafficRequest
 */
export interface AnalyticsApiGetTrafficRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetTraffic
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetTraffic
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetTraffic
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTraffic
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTraffic
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTraffic
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetTraffic
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetTraffic
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getUpliftStatistics operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetUpliftStatisticsRequest
 */
export interface AnalyticsApiGetUpliftStatisticsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetUpliftStatistics
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetUpliftStatistics
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetUpliftStatistics
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetUpliftStatistics
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetUpliftStatistics
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetUpliftStatistics
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetUpliftStatistics
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetUpliftStatistics
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getVipCustomersCount operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetVipCustomersCountRequest
 */
export interface AnalyticsApiGetVipCustomersCountRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetVipCustomersCount
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetVipCustomersCount
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetVipCustomersCount
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetVipCustomersCount
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetVipCustomersCount
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetVipCustomersCount
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetVipCustomersCount
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetVipCustomersCount
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getVisitorStatistics operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetVisitorStatisticsRequest
 */
export interface AnalyticsApiGetVisitorStatisticsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetVisitorStatistics
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetVisitorStatistics
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetVisitorStatistics
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetVisitorStatistics
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetVisitorStatistics
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetVisitorStatistics
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetVisitorStatistics
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetVisitorStatistics
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getVisitorsPerDay operation in AnalyticsApi.
 * @export
 * @interface AnalyticsApiGetVisitorsPerDayRequest
 */
export interface AnalyticsApiGetVisitorsPerDayRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof AnalyticsApiGetVisitorsPerDay
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetVisitorsPerDay
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof AnalyticsApiGetVisitorsPerDay
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetVisitorsPerDay
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetVisitorsPerDay
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetVisitorsPerDay
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyticsApiGetVisitorsPerDay
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsApiGetVisitorsPerDay
     */
    readonly beacons?: Array<string>
}

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI {
    /**
     * 
     * @param {AnalyticsApiGetAppDownloadUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAppDownloadUsers(requestParameters: AnalyticsApiGetAppDownloadUsersRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getAppDownloadUsers(requestParameters.skip, requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns ratio of new users for given date range vs active users for the same date range.  Active user is user who either opened the app or read, clicked or redeemed a push notification after joining.  Values will be percentage from 0 to 1.
     * @param {AnalyticsApiGetAppInteractionRateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAppInteractionRate(requestParameters: AnalyticsApiGetAppInteractionRateRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getAppInteractionRate(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetAppInteractionUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAppInteractionUsers(requestParameters: AnalyticsApiGetAppInteractionUsersRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getAppInteractionUsers(requestParameters.skip, requestParameters.limit, requestParameters.filter, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetAppOpenUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAppOpenUsers(requestParameters: AnalyticsApiGetAppOpenUsersRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getAppOpenUsers(requestParameters.skip, requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetAppRegistrationUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAppRegistrationUsers(requestParameters: AnalyticsApiGetAppRegistrationUsersRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getAppRegistrationUsers(requestParameters.skip, requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetAverageTransactionQuantityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAverageTransactionQuantity(requestParameters: AnalyticsApiGetAverageTransactionQuantityRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getAverageTransactionQuantity(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetAverageTransactionValueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAverageTransactionValue(requestParameters: AnalyticsApiGetAverageTransactionValueRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getAverageTransactionValue(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ratio of delivered vouchers compared to redeemed vouchers.  Returns percentage from 0 to 1.
     * @param {AnalyticsApiGetAvgRedemptionRateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAvgRedemptionRate(requestParameters: AnalyticsApiGetAvgRedemptionRateRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getAvgRedemptionRate(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns average shopping time in seconds.
     * @param {AnalyticsApiGetAvgShoppingTimeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAvgShoppingTime(requestParameters: AnalyticsApiGetAvgShoppingTimeRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getAvgShoppingTime(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.includeTrend, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetAvgTransactionQuantityUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAvgTransactionQuantityUsers(requestParameters: AnalyticsApiGetAvgTransactionQuantityUsersRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getAvgTransactionQuantityUsers(requestParameters.skip, requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetAvgTransactionValueUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAvgTransactionValueUsers(requestParameters: AnalyticsApiGetAvgTransactionValueUsersRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getAvgTransactionValueUsers(requestParameters.skip, requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetConversionStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getConversionStatistics(requestParameters: AnalyticsApiGetConversionStatisticsRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getConversionStatistics(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetConvertedUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getConvertedUsers(requestParameters: AnalyticsApiGetConvertedUsersRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getConvertedUsers(requestParameters.skip, requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetDownloadsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getDownloads(requestParameters: AnalyticsApiGetDownloadsRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getDownloads(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.includeTrend, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetInteractionUpliftStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getInteractionUpliftStatistics(requestParameters: AnalyticsApiGetInteractionUpliftStatisticsRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getInteractionUpliftStatistics(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetLoginsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getLogins(requestParameters: AnalyticsApiGetLoginsRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getLogins(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.includeTrend, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetLoyalCustomersCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getLoyalCustomersCount(requestParameters: AnalyticsApiGetLoyalCustomersCountRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getLoyalCustomersCount(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetMostActiveCustomersCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getMostActiveCustomersCount(requestParameters: AnalyticsApiGetMostActiveCustomersCountRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getMostActiveCustomersCount(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetNewCustomersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getNewCustomers(requestParameters: AnalyticsApiGetNewCustomersRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getNewCustomers(requestParameters.skip, requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetNewCustomersCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getNewCustomersCount(requestParameters: AnalyticsApiGetNewCustomersCountRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getNewCustomersCount(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetOpeningsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getOpenings(requestParameters: AnalyticsApiGetOpeningsRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getOpenings(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.includeTrend, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetRedemptionUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getRedemptionUsers(requestParameters: AnalyticsApiGetRedemptionUsersRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getRedemptionUsers(requestParameters.skip, requestParameters.limit, requestParameters.filter, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetRegistrationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getRegistrations(requestParameters: AnalyticsApiGetRegistrationsRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getRegistrations(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.includeTrend, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetRegistrationsByDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getRegistrationsByDevice(requestParameters: AnalyticsApiGetRegistrationsByDeviceRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getRegistrationsByDevice(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetSalesConversionRateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getSalesConversionRate(requestParameters: AnalyticsApiGetSalesConversionRateRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getSalesConversionRate(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetSalesConversionUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getSalesConversionUsers(requestParameters: AnalyticsApiGetSalesConversionUsersRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getSalesConversionUsers(requestParameters.skip, requestParameters.limit, requestParameters.filter, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetSalesPerDayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getSalesPerDay(requestParameters: AnalyticsApiGetSalesPerDayRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getSalesPerDay(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetShoppingStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getShoppingStatistics(requestParameters: AnalyticsApiGetShoppingStatisticsRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getShoppingStatistics(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetStoreVisitsUpliftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getStoreVisitsUplift(requestParameters: AnalyticsApiGetStoreVisitsUpliftRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getStoreVisitsUplift(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetTimeSpentUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getTimeSpentUsers(requestParameters: AnalyticsApiGetTimeSpentUsersRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getTimeSpentUsers(requestParameters.skip, requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetTotalAppCustomersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getTotalAppCustomers(requestParameters: AnalyticsApiGetTotalAppCustomersRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getTotalAppCustomers(requestParameters.skip, requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetTotalAppUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getTotalAppUsers(requestParameters: AnalyticsApiGetTotalAppUsersRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getTotalAppUsers(requestParameters.skip, requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetTotalCustomersCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getTotalCustomersCount(requestParameters: AnalyticsApiGetTotalCustomersCountRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getTotalCustomersCount(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetTotalUsersCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getTotalUsersCount(requestParameters: AnalyticsApiGetTotalUsersCountRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getTotalUsersCount(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetTouchpointsStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getTouchpointsStatistics(requestParameters: AnalyticsApiGetTouchpointsStatisticsRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getTouchpointsStatistics(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetTrafficRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getTraffic(requestParameters: AnalyticsApiGetTrafficRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getTraffic(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetUpliftStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getUpliftStatistics(requestParameters: AnalyticsApiGetUpliftStatisticsRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getUpliftStatistics(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetVipCustomersCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getVipCustomersCount(requestParameters: AnalyticsApiGetVipCustomersCountRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getVipCustomersCount(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetVisitorStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getVisitorStatistics(requestParameters: AnalyticsApiGetVisitorStatisticsRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getVisitorStatistics(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsApiGetVisitorsPerDayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getVisitorsPerDay(requestParameters: AnalyticsApiGetVisitorsPerDayRequest = {}, options?: any) {
        return AnalyticsApiFp(this.configuration).getVisitorsPerDay(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AppSigningApi - axios parameter creator
 * @export
 */
export const AppSigningApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAndroidAssetLinks: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/.well-known/assetlinks.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppleAppSiteAssociation: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/.well-known/apple-app-site-association`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppSigningApi - functional programming interface
 * @export
 */
export const AppSigningApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppSigningApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAndroidAssetLinks(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAndroidAssetLinks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppleAppSiteAssociation(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppleAppSiteAssociation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppSigningApi - factory interface
 * @export
 */
export const AppSigningApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppSigningApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAndroidAssetLinks(options?: any): AxiosPromise<void> {
            return localVarFp.getAndroidAssetLinks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppleAppSiteAssociation(options?: any): AxiosPromise<void> {
            return localVarFp.getAppleAppSiteAssociation(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppSigningApi - object-oriented interface
 * @export
 * @class AppSigningApi
 * @extends {BaseAPI}
 */
export class AppSigningApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSigningApi
     */
    public getAndroidAssetLinks(options?: any) {
        return AppSigningApiFp(this.configuration).getAndroidAssetLinks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSigningApi
     */
    public getAppleAppSiteAssociation(options?: any) {
        return AppSigningApiFp(this.configuration).getAppleAppSiteAssociation(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [grantType] 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        token: async (grantType?: string, username?: string, password?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [grantType] 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async token(grantType?: string, username?: string, password?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.token(grantType, username, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [grantType] 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        token(grantType?: string, username?: string, password?: string, options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.token(grantType, username, password, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for token operation in AuthApi.
 * @export
 * @interface AuthApiTokenRequest
 */
export interface AuthApiTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiToken
     */
    readonly grantType?: string

    /**
     * 
     * @type {string}
     * @memberof AuthApiToken
     */
    readonly username?: string

    /**
     * 
     * @type {string}
     * @memberof AuthApiToken
     */
    readonly password?: string
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {AuthApiTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public token(requestParameters: AuthApiTokenRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).token(requestParameters.grantType, requestParameters.username, requestParameters.password, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BeaconsApi - axios parameter creator
 * @export
 */
export const BeaconsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} beaconId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (beaconId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'beaconId' is not null or undefined
            assertParamExists('_delete', 'beaconId', beaconId)
            const localVarPath = `/api/v2/beacons/{beaconId}`
                .replace(`{${"beaconId"}}`, encodeURIComponent(String(beaconId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BeaconModel} [beaconModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (beaconModel?: BeaconModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/beacons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(beaconModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {string} [search] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, search?: string, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/beacons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAreas: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/beacons/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBeaconVisitsStatistics: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/beacons/beacon-visits/stats-by-minute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByAverageTimeSpent: async (size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/beacons/by-average-time/top`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByMaximumTimeSpent: async (size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/beacons/by-maximum-time/top`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByMinimumTimeSpent: async (size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/beacons/by-minimum-time/top`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [storeId] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForLookup: async (storeId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/beacons/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} beaconId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne: async (beaconId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'beaconId' is not null or undefined
            assertParamExists('getOne', 'beaconId', beaconId)
            const localVarPath = `/api/v2/beacons/{beaconId}`
                .replace(`{${"beaconId"}}`, encodeURIComponent(String(beaconId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return area ordered by beacon contacts in given period for given location.
         * @param {number} size 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopAreas: async (size: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getTopAreas', 'size', size)
            const localVarPath = `/api/v2/beacons/areas/by-beacon-contacts/top-{size}`
                .replace(`{${"size"}}`, encodeURIComponent(String(size)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWarnings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/beacons/warnings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} beaconId 
         * @param {BeaconModel} [beaconModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (beaconId: string, beaconModel?: BeaconModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'beaconId' is not null or undefined
            assertParamExists('update', 'beaconId', beaconId)
            const localVarPath = `/api/v2/beacons/{beaconId}`
                .replace(`{${"beaconId"}}`, encodeURIComponent(String(beaconId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(beaconModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BeaconsApi - functional programming interface
 * @export
 */
export const BeaconsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BeaconsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} beaconId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(beaconId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(beaconId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BeaconModel} [beaconModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(beaconModel?: BeaconModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeaconModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(beaconModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {string} [search] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, search?: string, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeaconViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(stores, countries, cities, areas, beacons, search, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAreas(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AreaView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAreas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBeaconVisitsStatistics(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeaconVisitsStatisticsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBeaconVisitsStatistics(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByAverageTimeSpent(size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopBeaconViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByAverageTimeSpent(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByMaximumTimeSpent(size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopBeaconViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByMaximumTimeSpent(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByMinimumTimeSpent(size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopBeaconViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByMinimumTimeSpent(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [storeId] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForLookup(storeId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BeaconLookupView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getForLookup(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} beaconId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOne(beaconId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeaconModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOne(beaconId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return area ordered by beacon contacts in given period for given location.
         * @param {number} size 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopAreas(size: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopAreaView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopAreas(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWarnings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeaconWarningModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWarnings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} beaconId 
         * @param {BeaconModel} [beaconModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(beaconId: string, beaconModel?: BeaconModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeaconModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(beaconId, beaconModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BeaconsApi - factory interface
 * @export
 */
export const BeaconsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BeaconsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} beaconId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(beaconId: string, options?: any): AxiosPromise<void> {
            return localVarFp._delete(beaconId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BeaconModel} [beaconModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(beaconModel?: BeaconModel, options?: any): AxiosPromise<BeaconModelServiceResult> {
            return localVarFp.create(beaconModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {string} [search] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, search?: string, skip?: number, limit?: number, options?: any): AxiosPromise<BeaconViewDataPage> {
            return localVarFp.getAll(stores, countries, cities, areas, beacons, search, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAreas(options?: any): AxiosPromise<Array<AreaView>> {
            return localVarFp.getAllAreas(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBeaconVisitsStatistics(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<BeaconVisitsStatisticsView> {
            return localVarFp.getBeaconVisitsStatistics(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByAverageTimeSpent(size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, options?: any): AxiosPromise<TopBeaconViewDataPage> {
            return localVarFp.getByAverageTimeSpent(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByMaximumTimeSpent(size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, options?: any): AxiosPromise<TopBeaconViewDataPage> {
            return localVarFp.getByMaximumTimeSpent(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByMinimumTimeSpent(size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, options?: any): AxiosPromise<TopBeaconViewDataPage> {
            return localVarFp.getByMinimumTimeSpent(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [storeId] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForLookup(storeId?: number, options?: any): AxiosPromise<Array<BeaconLookupView>> {
            return localVarFp.getForLookup(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} beaconId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne(beaconId: string, options?: any): AxiosPromise<BeaconModel> {
            return localVarFp.getOne(beaconId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return area ordered by beacon contacts in given period for given location.
         * @param {number} size 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopAreas(size: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<Array<TopAreaView>> {
            return localVarFp.getTopAreas(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWarnings(options?: any): AxiosPromise<BeaconWarningModel> {
            return localVarFp.getWarnings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} beaconId 
         * @param {BeaconModel} [beaconModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(beaconId: string, beaconModel?: BeaconModel, options?: any): AxiosPromise<BeaconModelServiceResult> {
            return localVarFp.update(beaconId, beaconModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in BeaconsApi.
 * @export
 * @interface BeaconsApiDeleteRequest
 */
export interface BeaconsApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof BeaconsApiDelete
     */
    readonly beaconId: string
}

/**
 * Request parameters for create operation in BeaconsApi.
 * @export
 * @interface BeaconsApiCreateRequest
 */
export interface BeaconsApiCreateRequest {
    /**
     * 
     * @type {BeaconModel}
     * @memberof BeaconsApiCreate
     */
    readonly beaconModel?: BeaconModel
}

/**
 * Request parameters for getAll operation in BeaconsApi.
 * @export
 * @interface BeaconsApiGetAllRequest
 */
export interface BeaconsApiGetAllRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetAll
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetAll
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetAll
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetAll
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof BeaconsApiGetAll
     */
    readonly beacons?: Array<string>

    /**
     * Default: null
     * @type {string}
     * @memberof BeaconsApiGetAll
     */
    readonly search?: string

    /**
     * Default: null
     * @type {number}
     * @memberof BeaconsApiGetAll
     */
    readonly skip?: number

    /**
     * Default: null
     * @type {number}
     * @memberof BeaconsApiGetAll
     */
    readonly limit?: number
}

/**
 * Request parameters for getBeaconVisitsStatistics operation in BeaconsApi.
 * @export
 * @interface BeaconsApiGetBeaconVisitsStatisticsRequest
 */
export interface BeaconsApiGetBeaconVisitsStatisticsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof BeaconsApiGetBeaconVisitsStatistics
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof BeaconsApiGetBeaconVisitsStatistics
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof BeaconsApiGetBeaconVisitsStatistics
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetBeaconVisitsStatistics
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetBeaconVisitsStatistics
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetBeaconVisitsStatistics
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetBeaconVisitsStatistics
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof BeaconsApiGetBeaconVisitsStatistics
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getByAverageTimeSpent operation in BeaconsApi.
 * @export
 * @interface BeaconsApiGetByAverageTimeSpentRequest
 */
export interface BeaconsApiGetByAverageTimeSpentRequest {
    /**
     * 
     * @type {number}
     * @memberof BeaconsApiGetByAverageTimeSpent
     */
    readonly size?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof BeaconsApiGetByAverageTimeSpent
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof BeaconsApiGetByAverageTimeSpent
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof BeaconsApiGetByAverageTimeSpent
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetByAverageTimeSpent
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetByAverageTimeSpent
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetByAverageTimeSpent
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetByAverageTimeSpent
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof BeaconsApiGetByAverageTimeSpent
     */
    readonly beacons?: Array<string>

    /**
     * Default: null
     * @type {number}
     * @memberof BeaconsApiGetByAverageTimeSpent
     */
    readonly skip?: number
}

/**
 * Request parameters for getByMaximumTimeSpent operation in BeaconsApi.
 * @export
 * @interface BeaconsApiGetByMaximumTimeSpentRequest
 */
export interface BeaconsApiGetByMaximumTimeSpentRequest {
    /**
     * 
     * @type {number}
     * @memberof BeaconsApiGetByMaximumTimeSpent
     */
    readonly size?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof BeaconsApiGetByMaximumTimeSpent
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof BeaconsApiGetByMaximumTimeSpent
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof BeaconsApiGetByMaximumTimeSpent
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetByMaximumTimeSpent
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetByMaximumTimeSpent
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetByMaximumTimeSpent
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetByMaximumTimeSpent
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof BeaconsApiGetByMaximumTimeSpent
     */
    readonly beacons?: Array<string>

    /**
     * Default: null
     * @type {number}
     * @memberof BeaconsApiGetByMaximumTimeSpent
     */
    readonly skip?: number
}

/**
 * Request parameters for getByMinimumTimeSpent operation in BeaconsApi.
 * @export
 * @interface BeaconsApiGetByMinimumTimeSpentRequest
 */
export interface BeaconsApiGetByMinimumTimeSpentRequest {
    /**
     * 
     * @type {number}
     * @memberof BeaconsApiGetByMinimumTimeSpent
     */
    readonly size?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof BeaconsApiGetByMinimumTimeSpent
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof BeaconsApiGetByMinimumTimeSpent
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof BeaconsApiGetByMinimumTimeSpent
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetByMinimumTimeSpent
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetByMinimumTimeSpent
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetByMinimumTimeSpent
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetByMinimumTimeSpent
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof BeaconsApiGetByMinimumTimeSpent
     */
    readonly beacons?: Array<string>

    /**
     * Default: null
     * @type {number}
     * @memberof BeaconsApiGetByMinimumTimeSpent
     */
    readonly skip?: number
}

/**
 * Request parameters for getForLookup operation in BeaconsApi.
 * @export
 * @interface BeaconsApiGetForLookupRequest
 */
export interface BeaconsApiGetForLookupRequest {
    /**
     * Default: null
     * @type {number}
     * @memberof BeaconsApiGetForLookup
     */
    readonly storeId?: number
}

/**
 * Request parameters for getOne operation in BeaconsApi.
 * @export
 * @interface BeaconsApiGetOneRequest
 */
export interface BeaconsApiGetOneRequest {
    /**
     * 
     * @type {string}
     * @memberof BeaconsApiGetOne
     */
    readonly beaconId: string
}

/**
 * Request parameters for getTopAreas operation in BeaconsApi.
 * @export
 * @interface BeaconsApiGetTopAreasRequest
 */
export interface BeaconsApiGetTopAreasRequest {
    /**
     * 
     * @type {number}
     * @memberof BeaconsApiGetTopAreas
     */
    readonly size: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof BeaconsApiGetTopAreas
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof BeaconsApiGetTopAreas
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof BeaconsApiGetTopAreas
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetTopAreas
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetTopAreas
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetTopAreas
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BeaconsApiGetTopAreas
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof BeaconsApiGetTopAreas
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for update operation in BeaconsApi.
 * @export
 * @interface BeaconsApiUpdateRequest
 */
export interface BeaconsApiUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof BeaconsApiUpdate
     */
    readonly beaconId: string

    /**
     * 
     * @type {BeaconModel}
     * @memberof BeaconsApiUpdate
     */
    readonly beaconModel?: BeaconModel
}

/**
 * BeaconsApi - object-oriented interface
 * @export
 * @class BeaconsApi
 * @extends {BaseAPI}
 */
export class BeaconsApi extends BaseAPI {
    /**
     * 
     * @param {BeaconsApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeaconsApi
     */
    public _delete(requestParameters: BeaconsApiDeleteRequest, options?: any) {
        return BeaconsApiFp(this.configuration)._delete(requestParameters.beaconId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeaconsApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeaconsApi
     */
    public create(requestParameters: BeaconsApiCreateRequest = {}, options?: any) {
        return BeaconsApiFp(this.configuration).create(requestParameters.beaconModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeaconsApiGetAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeaconsApi
     */
    public getAll(requestParameters: BeaconsApiGetAllRequest = {}, options?: any) {
        return BeaconsApiFp(this.configuration).getAll(requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, requestParameters.search, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeaconsApi
     */
    public getAllAreas(options?: any) {
        return BeaconsApiFp(this.configuration).getAllAreas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeaconsApiGetBeaconVisitsStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeaconsApi
     */
    public getBeaconVisitsStatistics(requestParameters: BeaconsApiGetBeaconVisitsStatisticsRequest = {}, options?: any) {
        return BeaconsApiFp(this.configuration).getBeaconVisitsStatistics(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeaconsApiGetByAverageTimeSpentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeaconsApi
     */
    public getByAverageTimeSpent(requestParameters: BeaconsApiGetByAverageTimeSpentRequest = {}, options?: any) {
        return BeaconsApiFp(this.configuration).getByAverageTimeSpent(requestParameters.size, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, requestParameters.skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeaconsApiGetByMaximumTimeSpentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeaconsApi
     */
    public getByMaximumTimeSpent(requestParameters: BeaconsApiGetByMaximumTimeSpentRequest = {}, options?: any) {
        return BeaconsApiFp(this.configuration).getByMaximumTimeSpent(requestParameters.size, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, requestParameters.skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeaconsApiGetByMinimumTimeSpentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeaconsApi
     */
    public getByMinimumTimeSpent(requestParameters: BeaconsApiGetByMinimumTimeSpentRequest = {}, options?: any) {
        return BeaconsApiFp(this.configuration).getByMinimumTimeSpent(requestParameters.size, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, requestParameters.skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeaconsApiGetForLookupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeaconsApi
     */
    public getForLookup(requestParameters: BeaconsApiGetForLookupRequest = {}, options?: any) {
        return BeaconsApiFp(this.configuration).getForLookup(requestParameters.storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeaconsApiGetOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeaconsApi
     */
    public getOne(requestParameters: BeaconsApiGetOneRequest, options?: any) {
        return BeaconsApiFp(this.configuration).getOne(requestParameters.beaconId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return area ordered by beacon contacts in given period for given location.
     * @param {BeaconsApiGetTopAreasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeaconsApi
     */
    public getTopAreas(requestParameters: BeaconsApiGetTopAreasRequest, options?: any) {
        return BeaconsApiFp(this.configuration).getTopAreas(requestParameters.size, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeaconsApi
     */
    public getWarnings(options?: any) {
        return BeaconsApiFp(this.configuration).getWarnings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeaconsApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeaconsApi
     */
    public update(requestParameters: BeaconsApiUpdateRequest, options?: any) {
        return BeaconsApiFp(this.configuration).update(requestParameters.beaconId, requestParameters.beaconModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BlogPostsApi - axios parameter creator
 * @export
 */
export const BlogPostsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/api/blog-posts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BlogPostModel} [blogPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (blogPostModel?: BlogPostModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/blog-posts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blogPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (search?: string, skip?: number, limit?: number, language?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/blog-posts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOne', 'id', id)
            const localVarPath = `/api/blog-posts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {BlogPostModel} [blogPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, blogPostModel?: BlogPostModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            const localVarPath = `/api/blog-posts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blogPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlogPostsApi - functional programming interface
 * @export
 */
export const BlogPostsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlogPostsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BlogPostModel} [blogPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(blogPostModel?: BlogPostModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogPostModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(blogPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(search?: string, skip?: number, limit?: number, language?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogPostViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(search, skip, limit, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogPostModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {BlogPostModel} [blogPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, blogPostModel?: BlogPostModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogPostModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, blogPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BlogPostsApi - factory interface
 * @export
 */
export const BlogPostsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlogPostsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: any): AxiosPromise<ServiceResult> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BlogPostModel} [blogPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(blogPostModel?: BlogPostModel, options?: any): AxiosPromise<BlogPostModelServiceResult> {
            return localVarFp.create(blogPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(search?: string, skip?: number, limit?: number, language?: string, options?: any): AxiosPromise<BlogPostViewDataPage> {
            return localVarFp.get(search, skip, limit, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne(id: number, options?: any): AxiosPromise<BlogPostModel> {
            return localVarFp.getOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {BlogPostModel} [blogPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, blogPostModel?: BlogPostModel, options?: any): AxiosPromise<BlogPostModelServiceResult> {
            return localVarFp.update(id, blogPostModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in BlogPostsApi.
 * @export
 * @interface BlogPostsApiDeleteRequest
 */
export interface BlogPostsApiDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof BlogPostsApiDelete
     */
    readonly id: number
}

/**
 * Request parameters for create operation in BlogPostsApi.
 * @export
 * @interface BlogPostsApiCreateRequest
 */
export interface BlogPostsApiCreateRequest {
    /**
     * 
     * @type {BlogPostModel}
     * @memberof BlogPostsApiCreate
     */
    readonly blogPostModel?: BlogPostModel
}

/**
 * Request parameters for get operation in BlogPostsApi.
 * @export
 * @interface BlogPostsApiGetRequest
 */
export interface BlogPostsApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof BlogPostsApiGet
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof BlogPostsApiGet
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof BlogPostsApiGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof BlogPostsApiGet
     */
    readonly language?: string
}

/**
 * Request parameters for getOne operation in BlogPostsApi.
 * @export
 * @interface BlogPostsApiGetOneRequest
 */
export interface BlogPostsApiGetOneRequest {
    /**
     * 
     * @type {number}
     * @memberof BlogPostsApiGetOne
     */
    readonly id: number
}

/**
 * Request parameters for update operation in BlogPostsApi.
 * @export
 * @interface BlogPostsApiUpdateRequest
 */
export interface BlogPostsApiUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof BlogPostsApiUpdate
     */
    readonly id: number

    /**
     * 
     * @type {BlogPostModel}
     * @memberof BlogPostsApiUpdate
     */
    readonly blogPostModel?: BlogPostModel
}

/**
 * BlogPostsApi - object-oriented interface
 * @export
 * @class BlogPostsApi
 * @extends {BaseAPI}
 */
export class BlogPostsApi extends BaseAPI {
    /**
     * 
     * @param {BlogPostsApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogPostsApi
     */
    public _delete(requestParameters: BlogPostsApiDeleteRequest, options?: any) {
        return BlogPostsApiFp(this.configuration)._delete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BlogPostsApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogPostsApi
     */
    public create(requestParameters: BlogPostsApiCreateRequest = {}, options?: any) {
        return BlogPostsApiFp(this.configuration).create(requestParameters.blogPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BlogPostsApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogPostsApi
     */
    public get(requestParameters: BlogPostsApiGetRequest = {}, options?: any) {
        return BlogPostsApiFp(this.configuration).get(requestParameters.search, requestParameters.skip, requestParameters.limit, requestParameters.language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BlogPostsApiGetOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogPostsApi
     */
    public getOne(requestParameters: BlogPostsApiGetOneRequest, options?: any) {
        return BlogPostsApiFp(this.configuration).getOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BlogPostsApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogPostsApi
     */
    public update(requestParameters: BlogPostsApiUpdateRequest, options?: any) {
        return BlogPostsApiFp(this.configuration).update(requestParameters.id, requestParameters.blogPostModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CampaignsApi - axios parameter creator
 * @export
 */
export const CampaignsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/api/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCampaignCommand} [createCampaignCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createCampaignCommand?: CreateCampaignCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCampaignCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {CampaignSort} [sort] 
         * @param {boolean} [onlyActive] 
         * @param {boolean} [onlyRecent] 
         * @param {boolean} [includeDeleted] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (search?: string, sort?: CampaignSort, onlyActive?: boolean, onlyRecent?: boolean, includeDeleted?: boolean, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (onlyActive !== undefined) {
                localVarQueryParameter['onlyActive'] = onlyActive;
            }

            if (onlyRecent !== undefined) {
                localVarQueryParameter['onlyRecent'] = onlyRecent;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForLookup: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/campaigns/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOne', 'id', id)
            const localVarPath = `/api/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCampaignCommand} [updateCampaignCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updateCampaignCommand?: UpdateCampaignCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            const localVarPath = `/api/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCampaignCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignsApi - functional programming interface
 * @export
 */
export const CampaignsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCampaignCommand} [createCampaignCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createCampaignCommand?: CreateCampaignCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createCampaignCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {CampaignSort} [sort] 
         * @param {boolean} [onlyActive] 
         * @param {boolean} [onlyRecent] 
         * @param {boolean} [includeDeleted] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(search?: string, sort?: CampaignSort, onlyActive?: boolean, onlyRecent?: boolean, includeDeleted?: boolean, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(search, sort, onlyActive, onlyRecent, includeDeleted, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForLookup(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CampaignLookupView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getForLookup(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCampaignCommand} [updateCampaignCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updateCampaignCommand?: UpdateCampaignCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateCampaignCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignsApi - factory interface
 * @export
 */
export const CampaignsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: any): AxiosPromise<ServiceResult> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCampaignCommand} [createCampaignCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createCampaignCommand?: CreateCampaignCommand, options?: any): AxiosPromise<CampaignModelServiceResult> {
            return localVarFp.create(createCampaignCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {CampaignSort} [sort] 
         * @param {boolean} [onlyActive] 
         * @param {boolean} [onlyRecent] 
         * @param {boolean} [includeDeleted] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(search?: string, sort?: CampaignSort, onlyActive?: boolean, onlyRecent?: boolean, includeDeleted?: boolean, skip?: number, limit?: number, options?: any): AxiosPromise<CampaignViewDataPage> {
            return localVarFp.get(search, sort, onlyActive, onlyRecent, includeDeleted, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForLookup(options?: any): AxiosPromise<Array<CampaignLookupView>> {
            return localVarFp.getForLookup(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne(id: number, options?: any): AxiosPromise<CampaignDetails> {
            return localVarFp.getOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCampaignCommand} [updateCampaignCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updateCampaignCommand?: UpdateCampaignCommand, options?: any): AxiosPromise<CampaignModelServiceResult> {
            return localVarFp.update(id, updateCampaignCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in CampaignsApi.
 * @export
 * @interface CampaignsApiDeleteRequest
 */
export interface CampaignsApiDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof CampaignsApiDelete
     */
    readonly id: number
}

/**
 * Request parameters for create operation in CampaignsApi.
 * @export
 * @interface CampaignsApiCreateRequest
 */
export interface CampaignsApiCreateRequest {
    /**
     * 
     * @type {CreateCampaignCommand}
     * @memberof CampaignsApiCreate
     */
    readonly createCampaignCommand?: CreateCampaignCommand
}

/**
 * Request parameters for get operation in CampaignsApi.
 * @export
 * @interface CampaignsApiGetRequest
 */
export interface CampaignsApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CampaignsApiGet
     */
    readonly search?: string

    /**
     * 
     * @type {CampaignSort}
     * @memberof CampaignsApiGet
     */
    readonly sort?: CampaignSort

    /**
     * 
     * @type {boolean}
     * @memberof CampaignsApiGet
     */
    readonly onlyActive?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CampaignsApiGet
     */
    readonly onlyRecent?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CampaignsApiGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {number}
     * @memberof CampaignsApiGet
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CampaignsApiGet
     */
    readonly limit?: number
}

/**
 * Request parameters for getOne operation in CampaignsApi.
 * @export
 * @interface CampaignsApiGetOneRequest
 */
export interface CampaignsApiGetOneRequest {
    /**
     * 
     * @type {number}
     * @memberof CampaignsApiGetOne
     */
    readonly id: number
}

/**
 * Request parameters for update operation in CampaignsApi.
 * @export
 * @interface CampaignsApiUpdateRequest
 */
export interface CampaignsApiUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof CampaignsApiUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateCampaignCommand}
     * @memberof CampaignsApiUpdate
     */
    readonly updateCampaignCommand?: UpdateCampaignCommand
}

/**
 * CampaignsApi - object-oriented interface
 * @export
 * @class CampaignsApi
 * @extends {BaseAPI}
 */
export class CampaignsApi extends BaseAPI {
    /**
     * 
     * @param {CampaignsApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public _delete(requestParameters: CampaignsApiDeleteRequest, options?: any) {
        return CampaignsApiFp(this.configuration)._delete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CampaignsApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public create(requestParameters: CampaignsApiCreateRequest = {}, options?: any) {
        return CampaignsApiFp(this.configuration).create(requestParameters.createCampaignCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CampaignsApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public get(requestParameters: CampaignsApiGetRequest = {}, options?: any) {
        return CampaignsApiFp(this.configuration).get(requestParameters.search, requestParameters.sort, requestParameters.onlyActive, requestParameters.onlyRecent, requestParameters.includeDeleted, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getForLookup(options?: any) {
        return CampaignsApiFp(this.configuration).getForLookup(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CampaignsApiGetOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getOne(requestParameters: CampaignsApiGetOneRequest, options?: any) {
        return CampaignsApiFp(this.configuration).getOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CampaignsApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public update(requestParameters: CampaignsApiUpdateRequest, options?: any) {
        return CampaignsApiFp(this.configuration).update(requestParameters.id, requestParameters.updateCampaignCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChangeLogApi - axios parameter creator
 * @export
 */
export const ChangeLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ChangeAction} [action] 
         * @param {Array<Table>} [tables] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (skip?: number, limit?: number, search?: string, action?: ChangeAction, tables?: Array<Table>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/change-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }

            if (tables) {
                localVarQueryParameter['tables'] = tables;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} entityChangeId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyChanges: async (entityChangeId: number, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityChangeId' is not null or undefined
            assertParamExists('getPropertyChanges', 'entityChangeId', entityChangeId)
            const localVarPath = `/api/change-log/{entityChangeId}/properties`
                .replace(`{${"entityChangeId"}}`, encodeURIComponent(String(entityChangeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChangeLogApi - functional programming interface
 * @export
 */
export const ChangeLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChangeLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ChangeAction} [action] 
         * @param {Array<Table>} [tables] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(skip?: number, limit?: number, search?: string, action?: ChangeAction, tables?: Array<Table>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityChangeViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(skip, limit, search, action, tables, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} entityChangeId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyChanges(entityChangeId: number, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyChangeViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyChanges(entityChangeId, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChangeLogApi - factory interface
 * @export
 */
export const ChangeLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChangeLogApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ChangeAction} [action] 
         * @param {Array<Table>} [tables] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(skip?: number, limit?: number, search?: string, action?: ChangeAction, tables?: Array<Table>, options?: any): AxiosPromise<EntityChangeViewDataPage> {
            return localVarFp.getAll(skip, limit, search, action, tables, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} entityChangeId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyChanges(entityChangeId: number, skip?: number, limit?: number, options?: any): AxiosPromise<PropertyChangeViewDataPage> {
            return localVarFp.getPropertyChanges(entityChangeId, skip, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAll operation in ChangeLogApi.
 * @export
 * @interface ChangeLogApiGetAllRequest
 */
export interface ChangeLogApiGetAllRequest {
    /**
     * 
     * @type {number}
     * @memberof ChangeLogApiGetAll
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ChangeLogApiGetAll
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ChangeLogApiGetAll
     */
    readonly search?: string

    /**
     * 
     * @type {ChangeAction}
     * @memberof ChangeLogApiGetAll
     */
    readonly action?: ChangeAction

    /**
     * 
     * @type {Array<Table>}
     * @memberof ChangeLogApiGetAll
     */
    readonly tables?: Array<Table>
}

/**
 * Request parameters for getPropertyChanges operation in ChangeLogApi.
 * @export
 * @interface ChangeLogApiGetPropertyChangesRequest
 */
export interface ChangeLogApiGetPropertyChangesRequest {
    /**
     * 
     * @type {number}
     * @memberof ChangeLogApiGetPropertyChanges
     */
    readonly entityChangeId: number

    /**
     * 
     * @type {number}
     * @memberof ChangeLogApiGetPropertyChanges
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ChangeLogApiGetPropertyChanges
     */
    readonly limit?: number
}

/**
 * ChangeLogApi - object-oriented interface
 * @export
 * @class ChangeLogApi
 * @extends {BaseAPI}
 */
export class ChangeLogApi extends BaseAPI {
    /**
     * 
     * @param {ChangeLogApiGetAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeLogApi
     */
    public getAll(requestParameters: ChangeLogApiGetAllRequest = {}, options?: any) {
        return ChangeLogApiFp(this.configuration).getAll(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.action, requestParameters.tables, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChangeLogApiGetPropertyChangesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeLogApi
     */
    public getPropertyChanges(requestParameters: ChangeLogApiGetPropertyChangesRequest, options?: any) {
        return ChangeLogApiFp(this.configuration).getPropertyChanges(requestParameters.entityChangeId, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary App configurations for a user, BundleId, platform, or default.
         * @param {string} [userId] 
         * @param {PhoneOS} [phoneOS] 
         * @param {string} [bundleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppConfiguration: async (userId?: string, phoneOS?: PhoneOS, bundleId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/configuration/app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (phoneOS !== undefined) {
                localVarQueryParameter['phoneOS'] = phoneOS;
            }

            if (bundleId !== undefined) {
                localVarQueryParameter['bundleId'] = bundleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebConfiguration: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/configuration/web`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary App configurations for a user, BundleId, platform, or default.
         * @param {string} [userId] 
         * @param {PhoneOS} [phoneOS] 
         * @param {string} [bundleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppConfiguration(userId?: string, phoneOS?: PhoneOS, bundleId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppConfiguration(userId, phoneOS, bundleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebConfiguration(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebConfigModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary App configurations for a user, BundleId, platform, or default.
         * @param {string} [userId] 
         * @param {PhoneOS} [phoneOS] 
         * @param {string} [bundleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppConfiguration(userId?: string, phoneOS?: PhoneOS, bundleId?: string, options?: any): AxiosPromise<AppConfigurationResponse> {
            return localVarFp.getAppConfiguration(userId, phoneOS, bundleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebConfiguration(options?: any): AxiosPromise<WebConfigModel> {
            return localVarFp.getWebConfiguration(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAppConfiguration operation in ConfigurationApi.
 * @export
 * @interface ConfigurationApiGetAppConfigurationRequest
 */
export interface ConfigurationApiGetAppConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiGetAppConfiguration
     */
    readonly userId?: string

    /**
     * 
     * @type {PhoneOS}
     * @memberof ConfigurationApiGetAppConfiguration
     */
    readonly phoneOS?: PhoneOS

    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiGetAppConfiguration
     */
    readonly bundleId?: string
}

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary App configurations for a user, BundleId, platform, or default.
     * @param {ConfigurationApiGetAppConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getAppConfiguration(requestParameters: ConfigurationApiGetAppConfigurationRequest = {}, options?: any) {
        return ConfigurationApiFp(this.configuration).getAppConfiguration(requestParameters.userId, requestParameters.phoneOS, requestParameters.bundleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getWebConfiguration(options?: any) {
        return ConfigurationApiFp(this.configuration).getWebConfiguration(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {DeviceId} [deviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateDevice: async (customerId: string, deviceId?: DeviceId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deactivateDevice', 'customerId', customerId)
            const localVarPath = `/api/customers/{customerId}/devices/deactivate`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (customerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('get', 'customerId', customerId)
            const localVarPath = `/api/customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {boolean} [hideZeros] 
         * @param {boolean} [visitorsOnly] 
         * @param {CustomerSort} [sort] 
         * @param {SortDirection} [sortDirection] 
         * @param {CustomerFilter} [customerFilter] 
         * @param {CustomerSummaryStatistic} [customerSummaryStatistic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, limit?: number, search?: string, hideZeros?: boolean, visitorsOnly?: boolean, sort?: CustomerSort, sortDirection?: SortDirection, customerFilter?: CustomerFilter, customerSummaryStatistic?: CustomerSummaryStatistic, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (hideZeros !== undefined) {
                localVarQueryParameter['hideZeros'] = hideZeros;
            }

            if (visitorsOnly !== undefined) {
                localVarQueryParameter['visitorsOnly'] = visitorsOnly;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (customerFilter !== undefined) {
                localVarQueryParameter['customerFilter'] = customerFilter;
            }

            if (customerSummaryStatistic !== undefined) {
                localVarQueryParameter['customerSummaryStatistic'] = customerSummaryStatistic;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBeaconVisits: async (customerId: string, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getBeaconVisits', 'customerId', customerId)
            const localVarPath = `/api/customers/{customerId}/beacon-visits`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByBeaconContacts: async (limit: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getByBeaconContacts', 'limit', limit)
            const localVarPath = `/api/customers/by-beacon-contacts/top-{limit}`
                .replace(`{${"limit"}}`, encodeURIComponent(String(limit)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByRevenue: async (limit: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getByRevenue', 'limit', limit)
            const localVarPath = `/api/customers/by-revenue/top-{limit}`
                .replace(`{${"limit"}}`, encodeURIComponent(String(limit)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByTouchpoints: async (limit: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getByTouchpoints', 'limit', limit)
            const localVarPath = `/api/customers/by-touchpoints/top-{limit}`
                .replace(`{${"limit"}}`, encodeURIComponent(String(limit)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByVisits: async (limit: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getByVisits', 'limit', limit)
            const localVarPath = `/api/customers/by-visits/top-{limit}`
                .replace(`{${"limit"}}`, encodeURIComponent(String(limit)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts: async (customerId: string, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getContacts', 'customerId', customerId)
            const localVarPath = `/api/customers/{customerId}/contacts`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {boolean} [hideZeros] 
         * @param {boolean} [visitorsOnly] 
         * @param {CustomerSort} [sort] 
         * @param {SortDirection} [sortDirection] 
         * @param {CustomerFilter} [customerFilter] 
         * @param {CustomerSummaryStatistic} [customerSummaryStatistic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerDataCsv: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, limit?: number, search?: string, hideZeros?: boolean, visitorsOnly?: boolean, sort?: CustomerSort, sortDirection?: SortDirection, customerFilter?: CustomerFilter, customerSummaryStatistic?: CustomerSummaryStatistic, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customers/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (hideZeros !== undefined) {
                localVarQueryParameter['hideZeros'] = hideZeros;
            }

            if (visitorsOnly !== undefined) {
                localVarQueryParameter['visitorsOnly'] = visitorsOnly;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (customerFilter !== undefined) {
                localVarQueryParameter['customerFilter'] = customerFilter;
            }

            if (customerSummaryStatistic !== undefined) {
                localVarQueryParameter['customerSummaryStatistic'] = customerSummaryStatistic;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyPurchases: async (customerId: string, skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getDailyPurchases', 'customerId', customerId)
            const localVarPath = `/api/customers/{customerId}/purchases/daily`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices: async (customerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getDevices', 'customerId', customerId)
            const localVarPath = `/api/customers/{customerId}/devices`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages: async (customerId: string, skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getMessages', 'customerId', customerId)
            const localVarPath = `/api/customers/{customerId}/messages`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSentMessages: async (customerId: string, offerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getSentMessages', 'customerId', customerId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('getSentMessages', 'offerId', offerId)
            const localVarPath = `/api/customers/{customerId}/messages/{offerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistics: async (customerId: string, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getStatistics', 'customerId', customerId)
            const localVarPath = `/api/customers/{customerId}/statistics`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreVisits: async (customerId: string, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getStoreVisits', 'customerId', customerId)
            const localVarPath = `/api/customers/{customerId}/store-visits`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupAll: async (search?: string, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customers/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reset: async (customerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('reset', 'customerId', customerId)
            const localVarPath = `/api/customers/{customerId}/reset`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {DeviceId} [deviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateDevice(customerId: string, deviceId?: DeviceId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateDevice(customerId, deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(customerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDetailsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {boolean} [hideZeros] 
         * @param {boolean} [visitorsOnly] 
         * @param {CustomerSort} [sort] 
         * @param {SortDirection} [sortDirection] 
         * @param {CustomerFilter} [customerFilter] 
         * @param {CustomerSummaryStatistic} [customerSummaryStatistic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, limit?: number, search?: string, hideZeros?: boolean, visitorsOnly?: boolean, sort?: CustomerSort, sortDirection?: SortDirection, customerFilter?: CustomerFilter, customerSummaryStatistic?: CustomerSummaryStatistic, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDataView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, skip, limit, search, hideZeros, visitorsOnly, sort, sortDirection, customerFilter, customerSummaryStatistic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBeaconVisits(customerId: string, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeaconVisitViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBeaconVisits(customerId, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByBeaconContacts(limit: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopCustomerGridView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByBeaconContacts(limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByRevenue(limit: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopCustomerGridView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByRevenue(limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByTouchpoints(limit: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopCustomerGridView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByTouchpoints(limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByVisits(limit: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopCustomerGridView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByVisits(limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContacts(customerId: string, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContacts(customerId, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {boolean} [hideZeros] 
         * @param {boolean} [visitorsOnly] 
         * @param {CustomerSort} [sort] 
         * @param {SortDirection} [sortDirection] 
         * @param {CustomerFilter} [customerFilter] 
         * @param {CustomerSummaryStatistic} [customerSummaryStatistic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerDataCsv(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, limit?: number, search?: string, hideZeros?: boolean, visitorsOnly?: boolean, sort?: CustomerSort, sortDirection?: SortDirection, customerFilter?: CustomerFilter, customerSummaryStatistic?: CustomerSummaryStatistic, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerSummaryViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerDataCsv(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, skip, limit, search, hideZeros, visitorsOnly, sort, sortDirection, customerFilter, customerSummaryStatistic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDailyPurchases(customerId: string, skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDailyPurchasesViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDailyPurchases(customerId, skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevices(customerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevices(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessages(customerId: string, skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SentMessageViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessages(customerId, skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSentMessages(customerId: string, offerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SentMessageViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSentMessages(customerId, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatistics(customerId: string, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerStatisticsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatistics(customerId, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreVisits(customerId: string, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreVisitViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreVisits(customerId, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupAll(search?: string, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerLookupViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupAll(search, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reset(customerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reset(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {DeviceId} [deviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateDevice(customerId: string, deviceId?: DeviceId, options?: any): AxiosPromise<Array<DeviceView>> {
            return localVarFp.deactivateDevice(customerId, deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(customerId: string, options?: any): AxiosPromise<CustomerDetailsView> {
            return localVarFp.get(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {boolean} [hideZeros] 
         * @param {boolean} [visitorsOnly] 
         * @param {CustomerSort} [sort] 
         * @param {SortDirection} [sortDirection] 
         * @param {CustomerFilter} [customerFilter] 
         * @param {CustomerSummaryStatistic} [customerSummaryStatistic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, limit?: number, search?: string, hideZeros?: boolean, visitorsOnly?: boolean, sort?: CustomerSort, sortDirection?: SortDirection, customerFilter?: CustomerFilter, customerSummaryStatistic?: CustomerSummaryStatistic, options?: any): AxiosPromise<CustomerDataView> {
            return localVarFp.getAll(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, skip, limit, search, hideZeros, visitorsOnly, sort, sortDirection, customerFilter, customerSummaryStatistic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBeaconVisits(customerId: string, skip?: number, limit?: number, options?: any): AxiosPromise<BeaconVisitViewDataPage> {
            return localVarFp.getBeaconVisits(customerId, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByBeaconContacts(limit: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<Array<TopCustomerGridView>> {
            return localVarFp.getByBeaconContacts(limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByRevenue(limit: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<Array<TopCustomerGridView>> {
            return localVarFp.getByRevenue(limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByTouchpoints(limit: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<Array<TopCustomerGridView>> {
            return localVarFp.getByTouchpoints(limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByVisits(limit: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<Array<TopCustomerGridView>> {
            return localVarFp.getByVisits(limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts(customerId: string, skip?: number, limit?: number, options?: any): AxiosPromise<ContactViewDataPage> {
            return localVarFp.getContacts(customerId, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {boolean} [hideZeros] 
         * @param {boolean} [visitorsOnly] 
         * @param {CustomerSort} [sort] 
         * @param {SortDirection} [sortDirection] 
         * @param {CustomerFilter} [customerFilter] 
         * @param {CustomerSummaryStatistic} [customerSummaryStatistic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerDataCsv(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, limit?: number, search?: string, hideZeros?: boolean, visitorsOnly?: boolean, sort?: CustomerSort, sortDirection?: SortDirection, customerFilter?: CustomerFilter, customerSummaryStatistic?: CustomerSummaryStatistic, options?: any): AxiosPromise<CustomerSummaryViewDataPage> {
            return localVarFp.getCustomerDataCsv(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, skip, limit, search, hideZeros, visitorsOnly, sort, sortDirection, customerFilter, customerSummaryStatistic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyPurchases(customerId: string, skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<CustomerDailyPurchasesViewDataPage> {
            return localVarFp.getDailyPurchases(customerId, skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices(customerId: string, options?: any): AxiosPromise<Array<DeviceView>> {
            return localVarFp.getDevices(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages(customerId: string, skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<SentMessageViewDataPage> {
            return localVarFp.getMessages(customerId, skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSentMessages(customerId: string, offerId: number, options?: any): AxiosPromise<SentMessageViewDataPage> {
            return localVarFp.getSentMessages(customerId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistics(customerId: string, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<CustomerStatisticsView> {
            return localVarFp.getStatistics(customerId, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreVisits(customerId: string, skip?: number, limit?: number, options?: any): AxiosPromise<StoreVisitViewDataPage> {
            return localVarFp.getStoreVisits(customerId, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupAll(search?: string, skip?: number, limit?: number, options?: any): AxiosPromise<CustomerLookupViewDataPage> {
            return localVarFp.lookupAll(search, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reset(customerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.reset(customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deactivateDevice operation in CustomersApi.
 * @export
 * @interface CustomersApiDeactivateDeviceRequest
 */
export interface CustomersApiDeactivateDeviceRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiDeactivateDevice
     */
    readonly customerId: string

    /**
     * 
     * @type {DeviceId}
     * @memberof CustomersApiDeactivateDevice
     */
    readonly deviceId?: DeviceId
}

/**
 * Request parameters for get operation in CustomersApi.
 * @export
 * @interface CustomersApiGetRequest
 */
export interface CustomersApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiGet
     */
    readonly customerId: string
}

/**
 * Request parameters for getAll operation in CustomersApi.
 * @export
 * @interface CustomersApiGetAllRequest
 */
export interface CustomersApiGetAllRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof CustomersApiGetAll
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetAll
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetAll
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetAll
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetAll
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetAll
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetAll
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof CustomersApiGetAll
     */
    readonly beacons?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof CustomersApiGetAll
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CustomersApiGetAll
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CustomersApiGetAll
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof CustomersApiGetAll
     */
    readonly hideZeros?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CustomersApiGetAll
     */
    readonly visitorsOnly?: boolean

    /**
     * 
     * @type {CustomerSort}
     * @memberof CustomersApiGetAll
     */
    readonly sort?: CustomerSort

    /**
     * 
     * @type {SortDirection}
     * @memberof CustomersApiGetAll
     */
    readonly sortDirection?: SortDirection

    /**
     * 
     * @type {CustomerFilter}
     * @memberof CustomersApiGetAll
     */
    readonly customerFilter?: CustomerFilter

    /**
     * 
     * @type {CustomerSummaryStatistic}
     * @memberof CustomersApiGetAll
     */
    readonly customerSummaryStatistic?: CustomerSummaryStatistic
}

/**
 * Request parameters for getBeaconVisits operation in CustomersApi.
 * @export
 * @interface CustomersApiGetBeaconVisitsRequest
 */
export interface CustomersApiGetBeaconVisitsRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiGetBeaconVisits
     */
    readonly customerId: string

    /**
     * Default: null
     * @type {number}
     * @memberof CustomersApiGetBeaconVisits
     */
    readonly skip?: number

    /**
     * Default: null
     * @type {number}
     * @memberof CustomersApiGetBeaconVisits
     */
    readonly limit?: number
}

/**
 * Request parameters for getByBeaconContacts operation in CustomersApi.
 * @export
 * @interface CustomersApiGetByBeaconContactsRequest
 */
export interface CustomersApiGetByBeaconContactsRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomersApiGetByBeaconContacts
     */
    readonly limit: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof CustomersApiGetByBeaconContacts
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetByBeaconContacts
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetByBeaconContacts
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetByBeaconContacts
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetByBeaconContacts
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetByBeaconContacts
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetByBeaconContacts
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof CustomersApiGetByBeaconContacts
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getByRevenue operation in CustomersApi.
 * @export
 * @interface CustomersApiGetByRevenueRequest
 */
export interface CustomersApiGetByRevenueRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomersApiGetByRevenue
     */
    readonly limit: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof CustomersApiGetByRevenue
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetByRevenue
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetByRevenue
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetByRevenue
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetByRevenue
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetByRevenue
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetByRevenue
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof CustomersApiGetByRevenue
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getByTouchpoints operation in CustomersApi.
 * @export
 * @interface CustomersApiGetByTouchpointsRequest
 */
export interface CustomersApiGetByTouchpointsRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomersApiGetByTouchpoints
     */
    readonly limit: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof CustomersApiGetByTouchpoints
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetByTouchpoints
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetByTouchpoints
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetByTouchpoints
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetByTouchpoints
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetByTouchpoints
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetByTouchpoints
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof CustomersApiGetByTouchpoints
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getByVisits operation in CustomersApi.
 * @export
 * @interface CustomersApiGetByVisitsRequest
 */
export interface CustomersApiGetByVisitsRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomersApiGetByVisits
     */
    readonly limit: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof CustomersApiGetByVisits
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetByVisits
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetByVisits
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetByVisits
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetByVisits
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetByVisits
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetByVisits
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof CustomersApiGetByVisits
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getContacts operation in CustomersApi.
 * @export
 * @interface CustomersApiGetContactsRequest
 */
export interface CustomersApiGetContactsRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiGetContacts
     */
    readonly customerId: string

    /**
     * Default: null
     * @type {number}
     * @memberof CustomersApiGetContacts
     */
    readonly skip?: number

    /**
     * Default: null
     * @type {number}
     * @memberof CustomersApiGetContacts
     */
    readonly limit?: number
}

/**
 * Request parameters for getCustomerDataCsv operation in CustomersApi.
 * @export
 * @interface CustomersApiGetCustomerDataCsvRequest
 */
export interface CustomersApiGetCustomerDataCsvRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly beacons?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly hideZeros?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly visitorsOnly?: boolean

    /**
     * 
     * @type {CustomerSort}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly sort?: CustomerSort

    /**
     * 
     * @type {SortDirection}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly sortDirection?: SortDirection

    /**
     * 
     * @type {CustomerFilter}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly customerFilter?: CustomerFilter

    /**
     * 
     * @type {CustomerSummaryStatistic}
     * @memberof CustomersApiGetCustomerDataCsv
     */
    readonly customerSummaryStatistic?: CustomerSummaryStatistic
}

/**
 * Request parameters for getDailyPurchases operation in CustomersApi.
 * @export
 * @interface CustomersApiGetDailyPurchasesRequest
 */
export interface CustomersApiGetDailyPurchasesRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiGetDailyPurchases
     */
    readonly customerId: string

    /**
     * Default: null
     * @type {number}
     * @memberof CustomersApiGetDailyPurchases
     */
    readonly skip?: number

    /**
     * Default: null
     * @type {number}
     * @memberof CustomersApiGetDailyPurchases
     */
    readonly limit?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof CustomersApiGetDailyPurchases
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetDailyPurchases
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetDailyPurchases
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetDailyPurchases
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetDailyPurchases
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetDailyPurchases
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetDailyPurchases
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof CustomersApiGetDailyPurchases
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getDevices operation in CustomersApi.
 * @export
 * @interface CustomersApiGetDevicesRequest
 */
export interface CustomersApiGetDevicesRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiGetDevices
     */
    readonly customerId: string
}

/**
 * Request parameters for getMessages operation in CustomersApi.
 * @export
 * @interface CustomersApiGetMessagesRequest
 */
export interface CustomersApiGetMessagesRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiGetMessages
     */
    readonly customerId: string

    /**
     * Default: null
     * @type {number}
     * @memberof CustomersApiGetMessages
     */
    readonly skip?: number

    /**
     * Default: null
     * @type {number}
     * @memberof CustomersApiGetMessages
     */
    readonly limit?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof CustomersApiGetMessages
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetMessages
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetMessages
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetMessages
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetMessages
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetMessages
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetMessages
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof CustomersApiGetMessages
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getSentMessages operation in CustomersApi.
 * @export
 * @interface CustomersApiGetSentMessagesRequest
 */
export interface CustomersApiGetSentMessagesRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiGetSentMessages
     */
    readonly customerId: string

    /**
     * 
     * @type {number}
     * @memberof CustomersApiGetSentMessages
     */
    readonly offerId: number
}

/**
 * Request parameters for getStatistics operation in CustomersApi.
 * @export
 * @interface CustomersApiGetStatisticsRequest
 */
export interface CustomersApiGetStatisticsRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiGetStatistics
     */
    readonly customerId: string

    /**
     * 
     * @type {TimeframeType}
     * @memberof CustomersApiGetStatistics
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetStatistics
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof CustomersApiGetStatistics
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetStatistics
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetStatistics
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetStatistics
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersApiGetStatistics
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof CustomersApiGetStatistics
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getStoreVisits operation in CustomersApi.
 * @export
 * @interface CustomersApiGetStoreVisitsRequest
 */
export interface CustomersApiGetStoreVisitsRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiGetStoreVisits
     */
    readonly customerId: string

    /**
     * Default: null
     * @type {number}
     * @memberof CustomersApiGetStoreVisits
     */
    readonly skip?: number

    /**
     * Default: null
     * @type {number}
     * @memberof CustomersApiGetStoreVisits
     */
    readonly limit?: number
}

/**
 * Request parameters for lookupAll operation in CustomersApi.
 * @export
 * @interface CustomersApiLookupAllRequest
 */
export interface CustomersApiLookupAllRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiLookupAll
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof CustomersApiLookupAll
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CustomersApiLookupAll
     */
    readonly limit?: number
}

/**
 * Request parameters for reset operation in CustomersApi.
 * @export
 * @interface CustomersApiResetRequest
 */
export interface CustomersApiResetRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiReset
     */
    readonly customerId: string
}

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     * 
     * @param {CustomersApiDeactivateDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public deactivateDevice(requestParameters: CustomersApiDeactivateDeviceRequest, options?: any) {
        return CustomersApiFp(this.configuration).deactivateDevice(requestParameters.customerId, requestParameters.deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public get(requestParameters: CustomersApiGetRequest, options?: any) {
        return CustomersApiFp(this.configuration).get(requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getAll(requestParameters: CustomersApiGetAllRequest = {}, options?: any) {
        return CustomersApiFp(this.configuration).getAll(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.hideZeros, requestParameters.visitorsOnly, requestParameters.sort, requestParameters.sortDirection, requestParameters.customerFilter, requestParameters.customerSummaryStatistic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetBeaconVisitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getBeaconVisits(requestParameters: CustomersApiGetBeaconVisitsRequest, options?: any) {
        return CustomersApiFp(this.configuration).getBeaconVisits(requestParameters.customerId, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetByBeaconContactsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getByBeaconContacts(requestParameters: CustomersApiGetByBeaconContactsRequest, options?: any) {
        return CustomersApiFp(this.configuration).getByBeaconContacts(requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetByRevenueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getByRevenue(requestParameters: CustomersApiGetByRevenueRequest, options?: any) {
        return CustomersApiFp(this.configuration).getByRevenue(requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetByTouchpointsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getByTouchpoints(requestParameters: CustomersApiGetByTouchpointsRequest, options?: any) {
        return CustomersApiFp(this.configuration).getByTouchpoints(requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetByVisitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getByVisits(requestParameters: CustomersApiGetByVisitsRequest, options?: any) {
        return CustomersApiFp(this.configuration).getByVisits(requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetContactsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getContacts(requestParameters: CustomersApiGetContactsRequest, options?: any) {
        return CustomersApiFp(this.configuration).getContacts(requestParameters.customerId, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetCustomerDataCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getCustomerDataCsv(requestParameters: CustomersApiGetCustomerDataCsvRequest = {}, options?: any) {
        return CustomersApiFp(this.configuration).getCustomerDataCsv(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.hideZeros, requestParameters.visitorsOnly, requestParameters.sort, requestParameters.sortDirection, requestParameters.customerFilter, requestParameters.customerSummaryStatistic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetDailyPurchasesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getDailyPurchases(requestParameters: CustomersApiGetDailyPurchasesRequest, options?: any) {
        return CustomersApiFp(this.configuration).getDailyPurchases(requestParameters.customerId, requestParameters.skip, requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetDevicesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getDevices(requestParameters: CustomersApiGetDevicesRequest, options?: any) {
        return CustomersApiFp(this.configuration).getDevices(requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetMessagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getMessages(requestParameters: CustomersApiGetMessagesRequest, options?: any) {
        return CustomersApiFp(this.configuration).getMessages(requestParameters.customerId, requestParameters.skip, requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetSentMessagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getSentMessages(requestParameters: CustomersApiGetSentMessagesRequest, options?: any) {
        return CustomersApiFp(this.configuration).getSentMessages(requestParameters.customerId, requestParameters.offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getStatistics(requestParameters: CustomersApiGetStatisticsRequest, options?: any) {
        return CustomersApiFp(this.configuration).getStatistics(requestParameters.customerId, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetStoreVisitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getStoreVisits(requestParameters: CustomersApiGetStoreVisitsRequest, options?: any) {
        return CustomersApiFp(this.configuration).getStoreVisits(requestParameters.customerId, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiLookupAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public lookupAll(requestParameters: CustomersApiLookupAllRequest = {}, options?: any) {
        return CustomersApiFp(this.configuration).lookupAll(requestParameters.search, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiResetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public reset(requestParameters: CustomersApiResetRequest, options?: any) {
        return CustomersApiFp(this.configuration).reset(requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemographicsApi - axios parameter creator
 * @export
 */
export const DemographicsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForTransactions: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/demographics/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForVisits: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/demographics/visits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemographicsApi - functional programming interface
 * @export
 */
export const DemographicsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemographicsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForTransactions(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemographicsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getForTransactions(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForVisits(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemographicsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getForVisits(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemographicsApi - factory interface
 * @export
 */
export const DemographicsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemographicsApiFp(configuration)
    return {
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForTransactions(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<DemographicsView> {
            return localVarFp.getForTransactions(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForVisits(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<DemographicsView> {
            return localVarFp.getForVisits(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getForTransactions operation in DemographicsApi.
 * @export
 * @interface DemographicsApiGetForTransactionsRequest
 */
export interface DemographicsApiGetForTransactionsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof DemographicsApiGetForTransactions
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof DemographicsApiGetForTransactions
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof DemographicsApiGetForTransactions
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof DemographicsApiGetForTransactions
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DemographicsApiGetForTransactions
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DemographicsApiGetForTransactions
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DemographicsApiGetForTransactions
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof DemographicsApiGetForTransactions
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getForVisits operation in DemographicsApi.
 * @export
 * @interface DemographicsApiGetForVisitsRequest
 */
export interface DemographicsApiGetForVisitsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof DemographicsApiGetForVisits
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof DemographicsApiGetForVisits
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof DemographicsApiGetForVisits
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof DemographicsApiGetForVisits
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DemographicsApiGetForVisits
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DemographicsApiGetForVisits
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DemographicsApiGetForVisits
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof DemographicsApiGetForVisits
     */
    readonly beacons?: Array<string>
}

/**
 * DemographicsApi - object-oriented interface
 * @export
 * @class DemographicsApi
 * @extends {BaseAPI}
 */
export class DemographicsApi extends BaseAPI {
    /**
     * 
     * @param {DemographicsApiGetForTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemographicsApi
     */
    public getForTransactions(requestParameters: DemographicsApiGetForTransactionsRequest = {}, options?: any) {
        return DemographicsApiFp(this.configuration).getForTransactions(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DemographicsApiGetForVisitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemographicsApi
     */
    public getForVisits(requestParameters: DemographicsApiGetForVisitsRequest = {}, options?: any) {
        return DemographicsApiFp(this.configuration).getForVisits(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeaturedItemsApi - axios parameter creator
 * @export
 */
export const FeaturedItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/api/featured-items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FeaturedItemModel} [featuredItemModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (featuredItemModel?: FeaturedItemModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/featured-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featuredItemModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (search?: string, skip?: number, limit?: number, language?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/featured-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOne', 'id', id)
            const localVarPath = `/api/featured-items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FeaturedItemModel} [featuredItemModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, featuredItemModel?: FeaturedItemModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            const localVarPath = `/api/featured-items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featuredItemModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeaturedItemsApi - functional programming interface
 * @export
 */
export const FeaturedItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeaturedItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FeaturedItemModel} [featuredItemModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(featuredItemModel?: FeaturedItemModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeaturedItemModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(featuredItemModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(search?: string, skip?: number, limit?: number, language?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeaturedItemViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(search, skip, limit, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeaturedItemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FeaturedItemModel} [featuredItemModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, featuredItemModel?: FeaturedItemModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeaturedItemModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, featuredItemModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeaturedItemsApi - factory interface
 * @export
 */
export const FeaturedItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeaturedItemsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: any): AxiosPromise<ServiceResult> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeaturedItemModel} [featuredItemModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(featuredItemModel?: FeaturedItemModel, options?: any): AxiosPromise<FeaturedItemModelServiceResult> {
            return localVarFp.create(featuredItemModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(search?: string, skip?: number, limit?: number, language?: string, options?: any): AxiosPromise<FeaturedItemViewDataPage> {
            return localVarFp.get(search, skip, limit, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne(id: number, options?: any): AxiosPromise<FeaturedItemModel> {
            return localVarFp.getOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FeaturedItemModel} [featuredItemModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, featuredItemModel?: FeaturedItemModel, options?: any): AxiosPromise<FeaturedItemModelServiceResult> {
            return localVarFp.update(id, featuredItemModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in FeaturedItemsApi.
 * @export
 * @interface FeaturedItemsApiDeleteRequest
 */
export interface FeaturedItemsApiDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturedItemsApiDelete
     */
    readonly id: number
}

/**
 * Request parameters for create operation in FeaturedItemsApi.
 * @export
 * @interface FeaturedItemsApiCreateRequest
 */
export interface FeaturedItemsApiCreateRequest {
    /**
     * 
     * @type {FeaturedItemModel}
     * @memberof FeaturedItemsApiCreate
     */
    readonly featuredItemModel?: FeaturedItemModel
}

/**
 * Request parameters for get operation in FeaturedItemsApi.
 * @export
 * @interface FeaturedItemsApiGetRequest
 */
export interface FeaturedItemsApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FeaturedItemsApiGet
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof FeaturedItemsApiGet
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof FeaturedItemsApiGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof FeaturedItemsApiGet
     */
    readonly language?: string
}

/**
 * Request parameters for getOne operation in FeaturedItemsApi.
 * @export
 * @interface FeaturedItemsApiGetOneRequest
 */
export interface FeaturedItemsApiGetOneRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturedItemsApiGetOne
     */
    readonly id: number
}

/**
 * Request parameters for update operation in FeaturedItemsApi.
 * @export
 * @interface FeaturedItemsApiUpdateRequest
 */
export interface FeaturedItemsApiUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturedItemsApiUpdate
     */
    readonly id: number

    /**
     * 
     * @type {FeaturedItemModel}
     * @memberof FeaturedItemsApiUpdate
     */
    readonly featuredItemModel?: FeaturedItemModel
}

/**
 * FeaturedItemsApi - object-oriented interface
 * @export
 * @class FeaturedItemsApi
 * @extends {BaseAPI}
 */
export class FeaturedItemsApi extends BaseAPI {
    /**
     * 
     * @param {FeaturedItemsApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturedItemsApi
     */
    public _delete(requestParameters: FeaturedItemsApiDeleteRequest, options?: any) {
        return FeaturedItemsApiFp(this.configuration)._delete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturedItemsApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturedItemsApi
     */
    public create(requestParameters: FeaturedItemsApiCreateRequest = {}, options?: any) {
        return FeaturedItemsApiFp(this.configuration).create(requestParameters.featuredItemModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturedItemsApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturedItemsApi
     */
    public get(requestParameters: FeaturedItemsApiGetRequest = {}, options?: any) {
        return FeaturedItemsApiFp(this.configuration).get(requestParameters.search, requestParameters.skip, requestParameters.limit, requestParameters.language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturedItemsApiGetOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturedItemsApi
     */
    public getOne(requestParameters: FeaturedItemsApiGetOneRequest, options?: any) {
        return FeaturedItemsApiFp(this.configuration).getOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturedItemsApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturedItemsApi
     */
    public update(requestParameters: FeaturedItemsApiUpdateRequest, options?: any) {
        return FeaturedItemsApiFp(this.configuration).update(requestParameters.id, requestParameters.featuredItemModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthChecksApi - axios parameter creator
 * @export
 */
export const HealthChecksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthChecks: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/health-checks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthChecksApi - functional programming interface
 * @export
 */
export const HealthChecksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthChecksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealthChecks(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthChecksView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealthChecks(rangeType, rangeStart, rangeEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthChecksApi - factory interface
 * @export
 */
export const HealthChecksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthChecksApiFp(configuration)
    return {
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthChecks(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, options?: any): AxiosPromise<HealthChecksView> {
            return localVarFp.getHealthChecks(rangeType, rangeStart, rangeEnd, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getHealthChecks operation in HealthChecksApi.
 * @export
 * @interface HealthChecksApiGetHealthChecksRequest
 */
export interface HealthChecksApiGetHealthChecksRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof HealthChecksApiGetHealthChecks
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof HealthChecksApiGetHealthChecks
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof HealthChecksApiGetHealthChecks
     */
    readonly rangeEnd?: IsoDateTimeString
}

/**
 * HealthChecksApi - object-oriented interface
 * @export
 * @class HealthChecksApi
 * @extends {BaseAPI}
 */
export class HealthChecksApi extends BaseAPI {
    /**
     * 
     * @param {HealthChecksApiGetHealthChecksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthChecksApi
     */
    public getHealthChecks(requestParameters: HealthChecksApiGetHealthChecksRequest = {}, options?: any) {
        return HealthChecksApiFp(this.configuration).getHealthChecks(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImageApi - axios parameter creator
 * @export
 */
export const ImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Receives multipart/form-data. Example JS code for uploading images:        var image = $(\'input[name=image]\')[0];      if (image.files.length > 0) {            var settings = {              generateThumbnail: $(\'input[name=generateThumbnail]\')[0].checked,              generateGrayscale: $(\'input[name=generateGrayscale]\')[0].checked,              generateThumbnailGrayscale: $(\'input[name=generateThumbnailGrayscale]\')[0].checked          };            var payload = new FormData();          payload.append(\'settings\', new Blob([JSON.stringify(settings)], { type: \'application/json\' }));          payload.append(\'image\', image.files[0], image.filename);            $.ajax({                  url: \'http://local.bms-api.com/api/v1/images\',              type: \'POST\',              data: payload,              success: function(data) {                      alert(JSON.stringify(data));                  },              cache: false,              contentType: false,              processData: false          });      }  
         * @param {ImageUploadSettings} [settings] 
         * @param {any} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (settings?: ImageUploadSettings, image?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (settings !== undefined) { 
                localVarFormParams.append('settings', new Blob([JSON.stringify(settings)], { type: "application/json", }));
            }
    
            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageApi - functional programming interface
 * @export
 */
export const ImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageApiAxiosParamCreator(configuration)
    return {
        /**
         * Receives multipart/form-data. Example JS code for uploading images:        var image = $(\'input[name=image]\')[0];      if (image.files.length > 0) {            var settings = {              generateThumbnail: $(\'input[name=generateThumbnail]\')[0].checked,              generateGrayscale: $(\'input[name=generateGrayscale]\')[0].checked,              generateThumbnailGrayscale: $(\'input[name=generateThumbnailGrayscale]\')[0].checked          };            var payload = new FormData();          payload.append(\'settings\', new Blob([JSON.stringify(settings)], { type: \'application/json\' }));          payload.append(\'image\', image.files[0], image.filename);            $.ajax({                  url: \'http://local.bms-api.com/api/v1/images\',              type: \'POST\',              data: payload,              success: function(data) {                      alert(JSON.stringify(data));                  },              cache: false,              contentType: false,              processData: false          });      }  
         * @param {ImageUploadSettings} [settings] 
         * @param {any} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(settings?: ImageUploadSettings, image?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(settings, image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImageApi - factory interface
 * @export
 */
export const ImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageApiFp(configuration)
    return {
        /**
         * Receives multipart/form-data. Example JS code for uploading images:        var image = $(\'input[name=image]\')[0];      if (image.files.length > 0) {            var settings = {              generateThumbnail: $(\'input[name=generateThumbnail]\')[0].checked,              generateGrayscale: $(\'input[name=generateGrayscale]\')[0].checked,              generateThumbnailGrayscale: $(\'input[name=generateThumbnailGrayscale]\')[0].checked          };            var payload = new FormData();          payload.append(\'settings\', new Blob([JSON.stringify(settings)], { type: \'application/json\' }));          payload.append(\'image\', image.files[0], image.filename);            $.ajax({                  url: \'http://local.bms-api.com/api/v1/images\',              type: \'POST\',              data: payload,              success: function(data) {                      alert(JSON.stringify(data));                  },              cache: false,              contentType: false,              processData: false          });      }  
         * @param {ImageUploadSettings} [settings] 
         * @param {any} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(settings?: ImageUploadSettings, image?: any, options?: any): AxiosPromise<ImageUploadResponse> {
            return localVarFp.create(settings, image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for create operation in ImageApi.
 * @export
 * @interface ImageApiCreateRequest
 */
export interface ImageApiCreateRequest {
    /**
     * 
     * @type {ImageUploadSettings}
     * @memberof ImageApiCreate
     */
    readonly settings?: ImageUploadSettings

    /**
     * 
     * @type {any}
     * @memberof ImageApiCreate
     */
    readonly image?: any
}

/**
 * ImageApi - object-oriented interface
 * @export
 * @class ImageApi
 * @extends {BaseAPI}
 */
export class ImageApi extends BaseAPI {
    /**
     * Receives multipart/form-data. Example JS code for uploading images:        var image = $(\'input[name=image]\')[0];      if (image.files.length > 0) {            var settings = {              generateThumbnail: $(\'input[name=generateThumbnail]\')[0].checked,              generateGrayscale: $(\'input[name=generateGrayscale]\')[0].checked,              generateThumbnailGrayscale: $(\'input[name=generateThumbnailGrayscale]\')[0].checked          };            var payload = new FormData();          payload.append(\'settings\', new Blob([JSON.stringify(settings)], { type: \'application/json\' }));          payload.append(\'image\', image.files[0], image.filename);            $.ajax({                  url: \'http://local.bms-api.com/api/v1/images\',              type: \'POST\',              data: payload,              success: function(data) {                      alert(JSON.stringify(data));                  },              cache: false,              contentType: false,              processData: false          });      }  
     * @param {ImageApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public create(requestParameters: ImageApiCreateRequest = {}, options?: any) {
        return ImageApiFp(this.configuration).create(requestParameters.settings, requestParameters.image, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImportApi - axios parameter creator
 * @export
 */
export const ImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UploadCustomerPurchasesRequest} [uploadCustomerPurchasesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCustomerPurchases: async (uploadCustomerPurchasesRequest?: UploadCustomerPurchasesRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/import/customers-purchases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadCustomerPurchasesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadProductsRequest} [uploadProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProducts: async (uploadProductsRequest?: UploadProductsRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/import/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadProductsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImportApi - functional programming interface
 * @export
 */
export const ImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UploadCustomerPurchasesRequest} [uploadCustomerPurchasesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadCustomerPurchases(uploadCustomerPurchasesRequest?: UploadCustomerPurchasesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadCustomerPurchases(uploadCustomerPurchasesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadProductsRequest} [uploadProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadProducts(uploadProductsRequest?: UploadProductsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadProducts(uploadProductsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImportApi - factory interface
 * @export
 */
export const ImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImportApiFp(configuration)
    return {
        /**
         * 
         * @param {UploadCustomerPurchasesRequest} [uploadCustomerPurchasesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCustomerPurchases(uploadCustomerPurchasesRequest?: UploadCustomerPurchasesRequest, options?: any): AxiosPromise<ImportResponse> {
            return localVarFp.uploadCustomerPurchases(uploadCustomerPurchasesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadProductsRequest} [uploadProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProducts(uploadProductsRequest?: UploadProductsRequest, options?: any): AxiosPromise<ImportResponse> {
            return localVarFp.uploadProducts(uploadProductsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for uploadCustomerPurchases operation in ImportApi.
 * @export
 * @interface ImportApiUploadCustomerPurchasesRequest
 */
export interface ImportApiUploadCustomerPurchasesRequest {
    /**
     * 
     * @type {UploadCustomerPurchasesRequest}
     * @memberof ImportApiUploadCustomerPurchases
     */
    readonly uploadCustomerPurchasesRequest?: UploadCustomerPurchasesRequest
}

/**
 * Request parameters for uploadProducts operation in ImportApi.
 * @export
 * @interface ImportApiUploadProductsRequest
 */
export interface ImportApiUploadProductsRequest {
    /**
     * 
     * @type {UploadProductsRequest}
     * @memberof ImportApiUploadProducts
     */
    readonly uploadProductsRequest?: UploadProductsRequest
}

/**
 * ImportApi - object-oriented interface
 * @export
 * @class ImportApi
 * @extends {BaseAPI}
 */
export class ImportApi extends BaseAPI {
    /**
     * 
     * @param {ImportApiUploadCustomerPurchasesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public uploadCustomerPurchases(requestParameters: ImportApiUploadCustomerPurchasesRequest = {}, options?: any) {
        return ImportApiFp(this.configuration).uploadCustomerPurchases(requestParameters.uploadCustomerPurchasesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImportApiUploadProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public uploadProducts(requestParameters: ImportApiUploadProductsRequest = {}, options?: any) {
        return ImportApiFp(this.configuration).uploadProducts(requestParameters.uploadProductsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocationsApi - axios parameter creator
 * @export
 */
export const LocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} countryId 
         * @param {string} [search] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitiesForCountry: async (countryId: number, search?: string, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('getCitiesForCountry', 'countryId', countryId)
            const localVarPath = `/api/v1/locations/countries/{countryId}/cities`
                .replace(`{${"countryId"}}`, encodeURIComponent(String(countryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries: async (search?: string, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/locations/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [excludeAreas] 
         * @param {boolean} [excludeBeacons] 
         * @param {boolean} [excludeStores] 
         * @param {boolean} [excludeCities] 
         * @param {boolean} [excludeCountries] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocations: async (excludeAreas?: boolean, excludeBeacons?: boolean, excludeStores?: boolean, excludeCities?: boolean, excludeCountries?: boolean, search?: string, skip?: number, limit?: number, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (excludeAreas !== undefined) {
                localVarQueryParameter['excludeAreas'] = excludeAreas;
            }

            if (excludeBeacons !== undefined) {
                localVarQueryParameter['excludeBeacons'] = excludeBeacons;
            }

            if (excludeStores !== undefined) {
                localVarQueryParameter['excludeStores'] = excludeStores;
            }

            if (excludeCities !== undefined) {
                localVarQueryParameter['excludeCities'] = excludeCities;
            }

            if (excludeCountries !== undefined) {
                localVarQueryParameter['excludeCountries'] = excludeCountries;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorldHeatmap: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/locations/heatmap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationsApi - functional programming interface
 * @export
 */
export const LocationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} countryId 
         * @param {string} [search] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitiesForCountry(countryId: number, search?: string, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CityView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitiesForCountry(countryId, search, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountries(search?: string, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountries(search, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [excludeAreas] 
         * @param {boolean} [excludeBeacons] 
         * @param {boolean} [excludeStores] 
         * @param {boolean} [excludeCities] 
         * @param {boolean} [excludeCountries] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocations(excludeAreas?: boolean, excludeBeacons?: boolean, excludeStores?: boolean, excludeCities?: boolean, excludeCountries?: boolean, search?: string, skip?: number, limit?: number, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocations(excludeAreas, excludeBeacons, excludeStores, excludeCities, excludeCountries, search, skip, limit, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorldHeatmap(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorldHeatmapMarkerView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorldHeatmap(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationsApi - factory interface
 * @export
 */
export const LocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} countryId 
         * @param {string} [search] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitiesForCountry(countryId: number, search?: string, limit?: number, options?: any): AxiosPromise<Array<CityView>> {
            return localVarFp.getCitiesForCountry(countryId, search, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries(search?: string, limit?: number, options?: any): AxiosPromise<Array<CountryView>> {
            return localVarFp.getCountries(search, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [excludeAreas] 
         * @param {boolean} [excludeBeacons] 
         * @param {boolean} [excludeStores] 
         * @param {boolean} [excludeCities] 
         * @param {boolean} [excludeCountries] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocations(excludeAreas?: boolean, excludeBeacons?: boolean, excludeStores?: boolean, excludeCities?: boolean, excludeCountries?: boolean, search?: string, skip?: number, limit?: number, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<Array<LocationView>> {
            return localVarFp.getLocations(excludeAreas, excludeBeacons, excludeStores, excludeCities, excludeCountries, search, skip, limit, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorldHeatmap(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<Array<WorldHeatmapMarkerView>> {
            return localVarFp.getWorldHeatmap(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCitiesForCountry operation in LocationsApi.
 * @export
 * @interface LocationsApiGetCitiesForCountryRequest
 */
export interface LocationsApiGetCitiesForCountryRequest {
    /**
     * 
     * @type {number}
     * @memberof LocationsApiGetCitiesForCountry
     */
    readonly countryId: number

    /**
     * Default: null
     * @type {string}
     * @memberof LocationsApiGetCitiesForCountry
     */
    readonly search?: string

    /**
     * Default: null
     * @type {number}
     * @memberof LocationsApiGetCitiesForCountry
     */
    readonly limit?: number
}

/**
 * Request parameters for getCountries operation in LocationsApi.
 * @export
 * @interface LocationsApiGetCountriesRequest
 */
export interface LocationsApiGetCountriesRequest {
    /**
     * Default: null
     * @type {string}
     * @memberof LocationsApiGetCountries
     */
    readonly search?: string

    /**
     * Default: null
     * @type {number}
     * @memberof LocationsApiGetCountries
     */
    readonly limit?: number
}

/**
 * Request parameters for getLocations operation in LocationsApi.
 * @export
 * @interface LocationsApiGetLocationsRequest
 */
export interface LocationsApiGetLocationsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetLocations
     */
    readonly excludeAreas?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetLocations
     */
    readonly excludeBeacons?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetLocations
     */
    readonly excludeStores?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetLocations
     */
    readonly excludeCities?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetLocations
     */
    readonly excludeCountries?: boolean

    /**
     * 
     * @type {string}
     * @memberof LocationsApiGetLocations
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof LocationsApiGetLocations
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof LocationsApiGetLocations
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof LocationsApiGetLocations
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof LocationsApiGetLocations
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof LocationsApiGetLocations
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof LocationsApiGetLocations
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof LocationsApiGetLocations
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getWorldHeatmap operation in LocationsApi.
 * @export
 * @interface LocationsApiGetWorldHeatmapRequest
 */
export interface LocationsApiGetWorldHeatmapRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof LocationsApiGetWorldHeatmap
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof LocationsApiGetWorldHeatmap
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof LocationsApiGetWorldHeatmap
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof LocationsApiGetWorldHeatmap
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof LocationsApiGetWorldHeatmap
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof LocationsApiGetWorldHeatmap
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof LocationsApiGetWorldHeatmap
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof LocationsApiGetWorldHeatmap
     */
    readonly beacons?: Array<string>
}

/**
 * LocationsApi - object-oriented interface
 * @export
 * @class LocationsApi
 * @extends {BaseAPI}
 */
export class LocationsApi extends BaseAPI {
    /**
     * 
     * @param {LocationsApiGetCitiesForCountryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public getCitiesForCountry(requestParameters: LocationsApiGetCitiesForCountryRequest, options?: any) {
        return LocationsApiFp(this.configuration).getCitiesForCountry(requestParameters.countryId, requestParameters.search, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationsApiGetCountriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public getCountries(requestParameters: LocationsApiGetCountriesRequest = {}, options?: any) {
        return LocationsApiFp(this.configuration).getCountries(requestParameters.search, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationsApiGetLocationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public getLocations(requestParameters: LocationsApiGetLocationsRequest = {}, options?: any) {
        return LocationsApiFp(this.configuration).getLocations(requestParameters.excludeAreas, requestParameters.excludeBeacons, requestParameters.excludeStores, requestParameters.excludeCities, requestParameters.excludeCountries, requestParameters.search, requestParameters.skip, requestParameters.limit, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationsApiGetWorldHeatmapRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public getWorldHeatmap(requestParameters: LocationsApiGetWorldHeatmapRequest = {}, options?: any) {
        return LocationsApiFp(this.configuration).getWorldHeatmap(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessagesApi - axios parameter creator
 * @export
 */
export const MessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (messageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('_delete', 'messageId', messageId)
            const localVarPath = `/api/messages/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {number} [messageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearMessageStatistics: async (userId?: string, messageId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (messageId !== undefined) {
                localVarQueryParameter['messageId'] = messageId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [draftId] 
         * @param {MessageModel} [messageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (draftId?: number, messageModel?: MessageModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (draftId !== undefined) {
                localVarQueryParameter['draftId'] = draftId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} messageId 
         * @param {ForceSendMessageRequest} [forceSendMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceSendMessage: async (messageId: number, forceSendMessageRequest?: ForceSendMessageRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('forceSendMessage', 'messageId', messageId)
            const localVarPath = `/api/messages/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forceSendMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] Default: null
         * @param {Array<number>} [campaignIds] Default: null
         * @param {boolean} [includeInactive] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {MessageSort} [sort] Default: null
         * @param {MessageStatus} [status] Default: null
         * @param {IsoDateTimeString} [validFrom] Default: null
         * @param {IsoDateTimeString} [validTo] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (search?: string, campaignIds?: Array<number>, includeInactive?: boolean, skip?: number, limit?: number, sort?: MessageSort, status?: MessageStatus, validFrom?: IsoDateTimeString, validTo?: IsoDateTimeString, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (campaignIds) {
                localVarQueryParameter['campaignIds'] = campaignIds;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (validFrom !== undefined) {
                localVarQueryParameter['validFrom'] = (validFrom as any instanceof Date) ?
                    (validFrom as any).toISOString() :
                    validFrom;
            }

            if (validTo !== undefined) {
                localVarQueryParameter['validTo'] = (validTo as any instanceof Date) ?
                    (validTo as any).toISOString() :
                    validTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForLookup: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {CustomAudienceType} [audienceType] Default: null
         * @param {Array<number>} [campaignIds] Default: null
         * @param {boolean} [includeInactive] Default: null
         * @param {SortDirection} [sortDirection] Default: null
         * @param {MessageInteractionPerformanceSortColumn} [sortColumn] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInteractionPerformance: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, audienceType?: CustomAudienceType, campaignIds?: Array<number>, includeInactive?: boolean, sortDirection?: SortDirection, sortColumn?: MessageInteractionPerformanceSortColumn, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/performance/interaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }

            if (audienceType !== undefined) {
                localVarQueryParameter['audienceType'] = audienceType;
            }

            if (campaignIds) {
                localVarQueryParameter['campaignIds'] = campaignIds;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['sortColumn'] = sortColumn;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [offerTitle] 
         * @param {string} [offerText] 
         * @param {string} [imageUrl] 
         * @param {boolean} [isRedeemable] 
         * @param {string} [callToActionTitle] 
         * @param {string} [callToActionLink] 
         * @param {boolean} [disableOnclicks] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferPreview: async (offerTitle?: string, offerText?: string, imageUrl?: string, isRedeemable?: boolean, callToActionTitle?: string, callToActionLink?: string, disableOnclicks?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/preview/editor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offerTitle !== undefined) {
                localVarQueryParameter['offerTitle'] = offerTitle;
            }

            if (offerText !== undefined) {
                localVarQueryParameter['offerText'] = offerText;
            }

            if (imageUrl !== undefined) {
                localVarQueryParameter['imageUrl'] = imageUrl;
            }

            if (isRedeemable !== undefined) {
                localVarQueryParameter['isRedeemable'] = isRedeemable;
            }

            if (callToActionTitle !== undefined) {
                localVarQueryParameter['callToActionTitle'] = callToActionTitle;
            }

            if (callToActionLink !== undefined) {
                localVarQueryParameter['callToActionLink'] = callToActionLink;
            }

            if (disableOnclicks !== undefined) {
                localVarQueryParameter['disableOnclicks'] = disableOnclicks;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} messageId 
         * @param {number} [draftId] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne: async (messageId: number, draftId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('getOne', 'messageId', messageId)
            const localVarPath = `/api/messages/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (draftId !== undefined) {
                localVarQueryParameter['draftId'] = draftId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [messageId] 
         * @param {NotificationMark} [flag] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerformanceNotificationUsers: async (messageId?: number, flag?: NotificationMark, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/performance/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (messageId !== undefined) {
                localVarQueryParameter['messageId'] = messageId;
            }

            if (flag !== undefined) {
                localVarQueryParameter['flag'] = flag;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {CustomAudienceType} [audienceType] Default: null
         * @param {Array<number>} [campaignIds] Default: null
         * @param {boolean} [includeInactive] Default: null
         * @param {SortDirection} [sortDirection] Default: null
         * @param {MessageRevenuePerformanceSortColumn} [sortColumn] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevenuePerformance: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, audienceType?: CustomAudienceType, campaignIds?: Array<number>, includeInactive?: boolean, sortDirection?: SortDirection, sortColumn?: MessageRevenuePerformanceSortColumn, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/performance/revenue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }

            if (audienceType !== undefined) {
                localVarQueryParameter['audienceType'] = audienceType;
            }

            if (campaignIds) {
                localVarQueryParameter['campaignIds'] = campaignIds;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['sortColumn'] = sortColumn;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} messageId 
         * @param {number} [draftId] 
         * @param {MessageModel} [messageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (messageId: number, draftId?: number, messageModel?: MessageModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('update', 'messageId', messageId)
            const localVarPath = `/api/messages/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (draftId !== undefined) {
                localVarQueryParameter['draftId'] = draftId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagesApi - functional programming interface
 * @export
 */
export const MessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(messageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {number} [messageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearMessageStatistics(userId?: string, messageId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearMessageStatistics(userId, messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [draftId] 
         * @param {MessageModel} [messageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(draftId?: number, messageModel?: MessageModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(draftId, messageModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} messageId 
         * @param {ForceSendMessageRequest} [forceSendMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forceSendMessage(messageId: number, forceSendMessageRequest?: ForceSendMessageRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SentMessageView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forceSendMessage(messageId, forceSendMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] Default: null
         * @param {Array<number>} [campaignIds] Default: null
         * @param {boolean} [includeInactive] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {MessageSort} [sort] Default: null
         * @param {MessageStatus} [status] Default: null
         * @param {IsoDateTimeString} [validFrom] Default: null
         * @param {IsoDateTimeString} [validTo] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(search?: string, campaignIds?: Array<number>, includeInactive?: boolean, skip?: number, limit?: number, sort?: MessageSort, status?: MessageStatus, validFrom?: IsoDateTimeString, validTo?: IsoDateTimeString, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(search, campaignIds, includeInactive, skip, limit, sort, status, validFrom, validTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForLookup(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessageLookupView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getForLookup(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {CustomAudienceType} [audienceType] Default: null
         * @param {Array<number>} [campaignIds] Default: null
         * @param {boolean} [includeInactive] Default: null
         * @param {SortDirection} [sortDirection] Default: null
         * @param {MessageInteractionPerformanceSortColumn} [sortColumn] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInteractionPerformance(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, audienceType?: CustomAudienceType, campaignIds?: Array<number>, includeInactive?: boolean, sortDirection?: SortDirection, sortColumn?: MessageInteractionPerformanceSortColumn, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageInteractionPerformanceViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInteractionPerformance(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, audienceType, campaignIds, includeInactive, sortDirection, sortColumn, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [offerTitle] 
         * @param {string} [offerText] 
         * @param {string} [imageUrl] 
         * @param {boolean} [isRedeemable] 
         * @param {string} [callToActionTitle] 
         * @param {string} [callToActionLink] 
         * @param {boolean} [disableOnclicks] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferPreview(offerTitle?: string, offerText?: string, imageUrl?: string, isRedeemable?: boolean, callToActionTitle?: string, callToActionLink?: string, disableOnclicks?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferPreview(offerTitle, offerText, imageUrl, isRedeemable, callToActionTitle, callToActionLink, disableOnclicks, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} messageId 
         * @param {number} [draftId] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOne(messageId: number, draftId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOne(messageId, draftId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [messageId] 
         * @param {NotificationMark} [flag] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerformanceNotificationUsers(messageId?: number, flag?: NotificationMark, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationUserViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerformanceNotificationUsers(messageId, flag, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {CustomAudienceType} [audienceType] Default: null
         * @param {Array<number>} [campaignIds] Default: null
         * @param {boolean} [includeInactive] Default: null
         * @param {SortDirection} [sortDirection] Default: null
         * @param {MessageRevenuePerformanceSortColumn} [sortColumn] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRevenuePerformance(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, audienceType?: CustomAudienceType, campaignIds?: Array<number>, includeInactive?: boolean, sortDirection?: SortDirection, sortColumn?: MessageRevenuePerformanceSortColumn, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageRevenuePerformanceViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRevenuePerformance(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, audienceType, campaignIds, includeInactive, sortDirection, sortColumn, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} messageId 
         * @param {number} [draftId] 
         * @param {MessageModel} [messageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(messageId: number, draftId?: number, messageModel?: MessageModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(messageId, draftId, messageModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessagesApi - factory interface
 * @export
 */
export const MessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessagesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(messageId: number, options?: any): AxiosPromise<ServiceResult> {
            return localVarFp._delete(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {number} [messageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearMessageStatistics(userId?: string, messageId?: number, options?: any): AxiosPromise<ServiceResult> {
            return localVarFp.clearMessageStatistics(userId, messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [draftId] 
         * @param {MessageModel} [messageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(draftId?: number, messageModel?: MessageModel, options?: any): AxiosPromise<ServiceResult> {
            return localVarFp.create(draftId, messageModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} messageId 
         * @param {ForceSendMessageRequest} [forceSendMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceSendMessage(messageId: number, forceSendMessageRequest?: ForceSendMessageRequest, options?: any): AxiosPromise<SentMessageView> {
            return localVarFp.forceSendMessage(messageId, forceSendMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] Default: null
         * @param {Array<number>} [campaignIds] Default: null
         * @param {boolean} [includeInactive] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {MessageSort} [sort] Default: null
         * @param {MessageStatus} [status] Default: null
         * @param {IsoDateTimeString} [validFrom] Default: null
         * @param {IsoDateTimeString} [validTo] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(search?: string, campaignIds?: Array<number>, includeInactive?: boolean, skip?: number, limit?: number, sort?: MessageSort, status?: MessageStatus, validFrom?: IsoDateTimeString, validTo?: IsoDateTimeString, options?: any): AxiosPromise<MessageViewDataPage> {
            return localVarFp.get(search, campaignIds, includeInactive, skip, limit, sort, status, validFrom, validTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForLookup(options?: any): AxiosPromise<Array<MessageLookupView>> {
            return localVarFp.getForLookup(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {CustomAudienceType} [audienceType] Default: null
         * @param {Array<number>} [campaignIds] Default: null
         * @param {boolean} [includeInactive] Default: null
         * @param {SortDirection} [sortDirection] Default: null
         * @param {MessageInteractionPerformanceSortColumn} [sortColumn] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInteractionPerformance(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, audienceType?: CustomAudienceType, campaignIds?: Array<number>, includeInactive?: boolean, sortDirection?: SortDirection, sortColumn?: MessageInteractionPerformanceSortColumn, skip?: number, limit?: number, options?: any): AxiosPromise<MessageInteractionPerformanceViewDataPage> {
            return localVarFp.getInteractionPerformance(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, audienceType, campaignIds, includeInactive, sortDirection, sortColumn, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [offerTitle] 
         * @param {string} [offerText] 
         * @param {string} [imageUrl] 
         * @param {boolean} [isRedeemable] 
         * @param {string} [callToActionTitle] 
         * @param {string} [callToActionLink] 
         * @param {boolean} [disableOnclicks] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferPreview(offerTitle?: string, offerText?: string, imageUrl?: string, isRedeemable?: boolean, callToActionTitle?: string, callToActionLink?: string, disableOnclicks?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.getOfferPreview(offerTitle, offerText, imageUrl, isRedeemable, callToActionTitle, callToActionLink, disableOnclicks, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} messageId 
         * @param {number} [draftId] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne(messageId: number, draftId?: number, options?: any): AxiosPromise<MessageDetails> {
            return localVarFp.getOne(messageId, draftId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [messageId] 
         * @param {NotificationMark} [flag] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerformanceNotificationUsers(messageId?: number, flag?: NotificationMark, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, limit?: number, options?: any): AxiosPromise<NotificationUserViewDataPage> {
            return localVarFp.getPerformanceNotificationUsers(messageId, flag, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {CustomAudienceType} [audienceType] Default: null
         * @param {Array<number>} [campaignIds] Default: null
         * @param {boolean} [includeInactive] Default: null
         * @param {SortDirection} [sortDirection] Default: null
         * @param {MessageRevenuePerformanceSortColumn} [sortColumn] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevenuePerformance(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, audienceType?: CustomAudienceType, campaignIds?: Array<number>, includeInactive?: boolean, sortDirection?: SortDirection, sortColumn?: MessageRevenuePerformanceSortColumn, skip?: number, limit?: number, options?: any): AxiosPromise<MessageRevenuePerformanceViewDataPage> {
            return localVarFp.getRevenuePerformance(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, audienceType, campaignIds, includeInactive, sortDirection, sortColumn, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} messageId 
         * @param {number} [draftId] 
         * @param {MessageModel} [messageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(messageId: number, draftId?: number, messageModel?: MessageModel, options?: any): AxiosPromise<ServiceResult> {
            return localVarFp.update(messageId, draftId, messageModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in MessagesApi.
 * @export
 * @interface MessagesApiDeleteRequest
 */
export interface MessagesApiDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof MessagesApiDelete
     */
    readonly messageId: number
}

/**
 * Request parameters for clearMessageStatistics operation in MessagesApi.
 * @export
 * @interface MessagesApiClearMessageStatisticsRequest
 */
export interface MessagesApiClearMessageStatisticsRequest {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiClearMessageStatistics
     */
    readonly userId?: string

    /**
     * 
     * @type {number}
     * @memberof MessagesApiClearMessageStatistics
     */
    readonly messageId?: number
}

/**
 * Request parameters for create operation in MessagesApi.
 * @export
 * @interface MessagesApiCreateRequest
 */
export interface MessagesApiCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof MessagesApiCreate
     */
    readonly draftId?: number

    /**
     * 
     * @type {MessageModel}
     * @memberof MessagesApiCreate
     */
    readonly messageModel?: MessageModel
}

/**
 * Request parameters for forceSendMessage operation in MessagesApi.
 * @export
 * @interface MessagesApiForceSendMessageRequest
 */
export interface MessagesApiForceSendMessageRequest {
    /**
     * 
     * @type {number}
     * @memberof MessagesApiForceSendMessage
     */
    readonly messageId: number

    /**
     * 
     * @type {ForceSendMessageRequest}
     * @memberof MessagesApiForceSendMessage
     */
    readonly forceSendMessageRequest?: ForceSendMessageRequest
}

/**
 * Request parameters for get operation in MessagesApi.
 * @export
 * @interface MessagesApiGetRequest
 */
export interface MessagesApiGetRequest {
    /**
     * Default: null
     * @type {string}
     * @memberof MessagesApiGet
     */
    readonly search?: string

    /**
     * Default: null
     * @type {Array<number>}
     * @memberof MessagesApiGet
     */
    readonly campaignIds?: Array<number>

    /**
     * Default: null
     * @type {boolean}
     * @memberof MessagesApiGet
     */
    readonly includeInactive?: boolean

    /**
     * Default: null
     * @type {number}
     * @memberof MessagesApiGet
     */
    readonly skip?: number

    /**
     * Default: null
     * @type {number}
     * @memberof MessagesApiGet
     */
    readonly limit?: number

    /**
     * Default: null
     * @type {MessageSort}
     * @memberof MessagesApiGet
     */
    readonly sort?: MessageSort

    /**
     * Default: null
     * @type {MessageStatus}
     * @memberof MessagesApiGet
     */
    readonly status?: MessageStatus

    /**
     * Default: null
     * @type {IsoDateTimeString}
     * @memberof MessagesApiGet
     */
    readonly validFrom?: IsoDateTimeString

    /**
     * Default: null
     * @type {IsoDateTimeString}
     * @memberof MessagesApiGet
     */
    readonly validTo?: IsoDateTimeString
}

/**
 * Request parameters for getInteractionPerformance operation in MessagesApi.
 * @export
 * @interface MessagesApiGetInteractionPerformanceRequest
 */
export interface MessagesApiGetInteractionPerformanceRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof MessagesApiGetInteractionPerformance
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof MessagesApiGetInteractionPerformance
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof MessagesApiGetInteractionPerformance
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof MessagesApiGetInteractionPerformance
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MessagesApiGetInteractionPerformance
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MessagesApiGetInteractionPerformance
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MessagesApiGetInteractionPerformance
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof MessagesApiGetInteractionPerformance
     */
    readonly beacons?: Array<string>

    /**
     * Default: null
     * @type {CustomAudienceType}
     * @memberof MessagesApiGetInteractionPerformance
     */
    readonly audienceType?: CustomAudienceType

    /**
     * Default: null
     * @type {Array<number>}
     * @memberof MessagesApiGetInteractionPerformance
     */
    readonly campaignIds?: Array<number>

    /**
     * Default: null
     * @type {boolean}
     * @memberof MessagesApiGetInteractionPerformance
     */
    readonly includeInactive?: boolean

    /**
     * Default: null
     * @type {SortDirection}
     * @memberof MessagesApiGetInteractionPerformance
     */
    readonly sortDirection?: SortDirection

    /**
     * Default: null
     * @type {MessageInteractionPerformanceSortColumn}
     * @memberof MessagesApiGetInteractionPerformance
     */
    readonly sortColumn?: MessageInteractionPerformanceSortColumn

    /**
     * Default: null
     * @type {number}
     * @memberof MessagesApiGetInteractionPerformance
     */
    readonly skip?: number

    /**
     * Default: null
     * @type {number}
     * @memberof MessagesApiGetInteractionPerformance
     */
    readonly limit?: number
}

/**
 * Request parameters for getOfferPreview operation in MessagesApi.
 * @export
 * @interface MessagesApiGetOfferPreviewRequest
 */
export interface MessagesApiGetOfferPreviewRequest {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiGetOfferPreview
     */
    readonly offerTitle?: string

    /**
     * 
     * @type {string}
     * @memberof MessagesApiGetOfferPreview
     */
    readonly offerText?: string

    /**
     * 
     * @type {string}
     * @memberof MessagesApiGetOfferPreview
     */
    readonly imageUrl?: string

    /**
     * 
     * @type {boolean}
     * @memberof MessagesApiGetOfferPreview
     */
    readonly isRedeemable?: boolean

    /**
     * 
     * @type {string}
     * @memberof MessagesApiGetOfferPreview
     */
    readonly callToActionTitle?: string

    /**
     * 
     * @type {string}
     * @memberof MessagesApiGetOfferPreview
     */
    readonly callToActionLink?: string

    /**
     * 
     * @type {boolean}
     * @memberof MessagesApiGetOfferPreview
     */
    readonly disableOnclicks?: boolean
}

/**
 * Request parameters for getOne operation in MessagesApi.
 * @export
 * @interface MessagesApiGetOneRequest
 */
export interface MessagesApiGetOneRequest {
    /**
     * 
     * @type {number}
     * @memberof MessagesApiGetOne
     */
    readonly messageId: number

    /**
     * Default: null
     * @type {number}
     * @memberof MessagesApiGetOne
     */
    readonly draftId?: number
}

/**
 * Request parameters for getPerformanceNotificationUsers operation in MessagesApi.
 * @export
 * @interface MessagesApiGetPerformanceNotificationUsersRequest
 */
export interface MessagesApiGetPerformanceNotificationUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof MessagesApiGetPerformanceNotificationUsers
     */
    readonly messageId?: number

    /**
     * 
     * @type {NotificationMark}
     * @memberof MessagesApiGetPerformanceNotificationUsers
     */
    readonly flag?: NotificationMark

    /**
     * 
     * @type {TimeframeType}
     * @memberof MessagesApiGetPerformanceNotificationUsers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof MessagesApiGetPerformanceNotificationUsers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof MessagesApiGetPerformanceNotificationUsers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof MessagesApiGetPerformanceNotificationUsers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MessagesApiGetPerformanceNotificationUsers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MessagesApiGetPerformanceNotificationUsers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MessagesApiGetPerformanceNotificationUsers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof MessagesApiGetPerformanceNotificationUsers
     */
    readonly beacons?: Array<string>

    /**
     * Default: null
     * @type {number}
     * @memberof MessagesApiGetPerformanceNotificationUsers
     */
    readonly skip?: number

    /**
     * Default: null
     * @type {number}
     * @memberof MessagesApiGetPerformanceNotificationUsers
     */
    readonly limit?: number
}

/**
 * Request parameters for getRevenuePerformance operation in MessagesApi.
 * @export
 * @interface MessagesApiGetRevenuePerformanceRequest
 */
export interface MessagesApiGetRevenuePerformanceRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof MessagesApiGetRevenuePerformance
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof MessagesApiGetRevenuePerformance
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof MessagesApiGetRevenuePerformance
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof MessagesApiGetRevenuePerformance
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MessagesApiGetRevenuePerformance
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MessagesApiGetRevenuePerformance
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MessagesApiGetRevenuePerformance
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof MessagesApiGetRevenuePerformance
     */
    readonly beacons?: Array<string>

    /**
     * Default: null
     * @type {CustomAudienceType}
     * @memberof MessagesApiGetRevenuePerformance
     */
    readonly audienceType?: CustomAudienceType

    /**
     * Default: null
     * @type {Array<number>}
     * @memberof MessagesApiGetRevenuePerformance
     */
    readonly campaignIds?: Array<number>

    /**
     * Default: null
     * @type {boolean}
     * @memberof MessagesApiGetRevenuePerformance
     */
    readonly includeInactive?: boolean

    /**
     * Default: null
     * @type {SortDirection}
     * @memberof MessagesApiGetRevenuePerformance
     */
    readonly sortDirection?: SortDirection

    /**
     * Default: null
     * @type {MessageRevenuePerformanceSortColumn}
     * @memberof MessagesApiGetRevenuePerformance
     */
    readonly sortColumn?: MessageRevenuePerformanceSortColumn

    /**
     * Default: null
     * @type {number}
     * @memberof MessagesApiGetRevenuePerformance
     */
    readonly skip?: number

    /**
     * Default: null
     * @type {number}
     * @memberof MessagesApiGetRevenuePerformance
     */
    readonly limit?: number
}

/**
 * Request parameters for update operation in MessagesApi.
 * @export
 * @interface MessagesApiUpdateRequest
 */
export interface MessagesApiUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof MessagesApiUpdate
     */
    readonly messageId: number

    /**
     * 
     * @type {number}
     * @memberof MessagesApiUpdate
     */
    readonly draftId?: number

    /**
     * 
     * @type {MessageModel}
     * @memberof MessagesApiUpdate
     */
    readonly messageModel?: MessageModel
}

/**
 * MessagesApi - object-oriented interface
 * @export
 * @class MessagesApi
 * @extends {BaseAPI}
 */
export class MessagesApi extends BaseAPI {
    /**
     * 
     * @param {MessagesApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public _delete(requestParameters: MessagesApiDeleteRequest, options?: any) {
        return MessagesApiFp(this.configuration)._delete(requestParameters.messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessagesApiClearMessageStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public clearMessageStatistics(requestParameters: MessagesApiClearMessageStatisticsRequest = {}, options?: any) {
        return MessagesApiFp(this.configuration).clearMessageStatistics(requestParameters.userId, requestParameters.messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessagesApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public create(requestParameters: MessagesApiCreateRequest = {}, options?: any) {
        return MessagesApiFp(this.configuration).create(requestParameters.draftId, requestParameters.messageModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessagesApiForceSendMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public forceSendMessage(requestParameters: MessagesApiForceSendMessageRequest, options?: any) {
        return MessagesApiFp(this.configuration).forceSendMessage(requestParameters.messageId, requestParameters.forceSendMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessagesApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public get(requestParameters: MessagesApiGetRequest = {}, options?: any) {
        return MessagesApiFp(this.configuration).get(requestParameters.search, requestParameters.campaignIds, requestParameters.includeInactive, requestParameters.skip, requestParameters.limit, requestParameters.sort, requestParameters.status, requestParameters.validFrom, requestParameters.validTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getForLookup(options?: any) {
        return MessagesApiFp(this.configuration).getForLookup(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessagesApiGetInteractionPerformanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getInteractionPerformance(requestParameters: MessagesApiGetInteractionPerformanceRequest = {}, options?: any) {
        return MessagesApiFp(this.configuration).getInteractionPerformance(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, requestParameters.audienceType, requestParameters.campaignIds, requestParameters.includeInactive, requestParameters.sortDirection, requestParameters.sortColumn, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessagesApiGetOfferPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getOfferPreview(requestParameters: MessagesApiGetOfferPreviewRequest = {}, options?: any) {
        return MessagesApiFp(this.configuration).getOfferPreview(requestParameters.offerTitle, requestParameters.offerText, requestParameters.imageUrl, requestParameters.isRedeemable, requestParameters.callToActionTitle, requestParameters.callToActionLink, requestParameters.disableOnclicks, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessagesApiGetOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getOne(requestParameters: MessagesApiGetOneRequest, options?: any) {
        return MessagesApiFp(this.configuration).getOne(requestParameters.messageId, requestParameters.draftId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessagesApiGetPerformanceNotificationUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getPerformanceNotificationUsers(requestParameters: MessagesApiGetPerformanceNotificationUsersRequest = {}, options?: any) {
        return MessagesApiFp(this.configuration).getPerformanceNotificationUsers(requestParameters.messageId, requestParameters.flag, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessagesApiGetRevenuePerformanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getRevenuePerformance(requestParameters: MessagesApiGetRevenuePerformanceRequest = {}, options?: any) {
        return MessagesApiFp(this.configuration).getRevenuePerformance(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, requestParameters.audienceType, requestParameters.campaignIds, requestParameters.includeInactive, requestParameters.sortDirection, requestParameters.sortColumn, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessagesApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public update(requestParameters: MessagesApiUpdateRequest, options?: any) {
        return MessagesApiFp(this.configuration).update(requestParameters.messageId, requestParameters.draftId, requestParameters.messageModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferAudienceApi - axios parameter creator
 * @export
 */
export const OfferAudienceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CustomAudienceExpression} [customAudienceExpression] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudienceEstimate: async (customAudienceExpression?: CustomAudienceExpression, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/audiences/estimate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customAudienceExpression, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferAudienceApi - functional programming interface
 * @export
 */
export const OfferAudienceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferAudienceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CustomAudienceExpression} [customAudienceExpression] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAudienceEstimate(customAudienceExpression?: CustomAudienceExpression, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAudienceEstimate(customAudienceExpression, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferAudienceApi - factory interface
 * @export
 */
export const OfferAudienceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferAudienceApiFp(configuration)
    return {
        /**
         * 
         * @param {CustomAudienceExpression} [customAudienceExpression] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudienceEstimate(customAudienceExpression?: CustomAudienceExpression, options?: any): AxiosPromise<number> {
            return localVarFp.getAudienceEstimate(customAudienceExpression, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAudienceEstimate operation in OfferAudienceApi.
 * @export
 * @interface OfferAudienceApiGetAudienceEstimateRequest
 */
export interface OfferAudienceApiGetAudienceEstimateRequest {
    /**
     * 
     * @type {CustomAudienceExpression}
     * @memberof OfferAudienceApiGetAudienceEstimate
     */
    readonly customAudienceExpression?: CustomAudienceExpression
}

/**
 * OfferAudienceApi - object-oriented interface
 * @export
 * @class OfferAudienceApi
 * @extends {BaseAPI}
 */
export class OfferAudienceApi extends BaseAPI {
    /**
     * 
     * @param {OfferAudienceApiGetAudienceEstimateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferAudienceApi
     */
    public getAudienceEstimate(requestParameters: OfferAudienceApiGetAudienceEstimateRequest = {}, options?: any) {
        return OfferAudienceApiFp(this.configuration).getAudienceEstimate(requestParameters.customAudienceExpression, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferDraftApi - axios parameter creator
 * @export
 */
export const OfferDraftApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OfferDraftModel} [offerDraftModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraft: async (offerDraftModel?: OfferDraftModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/draft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerDraftModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} draftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDraft: async (draftId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'draftId' is not null or undefined
            assertParamExists('deleteDraft', 'draftId', draftId)
            const localVarPath = `/api/draft/{draftId}`
                .replace(`{${"draftId"}}`, encodeURIComponent(String(draftId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [campaignIds] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {MessageSort} [sort] Default: null
         * @param {IsoDateTimeString} [validFrom] Default: null
         * @param {IsoDateTimeString} [validTo] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrafts: async (campaignIds?: Array<number>, skip?: number, limit?: number, sort?: MessageSort, validFrom?: IsoDateTimeString, validTo?: IsoDateTimeString, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/draft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (campaignIds) {
                localVarQueryParameter['campaignIds'] = campaignIds;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (validFrom !== undefined) {
                localVarQueryParameter['validFrom'] = (validFrom as any instanceof Date) ?
                    (validFrom as any).toISOString() :
                    validFrom;
            }

            if (validTo !== undefined) {
                localVarQueryParameter['validTo'] = (validTo as any instanceof Date) ?
                    (validTo as any).toISOString() :
                    validTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OfferDraftModel} [offerDraftModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDraft: async (offerDraftModel?: OfferDraftModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/draft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerDraftModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferDraftApi - functional programming interface
 * @export
 */
export const OfferDraftApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferDraftApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {OfferDraftModel} [offerDraftModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDraft(offerDraftModel?: OfferDraftModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDraft(offerDraftModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} draftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDraft(draftId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDraft(draftId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [campaignIds] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {MessageSort} [sort] Default: null
         * @param {IsoDateTimeString} [validFrom] Default: null
         * @param {IsoDateTimeString} [validTo] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDrafts(campaignIds?: Array<number>, skip?: number, limit?: number, sort?: MessageSort, validFrom?: IsoDateTimeString, validTo?: IsoDateTimeString, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDrafts(campaignIds, skip, limit, sort, validFrom, validTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OfferDraftModel} [offerDraftModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDraft(offerDraftModel?: OfferDraftModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDraft(offerDraftModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferDraftApi - factory interface
 * @export
 */
export const OfferDraftApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferDraftApiFp(configuration)
    return {
        /**
         * 
         * @param {OfferDraftModel} [offerDraftModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraft(offerDraftModel?: OfferDraftModel, options?: any): AxiosPromise<ServiceResult> {
            return localVarFp.createDraft(offerDraftModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} draftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDraft(draftId: number, options?: any): AxiosPromise<ServiceResult> {
            return localVarFp.deleteDraft(draftId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [campaignIds] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {MessageSort} [sort] Default: null
         * @param {IsoDateTimeString} [validFrom] Default: null
         * @param {IsoDateTimeString} [validTo] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrafts(campaignIds?: Array<number>, skip?: number, limit?: number, sort?: MessageSort, validFrom?: IsoDateTimeString, validTo?: IsoDateTimeString, options?: any): AxiosPromise<MessageViewDataPage> {
            return localVarFp.getDrafts(campaignIds, skip, limit, sort, validFrom, validTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OfferDraftModel} [offerDraftModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDraft(offerDraftModel?: OfferDraftModel, options?: any): AxiosPromise<ServiceResult> {
            return localVarFp.updateDraft(offerDraftModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDraft operation in OfferDraftApi.
 * @export
 * @interface OfferDraftApiCreateDraftRequest
 */
export interface OfferDraftApiCreateDraftRequest {
    /**
     * 
     * @type {OfferDraftModel}
     * @memberof OfferDraftApiCreateDraft
     */
    readonly offerDraftModel?: OfferDraftModel
}

/**
 * Request parameters for deleteDraft operation in OfferDraftApi.
 * @export
 * @interface OfferDraftApiDeleteDraftRequest
 */
export interface OfferDraftApiDeleteDraftRequest {
    /**
     * 
     * @type {number}
     * @memberof OfferDraftApiDeleteDraft
     */
    readonly draftId: number
}

/**
 * Request parameters for getDrafts operation in OfferDraftApi.
 * @export
 * @interface OfferDraftApiGetDraftsRequest
 */
export interface OfferDraftApiGetDraftsRequest {
    /**
     * Default: null
     * @type {Array<number>}
     * @memberof OfferDraftApiGetDrafts
     */
    readonly campaignIds?: Array<number>

    /**
     * Default: null
     * @type {number}
     * @memberof OfferDraftApiGetDrafts
     */
    readonly skip?: number

    /**
     * Default: null
     * @type {number}
     * @memberof OfferDraftApiGetDrafts
     */
    readonly limit?: number

    /**
     * Default: null
     * @type {MessageSort}
     * @memberof OfferDraftApiGetDrafts
     */
    readonly sort?: MessageSort

    /**
     * Default: null
     * @type {IsoDateTimeString}
     * @memberof OfferDraftApiGetDrafts
     */
    readonly validFrom?: IsoDateTimeString

    /**
     * Default: null
     * @type {IsoDateTimeString}
     * @memberof OfferDraftApiGetDrafts
     */
    readonly validTo?: IsoDateTimeString
}

/**
 * Request parameters for updateDraft operation in OfferDraftApi.
 * @export
 * @interface OfferDraftApiUpdateDraftRequest
 */
export interface OfferDraftApiUpdateDraftRequest {
    /**
     * 
     * @type {OfferDraftModel}
     * @memberof OfferDraftApiUpdateDraft
     */
    readonly offerDraftModel?: OfferDraftModel
}

/**
 * OfferDraftApi - object-oriented interface
 * @export
 * @class OfferDraftApi
 * @extends {BaseAPI}
 */
export class OfferDraftApi extends BaseAPI {
    /**
     * 
     * @param {OfferDraftApiCreateDraftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferDraftApi
     */
    public createDraft(requestParameters: OfferDraftApiCreateDraftRequest = {}, options?: any) {
        return OfferDraftApiFp(this.configuration).createDraft(requestParameters.offerDraftModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OfferDraftApiDeleteDraftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferDraftApi
     */
    public deleteDraft(requestParameters: OfferDraftApiDeleteDraftRequest, options?: any) {
        return OfferDraftApiFp(this.configuration).deleteDraft(requestParameters.draftId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OfferDraftApiGetDraftsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferDraftApi
     */
    public getDrafts(requestParameters: OfferDraftApiGetDraftsRequest = {}, options?: any) {
        return OfferDraftApiFp(this.configuration).getDrafts(requestParameters.campaignIds, requestParameters.skip, requestParameters.limit, requestParameters.sort, requestParameters.validFrom, requestParameters.validTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OfferDraftApiUpdateDraftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferDraftApi
     */
    public updateDraft(requestParameters: OfferDraftApiUpdateDraftRequest = {}, options?: any) {
        return OfferDraftApiFp(this.configuration).updateDraft(requestParameters.offerDraftModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PasswordApi - axios parameter creator
 * @export
 */
export const PasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (changePasswordRequest?: ChangePasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DepreactedChangePasswordRequest} [depreactedChangePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecatedChangePassword: async (depreactedChangePasswordRequest?: DepreactedChangePasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/changePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(depreactedChangePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeprecatedResetPasswordRequest} [deprecatedResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecatedResetPassword: async (deprecatedResetPasswordRequest?: DeprecatedResetPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deprecatedResetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {boolean} [skipSendingEmail] Default: False
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecatedResetPasswordRequest: async (email?: string, skipSendingEmail?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/resetPasswordRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (skipSendingEmail !== undefined) {
                localVarQueryParameter['skipSendingEmail'] = skipSendingEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (forgotPasswordRequest?: ForgotPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPasswordRequest?: ResetPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyTokenRequest} [verifyTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken: async (verifyTokenRequest?: VerifyTokenRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/token/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordApi - functional programming interface
 * @export
 */
export const PasswordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(changePasswordRequest?: ChangePasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordChangedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(changePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DepreactedChangePasswordRequest} [depreactedChangePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deprecatedChangePassword(depreactedChangePasswordRequest?: DepreactedChangePasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deprecatedChangePassword(depreactedChangePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeprecatedResetPasswordRequest} [deprecatedResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deprecatedResetPassword(deprecatedResetPasswordRequest?: DeprecatedResetPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deprecatedResetPassword(deprecatedResetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {boolean} [skipSendingEmail] Default: False
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deprecatedResetPasswordRequest(email?: string, skipSendingEmail?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordChangedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deprecatedResetPasswordRequest(email, skipSendingEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(forgotPasswordRequest?: ForgotPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailSentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(forgotPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPasswordRequest?: ResetPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordChangedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyTokenRequest} [verifyTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyToken(verifyTokenRequest?: VerifyTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyToken(verifyTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PasswordApi - factory interface
 * @export
 */
export const PasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordApiFp(configuration)
    return {
        /**
         * 
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(changePasswordRequest?: ChangePasswordRequest, options?: any): AxiosPromise<PasswordChangedResponse> {
            return localVarFp.changePassword(changePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepreactedChangePasswordRequest} [depreactedChangePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecatedChangePassword(depreactedChangePasswordRequest?: DepreactedChangePasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deprecatedChangePassword(depreactedChangePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeprecatedResetPasswordRequest} [deprecatedResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecatedResetPassword(deprecatedResetPasswordRequest?: DeprecatedResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deprecatedResetPassword(deprecatedResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {boolean} [skipSendingEmail] Default: False
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecatedResetPasswordRequest(email?: string, skipSendingEmail?: boolean, options?: any): AxiosPromise<PasswordChangedResponse> {
            return localVarFp.deprecatedResetPasswordRequest(email, skipSendingEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPasswordRequest?: ForgotPasswordRequest, options?: any): AxiosPromise<EmailSentResponse> {
            return localVarFp.forgotPassword(forgotPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordRequest?: ResetPasswordRequest, options?: any): AxiosPromise<PasswordChangedResponse> {
            return localVarFp.resetPassword(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyTokenRequest} [verifyTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken(verifyTokenRequest?: VerifyTokenRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.verifyToken(verifyTokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for changePassword operation in PasswordApi.
 * @export
 * @interface PasswordApiChangePasswordRequest
 */
export interface PasswordApiChangePasswordRequest {
    /**
     * 
     * @type {ChangePasswordRequest}
     * @memberof PasswordApiChangePassword
     */
    readonly changePasswordRequest?: ChangePasswordRequest
}

/**
 * Request parameters for deprecatedChangePassword operation in PasswordApi.
 * @export
 * @interface PasswordApiDeprecatedChangePasswordRequest
 */
export interface PasswordApiDeprecatedChangePasswordRequest {
    /**
     * 
     * @type {DepreactedChangePasswordRequest}
     * @memberof PasswordApiDeprecatedChangePassword
     */
    readonly depreactedChangePasswordRequest?: DepreactedChangePasswordRequest
}

/**
 * Request parameters for deprecatedResetPassword operation in PasswordApi.
 * @export
 * @interface PasswordApiDeprecatedResetPasswordRequest
 */
export interface PasswordApiDeprecatedResetPasswordRequest {
    /**
     * 
     * @type {DeprecatedResetPasswordRequest}
     * @memberof PasswordApiDeprecatedResetPassword
     */
    readonly deprecatedResetPasswordRequest?: DeprecatedResetPasswordRequest
}

/**
 * Request parameters for deprecatedResetPasswordRequest operation in PasswordApi.
 * @export
 * @interface PasswordApiDeprecatedResetPasswordRequestRequest
 */
export interface PasswordApiDeprecatedResetPasswordRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordApiDeprecatedResetPasswordRequest
     */
    readonly email?: string

    /**
     * Default: False
     * @type {boolean}
     * @memberof PasswordApiDeprecatedResetPasswordRequest
     */
    readonly skipSendingEmail?: boolean
}

/**
 * Request parameters for forgotPassword operation in PasswordApi.
 * @export
 * @interface PasswordApiForgotPasswordRequest
 */
export interface PasswordApiForgotPasswordRequest {
    /**
     * 
     * @type {ForgotPasswordRequest}
     * @memberof PasswordApiForgotPassword
     */
    readonly forgotPasswordRequest?: ForgotPasswordRequest
}

/**
 * Request parameters for resetPassword operation in PasswordApi.
 * @export
 * @interface PasswordApiResetPasswordRequest
 */
export interface PasswordApiResetPasswordRequest {
    /**
     * 
     * @type {ResetPasswordRequest}
     * @memberof PasswordApiResetPassword
     */
    readonly resetPasswordRequest?: ResetPasswordRequest
}

/**
 * Request parameters for verifyToken operation in PasswordApi.
 * @export
 * @interface PasswordApiVerifyTokenRequest
 */
export interface PasswordApiVerifyTokenRequest {
    /**
     * 
     * @type {VerifyTokenRequest}
     * @memberof PasswordApiVerifyToken
     */
    readonly verifyTokenRequest?: VerifyTokenRequest
}

/**
 * PasswordApi - object-oriented interface
 * @export
 * @class PasswordApi
 * @extends {BaseAPI}
 */
export class PasswordApi extends BaseAPI {
    /**
     * 
     * @param {PasswordApiChangePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public changePassword(requestParameters: PasswordApiChangePasswordRequest = {}, options?: any) {
        return PasswordApiFp(this.configuration).changePassword(requestParameters.changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordApiDeprecatedChangePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public deprecatedChangePassword(requestParameters: PasswordApiDeprecatedChangePasswordRequest = {}, options?: any) {
        return PasswordApiFp(this.configuration).deprecatedChangePassword(requestParameters.depreactedChangePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordApiDeprecatedResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public deprecatedResetPassword(requestParameters: PasswordApiDeprecatedResetPasswordRequest = {}, options?: any) {
        return PasswordApiFp(this.configuration).deprecatedResetPassword(requestParameters.deprecatedResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordApiDeprecatedResetPasswordRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public deprecatedResetPasswordRequest(requestParameters: PasswordApiDeprecatedResetPasswordRequestRequest = {}, options?: any) {
        return PasswordApiFp(this.configuration).deprecatedResetPasswordRequest(requestParameters.email, requestParameters.skipSendingEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordApiForgotPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public forgotPassword(requestParameters: PasswordApiForgotPasswordRequest = {}, options?: any) {
        return PasswordApiFp(this.configuration).forgotPassword(requestParameters.forgotPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordApiResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public resetPassword(requestParameters: PasswordApiResetPasswordRequest = {}, options?: any) {
        return PasswordApiFp(this.configuration).resetPassword(requestParameters.resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordApiVerifyTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public verifyToken(requestParameters: PasswordApiVerifyTokenRequest = {}, options?: any) {
        return PasswordApiFp(this.configuration).verifyToken(requestParameters.verifyTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PocAccountApi - axios parameter creator
 * @export
 */
export const PocAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PocUserModel} [pocUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (pocUserModel?: PocUserModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/poc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pocUserModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (skip?: number, limit?: number, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/poc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOne', 'id', id)
            const localVarPath = `/api/poc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQRCodeSample: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/poc/qr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PocUserModel} [pocUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, pocUserModel?: PocUserModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            const localVarPath = `/api/poc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pocUserModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PocAccountApi - functional programming interface
 * @export
 */
export const PocAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PocAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PocUserModel} [pocUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(pocUserModel?: PocUserModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PocUserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(pocUserModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(skip?: number, limit?: number, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PocUserViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(skip, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOne(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PocUserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQRCodeSample(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQRCodeSample(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {PocUserModel} [pocUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, pocUserModel?: PocUserModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PocUserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, pocUserModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PocAccountApi - factory interface
 * @export
 */
export const PocAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PocAccountApiFp(configuration)
    return {
        /**
         * 
         * @param {PocUserModel} [pocUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(pocUserModel?: PocUserModel, options?: any): AxiosPromise<PocUserModel> {
            return localVarFp.createUser(pocUserModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(skip?: number, limit?: number, search?: string, options?: any): AxiosPromise<PocUserViewDataPage> {
            return localVarFp.getAll(skip, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne(id: string, options?: any): AxiosPromise<PocUserModel> {
            return localVarFp.getOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQRCodeSample(options?: any): AxiosPromise<string> {
            return localVarFp.getQRCodeSample(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PocUserModel} [pocUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, pocUserModel?: PocUserModel, options?: any): AxiosPromise<PocUserModel> {
            return localVarFp.update(id, pocUserModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createUser operation in PocAccountApi.
 * @export
 * @interface PocAccountApiCreateUserRequest
 */
export interface PocAccountApiCreateUserRequest {
    /**
     * 
     * @type {PocUserModel}
     * @memberof PocAccountApiCreateUser
     */
    readonly pocUserModel?: PocUserModel
}

/**
 * Request parameters for getAll operation in PocAccountApi.
 * @export
 * @interface PocAccountApiGetAllRequest
 */
export interface PocAccountApiGetAllRequest {
    /**
     * 
     * @type {number}
     * @memberof PocAccountApiGetAll
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof PocAccountApiGetAll
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof PocAccountApiGetAll
     */
    readonly search?: string
}

/**
 * Request parameters for getOne operation in PocAccountApi.
 * @export
 * @interface PocAccountApiGetOneRequest
 */
export interface PocAccountApiGetOneRequest {
    /**
     * 
     * @type {string}
     * @memberof PocAccountApiGetOne
     */
    readonly id: string
}

/**
 * Request parameters for update operation in PocAccountApi.
 * @export
 * @interface PocAccountApiUpdateRequest
 */
export interface PocAccountApiUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PocAccountApiUpdate
     */
    readonly id: string

    /**
     * 
     * @type {PocUserModel}
     * @memberof PocAccountApiUpdate
     */
    readonly pocUserModel?: PocUserModel
}

/**
 * PocAccountApi - object-oriented interface
 * @export
 * @class PocAccountApi
 * @extends {BaseAPI}
 */
export class PocAccountApi extends BaseAPI {
    /**
     * 
     * @param {PocAccountApiCreateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PocAccountApi
     */
    public createUser(requestParameters: PocAccountApiCreateUserRequest = {}, options?: any) {
        return PocAccountApiFp(this.configuration).createUser(requestParameters.pocUserModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PocAccountApiGetAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PocAccountApi
     */
    public getAll(requestParameters: PocAccountApiGetAllRequest = {}, options?: any) {
        return PocAccountApiFp(this.configuration).getAll(requestParameters.skip, requestParameters.limit, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PocAccountApiGetOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PocAccountApi
     */
    public getOne(requestParameters: PocAccountApiGetOneRequest, options?: any) {
        return PocAccountApiFp(this.configuration).getOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PocAccountApi
     */
    public getQRCodeSample(options?: any) {
        return PocAccountApiFp(this.configuration).getQRCodeSample(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PocAccountApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PocAccountApi
     */
    public update(requestParameters: PocAccountApiUpdateRequest, options?: any) {
        return PocAccountApiFp(this.configuration).update(requestParameters.id, requestParameters.pocUserModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrivacyPolicyApi - axios parameter creator
 * @export
 */
export const PrivacyPolicyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivacyPolicy: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/privacy-policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivacyPolicyApi - functional programming interface
 * @export
 */
export const PrivacyPolicyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivacyPolicyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrivacyPolicy(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrivacyPolicy(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivacyPolicyApi - factory interface
 * @export
 */
export const PrivacyPolicyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivacyPolicyApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivacyPolicy(options?: any): AxiosPromise<void> {
            return localVarFp.getPrivacyPolicy(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrivacyPolicyApi - object-oriented interface
 * @export
 * @class PrivacyPolicyApi
 * @extends {BaseAPI}
 */
export class PrivacyPolicyApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivacyPolicyApi
     */
    public getPrivacyPolicy(options?: any) {
        return PrivacyPolicyApiFp(this.configuration).getPrivacyPolicy(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductCategoriesApi - axios parameter creator
 * @export
 */
export const ProductCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/api/product-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductCategoryModel} [productCategoryModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (productCategoryModel?: ProductCategoryModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/product-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productCategoryModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (search?: string, skip?: number, limit?: number, language?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/product-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForLookup: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/product-categories/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOne', 'id', id)
            const localVarPath = `/api/product-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductCategoryModel} [productCategoryModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, productCategoryModel?: ProductCategoryModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            const localVarPath = `/api/product-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productCategoryModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductCategoriesApi - functional programming interface
 * @export
 */
export const ProductCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductCategoryModel} [productCategoryModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(productCategoryModel?: ProductCategoryModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(productCategoryModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(search?: string, skip?: number, limit?: number, language?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(search, skip, limit, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForLookup(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductCategoryLookupView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getForLookup(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductCategoryModel} [productCategoryModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, productCategoryModel?: ProductCategoryModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, productCategoryModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductCategoriesApi - factory interface
 * @export
 */
export const ProductCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: any): AxiosPromise<ServiceResult> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductCategoryModel} [productCategoryModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(productCategoryModel?: ProductCategoryModel, options?: any): AxiosPromise<ProductCategoryModelServiceResult> {
            return localVarFp.create(productCategoryModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(search?: string, skip?: number, limit?: number, language?: string, options?: any): AxiosPromise<ProductCategoryViewDataPage> {
            return localVarFp.get(search, skip, limit, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForLookup(options?: any): AxiosPromise<Array<ProductCategoryLookupView>> {
            return localVarFp.getForLookup(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne(id: number, options?: any): AxiosPromise<ProductCategoryModel> {
            return localVarFp.getOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductCategoryModel} [productCategoryModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, productCategoryModel?: ProductCategoryModel, options?: any): AxiosPromise<ProductCategoryModelServiceResult> {
            return localVarFp.update(id, productCategoryModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in ProductCategoriesApi.
 * @export
 * @interface ProductCategoriesApiDeleteRequest
 */
export interface ProductCategoriesApiDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductCategoriesApiDelete
     */
    readonly id: number
}

/**
 * Request parameters for create operation in ProductCategoriesApi.
 * @export
 * @interface ProductCategoriesApiCreateRequest
 */
export interface ProductCategoriesApiCreateRequest {
    /**
     * 
     * @type {ProductCategoryModel}
     * @memberof ProductCategoriesApiCreate
     */
    readonly productCategoryModel?: ProductCategoryModel
}

/**
 * Request parameters for get operation in ProductCategoriesApi.
 * @export
 * @interface ProductCategoriesApiGetRequest
 */
export interface ProductCategoriesApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoriesApiGet
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof ProductCategoriesApiGet
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ProductCategoriesApiGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ProductCategoriesApiGet
     */
    readonly language?: string
}

/**
 * Request parameters for getOne operation in ProductCategoriesApi.
 * @export
 * @interface ProductCategoriesApiGetOneRequest
 */
export interface ProductCategoriesApiGetOneRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductCategoriesApiGetOne
     */
    readonly id: number
}

/**
 * Request parameters for update operation in ProductCategoriesApi.
 * @export
 * @interface ProductCategoriesApiUpdateRequest
 */
export interface ProductCategoriesApiUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductCategoriesApiUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ProductCategoryModel}
     * @memberof ProductCategoriesApiUpdate
     */
    readonly productCategoryModel?: ProductCategoryModel
}

/**
 * ProductCategoriesApi - object-oriented interface
 * @export
 * @class ProductCategoriesApi
 * @extends {BaseAPI}
 */
export class ProductCategoriesApi extends BaseAPI {
    /**
     * 
     * @param {ProductCategoriesApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public _delete(requestParameters: ProductCategoriesApiDeleteRequest, options?: any) {
        return ProductCategoriesApiFp(this.configuration)._delete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductCategoriesApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public create(requestParameters: ProductCategoriesApiCreateRequest = {}, options?: any) {
        return ProductCategoriesApiFp(this.configuration).create(requestParameters.productCategoryModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductCategoriesApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public get(requestParameters: ProductCategoriesApiGetRequest = {}, options?: any) {
        return ProductCategoriesApiFp(this.configuration).get(requestParameters.search, requestParameters.skip, requestParameters.limit, requestParameters.language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public getForLookup(options?: any) {
        return ProductCategoriesApiFp(this.configuration).getForLookup(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductCategoriesApiGetOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public getOne(requestParameters: ProductCategoriesApiGetOneRequest, options?: any) {
        return ProductCategoriesApiFp(this.configuration).getOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductCategoriesApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public update(requestParameters: ProductCategoriesApiUpdateRequest, options?: any) {
        return ProductCategoriesApiFp(this.configuration).update(requestParameters.id, requestParameters.productCategoryModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/api/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductModel} [productModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (productModel?: ProductModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [language] 
         * @param {Array<number>} [categoryIds] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (search?: string, skip?: number, limit?: number, language?: string, categoryIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (categoryIds) {
                localVarQueryParameter['categoryIds'] = categoryIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByRevenue: async (skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/by-revenue/top`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOne', 'id', id)
            const localVarPath = `/api/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductModel} [productModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, productModel?: ProductModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            const localVarPath = `/api/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductModel} [productModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(productModel?: ProductModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(productModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [language] 
         * @param {Array<number>} [categoryIds] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(search?: string, skip?: number, limit?: number, language?: string, categoryIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(search, skip, limit, language, categoryIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByRevenue(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopProductViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByRevenue(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductModel} [productModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, productModel?: ProductModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, productModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: any): AxiosPromise<ServiceResult> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductModel} [productModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(productModel?: ProductModel, options?: any): AxiosPromise<ProductModelServiceResult> {
            return localVarFp.create(productModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [language] 
         * @param {Array<number>} [categoryIds] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(search?: string, skip?: number, limit?: number, language?: string, categoryIds?: Array<number>, options?: any): AxiosPromise<ProductViewDataPage> {
            return localVarFp.get(search, skip, limit, language, categoryIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByRevenue(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<TopProductViewDataPage> {
            return localVarFp.getByRevenue(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne(id: number, options?: any): AxiosPromise<ProductModel> {
            return localVarFp.getOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductModel} [productModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, productModel?: ProductModel, options?: any): AxiosPromise<ProductModelServiceResult> {
            return localVarFp.update(id, productModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in ProductsApi.
 * @export
 * @interface ProductsApiDeleteRequest
 */
export interface ProductsApiDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiDelete
     */
    readonly id: number
}

/**
 * Request parameters for create operation in ProductsApi.
 * @export
 * @interface ProductsApiCreateRequest
 */
export interface ProductsApiCreateRequest {
    /**
     * 
     * @type {ProductModel}
     * @memberof ProductsApiCreate
     */
    readonly productModel?: ProductModel
}

/**
 * Request parameters for get operation in ProductsApi.
 * @export
 * @interface ProductsApiGetRequest
 */
export interface ProductsApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiGet
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGet
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ProductsApiGet
     */
    readonly language?: string

    /**
     * Default: null
     * @type {Array<number>}
     * @memberof ProductsApiGet
     */
    readonly categoryIds?: Array<number>
}

/**
 * Request parameters for getByRevenue operation in ProductsApi.
 * @export
 * @interface ProductsApiGetByRevenueRequest
 */
export interface ProductsApiGetByRevenueRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetByRevenue
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetByRevenue
     */
    readonly limit?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof ProductsApiGetByRevenue
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof ProductsApiGetByRevenue
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof ProductsApiGetByRevenue
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof ProductsApiGetByRevenue
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ProductsApiGetByRevenue
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ProductsApiGetByRevenue
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ProductsApiGetByRevenue
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof ProductsApiGetByRevenue
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getOne operation in ProductsApi.
 * @export
 * @interface ProductsApiGetOneRequest
 */
export interface ProductsApiGetOneRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetOne
     */
    readonly id: number
}

/**
 * Request parameters for update operation in ProductsApi.
 * @export
 * @interface ProductsApiUpdateRequest
 */
export interface ProductsApiUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ProductModel}
     * @memberof ProductsApiUpdate
     */
    readonly productModel?: ProductModel
}

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @param {ProductsApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public _delete(requestParameters: ProductsApiDeleteRequest, options?: any) {
        return ProductsApiFp(this.configuration)._delete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public create(requestParameters: ProductsApiCreateRequest = {}, options?: any) {
        return ProductsApiFp(this.configuration).create(requestParameters.productModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public get(requestParameters: ProductsApiGetRequest = {}, options?: any) {
        return ProductsApiFp(this.configuration).get(requestParameters.search, requestParameters.skip, requestParameters.limit, requestParameters.language, requestParameters.categoryIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiGetByRevenueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getByRevenue(requestParameters: ProductsApiGetByRevenueRequest = {}, options?: any) {
        return ProductsApiFp(this.configuration).getByRevenue(requestParameters.skip, requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiGetOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getOne(requestParameters: ProductsApiGetOneRequest, options?: any) {
        return ProductsApiFp(this.configuration).getOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public update(requestParameters: ProductsApiUpdateRequest, options?: any) {
        return ProductsApiFp(this.configuration).update(requestParameters.id, requestParameters.productModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PurchaseHistoryApi - axios parameter creator
 * @export
 */
export const PurchaseHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandsForLookup: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/purchase-history/brands/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseHistoryApi - functional programming interface
 * @export
 */
export const PurchaseHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchaseHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrandsForLookup(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrandLookupView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandsForLookup(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PurchaseHistoryApi - factory interface
 * @export
 */
export const PurchaseHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchaseHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandsForLookup(options?: any): AxiosPromise<Array<BrandLookupView>> {
            return localVarFp.getBrandsForLookup(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchaseHistoryApi - object-oriented interface
 * @export
 * @class PurchaseHistoryApi
 * @extends {BaseAPI}
 */
export class PurchaseHistoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseHistoryApi
     */
    public getBrandsForLookup(options?: any) {
        return PurchaseHistoryApiFp(this.configuration).getBrandsForLookup(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} date 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthly: async (date: string, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getMonthly', 'date', date)
            const localVarPath = `/api/reports/monthly/{date}`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeekly: async (date: string, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getWeekly', 'date', date)
            const localVarPath = `/api/reports/weekly/{date}`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monthly: async (stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports/monthly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weekly: async (stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports/weekly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} date 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonthly(date: string, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonthly(date, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeekly(date: string, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeekly(date, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monthly(stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monthly(stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weekly(stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weekly(stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * 
         * @param {string} date 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthly(date: string, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.getMonthly(date, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeekly(date: string, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.getWeekly(date, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monthly(stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.monthly(stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weekly(stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.weekly(stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getMonthly operation in ReportApi.
 * @export
 * @interface ReportApiGetMonthlyRequest
 */
export interface ReportApiGetMonthlyRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportApiGetMonthly
     */
    readonly date: string

    /**
     * 
     * @type {Array<number>}
     * @memberof ReportApiGetMonthly
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ReportApiGetMonthly
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ReportApiGetMonthly
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ReportApiGetMonthly
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof ReportApiGetMonthly
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getWeekly operation in ReportApi.
 * @export
 * @interface ReportApiGetWeeklyRequest
 */
export interface ReportApiGetWeeklyRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportApiGetWeekly
     */
    readonly date: string

    /**
     * 
     * @type {Array<number>}
     * @memberof ReportApiGetWeekly
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ReportApiGetWeekly
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ReportApiGetWeekly
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ReportApiGetWeekly
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof ReportApiGetWeekly
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for monthly operation in ReportApi.
 * @export
 * @interface ReportApiMonthlyRequest
 */
export interface ReportApiMonthlyRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportApiMonthly
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ReportApiMonthly
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ReportApiMonthly
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ReportApiMonthly
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof ReportApiMonthly
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for weekly operation in ReportApi.
 * @export
 * @interface ReportApiWeeklyRequest
 */
export interface ReportApiWeeklyRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportApiWeekly
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ReportApiWeekly
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ReportApiWeekly
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ReportApiWeekly
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof ReportApiWeekly
     */
    readonly beacons?: Array<string>
}

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @param {ReportApiGetMonthlyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public getMonthly(requestParameters: ReportApiGetMonthlyRequest, options?: any) {
        return ReportApiFp(this.configuration).getMonthly(requestParameters.date, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportApiGetWeeklyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public getWeekly(requestParameters: ReportApiGetWeeklyRequest, options?: any) {
        return ReportApiFp(this.configuration).getWeekly(requestParameters.date, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportApiMonthlyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public monthly(requestParameters: ReportApiMonthlyRequest = {}, options?: any) {
        return ReportApiFp(this.configuration).monthly(requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportApiWeeklyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public weekly(requestParameters: ReportApiWeeklyRequest = {}, options?: any) {
        return ReportApiFp(this.configuration).weekly(requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RevenueStatisticsApi - axios parameter creator
 * @export
 */
export const RevenueStatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppRevenueUsers: async (skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/revenue-statistics/app-revenue-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSalesByCustomerType: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/revenue-statistics/app-sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppTransactionUsers: async (skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/revenue-statistics/app-transaction-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageTransactionValueForReturningCustomers: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/revenue-statistics/avg-trans-value-for-returning-customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewVsReturning: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/revenue-statistics/new-vs-returning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecurringTransactions: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/revenue-statistics/recurring-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevenue: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/revenue-statistics/revenue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/revenue-statistics/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RevenueStatisticsApi - functional programming interface
 * @export
 */
export const RevenueStatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RevenueStatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppRevenueUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalAppCustomerViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppRevenueUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppSalesByCustomerType(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppSalesByCustomerTypeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppSalesByCustomerType(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppTransactionUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppTransactionUserViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppTransactionUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageTransactionValueForReturningCustomers(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageTransactionValueForReturningCustomers(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewVsReturning(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewVsReturningView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewVsReturning(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecurringTransactions(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecurringTransactions(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRevenue(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviousCurrentValueView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRevenue(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactions(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviousCurrentValueView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactions(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RevenueStatisticsApi - factory interface
 * @export
 */
export const RevenueStatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RevenueStatisticsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppRevenueUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<TotalAppCustomerViewDataPage> {
            return localVarFp.getAppRevenueUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSalesByCustomerType(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<AppSalesByCustomerTypeView> {
            return localVarFp.getAppSalesByCustomerType(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppTransactionUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<AppTransactionUserViewDataPage> {
            return localVarFp.getAppTransactionUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageTransactionValueForReturningCustomers(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.getAverageTransactionValueForReturningCustomers(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewVsReturning(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<NewVsReturningView> {
            return localVarFp.getNewVsReturning(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecurringTransactions(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.getRecurringTransactions(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevenue(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<PreviousCurrentValueView> {
            return localVarFp.getRevenue(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<PreviousCurrentValueView> {
            return localVarFp.getTransactions(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAppRevenueUsers operation in RevenueStatisticsApi.
 * @export
 * @interface RevenueStatisticsApiGetAppRevenueUsersRequest
 */
export interface RevenueStatisticsApiGetAppRevenueUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof RevenueStatisticsApiGetAppRevenueUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof RevenueStatisticsApiGetAppRevenueUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof RevenueStatisticsApiGetAppRevenueUsers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RevenueStatisticsApiGetAppRevenueUsers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RevenueStatisticsApiGetAppRevenueUsers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetAppRevenueUsers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetAppRevenueUsers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetAppRevenueUsers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetAppRevenueUsers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof RevenueStatisticsApiGetAppRevenueUsers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getAppSalesByCustomerType operation in RevenueStatisticsApi.
 * @export
 * @interface RevenueStatisticsApiGetAppSalesByCustomerTypeRequest
 */
export interface RevenueStatisticsApiGetAppSalesByCustomerTypeRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof RevenueStatisticsApiGetAppSalesByCustomerType
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RevenueStatisticsApiGetAppSalesByCustomerType
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RevenueStatisticsApiGetAppSalesByCustomerType
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetAppSalesByCustomerType
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetAppSalesByCustomerType
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetAppSalesByCustomerType
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetAppSalesByCustomerType
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof RevenueStatisticsApiGetAppSalesByCustomerType
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getAppTransactionUsers operation in RevenueStatisticsApi.
 * @export
 * @interface RevenueStatisticsApiGetAppTransactionUsersRequest
 */
export interface RevenueStatisticsApiGetAppTransactionUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof RevenueStatisticsApiGetAppTransactionUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof RevenueStatisticsApiGetAppTransactionUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof RevenueStatisticsApiGetAppTransactionUsers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RevenueStatisticsApiGetAppTransactionUsers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RevenueStatisticsApiGetAppTransactionUsers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetAppTransactionUsers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetAppTransactionUsers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetAppTransactionUsers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetAppTransactionUsers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof RevenueStatisticsApiGetAppTransactionUsers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getAverageTransactionValueForReturningCustomers operation in RevenueStatisticsApi.
 * @export
 * @interface RevenueStatisticsApiGetAverageTransactionValueForReturningCustomersRequest
 */
export interface RevenueStatisticsApiGetAverageTransactionValueForReturningCustomersRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof RevenueStatisticsApiGetAverageTransactionValueForReturningCustomers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RevenueStatisticsApiGetAverageTransactionValueForReturningCustomers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RevenueStatisticsApiGetAverageTransactionValueForReturningCustomers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetAverageTransactionValueForReturningCustomers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetAverageTransactionValueForReturningCustomers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetAverageTransactionValueForReturningCustomers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetAverageTransactionValueForReturningCustomers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof RevenueStatisticsApiGetAverageTransactionValueForReturningCustomers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getNewVsReturning operation in RevenueStatisticsApi.
 * @export
 * @interface RevenueStatisticsApiGetNewVsReturningRequest
 */
export interface RevenueStatisticsApiGetNewVsReturningRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof RevenueStatisticsApiGetNewVsReturning
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RevenueStatisticsApiGetNewVsReturning
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RevenueStatisticsApiGetNewVsReturning
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetNewVsReturning
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetNewVsReturning
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetNewVsReturning
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetNewVsReturning
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof RevenueStatisticsApiGetNewVsReturning
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getRecurringTransactions operation in RevenueStatisticsApi.
 * @export
 * @interface RevenueStatisticsApiGetRecurringTransactionsRequest
 */
export interface RevenueStatisticsApiGetRecurringTransactionsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof RevenueStatisticsApiGetRecurringTransactions
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RevenueStatisticsApiGetRecurringTransactions
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RevenueStatisticsApiGetRecurringTransactions
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetRecurringTransactions
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetRecurringTransactions
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetRecurringTransactions
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetRecurringTransactions
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof RevenueStatisticsApiGetRecurringTransactions
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getRevenue operation in RevenueStatisticsApi.
 * @export
 * @interface RevenueStatisticsApiGetRevenueRequest
 */
export interface RevenueStatisticsApiGetRevenueRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof RevenueStatisticsApiGetRevenue
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RevenueStatisticsApiGetRevenue
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RevenueStatisticsApiGetRevenue
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetRevenue
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetRevenue
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetRevenue
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetRevenue
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof RevenueStatisticsApiGetRevenue
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getTransactions operation in RevenueStatisticsApi.
 * @export
 * @interface RevenueStatisticsApiGetTransactionsRequest
 */
export interface RevenueStatisticsApiGetTransactionsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof RevenueStatisticsApiGetTransactions
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RevenueStatisticsApiGetTransactions
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof RevenueStatisticsApiGetTransactions
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetTransactions
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetTransactions
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetTransactions
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueStatisticsApiGetTransactions
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof RevenueStatisticsApiGetTransactions
     */
    readonly beacons?: Array<string>
}

/**
 * RevenueStatisticsApi - object-oriented interface
 * @export
 * @class RevenueStatisticsApi
 * @extends {BaseAPI}
 */
export class RevenueStatisticsApi extends BaseAPI {
    /**
     * 
     * @param {RevenueStatisticsApiGetAppRevenueUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevenueStatisticsApi
     */
    public getAppRevenueUsers(requestParameters: RevenueStatisticsApiGetAppRevenueUsersRequest = {}, options?: any) {
        return RevenueStatisticsApiFp(this.configuration).getAppRevenueUsers(requestParameters.skip, requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RevenueStatisticsApiGetAppSalesByCustomerTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevenueStatisticsApi
     */
    public getAppSalesByCustomerType(requestParameters: RevenueStatisticsApiGetAppSalesByCustomerTypeRequest = {}, options?: any) {
        return RevenueStatisticsApiFp(this.configuration).getAppSalesByCustomerType(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RevenueStatisticsApiGetAppTransactionUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevenueStatisticsApi
     */
    public getAppTransactionUsers(requestParameters: RevenueStatisticsApiGetAppTransactionUsersRequest = {}, options?: any) {
        return RevenueStatisticsApiFp(this.configuration).getAppTransactionUsers(requestParameters.skip, requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RevenueStatisticsApiGetAverageTransactionValueForReturningCustomersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevenueStatisticsApi
     */
    public getAverageTransactionValueForReturningCustomers(requestParameters: RevenueStatisticsApiGetAverageTransactionValueForReturningCustomersRequest = {}, options?: any) {
        return RevenueStatisticsApiFp(this.configuration).getAverageTransactionValueForReturningCustomers(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RevenueStatisticsApiGetNewVsReturningRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevenueStatisticsApi
     */
    public getNewVsReturning(requestParameters: RevenueStatisticsApiGetNewVsReturningRequest = {}, options?: any) {
        return RevenueStatisticsApiFp(this.configuration).getNewVsReturning(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RevenueStatisticsApiGetRecurringTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevenueStatisticsApi
     */
    public getRecurringTransactions(requestParameters: RevenueStatisticsApiGetRecurringTransactionsRequest = {}, options?: any) {
        return RevenueStatisticsApiFp(this.configuration).getRecurringTransactions(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RevenueStatisticsApiGetRevenueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevenueStatisticsApi
     */
    public getRevenue(requestParameters: RevenueStatisticsApiGetRevenueRequest = {}, options?: any) {
        return RevenueStatisticsApiFp(this.configuration).getRevenue(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RevenueStatisticsApiGetTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevenueStatisticsApi
     */
    public getTransactions(requestParameters: RevenueStatisticsApiGetTransactionsRequest = {}, options?: any) {
        return RevenueStatisticsApiFp(this.configuration).getTransactions(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SegmentGroupsApi - axios parameter creator
 * @export
 */
export const SegmentGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} segmentGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (segmentGroupId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'segmentGroupId' is not null or undefined
            assertParamExists('_delete', 'segmentGroupId', segmentGroupId)
            const localVarPath = `/api/segment-groups/{segmentGroupId}`
                .replace(`{${"segmentGroupId"}}`, encodeURIComponent(String(segmentGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SegmentGroupModel} [segmentGroupModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (segmentGroupModel?: SegmentGroupModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/segment-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(segmentGroupModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (search?: string, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/segment-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} segmentGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne: async (segmentGroupId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'segmentGroupId' is not null or undefined
            assertParamExists('getOne', 'segmentGroupId', segmentGroupId)
            const localVarPath = `/api/segment-groups/{segmentGroupId}`
                .replace(`{${"segmentGroupId"}}`, encodeURIComponent(String(segmentGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} segmentGroupId 
         * @param {SegmentGroupModel} [segmentGroupModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (segmentGroupId: number, segmentGroupModel?: SegmentGroupModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'segmentGroupId' is not null or undefined
            assertParamExists('update', 'segmentGroupId', segmentGroupId)
            const localVarPath = `/api/segment-groups/{segmentGroupId}`
                .replace(`{${"segmentGroupId"}}`, encodeURIComponent(String(segmentGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(segmentGroupModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SegmentGroupsApi - functional programming interface
 * @export
 */
export const SegmentGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SegmentGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} segmentGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(segmentGroupId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(segmentGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SegmentGroupModel} [segmentGroupModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(segmentGroupModel?: SegmentGroupModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentGroupModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(segmentGroupModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(search?: string, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentGroupViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(search, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} segmentGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOne(segmentGroupId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentGroupModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOne(segmentGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} segmentGroupId 
         * @param {SegmentGroupModel} [segmentGroupModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(segmentGroupId: number, segmentGroupModel?: SegmentGroupModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentGroupModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(segmentGroupId, segmentGroupModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SegmentGroupsApi - factory interface
 * @export
 */
export const SegmentGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SegmentGroupsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} segmentGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(segmentGroupId: number, options?: any): AxiosPromise<void> {
            return localVarFp._delete(segmentGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SegmentGroupModel} [segmentGroupModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(segmentGroupModel?: SegmentGroupModel, options?: any): AxiosPromise<SegmentGroupModelServiceResult> {
            return localVarFp.create(segmentGroupModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(search?: string, skip?: number, limit?: number, options?: any): AxiosPromise<SegmentGroupViewDataPage> {
            return localVarFp.getAll(search, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} segmentGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne(segmentGroupId: number, options?: any): AxiosPromise<SegmentGroupModelServiceResult> {
            return localVarFp.getOne(segmentGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} segmentGroupId 
         * @param {SegmentGroupModel} [segmentGroupModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(segmentGroupId: number, segmentGroupModel?: SegmentGroupModel, options?: any): AxiosPromise<SegmentGroupModelServiceResult> {
            return localVarFp.update(segmentGroupId, segmentGroupModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in SegmentGroupsApi.
 * @export
 * @interface SegmentGroupsApiDeleteRequest
 */
export interface SegmentGroupsApiDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof SegmentGroupsApiDelete
     */
    readonly segmentGroupId: number
}

/**
 * Request parameters for create operation in SegmentGroupsApi.
 * @export
 * @interface SegmentGroupsApiCreateRequest
 */
export interface SegmentGroupsApiCreateRequest {
    /**
     * 
     * @type {SegmentGroupModel}
     * @memberof SegmentGroupsApiCreate
     */
    readonly segmentGroupModel?: SegmentGroupModel
}

/**
 * Request parameters for getAll operation in SegmentGroupsApi.
 * @export
 * @interface SegmentGroupsApiGetAllRequest
 */
export interface SegmentGroupsApiGetAllRequest {
    /**
     * Default: null
     * @type {string}
     * @memberof SegmentGroupsApiGetAll
     */
    readonly search?: string

    /**
     * Default: null
     * @type {number}
     * @memberof SegmentGroupsApiGetAll
     */
    readonly skip?: number

    /**
     * Default: null
     * @type {number}
     * @memberof SegmentGroupsApiGetAll
     */
    readonly limit?: number
}

/**
 * Request parameters for getOne operation in SegmentGroupsApi.
 * @export
 * @interface SegmentGroupsApiGetOneRequest
 */
export interface SegmentGroupsApiGetOneRequest {
    /**
     * 
     * @type {number}
     * @memberof SegmentGroupsApiGetOne
     */
    readonly segmentGroupId: number
}

/**
 * Request parameters for update operation in SegmentGroupsApi.
 * @export
 * @interface SegmentGroupsApiUpdateRequest
 */
export interface SegmentGroupsApiUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof SegmentGroupsApiUpdate
     */
    readonly segmentGroupId: number

    /**
     * 
     * @type {SegmentGroupModel}
     * @memberof SegmentGroupsApiUpdate
     */
    readonly segmentGroupModel?: SegmentGroupModel
}

/**
 * SegmentGroupsApi - object-oriented interface
 * @export
 * @class SegmentGroupsApi
 * @extends {BaseAPI}
 */
export class SegmentGroupsApi extends BaseAPI {
    /**
     * 
     * @param {SegmentGroupsApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentGroupsApi
     */
    public _delete(requestParameters: SegmentGroupsApiDeleteRequest, options?: any) {
        return SegmentGroupsApiFp(this.configuration)._delete(requestParameters.segmentGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SegmentGroupsApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentGroupsApi
     */
    public create(requestParameters: SegmentGroupsApiCreateRequest = {}, options?: any) {
        return SegmentGroupsApiFp(this.configuration).create(requestParameters.segmentGroupModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SegmentGroupsApiGetAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentGroupsApi
     */
    public getAll(requestParameters: SegmentGroupsApiGetAllRequest = {}, options?: any) {
        return SegmentGroupsApiFp(this.configuration).getAll(requestParameters.search, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SegmentGroupsApiGetOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentGroupsApi
     */
    public getOne(requestParameters: SegmentGroupsApiGetOneRequest, options?: any) {
        return SegmentGroupsApiFp(this.configuration).getOne(requestParameters.segmentGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SegmentGroupsApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentGroupsApi
     */
    public update(requestParameters: SegmentGroupsApiUpdateRequest, options?: any) {
        return SegmentGroupsApiFp(this.configuration).update(requestParameters.segmentGroupId, requestParameters.segmentGroupModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoresApi - axios parameter creator
 * @export
 */
export const StoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (storeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('_delete', 'storeId', storeId)
            const localVarPath = `/api/stores/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StoreModel} [storeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (storeModel?: StoreModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {string} [search] Default: null
         * @param {boolean} [onlyActive] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, search?: string, onlyActive?: boolean, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (onlyActive !== undefined) {
                localVarQueryParameter['onlyActive'] = onlyActive;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBeacons: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBeacons', 'id', id)
            const localVarPath = `/api/stores/{id}/beacons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {boolean} [includeAllStores] Default: null
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByBeaconContacts: async (size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, includeAllStores?: boolean, skip?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stores/by-beacon-contacts/top`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }

            if (includeAllStores !== undefined) {
                localVarQueryParameter['includeAllStores'] = includeAllStores;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {boolean} [includeAllStores] Default: null
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByFavoriteCustomers: async (size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, includeAllStores?: boolean, skip?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stores/by-favorite-customers/top`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }

            if (includeAllStores !== undefined) {
                localVarQueryParameter['includeAllStores'] = includeAllStores;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {boolean} [includeAllStores] Default: null
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByRevenue: async (size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, includeAllStores?: boolean, skip?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stores/by-revenue/top`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }

            if (includeAllStores !== undefined) {
                localVarQueryParameter['includeAllStores'] = includeAllStores;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {boolean} [includeAllStores] Default: null
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByVisits: async (size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, includeAllStores?: boolean, skip?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stores/by-visits/top`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }

            if (includeAllStores !== undefined) {
                localVarQueryParameter['includeAllStores'] = includeAllStores;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitiesByVisits: async (size: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getCitiesByVisits', 'size', size)
            const localVarPath = `/api/stores/cities-by-visits/top-{size}`
                .replace(`{${"size"}}`, encodeURIComponent(String(size)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountriesByVisits: async (size: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getCountriesByVisits', 'size', size)
            const localVarPath = `/api/stores/countries-by-visits/top-{size}`
                .replace(`{${"size"}}`, encodeURIComponent(String(size)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForLookup: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stores/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayout: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLayout', 'id', id)
            const localVarPath = `/api/stores/{id}/layout`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOne', 'id', id)
            const localVarPath = `/api/stores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreBeaconVisits: async (id: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getStoreBeaconVisits', 'id', id)
            const localVarPath = `/api/stores/{id}/beacon-visit-counts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {StoreModel} [storeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, storeModel?: StoreModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            const localVarPath = `/api/stores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {StoreLayoutModel} [storeLayoutModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLayout: async (id: number, storeLayoutModel?: StoreLayoutModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLayout', 'id', id)
            const localVarPath = `/api/stores/{id}/layout`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeLayoutModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitorData: async (storeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('visitorData', 'storeId', storeId)
            const localVarPath = `/api/stores/{storeId}/visitor-data`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoresApi - functional programming interface
 * @export
 */
export const StoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(storeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StoreModel} [storeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(storeModel?: StoreModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(storeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {string} [search] Default: null
         * @param {boolean} [onlyActive] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, search?: string, onlyActive?: boolean, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(stores, countries, cities, areas, beacons, search, onlyActive, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBeacons(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BeaconView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBeacons(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {boolean} [includeAllStores] Default: null
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByBeaconContacts(size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, includeAllStores?: boolean, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopStoreGridViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByBeaconContacts(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, includeAllStores, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {boolean} [includeAllStores] Default: null
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByFavoriteCustomers(size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, includeAllStores?: boolean, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopStoreGridViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByFavoriteCustomers(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, includeAllStores, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {boolean} [includeAllStores] Default: null
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByRevenue(size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, includeAllStores?: boolean, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopStoreGridViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByRevenue(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, includeAllStores, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {boolean} [includeAllStores] Default: null
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByVisits(size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, includeAllStores?: boolean, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopStoreGridViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByVisits(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, includeAllStores, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} size 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitiesByVisits(size: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopLocationGridView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitiesByVisits(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} size 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountriesByVisits(size: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopLocationGridView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountriesByVisits(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForLookup(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StoreLookupView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getForLookup(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLayout(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreLayoutModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLayout(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreBeaconVisits(id: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BeaconVisitCountView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreBeaconVisits(id, rangeType, rangeStart, rangeEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {StoreModel} [storeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, storeModel?: StoreModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, storeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {StoreLayoutModel} [storeLayoutModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLayout(id: number, storeLayoutModel?: StoreLayoutModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreLayoutModelServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLayout(id, storeLayoutModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitorData(storeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreVisitorDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitorData(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoresApi - factory interface
 * @export
 */
export const StoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoresApiFp(configuration)
    return {
        /**
         * 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(storeId: number, options?: any): AxiosPromise<void> {
            return localVarFp._delete(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StoreModel} [storeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(storeModel?: StoreModel, options?: any): AxiosPromise<StoreModelServiceResult> {
            return localVarFp.create(storeModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {string} [search] Default: null
         * @param {boolean} [onlyActive] Default: null
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, search?: string, onlyActive?: boolean, skip?: number, limit?: number, options?: any): AxiosPromise<StoreViewDataPage> {
            return localVarFp.get(stores, countries, cities, areas, beacons, search, onlyActive, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBeacons(id: number, options?: any): AxiosPromise<Array<BeaconView>> {
            return localVarFp.getBeacons(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {boolean} [includeAllStores] Default: null
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByBeaconContacts(size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, includeAllStores?: boolean, skip?: number, options?: any): AxiosPromise<TopStoreGridViewDataPage> {
            return localVarFp.getByBeaconContacts(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, includeAllStores, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {boolean} [includeAllStores] Default: null
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByFavoriteCustomers(size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, includeAllStores?: boolean, skip?: number, options?: any): AxiosPromise<TopStoreGridViewDataPage> {
            return localVarFp.getByFavoriteCustomers(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, includeAllStores, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {boolean} [includeAllStores] Default: null
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByRevenue(size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, includeAllStores?: boolean, skip?: number, options?: any): AxiosPromise<TopStoreGridViewDataPage> {
            return localVarFp.getByRevenue(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, includeAllStores, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [size] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {boolean} [includeAllStores] Default: null
         * @param {number} [skip] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByVisits(size?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, includeAllStores?: boolean, skip?: number, options?: any): AxiosPromise<TopStoreGridViewDataPage> {
            return localVarFp.getByVisits(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, includeAllStores, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitiesByVisits(size: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<Array<TopLocationGridView>> {
            return localVarFp.getCitiesByVisits(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountriesByVisits(size: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<Array<TopLocationGridView>> {
            return localVarFp.getCountriesByVisits(size, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForLookup(options?: any): AxiosPromise<Array<StoreLookupView>> {
            return localVarFp.getForLookup(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayout(id: number, options?: any): AxiosPromise<StoreLayoutModel> {
            return localVarFp.getLayout(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne(id: number, options?: any): AxiosPromise<StoreModel> {
            return localVarFp.getOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreBeaconVisits(id: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, options?: any): AxiosPromise<Array<BeaconVisitCountView>> {
            return localVarFp.getStoreBeaconVisits(id, rangeType, rangeStart, rangeEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {StoreModel} [storeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, storeModel?: StoreModel, options?: any): AxiosPromise<StoreModelServiceResult> {
            return localVarFp.update(id, storeModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {StoreLayoutModel} [storeLayoutModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLayout(id: number, storeLayoutModel?: StoreLayoutModel, options?: any): AxiosPromise<StoreLayoutModelServiceResult> {
            return localVarFp.updateLayout(id, storeLayoutModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitorData(storeId: number, options?: any): AxiosPromise<StoreVisitorDataResponse> {
            return localVarFp.visitorData(storeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in StoresApi.
 * @export
 * @interface StoresApiDeleteRequest
 */
export interface StoresApiDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof StoresApiDelete
     */
    readonly storeId: number
}

/**
 * Request parameters for create operation in StoresApi.
 * @export
 * @interface StoresApiCreateRequest
 */
export interface StoresApiCreateRequest {
    /**
     * 
     * @type {StoreModel}
     * @memberof StoresApiCreate
     */
    readonly storeModel?: StoreModel
}

/**
 * Request parameters for get operation in StoresApi.
 * @export
 * @interface StoresApiGetRequest
 */
export interface StoresApiGetRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGet
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGet
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGet
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGet
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof StoresApiGet
     */
    readonly beacons?: Array<string>

    /**
     * Default: null
     * @type {string}
     * @memberof StoresApiGet
     */
    readonly search?: string

    /**
     * Default: null
     * @type {boolean}
     * @memberof StoresApiGet
     */
    readonly onlyActive?: boolean

    /**
     * Default: null
     * @type {number}
     * @memberof StoresApiGet
     */
    readonly skip?: number

    /**
     * Default: null
     * @type {number}
     * @memberof StoresApiGet
     */
    readonly limit?: number
}

/**
 * Request parameters for getBeacons operation in StoresApi.
 * @export
 * @interface StoresApiGetBeaconsRequest
 */
export interface StoresApiGetBeaconsRequest {
    /**
     * 
     * @type {number}
     * @memberof StoresApiGetBeacons
     */
    readonly id: number
}

/**
 * Request parameters for getByBeaconContacts operation in StoresApi.
 * @export
 * @interface StoresApiGetByBeaconContactsRequest
 */
export interface StoresApiGetByBeaconContactsRequest {
    /**
     * 
     * @type {number}
     * @memberof StoresApiGetByBeaconContacts
     */
    readonly size?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof StoresApiGetByBeaconContacts
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoresApiGetByBeaconContacts
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoresApiGetByBeaconContacts
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetByBeaconContacts
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetByBeaconContacts
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetByBeaconContacts
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetByBeaconContacts
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof StoresApiGetByBeaconContacts
     */
    readonly beacons?: Array<string>

    /**
     * Default: null
     * @type {boolean}
     * @memberof StoresApiGetByBeaconContacts
     */
    readonly includeAllStores?: boolean

    /**
     * Default: null
     * @type {number}
     * @memberof StoresApiGetByBeaconContacts
     */
    readonly skip?: number
}

/**
 * Request parameters for getByFavoriteCustomers operation in StoresApi.
 * @export
 * @interface StoresApiGetByFavoriteCustomersRequest
 */
export interface StoresApiGetByFavoriteCustomersRequest {
    /**
     * 
     * @type {number}
     * @memberof StoresApiGetByFavoriteCustomers
     */
    readonly size?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof StoresApiGetByFavoriteCustomers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoresApiGetByFavoriteCustomers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoresApiGetByFavoriteCustomers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetByFavoriteCustomers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetByFavoriteCustomers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetByFavoriteCustomers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetByFavoriteCustomers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof StoresApiGetByFavoriteCustomers
     */
    readonly beacons?: Array<string>

    /**
     * Default: null
     * @type {boolean}
     * @memberof StoresApiGetByFavoriteCustomers
     */
    readonly includeAllStores?: boolean

    /**
     * Default: null
     * @type {number}
     * @memberof StoresApiGetByFavoriteCustomers
     */
    readonly skip?: number
}

/**
 * Request parameters for getByRevenue operation in StoresApi.
 * @export
 * @interface StoresApiGetByRevenueRequest
 */
export interface StoresApiGetByRevenueRequest {
    /**
     * 
     * @type {number}
     * @memberof StoresApiGetByRevenue
     */
    readonly size?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof StoresApiGetByRevenue
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoresApiGetByRevenue
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoresApiGetByRevenue
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetByRevenue
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetByRevenue
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetByRevenue
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetByRevenue
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof StoresApiGetByRevenue
     */
    readonly beacons?: Array<string>

    /**
     * Default: null
     * @type {boolean}
     * @memberof StoresApiGetByRevenue
     */
    readonly includeAllStores?: boolean

    /**
     * Default: null
     * @type {number}
     * @memberof StoresApiGetByRevenue
     */
    readonly skip?: number
}

/**
 * Request parameters for getByVisits operation in StoresApi.
 * @export
 * @interface StoresApiGetByVisitsRequest
 */
export interface StoresApiGetByVisitsRequest {
    /**
     * 
     * @type {number}
     * @memberof StoresApiGetByVisits
     */
    readonly size?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof StoresApiGetByVisits
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoresApiGetByVisits
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoresApiGetByVisits
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetByVisits
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetByVisits
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetByVisits
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetByVisits
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof StoresApiGetByVisits
     */
    readonly beacons?: Array<string>

    /**
     * Default: null
     * @type {boolean}
     * @memberof StoresApiGetByVisits
     */
    readonly includeAllStores?: boolean

    /**
     * Default: null
     * @type {number}
     * @memberof StoresApiGetByVisits
     */
    readonly skip?: number
}

/**
 * Request parameters for getCitiesByVisits operation in StoresApi.
 * @export
 * @interface StoresApiGetCitiesByVisitsRequest
 */
export interface StoresApiGetCitiesByVisitsRequest {
    /**
     * 
     * @type {number}
     * @memberof StoresApiGetCitiesByVisits
     */
    readonly size: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof StoresApiGetCitiesByVisits
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoresApiGetCitiesByVisits
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoresApiGetCitiesByVisits
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetCitiesByVisits
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetCitiesByVisits
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetCitiesByVisits
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetCitiesByVisits
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof StoresApiGetCitiesByVisits
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getCountriesByVisits operation in StoresApi.
 * @export
 * @interface StoresApiGetCountriesByVisitsRequest
 */
export interface StoresApiGetCountriesByVisitsRequest {
    /**
     * 
     * @type {number}
     * @memberof StoresApiGetCountriesByVisits
     */
    readonly size: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof StoresApiGetCountriesByVisits
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoresApiGetCountriesByVisits
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoresApiGetCountriesByVisits
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetCountriesByVisits
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetCountriesByVisits
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetCountriesByVisits
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof StoresApiGetCountriesByVisits
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof StoresApiGetCountriesByVisits
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getLayout operation in StoresApi.
 * @export
 * @interface StoresApiGetLayoutRequest
 */
export interface StoresApiGetLayoutRequest {
    /**
     * 
     * @type {number}
     * @memberof StoresApiGetLayout
     */
    readonly id: number
}

/**
 * Request parameters for getOne operation in StoresApi.
 * @export
 * @interface StoresApiGetOneRequest
 */
export interface StoresApiGetOneRequest {
    /**
     * 
     * @type {number}
     * @memberof StoresApiGetOne
     */
    readonly id: number
}

/**
 * Request parameters for getStoreBeaconVisits operation in StoresApi.
 * @export
 * @interface StoresApiGetStoreBeaconVisitsRequest
 */
export interface StoresApiGetStoreBeaconVisitsRequest {
    /**
     * 
     * @type {number}
     * @memberof StoresApiGetStoreBeaconVisits
     */
    readonly id: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof StoresApiGetStoreBeaconVisits
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoresApiGetStoreBeaconVisits
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof StoresApiGetStoreBeaconVisits
     */
    readonly rangeEnd?: IsoDateTimeString
}

/**
 * Request parameters for update operation in StoresApi.
 * @export
 * @interface StoresApiUpdateRequest
 */
export interface StoresApiUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof StoresApiUpdate
     */
    readonly id: number

    /**
     * 
     * @type {StoreModel}
     * @memberof StoresApiUpdate
     */
    readonly storeModel?: StoreModel
}

/**
 * Request parameters for updateLayout operation in StoresApi.
 * @export
 * @interface StoresApiUpdateLayoutRequest
 */
export interface StoresApiUpdateLayoutRequest {
    /**
     * 
     * @type {number}
     * @memberof StoresApiUpdateLayout
     */
    readonly id: number

    /**
     * 
     * @type {StoreLayoutModel}
     * @memberof StoresApiUpdateLayout
     */
    readonly storeLayoutModel?: StoreLayoutModel
}

/**
 * Request parameters for visitorData operation in StoresApi.
 * @export
 * @interface StoresApiVisitorDataRequest
 */
export interface StoresApiVisitorDataRequest {
    /**
     * 
     * @type {number}
     * @memberof StoresApiVisitorData
     */
    readonly storeId: number
}

/**
 * StoresApi - object-oriented interface
 * @export
 * @class StoresApi
 * @extends {BaseAPI}
 */
export class StoresApi extends BaseAPI {
    /**
     * 
     * @param {StoresApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public _delete(requestParameters: StoresApiDeleteRequest, options?: any) {
        return StoresApiFp(this.configuration)._delete(requestParameters.storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoresApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public create(requestParameters: StoresApiCreateRequest = {}, options?: any) {
        return StoresApiFp(this.configuration).create(requestParameters.storeModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoresApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public get(requestParameters: StoresApiGetRequest = {}, options?: any) {
        return StoresApiFp(this.configuration).get(requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, requestParameters.search, requestParameters.onlyActive, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoresApiGetBeaconsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public getBeacons(requestParameters: StoresApiGetBeaconsRequest, options?: any) {
        return StoresApiFp(this.configuration).getBeacons(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoresApiGetByBeaconContactsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public getByBeaconContacts(requestParameters: StoresApiGetByBeaconContactsRequest = {}, options?: any) {
        return StoresApiFp(this.configuration).getByBeaconContacts(requestParameters.size, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, requestParameters.includeAllStores, requestParameters.skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoresApiGetByFavoriteCustomersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public getByFavoriteCustomers(requestParameters: StoresApiGetByFavoriteCustomersRequest = {}, options?: any) {
        return StoresApiFp(this.configuration).getByFavoriteCustomers(requestParameters.size, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, requestParameters.includeAllStores, requestParameters.skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoresApiGetByRevenueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public getByRevenue(requestParameters: StoresApiGetByRevenueRequest = {}, options?: any) {
        return StoresApiFp(this.configuration).getByRevenue(requestParameters.size, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, requestParameters.includeAllStores, requestParameters.skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoresApiGetByVisitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public getByVisits(requestParameters: StoresApiGetByVisitsRequest = {}, options?: any) {
        return StoresApiFp(this.configuration).getByVisits(requestParameters.size, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, requestParameters.includeAllStores, requestParameters.skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoresApiGetCitiesByVisitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public getCitiesByVisits(requestParameters: StoresApiGetCitiesByVisitsRequest, options?: any) {
        return StoresApiFp(this.configuration).getCitiesByVisits(requestParameters.size, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoresApiGetCountriesByVisitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public getCountriesByVisits(requestParameters: StoresApiGetCountriesByVisitsRequest, options?: any) {
        return StoresApiFp(this.configuration).getCountriesByVisits(requestParameters.size, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public getForLookup(options?: any) {
        return StoresApiFp(this.configuration).getForLookup(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoresApiGetLayoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public getLayout(requestParameters: StoresApiGetLayoutRequest, options?: any) {
        return StoresApiFp(this.configuration).getLayout(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoresApiGetOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public getOne(requestParameters: StoresApiGetOneRequest, options?: any) {
        return StoresApiFp(this.configuration).getOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoresApiGetStoreBeaconVisitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public getStoreBeaconVisits(requestParameters: StoresApiGetStoreBeaconVisitsRequest, options?: any) {
        return StoresApiFp(this.configuration).getStoreBeaconVisits(requestParameters.id, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoresApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public update(requestParameters: StoresApiUpdateRequest, options?: any) {
        return StoresApiFp(this.configuration).update(requestParameters.id, requestParameters.storeModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoresApiUpdateLayoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public updateLayout(requestParameters: StoresApiUpdateLayoutRequest, options?: any) {
        return StoresApiFp(this.configuration).updateLayout(requestParameters.id, requestParameters.storeLayoutModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoresApiVisitorDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public visitorData(requestParameters: StoresApiVisitorDataRequest, options?: any) {
        return StoresApiFp(this.configuration).visitorData(requestParameters.storeId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrackingApi - axios parameter creator
 * @export
 */
export const TrackingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [platformDeviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiagnostics: async (userId?: string, platformDeviceId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tracking/diagnostics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (platformDeviceId !== undefined) {
                localVarQueryParameter['platformDeviceId'] = platformDeviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [platformDeviceId] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiagnostics: async (userId?: string, platformDeviceId?: string, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tracking/diagnostics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (platformDeviceId !== undefined) {
                localVarQueryParameter['platformDeviceId'] = platformDeviceId;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [startHour] Default: null
         * @param {number} [endHour] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTracking: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, startHour?: number, endHour?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tracking/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }

            if (startHour !== undefined) {
                localVarQueryParameter['startHour'] = startHour;
            }

            if (endHour !== undefined) {
                localVarQueryParameter['endHour'] = endHour;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrackingApi - functional programming interface
 * @export
 */
export const TrackingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrackingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [platformDeviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDiagnostics(userId?: string, platformDeviceId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDiagnostics(userId, platformDeviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [platformDeviceId] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDiagnostics(userId?: string, platformDeviceId?: string, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiagnosticsGridDataModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiagnostics(userId, platformDeviceId, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [startHour] Default: null
         * @param {number} [endHour] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserTracking(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, startHour?: number, endHour?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerTrackingGridViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserTracking(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, startHour, endHour, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrackingApi - factory interface
 * @export
 */
export const TrackingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrackingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [platformDeviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiagnostics(userId?: string, platformDeviceId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDiagnostics(userId, platformDeviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [platformDeviceId] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [skip] Default: null
         * @param {number} [limit] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiagnostics(userId?: string, platformDeviceId?: string, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, skip?: number, limit?: number, options?: any): AxiosPromise<DiagnosticsGridDataModel> {
            return localVarFp.getDiagnostics(userId, platformDeviceId, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {number} [startHour] Default: null
         * @param {number} [endHour] Default: null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTracking(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, startHour?: number, endHour?: number, options?: any): AxiosPromise<CustomerTrackingGridViewDataPage> {
            return localVarFp.getUserTracking(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, startHour, endHour, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteDiagnostics operation in TrackingApi.
 * @export
 * @interface TrackingApiDeleteDiagnosticsRequest
 */
export interface TrackingApiDeleteDiagnosticsRequest {
    /**
     * 
     * @type {string}
     * @memberof TrackingApiDeleteDiagnostics
     */
    readonly userId?: string

    /**
     * 
     * @type {string}
     * @memberof TrackingApiDeleteDiagnostics
     */
    readonly platformDeviceId?: string
}

/**
 * Request parameters for getDiagnostics operation in TrackingApi.
 * @export
 * @interface TrackingApiGetDiagnosticsRequest
 */
export interface TrackingApiGetDiagnosticsRequest {
    /**
     * 
     * @type {string}
     * @memberof TrackingApiGetDiagnostics
     */
    readonly userId?: string

    /**
     * 
     * @type {string}
     * @memberof TrackingApiGetDiagnostics
     */
    readonly platformDeviceId?: string

    /**
     * 
     * @type {TimeframeType}
     * @memberof TrackingApiGetDiagnostics
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof TrackingApiGetDiagnostics
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof TrackingApiGetDiagnostics
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof TrackingApiGetDiagnostics
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof TrackingApiGetDiagnostics
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof TrackingApiGetDiagnostics
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof TrackingApiGetDiagnostics
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof TrackingApiGetDiagnostics
     */
    readonly beacons?: Array<string>

    /**
     * Default: null
     * @type {number}
     * @memberof TrackingApiGetDiagnostics
     */
    readonly skip?: number

    /**
     * Default: null
     * @type {number}
     * @memberof TrackingApiGetDiagnostics
     */
    readonly limit?: number
}

/**
 * Request parameters for getUserTracking operation in TrackingApi.
 * @export
 * @interface TrackingApiGetUserTrackingRequest
 */
export interface TrackingApiGetUserTrackingRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof TrackingApiGetUserTracking
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof TrackingApiGetUserTracking
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof TrackingApiGetUserTracking
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof TrackingApiGetUserTracking
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof TrackingApiGetUserTracking
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof TrackingApiGetUserTracking
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof TrackingApiGetUserTracking
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof TrackingApiGetUserTracking
     */
    readonly beacons?: Array<string>

    /**
     * Default: null
     * @type {number}
     * @memberof TrackingApiGetUserTracking
     */
    readonly startHour?: number

    /**
     * Default: null
     * @type {number}
     * @memberof TrackingApiGetUserTracking
     */
    readonly endHour?: number
}

/**
 * TrackingApi - object-oriented interface
 * @export
 * @class TrackingApi
 * @extends {BaseAPI}
 */
export class TrackingApi extends BaseAPI {
    /**
     * 
     * @param {TrackingApiDeleteDiagnosticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingApi
     */
    public deleteDiagnostics(requestParameters: TrackingApiDeleteDiagnosticsRequest = {}, options?: any) {
        return TrackingApiFp(this.configuration).deleteDiagnostics(requestParameters.userId, requestParameters.platformDeviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TrackingApiGetDiagnosticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingApi
     */
    public getDiagnostics(requestParameters: TrackingApiGetDiagnosticsRequest = {}, options?: any) {
        return TrackingApiFp(this.configuration).getDiagnostics(requestParameters.userId, requestParameters.platformDeviceId, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TrackingApiGetUserTrackingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingApi
     */
    public getUserTracking(requestParameters: TrackingApiGetUserTrackingRequest = {}, options?: any) {
        return TrackingApiFp(this.configuration).getUserTracking(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, requestParameters.startHour, requestParameters.endHour, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRolesToUser: async (id: string, requestBody?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignRolesToUser', 'id', id)
            const localVarPath = `/api/accounts/user/{id}/roles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/api/accounts/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageSize] Default: 10
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignRolesToUser(id: string, requestBody?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignRolesToUser(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageSize] Default: 10
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfileResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRolesToUser(id: string, requestBody?: Array<string>, options?: any): AxiosPromise<ProfileResponse> {
            return localVarFp.assignRolesToUser(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageSize] Default: 10
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(pageSize?: number, options?: any): AxiosPromise<Array<ProfileResponse>> {
            return localVarFp.getUsers(pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assignRolesToUser operation in UsersApi.
 * @export
 * @interface UsersApiAssignRolesToUserRequest
 */
export interface UsersApiAssignRolesToUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiAssignRolesToUser
     */
    readonly id: string

    /**
     * 
     * @type {Array<string>}
     * @memberof UsersApiAssignRolesToUser
     */
    readonly requestBody?: Array<string>
}

/**
 * Request parameters for getUser operation in UsersApi.
 * @export
 * @interface UsersApiGetUserRequest
 */
export interface UsersApiGetUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUser
     */
    readonly id: string
}

/**
 * Request parameters for getUsers operation in UsersApi.
 * @export
 * @interface UsersApiGetUsersRequest
 */
export interface UsersApiGetUsersRequest {
    /**
     * Default: 10
     * @type {number}
     * @memberof UsersApiGetUsers
     */
    readonly pageSize?: number
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {UsersApiAssignRolesToUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public assignRolesToUser(requestParameters: UsersApiAssignRolesToUserRequest, options?: any) {
        return UsersApiFp(this.configuration).assignRolesToUser(requestParameters.id, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiGetUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(requestParameters: UsersApiGetUserRequest, options?: any) {
        return UsersApiFp(this.configuration).getUser(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiGetUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(requestParameters: UsersApiGetUsersRequest = {}, options?: any) {
        return UsersApiFp(this.configuration).getUsers(requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VisitorStatisticsApi - axios parameter creator
 * @export
 */
export const VisitorStatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewVisitorsCount: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visitor-statistics/new-visitors-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewVsReturning: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visitor-statistics/new-vs-returning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNmRecurringVisits: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visitor-statistics/nm-recurring-visits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreVisits: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visitor-statistics/store-visits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitStatistics: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visitor-statistics/visit/frequency-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitingUsers: async (skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visitor-statistics/visiting-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitorsCount: async (rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visitor-statistics/visitors-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BmsClientKey required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }

            if (stores) {
                localVarQueryParameter['stores'] = stores;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (cities) {
                localVarQueryParameter['cities'] = cities;
            }

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (beacons) {
                localVarQueryParameter['beacons'] = beacons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VisitorStatisticsApi - functional programming interface
 * @export
 */
export const VisitorStatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VisitorStatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewVisitorsCount(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewVisitorsCount(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewVsReturning(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewVsReturningView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewVsReturning(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNmRecurringVisits(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNmRecurringVisits(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreVisits(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviousCurrentValueView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreVisits(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitStatistics(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreVisitsStatisticsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitStatistics(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitingUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitingUserViewDataPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitingUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitorsCount(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitorsCount(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VisitorStatisticsApi - factory interface
 * @export
 */
export const VisitorStatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VisitorStatisticsApiFp(configuration)
    return {
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewVisitorsCount(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.getNewVisitorsCount(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewVsReturning(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<NewVsReturningView> {
            return localVarFp.getNewVsReturning(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNmRecurringVisits(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.getNmRecurringVisits(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreVisits(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<PreviousCurrentValueView> {
            return localVarFp.getStoreVisits(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitStatistics(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<StoreVisitsStatisticsView> {
            return localVarFp.getVisitStatistics(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitingUsers(skip?: number, limit?: number, rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<VisitingUserViewDataPage> {
            return localVarFp.getVisitingUsers(skip, limit, rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeframeType} [rangeType] 
         * @param {IsoDateTimeString} [rangeStart] 
         * @param {IsoDateTimeString} [rangeEnd] 
         * @param {Array<number>} [stores] 
         * @param {Array<number>} [countries] 
         * @param {Array<number>} [cities] 
         * @param {Array<number>} [areas] 
         * @param {Array<string>} [beacons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitorsCount(rangeType?: TimeframeType, rangeStart?: IsoDateTimeString, rangeEnd?: IsoDateTimeString, stores?: Array<number>, countries?: Array<number>, cities?: Array<number>, areas?: Array<number>, beacons?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.getVisitorsCount(rangeType, rangeStart, rangeEnd, stores, countries, cities, areas, beacons, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getNewVisitorsCount operation in VisitorStatisticsApi.
 * @export
 * @interface VisitorStatisticsApiGetNewVisitorsCountRequest
 */
export interface VisitorStatisticsApiGetNewVisitorsCountRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof VisitorStatisticsApiGetNewVisitorsCount
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof VisitorStatisticsApiGetNewVisitorsCount
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof VisitorStatisticsApiGetNewVisitorsCount
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetNewVisitorsCount
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetNewVisitorsCount
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetNewVisitorsCount
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetNewVisitorsCount
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VisitorStatisticsApiGetNewVisitorsCount
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getNewVsReturning operation in VisitorStatisticsApi.
 * @export
 * @interface VisitorStatisticsApiGetNewVsReturningRequest
 */
export interface VisitorStatisticsApiGetNewVsReturningRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof VisitorStatisticsApiGetNewVsReturning
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof VisitorStatisticsApiGetNewVsReturning
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof VisitorStatisticsApiGetNewVsReturning
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetNewVsReturning
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetNewVsReturning
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetNewVsReturning
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetNewVsReturning
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VisitorStatisticsApiGetNewVsReturning
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getNmRecurringVisits operation in VisitorStatisticsApi.
 * @export
 * @interface VisitorStatisticsApiGetNmRecurringVisitsRequest
 */
export interface VisitorStatisticsApiGetNmRecurringVisitsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof VisitorStatisticsApiGetNmRecurringVisits
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof VisitorStatisticsApiGetNmRecurringVisits
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof VisitorStatisticsApiGetNmRecurringVisits
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetNmRecurringVisits
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetNmRecurringVisits
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetNmRecurringVisits
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetNmRecurringVisits
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VisitorStatisticsApiGetNmRecurringVisits
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getStoreVisits operation in VisitorStatisticsApi.
 * @export
 * @interface VisitorStatisticsApiGetStoreVisitsRequest
 */
export interface VisitorStatisticsApiGetStoreVisitsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof VisitorStatisticsApiGetStoreVisits
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof VisitorStatisticsApiGetStoreVisits
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof VisitorStatisticsApiGetStoreVisits
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetStoreVisits
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetStoreVisits
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetStoreVisits
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetStoreVisits
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VisitorStatisticsApiGetStoreVisits
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getVisitStatistics operation in VisitorStatisticsApi.
 * @export
 * @interface VisitorStatisticsApiGetVisitStatisticsRequest
 */
export interface VisitorStatisticsApiGetVisitStatisticsRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof VisitorStatisticsApiGetVisitStatistics
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof VisitorStatisticsApiGetVisitStatistics
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof VisitorStatisticsApiGetVisitStatistics
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetVisitStatistics
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetVisitStatistics
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetVisitStatistics
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetVisitStatistics
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VisitorStatisticsApiGetVisitStatistics
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getVisitingUsers operation in VisitorStatisticsApi.
 * @export
 * @interface VisitorStatisticsApiGetVisitingUsersRequest
 */
export interface VisitorStatisticsApiGetVisitingUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof VisitorStatisticsApiGetVisitingUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof VisitorStatisticsApiGetVisitingUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {TimeframeType}
     * @memberof VisitorStatisticsApiGetVisitingUsers
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof VisitorStatisticsApiGetVisitingUsers
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof VisitorStatisticsApiGetVisitingUsers
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetVisitingUsers
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetVisitingUsers
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetVisitingUsers
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetVisitingUsers
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VisitorStatisticsApiGetVisitingUsers
     */
    readonly beacons?: Array<string>
}

/**
 * Request parameters for getVisitorsCount operation in VisitorStatisticsApi.
 * @export
 * @interface VisitorStatisticsApiGetVisitorsCountRequest
 */
export interface VisitorStatisticsApiGetVisitorsCountRequest {
    /**
     * 
     * @type {TimeframeType}
     * @memberof VisitorStatisticsApiGetVisitorsCount
     */
    readonly rangeType?: TimeframeType

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof VisitorStatisticsApiGetVisitorsCount
     */
    readonly rangeStart?: IsoDateTimeString

    /**
     * 
     * @type {IsoDateTimeString}
     * @memberof VisitorStatisticsApiGetVisitorsCount
     */
    readonly rangeEnd?: IsoDateTimeString

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetVisitorsCount
     */
    readonly stores?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetVisitorsCount
     */
    readonly countries?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetVisitorsCount
     */
    readonly cities?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VisitorStatisticsApiGetVisitorsCount
     */
    readonly areas?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VisitorStatisticsApiGetVisitorsCount
     */
    readonly beacons?: Array<string>
}

/**
 * VisitorStatisticsApi - object-oriented interface
 * @export
 * @class VisitorStatisticsApi
 * @extends {BaseAPI}
 */
export class VisitorStatisticsApi extends BaseAPI {
    /**
     * 
     * @param {VisitorStatisticsApiGetNewVisitorsCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorStatisticsApi
     */
    public getNewVisitorsCount(requestParameters: VisitorStatisticsApiGetNewVisitorsCountRequest = {}, options?: any) {
        return VisitorStatisticsApiFp(this.configuration).getNewVisitorsCount(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisitorStatisticsApiGetNewVsReturningRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorStatisticsApi
     */
    public getNewVsReturning(requestParameters: VisitorStatisticsApiGetNewVsReturningRequest = {}, options?: any) {
        return VisitorStatisticsApiFp(this.configuration).getNewVsReturning(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisitorStatisticsApiGetNmRecurringVisitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorStatisticsApi
     */
    public getNmRecurringVisits(requestParameters: VisitorStatisticsApiGetNmRecurringVisitsRequest = {}, options?: any) {
        return VisitorStatisticsApiFp(this.configuration).getNmRecurringVisits(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisitorStatisticsApiGetStoreVisitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorStatisticsApi
     */
    public getStoreVisits(requestParameters: VisitorStatisticsApiGetStoreVisitsRequest = {}, options?: any) {
        return VisitorStatisticsApiFp(this.configuration).getStoreVisits(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisitorStatisticsApiGetVisitStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorStatisticsApi
     */
    public getVisitStatistics(requestParameters: VisitorStatisticsApiGetVisitStatisticsRequest = {}, options?: any) {
        return VisitorStatisticsApiFp(this.configuration).getVisitStatistics(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisitorStatisticsApiGetVisitingUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorStatisticsApi
     */
    public getVisitingUsers(requestParameters: VisitorStatisticsApiGetVisitingUsersRequest = {}, options?: any) {
        return VisitorStatisticsApiFp(this.configuration).getVisitingUsers(requestParameters.skip, requestParameters.limit, requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisitorStatisticsApiGetVisitorsCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorStatisticsApi
     */
    public getVisitorsCount(requestParameters: VisitorStatisticsApiGetVisitorsCountRequest = {}, options?: any) {
        return VisitorStatisticsApiFp(this.configuration).getVisitorsCount(requestParameters.rangeType, requestParameters.rangeStart, requestParameters.rangeEnd, requestParameters.stores, requestParameters.countries, requestParameters.cities, requestParameters.areas, requestParameters.beacons, options).then((request) => request(this.axios, this.basePath));
    }
}


