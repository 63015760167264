import React from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis } from "recharts";
import { SegmentGroupView } from "../../api";
import { useCachedInstance } from "../../services/hooks";
import { highlight } from "../widgets/HighlightedString";
import Card from "./Card";

import "./CustomerSegmentCard.scss";

type Props = {
  model: SegmentGroupView;
  highlightText?: string;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
};

export function CustomerSegmentCard(props: Props) {
  const segments = useCachedInstance(props.model.segments || []);

  return (
    <Card className="customer-segment-card">
      <div className="details">
        <h1>{highlight(props.model.title || "No title", props.highlightText)}</h1>
        <ResponsiveContainer height={250}>
          <BarChart className="customer-segment-preview" data={segments}>
            <XAxis dataKey="name"></XAxis>
            <Bar
              className="segment-bar"
              dataKey={"size"}
              label={{ fill: "white", stroke: "4px", formatter: (value: number) => value.toString() + "%" }}
            ></Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="buttons">
        <button onClick={props.onClickDelete} className="delete">
          DELETE
        </button>
        <button onClick={props.onClickEdit} className="edit">
          EDIT
        </button>
      </div>
    </Card>
  );
}
