import { GeoProjection } from "d3-geo";
import { isNaN, max, min } from "lodash";
import { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { LocationsApi, WorldHeatmapMarkerView } from "../../../api";
import { isHttpOk } from "../../../services/api";
import { isProvided } from "../../../services/objects";
import { getBulletedTooltip, getTooltip } from "../../../services/tooltip";
import { getApiConfig } from "../../../state/configuration";
import { useTimeLocationFilters } from "../../../state/globalFilters";
import Loading from "../../widgets/Loading";
import { ComposableMap, Geographies, Geography, MapContext, useZoomPan } from "./simple-maps";

import CountriesData from "../../../data/countries-110m.json";

interface WorldMapMarker {
  id: string;
  title: string;
  text: string;
  nmVisits: number;
  latitude: number;
  longitude: number;
}

type ProjectedMarker = {
  marker: WorldMapMarker;
  x: number;
  y: number;
};

class MarkerGroup {
  public x = 0;
  public y = 0;
  public nmVisits = 0;
  public markers: ProjectedMarker[];

  constructor(marker: ProjectedMarker) {
    this.x = marker.x;
    this.y = marker.y;
    this.nmVisits = marker.marker.nmVisits;
    this.markers = [marker];
  }

  public add(marker: ProjectedMarker) {
    this.x = (this.x * this.markers.length + marker.x) / (this.markers.length + 1);
    this.y = (this.y * this.markers.length + marker.y) / (this.markers.length + 1);
    this.nmVisits = (this.nmVisits * this.markers.length + marker.marker.nmVisits) / (this.markers.length + 1);
    this.markers.push(marker);
  }
}

function distance(a: { x: number; y: number }, b: { x: number; y: number }) {
  return Math.sqrt((a.x - b.x) * (a.x - b.x) + (a.y - b.y) * (a.y - b.y));
}

function groupByArea(items: readonly ProjectedMarker[], getRadius: (nmVisits: number) => number) {
  const groups: MarkerGroup[] = [];
  const queue = items.slice();
  while (queue.length > 0) {
    const current = queue.pop()!;
    let added = false;
    for (const group of groups) {
      if (distance(current, group) < getRadius(current.marker.nmVisits) + getRadius(group.nmVisits)) {
        group.add(current);
        added = true;
        break;
      }
    }
    if (!added) {
      groups.push(new MarkerGroup(current));
    }
  }
  return groups;
}

function MapContent(props: { markerSize: number; markers: WorldMapMarker[] }) {
  const { projection } = useContext<{ projection: GeoProjection }>(MapContext);
  const maxNmVisits = max(props.markers.map((m) => m.nmVisits)) || 0;
  const projectedMarkers = props.markers
    .map<ProjectedMarker | null>((m) => {
      const projected = projection([m.longitude, m.latitude]);
      if (!projected || projected.some(isNaN)) return null;
      return {
        marker: m,
        x: projected[0],
        y: projected[1],
      };
    })
    .filter(isProvided);

  const topLeftX = min(projectedMarkers.map((c) => c.x)) || 0;
  const topLeftY = min(projectedMarkers.map((c) => c.y)) || 0;
  const bottomRightX = max(projectedMarkers.map((c) => c.x)) || 0;
  const bottomRightY = max(projectedMarkers.map((c) => c.y)) || 0;
  const center = projection.invert!([(topLeftX + bottomRightX) / 2, (topLeftY + bottomRightY) / 2]);
  const scaleY = 1000 / (bottomRightY - topLeftY);
  const scaleX = 1000 / (bottomRightX - topLeftX);
  const scale = min([scaleX, scaleY, 60])! * 0.8;

  const { mapRef, position, transformString } = useZoomPan({
    center: center,
    zoom: scale,
    scaleExtent: [1.2, 60],
    translateExtent: [
      [-200, -200],
      [1200, 1200],
    ],
  });

  const zoom = position.k;
  const outerRadius = (450 * props.markerSize) / zoom;
  const innerRadius = (260 * props.markerSize) / zoom;
  const strokeWidth = (100 * props.markerSize) / zoom;
  const groups = groupByArea(projectedMarkers, () => outerRadius / 2);
  const jsonGroups = JSON.stringify(groups);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [jsonGroups]);

  const getMarkerColor = (nmVisits: number) => {
    if (nmVisits <= maxNmVisits * 0.2) return "red";
    if (nmVisits < maxNmVisits * 0.7) return "yellow";
    return "green";
  };

  function getGroupTooltip(group: MarkerGroup) {
    if (group.markers.length === 1) {
      return getTooltip(group.markers[0].marker.text, group.markers[0].marker.title);
    }
    return getBulletedTooltip({
      text: "",
      bulletPoints: group.markers.map((m) => m.marker.title),
      title: `${group.markers.length} stores`,
    });
  }

  const markers = groups.map((g, i) => {
    return (
      <g
        key={i}
        data-for="appTooltip"
        data-tip={getGroupTooltip(g)}
        transform={`translate(${g.x}, ${g.y})`}
        className={"marker center " + getMarkerColor(g.nmVisits)}
      >
        <circle className="stroked" r={outerRadius} stroke="#000000" strokeWidth={strokeWidth} fill="none" />
        <circle className="filled" r={innerRadius} stroke="none" fill="#000000" />
      </g>
    );
  });

  return (
    <g ref={mapRef as any}>
      <rect width={1000} height={1000} fill="transparent" />
      <g transform={transformString} className={"rsm-zoomable-group"}>
        <Geographies geography={CountriesData} stroke="white">
          {({ geographies }: any) => {
            return geographies.map((geo: any) => <Geography strokeWidth={0.5} key={geo.rsmKey} geography={geo} />);
          }}
        </Geographies>
        {markers}
      </g>
    </g>
  );
}

export function WorldHeatmap(props: { markerSize: number }) {
  const filters = useTimeLocationFilters();
  const [state, setState] = useState({
    map: [] as WorldHeatmapMarkerView[],
    loading: true,
  });

  useEffect(() => {
    setState({ map: [], loading: true });
    const apiConfig = getApiConfig();
    const locationApi = new LocationsApi(apiConfig);
    locationApi.getWorldHeatmap(filters).then((result) => {
      if (isHttpOk(result)) {
        setState({
          loading: false,
          map: result.data,
        });
        window.setTimeout(ReactTooltip.rebuild, 1000);
      } else {
        setState({
          loading: false,
          map: [],
        });
      }
    });
  }, [filters]);

  const markers: WorldMapMarker[] = state.map.map((marker) => ({
    id: marker.id.toString(),
    title: marker.name || "",
    nmVisits: marker.nmVisits,
    latitude: marker.latitude,
    longitude: marker.longitude,
    text: `${marker.nmVisits} visits`,
  }));

  if (state.loading) {
    return (
      <div className="world-map">
        <Loading />
      </div>
    );
  } else {
    return (
      <ComposableMap width={1000} height={1000} className="world-map" projection="geoMercator">
        <MapContent markers={markers} markerSize={props.markerSize} />
      </ComposableMap>
    );
  }
}
