import { useCallback } from "react";
import { CustomerSummaryStatistic, CustomersApi } from "../../api";
import { logErrorSilently } from "../../services/alerts";
import { isHttpOk } from "../../services/api";
import { useMoneyFormatter } from "../../services/format";
import { useStateEx } from "../../services/hooks";
import { getApiConfig } from "../../state/configuration";
import { useDeploymentConfig } from "../../state/deployment";
import { useTimeLocationFilters } from "../../state/globalFilters";
import Card from "../cards/Card";
import { Modal, useModal } from "../modals/Modal";
import { Avatar } from "../widgets/Avatar";
import { PaginatedTable } from "../widgets/PaginatedTable";
import { CustomerDataTable } from "./CustomerDataTable";

import "./TopCustomers.scss";

function TopCustomers() {
  const { config } = useDeploymentConfig();
  const storeWord = config.translations.store.singular;

  const sortOptions = [
    {
      label: "Touchpoints",
      value: CustomerSummaryStatistic.NmOfTouchpoints,
    },
    {
      label: `${storeWord} Visits`,
      value: CustomerSummaryStatistic.NmOfStoreVisits,
    },
    {
      label: "Beacon Contacts",
      value: CustomerSummaryStatistic.NmOfBeaconContacts,
    },
  ];

  const hasPurchaseHistories = !config.disablePurchaseHistories;

  if (hasPurchaseHistories) {
    sortOptions.splice(1, 0, {
      label: "Revenue",
      value: CustomerSummaryStatistic.TotalPurchaseAmount,
    });
  }

  const filters = useTimeLocationFilters();
  const { formatMoney } = useMoneyFormatter();
  const defaultValue = hasPurchaseHistories
    ? CustomerSummaryStatistic.TotalPurchaseAmount
    : CustomerSummaryStatistic.NmOfStoreVisits;
  const { state, mergeState } = useStateEx({
    selectedValue: defaultValue,
    firstRun: true,
    isEmpty: true,
  });

  const getColumnHeader = (selectedValue: number) => {
    switch (selectedValue) {
      case CustomerSummaryStatistic.NmOfTouchpoints:
        return "TOUCHPOINTS";
      case CustomerSummaryStatistic.TotalPurchaseAmount:
        return "REVENUE";
      case CustomerSummaryStatistic.NmOfStoreVisits:
        return `${storeWord.toUpperCase()} VISITS`;
      default:
        return "BEACON CONTACTS";
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      mergeState({ selectedValue: value as CustomerSummaryStatistic });
    }
  };

  const dataSource = useCallback(async () => {
    function getData() {
      const api = new CustomersApi(getApiConfig());
      switch (state.selectedValue) {
        case CustomerSummaryStatistic.NmOfTouchpoints:
          return api.getByTouchpoints({ limit: 5, ...filters });
        case CustomerSummaryStatistic.TotalPurchaseAmount:
          return api.getByRevenue({ limit: 5, ...filters });
        case CustomerSummaryStatistic.NmOfStoreVisits:
          return api.getByVisits({ limit: 5, ...filters });
        default:
          return api.getByBeaconContacts({ limit: 5, ...filters });
      }
    }

    try {
      const result = await getData();
      if (isHttpOk(result)) {
        // If Purchase history is enabled but not populated, default to Touchpoints
        if (state.firstRun) {
          if (state.selectedValue === CustomerSummaryStatistic.TotalPurchaseAmount && result.data.length === 0) {
            mergeState({ selectedValue: CustomerSummaryStatistic.NmOfTouchpoints, firstRun: false });
          } else {
            mergeState({ firstRun: false });
          }
        }
        mergeState({ isEmpty: result.data.length === 0 });
        return {
          count: result.data.length,
          items: result.data,
        };
      } else {
        mergeState({ isEmpty: true });
        logErrorSilently(result);
      }
    } catch (err) {
      logErrorSilently(err);
    }
    return {
      count: 0,
      items: [],
    };
  }, [filters, state.selectedValue, state.firstRun, mergeState]);

  const modalId = "customers";
  const { openModal, closeModal } = useModal(modalId);

  const tableHeaders = [{ title: "NAME" }, { title: getColumnHeader(state.selectedValue) }];

  return (
    <Card className="top-customers">
      <PaginatedTable
        mainHeader={
          <header>
            <h1>{config.disablePurchaseHistories ? "Top Users" : "Top Customers"}</h1>
            <select value={state.selectedValue} onChange={handleChange}>
              {sortOptions.map((option, i) => (
                <option value={option.value} key={i}>
                  {option.label}
                </option>
              ))}
            </select>
          </header>
        }
        tableClassName="with-image"
        minLoadingTimeMs={200}
        initialPageSize={5}
        hidePagination={true}
        tableDataSource={dataSource}
        tableHeaders={tableHeaders}
        tableRowFactory={(item) => {
          return [
            <div className="profile-info">
              <Avatar name={item.name || "Anonymous"} image={item.pictureUrl} />
              <span className="name" title={item.name || "Anonymous"}>
                {item.name}
              </span>
            </div>,
            state.selectedValue === CustomerSummaryStatistic.TotalPurchaseAmount ? formatMoney(item.value) : item.value,
          ];
        }}
      >
        {!state.isEmpty && (
          <div className="show-all" onClick={() => openModal()}>
            Show all
          </div>
        )}
      </PaginatedTable>
      <Modal id={modalId} onRequestClose={closeModal} className="wide-modal">
        <section>
          <CustomerDataTable
            hideZeros={true}
            visitorsOnly={false}
            selectedStatistic={state.selectedValue}
            customOptions={sortOptions}
          />
        </section>
      </Modal>
    </Card>
  );
}

export default TopCustomers;
