import { format } from "date-fns";
import { CustomAudienceType, MessageStatus, MessageView } from "../../api";
import { stringToDate } from "../../services/date";
import { useLocalizedText } from "../../services/localization";
import { isProvided } from "../../services/objects";
import { getTooltip } from "../../services/tooltip";
import { HighlightColor } from "../../services/types";
import { useAccessControl } from "../../state/authentication";
import { ConvertedMessageIcon, ConvertingMessageIcon, MuteNotification } from "../icons/Icons";
import { AudienceType } from "../modals/forms/AudienceEditors";
import { AudienceDisplay } from "../modals/forms/AudienceForm";
import { highlight } from "../widgets/HighlightedString";
import Card from "./Card";
import { MessageImage } from "./MessageImage";

import "./MessageCard.scss";

export interface Props extends MessageView {
  highlightText?: string;
  loading?: boolean;
  onClickDuplicate?: () => void;
  onClickDelete?: () => void;
  onClickEdit?: () => void;
  onForceSend?: () => void;
}

function formatDate(value: Date, withTime?: boolean) {
  if (value instanceof Date) {
    if (withTime) {
      return format(value, "MMM dd, yyyy HH:mm");
    } else {
      return format(value, "MMM dd, yyyy");
    }
  }
  throw new Error(value + " is not a Date");
}

function renderMessageDuration(props: Props) {
  if (props.loading) return "...loading...";
  if (props.status === MessageStatus.Expired) return "Expired";

  const at = stringToDate(props.dateAt, true);
  const from = stringToDate(props.dateFrom, false);
  const to = stringToDate(props.dateTo, false);

  if (!at && !from && !to) {
    return "Never expires";
  } else if (at) {
    return formatDate(at, true);
  } else if (!to && from) {
    return "From " + formatDate(from);
  } else if (!from && to) {
    return "Until " + formatDate(to);
  } else {
    return formatDate(from!) + " - " + formatDate(to!);
  }
}

export function MessageCard(props: Props) {
  const texts = useLocalizedText();
  const permissions = useAccessControl();

  function getType() {
    if (props.audienceType === CustomAudienceType.Global) {
      return "GLOBAL";
    } else {
      return "LOCAL";
    }
  }

  function getStatus() {
    if (props.loading) return "LOADING";
    if (props.draftId && props.id === 0) return "DRAFT";

    switch (props.status) {
      case MessageStatus.Deleted:
        return "DELETED";
      case MessageStatus.Expired:
        return "EXPIRED";
      case MessageStatus.Live:
        return "LIVE";
      case MessageStatus.Paused:
        return "PAUSED";
      default:
        return "SCHEDULED";
    }
  }

  function getColor(): HighlightColor {
    if (props.loading) {
      return "blue";
    }
    if (props.draftId && props.id === 0) return "pink";

    switch (props.status) {
      case MessageStatus.Deleted:
        return "black";
      case MessageStatus.Expired:
        return "red";
      case MessageStatus.Live:
        return "green";
      case MessageStatus.Paused:
        return "yellow";
      default:
        return "blue";
    }
  }

  const audienceConfig = props.audienceConfig || undefined;
  const hasAllUsersPart = isProvided(audienceConfig?.parts?.at(0)?.sentence?.allUsers);
  const audienceType = hasAllUsersPart ? AudienceType.All : AudienceType.Custom;

  return (
    <Card className="message-card">
      <MessageImage
        pushTitle={props.pushTitle}
        pushText={props.pushText}
        offerTitle={props.offerTitle}
        offerRichText={props.offerRichText}
        status={props.status}
        imageUrl={props.imageUrl}
        grayImageUrl={props.grayImageUrl}
        callToActionTitle={props.callToActionTitle}
        callToActionLink={props.callToActionLink}
        isRedeemable={props.isRedeemable}
      />
      <div className="message-details">
        <div className="status">
          <div className="status-top">
            {props.isSilent && (
              <span className="mute-icon">
                <MuteNotification />
              </span>
            )}
            {props.isConvertedMessage && (
              <span
                className="converted-icon"
                data-for="appTooltip"
                data-tip={getTooltip(props.convertingOfferLabel || "", "Converted by:")}
              >
                <ConvertedMessageIcon />
              </span>
            )}
            {props.isConvertingMessage && (
              <span
                className="converting-icon"
                data-for="appTooltip"
                data-tip={getTooltip(props.convertedOfferLabel || "", "Converts:")}
              >
                <ConvertingMessageIcon />
              </span>
            )}
            <strong className={"text " + getColor()}>{getStatus()}</strong>
            <strong className="type">{getType()}</strong>
            {props.draftId && !!props.id && <em>(has draft changes)</em>}
          </div>
          <AudienceDisplay audienceType={audienceType} audience={audienceConfig} total={props.estimatedAudience} />
        </div>
        <div className="description">
          <h1>{highlight(props.label, props.highlightText) || "(No label)"}</h1>
          <h2 className="title">
            {highlight(`[${props.id || "draft"}] ${props.offerTitle || "(No title)"}`, props.highlightText)}
          </h2>
          <div className="text" dangerouslySetInnerHTML={{ __html: props.offerRichText || "No description" }}></div>
          <div className="schedule">{renderMessageDuration(props)}</div>
        </div>
        <div className="buttons">
          {permissions.forceSendMessage && typeof props.onForceSend === "function" && (
            <button
              className="for-developers force-send"
              data-for="appTooltip"
              data-tip={getTooltip(texts.messages.forceSend)}
              onClick={props.onForceSend}
            >
              FORCE SEND
            </button>
          )}
          <button onClick={props.onClickDelete} className="delete">
            DELETE
          </button>
          <button onClick={props.onClickDuplicate} className="duplicate">
            DUPLICATE
          </button>
          <button onClick={props.onClickEdit} className="edit">
            EDIT
          </button>
        </div>
      </div>
    </Card>
  );
}
