import { MutableRefObject, Ref, RefCallback } from "react";
import { CancellablePromise } from "../../services/objects";
import { PDFPageProxy } from "../pdfjs";

/**
 * Checks if we're running in a browser environment.
 */
export const isBrowser = typeof window !== "undefined";

/**
 * Checks whether we're running from a local file system.
 */
export const isLocalFileSystem = isBrowser && window.location.protocol === "file:";

/**
 * Checks whether we're running on a production build or not.
 */
export const isProduction = process.env.NODE_ENV === "production";

/**
 * Checks whether a variable is defined.
 *
 * @param {*} variable Variable to check
 */
export function isDefined<T>(variable: T | undefined): variable is T {
  return typeof variable !== "undefined";
}

/**
 * Checks whether a variable is defined and not null.
 *
 * @param {*} variable Variable to check
 */
export function isProvided<T>(variable: T | undefined | null): variable is T {
  return isDefined(variable) && variable !== null;
}

/**
 * Checkes whether a variable provided is a string.
 *
 * @param {*} variable Variable to check
 */
export function isString(variable: any): variable is string {
  return typeof variable === "string";
}

/**
 * Checks whether a variable provided is an ArrayBuffer.
 *
 * @param {*} variable Variable to check
 */
export function isArrayBuffer(variable: any): variable is ArrayBuffer {
  return variable instanceof ArrayBuffer;
}

/**
 * Checkes whether a variable provided is a Blob.
 *
 * @param {*} variable Variable to check
 */
export function isBlob(variable: any): variable is Blob {
  if (!isBrowser) {
    throw new Error("Attempted to check if a variable is a Blob on a non-browser environment.");
  }

  return variable instanceof Blob;
}

/**
 * Checkes whether a variable provided is a File.
 *
 * @param {*} variable Variable to check
 */
export function isFile(variable: any): variable is File {
  if (!isBrowser) {
    throw new Error("Attempted to check if a variable is a File on a non-browser environment.");
  }

  return variable instanceof File;
}

/**
 * Checks whether a string provided is a data URI.
 *
 * @param {string} str String to check
 */
export function isDataURI(str: any): str is string {
  return isString(str) && /^data:/.test(str);
}

export function dataURItoByteString(dataURI: any) {
  if (!isDataURI(dataURI)) {
    throw new Error("Invalid data URI.");
  }

  const [headersString, dataString] = dataURI.split(",");
  const headers = headersString.split(";");

  if (headers.indexOf("base64") !== -1) {
    return atob(dataString);
  }

  return unescape(dataString);
}

export function getPixelRatio() {
  return (isBrowser && window.devicePixelRatio) || 1;
}

function consoleOnDev(method: "warn" | "error", ...message: any[]) {
  if (!isProduction) {
    // eslint-disable-next-line no-console
    console[method](...message);
  }
}

export function warnOnDev(...message: any[]) {
  consoleOnDev("warn", ...message);
}

export function errorOnDev(...message: any[]) {
  consoleOnDev("error", ...message);
}

export function displayCORSWarning() {
  if (isLocalFileSystem) {
    warnOnDev(
      "Loading PDF as base64 strings/URLs might not work on protocols other than HTTP/HTTPS. On Google Chrome, you can use --allow-file-access-from-files flag for debugging purposes."
    );
  }
}

export function cancelRunningTask<T>(runningTask?: CancellablePromise<T> | null) {
  if (runningTask && runningTask.cancel) runningTask.cancel();
}

export type EnhancedPage = PDFPageProxy & {
  width: number;
  height: number;
  originalWidth: number;
  originalHeight: number;
};

export function makePageCallback(page: PDFPageProxy, scale: number): EnhancedPage {
  Object.defineProperty(page, "width", {
    get() {
      return this.view[2] * scale;
    },
    configurable: true,
  });
  Object.defineProperty(page, "height", {
    get() {
      return this.view[3] * scale;
    },
    configurable: true,
  });
  Object.defineProperty(page, "originalWidth", {
    get() {
      return this.view[2];
    },
    configurable: true,
  });
  Object.defineProperty(page, "originalHeight", {
    get() {
      return this.view[3];
    },
    configurable: true,
  });
  return page as EnhancedPage;
}

export function isCancelException(error: Error) {
  return error.name === "RenderingCancelledException";
}

export function loadFromFile(file: File | Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (isArrayBuffer(reader.result)) {
        resolve(new Uint8Array(reader.result));
      } else {
        reject(new Error("File read result is not ArrayBuffer."));
      }
    };
    reader.onerror = (event) => {
      if (event.target && event.target.error) {
        switch (event.target.error.code) {
          case event.target.error.NOT_FOUND_ERR:
            return reject(new Error("Error while reading a file: File not found."));
          case event.target.error.NOT_SUPPORTED_ERR:
            return reject(new Error("Error while reading a file: File not readable."));
          case event.target.error.SECURITY_ERR:
            return reject(new Error("Error while reading a file: Security error."));
          case event.target.error.ABORT_ERR:
            return reject(new Error("Error while reading a file: Aborted."));
        }
      }
      return reject(new Error("Error while reading a file."));
    };
    reader.readAsArrayBuffer(file);
    return null;
  });
}

export function mergeRefs<T>(...inputRefs: (RefCallback<T> | Ref<T> | undefined)[]) {
  if (inputRefs.length <= 1) {
    return inputRefs[0];
  }

  return function mergedRefs(ref: T) {
    inputRefs.forEach((inputRef) => {
      if (typeof inputRef === "function") {
        inputRef(ref);
      } else if (inputRef) {
        // eslint-disable-next-line no-param-reassign
        (inputRef as MutableRefObject<T>).current = ref;
      }
    });
  };
}

export function mergeClassNames(...args: any[]) {
  return Array.prototype.slice
    .call(args)
    .reduce((classList, arg) => classList.concat(arg), [])
    .filter((arg: any) => typeof arg === "string")
    .join(" ");
}

export function coalesce<T>(values: (T | null | undefined)[], defaultValue: T): T {
  for (const v of values) {
    if (isProvided(v)) return v;
  }
  return defaultValue;
}
