import { useEffect } from "react";
import { DemographicsApi, DemographicsView, RevenueStatisticsApi } from "../api";
import StatsCardSimple from "../app/cards/StatsCardSimple";
import { Dollar, NewAndReturning, RepeatPurchase, RevenueLocation, TransactionVertical } from "../app/icons/Icons";
import { AppSalesByCustomerType } from "../app/tables/AppSalesByCustomerType";
import { AgeChart, GenderChart } from "../app/widgets/Demographics";
import { AnalyticsPageHeader } from "../app/widgets/PageHeaders";
import { isHttpOk } from "../services/api";
import { formatPercentage, useMoneyFormatter } from "../services/format";
import { useStateEx } from "../services/hooks";
import { useLocalizedText } from "../services/localization";
import { isProvided } from "../services/objects";
import { getApiConfig } from "../state/configuration";
import { useTimeLocationFilters } from "../state/globalFilters";
import { ShopperTrends } from "./revenue-statistics/ShopperTrends";

import "./RevenueStatistics.scss";

function RevenueStatistics() {
  type State = {
    isLoading: boolean;
    demographics: DemographicsView;
    revenue: number;
    transactions: number;
    recurringTransactions: number;
    avgTransactionValueReturningCustomers: number;
    newVsReturning: number | undefined;
  };

  const filters = useTimeLocationFilters();
  const texts = useLocalizedText();
  const { formatMoney } = useMoneyFormatter();
  const { state, mergeState } = useStateEx<Partial<State>>({ isLoading: true });

  useEffect(() => {
    mergeState({ isLoading: true });

    const config = getApiConfig();
    const revenueApi = new RevenueStatisticsApi(config);
    const demographicsApi = new DemographicsApi(config);

    const promises = [
      demographicsApi.getForTransactions(filters).then((result) => {
        if (isHttpOk(result)) {
          mergeState({
            demographics: result.data,
          });
        }
      }),
      revenueApi.getTransactions(filters).then((result) => {
        if (isHttpOk(result)) mergeState({ transactions: result.data.current });
      }),
      revenueApi.getRevenue(filters).then((result) => {
        if (isHttpOk(result)) mergeState({ revenue: result.data.current });
      }),
      revenueApi.getRecurringTransactions(filters).then((result) => {
        if (isHttpOk(result)) mergeState({ recurringTransactions: result.data });
      }),
      revenueApi.getAverageTransactionValueForReturningCustomers(filters).then((result) => {
        if (isHttpOk(result)) mergeState({ avgTransactionValueReturningCustomers: result.data });
      }),
      revenueApi.getNewVsReturning(filters).then((result) => {
        if (isHttpOk(result)) mergeState({ newVsReturning: result.data.newVsReturning || undefined });
      }),
    ];
    Promise.all(promises).then(() => {
      mergeState({ isLoading: false });
    });
  }, [filters, mergeState]);

  const text = texts.revenueStatisticsPage;

  return (
    <div className="revenue-statistics page-content">
      <AnalyticsPageHeader title={text.title} />
      <section className="analytics">
        <StatsCardSimple
          value={state.transactions}
          tooltipText={text.items.transactions.tooltip}
          label={text.items.transactions.title}
          color="red"
          figure={<TransactionVertical />}
        />
        <StatsCardSimple
          value={formatMoney(state.revenue)}
          tooltipText={text.items.revenue.tooltip}
          label={text.items.revenue.title}
          color="pink"
          figure={<RevenueLocation />}
        />
        <StatsCardSimple
          value={state.recurringTransactions}
          tooltipText={text.items.recurringTransactions.tooltip}
          label={text.items.recurringTransactions.title}
          color="green"
          figure={<RepeatPurchase />}
        />
        <StatsCardSimple
          value={formatMoney(state.avgTransactionValueReturningCustomers)}
          tooltipText={text.items.avgTransactionValueReturningCustomers.tooltip}
          label={text.items.avgTransactionValueReturningCustomers.title}
          subLabel="(Returning Customers)"
          color="blue"
          figure={<Dollar />}
        />
        <StatsCardSimple
          value={isProvided(state.newVsReturning) ? formatPercentage(state.newVsReturning / 100, 1) : "N/A"}
          tooltipText={text.items.newVsReturning.tooltip}
          label={text.items.newVsReturning.title}
          color="green"
          decimalPlaces={1}
          asPercentage={true}
          figure={<NewAndReturning />}
        />
      </section>
      <section className="trends">
        <AgeChart
          itemTitle={{ singular: "purchase", plural: "purchases" }}
          ages={state.demographics?.ageGroups || []}
        />
        <GenderChart metricTitle={{ singular: "purchase", plural: "purchases" }} genders={state.demographics} />
        <ShopperTrends />
      </section>
      <section>
        <AppSalesByCustomerType />
      </section>
    </div>
  );
}

export default RevenueStatistics;
