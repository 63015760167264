import { FocusEventHandler } from "react";
import { ChangeEventHandler, ForwardedRef, forwardRef } from "react";
import { FieldErrors } from "react-hook-form";
import { ValidationError } from "../widgets/ValidationError";

import "./Checkbox.scss";

type CheckBoxProps = {
  name: string;
  label: string;
  value?: boolean;
  className?: string;
  readOnly?: boolean;
  errorLabel?: string;
  errors?: FieldErrors;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onCheckedChange?: (value: boolean) => void;
};

function CheckboxRender(props: CheckBoxProps, inputRef: ForwardedRef<HTMLInputElement>) {
  const { value, name, className, label, errorLabel, readOnly, onBlur, onChange, onCheckedChange, errors = {} } = props;

  const error = name.split(".").reduce((errors, name) => errors && errors[name], errors);

  const renderErrors = () => {
    if (!error) {
      return null;
    }

    const fieldName = errorLabel || "Field";
    let message: string;
    switch (error.type) {
      case "required":
        message = `${fieldName} is required`;
        break;
      default:
        message = error.message || `${fieldName} is invalid`;
        break;
    }

    return <ValidationError path={props.name} message={message} />;
  };

  const required = true;

  const classes = ["form-checkbox"];
  if (className) classes.push(className);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (onChange) {
      onChange(e);
    }
    if (onCheckedChange) {
      onCheckedChange(e.currentTarget.checked);
    }
  };

  return (
    <label className={classes.join(" ")}>
      <span className={`label-text ${required ? "required" : "not-required"}`}>
        <input
          ref={inputRef}
          name={name}
          readOnly={readOnly}
          className={error ? "invalid" : undefined}
          type="checkbox"
          onChange={handleChange}
          onBlur={onBlur}
          checked={value}
        />
        {label || "Checkbox"}
      </span>
      {renderErrors()}
    </label>
  );
}

export default forwardRef(CheckboxRender);
