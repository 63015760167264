import { BeaconTrackingHeader } from "../../app/widgets/PageHeaders";
import { useStateEx } from "../../services/hooks";
import { useTimeLocationFilters } from "../../state/globalFilters";
import { ContactUserFilters, ContactUsers } from "./ContactUsers";

import "./ContactTracking.scss";

export type BeaconHourTracking = {
  beacons: string[];
  startHour?: number;
  endHour?: number;
};
export function ContactTracking() {
  const globalFilters = useTimeLocationFilters();
  const { state: beaconHour, mergeState: mergeBeaconHour } = useStateEx<BeaconHourTracking>({
    beacons: [],
  });
  const filters: ContactUserFilters = {
    ...globalFilters,
    beacons: beaconHour.beacons,
    startHour: beaconHour.startHour,
    endHour: beaconHour.endHour,
  };

  return (
    <div className="contact-tracking page-content">
      <BeaconTrackingHeader
        title="Contact Tracking"
        selectedBeaconHour={beaconHour}
        mergeBeaconHour={mergeBeaconHour}
      />
      <section>
        <ContactUsers filters={filters} />
      </section>
    </div>
  );
}
