import { editorClassName, EditorProps } from "./common";
import { LocationInput } from "./inputs/LocationInput";

export function CustomerEngagementEditor(props: EditorProps) {
  const selectedLocations = props.value.customerEngagement?.locations || [];

  return (
    <div className={editorClassName(props, "customer-engagement")}>
      <h2>{props.description}</h2>
      <LocationInput
        value={selectedLocations}
        onChange={(value) => {
          props.onChange({
            customerEngagement: {
              locations: value,
            },
          });
        }}
        error={props.error?.locations}
      />
    </div>
  );
}
