import { useEffect } from "react";
import { AnalyticsApi, PreviousCurrentValueView, RevenueStatisticsApi, TouchpointsStatisticsView } from "../../api";
import Card from "../../app/cards/Card";
import StatsCardSimple from "../../app/cards/StatsCardSimple";
import StatsCardWithTrend from "../../app/cards/StatsCardWithTrend";
import { Conversion, StoreVisit, Transaction } from "../../app/icons/Icons";
import AppTouchpointsTable from "../../app/tables/AppTouchpointsTable";
import { isHttpOk } from "../../services/api";
import { calcTrend, formatDecimal, useMoneyFormatter } from "../../services/format";
import { useStateEx } from "../../services/hooks";
import { useLocalizedText } from "../../services/localization";
import { getBulletedTooltip } from "../../services/tooltip";
import { getApiConfig } from "../../state/configuration";
import { useTimeLocationFilters } from "../../state/globalFilters";
import { TouchpointGraph } from "./TouchpointGraph";

interface StateWithRevenue {
  isLoading: true;
  touchpointsView: TouchpointsStatisticsView;
  revenue: PreviousCurrentValueView;
  transactions: PreviousCurrentValueView;
  conversionUplift: number;
  avgTransactionValueUplift: number;
  storeVisitUplift: number;
}

export function TouchpointsWithRevenue() {
  const texts = useLocalizedText();
  const filters = useTimeLocationFilters();
  const { formatMoney } = useMoneyFormatter();
  const { state, mergeState } = useStateEx<StateWithRevenue>({
    isLoading: true,
    touchpointsView: { statistics: [], frequency: 0 },
    revenue: { previous: 0, current: 0 },
    transactions: { previous: 0, current: 0 },
    conversionUplift: 0,
    avgTransactionValueUplift: 0,
    storeVisitUplift: 0,
  });

  useEffect(() => {
    mergeState({ isLoading: true });

    const config = getApiConfig();
    const revenueApi = new RevenueStatisticsApi(config);
    const analyticsApi = new AnalyticsApi(config);

    analyticsApi.getTouchpointsStatistics(filters).then((result) => {
      if (isHttpOk(result) && result.data) {
        mergeState({ touchpointsView: result.data });
      }
    });

    analyticsApi.getUpliftStatistics(filters).then((result) => {
      if (isHttpOk(result)) {
        mergeState({
          conversionUplift: result.data.conversion,
          avgTransactionValueUplift: result.data.avgTransactionValue,
          storeVisitUplift: result.data.storeVisits,
        });
      }
    });

    revenueApi.getRevenue(filters).then((result) => {
      if (isHttpOk(result)) {
        mergeState({
          revenue: result.data,
        });
      }
    });

    revenueApi.getTransactions(filters).then((result) => {
      if (isHttpOk(result)) {
        mergeState({
          transactions: result.data,
        });
      }
    });
  }, [filters, mergeState]);

  return (
    <>
      <section className="graph-and-stats">
        <TouchpointGraph
          tooltip={getBulletedTooltip(texts.appTouchpointsPage.bulletedItems.touchpointsGraph)}
          touchpointsView={state.touchpointsView}
        />
        <div className="graph-stats">
          <StatsCardWithTrend
            currentValue={formatMoney(state.revenue.current)}
            previousValue={formatMoney(state.revenue.previous)}
            trendChangePercent={calcTrend(state.revenue)}
            tooltipText={texts.appTouchpointsPage.items.appRevenue.tooltip}
            label={texts.appTouchpointsPage.items.appRevenue.title}
            color="green"
            hideDots
          />
          <StatsCardWithTrend
            currentValue={formatDecimal(state.transactions.current, 0)}
            previousValue={formatDecimal(state.transactions.previous, 0)}
            trendChangePercent={calcTrend(state.transactions)}
            tooltipText={texts.appTouchpointsPage.items.appTransactions.tooltip}
            label={texts.appTouchpointsPage.items.appTransactions.title}
            color="orange"
            hideDots
          />
        </div>
      </section>
      <section className="touchpoint-simple-cards">
        <StatsCardSimple
          asPercentage={true}
          value={state.conversionUplift}
          label={texts.appTouchpointsPage.items.conversionUplift.title}
          tooltipText={texts.appTouchpointsPage.items.conversionUplift.tooltip}
          color="red"
          figure={<Conversion />}
        />
        <StatsCardSimple
          asPercentage={true}
          value={state.avgTransactionValueUplift}
          label={texts.appTouchpointsPage.items.transactionValueUplift.title}
          tooltipText={texts.appTouchpointsPage.items.transactionValueUplift.tooltip}
          color="pink"
          figure={<Transaction />}
        />
        <StatsCardSimple
          asPercentage={true}
          value={state.storeVisitUplift}
          label={texts.appTouchpointsPage.items.storeVisitUplift.title}
          tooltipText={texts.appTouchpointsPage.items.storeVisitUplift.tooltip}
          color="blue"
          figure={<StoreVisit />}
        />
      </section>
      <section className="app-touchpoint-table">
        <Card>
          <AppTouchpointsTable touchpointsView={state.touchpointsView} />
        </Card>
      </section>
    </>
  );
}
