import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { isArray } from "lodash";
import { FieldError, useForm } from "react-hook-form";
import { CampaignDetails, DayOfWeek, ResendInterval, ScheduleModel } from "../../../api";
import { stringToDate } from "../../../services/date";
import { safeParse } from "../../../services/number";
import { ScheduleValidator } from "../../../services/validators";
import { AlertMissingValidationErrors } from "../../forms/AlertMissingValidationErrors";
import { StateSchedulePicker } from "../../forms/SchedulePicker";
import { Select } from "../../forms/Select";

import "./ScheduleForm.scss";

type Props = {
  title: string;
  identifier?: string;
  value?: ScheduleModel | null;
  onCancel?: () => void;
  onSave?: (value: ScheduleModel) => void;
  campaign?: CampaignDetails | undefined;
};

function isFieldError(error: any): error is FieldError {
  return error && "type" in error && "message" in error;
}

export function ScheduleForm(props: Props) {
  var defaultModel: ScheduleModel = props.value || {
    onlyOnce: undefined,
    messageResend: { resendInterval: ResendInterval.None },
  };

  const resendOptions = [
    { label: "None", value: ResendInterval.None },
    { label: "Daily", value: ResendInterval.Daily },
    { label: "Weekly", value: ResendInterval.Weekly },
    { label: "Monthly", value: ResendInterval.Monthly },
    { label: "Yearly", value: ResendInterval.Yearly },
    { label: "Custom (Days)", value: ResendInterval.CustomDays },
  ];

  const dayOptions = [
    { label: "Monday", value: DayOfWeek.Monday },
    { label: "Tuesday", value: DayOfWeek.Tuesday },
    { label: "Wednesday", value: DayOfWeek.Wednesday },
    { label: "Thursday", value: DayOfWeek.Thursday },
    { label: "Friday", value: DayOfWeek.Friday },
    { label: "Saturday", value: DayOfWeek.Saturday },
    { label: "Sunday", value: DayOfWeek.Sunday },
  ];

  const { control, handleSubmit, setValue, formState, watch } = useForm<ScheduleModel>({
    defaultValues: defaultModel,
    resolver: yupResolver(ScheduleValidator),
  });

  const errors = formState.errors;
  const schedulePickerError = [errors.onlyOnce, errors.duringPeriod, errors.constrainDays, errors.constrainHours].find(
    isFieldError
  );

  const formModel = watch();
  const selectedResendInterval = formModel?.messageResend?.resendInterval || ResendInterval.None;
  const selectedAllowedDays = formModel.constrainDays?.daysAllowed?.map((d) => d as DayOfWeek) ?? [];
  const selectedCustomDays = formModel?.messageResend?.customNumberOfDays ?? undefined;

  const handleSave = (model: ScheduleModel) => {
    if (props.onSave) props.onSave(model);
  };

  const isCustomDays = selectedResendInterval === ResendInterval.CustomDays;
  var customDaysClassName = "form-input custom-days";
  var resendClassName = "resend";
  if (isCustomDays) {
    resendClassName += " reduced";
    customDaysClassName += " reduced";
  }

  return (
    <>
      <div className="header schedule-header">
        <h1 className="title">{props.title}</h1>
        {props.identifier && <h5>For {props.identifier}</h5>}
        <p className="description">
          Select a date (or period) to send the message to the target audience.
          <br />
          Within a period, repeat resends can be enabled.
        </p>
        <ScheduleWarning schedule={formModel} campaign={props.campaign} />
      </div>
      <form className="schedule-form" onSubmit={handleSubmit(handleSave)}>
        <AlertMissingValidationErrors control={control} />
        <div className="form-row">
          <div className="prefix">Send Schedule:</div>
          <div className="form-input">
            <StateSchedulePicker
              error={schedulePickerError}
              value={formModel as ScheduleModel}
              onChange={(model) => {
                setValue("onlyOnce", model.onlyOnce);
                setValue("duringPeriod", model.duringPeriod);
                setValue("constrainHours", model.constrainHours);
                setValue("indefiniteFrom", model.indefiniteFrom);
                if (model.onlyOnce) setValue("messageResend.resendInterval", ResendInterval.None);
              }}
            />
          </div>
        </div>
        {!formModel.onlyOnce && (
          <div className="form-row">
            <div className="prefix">Days to Send:</div>
            <div className="form-input input-row">
              <Select
                type="form"
                isMulti={true}
                clearText="All days"
                placeholder="All Days"
                className="allowed-days"
                multiValueItemLabel="days"
                options={dayOptions}
                value={selectedAllowedDays}
                onChange={(e) => {
                  if (isArray(e)) setValue("constrainDays.daysAllowed", e as DayOfWeek[]);
                }}
              />
            </div>
          </div>
        )}
        <div className="form-row">
          <div className="prefix">Allow Repeat Sending:</div>
          <div className="form-input input-row">
            <Select
              type="form"
              required={true}
              options={resendOptions}
              className={resendClassName}
              value={selectedResendInterval}
              readOnly={!!formModel.onlyOnce}
              onChange={(e) => {
                if (typeof e === "number") setValue("messageResend.resendInterval", e);
              }}
            />
            {isCustomDays && (
              <label className={customDaysClassName}>
                <input
                  type="number"
                  placeholder={"#"}
                  value={selectedCustomDays}
                  onChange={(e) => {
                    const amount = safeParse(e.target.value);
                    setValue("messageResend.customNumberOfDays", amount);
                  }}
                />
              </label>
            )}
          </div>
        </div>
        <div className="buttons">
          <button type="button" onClick={props.onCancel} className="primary cancel">
            Cancel
          </button>
          <button type="submit" className="primary save">
            Save
          </button>
        </div>
      </form>
    </>
  );
}

export function ScheduleWarning(props: {
  schedule: ScheduleModel | null | undefined;
  campaign: CampaignDetails | undefined;
}) {
  var warning = "";
  const { campaign, schedule } = props;
  if (campaign && schedule) {
    const campaignFrom = stringToDate(campaign.validFrom);
    const campaignTo = stringToDate(campaign.validTo);
    const campaignFromStr = !!campaignFrom ? format(campaignFrom, "MMM dd, yyyy") : "";
    const campaignToStr = !!campaignTo ? format(campaignTo, "MMM dd, yyyy") : "";
    if (schedule.onlyOnce) {
      const date = stringToDate(schedule.onlyOnce.dateTime);
      if (!!campaignFrom && date && date < campaignFrom)
        warning = `Specific date is set to before campaign starts (${campaignFromStr}) and will not be sent!`;
      if (!!campaignTo && date && date > campaignTo)
        warning = `Specific date is set to after campaign end (${campaignToStr}) and will not be sent!`;
    } else if (schedule.indefiniteFrom) {
      const date = stringToDate(schedule.indefiniteFrom.dateTime);
      if (!!campaignFrom && date && date < campaignFrom)
        warning = `Schedule will only start after campaign start date of ${campaignFromStr}`;
      else if (!!campaignTo && date && date > campaignTo)
        warning = `No offer will be sent as campaign set to expire on ${campaignToStr}!`;
    } else if (schedule.duringPeriod) {
      const fromDate = stringToDate(schedule.duringPeriod.from);
      const toDate = stringToDate(schedule.duringPeriod.to);
      if (!!campaignFrom && toDate && toDate < campaignFrom)
        warning = `No offer will be sent because selected campaign starts on ${campaignFromStr}`;
      else if (!!campaignFrom && fromDate && fromDate < campaignFrom)
        warning = `Schedule will only start after campaign start date of ${campaignFromStr}`;
      else if (!!campaignTo && fromDate && fromDate > campaignTo)
        warning = `No offer will be sent as campaign set to expire on ${campaignToStr}!`;
      else if (!!campaignTo && toDate && toDate > campaignTo)
        warning = `Note: selected campaign set to expire on ${campaignToStr} so schedule will end at this date.`;
    }
    if (warning) warning = "⚠️" + warning;
  }
  const hasWarning = !!warning;

  return hasWarning ? <p className="warning">{warning}</p> : <></>;
}
