import { FieldError } from "react-hook-form";
import { safeParse } from "../../../../../services/number";
import { Select } from "../../../../forms/Select";

type NumberOrPercentValue = { value: number; isPercent: boolean };

export function NumberOrPercentInput(props: {
  error?: FieldError;
  value: NumberOrPercentValue;
  onChange: (value: NumberOrPercentValue) => void;
}) {
  const text = props.value.isPercent ? "Top" : "Customers with at least";
  return (
    <div className="input-row num-or-percent">
      <span className="prefix">{text}</span>
      <label className="form-input">
        <input
          placeholder={"Enter value"}
          type="text"
          value={safeParse(props.value.value)}
          onChange={(e) => {
            props.onChange({
              value: safeParse(e.target.value) || 0,
              isPercent: props.value.isPercent,
            });
          }}
        />
        {props.error ? <div className="error">{props.error.message}</div> : null}
      </label>
      <Select
        type="form"
        required={true}
        options={[
          { label: "items purchased", value: "false" },
          { label: "% customers", value: "true" },
        ]}
        value={props.value.isPercent.toString()}
        onChange={(value) => {
          props.onChange({
            value: safeParse(props.value.value) || 0,
            isPercent: value === "true",
          });
        }}
      />
    </div>
  );
}
