import { useEffect } from "react";
import { HealthChecksApi, HealthChecksView, TotalAndRateView } from "../api";
import StatsCardSimple from "../app/cards/StatsCardSimple";
import {
  Accuracy,
  BluetoothEnabled,
  LocationGreen,
  LocationLightGreen,
  NewAppCustomers,
  PermissionsGranted,
  PushNotificationEnabled,
  UserLocation,
} from "../app/icons/Icons";
import { AnalyticsPageHeader } from "../app/widgets/PageHeaders";
import { isHttpOk } from "../services/api";
import { useStateEx } from "../services/hooks";
import { LocalizedText, useLocalizedText } from "../services/localization";
import { getApiConfig } from "../state/configuration";
import { useTimeLocationFilters } from "../state/globalFilters";

import "./HealthCheck.scss";

enum Widgets {
  totalAppUsers = "totalAppUsers",
  allPermissionsEnabled = "allPermissionsEnabled",
  pushNotificationEnabled = "pushNotificationEnabled",
  bluetoothEnabled = "bluetoothEnabled",
  locationAlwaysAllowed = "locationAlwaysAllowed",
  locationAllowedWhileUsing = "locationAllowedWhileUsing",
  locationFullPrecision = "locationFullPrecision",
  locationEnabled = "locationEnabled",
}

function createWidget(texts: LocalizedText, model: HealthChecksView, widget: Widgets, onClick?: () => void) {
  const renderRateSubItem = (rate: number) => ({ label: "Rate", percentage: Math.round(rate * 100) });
  switch (widget) {
    case Widgets.totalAppUsers:
      return (
        <StatsCardSimple
          key={widget}
          value={model.totalUsers}
          tooltipText={texts.healthCheckPage.items.totalAppUsers.tooltip}
          label={texts.healthCheckPage.items.totalAppUsers.title}
          color="purple"
          figure={<NewAppCustomers />}
        />
      );
    case Widgets.allPermissionsEnabled:
      var rateItem = renderRateSubItem(model.allPermissionsEnabled!.rate);
      return (
        <StatsCardSimple
          key={widget}
          value={model.allPermissionsEnabled!.total}
          tooltipText={texts.healthCheckPage.items.allPermissionsEnabled.tooltip}
          label={texts.healthCheckPage.items.allPermissionsEnabled.title}
          color="pink"
          figure={<PermissionsGranted />}
          items={[rateItem]}
        />
      );
    case Widgets.pushNotificationEnabled:
      rateItem = renderRateSubItem(model.pushNotificationEnabled!.rate);
      return (
        <StatsCardSimple
          key={widget}
          value={model.pushNotificationEnabled!.total}
          tooltipText={texts.healthCheckPage.items.pushNotificationEnabled.tooltip}
          label={texts.healthCheckPage.items.pushNotificationEnabled.title}
          color="lightblue"
          figure={<PushNotificationEnabled />}
          items={[rateItem]}
        />
      );
    case Widgets.bluetoothEnabled:
      rateItem = renderRateSubItem(model.bluetoothEnabled!.rate);
      return (
        <StatsCardSimple
          key={widget}
          value={model.bluetoothEnabled!.total}
          tooltipText={texts.healthCheckPage.items.bluetoothEnabled.tooltip}
          label={texts.healthCheckPage.items.bluetoothEnabled.title}
          color="blue"
          figure={<BluetoothEnabled />}
          items={[rateItem]}
        />
      );
    case Widgets.locationAlwaysAllowed:
      rateItem = renderRateSubItem(model.locationAlwaysAllowed!.rate);
      return (
        <StatsCardSimple
          key={widget}
          value={model.locationAlwaysAllowed!.total}
          tooltipText={texts.healthCheckPage.items.locationAlwaysAllowed.tooltip}
          label={texts.healthCheckPage.items.locationAlwaysAllowed.title}
          color="green"
          figure={<LocationGreen />}
          items={[rateItem]}
        />
      );
    case Widgets.locationAllowedWhileUsing:
      rateItem = renderRateSubItem(model.locationAllowedWhileUsing!.rate);
      return (
        <StatsCardSimple
          key={widget}
          value={model.locationAllowedWhileUsing!.total}
          tooltipText={texts.healthCheckPage.items.locationAllowedWhileUsing.tooltip}
          label={texts.healthCheckPage.items.locationAllowedWhileUsing.title}
          color="lightgreen"
          figure={<LocationLightGreen />}
          items={[rateItem]}
        />
      );
    case Widgets.locationFullPrecision:
      rateItem = renderRateSubItem(model.locationFullPrecision!.rate);
      return (
        <StatsCardSimple
          key={widget}
          value={model.locationFullPrecision!.total}
          tooltipText={texts.healthCheckPage.items.locationFullPrecision.tooltip}
          label={texts.healthCheckPage.items.locationFullPrecision.title}
          color="golden"
          figure={<Accuracy />}
          items={[rateItem]}
        />
      );
    case Widgets.locationEnabled:
      rateItem = renderRateSubItem(model.locationEnabled!.rate);
      return (
        <StatsCardSimple
          key={widget}
          value={model.locationEnabled!.total}
          tooltipText={texts.healthCheckPage.items.locationEnabled.tooltip}
          label={texts.healthCheckPage.items.locationEnabled.title}
          color="green"
          figure={<UserLocation />}
          items={[rateItem]}
        />
      );
  }
}

const empty = (): TotalAndRateView => ({ total: 0, rate: 0 });

export function HealthCheck() {
  const texts = useLocalizedText();
  const filters = useTimeLocationFilters("HealthCheck");

  const topWidgets: Widgets[] = [
    Widgets.totalAppUsers,
    Widgets.allPermissionsEnabled,
    Widgets.pushNotificationEnabled,
    Widgets.bluetoothEnabled,
  ];
  const bottomWidgets: Widgets[] = [
    Widgets.locationAlwaysAllowed,
    Widgets.locationAllowedWhileUsing,
    Widgets.locationFullPrecision,
    Widgets.locationEnabled,
  ];

  const { state, mergeState } = useStateEx<HealthChecksView>({
    totalUsers: 0,
    allPermissionsEnabled: empty(),
    pushNotificationEnabled: empty(),
    bluetoothEnabled: empty(),
    locationAlwaysAllowed: empty(),
    locationAllowedWhileUsing: empty(),
    locationFullPrecision: empty(),
    locationEnabled: empty(),
  });

  useEffect(() => {
    const api = new HealthChecksApi(getApiConfig());
    api.getHealthChecks({ ...filters }).then((result) => {
      if (isHttpOk(result)) mergeState(result.data);
    });
  }, [filters, mergeState]);

  return (
    <div className="health-check page-content">
      <AnalyticsPageHeader title={texts.healthCheckPage.title} hideLocationFilters={true} />
      <section className="top-row featured-cards">
        {topWidgets.map((item, index) => (
          <div className="inner-card" key={index}>
            {createWidget(texts, state, item)}
          </div>
        ))}
      </section>
      <section className="bottom-row featured-cards">
        {bottomWidgets.map((item, index) => (
          <div className="inner-card" key={index}>
            {createWidget(texts, state, item)}
          </div>
        ))}
      </section>
    </div>
  );
}
