import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { toast as alert } from "react-toastify";
import {
  CustomersApi,
  DeviceView,
  MessageDetails,
  MessagesApi,
  PhoneOS,
  PlatformType,
  SentMessageView,
} from "../../api";
import { Modal, useModal } from "../../app/modals/Modal";
import { AlertContent } from "../../app/widgets/Alerts";
import { logErrorSilently } from "../../services/alerts";
import { isHttpOk } from "../../services/api";
import { getUtcDate, utcToLocal } from "../../services/date";
import { formatDateTime, formatDurationBetweenDates } from "../../services/format";
import { useStateEx } from "../../services/hooks";
import { isProvided } from "../../services/objects";
import { getApiConfig } from "../../state/configuration";
import { DiagnosticsTable } from "./DiagnosticsTable";

import "./LiveMessageTracking.scss";

type State = {
  message?: MessageDetails;
  userSentMessages: SentMessageView[];
  userDevices: DeviceView[];
};

type Props = {
  customerId?: string;
  messageId?: number;
  displayLimit: number;
  displayAbsoluteTime: boolean;
};

export function LiveMessageTracking(props: Props) {
  const [sending, setSending] = useState(false);
  const { customerId, messageId } = props;
  const { openModal, closeModal } = useModal();
  const [selectedDeviceId, setDeviceId] = useState<string>();
  const { state, mergeState } = useStateEx<State>({
    message: undefined,
    userSentMessages: [],
    userDevices: [],
  });

  const loadMessages = useCallback(async () => {
    if (customerId && messageId) {
      try {
        const config = getApiConfig();
        const customerApi = new CustomersApi(config);
        const sentMessages = await customerApi.getSentMessages({
          customerId: customerId,
          offerId: messageId,
        });
        const messageApi = new MessagesApi(config);
        const message = await messageApi.getOne({
          messageId,
        });

        if (isHttpOk(sentMessages) && isHttpOk(message)) {
          mergeState({
            message: message.data,
            userSentMessages: sentMessages.data.items || [],
          });
        }
      } catch (err) {
        logErrorSilently(err);
      }
    }
  }, [customerId, messageId, mergeState]);

  const loadDevices = useCallback(async () => {
    if (!customerId) return;
    try {
      const config = getApiConfig();
      const api = new CustomersApi(config);
      const deviceResponse = await api.getDevices({ customerId: customerId });
      mergeState({
        userDevices: isHttpOk(deviceResponse) ? deviceResponse.data : [],
      });
    } catch (err) {
      logErrorSilently(err);
    }
  }, [customerId, mergeState]);

  useEffect(() => {
    const handle = window.setInterval(async () => {
      if (customerId) loadDevices();
      if (customerId && messageId) loadMessages();
    }, 1000);
    return () => window.clearInterval(handle);
  }, [mergeState, loadMessages, loadDevices, messageId, customerId]);

  const sendMessage = useCallback(async () => {
    if (sending || !customerId || !messageId) return;
    setSending(true);
    try {
      const config = getApiConfig();
      const api = new MessagesApi(config);
      await api.forceSendMessage({
        messageId: messageId,
        forceSendMessageRequest: {
          customerId: customerId,
          delaySeconds: 0,
        },
      });
      alert.success(<AlertContent message="Message was queued successfully." />);
    } catch (err) {
      alert.error(<AlertContent message="Failed to send the message." />);
      logErrorSilently(err);
    } finally {
      setSending(false);
    }
    loadMessages();
  }, [customerId, messageId, sending, loadMessages]);

  const deactivateDevice = async (id: string, platform: PlatformType) => {
    const config = getApiConfig();
    const api = new CustomersApi(config);
    await api.deactivateDevice({
      customerId: customerId!,
      deviceId: {
        platformType: platform,
        platformId: id,
      },
    });
    alert.success(<AlertContent message="Device was deactivated!" />);
    await loadDevices();
  };

  const utcNow = getUtcDate();

  const renderDevice = (deviceStats: DeviceView) => {
    const phoneOSType =
      deviceStats.phoneOSType === PhoneOS.Android
        ? "android"
        : deviceStats.phoneOSType === PhoneOS.iOS
        ? "ios"
        : "other";
    const platformType =
      deviceStats.platformType === PlatformType.Apns
        ? "apns"
        : deviceStats.platformType === PlatformType.Fcm
        ? "fcm"
        : "unknown";
    const isActive = !isProvided(deviceStats.utcDeactivated);

    return (
      <ul key={deviceStats.id} className={clsx("device-panel", phoneOSType, isActive ? "active" : "inactive")}>
        <li className="header">
          <div>{phoneOSType.toUpperCase() + (!isActive ? " (inactive)" : "")} </div>
          <button
            className="primary diagnostics"
            disabled={!deviceStats.platformDeviceId}
            onClick={() => {
              setDeviceId(deviceStats.platformDeviceId!);
              openModal();
            }}
          >
            Show Diagnostics
          </button>
          {isActive && (
            <button
              className="primary delete"
              disabled={!deviceStats.platformDeviceId}
              onClick={() => deactivateDevice(deviceStats.platformDeviceId!, deviceStats.platformType)}
            >
              Force Deactivate
            </button>
          )}
        </li>
        <li>
          <strong>Activated:</strong>
          {props.displayAbsoluteTime
            ? formatDateTime(utcToLocal(deviceStats.utcActivated))
            : formatDurationBetweenDates(utcNow, deviceStats.utcActivated)}
        </li>
        <li>
          <strong>Deactivated:</strong>
          {(props.displayAbsoluteTime
            ? formatDateTime(utcToLocal(deviceStats.utcDeactivated))
            : formatDurationBetweenDates(deviceStats.utcActivated, deviceStats.utcDeactivated)) || "no"}
        </li>
        <li>
          <strong>Platform Type:</strong>
          <span>{platformType.toUpperCase()}</span>
        </li>
        <li>
          <strong>Platform Device Id:</strong>
          <span>{deviceStats.platformDeviceId}</span>
        </li>
        <li>
          <strong>Phone OS Version:</strong>
          <span>{deviceStats.phoneOSVersion}</span>
        </li>
        <li>
          <strong>Phone Manufacturer:</strong>
          <span>{deviceStats.phoneManufacturer}</span>
        </li>
        <li>
          <strong>Phone Model:</strong>
          <span>{deviceStats.phoneModel}</span>
        </li>
      </ul>
    );
  };

  const renderTimestamp = (utcTime?: string | Date | null, baseDate?: string | Date | null, baseDateName?: string) => {
    if (isProvided(utcTime)) {
      const absValue = formatDateTime(utcToLocal(utcTime));
      const relValue = formatDurationBetweenDates(baseDate, utcTime, baseDateName);
      return <span title={absValue}>{props.displayAbsoluteTime ? absValue : relValue}</span>;
    }
    return "never";
  };

  const renderMessage = (stats: SentMessageView) => {
    const hasFailed = stats.failedTimes > 0 && !isProvided(stats.utcSent);

    return (
      <ul className="message-panel" key={stats.sentOfferId}>
        <li>
          <strong>Id:</strong>
          <span>{stats.sentOfferId}</span>
        </li>
        <li>
          <strong className={!!stats.utcQueued ? "green" : "gray"}>Queued:</strong>
          {renderTimestamp(stats.utcQueued, utcNow)}
        </li>
        <li>
          <strong className={!!stats.utcSent ? "green" : "gray"}>Sent:</strong>
          {renderTimestamp(stats.utcSent, stats.utcQueued, "queued")}
        </li>
        <li>
          <strong className={!!stats.utcReceived ? "green" : "gray"}>Received:</strong>
          {renderTimestamp(stats.utcReceived, stats.utcSent, "sent")}
        </li>
        <li>
          <strong className={!!stats.utcRead ? "green" : "gray"}>Read:</strong>
          {renderTimestamp(stats.utcRead, stats.utcSent, "sent")}
        </li>
        <li>
          <strong className={!!stats.utcRedeemed ? "green" : "gray"}>Redeemed:</strong>
          {renderTimestamp(stats.utcRedeemed, stats.utcSent, "sent")}
        </li>
        <li>
          <strong className={!!stats.utcClicked ? "green" : "gray"}>Clicked:</strong>
          {renderTimestamp(stats.utcClicked, stats.utcSent, "sent")}
        </li>
        <li>
          <strong className={hasFailed ? "red" : "gray"}>Retries:</strong>
          <span> {stats.failedTimes + (stats.failedTimes === 1 ? " time" : " times")}</span>
        </li>
        <li>
          <strong className={hasFailed ? "red" : "gray"}>Last failure:</strong>
          {renderTimestamp(stats.failedAt, stats.utcQueued, "queued")}
        </li>
        {isProvided(stats.failureReason) ? (
          <li className={clsx("failed-panel", hasFailed ? "red" : "gray")}>
            <strong>Reason:</strong>
            <pre>{stats.failureReason}</pre>
          </li>
        ) : null}
      </ul>
    );
  };

  const orderedDevices = [
    ...state.userDevices.filter((d) => !isProvided(d.utcDeactivated)),
    ...state.userDevices.filter((d) => isProvided(d.utcDeactivated)),
  ];

  const devices = (
    <Collapsible trigger="DEVICE INFO" open>
      <ul>
        {!customerId ? (
          <h2>Customer not selected</h2>
        ) : state.userDevices.length === 0 ? (
          <h2>Device info not available</h2>
        ) : (
          orderedDevices.map(renderDevice)
        )}
      </ul>
    </Collapsible>
  );

  const payload = state.message?.deepLinkPayload;

  const messagePayload = (
    <Collapsible trigger="MESSAGE PAYLOAD" open>
      <ul>
        {!messageId ? (
          <h2>Message not selected</h2>
        ) : !customerId ? (
          <h2>Customer not selected</h2>
        ) : (
          <div>
            <ul>
              <li>
                <strong>Message Payload:</strong>
                <pre>{payload ? JSON.stringify(payload, null, 4) : "No payload"}</pre>
              </li>
            </ul>
          </div>
        )}
      </ul>
    </Collapsible>
  );

  const sentMessages = (
    <Collapsible trigger="MESSAGE STATISTICS" open>
      <ul>
        {!messageId ? (
          <h2>Message not selected</h2>
        ) : !customerId ? (
          <h2>Customer not selected</h2>
        ) : (
          <div>
            <button type="button" onClick={sendMessage} className="primary save">
              Send selected Message
            </button>
            {state.userSentMessages.map(renderMessage)}
          </div>
        )}
      </ul>
    </Collapsible>
  );

  return (
    <div className="customer-tracking">
      {customerId && (
        <Modal onRequestClose={closeModal}>
          <section>
            <DiagnosticsTable userId={customerId} platformDeviceId={selectedDeviceId} />
          </section>
        </Modal>
      )}
      <div className="stats">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6">{devices}</div>
            <div className="col-12 col-md-3">{messagePayload}</div>
            <div className="col-12 col-md-3">{sentMessages}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
