import { editorClassName, EditorProps } from "./common";
import { LocationInput } from "./inputs/LocationInput";
import { TimeSpanRangeInput } from "./inputs/TimeSpanRangeInput";

export function VisitsWithoutPurchaseEditor(props: EditorProps) {
  const selectedLocations = props.value.visitWithoutPurchase?.locations || [];
  const selectedTimerange = props.value.visitWithoutPurchase?.time || undefined;

  return (
    <div className={editorClassName(props, "visit-without-purchase")}>
      <h2>{props.description}</h2>
      <TimeSpanRangeInput
        prefix="Visited"
        rangeSemantics={false}
        value={selectedTimerange}
        onChange={(value) =>
          props.onChange({
            visitWithoutPurchase: {
              locations: selectedLocations,
              time: value,
            },
          })
        }
        error={props.error?.time}
      />
      <LocationInput
        value={selectedLocations}
        onChange={(value) => {
          props.onChange({
            visitWithoutPurchase: {
              locations: value,
              time: selectedTimerange,
            },
          });
        }}
        error={props.error?.locations}
      />
    </div>
  );
}
