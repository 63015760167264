import { differenceInSeconds, format } from "date-fns";
import { useCallback, useEffect } from "react";
import Collapsible from "react-collapsible";
import { BeaconVisitView, ContactView, CustomersApi, StoreVisitView } from "../../api";
import { logErrorSilently } from "../../services/alerts";
import { isHttpOk } from "../../services/api";
import { getUtcDate, stringToDate, utcToLocal } from "../../services/date";
import { approximateDuration, formatDateTime, formatDurationBetweenDates } from "../../services/format";
import { useStateEx } from "../../services/hooks";
import { getApiConfig } from "../../state/configuration";

import "./LiveContactTracking.scss";

function VisitTimings(props: { view: StoreVisitView & BeaconVisitView; absoluteTime: boolean }) {
  const { utcStart, utcEnd } = props.view;
  const utcNow = getUtcDate();
  const relStart = formatDurationBetweenDates(utcNow, utcStart);
  const relEnd = formatDurationBetweenDates(utcNow, utcEnd);
  const absStart = formatDateTime(utcToLocal(utcStart));
  const absEnd = formatDateTime(utcToLocal(utcEnd));
  const actualDurationSeconds = differenceInSeconds(stringToDate(utcEnd, true) || utcNow, stringToDate(utcStart, true));
  const actualDurationText = approximateDuration(actualDurationSeconds).join(" ");

  return (
    <>
      <div>
        <strong className={"ping-type " + (utcEnd ? "out" : "in")}>{utcEnd ? "VISIT" : "ENTRY"}</strong>
        <span title={absStart}>Start: {props.absoluteTime ? absStart : relStart}</span>
      </div>
      <i title={absEnd}>End: {props.absoluteTime ? absEnd : relEnd}</i>
      <i>Duration: {actualDurationText}</i>
    </>
  );
}

type State = {
  contactStats: ContactView[];
  beaconVisits: BeaconVisitView[];
  storeVisits: StoreVisitView[];
};

type Props = {
  customerId?: string;
  displayLimit: number;
  displayAbsoluteTime: boolean;
};

export function LiveContactTracking(props: Props) {
  const { customerId } = props;
  const { state, mergeState } = useStateEx<State>({
    contactStats: [],
    storeVisits: [],
    beaconVisits: [],
  });

  const loadContactsAndVisits = useCallback(
    async (customerId: string) => {
      try {
        const config = getApiConfig();
        const api = new CustomersApi(config);
        const requestParams = {
          customerId: customerId,
          limit: props.displayLimit,
          skip: 0,
        };

        const [contacts, storeVisits, beaconVisits] = await Promise.all([
          await api.getContacts(requestParams),
          await api.getStoreVisits(requestParams),
          await api.getBeaconVisits(requestParams),
        ]);

        if (isHttpOk(contacts) && isHttpOk(storeVisits) && isHttpOk(beaconVisits)) {
          mergeState({
            contactStats: contacts.data.items ?? [],
            storeVisits: storeVisits.data.items ?? [],
            beaconVisits: beaconVisits.data.items ?? [],
          });
        }
      } catch (err) {
        logErrorSilently(err);
      }
    },
    [mergeState, props.displayLimit]
  );

  useEffect(() => {
    const handle = window.setInterval(async () => {
      if (customerId) loadContactsAndVisits(customerId);
    }, 1000);
    return () => {
      window.clearInterval(handle);
    };
  }, [mergeState, loadContactsAndVisits, customerId]);

  const utcNow = getUtcDate();

  const contacts = (
    <Collapsible trigger="BEACON CONTACTS" open>
      <ul>
        {!customerId ? (
          <h2>Customer not selected</h2>
        ) : (
          state.contactStats.map((c, i) => {
            const beaconText = `${c.beaconUuid} : ${c.beaconMajor} : ${c.beaconMinor}`;
            const event = c.event || "RANGE";
            return (
              <li key={i}>
                <div>
                  <strong className={"ping-type " + event.toLowerCase()}>{event}</strong>
                  <span title={formatDateTime(utcToLocal(c.utcTime))}>
                    {props.displayAbsoluteTime
                      ? formatDateTime(utcToLocal(c.utcTime))
                      : formatDurationBetweenDates(utcNow, c.utcTime)}
                  </span>
                </div>
                <i>Name: {c.beaconName}</i>
                <i>UUID: {beaconText}</i>
              </li>
            );
          })
        )}
      </ul>
    </Collapsible>
  );

  const beaconVisits = (
    <Collapsible trigger="BEACON VISITS" open>
      <ul>
        {!customerId ? (
          <h2>Customer not selected</h2>
        ) : (
          state.beaconVisits.map((v, i) => {
            const beaconText = `${v.beaconUuid} : ${v.beaconMajor} : ${v.beaconMinor}`;
            return (
              <li key={i}>
                <VisitTimings absoluteTime={props.displayAbsoluteTime} view={v} />
                <i>Name: {v.beaconName}</i>
                <i>UUID: {beaconText}</i>
              </li>
            );
          })
        )}
      </ul>
    </Collapsible>
  );

  const storeVisits = (
    <Collapsible trigger="STORE VISITS" open>
      <ul>
        {!customerId ? (
          <h2>Customer not selected</h2>
        ) : (
          state.storeVisits.map((v, i) => {
            return (
              <li key={i}>
                <VisitTimings absoluteTime={props.displayAbsoluteTime} view={v} />
                <i>Name: {`${v.name} (${v.status})`}</i>
                <i>Location: {`${v.city}, ${v.country}`}</i>
              </li>
            );
          })
        )}
      </ul>
    </Collapsible>
  );

  const clockText = `Current time: ${format(utcToLocal(utcNow), "HH:mm:ss")} (${format(utcNow, "HH:mm:ss")} UTC)`;

  return (
    <div className="customer-tracking">
      <h2>{clockText}</h2>
      <div className="stats">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-4">{beaconVisits}</div>
            <div className="col-12 col-lg-4">{storeVisits}</div>
            <div className="col-12 col-lg-4">{contacts}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
