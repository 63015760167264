import { useCallback, useState } from "react";
import { StoresApi } from "../../api";
import { logErrorSilently } from "../../services/alerts";
import { isHttpOk } from "../../services/api";
import { useMoneyFormatter } from "../../services/format";
import { useStateEx } from "../../services/hooks";
import { getApiConfig } from "../../state/configuration";
import { useDeploymentConfig } from "../../state/deployment";
import { useTimeLocationFilters } from "../../state/globalFilters";
import Card from "../cards/Card";
import { Modal, useModal } from "../modals/Modal";
import { PaginatedTable } from "../widgets/PaginatedTable";
import { Metric, StoresTable } from "./StoresTable";

import "./TopStores.scss";

function getColumnHeader(selectedValue: Metric) {
  switch (selectedValue) {
    case Metric.Revenue:
      return "REVENUE";
    case Metric.StoreVisits:
      return `CUSTOMERS VISITS`;
    default:
      return "BEACON VISITS";
  }
}

function TopStores(props: { showFooter?: boolean; includeAllStores?: boolean }) {
  const { config } = useDeploymentConfig();
  const { formatMoney } = useMoneyFormatter();
  const [isEmpty, setIsEmpty] = useState(true);

  const sortOptions = [
    {
      label: "Customer Visits",
      value: Metric.StoreVisits,
    },
    {
      label: "Beacon Visits",
      value: Metric.BeaconContacts,
    },
  ];

  if (!config.disablePurchaseHistories) {
    sortOptions.splice(0, 0, {
      label: "Revenue",
      value: Metric.Revenue,
    });
  }

  const filters = useTimeLocationFilters();
  const { state, mergeState } = useStateEx({ selectedValue: sortOptions[0].value });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      mergeState({ selectedValue: value as Metric });
    }
  };

  const dataSource = useCallback(async () => {
    function getData() {
      const api = new StoresApi(getApiConfig());
      const payload = { size: 5, includeAllStores: props.includeAllStores, ...filters };
      switch (state.selectedValue) {
        case Metric.Revenue:
          return api.getByRevenue(payload);
        case Metric.StoreVisits:
          return api.getByVisits(payload);
        default:
          return api.getByBeaconContacts(payload);
      }
    }

    const result = await getData();

    if (isHttpOk(result)) {
      setIsEmpty(result.data.count === 0);
      return result.data;
    } else {
      setIsEmpty(true);
      logErrorSilently(result);
      return {
        count: 0,
        items: [],
      };
    }
  }, [filters, state.selectedValue, props.includeAllStores, setIsEmpty]);

  const storeWord = config.translations.store;

  const tableHeaders = [{ title: storeWord.singular.toUpperCase() }, { title: getColumnHeader(state.selectedValue) }];

  const modalId = "top-stores";
  const { openModal, closeModal } = useModal(modalId);

  return (
    <Card>
      <PaginatedTable
        mainHeader={
          <header>
            <h1>Top {storeWord.plural}</h1>
            <select value={state.selectedValue} onChange={handleChange}>
              {sortOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </header>
        }
        className="top-stores-table"
        minLoadingTimeMs={200}
        initialPageSize={5}
        hidePagination={true}
        tableDataSource={dataSource}
        tableHeaders={tableHeaders}
        tableRowFactory={(item, index) => {
          return [item.name, state.selectedValue === 1 ? formatMoney(item.value) : item.value];
        }}
      >
        {props.showFooter && !isEmpty && (
          <div className="show-all" onClick={() => openModal()}>
            Show all
          </div>
        )}
      </PaginatedTable>
      <Modal id={modalId} onRequestClose={closeModal} className="wide-modal">
        <section>
          <StoresTable
            selectedMetric={state.selectedValue}
            includeAllStores={props.includeAllStores}
            customOptions={sortOptions}
          />
        </section>
      </Modal>
    </Card>
  );
}

export default TopStores;
