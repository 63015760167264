import { useTimeLocationFilters } from "../../state/globalFilters";
import { useCallback } from "react";
import { StoresApi } from "../../api";
import { getApiConfig } from "../../state/configuration";
import { isHttpOk } from "../../services/api";
import { logErrorSilently } from "../../services/alerts";
import { PaginatedTable } from "../widgets/PaginatedTable";
import { useDeploymentConfig } from "../../state/deployment";
import Card from "../cards/Card";
import * as flags from "country-flag-icons/modules/react/1x1";

import "./TopCountry.scss";

function getCountryFlag(flag: keyof typeof flags) {
  const Flag = flags[flag];
  return <Flag />;
}

export default function TopCountry() {
  const deployment = useDeploymentConfig();
  const filters = useTimeLocationFilters();
  const dataSource = useCallback(async () => {
    try {
      const api = new StoresApi(getApiConfig());
      const result = await api.getCountriesByVisits({ size: 5, ...filters });
      if (isHttpOk(result)) {
        return {
          count: result.data.length,
          items: result.data,
        };
      } else {
        logErrorSilently(result);
      }
    } catch (error) {
      logErrorSilently(error);
    }
    return {
      count: 0,
      items: [],
    };
  }, [filters]);

  const headers = [
    { title: "COUNTRY" },
    { title: deployment.config.disablePurchaseHistories ? "VISITORS" : "CUSTOMERS" }
  ];
  return (
    <Card className="top-countries">
      <PaginatedTable
        mainHeader="Top Country"
        tableClassName="with-image"
        minLoadingTimeMs={200}
        initialPageSize={5}
        hidePagination={true}
        tableDataSource={dataSource}
        tableHeaders={headers}
        tableRowFactory={(item, index) => {
          return [
            <div>
              {getCountryFlag(item.iso2 as keyof typeof flags)}
              {item.name}
            </div>,
            item.count,
          ];
        }}
      />
    </Card>
  );
}
