import { isEqual, noop } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

// eslint-disable-next-line no-console
const log = false ? console.log : noop;

export function useWasUnmountedRef() {
  const wasUnmountedRef = useRef(false);
  useEffect(() => {
    return () => {
      wasUnmountedRef.current = true;
    };
  }, []);
  return wasUnmountedRef;
}

export function useEventHandler<T>(handler: T) {
  const handlerRef = useRef(handler);
  handlerRef.current = handler;
  return handlerRef;
}

export function useCachedInstance<T>(value: T): T {
  const instanceRef = useRef<T>();
  if (!isEqual(value, instanceRef.current)) {
    instanceRef.current = value;
  }
  return instanceRef.current!;
}

export function useStateEx<TState>(initial: TState | (() => TState), component?: string) {
  const [state, innerSet] = useState(initial);
  // const isUnmounted = useIsUnmountedRef();

  const setState = useCallback(
    (value: TState | ((state: TState) => TState)) => {
      if (component) log("Setting", component, "to", value);
      innerSet(value);
    },
    [component]
  );

  const mergeState = useCallback(
    (partial: Partial<TState> | ((state: TState) => Partial<TState> | undefined)) => {
      // if (isUnmounted.current) {
      // eslint-disable-next-line no-console
      //   console.warn("Skipping state update due to unmounted component!");
      //   return;
      // }

      if (typeof partial === "function") {
        if (component) log("Setting", component, "state via factory");
        innerSet((prev) => {
          const next = partial(prev);
          if (next !== undefined && next !== prev) {
            const value = {
              ...prev,
              ...next,
            };
            if (component) log("    :resolving", component, "state to", value);
            return value;
          } else {
            if (component) log("    :resolving", component, "state update");
            return prev;
          }
        });
      } else {
        if (component) log("Merging", component, "state with", partial);
        innerSet((prev) => ({
          ...prev,
          ...partial,
        }));
      }
    },
    [component]
  );

  return { state, mergeState, setState };
}
