import { SortDirection } from "../api";
import { Header } from "../app/widgets/PaginatedTable";

export function generateSortableHeader(texts: { title: string; tooltip: string }): Header {
  return {
    sortDirection: SortDirection.None,
    sortable: true,
    ...texts,
  };
}

export function toggleSortForHeader(headers: Header[], indexOfToggle: number) {
  const oldSortDirection = headers[indexOfToggle].sortDirection;
  const cloned = headers.map((h) => ({ ...h, sortDirection: SortDirection.None }));
  cloned[indexOfToggle].sortDirection =
    oldSortDirection === SortDirection.None
      ? SortDirection.Ascending
      : oldSortDirection === SortDirection.Ascending
      ? SortDirection.Descending
      : SortDirection.None;
  return cloned;
}

export function resetSortHeaders(headers: Header[]) {
  const cloned = headers.map((h) => ({ ...h, sortDirection: SortDirection.None }));
  return cloned;
}
