import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { AnalyticsApi, TimeSpentUserView } from "../../../api";
import { logErrorSilently } from "../../../services/alerts";
import { isHttpOk } from "../../../services/api";
import { formatApproximateDuration } from "../../../services/format";
import { getApiConfig } from "../../../state/configuration";
import { useTimeLocationFilters } from "../../../state/globalFilters";
import Card from "../../cards/Card";
import { Avatar } from "../../widgets/Avatar";
import { PaginatedTable } from "../../widgets/PaginatedTable";

import { format } from "date-fns";
import { toast } from "react-toastify";
import { toCsvValue } from "../../../services/string";
import { AlertContent } from "../../widgets/Alerts";
import "./TimeSpentUsersTable.scss";

export function TimeSpentUsersTable() {
  const filters = useTimeLocationFilters();
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const dataSource = useCallback(
    async (offset: number, limit: number) => {
      const api = new AnalyticsApi(getApiConfig());
      const result = await api.getTimeSpentUsers({
        skip: offset,
        limit: limit,
        ...filters,
      });

      if (isHttpOk(result)) {
        return result.data;
      } else {
        logErrorSilently(result);
        return {
          count: 0,
          items: [],
        };
      }
    },
    [filters]
  );

  const tableHeaders = [{ title: "NAME" }, { title: "EMAIL" }, { title: "NO. OF VISIT" }, { title: "TOTAL DURATION" }];

  const renderRow = (item: TimeSpentUserView) => {
    var name = item.firstName ? `${item.firstName} ${item.lastName}` : "Anonymous";
    return [
      <Link to={item.id ? `/customer/${item.id}/details` : "#"}>
        <Avatar name={name} image={item.pictureUrl} />
        <span className="name">{name}</span>
      </Link>,
      item.email,
      item.nmOfVisits,
      formatApproximateDuration(item.totalVisitDurationSeconds),
    ];
  };

  async function downloadCsv() {
    setIsExporting(true);
    try {
      const data = await dataSource(0, 0);
      const csvHeaders = ["Picture Url", "Name", "Email", "Number Of Visits", "Total Duration"].join(",");
      let rows = data.items?.map((item) =>
        [
          toCsvValue(item.pictureUrl || ""),
          toCsvValue(item.firstName ? item.firstName + " " + item.lastName : "Anonymous"),
          toCsvValue(item.email),
          item.nmOfVisits,
          formatApproximateDuration(item.totalVisitDurationSeconds),
        ].join(",")
      );
      rows = [csvHeaders, ...(rows || [])];
      const csv = rows.join("\n");
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `time-spent-users-${format(Date.now(), "yy.MM.dd-HH:mm")}.csv`;
      link.click();
    } catch {
      toast.error(<AlertContent message="Unexpected error while exporting data" />);
    } finally {
      setIsExporting(false);
    }
  }

  const title = "Time Spent by Visitors";

  return (
    <Card className="time-spent-users-table">
      <PaginatedTable
        mainHeader={
          <section className="header-row">
            <header>
              <div>
                <h1>{title}</h1>
              </div>
              <button className="secondary export-data-btn" disabled={isExporting} onClick={downloadCsv}>
                Export Data
              </button>
            </header>
          </section>
        }
        tableClassName="with-image"
        minLoadingTimeMs={200}
        initialPageSize={10}
        tableDataSource={dataSource}
        tableHeaders={tableHeaders}
        tableRowFactory={renderRow}
      ></PaginatedTable>
    </Card>
  );
}
