import clsx from "clsx";
import { cloneDeep, isArray, isFunction, uniq } from "lodash";
import pDebounce from "p-debounce";
import { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import { FieldError, FieldValues, Path, PathValue, UseFormReturn, useController } from "react-hook-form";
import ReactSelect, {
  ActionMeta,
  ContainerProps,
  ControlProps,
  MenuProps,
  OnChangeValue,
  OptionProps,
  Props,
  ValueContainerProps,
  components,
} from "react-select";
import AsyncSelect from "react-select/async";
import { isProvided } from "../../services/objects";
import { DropdownIcon } from "../icons/Icons";
import { ValidationError } from "../widgets/ValidationError";

import "./Select.scss";

export type OptionGroup = {
  label: string;
  options: Option[];
};

export type Option = {
  label: string;
  deactivated?: boolean;
  description?: string;
  value: OptionValueType;
};

export type Options = (Option | OptionGroup)[];
export type ReadonlyOptions = Readonly<Options>;

function OptionWithCheckbox<M extends boolean>(props: OptionProps<Option, M>) {
  const option = props.data;
  const checkClasses = ["checkbox"];
  if (props.isSelected) checkClasses.push("checked");

  return (
    <components.Option className="multiple" {...props}>
      <div className={checkClasses.join(" ")}></div>
      {option.label}
      {option.description && <div className="description">{option.description}</div>}
    </components.Option>
  );
}

function NormalOption<M extends boolean>(props: OptionProps<Option, M>) {
  const option = props.data as Option;
  const labelClassName = option.deactivated ? "deactivated" : "";
  var descriptionClassName = ["description"];
  if (option.deactivated) {
    descriptionClassName.push("deactivated");
  }

  return (
    <components.Option {...props}>
      <div className={labelClassName}>{option.label}</div>
      {option.description && <div className={descriptionClassName.join(" ")}>{option.description}</div>}
    </components.Option>
  );
}

const Empty = () => {
  return <></>;
};

export type OptionValueType = number | string | boolean;
type SelectedOptionType<IsMulti extends boolean = false> = OnChangeValue<Option, IsMulti>;
type SelectedValueType<IsMulti extends boolean = false> = IsMulti extends true
  ? OptionValueType[]
  : OptionValueType | null;

interface SelectProps<IsMulti extends boolean = false> {
  isMenuOpen?: boolean;
  isMulti?: IsMulti;
  placeholder?: string;
  className?: string;
  required?: boolean;
  clearText?: string;
  errorLabel?: string;
  multiValueItemLabel?: string | ((items: number) => string);
  icon?: JSX.Element;
  type: "form" | "filter";
  readOnly?: boolean;
  options: ReadonlyOptions | ((input: string) => Promise<ReadonlyOptions>);
  value: SelectedOptionType<IsMulti>;
  onChange?: Props<Option, IsMulti>["onChange"];
  onBlur?: () => void;
  error?: FieldError;
  errorPath?: string;
  hint?: string;
}

function SelfPositioningMenu<IsMulti extends boolean>({
  children,
  innerRef,
  ...innerProps
}: MenuProps<Option, IsMulti>) {
  const menuRef = useRef<HTMLDivElement | null>();

  useLayoutEffect(() => {
    if (!menuRef.current) return;
    // Menu is too close to right edge
    const position = menuRef.current.getBoundingClientRect();
    if (position.right + 100 >= window.innerWidth || position.right + 100 >= document.documentElement.clientWidth) {
      menuRef.current.classList.add("rightmost");
    }
  });

  const setRefs = useCallback(
    (el: HTMLDivElement) => {
      menuRef.current = el;
      innerRef && innerRef(el);
    },
    [innerRef]
  );

  return (
    <components.Menu {...innerProps} innerRef={setRefs}>
      {children}
    </components.Menu>
  );
}

function AbstractSelect<IsMulti extends boolean = false>(props: SelectProps<IsMulti>) {
  const getCssClasses = () => {
    const classes = props.type === "form" ? ["select", "form-input"] : ["filter"];
    if (props.className) classes.push(props.className);
    if (!props.required) classes.push("select__optional");
    if (props.error) classes.push("invalid");
    return classes;
  };

  const renderError = () => {
    if (!props.error) {
      return null;
    }
    const fieldName = props.errorLabel || "Field";
    let message: string;
    switch (props.error.type) {
      case "required":
        message = props.error.message || `${fieldName} is required`;
        break;
      default:
        message = props.error.message || `${fieldName} is invalid`;
        break;
    }

    return <ValidationError path={props.errorPath || ""} message={message} />;
  };

  const error = renderError();
  const componentOverrides: Props<Option, IsMulti>["components"] = {
    SelectContainer: useCallback(
      ({ children, ...innerProps }: ContainerProps<Option, IsMulti>) => {
        const hint = props.hint ? <div className="hint">{props.hint}</div> : null;
        return (
          <components.SelectContainer {...innerProps}>
            {children}
            {error || hint || null}
          </components.SelectContainer>
        );
      },
      [error, props.hint]
    ),
    Control: useCallback(
      ({ children, ...innerProps }: ControlProps<Option, IsMulti>) => {
        return (
          <components.Control {...innerProps}>
            {props.icon}
            {children}
          </components.Control>
        );
      },
      [props.icon]
    ),
    ValueContainer: useCallback(
      ({ children, ...innerProps }: ValueContainerProps<Option, IsMulti>) => {
        const renderValue = () => {
          const values = innerProps.getValue();
          if (innerProps.selectProps.inputValue && innerProps.selectProps.inputValue.length > 0) {
            return null;
          } else if (values && values.length > 0) {
            if (innerProps.isMulti) {
              return (
                <div className="select__multi-value">
                  {values.length === 1 ? values[0].label : `${values.length} ${props.multiValueItemLabel || "items"}`}
                </div>
              );
            } else {
              return <div className="select__single-value">{values[0].label}</div>;
            }
          }
        };
        return (
          <components.ValueContainer {...innerProps}>
            {children}
            {renderValue()}
          </components.ValueContainer>
        );
      },
      [props.multiValueItemLabel]
    ),
    Menu: SelfPositioningMenu,
    MenuList: props.required
      ? components.MenuList
      : ({ children, ...innerProps }) => {
          const prefix = innerProps.selectProps?.classNamePrefix || "select";
          return (
            <components.MenuList {...innerProps}>
              {innerProps.hasValue && (
                <div
                  className={clsx(prefix + "__clear")}
                  onClick={() => {
                    innerProps.clearValue();
                    innerProps.selectProps.onMenuClose();
                  }}
                >
                  {props.clearText || "Clear"}
                </div>
              )}
              {children}
            </components.MenuList>
          );
        },
    MultiValue: Empty,
    SingleValue: Empty,
    Option: props.isMulti ? OptionWithCheckbox : NormalOption,
    DropdownIndicator: (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <DropdownIcon />
        </components.DropdownIndicator>
      );
    },
    IndicatorSeparator: null,
    NoOptionsMessage: () => (
      <div className="select__menu-notice select__menu-notice--no-options">
        {isFunction(props.options) ? "Type to search options" : "No options"}
      </div>
    ),
  };

  const commonProps: Props<Option, IsMulti> = {
    value: props.value,
    onBlur: props.onBlur,
    onChange: props.readOnly ? undefined : props.onChange,
    isMulti: props.isMulti,
    menuIsOpen: props.isMenuOpen,
    closeMenuOnSelect: !props.isMulti,
    placeholder: props.placeholder,
    isDisabled: props.readOnly,
    isClearable: false,
    hideSelectedOptions: false,
    blurInputOnSelect: !props.isMulti,
    className: getCssClasses().join(" "),
    classNamePrefix: "select",
    components: componentOverrides,
    styles: {
      placeholder: (styles, innerProps) => ({}),
      container: (styles, innerProps) => ({
        ...styles,
        border: "none",
        outline: "none",
        height: "40px",
        color: "var(--font-color)",
      }),
      control: (styles, innerProps) => ({
        ...styles,
        cursor: innerProps.isDisabled ? undefined : "pointer",
        outline: "none",
        border: "none",
        height: "40px",
        font: "var(--font)",
        boxShadow: "none",
      }),
      valueContainer: (styles, innerProps) => ({
        ...styles,
        padding: props.icon ? "2px 8px 2px 0px" : "2px 8px 2px 12px",
        display: "flex",
        flexWrap: "nowrap",
      }),
      menuList: (styles, innerProps) => ({ ...styles, WebkitOverflowScrolling: "touch" }), // maybe will solve issue
      option: (styles, innerProps) => {
        const override = { ...styles };
        if (innerProps.isSelected) override.backgroundColor = "var(--button-background-a05)";
        else if (innerProps.isFocused) override.backgroundColor = "var(--button-background-a01)";
        return override;
      },
    },
  };

  if (isFunction(props.options)) {
    const getDefaultOptions = () => {
      if (props.value === undefined || props.value === null) return [];
      if (isArray(props.value)) {
        return props.value;
      } else if (props.value !== null) {
        return [props.value as Option];
      } else {
        return undefined;
      }
    };

    return (
      <AsyncSelect<Option, IsMulti>
        {...commonProps}
        cacheOptions={false}
        defaultOptions={getDefaultOptions()}
        loadOptions={props.options}
      />
    );
  } else {
    return <ReactSelect<Option, IsMulti> {...commonProps} isSearchable={true} options={props.options} />;
  }
}

function optionsAreGroups(options: ReadonlyOptions): options is readonly OptionGroup[] {
  return options.every((o) => "options" in o);
}

function valuesToOptions(options: ReadonlyOptions, values?: SelectedValueType<true>) {
  if (values === undefined) return [];
  const allOptions = optionsAreGroups(options) ? options.flatMap((g) => g.options) : (options as readonly Option[]);
  const allValues = allOptions.map((o) => o.value);
  const hasDuplicates = uniq(allValues).length < allValues.length;
  if (hasDuplicates) throw new Error("Duplicate values in groups not supported in Select.");
  return values.map((v) => allOptions.find((o) => o.value === v)).filter(isProvided);
}

function valueToOption(options: ReadonlyOptions, value?: SelectedValueType<false>) {
  const mapped = valuesToOptions(options, isProvided(value) ? [value] : []);
  return mapped.length === 1 ? mapped[0] : undefined;
}

function optionsToValues(options: SelectedOptionType<true>): SelectedValueType<true> {
  return options.map((o) => o.value);
}

function optionToValue(option?: SelectedOptionType<false>): SelectedValueType<false> {
  return isProvided(option) ? option.value : null;
}

function valueTransformer<IsMulti extends boolean = false>(
  isMulti?: IsMulti
): {
  optionsToValues: (options: SelectedOptionType<IsMulti>) => SelectedValueType<IsMulti>;
  valueToOption: (options: ReadonlyOptions, value?: SelectedValueType<IsMulti>) => SelectedOptionType<IsMulti>;
} {
  if (isMulti) {
    return { optionsToValues: optionsToValues as any, valueToOption: valuesToOptions as any };
  } else {
    return { optionsToValues: optionToValue as any, valueToOption: valueToOption as any };
  }
}

export const optionToString = (option?: Option | null): string | undefined => {
  return typeof option?.value === "string" ? option.value : undefined;
};

export const optionToNumber = (option?: Option | null): number | undefined => {
  return typeof option?.value === "number" ? option.value : undefined;
};

export function Select<IsMulti extends boolean = false>(
  props: {
    value: SelectedValueType<IsMulti>;
    options: ReadonlyOptions;
    onChange: (value: SelectedValueType<IsMulti>) => void;
  } & Omit<SelectProps<IsMulti>, "onChange" | "value" | "options">
) {
  const { value, options, onChange, ...common } = props;

  const { valueToOption, optionsToValues } = valueTransformer<IsMulti>(props.isMulti);

  const handleChange = (selection: SelectedOptionType<IsMulti>, meta: ActionMeta<Option>) => {
    if (onChange) {
      const values = optionsToValues(selection);
      onChange(values);
    }
  };

  return <AbstractSelect {...common} options={options} onChange={handleChange} value={valueToOption(options, value)} />;
}

export function SelectAsync<IsMulti extends boolean = false>(
  props: {
    value: SelectedOptionType<IsMulti>;
    loadOptions: (input: string) => Promise<ReadonlyOptions>;
    debounceLoadOptions?: number;
    onChange?: (value: SelectedOptionType<IsMulti>) => void;
  } & Omit<SelectProps<IsMulti>, "onChange" | "value" | "options">
) {
  const { value, onChange, loadOptions: options, debounceLoadOptions: debounceInterval, ...common } = props;

  const debouncedOptions = useMemo(() => {
    return pDebounce(options, debounceInterval || 0);
  }, [options, debounceInterval]);

  const handleChange = (value: SelectedOptionType<IsMulti>) => {
    if (onChange) {
      const cloned = cloneDeep(value);
      onChange(cloned);
    }
  };

  return <AbstractSelect {...common} value={cloneDeep(value)} onChange={handleChange} options={debouncedOptions} />;
}

export function FormSelect<TModel extends FieldValues, TPath extends Path<TModel>, IsMulti extends boolean = false>(
  props: {
    name: TPath;
    control: UseFormReturn<TModel>["control"];
    options: ReadonlyOptions;
    onChange?: (value: PathValue<TModel, TPath>) => void;
  } & Omit<SelectProps<IsMulti>, "onChange" | "value" | "error" | "onBlur" | "options">
) {
  const { name, control, options, onChange, ...common } = props;

  const { field, fieldState } = useController<TModel>({
    name: name,
    control: control,
    defaultValue: undefined,
  });

  const { valueToOption, optionsToValues } = valueTransformer<IsMulti>(props.isMulti);

  const handleChange = (selection: SelectedOptionType<IsMulti>) => {
    const value = optionsToValues(selection);
    if (props.onChange) props.onChange(value as any);
    field.onChange(value);
  };

  return (
    <AbstractSelect
      {...common}
      options={options}
      onChange={handleChange}
      value={valueToOption(options, field.value as any)}
      error={fieldState.error}
      errorPath={name}
      onBlur={field.onBlur}
    />
  );
}

export function FormSelectAsync<
  TModel extends FieldValues,
  TPath extends Path<TModel>,
  IsMulti extends boolean = false
>(
  props: {
    name: TPath;
    control: UseFormReturn<TModel>["control"];
    loadOptions: (input: string) => Promise<ReadonlyOptions>;
    debounceLoadOptions?: number;
    onChange?: (value: SelectedOptionType<IsMulti>) => void;
  } & Omit<SelectProps<IsMulti>, "onChange" | "value" | "error" | "onBlur" | "options">
) {
  const { name, control, loadOptions: options, debounceLoadOptions: debounceInterval, onChange, ...common } = props;

  const { field, fieldState } = useController({
    name: name,
    control: control,
    defaultValue: undefined,
  });

  const handleChange = (options: SelectedOptionType<IsMulti>) => {
    field.onChange(options);
    if (onChange) onChange(options);
  };

  const debouncedOptions = useMemo(() => {
    return pDebounce(options, debounceInterval || 0);
  }, [options, debounceInterval]);

  return (
    <AbstractSelect
      {...common}
      options={debouncedOptions}
      onChange={handleChange}
      value={field.value as any}
      error={fieldState.error}
      errorPath={name}
      onBlur={field.onBlur}
    />
  );
}
