import { useEffect } from "react";
import { AnalyticsApi, DemographicsApi, DemographicsView, ShoppingStatisticsView } from "../api";
import Card from "../app/cards/Card";
import DailyVisitingAnalytics from "../app/tables/DailyVisitingAnalytics";
import TopVisitors from "../app/tables/TopVisitors";
import { AgeChart, GenderChart } from "../app/widgets/Demographics";
import { AnalyticsPageHeader } from "../app/widgets/PageHeaders";
import { logErrorSilently } from "../services/alerts";
import { isHttpOk } from "../services/api";
import { useStateEx } from "../services/hooks";
import { useLocalizedText } from "../services/localization";
import { getApiConfig } from "../state/configuration";
import { useTimeLocationFilters } from "../state/globalFilters";
import { BottomRowStatistics } from "./visitor-statistics/BottomRowStatistics";
import { TopRowStatistics } from "./visitor-statistics/TopRowStatistics";

import "./VisitorStatistics.scss";

function VisitorStatistics() {
  const { state, mergeState } = useStateEx<{
    isLoading: boolean;
    stats: ShoppingStatisticsView | undefined;
    demographics: DemographicsView | undefined;
  }>({
    isLoading: true,
    stats: undefined,
    demographics: undefined,
  });
  const texts = useLocalizedText();
  const filters = useTimeLocationFilters();

  useEffect(() => {
    mergeState({ isLoading: true });

    const config = getApiConfig();
    const demographicsApi = new DemographicsApi(config);
    const p1 = demographicsApi
      .getForVisits(filters)
      .then((result) => {
        if (isHttpOk(result)) {
          mergeState({ demographics: result.data });
        } else {
          logErrorSilently(result);
        }
      })
      .catch(logErrorSilently);

    const analyticsApi = new AnalyticsApi(config);
    const p2 = analyticsApi
      .getShoppingStatistics(filters)
      .then((result) => {
        if (isHttpOk(result)) {
          mergeState({ stats: result.data });
        } else {
          logErrorSilently(result);
        }
      })
      .catch(logErrorSilently);

    Promise.all([p1, p2]).finally(() => mergeState({ isLoading: false }));
  }, [filters, mergeState]);

  return (
    <div className="visitor-statistics page-content">
      <AnalyticsPageHeader title={texts.visitorStatisticsPage.title} />
      <TopRowStatistics />
      <section className="charts-and-visitors">
        <AgeChart itemTitle={{ singular: "visit", plural: "visits" }} ages={state.demographics?.ageGroups || []} />
        <GenderChart metricTitle={{ singular: "visit", plural: "visits" }} genders={state.demographics} />
        <TopVisitors />
      </section>
      <BottomRowStatistics stats={state.stats} />
      <section>
        <Card>
          <DailyVisitingAnalytics loading={state.isLoading} statsByDay={state.stats?.statisticsByDay || []} />
        </Card>
      </section>
    </div>
  );
}

export default VisitorStatistics;
