import { ComponentType, RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldError } from "react-hook-form";
import ReactSelect, { ContainerProps, SingleValue, components } from "react-select";
import { useCachedInstance, useEventHandler } from "../../services/hooks";
import { isProvided } from "../../services/objects";
import { Calendar, Clock, DropdownIcon } from "../icons/Icons";
import { ValidationError } from "../widgets/ValidationError";
import { Option } from "./Select";

import "./ComplexSelector.scss";

export interface EditorProps<Model> {
  value: Model | null;
  onSave: (value: Model, closeAfterSave?: boolean) => void;
  onCancel: () => void;
  wrapperRef: RefObject<HTMLDivElement>;
}

export interface ComplexOptions<Model> {
  getOptionLabel: (input: Model | null) => string;
  getRenderedValue?: (input: Model) => string;
  isSelected: (input: Model) => boolean;
  useEditor?: ComponentType<EditorProps<Model>>;
  useDefault?: () => Model;
}

export type ComplexSelectorProps<Model> = {
  className?: string;
  value: Model | null;
  options: ComplexOptions<Model>[];
  onChange: (value: Model | null) => void;
  required?: boolean;
  placeholder?: string;
  error?: FieldError;
  customIcon?: string;
};

export function ComplexSelector<Model>({
  value,
  required,
  error,
  className,
  placeholder,
  ...otherProps
}: ComplexSelectorProps<Model>) {
  const onChangeHandler = useEventHandler(otherProps.onChange);
  const options = useCachedInstance(otherProps.options);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [editor, setEditor] = useState<{
    open: boolean;
    component: ComponentType<EditorProps<Model>> | null;
  }>({
    open: false,
    component: null,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);

  const selectedOption = useMemo(() => {
    if (value !== null) {
      const option = options.find((o) => o.isSelected(value));
      if (option) {
        return option;
      }
      return "";
    }
  }, [options, value]);

  const renderedValue = useMemo(() => {
    if (selectedOption && value) {
      return selectedOption.getRenderedValue
        ? selectedOption.getRenderedValue(value)
        : selectedOption.getOptionLabel(value);
    }
    return "";
  }, [selectedOption, value]);

  const closeEditorIfOpen = useCallback(() => {
    setEditor((prev) => (prev.open ? { open: false, component: null } : prev));
  }, []);

  const hasError = isProvided(error);
  const errorNode = useMemo(() => {
    if (hasError) {
      let message: string;
      switch (error?.type) {
        case "required":
          message = error?.message || `Field is required`;
          break;
        default:
          message = error?.message || `Field is invalid`;
          break;
      }
      return <ValidationError message={message} />;
    }
    return null;
  }, [hasError, error?.type, error?.message]);

  const setValueAndClose = useCallback(
    (value, closeAfterSave: boolean = true) => {
      onChangeHandler.current(value);
      if (closeAfterSave) closeEditorIfOpen();
    },
    [closeEditorIfOpen, onChangeHandler]
  );

  const editorNode = useMemo(() => {
    if (!editor.open || editor.component === null) {
      return null;
    }
    return (
      <editor.component value={value} onSave={setValueAndClose} onCancel={closeEditorIfOpen} wrapperRef={wrapperRef} />
    );
  }, [value, editor, closeEditorIfOpen, setValueAndClose]);

  const handleSelectionStart = useCallback(
    (selectedOption: SingleValue<Option>) => {
      if (selectedOption && typeof selectedOption.value === "number") {
        if (!required && selectedOption.value === -1) {
          onChangeHandler.current(null);
        } else {
          const option = options[selectedOption.value];
          if (option.useDefault) {
            onChangeHandler.current(option.useDefault());
          } else if (option.useEditor) {
            setEditor({ open: true, component: option.useEditor });
          } else {
            throw new Error("Each option should have useEditor or useDefault present!");
          }
        }
      }
    },
    [required, setEditor, options, onChangeHandler]
  );

  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      if (wrapperRef?.current && event.target instanceof Node && !wrapperRef.current.contains(event.target))
        closeEditorIfOpen();
    },
    [closeEditorIfOpen]
  );
  useEffect(() => {
    if (editor.open) document.addEventListener("mousedown", handleOutsideClick);

    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [handleOutsideClick, editor.open]);

  const rawOptions = useMemo(() => {
    const raw = options.map((o, index) => ({
      label: o.getOptionLabel(value),
      value: index,
    }));
    if (!required && value !== null) {
      raw.splice(0, 0, { label: "Clear", value: -1 });
    }
    return raw;
  }, [options, value, required]);

  const getSelectedRawOption = () => {
    if (value !== null) {
      const index = options.findIndex((option) => option.isSelected(value));
      if (index > -1) {
        return required ? rawOptions[index] : rawOptions[index + 1];
      }
    }
    return null;
  };

  const classNames = ["select", "complex-selector", "form-input"];
  if (error) {
    classNames.push("invalid");
  }
  if (className) {
    classNames.push(className);
  }

  const svgIcon = (() => {
    switch (otherProps.customIcon) {
      case "clock":
        return <Clock className="input-icon" />;
      default:
        return <Calendar className="input-icon" />;
    }
  })();

  return (
    <ReactSelect
      options={rawOptions}
      menuIsOpen={menuIsOpen}
      onMenuOpen={useCallback(() => {
        closeEditorIfOpen();
        setMenuIsOpen(true);
      }, [closeEditorIfOpen])}
      onMenuClose={useCallback(() => setMenuIsOpen(false), [])}
      value={getSelectedRawOption()}
      isMulti={false}
      isSearchable={false}
      className={classNames.join(" ")}
      classNamePrefix="select"
      placeholder={menuIsOpen ? "Choose option" : placeholder}
      onChange={handleSelectionStart}
      styles={{
        valueContainer: (style, props) => ({
          ...style,
          display: "flex",
        }),
        control: (style, props) => {
          return {
            ...style,
            "&:hover": undefined,
            "& input": {
              height: "1px",
            },
          };
        },
      }}
      components={{
        IndicatorSeparator: null,
        SingleValue: (props) => {
          return <div className="select__single-value">{renderedValue}</div>;
        },
        DropdownIndicator: (props) => {
          return (
            <>
              <DropdownIcon />
              {svgIcon}
            </>
          );
        },
        SelectContainer: useCallback(
          (props: ContainerProps<Option, false>) => {
            const { children, ...rest } = props;
            return (
              <components.SelectContainer {...rest}>
                {children}
                {editorNode}
                {errorNode}
              </components.SelectContainer>
            );
          },
          [editorNode, errorNode]
        ),
      }}
    />
  );
}
