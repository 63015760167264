import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import React, { PropsWithChildren, useEffect, useRef } from "react";
import { toast as alert } from "react-toastify";
import { AlertContent } from "../app/widgets/Alerts";
import { logout } from "../state/authentication";

interface AxiosInterceptor {
  onRequest?: (request: AxiosRequestConfig) => Promise<AxiosRequestConfig> | AxiosRequestConfig;
  onResponse?: (response: AxiosResponse<any>) => Promise<AxiosResponse<any>> | AxiosResponse<any>;
  onRejectedRequest?: (error: any) => void;
  onRejectedResponse?: (error: any) => void;
}

function applyInterceptor(axios: AxiosInstance, interceptor: AxiosInterceptor) {
  const rqId = axios.interceptors.request.use(interceptor.onRequest, interceptor.onRejectedRequest);
  const rsId = axios.interceptors.response.use(interceptor.onResponse, interceptor.onRejectedResponse);
  return {
    eject: () => {
      axios.interceptors.request.eject(rqId);
      axios.interceptors.request.eject(rsId);
    },
  };
}

// const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;
// function isIsoDateString(value: any): boolean {
//   return value && typeof value === "string" && value.length > 18 && isoDateFormat.test(value);
// }
// function interceptDates(body: any) {
//   if (body === null || body === undefined || typeof body !== "object") return body;
//   for (const key of Object.keys(body)) {
//     const value = body[key];
//     if (isIsoDateString(value)) {
//       body[key] = df.parseISO(value);
//     } else if (typeof value === "object") {
//       interceptDates(value);
//     }
//   }
// }

axios.defaults.validateStatus = (status) => {
  return status < 500;
};

export function LogoutWhenUnauthorized(props: PropsWithChildren<{}>) {
  const isLoggingOut = useRef<boolean>();
  useEffect(() => {
    const unauthorizedInterceptor: AxiosInterceptor = {
      onResponse: (response: AxiosResponse<any>) => {
        if (response.status === 401 && !response.config.disableUnauthorizedInterception) {
          if (!isLoggingOut.current) {
            isLoggingOut.current = true;
            alert.warn(<AlertContent message="Your session has expired. Please login again."></AlertContent>);
            logout();
          }
        }
        return response;
      },
    };
    const instance = applyInterceptor(axios, unauthorizedInterceptor);
    return () => {
      instance.eject();
    };
  }, []);

  return <>{props.children}</>;
}
