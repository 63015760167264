import { useCallback, useState } from "react";
import { toast as alert } from "react-toastify";
import { CustomersApi, MessageSort, MessageStatus, MessagesApi } from "../../api";
import Checkbox from "../../app/forms/Checkbox";
import { Option, OptionValueType, Select, SelectAsync, optionToNumber, optionToString } from "../../app/forms/Select";
import { ItemList } from "../../app/icons/Icons";
import { AlertContent } from "../../app/widgets/Alerts";
import { PageHeader } from "../../app/widgets/PageHeaders";
import { isHttpOk } from "../../services/api";
import { getApiConfig } from "../../state/configuration";
import { LiveContactTracking } from "./LiveContactTracking";
import { LiveMessageTracking } from "./LiveMessageTracking";

import "./CustomerUtils.scss";

const displayLimitOptions = [
  { label: "Display Limit: 5", value: 5 },
  { label: "Display Limit: 10", value: 10 },
  { label: "Display Limit: 20", value: 20 },
];

function statusText(status: MessageStatus): string {
  switch (status) {
    case 1:
      return "Scheduled";
    case 2:
      return "Live";
    case 3:
      return "Expired";
    case 4:
      return "Deleted";
    case 5:
      return "Paused";
    default:
      return "N/A";
  }
}

async function searchCustomers(search: string): Promise<Option[]> {
  const config = getApiConfig();
  const api = new CustomersApi(config);
  const response = await api.lookupAll({
    search: search,
    limit: 10,
    skip: 0,
  });

  if (isHttpOk(response) && response.data.items) {
    return response.data.items.map((u) => ({
      label: `${u.name} (${u.email})`,
      description: `ID: ${u.id}\nName: ${u.name}\nEmail: ${u.email}`,
      value: u.id || "",
    }));
  }
  return [];
}

async function searchMessages(search: string): Promise<Option[]> {
  const config = getApiConfig();
  const api = new MessagesApi(config);
  const response = await api.get({
    search: search,
    limit: 10,
    skip: 0,
    sort: MessageSort.TitleAsc,
  });
  const bellIcon = "🔕";

  if (isHttpOk(response) && response.data.items) {
    return response.data.items.map((m) => {
      return {
        label: `${m.isSilent ? bellIcon : ""} [${m.id}] ${m.pushTitle} ${m.label ? ` (Label: ${m.label})` : ""}`,
        deactivated: m.status !== MessageStatus.Live && m.status !== MessageStatus.Scheduled,
        description: `Campaign: ${m.campaignTitle}
Notification: ${m.isSilent ? "Disabled" : "Enabled"}
Status: ${statusText(m.status)}`,
        value: m.id || 0,
      };
    });
  }
  return [];
}

export function CustomerUtils() {
  const [customer, setCustomer] = useState<Option | null>(null);
  const [message, setMessage] = useState<Option | null>(null);
  const [displayLimit, setDisplayLimit] = useState<number>(5);
  const [displayAbsTime, setDisplayAbsTime] = useState<boolean>(false);
  const customerId = optionToString(customer);
  const messageId = optionToNumber(message);

  const displayLimitChanged = (value: OptionValueType | null) => {
    if (typeof value === "number") setDisplayLimit(value);
  };

  const handleClearContacts = useCallback(async () => {
    if (typeof customerId === "string") {
      const config = getApiConfig();
      const api = new CustomersApi(config);
      const response = await api.reset({
        customerId: customerId,
      });
      if (isHttpOk(response)) {
        alert.success(<AlertContent message="Customer reset successful." />);
      } else {
        alert.error(<AlertContent message="Customer reset failed." />);
      }
    }
  }, [customerId]);

  const handleClearMessages = useCallback(async () => {
    if (!customerId || !messageId) return;

    const config = getApiConfig();
    const api = new MessagesApi(config);
    const response = await api.clearMessageStatistics({
      userId: customerId,
      messageId: messageId,
    });

    if (isHttpOk(response)) {
      alert.success(<AlertContent message="Messages cleared." />);
    } else {
      alert.error(<AlertContent message="Failed to clear messages." />);
    }
  }, [customerId, messageId]);

  return (
    <div className="customer-utils page-content">
      <PageHeader title="Customer Utilities">
        <div className="toolbar">
          <Select
            type="filter"
            required={true}
            value={displayLimit}
            icon={<ItemList />}
            onChange={displayLimitChanged}
            options={displayLimitOptions}
          />
        </div>
      </PageHeader>
      <section>
        <div className="customer-utils-card m-1 m-sm-2 px-0 px-sm-3 py-2 py-sm-4">
          <div className="container-fluid">
            <h3 className="customer-selector-title">Find customer to view visits and contacts</h3>
            <div className="customer-selector py-2">
              <SelectAsync
                type="form"
                className="col-12 col-md-6"
                placeholder="Type to search customer"
                required={true}
                loadOptions={searchCustomers}
                debounceLoadOptions={400}
                value={customer}
                onChange={setCustomer}
              />
              <button
                disabled={typeof customerId !== "string"}
                onClick={handleClearContacts}
                className="primary delete"
              >
                Clear Visits
              </button>
            </div>
            <div className="margin-bottom-2x">
              <Checkbox
                name="chkAbsTime"
                label="Show absolute time"
                value={displayAbsTime}
                onCheckedChange={(value) => setDisplayAbsTime(value)}
              />
            </div>
            <LiveContactTracking
              customerId={customerId}
              displayLimit={displayLimit}
              displayAbsoluteTime={displayAbsTime}
            />
            <h3 className="message-selector-title">Find a message to review statistics or send to selected customer</h3>
            <div className="message-selector py-2">
              <SelectAsync
                className="col-12 col-md-6"
                type="form"
                placeholder="Type to search for a message"
                required={true}
                loadOptions={searchMessages}
                debounceLoadOptions={400}
                value={message}
                onChange={setMessage}
              />
              <button
                disabled={typeof customerId !== "string" || typeof messageId !== "number"}
                onClick={handleClearMessages}
                className="primary delete"
              >
                Clear Messages
              </button>
            </div>
            <LiveMessageTracking
              messageId={messageId}
              customerId={customerId}
              displayLimit={displayLimit}
              displayAbsoluteTime={displayAbsTime}
            />
          </div>
        </div>
      </section>
    </div>
  );
}
