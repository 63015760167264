import { useEffect } from "react";
import { AnalyticsApi, BeaconsApi, RevenueStatisticsApi, TopAreaView, VisitorStatisticsApi } from "../../api";
import StatsCardWithTrend from "../../app/cards/StatsCardWithTrend";
import { Modal, useModal } from "../../app/modals/Modal";
import { AppInteractionUsersTable } from "../../app/tables/analytics/AppInteractionUsersTable";
import { AppRevenueUsersTable } from "../../app/tables/analytics/AppRevenueUsersTable";
import { AppTransactionUsersTable } from "../../app/tables/analytics/AppTransactionUsersTable";
import { AvgTransactionQuantityUsersTable } from "../../app/tables/analytics/AvgTransactionQuantityUsersTable";
import { AvgTransactionValueUsersTable } from "../../app/tables/analytics/AvgTransactionValueUsersTable";
import { RedemptionUsersTable } from "../../app/tables/analytics/RedemptionUsersTable";
import { SalesConversionUsersTable } from "../../app/tables/analytics/SalesConversionUsersTable";
import { TimeSpentUsersTable } from "../../app/tables/analytics/TimeSpentUsersTable";
import { VisitingUsersTable } from "../../app/tables/analytics/VisitingUsersTable";
import { isHttpOk } from "../../services/api";
import {
  approximateDuration,
  calcTrend,
  formatDecimal,
  formatPercentage,
  useMoneyFormatter,
} from "../../services/format";
import { useStateEx } from "../../services/hooks";
import { useLocalizedText } from "../../services/localization";
import { getApiConfig } from "../../state/configuration";
import { useDeploymentConfig } from "../../state/deployment";
import { useIsSingleStore, useTimeLocationFilters } from "../../state/globalFilters";

import "./BottomRow.scss";

const emptyValue = () => ({ current: 0, previous: 0 });

function BottomRowForRevenue() {
  const texts = useLocalizedText();
  const filters = useTimeLocationFilters("Analytics");
  const { formatMoney } = useMoneyFormatter();

  const { state: revenue, setState: setRevenue } = useStateEx(emptyValue);
  const { state: transactions, setState: setTransactions } = useStateEx(emptyValue);
  const { state: averageTransactionValue, setState: setAverageTransactionValue } = useStateEx(emptyValue);
  const { state: averageTransactionQuantity, setState: setAverageTransactionQuantity } = useStateEx(emptyValue);
  const { state: storeVisits, setState: setStoreVisits } = useStateEx(emptyValue);
  const { state: salesConversionRate, setState: setSalesConversionRate } = useStateEx(emptyValue);

  useEffect(() => {
    const config = getApiConfig();
    const analyticsApi = new AnalyticsApi(config);
    const revenueApi = new RevenueStatisticsApi(config);
    const visitorApi = new VisitorStatisticsApi(config);
    revenueApi.getRevenue(filters).then((result) => {
      if (isHttpOk(result)) setRevenue(result.data);
    });
    revenueApi.getTransactions(filters).then((result) => {
      if (isHttpOk(result)) setTransactions(result.data);
    });
    visitorApi.getStoreVisits(filters).then((result) => {
      if (isHttpOk(result)) setStoreVisits(result.data);
    });
    analyticsApi.getAverageTransactionValue(filters).then((result) => {
      if (isHttpOk(result)) setAverageTransactionValue(result.data);
    });
    analyticsApi.getAverageTransactionQuantity(filters).then((result) => {
      if (isHttpOk(result)) setAverageTransactionQuantity(result.data);
    });
    analyticsApi.getSalesConversionRate(filters).then((result) => {
      if (isHttpOk(result)) setSalesConversionRate(result.data);
    });
  }, [
    filters,
    setRevenue,
    setTransactions,
    setAverageTransactionValue,
    setAverageTransactionQuantity,
    setStoreVisits,
    setSalesConversionRate,
  ]);

  const appRevenueModalId = "appRevenueModal";
  const { openModal: openAppRevenueModal, closeModal: closeAppRevenueModal } = useModal(appRevenueModalId);
  const appTransactionModalId = "appTransactionModal";
  const { openModal: openAppTransactionModal, closeModal: closeAppTransactionModal } = useModal(appTransactionModalId);
  const avgTransactionValueModalId = "avgTransactionValueModal";
  const { openModal: openAvgTransactionValueModal, closeModal: closeAvgTransactionValueModal } =
    useModal(avgTransactionValueModalId);
  const avgTransactionQuantityModalId = "avgTransactionQuantityModal";
  const { openModal: openAvgTransactionQuantityModal, closeModal: closeAvgTransactionQuantityModal } =
    useModal(avgTransactionQuantityModalId);
  const customerVisitsModalId = "customerVisitsModal";
  const { openModal: openCustomerVisitsModal, closeModal: closeCustomerVisitsModal } = useModal(customerVisitsModalId);
  const salesConversionModalId = "salesConversionModal";
  const { openModal: openSalesConversionModal, closeModal: closeSalesConversionModal } =
    useModal(salesConversionModalId);

  return (
    <section className="analytics-bottom-row app-stat-cards">
      <StatsCardWithTrend
        hideDots={true}
        currentValue={formatMoney(revenue.current)}
        previousValue={formatMoney(revenue.previous)}
        trendChangePercent={calcTrend(revenue)}
        tooltipText={texts.analyticsPage.items.appRevenue.tooltip}
        label={texts.analyticsPage.items.appRevenue.title}
        color="green"
        onClick={openAppRevenueModal}
      />
      <StatsCardWithTrend
        hideDots={true}
        currentValue={formatDecimal(transactions.current, 0)}
        previousValue={formatDecimal(transactions.previous, 0)}
        trendChangePercent={calcTrend(transactions)}
        tooltipText={texts.analyticsPage.items.appTransactions.tooltip}
        label={texts.analyticsPage.items.appTransactions.title}
        color="orange"
        onClick={openAppTransactionModal}
      />
      <StatsCardWithTrend
        hideDots={true}
        currentValue={formatMoney(averageTransactionValue.current)}
        previousValue={formatMoney(averageTransactionValue.previous)}
        trendChangePercent={calcTrend(averageTransactionValue)}
        tooltipText={texts.analyticsPage.items.avgAppTransactions.tooltip}
        label={texts.analyticsPage.items.avgAppTransactions.title}
        color="purple"
        onClick={openAvgTransactionValueModal}
      />
      <StatsCardWithTrend
        hideDots={true}
        currentValue={formatDecimal(averageTransactionQuantity.current, 2)}
        previousValue={formatDecimal(averageTransactionQuantity.previous, 2)}
        trendChangePercent={calcTrend(averageTransactionQuantity)}
        tooltipText={texts.analyticsPage.items.avgQtyPerAppTransactions.tooltip}
        label={texts.analyticsPage.items.avgQtyPerAppTransactions.title}
        color="pink"
        onClick={openAvgTransactionQuantityModal}
      />
      <StatsCardWithTrend
        hideDots={true}
        currentValue={formatDecimal(storeVisits.current, 0)}
        previousValue={formatDecimal(storeVisits.previous, 0)}
        trendChangePercent={calcTrend(storeVisits)}
        tooltipText={texts.analyticsPage.items.customerVisits.tooltip}
        label={texts.analyticsPage.items.customerVisits.title}
        color="golden"
        onClick={openCustomerVisitsModal}
      />
      <StatsCardWithTrend
        hideDots={true}
        currentValue={formatPercentage(salesConversionRate.current, 1)}
        previousValue={formatPercentage(salesConversionRate.previous, 1)}
        trendChangePercent={calcTrend(salesConversionRate)}
        tooltipText={texts.analyticsPage.items.salesConvRate.tooltip}
        label={texts.analyticsPage.items.salesConvRate.title}
        color="red"
        onClick={openSalesConversionModal}
      />
      <Modal id={appRevenueModalId} onRequestClose={closeAppRevenueModal} className="wide-modal">
        <AppRevenueUsersTable />
      </Modal>
      <Modal id={appTransactionModalId} onRequestClose={closeAppTransactionModal} className="wide-modal">
        <AppTransactionUsersTable />
      </Modal>
      <Modal id={avgTransactionValueModalId} onRequestClose={closeAvgTransactionValueModal} className="wide-modal">
        <AvgTransactionValueUsersTable />
      </Modal>
      <Modal
        id={avgTransactionQuantityModalId}
        onRequestClose={closeAvgTransactionQuantityModal}
        className="wide-modal"
      >
        <AvgTransactionQuantityUsersTable />
      </Modal>
      <Modal id={customerVisitsModalId} onRequestClose={closeCustomerVisitsModal} className="wide-modal">
        <VisitingUsersTable title="Visiting Customers" />
      </Modal>
      <Modal id={salesConversionModalId} onRequestClose={closeSalesConversionModal} className="wide-modal">
        <SalesConversionUsersTable />
      </Modal>
    </section>
  );
}

function BottomRowForInteractions() {
  const texts = useLocalizedText();
  const isSingleStore = useIsSingleStore();
  const filters = useTimeLocationFilters("Analytics");

  const { state: topArea, setState: setTopArea } = useStateEx<TopAreaView | null>(null);
  const { state: storeVisits, setState: setStoreVisits } = useStateEx(emptyValue);
  const { state: interactionRate, setState: setAppInteractionRate } = useStateEx(emptyValue);
  const { state: avgRedemptionRate, setState: setAvgRedemptionRate } = useStateEx(emptyValue);
  const { state: avgTimeInStore, setState: setAvgTimeInStore } = useStateEx({
    current: 0,
    previous: 0,
    unit: "",
  });

  useEffect(() => {
    const config = getApiConfig();
    const beaconsApi = new BeaconsApi(config);
    if (isSingleStore) {
      beaconsApi
        .getTopAreas({
          size: 1,
          ...filters,
        })
        .then((response) => {
          if (isHttpOk(response) && response.data.length >= 1) {
            setTopArea(response.data[0]);
          } else {
            setTopArea(null);
          }
        });
    }
  }, [filters, setTopArea, isSingleStore]);

  useEffect(() => {
    const config = getApiConfig();
    const analyticsApi = new AnalyticsApi(config);
    const visitorApi = new VisitorStatisticsApi(config);
    analyticsApi.getAppInteractionRate(filters).then((result) => {
      if (isHttpOk(result)) setAppInteractionRate(result.data);
    });
    visitorApi.getStoreVisits(filters).then((result) => {
      if (isHttpOk(result)) setStoreVisits(result.data);
    });
    analyticsApi.getAvgShoppingTime({ includeTrend: true, ...filters }).then((result) => {
      if (isHttpOk(result)) {
        const [current, unit] = approximateDuration(result.data.current);
        const [prev] = approximateDuration(result.data.previous, unit);
        setAvgTimeInStore({
          current: current,
          previous: prev,
          unit: unit,
        });
      }
    });
    analyticsApi.getAvgRedemptionRate(filters).then((result) => {
      if (isHttpOk(result)) setAvgRedemptionRate(result.data);
    });
  }, [filters, setStoreVisits, setAppInteractionRate, setAvgRedemptionRate, setAvgTimeInStore]);

  const redemptionModalId = "avgRedemptionRateModal";
  const { openModal: openRedemptionModal, closeModal: closeRedemptionModal } = useModal(redemptionModalId);
  const userVisitsModalId = "userVisitsModal";
  const { openModal: openUserVisitModal, closeModal: closeUserVisitModal } = useModal(userVisitsModalId);
  const avgTimeModalId = "avgTimeSpentModal";
  const { openModal: openAvgTimeModal, closeModal: closeAvgTimeModal } = useModal(avgTimeModalId);
  const interactionRateModalId = "interactionRateModal";
  const { openModal: openInteractionRateModal, closeModal: closeInteractionRateModal } =
    useModal(interactionRateModalId);

  return (
    <section className="analytics-bottom-row app-stat-cards">
      <StatsCardWithTrend
        hideDots={true}
        currentValue={formatPercentage(avgRedemptionRate.current, 1)}
        previousValue={formatPercentage(avgRedemptionRate.previous, 1)}
        trendChangePercent={calcTrend(avgRedemptionRate)}
        tooltipText={texts.analyticsPage.items.avgRedemptionRate.tooltip}
        label={texts.analyticsPage.items.avgRedemptionRate.title}
        color="green"
        onClick={openRedemptionModal}
      />
      <StatsCardWithTrend
        hideDots={true}
        currentValue={formatPercentage(interactionRate.current, 1)}
        previousValue={formatPercentage(interactionRate.previous, 1)}
        trendChangePercent={calcTrend(interactionRate)}
        tooltipText={texts.analyticsPage.items.appInteractionRate.tooltip}
        label={texts.analyticsPage.items.appInteractionRate.title}
        color="red"
        onClick={openInteractionRateModal}
      />
      <StatsCardWithTrend
        hideDots={true}
        currentValue={formatDecimal(storeVisits.current, 0)}
        previousValue={formatDecimal(storeVisits.previous, 0)}
        trendChangePercent={calcTrend(storeVisits)}
        tooltipText={texts.analyticsPage.items.userVisits.tooltip}
        label={texts.analyticsPage.items.userVisits.title}
        color="golden"
        onClick={openUserVisitModal}
      />
      <StatsCardWithTrend
        hideDots={true}
        currentValue={formatDecimal(avgTimeInStore.current, 0) + " " + avgTimeInStore.unit}
        previousValue={formatDecimal(avgTimeInStore.previous, 0) + " " + avgTimeInStore.unit}
        trendChangePercent={calcTrend(avgTimeInStore)}
        tooltipText={texts.analyticsPage.items.avgTimeSpent.tooltip}
        label={texts.analyticsPage.items.avgTimeSpent.title}
        color="purple"
        onClick={openAvgTimeModal}
      />
      {isSingleStore && (
        <StatsCardWithTrend
          hideDots={true}
          currentValue={topArea?.name || "N/A"}
          tooltipText={texts.analyticsPage.items.topPerformingArea.tooltip}
          label={texts.analyticsPage.items.topPerformingArea.title}
          color="blue"
        />
      )}
      <Modal id={redemptionModalId} onRequestClose={closeRedemptionModal} className="wide-modal">
        <RedemptionUsersTable />
      </Modal>
      <Modal id={userVisitsModalId} onRequestClose={closeUserVisitModal} className="wide-modal">
        <VisitingUsersTable />
      </Modal>
      <Modal id={avgTimeModalId} onRequestClose={closeAvgTimeModal} className="wide-modal">
        <TimeSpentUsersTable />
      </Modal>
      <Modal id={interactionRateModalId} onRequestClose={closeInteractionRateModal} className="wide-modal">
        <AppInteractionUsersTable />
      </Modal>
    </section>
  );
}

export function BottomRow() {
  const deployment = useDeploymentConfig();
  if (deployment.config.disablePurchaseHistories) {
    return <BottomRowForInteractions />;
  } else {
    return <BottomRowForRevenue />;
  }
}
