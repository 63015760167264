import { AxiosResponse } from "axios";
import { format } from "date-fns";
import { useCallback, useRef, useState } from "react";
import { toast as alert } from "react-toastify";
import { PocAccountApi, PocUserModel, PocUserView } from "../../api";
import Card from "../../app/cards/Card";
import { EditPocUser } from "../../app/modals/EditPocUser";
import { Modal, useModal } from "../../app/modals/Modal";
import { Result } from "../../app/modals/utils";
import { AlertContent, ServerUnavailable } from "../../app/widgets/Alerts";
import { highlight } from "../../app/widgets/HighlightedString";
import { PageHeader } from "../../app/widgets/PageHeaders";
import { PaginatedTable, PaginatedTableApi } from "../../app/widgets/PaginatedTable";
import { confirmDialog } from "../../services/alerts";
import { isHttpOk } from "../../services/api";
import { stringToDate } from "../../services/date";
import { useStateEx } from "../../services/hooks";
import { getApiConfig } from "../../state/configuration";
import { useGlobalSearch } from "../../state/globalSearch";

import "./UserManagement.scss";

type State = {
  model: PocUserModel | null;
  isNew: boolean;
  totalCount: number;
};

function UserManagement() {
  const apiRef = useRef<PaginatedTableApi>(null);
  const { search } = useGlobalSearch();
  const { openModal, closeModal } = useModal();
  const { state, mergeState } = useStateEx<State>({
    totalCount: 0,
    model: null,
    isNew: true,
  });

  const loadPage = useCallback(
    (offset: number, limit: number) => {
      const config = getApiConfig();
      const api = new PocAccountApi(config);
      return api.getAll({ search: search, skip: offset, limit: limit });
    },
    [search]
  );

  const [formModified, setFormModified] = useState<boolean>(false);
  const changed = () => setFormModified(true);

  const renderCells = (model: PocUserView, index: number) => {
    const editUser = async () => {
      const config = getApiConfig();
      const api = new PocAccountApi(config);
      try {
        const response = await api.getOne({
          id: model.id!,
        });
        mergeState({
          model: response.data,
          isNew: false,
        });
        openModal();
      } catch {
        alert.error(<ServerUnavailable />);
      }
    };
    const now = new Date();
    const expiration = stringToDate(model.expirationDateLocal);
    const hasExpired = now > expiration;
    const status = <span className={hasExpired ? "red" : "green"}>{hasExpired ? "Expired" : "Active"}</span>;

    return [
      <span className="name link" onClick={editUser}>
        {highlight(model.name, search)}
      </span>,
      highlight(model.email, search),
      <div>{status}</div>,
      format(expiration, "dd MMM yyyy"),
    ];
  };

  const openAddModal = () => {
    mergeState({ isNew: true });
    openModal();
  };

  const handleCancel = () => {
    if (formModified) confirmDialog("Form has unsaved changes. Discard all?", closeModalAndReset);
    else closeModalAndReset();
  };

  const closeModalAndReset = () => {
    mergeState({ model: null });
    closeModal();
    setFormModified(false);
  };

  const savePocAccount = async (model: PocUserModel) => {
    const config = getApiConfig();
    const api = new PocAccountApi(config);

    try {
      let message: string;
      let result: AxiosResponse<PocUserModel>;

      if (!model.id) {
        message = `POC account created. QR Code and password reset emails sent to: ${model.email}.`;
        result = await api.createUser({
          pocUserModel: model,
        });
      } else {
        message = `POC account updated.`;
        const oldExpDate = state.model ? stringToDate(state.model.expirationDate) : null;
        const newExpDate = stringToDate(model.expirationDate);
        if (oldExpDate && newExpDate > oldExpDate)
          message += ` New QR Code with extended expiration time sent to: ${model.email}.`;
        result = await api.update({
          id: model.id,
          pocUserModel: model,
        });
      }

      if (isHttpOk(result)) {
        closeModalAndReset();
        if (apiRef.current) apiRef.current.refreshPage();
        alert.success(<AlertContent message={message} />, { autoClose: 10000 });
        return Result.Ok;
      } else if (typeof result.data === "string") {
        alert.warn(<AlertContent message={result.data} />);
      } else {
        return Result.Invalid();
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }

    return Result.Invalid();
  };

  const count = state.totalCount;

  return (
    <div className="page-content dashboard">
      <Modal onRequestClose={handleCancel}>
        <EditPocUser initial={state.model} onCancel={handleCancel} onSave={savePocAccount} onChange={changed} />
      </Modal>
      <PageHeader title="POC Accounts" subTitle={`Found ${count} POC account${count !== 1 ? "s" : ""}`}>
        <div className="toolbar">
          <button onClick={openAddModal} className="new">
            + New POC Account
          </button>
        </div>
      </PageHeader>
      <section>
        <Card>
          <PaginatedTable
            apiRef={apiRef}
            className="table"
            initialPageSize={10}
            tableHeaders={[{ title: "NAME" }, { title: "EMAIL" }, { title: "STATUS" }, { title: "EXPIRATION DATE" }]}
            tableDataSource={loadPage}
            tableRowFactory={renderCells}
            minLoadingTimeMs={200}
            hidePagination={false}
            onTotalCountChange={(count) => mergeState({ totalCount: count })}
          />
        </Card>
      </section>
    </div>
  );
}

export default UserManagement;
