import { AxiosResponse } from "axios";
import { ErrorOption, FieldErrors, FieldPath, FieldValues, Path, UseFormSetError } from "react-hook-form";
import { toast as alert } from "react-toastify";
import { ResultStatus } from "../../api";
import { BadRequestResponse, ServiceResult } from "../../services/types";
import { ValidationMessage } from "../widgets/Alerts";

export interface FormModalProps<TModel> {
  initial: TModel | null;
  onSave: (model: TModel) => ResultType | Promise<ResultType>;
  onCancel: () => void;
  onDelete?: (model: TModel) => ResultType | Promise<ResultType>;
  onChange?: () => void;
}

type Error = BadRequestResponse | ServiceResult<any> | void;

export interface ResultType {
  success: boolean;
  result?: AxiosResponse<Error>;
}

export const Result = {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  Ok: { success: true } as ResultType,
  Invalid: (result?: AxiosResponse<Error>): ResultType => {
    return { success: false, result: result };
  },
};

function isHttpBadRequest(result: AxiosResponse<any>): result is AxiosResponse<BadRequestResponse> {
  const temp = result.data as BadRequestResponse;
  return (
    result.status >= 400 &&
    result.status <= 499 &&
    temp !== undefined &&
    temp.errors !== undefined &&
    temp.title !== undefined
  );
}

function isServiceResultError(result: AxiosResponse<any>): result is AxiosResponse<ServiceResult<any>> {
  const temp = result as AxiosResponse<ServiceResult<any>>;
  return temp.data.success === false && temp.data.status !== undefined && temp.data.status !== ResultStatus.Success;
}

function convertBadRequestToFieldErrors(result: BadRequestResponse) {
  const errors: FieldErrors = {};
  if (result.errors) {
    for (const key of Object.keys(result.errors)) {
      for (const message of result.errors[key]) {
        if (message.length > 0) {
          //TODO - remove this ugly hack
          if (key === "UUID") {
            errors["uuid"] = {
              message: message,
            };
          } else {
            const lowerKey = key
              .split(".")
              .map((k) => k[0].toLowerCase() + k.slice(1))
              .join(".");

            errors[lowerKey] = {
              message: message,
            };
          }
        }
      }
    }
  }
  return errors;
}

export function showErrors<TModel extends FieldValues>(
  result: ResultType,
  setError: ((path: FieldPath<TModel>, error: ErrorOption) => void) | UseFormSetError<TModel>
) {
  if (result.success) {
    throw new Error("showErrors called on a result which is not error!");
  }
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.error(result);
  }

  if (result.result) {
    const response = result.result;

    if (isHttpBadRequest(response)) {
      const errors = convertBadRequestToFieldErrors(response.data);
      const fields = Object.keys(errors);
      if (fields.length > 0) {
        for (const field of fields) {
          setError(field as Path<TModel>, errors[field]);
        }
        return;
      }
    }

    if (isServiceResultError(response)) {
      if (response.data.message) {
        alert.error(<ValidationMessage message={response.data.message} />);
        return;
      }
    }

    alert.error(<ValidationMessage message={"Unknown validation error!"} />);
  }
}
