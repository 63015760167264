import { range } from "lodash";
import { FieldErrors } from "react-hook-form";
import {
  FixedDate,
  FixedDateRange,
  PredefinedTimeSpan,
  RelativeTime,
  RelativeTimeRange,
  TimeSpanRange,
} from "../../../../../api";
import { Option, Options, Select } from "../../../../forms/Select";
import { FixedDateInput } from "./FixedDateInput";
import { RelativeTimeInput } from "./RelativeTimeInput";

import "./TimeSpanRangeInput.scss";

export function TimeSpanRangeInput(props: {
  prefix: string;
  rangeSemantics?: boolean;
  customGeneralOptions?: Option[];
  includeOptionAny?: boolean;
  includeOptionNever?: boolean;
  includeFixedGroup?: boolean;
  includeRelativeGroup?: boolean;
  value?: PredefinedTimeSpan;
  onChange: (value: PredefinedTimeSpan) => void;
  error?: FieldErrors<any>;
}) {
  const applySemantics = (range: string) => {
    return props.rangeSemantics ? `last ${range}` : `${range} ago`;
  };

  var options: Options = [];
  // slice to avoid mutating array
  const generalOptions = props.customGeneralOptions?.slice() ?? [
    { label: "today", value: TimeSpanRange.Today },
    { label: "yesterday", value: TimeSpanRange.Yesterday },
    { label: applySemantics("7 days"), value: TimeSpanRange.SevenDaysAgo },
    { label: applySemantics("30 days"), value: TimeSpanRange.ThirtyDaysAgo },
    { label: applySemantics("3 months"), value: TimeSpanRange.ThreeMonthsAgo },
    { label: applySemantics("6 months"), value: TimeSpanRange.SixMonthsAgo },
  ];
  const generalGroup = {
    label: "General",
    options: generalOptions,
  };

  if (props.includeOptionNever) generalGroup.options.push({ label: "never", value: TimeSpanRange.Never });
  if (props.includeOptionAny) generalGroup.options.push({ label: "any time", value: TimeSpanRange.AnyTime });

  const groups = [generalGroup];
  if (props.includeRelativeGroup)
    groups.push({
      label: "Relative time",
      options: [
        { label: "in the last", value: TimeSpanRange.RelativeTime },
        { label: "between", value: TimeSpanRange.RelativeTimeRange },
      ],
    });
  if (props.includeFixedGroup)
    groups.push({
      label: "Fixed dates",
      options: [
        { label: "on", value: TimeSpanRange.FixedDate },
        { label: "between", value: TimeSpanRange.FixedDateRange },
      ],
    });
  options = groups.length > 1 ? groups : groups[0].options;

  const hourOptions = range(1, 24).map((h) => {
    const prefix = props.rangeSemantics ? "until" : "before";
    const hour = h > 12 ? h - 12 : h;
    const noon = h >= 12 ? "PM" : "AM";
    return {
      label: `${prefix} ${hour}:00 ${noon}`,
      value: h,
    };
  });

  function validHour(range: TimeSpanRange, hour: number | null | undefined) {
    if (range !== TimeSpanRange.Today) return null;
    if (typeof hour !== "number") return 8;
    return hour;
  }

  const rangeValue = typeof props.value?.range === "number" ? props.value.range : TimeSpanRange.Today;
  const hourValue = validHour(rangeValue, props.value?.hour);

  const isTodayRange = rangeValue === TimeSpanRange.Today;
  const isRelativeTime = rangeValue === TimeSpanRange.RelativeTime;
  const isRelativeRange = rangeValue === TimeSpanRange.RelativeTimeRange;
  const isFixedDate = rangeValue === TimeSpanRange.FixedDate;
  const isFixedDateRange = rangeValue === TimeSpanRange.FixedDateRange;

  var firstClassName = "first";
  if (isRelativeRange) firstClassName += " reduced";

  return (
    <div className="input-row double relative-span-range">
      <span className="prefix">{props.prefix}</span>
      <Select
        type="form"
        className={firstClassName}
        placeholder={props.rangeSemantics ? "Timeframe" : "Time"}
        errorLabel={props.rangeSemantics ? "Timeframe" : "Time"}
        options={options}
        value={rangeValue}
        onChange={(value) => {
          props.onChange({
            range: value as TimeSpanRange,
            hour: validHour(value as TimeSpanRange, props.value?.hour),
          });
        }}
        isMulti={false}
        required={true}
        error={props.error?.range}
      />
      <div className="second">
        {isRelativeTime && (
          <RelativeTimeInput
            value={props.value?.relativeTime}
            error={props.error && props.error["time.relativeTime"]}
            onChange={(value) => {
              props.onChange({
                range: rangeValue,
                relativeTime: value as RelativeTime,
              });
            }}
          />
        )}
        {isRelativeRange && (
          <RelativeTimeInput
            isRange={true}
            value={props.value?.relativeTimeRange}
            error={props.error && props.error["time.relativeTimeRange"]}
            onChange={(value) => {
              props.onChange({
                range: rangeValue,
                relativeTimeRange: value as RelativeTimeRange,
              });
            }}
          />
        )}
        {isFixedDate && (
          <FixedDateInput
            value={props.value?.fixedDate}
            error={props.error && props.error["time.fixedDate"]}
            onChange={(value) => {
              props.onChange({
                range: rangeValue,
                fixedDate: value as FixedDate,
              });
            }}
          />
        )}
        {isFixedDateRange && (
          <FixedDateInput
            isRange={true}
            value={props.value?.fixedDateRange}
            error={props.error && props.error["time.fixedDateRange"]}
            onChange={(value) => {
              props.onChange({
                range: rangeValue,
                fixedDateRange: value as FixedDateRange,
              });
            }}
          />
        )}
        {isTodayRange && (
          <Select
            type="form"
            placeholder="Hour"
            errorLabel="Hour"
            options={hourOptions}
            value={hourValue}
            onChange={(value) => {
              props.onChange({
                range: rangeValue,
                hour: validHour(rangeValue, value as number),
              });
            }}
            isMulti={false}
            required={true}
            error={props.error?.hour}
          />
        )}
      </div>
    </div>
  );
}
