import { logErrorSilently } from "../../services/alerts";

type MessageProps = {
  message?: string;
  diagnosticError?: any;
};

export const AlertContent = (props: MessageProps) => {
  if (props.diagnosticError) {
    logErrorSilently(props.diagnosticError);
  }
  return <span>{props.message}</span>;
};

export const ServerUnavailable = (props: MessageProps) => (
  <AlertContent message="Server unavailable. Please try again." {...props} />
);

export function ValidationMessage(props: { message: string }) {
  return <AlertContent message={props.message} />;
}

export const UnexpectedError = () => (
  <span>
    Unexpected failure. Please contact <a href="mailto:admin@beaconsmind.com">admin@beaconsmind.com</a>.
  </span>
);
