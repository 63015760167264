import { AnalyticsPageHeader } from "../app/widgets/PageHeaders";
import { useLocalizedText } from "../services/localization";
import { useDeploymentConfig } from "../state/deployment";
import { TouchpointsWithoutRevenue } from "./app-touchpoints/TouchpointsWithoutRevenue";
import { TouchpointsWithRevenue } from "./app-touchpoints/TouchpointsWithRevenue";

import "./AppTouchpoints.scss";

export default function AppTouchpoints() {
  const texts = useLocalizedText();
  const deployment = useDeploymentConfig();
  return (
    <div className="page-content">
      <AnalyticsPageHeader title={texts.appTouchpointsPage.title} />
      {deployment.config.disablePurchaseHistories ? <TouchpointsWithoutRevenue /> : <TouchpointsWithRevenue />}
    </div>
  );
}
