import { MouseEventHandler, PropsWithChildren } from "react";
import "./Card.scss";

interface CardProps {
  onClick?: MouseEventHandler;
  className?: string;
  selected?: boolean;
}

function Card(props: PropsWithChildren<CardProps>) {
  const { children, className } = props;

  const classes = ["ui--card", className];

  return (
    <div className={classes.join(" ")} onClick={props.onClick}>
      <div aria-selected={props.selected} className="content">
        {children}
      </div>
    </div>
  );
}

export default Card;
