import { useCallback, useState } from "react";
import { CustomerSummaryStatistic, CustomersApi } from "../../api";
import { logErrorSilently } from "../../services/alerts";
import { isHttpOk } from "../../services/api";
import { getApiConfig } from "../../state/configuration";
import { useDeploymentConfig } from "../../state/deployment";
import { useTimeLocationFilters } from "../../state/globalFilters";
import Card from "../cards/Card";
import { Modal, useModal } from "../modals/Modal";
import { Avatar } from "../widgets/Avatar";
import { PaginatedTable } from "../widgets/PaginatedTable";
import { CustomerDataTable } from "./CustomerDataTable";

import "./TopVisitors.scss";

function TopVisitors() {
  const { config } = useDeploymentConfig();
  const filters = useTimeLocationFilters();
  const [isEmpty, setIsEmpty] = useState(true);

  const dataSource = useCallback(async () => {
    function getData() {
      const api = new CustomersApi(getApiConfig());
      return api.getByVisits({ limit: 5, ...filters });
    }

    try {
      const result = await getData();
      if (isHttpOk(result)) {
        const count = result.data.length;
        setIsEmpty(count === 0);
        return {
          count: count,
          items: result.data,
        };
      } else {
        setIsEmpty(true);
        logErrorSilently(result);
      }
    } catch (err) {
      logErrorSilently(err);
    }
    return {
      count: 0,
      items: [],
    };
  }, [filters, setIsEmpty]);

  const tableHeaders = [{ title: "NAME" }, { title: `${config.translations.store.singular.toUpperCase()} VISITS` }];

  const modalId = "visitors";
  const { openModal, closeModal } = useModal(modalId);

  const customOptions = [{ label: "Store Visits", value: CustomerSummaryStatistic.NmOfStoreVisits }];

  return (
    <Card className="top-visitors">
      <PaginatedTable
        mainHeader={
          <header>
            <h1>Top Visitors</h1>
          </header>
        }
        tableClassName="with-image"
        minLoadingTimeMs={200}
        initialPageSize={5}
        hidePagination={true}
        tableDataSource={dataSource}
        tableHeaders={tableHeaders}
        tableRowFactory={(item, index) => {
          return [
            <span>
              <Avatar name={item.name || "Anonymous"} image={item.pictureUrl} />
              {item.name}
            </span>,
            item.value,
          ];
        }}
      >
        {!isEmpty && (
          <div className="show-all" onClick={() => openModal()}>
            Show all
          </div>
        )}
      </PaginatedTable>
      <Modal id={modalId} onRequestClose={closeModal} className="wide-modal">
        <section>
          <CustomerDataTable
            selectedStatistic={CustomerSummaryStatistic.NmOfStoreVisits}
            customOptions={customOptions}
          />
        </section>
      </Modal>
    </Card>
  );
}

export default TopVisitors;
