import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { toast as alert } from "react-toastify";
import { PasswordApi, ResetPasswordRequest } from "../../../api";
import { FormInput as Input } from "../../../app/forms/Input";
import { AlertContent, UnexpectedError, ValidationMessage } from "../../../app/widgets/Alerts";
import { isHttpOk } from "../../../services/api";
import { ResetPasswordValidator } from "../../../services/validators";
import { getApiConfig } from "../../../state/configuration";

function useEmailAndCodeFromLocation() {
  const location = useLocation();
  const map = new URLSearchParams(location.search);
  if (map.has("code") && map.has("email")) {
    return {
      email: map.get("email") || "",
      code: map.get("code") || "",
    };
  }
  return { email: null, code: null };
}

export interface ResetPasswordModel {
  password: string;
  confirmPassword: string;
}

export function ResetPasswordForm(props: { forCustomer: boolean }) {
  const history = useHistory();
  const [isWorking, setWorking] = useState(false);
  const [isValidToken, setValidToken] = useState(true);
  const { email, code } = useEmailAndCodeFromLocation();
  const { register, handleSubmit, trigger, watch, formState } = useForm<ResetPasswordModel>({
    resolver: yupResolver(ResetPasswordValidator),
  });

  useEffect(() => {
    const verifyResetPasswordToken = async (token: string | null, email: string | null) => {
      if (token === null || email === null) {
        return false;
      }
      const config = getApiConfig();
      const api = new PasswordApi(config);

      try {
        const response = await api.verifyToken({
          verifyTokenRequest: {
            email: email,
            token: token,
            tokenPurpose: "ResetPassword",
          },
        });
        return isHttpOk(response) ? response.data : false;
      } catch (error) {
        alert.error(<UnexpectedError />);
        return false;
      }
    };
    verifyResetPasswordToken(code, email).then((isValid) => {
      setValidToken(isValid);
      if (!isValid) {
        alert.error(<ValidationMessage message="Reset pasword link is invalid!" />);
      }
    });
  }, [code, email, setValidToken]);

  const password = watch("password");
  useEffect(() => {
    if (formState.isSubmitted) {
      trigger("confirmPassword");
    }
  }, [password, formState.isSubmitted, trigger]);

  const onSubmit = useCallback(
    async (data: ResetPasswordRequest) => {
      if (isWorking || email === null || code === null) {
        return;
      }
      setWorking(true);
      const config = getApiConfig();
      const api = new PasswordApi(config);

      try {
        const response = await api.resetPassword({
          resetPasswordRequest: {
            email: email,
            token: code,
            password: data.password,
          },
        });

        if (isHttpOk(response)) {
          alert.info(<AlertContent message="Password was successfully reset. Please login with your new password." />, {
            onClose: () => {
              if (props.forCustomer || response.data.isCustomer) {
                history.replace("/app");
              } else {
                history.replace("/");
              }
            },
          });
        } else {
          if (typeof response.data === "string") {
            alert.warn(<AlertContent message={response.data} />);
          } else {
            alert.error(<UnexpectedError />);
          }
          setWorking(false);
        }
      } catch (error) {
        alert.error(<UnexpectedError />);
        setWorking(false);
      }
    },
    [props.forCustomer, isWorking, history, email, code]
  );

  if (email === null || code === null || !isValidToken) {
    if (props.forCustomer) {
      return <Redirect to="/app" />;
    } else {
      return <Redirect to="/" />;
    }
  } else {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <Input
            name="password"
            type="password"
            errors={formState.errors}
            required={true}
            placeholder="&nbsp;New password"
            register={register}
          />
        </div>
        <div className="form-row">
          <Input
            name="confirmPassword"
            type="password"
            errors={formState.errors}
            required={true}
            placeholder="&nbsp;Repeat password"
            register={register}
          />
        </div>
        <button type="submit" className="primary" disabled={isWorking}>
          Set Password
        </button>
      </form>
    );
  }
}
