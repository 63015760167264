import { useCallback } from "react";
import { FieldError } from "react-hook-form";
import { MultiValue } from "react-select";
import { Location, LocationsApi, LocationView } from "../../../../../api";
import { isHttpOk } from "../../../../../services/api";
import { useEventHandler } from "../../../../../services/hooks";
import { getApiConfig } from "../../../../../state/configuration";
import { useDeploymentConfig } from "../../../../../state/deployment";
import { Option, SelectAsync } from "../../../../forms/Select";

export function LocationInput(props: {
  error?: FieldError;
  prefix?: string;
  value?: Location[];
  includeBeacons?: boolean;
  onChange: (value: Location[]) => void;
}) {
  const { config } = useDeploymentConfig();
  const onChange = useEventHandler(props.onChange);

  const toOptions = useCallback(
    (item: Location | LocationView): Option => {
      const typeName =
        typeof item.cityId === "number"
          ? "City"
          : typeof item.areaId === "number"
          ? "Area"
          : typeof item.beaconId === "string"
          ? "Beacon"
          : typeof item.countryId === "number"
          ? "Country"
          : typeof item.storeId === "number"
          ? config.translations.store.singular
          : "";
      return {
        label: item.name || "",
        description: typeName,
        value: JSON.stringify(item, ["name", "areaId", "beaconId", "cityId", "countryId", "storeId"], ""),
      };
    },
    [config.translations.store.singular]
  );

  const locations = (props.value || []).map(toOptions);

  const loadOptions = useCallback(
    async (input: string) => {
      const config = getApiConfig();
      const api = new LocationsApi(config);
      const response = await api.getLocations({
        search: input,
        excludeBeacons: !props.includeBeacons,
      });
      if (isHttpOk(response)) {
        return response.data.map(toOptions);
      }
      return [];
    },
    [toOptions, props.includeBeacons]
  );

  const handleChange = useCallback(
    (values: MultiValue<Option>) => {
      const selection = values.map((v) => JSON.parse(v.value as string)) as Location[];
      onChange.current(selection);
    },
    [onChange]
  );

  return (
    <div className="input-row single">
      <span className="prefix">{props.prefix || "In location"}</span>
      <SelectAsync
        type="form"
        placeholder="Select location"
        errorLabel="Location"
        isMulti={true}
        required={true}
        value={locations}
        loadOptions={loadOptions}
        error={props.error}
        onChange={handleChange}
      />
    </div>
  );
}
