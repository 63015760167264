import flyd from "flyd";
import { isEqual, isFunction, noop } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";

// eslint-disable-next-line no-console
const logFunction = false ? console.log : noop;

export type S<T> = flyd.Stream<T> & { _debugName?: string };

export function listen<T>(state: S<T>, onChange: (value: T) => void, ignoreDeepEqualChanges?: boolean) {
  return flyd.scan<T, T>(
    (prev, next) => {
      const ignoreChange = ignoreDeepEqualChanges ? isEqual(prev, next) : prev === next;
      if (ignoreChange) {
        return next;
      } else {
        onChange(next);
        return next;
      }
    },
    state(),
    state
  );
}

export function createStore<T>(initial: T | (() => T), name?: string): S<T> {
  const store = (() => {
    if (isFunction(initial)) {
      return flyd.stream(initial());
    } else {
      return flyd.stream(initial);
    }
  })();

  if (name !== undefined) {
    Object.defineProperty(store, "_debugName", {
      writable: false,
      enumerable: false,
      value: name,
    });
  }

  return store;
}

type SetStateMethod<T> = (value: T | ((prev: T) => T)) => void;
type MergeStateMethod<T> = (value: Partial<T> | ((prev: T) => Partial<T> | undefined)) => void;

function createMutators<T>(store: S<T>) {
  const logger =
    store._debugName !== undefined ? (value: T) => logFunction("Setting", store._debugName, "state to", value) : noop;

  const setState: SetStateMethod<T> = (value) => {
    if (isFunction(value)) {
      const prev = store();
      const next = value(prev);
      logger(next);
      store(next);
    } else {
      logger(value);
      store(value);
    }
  };

  const mergeState: MergeStateMethod<T> = (value) => {
    const previous = store();
    if (isFunction(value)) {
      const mutation = value(previous);
      if (mutation !== undefined && mutation !== previous) {
        const next = {
          ...previous,
          ...mutation,
        };
        logger(next);
        store(next);
      }
    } else {
      const next = {
        ...previous,
        ...value,
      };
      logger(next);
      store(next);
    }
  };

  return { setState, mergeState };
}

export function useStore<T>(store: S<T>, component?: string): [T, SetStateMethod<T>, MergeStateMethod<T>] {
  const [componentState, setComponentState] = useState<T>(() => store());
  useEffect(() => {
    const listener = listen(store, (value) => {
      if (component !== undefined) {
        logFunction("Propagating", store._debugName, "state to", component, "component");
      }
      setComponentState(value);
    });
    return () => {
      listener.end(true);
    };
  }, [store, component]);

  const { setState, mergeState } = useMemo(() => createMutators(store), [store]);

  return [componentState, setState, mergeState];
}

export function useMappedStore<I, O>(
  store: S<I>,
  mapFunction: (m: I) => O,
  skipDeepEqualChanges?: boolean,
  component?: string
): O {
  const mapFunctionRef = useRef(mapFunction);
  if (mapFunctionRef.current !== mapFunction) {
    throw new Error(
      component !== undefined
        ? `Changing mapping function is not supported in ${component}!`
        : `Changing mapping function is not supported!`
    );
  }

  const [proxy, setProxy] = useState(() => {
    const initial = mapFunction(store());
    if (component !== undefined) {
      logFunction("Initializing mapped", store._debugName, "state for", component, "component:", initial);
    }
    return initial;
  });
  useEffect(() => {
    const mapped = store.map(mapFunctionRef.current);
    const listener = listen(
      mapped,
      (value) => {
        if (component !== undefined) {
          logFunction("Propagating mapped ", store._debugName, "state to", component, "component:", value);
        }
        setProxy(value);
      },
      skipDeepEqualChanges
    );
    return () => {
      mapped.end(true);
      listener.end(true);
    };
  }, [store, component, skipDeepEqualChanges]);

  return proxy;
}
