import React, { useEffect, useRef } from "react";
import { useStateEx } from "../../services/hooks";

interface Props {
  value: string;
  ignoreClicks: boolean;
  initialEditable?: boolean;
  tooltip?: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

export function EditableTitle({
  placeholder = "Edit title",
  value,
  ignoreClicks,
  initialEditable = false,
  tooltip,
  onChange,
  ...attributes
}: Props) {
  const { state, mergeState } = useStateEx({
    editable: initialEditable,
    value: value,
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (state.editable) {
      inputRef.current?.focus();
    }
  }, [state.editable]);

  useEffect(() => {
    if (spanRef.current && inputRef.current) {
      const spanRect = spanRef.current.getBoundingClientRect();
      const spanWidth = Math.max(10, spanRect.width + 5);
      inputRef.current.style.width = spanWidth + "px";
    }
  }, [state.value, state.editable]);

  const commitChange = () => {
    mergeState({
      editable: false,
    });
    if (state.value !== value) {
      onChange(state.value);
    }
  };

  if (!state.editable) {
    const html = state.value.length === 0 ? "&nbsp;" : state.value;
    return (
      <span
        dangerouslySetInnerHTML={{ __html: html }}
        ref={spanRef}
        className="editable-title"
        data-for="appTooltip"
        data-tip={tooltip}
        onClick={(e) => {
          if (ignoreClicks) {
            e.preventDefault();
          } else {
            mergeState({ editable: true });
          }
        }}
      ></span>
    );
  } else {
    const text = state.value.length === 0 ? placeholder : state.value;
    return (
      <span ref={spanRef} className="editable-title editing">
        {text}
        <input
          {...attributes}
          placeholder={placeholder}
          ref={inputRef}
          type="text"
          value={state.value}
          onBlur={commitChange}
          onKeyDown={(e) => {
            if (!e.altKey && !e.ctrlKey && e.key === "Enter") {
              commitChange();
            }
          }}
          onChange={(e) => {
            mergeState({
              value: e.target.value,
            });
          }}
        />
      </span>
    );
  }
}
