import { cloneDeep, keyBy } from "lodash";
import { useForm } from "react-hook-form";
import { BeaconVisitCountView, StoreFloorModel, StoreLayoutModel } from "../../../api";
import { FloorplanUpload } from "../../../app/forms/FloorplanUpload";
import { ImageUploadArea, ImageUploadButton } from "../../../app/forms/ImageUpload";
import { AddNew, Plus } from "../../../app/icons/Icons";
import { EditableTitle } from "../../../app/widgets/EditableTitle";
import { Tab, TabList, TabPanel, Tabs } from "../../../app/widgets/tabs";
import { useStateEx } from "../../../services/hooks";
import { getTooltip } from "../../../services/tooltip";
import { MapWithBeacons } from "./MapWithBeacons";

import "./FloorplanOverview.scss";

type Props = {
  model: StoreLayoutModel;
  allBeacons: readonly BeaconVisitCountView[];
  onChange: (model: StoreLayoutModel) => void;
};

export function FloorplanOverview(props: Props) {
  const { state, mergeState } = useStateEx({ activeTab: 0 });
  const { control, handleSubmit } = useForm<{ layout: string[] }>({
    defaultValues: {
      layout: [],
    },
  });

  const existingPlacements = keyBy(
    (props.model.floors || []).flatMap((f) =>
      (f.beacons || []).map((b) => ({
        existingPlacement: f.title || "",
        id: b.id || "",
      }))
    ),
    (i) => i.id
  );

  const beaconsWithPlacement = props.allBeacons.map((b) => ({
    id: b.id || "",
    name: b.name || "",
    areaName: b.areaName || "",
    nmVisits: b.nmVisits,
    existingFloorPlacement: existingPlacements[b.id || ""]?.existingPlacement || "",
  }));

  const renderTabHeader = (floor: StoreFloorModel, index: number) => {
    const setTitle = (title: string) => {
      const model = cloneDeep(props.model);
      model.floors![index].title = title;
      props.onChange(model);
    };

    const hasImage = floor.imageUrl && floor.imageUrl.length > 0;

    return (
      <Tab className={{ empty: !hasImage }} key={`${index}_${floor.title}}`}>
        <EditableTitle
          value={floor.title || ""}
          ignoreClicks={state.activeTab !== index}
          tooltip={state.activeTab !== index ? getTooltip("Click to open") : getTooltip("Click to edit")}
          onChange={setTitle}
        />
      </Tab>
    );
  };

  const renderTabPanel = (floor: StoreFloorModel, index: number) => {
    const hasImage = typeof floor.imageUrl === "string" && floor.imageUrl.length;

    const setLayout = (layout: string) => {
      const model = cloneDeep(props.model);
      model.floors![index].imageUrl = layout;
      props.onChange(model);
    };

    const updateFloor = (floor: StoreFloorModel) => {
      const model = cloneDeep(props.model);
      const used = floor.beacons!.map((e) => e.id);
      model.floors?.forEach((floor) => {
        floor.beacons = floor.beacons?.filter((b) => !used.includes(b.id));
      });
      model.floors![index] = floor;
      props.onChange(model);
    };

    const deleteFloor = () => {
      if (floors.length <= 1) return;

      const model = cloneDeep(props.model);
      model.floors!.splice(index, 1);
      props.onChange(model);
    };

    return (
      <TabPanel key={index}>
        <div className="toolbar">
          {floors.length > 1 ? (
            <button className="primary delete" onClick={deleteFloor} type="button">
              Delete
            </button>
          ) : null}
          {hasImage ? (
            <ImageUploadButton
              name={`layout.${index}` as "layout.0"}
              control={control}
              title="Change"
              skipResizing={true}
              onUploaded={handleSubmit((model) => setLayout(model.layout[index]))}
            />
          ) : null}
        </div>
        {hasImage ? (
          <MapWithBeacons model={floor} allBeacons={beaconsWithPlacement} onChanged={updateFloor} />
        ) : (
          <form className="layout-uploader empty">
            <ImageUploadArea
              name={`layout.${index}` as "layout.0"}
              control={control}
              skipResizing={true}
              onUploaded={handleSubmit((model) => setLayout(model.layout[index]))}
              placeholderIcon={<AddNew />}
              placeholderText={
                <span>
                  {`Add floorplan for ${props.model.storeName} (${floor.title})`}
                  <br />
                  {`(ideal resolution is ~1200 \u2715 700 pixels)`}
                </span>
              }
            />
          </form>
        )}
      </TabPanel>
    );
  };

  const addFloor = () => {
    const model = cloneDeep(props.model);
    model.floors!.push({
      title: model.floors!.length + 1 + ". floor",
      beacons: [],
      imageUrl: null,
    });
    props.onChange(model);
  };

  const floors = props.model.floors || [];
  const tabs = floors.map(renderTabHeader);
  const panels = floors.map(renderTabPanel);

  return (
    <Tabs
      className="floorplan-overview"
      selectedIndex={Math.min(state.activeTab, floors.length - 1)}
      onSelect={(selected, last) => {
        if (last !== selected) {
          mergeState({ activeTab: selected });
        }
        return true;
      }}
    >
      <FloorplanUpload
        storeId={props.model.id}
        onSuccess={(model) => {
          props.onChange(model);
        }}
      />
      <TabList>
        {tabs}
        <Plus className="add" onClick={addFloor} />
      </TabList>
      {panels}
    </Tabs>
  );
}
