import { useCallback, useState } from "react";
import { ProductsApi } from "../../api";
import { logErrorSilently } from "../../services/alerts";
import { isHttpOk } from "../../services/api";
import { useMoneyFormatter } from "../../services/format";
import { getApiConfig } from "../../state/configuration";
import { useTimeLocationFilters } from "../../state/globalFilters";
import Card from "../cards/Card";
import { Modal, useModal } from "../modals/Modal";
import { PaginatedTable } from "../widgets/PaginatedTable";
import { ProductsTable } from "./ProductsTable";

import "./TopProducts.scss";

export function Products(props: { id: number }) {
  const url = "/dummy/articles/" + props.id.toString() + ".jpg";
  return <img alt="item" src={url} />;
}

function TopProducts(props: { showFooter?: boolean }) {
  const { formatMoney } = useMoneyFormatter();
  const filters = useTimeLocationFilters();
  const modalId = "products";
  const { openModal, closeModal } = useModal(modalId);
  const [isEmpty, setIsEmpty] = useState(true);

  const dataSource = useCallback(async () => {
    const api = new ProductsApi(getApiConfig());
    const result = await api.getByRevenue({ limit: 5, ...filters });

    if (isHttpOk(result)) {
      setIsEmpty(result.data.count === 0);
      return result.data;
    } else {
      setIsEmpty(true);
      logErrorSilently(result);
      return {
        count: 0,
        items: [],
      };
    }
  }, [filters, setIsEmpty]);

  const tableHeaders = [{ title: "IMAGE" }, { title: "PRODUCT NAME" }, { title: "REVENUE" }];

  const footer = (
    <div className="show-all" onClick={() => openModal()}>
      Show all
    </div>
  );

  return (
    <Card className="top-products-table">
      <PaginatedTable
        mainHeader="Top Products"
        tableClassName="with-image"
        minLoadingTimeMs={200}
        initialPageSize={5}
        hidePagination={true}
        tableDataSource={dataSource}
        tableHeaders={tableHeaders}
        tableRowFactory={(item, index) => {
          return [<Products id={index + 1} />, item.name, formatMoney(item.revenueGenerated)];
        }}
      >
        {props.showFooter && !isEmpty && footer}
      </PaginatedTable>
      <Modal id={modalId} onRequestClose={closeModal} className="wide-modal">
        <section>
          <ProductsTable />
        </section>
      </Modal>
    </Card>
  );
}

export default TopProducts;
