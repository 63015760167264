import { Configuration, ConfigurationParameters } from "../api";
import { getAccessToken } from "./authentication";

export function getApiHost() {
  let apiUrl = process.env.REACT_APP_API_URL;
  if (!apiUrl || apiUrl === ".") {
    apiUrl = `${window.location.protocol}//${window.location.host}`;
  }
  return apiUrl;
}

export function getApiConfig() {
  const token = getAccessToken();
  const parameters: ConfigurationParameters = {
    basePath: getApiHost(),
    accessToken: token,
  };
  return new Configuration(parameters);
}
