import { useCallback, useLayoutEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { CustomerTrackingGridView, TimeframeType, TrackingApi } from "../../api";
import { PaginatedTable, PaginatedTableApi } from "../../app/widgets/PaginatedTable";
import { useLocalizedText } from "../../services/localization";
import { getApiConfig } from "../../state/configuration";

import "./ContactUsers.scss";

export type ContactUserFilters = {
  beacons?: string[];
  rangeType: TimeframeType;
  rangeStart?: IsoDateTimeString;
  rangeEnd?: IsoDateTimeString;
  startHour?: number;
  endHour?: number;
};

export function ContactUsers(props: { filters: ContactUserFilters }) {
  const texts = useLocalizedText();
  const tableApi = useRef<PaginatedTableApi>(null);

  useLayoutEffect(() => {
    if (props.filters.rangeType === TimeframeType.Now) {
      const refreshTimer = window.setInterval(() => {
        if (tableApi.current) {
          tableApi.current.refreshPage();
        }
      }, 1000);
      return () => {
        window.clearInterval(refreshTimer);
      };
    }
  }, [props.filters.rangeType]);

  const loadPage = useCallback(
    async (skip: number, limit: number) => {
      const config = getApiConfig();
      const api = new TrackingApi(config);
      const result = await api.getUserTracking({
        ...props.filters,
      });

      window.setTimeout(() => {
        ReactTooltip.rebuild();
      }, 500);

      return result.data;
    },
    [props]
  );

  const renderCells = useCallback((item: CustomerTrackingGridView, i: number) => {
    return [<span>{item.id}</span>, <span>{item.userName}</span>, <span>{item.platformDeviceId}</span>];
  }, []);

  return (
    <PaginatedTable
      apiRef={tableApi}
      className="contact-user-table"
      mainHeader={
        <header>
          <h1>{texts.contactUsersTable.title}</h1>
        </header>
      }
      tableHeaders={[
        texts.contactUsersTable.items.id,
        texts.contactUsersTable.items.username,
        texts.contactUsersTable.items.platformDeviceId,
      ]}
      tableDataSource={loadPage}
      tableRowFactory={renderCells}
      minLoadingTimeMs={200}
      hidePagination={true}
      initialPageSize={100}
    />
  );
}
