import { MouseEventHandler } from "react";
import * as api from "../../api";
import { isProvided } from "../../services/objects";
import { highlight } from "../widgets/HighlightedString";
import Card from "./Card";

import "./ProductCard.scss";

interface ComponentProps extends api.ProductView {
  highlightedText?: string;
  onEditClick?: MouseEventHandler;
  onDeleteClick?: MouseEventHandler;
}

function PriceText(props: ComponentProps) {
  const prices = [props.fromPrice, props.toPrice].filter(isProvided);
  if (prices.length === 0) {
    return <></>;
  } else {
    return <p className="price">{`Price: ${prices.join(" - ")} ${props.currency}`}</p>;
  }
}

export function ProductCard(props: ComponentProps) {
  const name = props.name;
  const description = props.description;
  const imageUrl = props.imageUrl;
  const categoryImageUrl = props.categoryImageUrl;
  const imageNode = imageUrl && <img alt="Product" src={imageUrl} />;
  const categoryImageNode = categoryImageUrl && <img alt="Category" src={categoryImageUrl} />;

  return (
    <Card className="product-card">
      <div className="figure">{imageNode}</div>
      <div className="description">
        <div className="main-area">
          <div className="texts col-11">
            <h1 className="name-text">{highlight(name, props.highlightedText)}</h1>
            <h3 className="description-text">{highlight(description, props.highlightedText)}</h3>
          </div>
          <div className="category-image col-1">{categoryImageNode}</div>
        </div>
        <PriceText {...props}></PriceText>
        <div className="update-buttons">
          <button onClick={props.onEditClick} className="edit">
            EDIT
          </button>
          <button onClick={props.onDeleteClick} className="delete">
            DELETE
          </button>
        </div>
      </div>
    </Card>
  );
}
