import React, { useRef, useCallback } from "react";
import { AxiosResponse } from "axios";
import { toast as alert } from "react-toastify";
import Loading from "../app/widgets/Loading";
import { Result } from "../app/modals/utils";
import { Modal, useModal } from "../app/modals/Modal";
import { ServiceResult } from "../services/types";
import { AlertContent, ServerUnavailable } from "../app/widgets/Alerts";
import Card from "../app/cards/Card";
import { Download } from "../app/icons/Icons";
import { PaginatedCards, PaginatedCardsApi } from "../app/widgets/PaginatedCards";
import { getApiConfig } from "../state/configuration";
import { BlogPostsApi, BlogPostModel, BlogPostView } from "../api";
import { useGlobalSearch } from "../state/globalSearch";
import { isHttpOk } from "../services/api";
import { PageHeader } from "../app/widgets/PageHeaders";
import { useStateEx } from "../services/hooks";
import { confirmDialog } from "../services/alerts";
import { BlogPostCard } from "../app/cards/BlogPostCard";
import EditBlogPost from "../app/modals/EditBlogPost";
import { isProvided } from "../services/objects";

import "./BlogPosts.scss";

type State = {
  model: BlogPostModel | null;
  isNew: boolean;
  totalCount: number;
  formModified: boolean;
};

function BlogPosts() {
  const pageApi = useRef<PaginatedCardsApi>(null);
  const { search } = useGlobalSearch();
  const { openModal, closeModal } = useModal();
  const { state, mergeState } = useStateEx<State>({
    totalCount: 0,
    model: null,
    isNew: true,
    formModified: false,
  });
  const changed = () => mergeState({ formModified: true });

  const openAddModal = () => {
    mergeState({ isNew: true });
    openModal();
  };

  const handleCancel = () => {
    if (state.formModified) confirmDialog("Form has unsaved changes. Discard all?", closeModalAndReset);
    else closeModalAndReset();
  };

  const closeModalAndReset = () => {
    mergeState({ model: null, formModified: false });
    closeModal();
  };

  const load = useCallback(
    (skip: number, limit: number) => {
      const config = getApiConfig();
      const api = new BlogPostsApi(config);
      return api.get({
        search: search,
        skip: skip,
        limit: limit,
      });
    },
    [search]
  );

  const loadingCardFactory = (key: number) => {
    return (
      <Card className="blog-post-card loading" key={key}>
        <Loading />
      </Card>
    );
  };

  const loadedCardFactory = (model: BlogPostView, key: number) => {
    const id = model.id;

    const editBlogPost = async () => {
      const config = getApiConfig();
      const api = new BlogPostsApi(config);

      try {
        const response = await api.getOne({ id: id });

        if (isHttpOk(response) && response.data) {
          mergeState({
            model: response.data,
            isNew: false,
          });

          openModal();
        }
      } catch (error) {
        alert.error(<ServerUnavailable diagnosticError={error} />);
      }
    };

    const deleteBlogPost = async () => {
      const onConfirm = async () => {
        const config = getApiConfig();
        const api = new BlogPostsApi(config);

        try {
          var result = await api._delete({ id: id });

          if (isHttpOk(result)) {
            alert.success(<AlertContent message="Blog Post deleted." />);
            if (pageApi.current) pageApi.current.refreshPage();

            return Result.Ok;
          }
        } catch (error) {
          alert.error(<ServerUnavailable diagnosticError={error} />);
        }
      };

      confirmDialog("Delete Blog Post?", onConfirm);
    };

    return (
      <BlogPostCard
        key={key}
        highlightedText={search}
        onDeleteClick={deleteBlogPost}
        onEditClick={editBlogPost}
        {...model}
      />
    );
  };

  const saveBlogPost = async (model: BlogPostModel) => {
    const config = getApiConfig();
    const api = new BlogPostsApi(config);

    try {
      let message: string;
      let result: AxiosResponse<ServiceResult<BlogPostModel>>;

      if (!model.id) {
        message = `New Blog Post created.`;

        result = await api.create({
          blogPostModel: model,
        });
      } else {
        message = `Blog Post updated.`;

        result = await api.update({
          id: model.id,
          blogPostModel: model,
        });
      }

      if (isHttpOk(result)) {
        closeModalAndReset();
        alert.success(<AlertContent message={message} />);
        if (pageApi.current) pageApi.current.refreshPage();
        return Result.Ok;
      } else {
        return Result.Invalid(result);
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }

    return Result.Invalid();
  };

  return (
    <div className="page-content blog-posts">
      <Modal onRequestClose={handleCancel}>
        <EditBlogPost
          initial={state.model}
          onSave={saveBlogPost}
          onCancel={handleCancel}
          onChange={changed}
          existingBlogPost={isProvided(state.model)}
        />
      </Modal>
      <PageHeader title="Blog Posts" subTitle={`Found ${state.totalCount} blog posts`}>
        <div className="toolbar">
          <button className="new" onClick={openAddModal}>
            + New Blog Post
          </button>
          <button className="download">
            <Download />
          </button>
        </div>
      </PageHeader>
      <PaginatedCards
        apiRef={pageApi}
        dataSource={load}
        minLoadingTimeMs={200}
        onAddNew={openAddModal}
        hideAddNew={true}
        hidePagination={false}
        loadingCardFactory={loadingCardFactory}
        loadedCardFactory={loadedCardFactory}
        onTotalCountChange={(count) => mergeState({ totalCount: count })}
      />
    </div>
  );
}

export default BlogPosts;
