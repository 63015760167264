import "./Circles.scss";

const create = (className: string, color: string) => ({
  class: className,
  color: color,
  icon: (
    <svg
      className={"circle-icon " + className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 16"
    >
      <circle cx="8" cy="8" r="6.5" stroke={color} fill="none" strokeWidth="3" />
    </svg>
  ),
});

export const Blue = create("blue", "#549dba");
export const Red = create("red", "#f55e7b");
export const Yellow = create("yellow", "#FFDA82");
export const Purple = create("purple", "#a4a1fc");
export const Green = create("green", "#4ad991");
export const Lightblue = create("lightblue", "#8fcae2");
export const Pink = create("pink", "#be71a3");
export const Orange = create("orange", "#f8b112");
export const Golden = create("golden", "#e2bc8f");
export const Black = create("black", "#000000");
export const White = create("white", "#E3E3E5");

export const All = [Blue, Red, Yellow, Purple, Green, Lightblue, Pink, Orange, Golden, Black, White];
