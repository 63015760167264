import { useCallback, useState } from "react";
import { StoresApi } from "../../api";
import { logErrorSilently } from "../../services/alerts";
import { isHttpOk } from "../../services/api";
import { getApiConfig } from "../../state/configuration";
import { useDeploymentConfig } from "../../state/deployment";
import { useTimeLocationFilters } from "../../state/globalFilters";
import Card from "../cards/Card";
import { Modal, useModal } from "../modals/Modal";
import { PaginatedTable } from "../widgets/PaginatedTable";
import { Metric, StoresTable } from "./StoresTable";

import "./FavoriteStores.scss";

function FavoriteStores(props: { showFooter?: boolean }) {
  const deployment = useDeploymentConfig();
  const filters = useTimeLocationFilters();
  const [isEmpty, setIsEmpty] = useState(true);

  const dataSource = useCallback(async () => {
    const api = new StoresApi(getApiConfig());
    const result = await api.getByFavoriteCustomers({
      size: 5,
      includeAllStores: true,
      ...filters,
    });

    if (isHttpOk(result)) {
      setIsEmpty(result.data.count === 0);
      return result.data;
    } else {
      setIsEmpty(true);
      logErrorSilently(result);
      return {
        count: 0,
        items: [],
      };
    }
  }, [filters, setIsEmpty]);

  const headers = [{ title: "STORE" }, { title: deployment.config.disablePurchaseHistories ? "USERS" : "CUSTOMERS" }];

  const modalId = "top-favorite-stores";
  const { openModal, closeModal } = useModal(modalId);

  const customOptions = [{ label: "Most Favourited", value: Metric.CustomerFavorite }];

  return (
    <Card className="favorite-stores">
      <PaginatedTable
        mainHeader={`Favorite ${deployment.config.translations.store.plural.toLowerCase()}`}
        minLoadingTimeMs={200}
        initialPageSize={5}
        hidePagination={true}
        tableDataSource={dataSource}
        tableHeaders={headers}
        tableRowFactory={(item) => {
          return [item.name, item.value];
        }}
      >
        {props.showFooter && !isEmpty && (
          <div className="show-all" onClick={() => openModal()}>
            Show all
          </div>
        )}
      </PaginatedTable>
      <Modal id={modalId} onRequestClose={closeModal} className="wide-modal">
        <section>
          <StoresTable selectedMetric={Metric.CustomerFavorite} includeAllStores={true} customOptions={customOptions} />
        </section>
      </Modal>
    </Card>
  );
}

export default FavoriteStores;
