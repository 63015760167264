import { useEffect, useState } from "react";

export default function AppReg(props: { percentage?: number }) {
  const percentage = props.percentage === undefined ? 1 : props.percentage;
  if (percentage > 1) {
    throw new Error("percentage should be less than 1!");
  }

  const totalLength = 2 * 32 * Math.PI;
  const fullLength = Math.ceil(totalLength * percentage);
  const [stroke, setStroke] = useState(`0 2000`);

  useEffect(() => {
    const dashes = [fullLength, 2000].join(" ");
    setStroke(dashes);
  }, [fullLength]);

  return (
    <svg width="55" height="55" viewBox="0 0 67 67">
      <defs>
        <clipPath id="clip-path">
          <path
            id="Path_259"
            d="M33.8-74.394A33.8,33.8,0,0,1,0-108.2,33.8,33.8,0,0,1,33.8-142a33.8,33.8,0,0,1,33.8,33.8A33.8,33.8,0,0,1,33.8-74.394Zm0-4.761A29.042,29.042,0,0,0,62.845-108.2,29.042,29.042,0,0,0,33.8-137.239,29.042,29.042,0,0,0,4.761-108.2,29.042,29.042,0,0,0,33.8-79.155Z"
            transform="translate(0 142)"
            fill="none"
            clipRule="evenodd"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <path id="Path_258" d="M-347-104.082H148.144V-534H-347Z" transform="translate(347 534)" fill="none" />
        </clipPath>
        <clipPath id="clip-path-3">
          <rect id="Rectangle_136" width="69" height="69" transform="translate(0.09 0.134)" fill="none" />
        </clipPath>
      </defs>
      <circle r="32" cx="33.5" cy="33.5" stroke="#EDEDFE" strokeWidth="3" fill="none" />
      <circle
        style={{
          transform: "rotate(-90deg)",
          transformOrigin: "center center",
          transition: "stroke-dasharray 1s ease",
        }}
        r="32"
        cx="33.5"
        cy="33.5"
        strokeDasharray={stroke}
        stroke="#a3a1fb"
        strokeWidth="3"
        fill="none"
      />
      <g transform="translate(-511.667 -212)">
        <path
          id="ic_assignment_turned_in_24px"
          d="M22.556,3.444H17.447a3.652,3.652,0,0,0-6.893,0H5.444A2.452,2.452,0,0,0,3,5.889V23a2.452,2.452,0,0,0,2.444,2.444H22.556A2.452,2.452,0,0,0,25,23V5.889A2.452,2.452,0,0,0,22.556,3.444ZM14,3.444a1.222,1.222,0,1,1-1.222,1.222A1.226,1.226,0,0,1,14,3.444ZM11.556,20.556,6.667,15.667,8.39,13.943,11.556,17.1,19.61,9.042l1.723,1.736Z"
          transform="translate(532 232)"
          fill="#a3a1fb"
        />
      </g>
    </svg>
  );
}
