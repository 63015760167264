import React, { useContext } from "react";
import { MapContext } from "./MapProvider";
import useGeographies from "./useGeographies";

const Geographies = ({ geography, children, parseGeographies = undefined, className = "", ...restProps }) => {
  const { path, projection } = useContext(MapContext);
  const { geographies, outline, borders } = useGeographies({ geography, parseGeographies });

  return (
    <g className={`rsm-geographies ${className}`} {...restProps}>
      {geographies && geographies.length > 0 && children({ geographies, outline, borders, path, projection })}
    </g>
  );
};

export default Geographies;
