// eslint-disable-next-line import/no-internal-modules
import { yupResolver } from "@hookform/resolvers/yup";
import { find } from "lodash";
import { useCallback } from "react";
import { FieldError, useController, useForm } from "react-hook-form";
import { BeaconModel } from "../../api";
import { useEventHandler } from "../../services/hooks";
import { BeaconValidator } from "../../services/validators";
import { useAccessControl } from "../../state/authentication";
import { useIsSingleStore } from "../../state/globalFilters";
import Checkbox from "../forms/Checkbox";
import { FormInput, Input } from "../forms/Input";
import { FormSelect, Option, Select } from "../forms/Select";
import { FormModalProps, showErrors } from "./utils";

import "./EditBeacon.scss";

const INVALID_UUID_CHARS = /[^A-Fa-f0-9]+/g;
export function formatUUID(value: string) {
  if (value.length === 0) return value;
  value = value.replaceAll(INVALID_UUID_CHARS, "").toLowerCase();
  const parts = [];
  if (value.length > 8) {
    parts.push(value.substring(0, 8));
    value = value.substring(8);
    if (value.length > 4) {
      parts.push(value.substring(0, 4));
      value = value.substring(4);
      if (value.length > 4) {
        parts.push(value.substring(0, 4));
        value = value.substring(4);
        if (value.length > 4) {
          parts.push(value.substring(0, 4));
          value = value.substring(4);
          if (value.length > 12) {
            parts.push(value.substring(0, 12));
            value = "";
          }
        }
      }
    }
  }
  if (value.length > 0) {
    parts.push(value);
  }
  value = parts.join("-");
  return value;
}

type EditBeaconProps = FormModalProps<BeaconModel> & {
  stores: Option[];
  areas: Option[];
  hideDeleteButton: boolean;
};

function EditBeacon({ hideDeleteButton, onChange, onSave, onDelete, ...props }: EditBeaconProps) {
  const isSingleStore = useIsSingleStore();
  const onChangeHandler = useEventHandler(onChange);
  const onSaveHandler = useEventHandler(onSave);
  const onDeleteHandler = useEventHandler(onDelete);
  const permissions = useAccessControl();
  const canDeleteBeacons = permissions.beacons?.remove;

  var defaultValues = props.initial || {};
  // If suite is single store, set store
  if (isSingleStore && props.stores.length > 0) {
    const store = props.stores[0];
    if (typeof store.value === "number") {
      const defaultStore = { value: store.value, label: store.label };
      defaultValues = { ...defaultValues, store: defaultStore };
    }
  }

  const { register, handleSubmit, formState, control, setError, watch } = useForm<BeaconModel>({
    defaultValues: defaultValues,
    resolver: yupResolver(BeaconValidator),
  });

  const { field: areaField } = useController({
    name: "area",
    control: control,
  });

  watch(
    useCallback(() => {
      if (onChangeHandler.current) {
        onChangeHandler.current();
      }
    }, [onChangeHandler])
  );

  const handleSave = async (model: BeaconModel) => {
    const result = await onSaveHandler.current(model);
    if (!result.success) {
      showErrors(result, setError);
    }
  };

  const handleDelete = async () => {
    if (!onDeleteHandler.current) return;
    if (props.initial === null) return;
    const result = await onDeleteHandler.current(props.initial);
    if (!result.success) {
      showErrors(result, setError);
    }
  };

  const typeOptions = [
    { label: "beaconsmind Track", value: "beaconsmind Track" },
    { label: "Battery Beacon", value: "Battery Beacon" },
  ];

  if (props.initial?.beaconType && !typeOptions.find((v) => v.value === props.initial?.beaconType)) {
    typeOptions.push({
      label: props.initial.beaconType,
      value: props.initial.beaconType,
    });
  }

  const errors = formState.errors;

  return (
    <div className="beacon-form">
      <div className="header">
        <h1 className="title">Beacon Editor</h1>
      </div>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="form-row">
          <FormInput name="name" type="text" label="" placeholder="Name" errors={errors} register={register} />
        </div>
        <div className="form-row">
          <FormInput
            name="uuid"
            type="text"
            label=""
            placeholder="UUID"
            interceptValue={formatUUID}
            errors={errors}
            register={register}
          />
        </div>
        <div className="form-row major-minor">
          <FormInput
            className="major"
            name="major"
            type="text"
            label=""
            placeholder="Major"
            errors={errors}
            register={register}
          />
          <FormInput
            className="minor"
            name="minor"
            type="text"
            label=""
            placeholder="Minor"
            errors={errors}
            register={register}
          />
          <FormSelect
            required={true}
            className="type"
            type="form"
            name="beaconType"
            control={control}
            placeholder="Select Model"
            options={typeOptions}
          />
          <Checkbox className="cashbox" label="Cashbox" errorLabel="Cashbox" errors={errors} {...register("cashbox")} />
        </div>
        <div className="form-row">
          <FormInput
            name="description"
            type="multiline"
            label=""
            placeholder="Description"
            errors={errors}
            register={register}
          />
        </div>
        <div className="form-row max-visit-duration">
          <FormInput
            name="maxVisitDurationSeconds"
            placeholder="Max Contact Duration (seconds)"
            errors={errors}
            register={register}
          />
        </div>
        <div className="form-row area">
          <Select
            type="form"
            placeholder="Area"
            options={[{ label: "New", value: 0 }, ...props.areas]}
            value={areaField.value?.value || null}
            required={false}
            onChange={(value) => {
              if (value === null) {
                areaField.onChange(null);
              } else if (value === 0) {
                areaField.onChange({
                  label: "",
                  value: 0,
                });
              } else {
                const option = find(props.areas, (o) => o.value === value)!;
                areaField.onChange({
                  label: option.label,
                  value: option.value,
                });
              }
            }}
          />
          {areaField.value?.value === 0 ? (
            <Input
              name="area.label"
              error={formState.errors.area as FieldError}
              placeholder="Area Name"
              value={areaField.value?.label || undefined}
              onChange={(event) => {
                areaField.onChange({
                  label: event.target.value,
                  value: 0,
                });
              }}
            />
          ) : null}
        </div>
        <div className={"form-row" + (isSingleStore ? " hide-input" : "")}>
          <FormSelect
            type="form"
            name="store.value"
            control={control}
            placeholder="Store"
            options={props.stores}
            required={!isSingleStore}
          />
        </div>

        <div className="footer">
          {onDeleteHandler.current && canDeleteBeacons && !hideDeleteButton && (
            <button onClick={handleDelete} type="button" className="primary delete">
              Delete
            </button>
          )}
          <button onClick={props.onCancel} type="button" className="primary cancel">
            Cancel
          </button>
          <button type="submit" disabled={formState.isSubmitting} className="primary save">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditBeacon;
