import { useRef } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { getTooltip } from "../../services/tooltip";
import { HighlightColor } from "../../services/types";
import { ThreeDots } from "../icons/Icons";
import Card from "./Card";

import "./StatsCardSimple.scss";

interface ComponentProps {
  value: string | number | undefined;
  decimalPlaces?: number;
  className?: string;
  tooltipText?: string;
  asPercentage?: boolean;
  label: string;
  subLabel?: string;
  color?: null | HighlightColor;
  figure?: JSX.Element;
  items?: {
    label: string;
    percentage: number;
  }[];
  showDots?: boolean;
  selected?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  valuePostfix?: null | string;
  onClick?: () => void;
}

function formatValue(
  value: string | number | undefined,
  decimals: number | undefined,
  asPercentage?: boolean,
  valuePostfix?: null | string
) {
  if (typeof value === "number") {
    let number = value;
    if (typeof decimals === "number") {
      const factor = Math.pow(10, decimals);
      number = Math.round(value * factor) / factor;
    }
    if (asPercentage) {
      return number > 0 ? "+" + number.toLocaleString("en-US") + "%" : number.toLocaleString("en-US") + "%";
    } else if (valuePostfix) {
      return number.toLocaleString("en-US") + ` ${valuePostfix}`;
    } else {
      return number.toLocaleString("en-US");
    }
  }
  return value;
}

function StatsCardSimple({
  value,
  decimalPlaces,
  tooltipText,
  label,
  subLabel,
  color,
  figure,
  items,
  showDots,
  asPercentage,
  dragHandleProps,
  valuePostfix,
  selected,
  onClick,
  className,
}: ComponentProps) {
  const lastIndex = items?.length;
  const cardClasses = "stats-card-simple" + (className ? ` ${className}` : "") + (onClick ? " clickable" : "");
  const innerClasses = "app-totals";
  const dotsRef = useRef<HTMLDivElement>(null);
  const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    if (onClick) {
      // do not call onClick if drag button is enabled and its area is clicked.
      if (showDots && dotsRef.current && event.target instanceof Node && dotsRef.current.contains(event.target)) return;
      else onClick();
    }
  };
  return (
    <Card className={cardClasses} onClick={handleClick} selected={selected}>
      <div className="outer" data-for="appTooltip" data-tip={getTooltip(tooltipText || "", label)}>
        <div className={innerClasses}>
          <div className="figure">{figure}</div>
          <div className="total-numbers">
            <strong className={color || "black"}>
              {formatValue(value, decimalPlaces, asPercentage, valuePostfix)}
            </strong>
            <span>
              {label}
              {subLabel && <br />}
              {subLabel}
            </span>
            {items && (
              <div className="stats">
                {items.map((item, index) => (
                  <span key={index} className="items">
                    {items.length > 1 && (
                      <span
                        style={{
                          display: "inline-block",
                          height: 10,
                          width: 10,
                          background: "#a4a1fc",
                          marginRight: 5,
                          opacity: item.percentage / 100,
                        }}
                      ></span>
                    )}
                    {item.label} <span className="itemStats">{item.percentage}%</span>
                    {index !== lastIndex && "  "}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {showDots && (
        <span ref={dotsRef} className="dots" {...dragHandleProps}>
          {<ThreeDots />}
        </span>
      )}
    </Card>
  );
}

export default StatsCardSimple;
