import { max } from "lodash";
import { useCallback, useEffect } from "react";
import { BeaconVisitCountView, StoresApi } from "../../../api";
import { isHttpOk } from "../../../services/api";
import { useStateEx } from "../../../services/hooks";
import { getTooltip } from "../../../services/tooltip";
import { HighlightColor } from "../../../services/types";
import { getApiConfig } from "../../../state/configuration";
import { useTimeLocationFilters } from "../../../state/globalFilters";
import Loading from "../../widgets/Loading";
import { MapWithMarkers, Marker } from "../../widgets/MapWithMarkers";

export function StoreLayoutHeatmap(props: { storeId: number }) {
  const filters = useTimeLocationFilters();
  const { state, mergeState } = useStateEx<{
    imageUrl: string;
    markers: Marker[];
    isLoading: boolean;
  }>({
    imageUrl: "",
    markers: [],
    isLoading: true,
  });

  const { rangeType, rangeStart, rangeEnd } = filters;

  const loadData = useCallback(async () => {
    const apiConfig = getApiConfig();
    const storesApi = new StoresApi(apiConfig);

    const visitCounts = await storesApi.getStoreBeaconVisits({
      id: props.storeId,
      rangeType: rangeType,
      rangeStart: rangeStart,
      rangeEnd: rangeEnd,
    });

    const layout = await storesApi.getLayout({
      id: props.storeId,
    });

    const markerColor = (beacon: BeaconVisitCountView): HighlightColor => {
      const maxNumber = max(visitCounts.data.map((b) => b.nmVisits)) || 0;
      if (beacon.nmVisits === 0) return "red";
      if (beacon.nmVisits < maxNumber * 0.3) return "red";
      if (beacon.nmVisits < maxNumber * 0.7) return "orange";
      return "green";
    };

    if (isHttpOk(layout) && isHttpOk(visitCounts) && layout.data.floors?.length) {
      const floor = layout.data.floors[0];
      const markers =
        floor.beacons!.map((beacon) => {
          const visitCount = visitCounts.data.find((c) => c.id === beacon.id);
          const color = !!visitCount ? markerColor(visitCount) : "red";
          return {
            x: beacon.x!,
            y: beacon.y!,
            name: beacon.name || undefined,
            tooltip: getTooltip(`${visitCount?.nmVisits || 0} contacts`, beacon.name || ""),
            color: color,
          };
        }) || [];

      mergeState({
        imageUrl: floor.imageUrl || "",
        markers: markers,
        isLoading: false,
      });
    } else {
      mergeState({
        isLoading: false,
      });
    }
  }, [props.storeId, rangeType, rangeStart, rangeEnd, mergeState]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (state.isLoading) {
    return <Loading />;
  } else {
    return <MapWithMarkers map={state.imageUrl} markers={state.markers} />;
  }
}
