import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { initialOrEmpty } from "../../services/string";
import { logout, useAuthentication } from "../../state/authentication";
import { useDeploymentConfig } from "../../state/deployment";
import { useGlobalSearch } from "../../state/globalSearch";
import { Search } from "../forms/Search";
import { Menu } from "../icons/Icons";
import { useModal } from "../modals/Modal";
import Dropdown from "./Dropdown";
import MainMenu from "./MainMenu";
import { useSidebar } from "./Sidebar";

import "./Header.scss";

function EnvironmentIndicator() {
  const environment = useDeploymentConfig().config.currentEnvironment;
  if (environment && environment !== "production") {
    return <div className={clsx("environment-tag", environment)}>{environment}</div>;
  } else {
    return null;
  }
}

function Header() {
  const deployment = useDeploymentConfig();
  const auth = useAuthentication();
  const { search: globalSearchText, isVisible: globalSearchVisible, setText: setGlobalSearch } = useGlobalSearch();
  const { isAnyOpen: isModalOpen } = useModal();
  const { toggleSidebar } = useSidebar();

  const userFullName = `${auth.firstName} ${auth.lastName}`.trim();
  const userInitials = initialOrEmpty(auth.firstName) + initialOrEmpty(auth.lastName);

  return (
    <header className="common-header">
      <EnvironmentIndicator />
      <div className="logo-and-menu">
        <span
          className="hamburger"
          onClick={() => {
            if (!isModalOpen) {
              toggleSidebar();
            }
          }}
        >
          <Menu />
        </span>
        <div className="logo">
          <NavLink to="/">
            <h1>{deployment.config.navbarLogo}</h1>
          </NavLink>
        </div>
        <div className="menu">
          <MainMenu />
        </div>
      </div>
      <div className="user-info">
        <div className="links">
          {globalSearchVisible ? (
            <Search
              disabled={isModalOpen}
              value={globalSearchText}
              onChangeDebounceInMs={600}
              onChange={setGlobalSearch}
            />
          ) : null}
          {/* <div className="notifications">
            <Notifications />
          </div> */}
          {/* <NavLink to="/settings">
            <ActiveUsers />
          </NavLink> */}
        </div>
        <div className="user-menu">
          <span className="user-selector">
            <Dropdown label={userFullName} items={[{ label: "Logout", onClick: logout }]} />
          </span>
          <span className="user-avatar">{userInitials}</span>
        </div>
      </div>
    </header>
  );
}

export default Header;
