import { FieldError, FieldErrors } from "react-hook-form";
import {
  FrequencyCheck,
  Gender,
  Location,
  PredefinedTimeSpan,
  PurchaseAction,
  ReceptionPeriod,
  RecipientSelection,
  RelativeTime,
  RuleSentence,
  TimeSpanRange,
  TimeUnit,
} from "../../../api";
import { formatDateTime, formatPreciseDuration } from "../../../services/format";
import { useLocalizedText } from "../../../services/localization";
import { isProvided } from "../../../services/objects";
import { Tooltip } from "../../../services/tooltip";
import * as Editors from "./audience-editors";
import { EditorProps } from "./audience-editors/common";

export type CustomAudienceType = keyof Omit<RuleSentence, "allUsers">;

type EditorConfig<K extends keyof RuleSentence> = {
  description: Tooltip;
  disabled?: boolean;
  editor: (props: EditorProps) => JSX.Element;
  default: NonNullable<RuleSentence[K]>;
  validate: (model: NonNullable<RuleSentence[K]>) => FieldErrors<NonNullable<RuleSentence[K]>>;
  display: (model: NonNullable<RuleSentence[K]>) => string[];
};
export type EditorConfigurationType = {
  [P in CustomAudienceType]: EditorConfig<P>;
};
export enum AudienceType {
  All,
  Custom,
}

const defaultTime = { range: TimeSpanRange.Today, hour: 8 };

function error(message: string): FieldError {
  return {
    type: "validate",
    message: message,
  };
}

function isNotEmpty<T>(array: T[] | null | undefined): array is T[] {
  return isProvided(array) && array.length > 0;
}

function isLocationValid(location: Location) {
  const allIds = [location.areaId, location.storeId, location.cityId, location.countryId, location.beaconId];
  const nmProvidedIds = allIds.filter(isProvided).length;
  return nmProvidedIds === 1;
}

function validateLocations(location: Location[] | null | undefined) {
  if (isNotEmpty(location)) {
    if (location.every(isLocationValid)) {
      return undefined;
    }
    return error("Invalid location selection.");
  }
  return error("Select at least one location.");
}

export function relativeTimeBefore(value: RelativeTime, arg: RelativeTime) {
  function toSeconds(value: RelativeTime) {
    switch (value.unit) {
      case TimeUnit.Day:
        return value.amount * 24 * 60 * 60;
      case TimeUnit.Hour:
        return value.amount * 60 * 60;
      case TimeUnit.Minute:
        return value.amount * 60;
      default:
        return value.amount;
    }
  }
  if (value.unit !== arg.unit) {
    return toSeconds(value) > toSeconds(arg);
  } else if (value.amount > arg.amount) {
    return true;
  } else if (value.amount < arg.amount) {
    return false;
  } else if (value.unit === TimeUnit.Day && isProvided(value.explicitHour) && isProvided(arg.explicitHour)) {
    return value.explicitHour < arg.explicitHour;
  } else {
    return false;
  }
}

export function validateRelativeTime(value?: RelativeTime | null) {
  if (isProvided(value)) {
    if (value.amount < 0) {
      return error("Invalid amount");
    }
    if (isProvided(value.explicitHour)) {
      if (value.unit !== TimeUnit.Day) {
        return error("Explicit hour can only be used with days");
      } else if (value.explicitHour < 0 || value.explicitHour > 24) {
        return error("Hour should be between 0 and 24");
      }
    }
  }
}

function validateTime(time: PredefinedTimeSpan | null | undefined) {
  const range = time?.range;

  const model = {
    "time.range": !time || typeof time.range !== "number" ? error("Select time frame.") : undefined,
    "time.relativeTime": validateRelative(time),
    "time.relativeTimeRange": validateRelativeRange(time),
    "time.fixedDate": range === TimeSpanRange.FixedDate && !time?.fixedDate?.from ? error("Date required") : undefined,
    "time.fixedDateRange":
      range === TimeSpanRange.FixedDateRange && (!time?.fixedDateRange?.from || !time?.fixedDateRange?.to)
        ? error("Date range required")
        : undefined,
    "time.hour": range === TimeSpanRange.Today && typeof time!.hour !== "number" ? error("Select hour") : undefined,
  };
  if (Object.values(model).some((property) => property !== undefined)) {
    return model;
  }
}

function validateRelative(time: PredefinedTimeSpan | null | undefined) {
  return time?.range !== TimeSpanRange.RelativeTime
    ? undefined
    : !time.relativeTime?.amount
    ? error("Please select a relative time")
    : time.relativeTime.amount <= 0
    ? error("Value must be greater than 0")
    : undefined;
}

function validateRelativeRange(time: PredefinedTimeSpan | null | undefined) {
  return time?.range !== TimeSpanRange.RelativeTimeRange
    ? undefined
    : !time?.relativeTimeRange
    ? error("Please select a relative time")
    : !time.relativeTimeRange.amount || time.relativeTimeRange.amount < 0
    ? error("From value must be greater than 0")
    : !time.relativeTimeRange.toAmount || time.relativeTimeRange.toAmount < time.relativeTimeRange.amount
    ? error("To value must be greater than From")
    : undefined;
}

function renderTime(time: PredefinedTimeSpan | null | undefined, rangeSemantics?: boolean) {
  if (!time) return [];
  if (time.range === TimeSpanRange.Never) return ["never"];
  if (time.range === TimeSpanRange.Today) return ["today"];
  if (time.range === TimeSpanRange.AnyTime) return ["any time"];
  if (time.range === TimeSpanRange.Yesterday) return ["yesterday"];
  if (time.range === TimeSpanRange.ThisMonth) return ["this month"];
  if (time.range === TimeSpanRange.LastMonth) return ["last month"];
  const semantics = rangeSemantics ?? false;
  if (time.range === TimeSpanRange.SevenDaysAgo) return [applySemantics(semantics, "7 days")];
  if (time.range === TimeSpanRange.ThirtyDaysAgo) return [applySemantics(semantics, "30 days")];
  if (time.range === TimeSpanRange.ThreeMonthsAgo) return [applySemantics(semantics, "3 months")];
  if (time.range === TimeSpanRange.SixMonthsAgo) return [applySemantics(semantics, "6 months")];
  if (time.range === TimeSpanRange.RelativeTime) {
    const relative = time.relativeTime;
    const pluralEnd = relative!.amount !== 1 ? "s" : "";
    return [`last ${relative!.amount} ${TimeUnit[relative!.unit]?.toLowerCase()}${pluralEnd}`];
  }
  if (time.range === TimeSpanRange.RelativeTimeRange) {
    const range = time.relativeTimeRange;
    return [`${range!.amount}-${range!.toAmount} ${TimeUnit[range!.unit]?.toLowerCase()}s ago`];
  }
  if (time.range === TimeSpanRange.FixedDate) return [`on ${formatDateTime(time.fixedDate!.from, "dd.MM.yy")}`];
  if (time.range === TimeSpanRange.FixedDateRange) {
    const range = time.fixedDateRange;
    return [`${formatDateTime(range!.from, "dd.MM.yy")} to ${formatDateTime(range!.to, "dd.MM.yy")}`];
  }
  return [];
}
const applySemantics = (semantics: boolean, range: string) => (semantics ? `last ${range}` : `${range} ago`);

function renderLocation(locations: Location[] | null | undefined) {
  if (isProvided(locations)) {
    const locationNames = locations.filter((l) => !!l.name);
    const total = locationNames.length;
    return total > 2 ? `in ${total} locations` : locationNames.map((l) => "in " + l.name!);
  }
  return [];
}

export function useEditorConfiguration(): EditorConfigurationType {
  const texts = useLocalizedText();
  return {
    newRegistrations: {
      description: texts.audienceEditor.items.newRegistrations,
      editor: Editors.NewRegistrationsEditor,
      default: {},
      validate: () => {
        return {};
      },
      display: (model) => {
        return ["new registrations"];
      },
    },
    liveBeaconContact: {
      description: texts.audienceEditor.items.liveBeaconContact,
      editor: Editors.LiveBeaconContactEditor,
      default: { beacons: [], minDurationSeconds: 0, maxDistanceSeconds: 0 },
      validate: (model) => {
        return {
          beacons: isNotEmpty(model.beacons) ? undefined : error("Select at least one beacon."),
          minDurationSeconds: isProvided(model.minDurationSeconds)
            ? undefined
            : error("Please select minimum duration."),
          maxDistanceSeconds: isProvided(model.maxDistanceSeconds)
            ? undefined
            : error("Please select maximum distance."),
        };
      },
      display: (model) => {
        const adjectives = [];
        model.minDurationSeconds && adjectives.push(`for ${formatPreciseDuration(model.minDurationSeconds)}`);
        model.maxDistanceSeconds && adjectives.push(`during last ${formatPreciseDuration(model.maxDistanceSeconds)}`);
        if (adjectives.length === 0) {
          return ["live contact"];
        } else {
          return [`live contact (${adjectives.join(", ")})`];
        }
      },
    },
    lastBeaconContact: {
      description: texts.audienceEditor.items.lastBeaconContact,
      editor: Editors.LastBeaconContactEditor,
      default: { locations: [], time: defaultTime },
      validate: (model) => {
        return {
          locations: validateLocations(model.locations),
          ...validateTime(model.time),
        };
      },
      display: (model) => {
        return [["last contact"], renderTime(model.time, false), renderLocation(model.locations)].flat();
      },
    },
    specificBeaconContact: {
      description: texts.audienceEditor.items.specificBeaconContact,
      editor: Editors.SpecificBeaconContactEditor,
      default: { locations: [], time: defaultTime },
      validate: (model) => {
        return {
          locations: validateLocations(model.locations),
          ...validateTime(model.time),
        };
      },
      display: (model) => {
        return [["specific contact"], renderTime(model.time, false), renderLocation(model.locations)].flat();
      },
    },
    productAndPurchases: {
      description: texts.audienceEditor.items.productAndPurchases,
      editor: Editors.ProductAndPurchasesEditor,
      default: {
        time: { range: TimeSpanRange.ThisMonth },
        rateOfOccurence: { frequencyCheck: FrequencyCheck.AtLeastOnce, amount: 1 },
      },
      validate: (model) => {
        const check = model.purchaseCheck;
        const amountError =
          (check?.purchaseAction === PurchaseAction.OrderWithTotalAmount ||
            check?.purchaseAction === PurchaseAction.OrderWithDiscountAmount) &&
          (!check.amount || check.amount < 0)
            ? error("Amount must be over 0")
            : undefined;
        return {
          "purchaseCheck.amount": amountError,
          brand: undefined,
          ...validateTime(model.time),
        };
      },
      display: (model) => {
        const check = model.purchaseCheck;
        var displayTexts = [["purchase history"]];
        if (check?.purchaseAction === PurchaseAction.SpecificProduct) {
          var keywords = model.descriptionKeywords;
          const count = keywords?.length ?? 0;
          const description = count === 0 ? "any description" : count > 2 ? `${count} keywords` : keywords!.join(" ");
          const brand = model.brand || "all brands";
          displayTexts.push([brand], [description]);
        } else if (check?.purchaseAction === PurchaseAction.HasNotPurchased) {
          displayTexts.push(["no purchases"]);
        } else if (check?.purchaseAction === PurchaseAction.OrderWithTotalAmount) {
          displayTexts.push(["total over"], [`${check!.amount}`]);
        } else if (check?.purchaseAction === PurchaseAction.OrderWithDiscountAmount) {
          displayTexts.push(["discount over"], [`${check!.amount}`]);
        }

        return [...displayTexts, renderTime(model.time, false)].flat();
      },
    },
    mostStoreVisits: {
      description: texts.audienceEditor.items.mostStoreVisits,
      editor: Editors.MostStoreVisitsEditor,
      default: { topPercent: 1, locations: [], time: defaultTime },
      validate: (model) => {
        return {
          locations: validateLocations(model.locations),
          topPercent: model.topPercent <= 0 || model.topPercent > 100 ? error("Select valid percentage.") : undefined,
        };
      },
      display: (model) => {
        return [[`in top ${model.topPercent}%`], renderLocation(model.locations)].flat();
      },
    },
    favoriteStore: {
      description: texts.audienceEditor.items.favoriteStore,
      editor: Editors.FavoriteStoreEditor,
      default: { locations: [] },
      validate: (model) => {
        return {
          locations: validateLocations(model.locations),
        };
      },
      display: (model) => {
        return model.locations!.map((l) => "favorite " + l.name!);
      },
    },
    specificGender: {
      description: texts.audienceEditor.items.specificGender,
      editor: Editors.GenderEditor,
      default: { gender: Gender.Other },
      validate: (model) => {
        return {
          gender: typeof model.gender !== "number" ? error("Select valid gender.") : undefined,
        };
      },
      display: (model) => {
        const text = model.gender === Gender.Male ? "men" : model.gender === Gender.Female ? "woman" : null;
        return text ? [text] : [];
      },
    },
    specificAge: {
      description: texts.audienceEditor.items.specificAge,
      editor: Editors.AgeEditor,
      default: { ageGroups: [] },
      validate: (model) => {
        return {
          ageGroups: isNotEmpty(model.ageGroups) ? undefined : error("Select at least one age group."),
        };
      },
      display: (model) => {
        return (
          model.ageGroups
            ?.filter((l) => l.from !== undefined)
            ?.map((l) => (l.from && l.from >= 0 ? l.from + (l.to ? `-${l.to} y.` : "+ y.") : "unknown age")) || []
        );
      },
    },
    birthdayToday: {
      description: texts.audienceEditor.items.birthdayToday,
      editor: Editors.BirthdayEditor,
      default: {},
      validate: () => {
        return {};
      },
      display: (model) => {
        return ["with birthday"];
      },
    },
    productPreference: {
      disabled: true,
      description: texts.audienceEditor.items.productPreference,
      editor: Editors.ProducePreferenceEditor,
      default: { minPurchases: 1, productCategory: [] },
      validate: (model) => {
        return {
          minPurchases: model.minPurchases < 1 ? error("Select minimum purchases.") : undefined,
          productCategory: isNotEmpty(model.productCategory) ? undefined : error("Select at least one category."),
        };
      },
      display: (model) => {
        return ["products"];
      },
    },
    clickAndCollect: {
      disabled: true,
      description: texts.audienceEditor.items.clickAndCollect,
      editor: Editors.ClickAndCollectEditor,
      default: {},
      validate: (model) => {
        return {};
      },
      display: (model) => {
        return ["with Click And Collect"];
      },
    },
    returningCustomers: {
      description: texts.audienceEditor.items.returningCustomers,
      editor: Editors.ReturningCustomersEditor,
      default: { locations: [] },
      validate: (model) => {
        return {
          locations: validateLocations(model.locations),
        };
      },
      display: (model) => {
        return [["returning"], renderLocation(model.locations)].flat();
      },
    },
    loyalCustomers: {
      disabled: true,
      description: texts.audienceEditor.items.loyalCustomers,
      editor: Editors.LoyalCustomersEditor,
      default: {},
      validate: (model) => {
        return {};
      },
      display: (model) => {
        return ["loyal"];
      },
    },
    mostActiveCustomers: {
      description: texts.audienceEditor.items.mostActiveCustomers,
      editor: Editors.MostActiveCustomersEditor,
      default: { topPercentage: 10 },
      validate: (model) => {
        const hasPercent = typeof model.topPercentage === "number";
        const hasMinNumber = typeof model.minNumberOfPurchases === "number";
        return {
          topPercentage:
            hasPercent === hasMinNumber
              ? error("Select percentage or number.")
              : hasPercent && (model.topPercentage! < 1 || model.topPercentage! > 100)
              ? error("Select valid percentage.")
              : hasMinNumber && model.minNumberOfPurchases! < 1
              ? error("Select at least 1 purchase.")
              : undefined,
        };
      },
      display: (model) => {
        const hasPercent = typeof model.topPercentage === "number";
        return hasPercent ? [`in top ${model.topPercentage}%`] : [`with >${model.minNumberOfPurchases} purchases`];
      },
    },
    vipCustomers: {
      description: texts.audienceEditor.items.vipCustomers,
      editor: Editors.VipCustomersEditor,
      default: { topPercentage: 10 },
      validate: (model) => {
        const hasPercent = typeof model.topPercentage === "number";
        const hasMinNumber = typeof model.minNumberOfPurchases === "number";
        return {
          topPercentage:
            hasPercent === hasMinNumber
              ? error("Select percentage or number.")
              : hasPercent && (model.topPercentage! < 1 || model.topPercentage! > 100)
              ? error("Select valid percentage.")
              : hasMinNumber && model.minNumberOfPurchases! < 1
              ? error("Select at least 1 purchase.")
              : undefined,
        };
      },
      display: (model) => {
        const hasPercent = typeof model.topPercentage === "number";
        return hasPercent
          ? [`in top ${model.topPercentage}% (VIP)`]
          : [`with >${model.minNumberOfPurchases} purchases`];
      },
    },
    visitWithoutPurchase: {
      description: texts.audienceEditor.items.visitWithoutPurchase,
      editor: Editors.VisitsWithoutPurchaseEditor,
      default: { locations: [], time: defaultTime },
      validate: (model) => {
        return {
          locations: validateLocations(model.locations),
          ...validateTime(model.time),
        };
      },
      display: (model) => {
        return ["buyers", renderTime(model.time), renderLocation(model.locations)].flat();
      },
    },
    customerEngagement: {
      description: texts.audienceEditor.items.customerEngagement,
      editor: Editors.CustomerEngagementEditor,
      default: {
        locations: [],
      },
      validate: (model) => {
        return {
          locations: validateLocations(model.locations),
        };
      },
      display: (model) => {
        return ["during closing", renderLocation(model.locations)].flat();
      },
    },
    customerSegment: {
      description: texts.audienceEditor.items.customerSegment,
      editor: Editors.CustomerSegmentEditor,
      default: {
        segmentGroupId: null,
        segmentIndex: null,
      },
      validate: (model) => {
        return {
          segmentGroupId: !isProvided(model.segmentGroupId) ? error("Select segment group") : undefined,
          segmentIndex: !isProvided(model.segmentIndex) ? error("Select index of segment") : undefined,
        };
      },
      display: (model) => {
        return [`${model.segmentIndex?.name} from ${model.segmentGroupId?.name}`];
      },
    },
    receivedExistingOffer: {
      description: texts.audienceEditor.items.receivedExistingOffer,
      editor: Editors.ExistingOfferEditor,
      default: {
        period: ReceptionPeriod.Ever,
        selection: RecipientSelection.Exclude,
        offer: null,
      },
      validate: (model) => {
        return {
          period: isProvided(model.period) ? undefined : error("Select period"),
          offer: isProvided(model.offer) && model.offer.id !== 0 ? undefined : error("Select existing message"),
          selection: isProvided(model.selection) ? undefined : error("Select type of selection"),
        };
      },
      display: (model) => {
        if (model.offer?.name) {
          const prefix = model.selection === RecipientSelection.Include ? "received" : "didn't receive";
          return [`${prefix} '${model.offer.name}'`];
        } else {
          return [];
        }
      },
    },
  };
}
