import { AuthenticationOptions } from "./Types";

// Add authentication details for connecting to Azure Maps.
export const AuthOptions: AuthenticationOptions = {
  // Alternatively, use an Azure Maps key. Get an Azure Maps key at https://azure.com/maps. NOTE: The primary key should be used as the key.
  clientId: "deddb22d-d837-430c-9f82-eb8227d1acba",
  subscriptionKey: "QJ_aQZTpA5X6zA6ymUWPLq7Xbp2ehtxS6dc1dMmAR0g",
};

export const Constants = {
  events: {
    tokenAcquired: "tokenacquired",
  },
  tokenExpiresIn: 3599,
  tokenRefreshClockSkew: 300,
  errors: {
    tokenExpired: "Token Expired, Try again",
  },
  AUTHORIZATION: "authorization",
  AUTHORIZATION_SCHEME: "Bearer",
  MAP_AGENT: "Map-Agent",
  MS_AM_REQUEST_ORIGIN: "Ms-Am-Request-Origin",
  MS_AM_REQUEST_ORIGIN_VALUE: "MapControl",
  X_MS_CLIENT_ID: "x-ms-client-id",
  SESSION_ID: "Session-Id",
  SHORT_DOMAIN: "atlas.microsoft.com",
  DEFAULT_DOMAIN: "https://atlas.microsoft.com/",
  SDK_VERSION: "0.0.1",
  TARGET_SDK: "Leaflet",
};
