import React from "react";
import clsx, { ClassValue } from "clsx";
import { isEqual } from "lodash";

import "./Loading.scss";

function Loading(props: { className?: ClassValue; text?: string }) {
  return (
    <div className={clsx(props.className, "common-loading")}>
      <div className="lds-dual-ring"></div>
      <strong>{props.text || "LOADING"}</strong>
    </div>
  );
}

export default React.memo(Loading, (prev, next) => isEqual(prev, next));
