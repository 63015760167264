import { cloneDeep, max } from "lodash";
import { ReactNode } from "react";
import { FieldError, FieldErrors, UseFormReturn, useController, useFieldArray, useForm } from "react-hook-form";
import { CustomAudienceExpression, RuleExpressionPartType, RuleSentence } from "../../../api";
import { isProvided } from "../../../services/objects";
import { AlertMissingValidationErrors } from "../../forms/AlertMissingValidationErrors";
import { Option, Select } from "../../forms/Select";
import { AudienceType, CustomAudienceType, EditorConfigurationType, useEditorConfiguration } from "./AudienceEditors";

import "./AudienceForm.scss";

export type AudienceModel = {
  rules: RuleModel[];
};

type RuleModel = {
  id: string;
  and: boolean;
  sentence: RuleSentence;
};

type RuleEditorProps = {
  name: string;
  currency: string;
  brands: Option[];
  beacons: Option[];
  stores: Option[];
  control: UseFormReturn<AudienceModel>["control"];
  onDelete: () => void;
  last: boolean;
  defaultValue: RuleModel;
};

function RuleEditor(props: RuleEditorProps) {
  const editorConfiguration = useEditorConfiguration();
  const editorIds = Object.keys(editorConfiguration) as CustomAudienceType[];
  const { name, control, last, defaultValue, onDelete } = props;

  const { field: andField } = useController({
    name: `${name}.and` as "rules.0.and",
    control: control,
    defaultValue: defaultValue.and,
  });

  const { field: sentenceField, fieldState: sentenceState } = useController({
    control: props.control,
    name: `${name}.sentence` as "rules.0.sentence",
    defaultValue: defaultValue.sentence,
  });

  const editorSelectOptions = editorIds
    .filter((id) => !editorConfiguration[id].disabled)
    .map((id) => ({
      label: editorConfiguration[id].description.title,
      description: editorConfiguration[id].description.tooltip,
      value: id,
    }));
  const editorSelectValue = editorIds.find((id) => isProvided(sentenceField.value[id]));

  // Editor Id is missing or invalid (allUsers)
  if (!editorSelectValue) return <></>;

  const andClass = andField.value ? "and" : "and inactive";
  const orClass = andField.value ? "or inactive" : "or";
  const error = sentenceState.error ? (sentenceState.error as any)[editorSelectValue] : undefined;

  const renderEditor = () => {
    const config = editorConfiguration[editorSelectValue];
    if (config.editor) {
      return (
        <config.editor
          description={config.description.tooltip}
          error={error}
          currency={props.currency}
          brands={props.brands}
          beacons={props.beacons}
          value={sentenceField.value}
          onChange={sentenceField.onChange}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="rule">
        <Select
          type="form"
          className="select-audience"
          placeholder="Select audience"
          errorLabel="Audience"
          options={editorSelectOptions}
          value={editorSelectValue}
          required={true}
          onChange={(value) => {
            if (typeof value === "string") {
              const editor = editorConfiguration[value as CustomAudienceType];
              if (editor && !editor.disabled) {
                const model: any = {};
                model[value] = { ...editor.default };
                sentenceField.onChange(model);
              }
            }
          }}
        />
        <button onClick={onDelete} type="button" className="delete">
          Delete
        </button>
        {renderEditor()}
      </div>
      {!last && (
        <div className="and-or">
          <button type="button" className={andClass} onClick={() => andField.onChange(true)}>
            AND
          </button>
          <button type="button" className={orClass} onClick={() => andField.onChange(false)}>
            OR
          </button>
        </div>
      )}
    </>
  );
}

function expressionFromModel(model: AudienceModel): CustomAudienceExpression {
  const expression: CustomAudienceExpression = { parts: [] };
  if (model.rules) {
    for (const rule of model.rules) {
      expression.parts!.push({
        type: RuleExpressionPartType.Sentence,
        sentence: rule.sentence,
      });
      expression.parts!.push({
        type: rule.and ? RuleExpressionPartType.And : RuleExpressionPartType.Or,
        sentence: undefined,
      });
    }
    expression.parts!.pop();
  }
  return expression;
}

function modelFromExpression(model?: CustomAudienceExpression): AudienceModel {
  const rules: RuleModel[] = [];
  if (model && model.parts) {
    for (const part of model.parts) {
      if (part.type !== RuleExpressionPartType.Sentence && rules.length === 0) {
        continue;
      }
      if (part.type === RuleExpressionPartType.And) {
        rules[rules.length - 1].and = true;
      } else if (part.type === RuleExpressionPartType.Or) {
        rules[rules.length - 1].and = false;
      } else if (part.type === RuleExpressionPartType.Sentence && part.sentence) {
        rules.push({
          id: rules.length.toString(),
          sentence: cloneDeep(part.sentence),
          and: false,
        });
      }
    }
  }

  return { rules: rules };
}

function validateRuleSentence(
  sentence: RuleSentence,
  editorConfiguration: EditorConfigurationType
): FieldErrors<RuleSentence> | undefined {
  const EditorIds = Object.keys(editorConfiguration) as CustomAudienceType[];
  const editorKey = EditorIds.find((id) => !!sentence[id]);
  if (editorKey) {
    const result = editorConfiguration[editorKey!].validate(sentence[editorKey!] as any) as Record<string, FieldError>;
    const errorKeys = Object.keys(result);
    const map: any = {};
    let hasErrors = false;
    for (const errorKey of errorKeys) {
      if (result[errorKey]) {
        map[editorKey] = map[editorKey] || {};
        map[editorKey][errorKey] = result[errorKey];
        hasErrors = true;
      }
    }
    return hasErrors ? map : undefined;
  }
}

type Props = {
  title: string;
  identifier?: string;
  beacons: Option[];
  stores: Option[];
  brands: Option[];
  currency: string;
  default?: CustomAudienceExpression;
  onCancel?: () => void;
  onSave?: (value: CustomAudienceExpression) => void;
};

export const AudienceFormResolver = (editorConfiguration: EditorConfigurationType) => {
  return (model: AudienceModel) => {
    const ruleErrors = model.rules
      .map((rule) => validateRuleSentence(rule.sentence, editorConfiguration))
      .map((error) => ({ sentence: error }));

    if (ruleErrors.some((e) => isProvided(e.sentence))) {
      const response = {
        values: {},
        errors: {
          rules: ruleErrors,
        },
      };
      return response;
    }
    return { values: model, errors: {} };
  };
};

export function AudienceForm(props: Props) {
  const editorConfiguration = useEditorConfiguration();
  const defaultValues = modelFromExpression(props.default);
  const { formState, control, handleSubmit } = useForm<AudienceModel>({
    defaultValues: defaultValues,
    resolver: AudienceFormResolver(editorConfiguration),
  });

  const { append, remove, fields } = useFieldArray({
    name: "rules",
    control: control,
    keyName: "id",
  });

  const addCondition = () => {
    const item: RuleModel = {
      id: fields.length > 0 ? (max(fields.map((f) => parseInt(f.id, 10)))! + 1).toString() : "1",
      sentence: {
        lastBeaconContact: editorConfiguration.lastBeaconContact.default,
      },
      and: true,
    };
    append(item);
  };

  const handleSave = (model: AudienceModel) => {
    const expression = expressionFromModel(model);
    if (props.onSave) props.onSave(expression);
  };

  const editors = fields.map((item, index) => {
    const isLast = index === fields.length - 1;

    return (
      <RuleEditor
        key={item.id}
        defaultValue={item}
        name={`rules.${index}`}
        beacons={props.beacons}
        stores={props.stores}
        brands={props.brands}
        currency={props.currency}
        control={control}
        onDelete={() => remove(index)}
        last={isLast}
      />
    );
  });

  const getAddTitle = () => {
    if (fields.length === 0) {
      return "Select targeting criteria";
    } else {
      return "Add targeting criteria";
    }
  };

  return (
    <>
      <div className="header">
        <h1 className="title">{props.title}</h1>
        {props.identifier && <h5>For {props.identifier}</h5>}
        <p className="description">Define your custom audience to create highly tailored push notifications.</p>
      </div>
      <form className="audience-form" onSubmit={handleSubmit(handleSave)}>
        <AlertMissingValidationErrors control={control} />
        {editors}
        <button type="button" className="primary condition" onClick={addCondition}>
          {getAddTitle()}
        </button>
        <div className="buttons">
          <button type="button" onClick={props.onCancel} className="primary cancel">
            Cancel
          </button>
          <button type="submit" disabled={formState.isSubmitting} className="primary save">
            Save
          </button>
        </div>
      </form>
    </>
  );
}

type AudienceDisplayProps = {
  total?: number | null;
  audienceType: AudienceType;
  audience?: CustomAudienceExpression | null;
};

export function AudienceDisplay(props: AudienceDisplayProps) {
  const editorConfiguration = useEditorConfiguration();
  const editorIds = Object.keys(editorConfiguration) as CustomAudienceType[];
  const model = modelFromExpression(props.audience || {});

  function describeSentence(rule: RuleSentence) {
    const key = editorIds.find((k) => !!rule[k]);
    if (key) {
      const display = editorConfiguration[key].display;
      return display(rule[key] as any);
    }
    return [];
  }

  const renderListItems = () => {
    if (props.audienceType === AudienceType.All || model.rules.length === 0) {
      return [
        <li key="empty" className="empty">
          {props.audienceType === AudienceType.All ? "Full audience" : "no audience"}
        </li>,
      ];
    }

    const final: ReactNode[] = [];
    model.rules.forEach((rule, ruleIndex) => {
      if (ruleIndex > 0 && model.rules[ruleIndex - 1].and === false && final.length > 0) {
        final.push(
          <li className="or" key={`${ruleIndex}_or`}>
            OR
          </li>
        );
      }
      const sentences = describeSentence(rule.sentence).map((sentence, sentenceIndex) => {
        return <li key={`${ruleIndex}_${sentenceIndex}`}>{sentence}</li>;
      });
      final.push(...sentences);
    });
    return final;
  };

  return (
    <>
      <div className="audience-total">Potential reach - {isProvided(props.total) ? `${props.total} Users` : "-"}</div>
      <ul className="audience-display">{renderListItems()}</ul>
    </>
  );
}
