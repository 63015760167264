import React, { useRef } from "react";
import { FieldError, Path, useController, UseFormReturn } from "react-hook-form";
import { toast as alert } from "react-toastify";
import { ImageApi } from "../../api";
import { useStateEx } from "../../services/hooks";
import { getApiConfig } from "../../state/configuration";
import { Upload } from "../icons/Icons";
import { ServerUnavailable } from "../widgets/Alerts";
import Loading from "../widgets/Loading";
import { ValidationError } from "../widgets/ValidationError";

import "./ImageUpload.scss";

interface Options<TModel> {
  name: Path<TModel>;
  thumbnailName?: Path<TModel>;
  grayscaleName?: Path<TModel>;
  grayscaleThumbnailName?: Path<TModel>;
  control: UseFormReturn<TModel>["control"];
  onUploaded?: () => void;
  skipResizing?: boolean;
}

type State = {
  errorMessage: string;
  invalid: boolean;
  uploadInProgress: boolean;
};

const isAcceptableFileList = (files: FileList): boolean => {
  if (files.length !== 1) {
    return false;
  }
  const fileType = files[0].type;
  return fileType === "image/png" || fileType === "image/jpeg";
};

function useImageUploading<TModel>(options: Options<TModel>) {
  const { state, mergeState } = useStateEx<State>({
    errorMessage: "",
    invalid: false,
    uploadInProgress: false,
  });

  const useImageController = (name?: Path<TModel>) => {
    const controller = useController({ name: name || ("____unused" as any), control: options.control });
    return name && controller;
  };

  const image = useImageController(options.name)!;
  const thumbnail = useImageController(options.thumbnailName);
  const grayscale = useImageController(options.grayscaleName);
  const grayscaleThumbnail = useImageController(options.grayscaleThumbnailName);

  const isInvalid = () => {
    return (
      state.invalid ||
      image.fieldState.invalid ||
      thumbnail?.fieldState.invalid ||
      grayscale?.fieldState.invalid ||
      grayscaleThumbnail?.fieldState.invalid
    );
  };

  const errorMessage: () => FieldError | undefined = () => {
    if (state.errorMessage) {
      return {
        type: "validation",
        message: state.errorMessage,
      };
    }
    return (
      image.fieldState.error ||
      thumbnail?.fieldState.error ||
      grayscale?.fieldState.error ||
      grayscaleThumbnail?.fieldState.error
    );
  };

  const handleNonValidationError = (error: any) => {
    alert.error(<ServerUnavailable message="Image was not uploaded! Please try again." diagnosticError={error} />);
    mergeState({
      uploadInProgress: false,
      invalid: true,
      errorMessage: "Image was not uploaded! Please try again.",
    });
  };

  const uploadFile = async (files: FileList) => {
    if (!isAcceptableFileList(files)) {
      mergeState({
        invalid: true,
        errorMessage: "Invalid file type!",
      });
      return;
    }

    const file = files[0];

    mergeState({ uploadInProgress: true, invalid: false });

    const config = getApiConfig();
    const api = new ImageApi(config);

    try {
      const result = await api.create({
        image: file,
        settings: {
          generateGrayscale: grayscale !== undefined,
          generateThumbnail: thumbnail !== undefined,
          generateThumbnailGrayscale: grayscaleThumbnail !== undefined,
          skipResizing: options.skipResizing,
        },
      });

      if (result.status === 200 && result.data) {
        mergeState({
          errorMessage: "",
          invalid: false,
          uploadInProgress: false,
        });

        /* eslint-disable no-console */

        if (result.data.imagePath) {
          image.field.onChange(result.data.imagePath);
          image.field.onBlur();
        } else {
          console.error("Did not receive image url!");
        }

        if (thumbnail) {
          if (result.data.thumbnailPath) {
            thumbnail.field.onChange(result.data.thumbnailPath);
            thumbnail.field.onBlur();
          } else {
            console.error("Did not receive thumbnail url!");
          }
        }

        if (grayscale) {
          if (result.data.imageGrayscalePath) {
            grayscale.field.onChange(result.data.imageGrayscalePath);
            grayscale.field.onBlur();
          } else {
            console.error("Did not receive grayscale url!");
          }
        }

        if (grayscaleThumbnail) {
          if (result.data.thumbnailGrayscalePath) {
            grayscaleThumbnail.field.onChange(result.data.thumbnailGrayscalePath);
            grayscaleThumbnail.field.onBlur();
          } else {
            console.error("Did not receive thumbnail grayscale url!");
          }
        }

        if (options.onUploaded) options.onUploaded();
      } else {
        handleNonValidationError(result);
      }
    } catch (error) {
      handleNonValidationError(error);
    }
  };

  return {
    uploadFile,
    current: {
      image: image.field.value as string | null | undefined,
      thumbnail: thumbnail?.field?.value as string | null | undefined,
      grayImage: grayscale?.field?.value as string | null | undefined,
      grayThumbnail: grayscaleThumbnail?.field?.value as string | null | undefined,
    },
    uploadInProgress: state.uploadInProgress,
    invalid: isInvalid(),
    error: errorMessage(),
  };
}

interface ImageUploadButtonProps<TModel> extends Options<TModel> {
  title?: string;
  instruction?: string;
  errorLabel?: string;
  skipResizing?: boolean;
}

export function ImageUploadButton<TModel>(props: ImageUploadButtonProps<TModel>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { uploadFile, uploadInProgress, error, invalid } = useImageUploading(props);

  const onFileSelect = (files?: FileList | null) => {
    if (!files) {
      return;
    }
    if (!isAcceptableFileList(files)) {
      return;
    }
    uploadFile(files);
  };

  const getCssClass = () => {
    const classes = ["form-input image-upload-button"];
    if (uploadInProgress) classes.push("upload-in-progress");
    if (invalid) classes.push("invalid");
    return classes.join(" ");
  };

  const buttonLabel = () => {
    if (uploadInProgress) {
      return "Uploading";
    } else if (props.title?.length) {
      return props.title;
    } else {
      return "Upload image";
    }
  };

  const renderInfoOrError = () => {
    if (error) {
      const fieldName = props.errorLabel || "Field";
      let message = error.message;
      if (!message) {
        switch (error.type) {
          case "required":
            message = `${fieldName} is required`;
            break;
          default:
            message = `${fieldName} is invalid`;
            break;
        }
      }
      return <ValidationError path={props.name} message={message} />;
    } else if (props.instruction?.length) {
      return (
        <>
          <br />
          <span className="instruction">{props.instruction}</span>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={getCssClass()}>
      <button className="secondary" type="button" onClick={() => inputRef.current!.click()}>
        <Upload /> {buttonLabel()}
      </button>
      <input
        id="file-input"
        ref={inputRef}
        type="file"
        multiple={false}
        accept="image/*"
        onChange={(e) => {
          onFileSelect(inputRef.current!.files);
          inputRef.current!.value = "";
          inputRef.current!.files = null;
        }}
      />
      {renderInfoOrError()}
    </div>
  );
}

interface ImageUploadAreaProps<TModel> extends Options<TModel> {
  placeholderIcon?: React.ReactNode;
  placeholderText?: React.ReactNode;
  skipResizing?: boolean;
}

export function ImageUploadArea<TModel>(props: ImageUploadAreaProps<TModel>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const { uploadFile, current, uploadInProgress, error, invalid } = useImageUploading(props);

  const { state, mergeState } = useStateEx({
    dragActive: false,
  });

  const onFileSelect = (files?: FileList | null) => {
    if (!files) {
      return;
    }
    if (!isAcceptableFileList(files)) {
      return;
    }
    uploadFile(files);
  };

  const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    mergeState({
      dragActive: true,
    });
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    mergeState({
      dragActive: false,
    });
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    return false;
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    mergeState({
      dragActive: false,
    });
    uploadFile(event.dataTransfer.files);
  };

  const getCssClass = () => {
    const classes = ["image-upload-area"];
    if (state.dragActive) classes.push("drag-active");
    if (uploadInProgress) classes.push("upload-in-progress");
    if (invalid) classes.push("invalid");
    return classes.join(" ");
  };

  const textualMessage = () => {
    if (invalid) {
      return error;
    } else if (state.dragActive) {
      return "Drop the file";
    } else if (props.placeholderText) {
      return props.placeholderText;
    } else {
      return "Drag and drop a file here or click";
    }
  };

  const showImage = () => {
    if (imageRef.current) {
      imageRef.current.style.display = "";
    }
  };

  const hideImage = () => {
    if (imageRef.current) {
      imageRef.current.style.display = "none";
    }
  };

  const imageOrPlaceholder = () => {
    if (current.image && current.image.length) {
      return (
        <label htmlFor="file-input" className="preview">
          <img
            ref={imageRef}
            style={{ display: "none" }}
            src={current.image}
            onLoad={showImage}
            onError={hideImage}
            alt="preview"
          />
        </label>
      );
    } else {
      return (
        <label htmlFor="file-input">
          {props.placeholderIcon || <Upload />}
          <span>{textualMessage()}</span>
        </label>
      );
    }
  };

  return (
    <div ref={containerRef} className={getCssClass()}>
      <div
        className="dnd-sensor"
        onDragEnter={(event) => onDragEnter(event)}
        onDragLeave={(event) => onDragLeave(event)}
        onDragOver={(event) => onDragOver(event)}
        onDrop={(event) => onDrop(event)}
        onClick={() => inputRef.current?.click()}
      ></div>
      {imageOrPlaceholder()}
      <input
        id="file-input"
        ref={inputRef}
        type="file"
        multiple={false}
        accept="image/*"
        onChange={(e) => onFileSelect(inputRef.current?.files)}
      />
      <div className="overlay upload-info">
        <Loading text="UPLOADING" />
      </div>
    </div>
  );
}
