import { ShoppingStatisticsView } from "../../api";
import StatsCardSimple from "../../app/cards/StatsCardSimple";
import { Hourglass, MaximumTime, MinimumTime, PeakDay, PeakTime } from "../../app/icons/Icons";
import { formatApproximateDuration, formatDayOfWeek, formatHourAmPm } from "../../services/format";
import { useLocalizedText } from "../../services/localization";

export function BottomRowStatistics(props: { stats?: ShoppingStatisticsView }) {
  const texts = useLocalizedText();
  return (
    <section className="analytics">
      <StatsCardSimple
        value={formatApproximateDuration(props.stats?.statisticsByDayAverages?.minTimeInSec)}
        tooltipText={texts.visitorStatisticsPage.items.minVisitingTime.tooltip}
        label={texts.visitorStatisticsPage.items.minVisitingTime.title}
        color="red"
        figure={<MinimumTime />}
      />
      <StatsCardSimple
        value={formatApproximateDuration(props.stats?.statisticsByDayAverages?.maxTimeInSec)}
        tooltipText={texts.visitorStatisticsPage.items.maxVisitingTime.tooltip}
        label={texts.visitorStatisticsPage.items.maxVisitingTime.title}
        color="pink"
        figure={<MaximumTime />}
      />
      <StatsCardSimple
        value={formatApproximateDuration(props.stats?.statisticsByDayAverages?.avgTimeInSec)}
        tooltipText={texts.visitorStatisticsPage.items.avgVisitingTime.tooltip}
        label={texts.visitorStatisticsPage.items.avgVisitingTime.title}
        color="blue"
        figure={<Hourglass />}
      />
      <StatsCardSimple
        value={formatHourAmPm(props.stats?.statisticsByDayAverages?.peakHour)}
        tooltipText={texts.visitorStatisticsPage.items.peakVisitingHour.tooltip}
        label={texts.visitorStatisticsPage.items.peakVisitingHour.title}
        color="orange"
        figure={<PeakTime />}
      />
      <StatsCardSimple
        value={formatDayOfWeek(props.stats?.peakDayOfWeek)}
        tooltipText={texts.visitorStatisticsPage.items.peakVisitingDay.tooltip}
        label={texts.visitorStatisticsPage.items.peakVisitingDay.title}
        color="green"
        figure={<PeakDay />}
      />
    </section>
  );
}
