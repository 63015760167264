// eslint-disable-next-line import/no-internal-modules
import format from "date-fns/format";
import { useCallback } from "react";
import { ShoppingStatisticsByDay } from "../../api";
import { stringToDate } from "../../services/date";
import { formatApproximateDuration, formatHourAmPm } from "../../services/format";
import { useCachedInstance } from "../../services/hooks";
import { useLocalizedText } from "../../services/localization";
import { PaginatedTable } from "../widgets/PaginatedTable";

import "./DailyVisitingAnalytics.scss";

interface Props {
  loading: boolean;
  statsByDay: ShoppingStatisticsByDay[];
}

function DailyVisitingAnalytics(props: Props) {
  const texts = useLocalizedText();
  const statsByDay = useCachedInstance(props.statsByDay);

  const headers = [
    {
      title: texts.visitorStatisticsPage.items.date.title.toUpperCase(),
      tooltip: texts.visitorStatisticsPage.items.date.tooltip,
    },
    {
      className: "day",
      title: texts.visitorStatisticsPage.items.day.title.toUpperCase(),
      tooltip: texts.visitorStatisticsPage.items.day.tooltip,
    },
    {
      title: texts.visitorStatisticsPage.items.minVisitingTime.title.toUpperCase(),
      tooltip: texts.visitorStatisticsPage.items.minVisitingTime.tooltip,
    },
    {
      title: texts.visitorStatisticsPage.items.maxVisitingTime.title.toUpperCase(),
      tooltip: texts.visitorStatisticsPage.items.maxVisitingTime.tooltip,
    },
    {
      title: texts.visitorStatisticsPage.items.avgVisitingTime.title.toUpperCase(),
      tooltip: texts.visitorStatisticsPage.items.avgVisitingTime.tooltip,
    },
    {
      title: texts.visitorStatisticsPage.items.peakVisitingHour.title.toUpperCase(),
      tooltip: texts.visitorStatisticsPage.items.peakVisitingHour.tooltip,
    },
  ];

  const dataSource = useCallback(
    (offset: number, limit: number) => {
      if (statsByDay.length < offset) {
        return {
          count: statsByDay.length,
          items: [],
        };
      }
      return {
        count: statsByDay.length,
        items: statsByDay.slice(offset, offset + limit),
      };
    },
    [statsByDay]
  );

  return (
    <PaginatedTable
      mainHeader="Visiting Analytics"
      className="daily-visiting-analytics"
      forceShowProgress={props.loading}
      tableHeaders={headers}
      minLoadingTimeMs={200}
      tableDataSource={dataSource}
      initialPageSize={10}
      tableRowFactory={(item) => {
        return [
          format(stringToDate(item.date, false), "dd.MM.yyyy"),
          format(stringToDate(item.date, false), "EEEE"),
          formatApproximateDuration(item.statistics?.minTimeInSec),
          formatApproximateDuration(item.statistics?.maxTimeInSec),
          formatApproximateDuration(item.statistics?.avgTimeInSec),
          formatHourAmPm(item.statistics?.peakHour),
        ];
      }}
    />
  );
}

export default DailyVisitingAnalytics;
