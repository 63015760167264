import React, { PureComponent, Ref, RefCallback } from "react";
import PropTypes from "prop-types";
import PageContext, { PageContextType } from "../PageContext";
import { coalesce, errorOnDev, getPixelRatio, isCancelException, makePageCallback, mergeRefs } from "../shared/utils";
import { isPage, isRef, isRotate } from "../shared/propTypes";
import { PDFPageProxy, RenderParameters } from "../pdfjs";

interface InternalProps extends Omit<PageContextType, "page" | "scale"> {
  page: PDFPageProxy;
  scale: number;
  canvasRef?: RefCallback<HTMLCanvasElement> | Ref<HTMLCanvasElement>;
}

class PageCanvasInternal extends PureComponent<InternalProps> {
  static propTypes = {
    canvasRef: isRef,
    onRenderStart: PropTypes.func,
    onRenderError: PropTypes.func,
    onRenderSuccess: PropTypes.func,
    page: isPage.isRequired,
    renderInteractiveForms: PropTypes.bool,
    rotate: isRotate,
    scale: PropTypes.number.isRequired,
  };

  private canvasLayer: HTMLCanvasElement | null = null;
  private renderer: ReturnType<PDFPageProxy["render"]> | null = null;

  componentDidMount() {
    this.drawPageOnCanvas();
  }

  componentDidUpdate(prevProps: InternalProps) {
    const { page, renderInteractiveForms } = this.props;
    if (renderInteractiveForms !== prevProps.renderInteractiveForms) {
      // Ensures the canvas will be re-rendered from scratch. Otherwise all form data will stay.
      page.cleanup();
      this.drawPageOnCanvas();
    }
  }

  componentWillUnmount() {
    this.cancelRenderingTask();

    /**
     * Zeroing the width and height cause most browsers to release graphics
     * resources immediately, which can greatly reduce memory consumption.
     */
    if (this.canvasLayer) {
      this.canvasLayer.width = 0;
      this.canvasLayer.height = 0;
      this.canvasLayer = null;
    }
  }

  cancelRenderingTask() {
    if (this.renderer) {
      this.renderer.cancel();
      this.renderer = null;
    }
  }

  onRenderStart = () => {
    const { onRenderStart, page, scale } = this.props;
    if (onRenderStart) onRenderStart(makePageCallback(page, scale));
  };

  onRenderSuccess = () => {
    this.renderer = null;
    const { onRenderSuccess, page, scale } = this.props;
    if (onRenderSuccess) onRenderSuccess(makePageCallback(page, scale));
  };

  onRenderError = (error: Error) => {
    if (isCancelException(error)) {
      return;
    }
    errorOnDev(error);
    if (this.props.onRenderError) this.props.onRenderError(error);
  };

  get renderViewport() {
    const { page, rotate, scale } = this.props;
    const pixelRatio = getPixelRatio();
    return page.getViewport({ scale: scale * pixelRatio, rotation: rotate });
  }

  get viewport() {
    const { page, rotate, scale } = this.props;
    return page.getViewport({ scale, rotation: rotate });
  }

  drawPageOnCanvas = () => {
    const canvas = this.canvasLayer;

    if (!canvas) {
      return null;
    }

    canvas.width = this.renderViewport.width;
    canvas.height = this.renderViewport.height;
    canvas.style.width = `${Math.floor(this.viewport.width)}px`;
    canvas.style.height = `${Math.floor(this.viewport.height)}px`;

    const renderContext: RenderParameters = {
      get canvasContext() {
        return canvas!.getContext("2d")!;
      },
      viewport: this.renderViewport,
      renderInteractiveForms: this.props.renderInteractiveForms,
    };

    this.onRenderStart();

    // If another render is in progress, let's cancel it
    this.cancelRenderingTask();

    this.renderer = this.props.page.render(renderContext);

    return this.renderer.promise.then(this.onRenderSuccess).catch(this.onRenderError);
  };

  render() {
    return (
      <canvas
        className="react-pdf__Page__canvas"
        dir="ltr"
        ref={mergeRefs(this.props.canvasRef, (ref) => {
          this.canvasLayer = ref;
        })}
        style={{
          display: "block",
          userSelect: "none",
        }}
      />
    );
  }
}

interface Props extends Omit<InternalProps, "page" | "scale"> {
  page?: PDFPageProxy;
  scale?: number;
}

export default function PageCanvas(props: Props) {
  return (
    <PageContext.Consumer>
      {(context) => {
        const page = context?.page || props.page;
        const scale = coalesce([context?.scale, props.scale], 1);
        if (page) {
          return <PageCanvasInternal {...context} {...props} page={page} scale={scale} />;
        }
      }}
    </PageContext.Consumer>
  );
}
