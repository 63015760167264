import React, { Component } from "react";
import cx, { ClassValue } from "clsx";

export type TabPanelProps = {
  className?: ClassValue;
  selectedClassName?: ClassValue;
  forceRender?: boolean;
  id?: string; // private
  selected?: boolean; // private
  tabId?: string; // private
};

export default class TabPanel extends Component<TabPanelProps> {
  public static tabsRole = "TabPanel";

  render() {
    const { children, className, forceRender, id, selected, selectedClassName, tabId, ...attributes } = this.props;
    const classNameOrDefault = className || "react-tabs";
    const selectedClassNameOrDefault = selectedClassName || `${classNameOrDefault}--selected`;

    return (
      <div
        {...attributes}
        className={cx(classNameOrDefault, selected ? selectedClassNameOrDefault : undefined)}
        role="tabpanel"
        id={id}
        aria-labelledby={tabId}
      >
        {forceRender || selected ? children : null}
      </div>
    );
  }
}
