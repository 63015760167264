import clsx from "clsx";
import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isSameDay,
  isSameMonth,
  isSameYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { useCallback, useEffect, useRef } from "react";
import { DateRangePicker, Range, RangeKeyDict, StaticRange } from "react-date-range";
import { TimeframeType } from "../../api";
import { useStateEx } from "../../services/hooks";
import { DateRange as DateRangeType } from "../../state/globalFilters";
import { Calendar, DropdownIcon } from "../icons/Icons";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./DateRange.scss";

function rangeEquals(a: Range, b: Range) {
  return !!(
    a.startDate &&
    a.endDate &&
    b.startDate &&
    b.endDate &&
    isSameDay(a.startDate, b.startDate) &&
    isSameDay(a.endDate, b.endDate)
  );
}

interface PredefinedRange {
  startDate?: Date;
  endDate?: Date;
  timeframeType: TimeframeType;
}

function isPredefinedRange(range: any): range is PredefinedRange {
  return typeof range.timeframeType === "number";
}

export function getStaticRanges(includeNowOption: boolean = false): StaticRange[] {
  const now = new Date();
  const days = {
    startOfWeek: startOfWeek(now),
    endOfWeek: endOfWeek(now),
    startOfToday: startOfDay(now),
    endOfToday: endOfDay(now),
    startOfYesterday: startOfDay(addDays(now, -1)),
    endOfYesterday: endOfDay(addDays(now, -1)),
    startOfMonth: startOfMonth(now),
    endOfMonth: endOfMonth(now),
    startOfLastMonth: startOfMonth(addMonths(now, -1)),
    endOfLastMonth: endOfMonth(addMonths(now, -1)),
  };

  const range: StaticRange[] = [
    {
      label: "Today",
      range: () => ({
        startDate: days.startOfToday,
        endDate: days.endOfToday,
        timeframeType: TimeframeType.Today,
      }),
      isSelected(range: Range) {
        const definedRange = this.range();
        return rangeEquals(range, definedRange);
      },
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: days.startOfYesterday,
        endDate: days.endOfYesterday,
        timeframeType: TimeframeType.Yesterday,
      }),
      isSelected(range: Range) {
        const definedRange = this.range();
        return rangeEquals(range, definedRange);
      },
    },
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: addDays(days.startOfToday, -6),
        endDate: days.endOfToday,
        timeframeType: TimeframeType.Last7Days,
      }),
      isSelected(range: Range) {
        const definedRange = this.range();
        return rangeEquals(range, definedRange);
      },
    },
    {
      label: "Last 30 Days",
      range: () => ({
        startDate: addDays(days.startOfToday, -29),
        endDate: days.endOfToday,
        timeframeType: TimeframeType.Last30Days,
      }),
      isSelected(range: Range) {
        const definedRange = this.range();
        return rangeEquals(range, definedRange);
      },
    },
    {
      label: "This Month",
      range: () => ({
        startDate: days.startOfMonth,
        endDate: days.endOfMonth,
        timeframeType: TimeframeType.ThisMonth,
      }),
      isSelected(range: Range) {
        const definedRange = this.range();
        return rangeEquals(range, definedRange);
      },
    },
    {
      label: "Last Month",
      range: () => ({
        startDate: days.startOfLastMonth,
        endDate: days.endOfLastMonth,
        timeframeType: TimeframeType.PreviousMonth,
      }),
      isSelected(range: Range) {
        const definedRange = this.range();
        return rangeEquals(range, definedRange);
      },
    },
    {
      label: "This Year",
      range: () => ({
        startDate: startOfYear(new Date()),
        endDate: endOfDay(new Date()),
        timeframeType: TimeframeType.ThisYear,
      }),
      isSelected(range: Range) {
        const definedRange = this.range();
        return rangeEquals(range, definedRange);
      },
    },
    {
      label: "Last Year",
      range: () => ({
        startDate: startOfYear(addYears(new Date(), -1)),
        endDate: endOfYear(addYears(new Date(), -1)),
        timeframeType: TimeframeType.PreviousYear,
      }),
      isSelected(range: Range) {
        const definedRange = this.range();
        return rangeEquals(range, definedRange);
      },
    },
    {
      label: "All Time",
      range: () => ({
        startDate: undefined,
        endDate: undefined,
        timeframeType: TimeframeType.AllTime,
      }),
      isSelected(range: Range) {
        return !range.startDate && !range.endDate;
      },
    },
  ];
  if (includeNowOption)
    range.splice(0, 0, {
      label: "Now",
      range: () => ({
        startDate: now,
        endDate: undefined,
        timeframeType: TimeframeType.Now,
      }),
      isSelected(range: Range) {
        return !!range.startDate && !range.endDate;
      },
    });
  return range;
}

export function DateRange(props: {
  className?: string;
  value: DateRangeType;
  includeNowOption?: boolean;
  onChange: (value: DateRangeType) => void;
}) {
  type State = {
    visible: boolean;
    pickingEnd: boolean;
    selection: [Range];
  };

  const { className, value, onChange, includeNowOption } = props;

  const ranges = getStaticRanges(includeNowOption);
  const timeButtonRef = useRef<HTMLButtonElement>(null);
  const calendarWrapperRef = useRef<HTMLDivElement>(null);
  const { state, mergeState } = useStateEx<State>(
    {
      selection: [{ startDate: value.startDate, endDate: value.endDate }],
      visible: false,
      pickingEnd: false,
    },
    "datePicker"
  );

  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      const calendarWrapper = calendarWrapperRef.current;
      if (
        calendarWrapper &&
        event.target instanceof Node &&
        !calendarWrapperRef.current.contains(event.target) &&
        state.visible
      ) {
        calendarWrapper.style.display = "none";
        mergeState({ visible: false });
      }
    },
    [calendarWrapperRef, mergeState, state.visible]
  );

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  function handleButtonClick() {
    const timeFilter = timeButtonRef.current;
    const calendarWrapper = calendarWrapperRef.current;
    if (timeFilter && calendarWrapper) {
      if (state.visible) {
        calendarWrapper.style.display = "none";
        mergeState({
          visible: false,
          pickingEnd: false,
        });
        if (state.pickingEnd) {
          onChange({
            ...state.selection[0],
            timeframeType: TimeframeType.CustomRange,
          });
        }
      } else {
        const position = timeFilter.getBoundingClientRect();
        calendarWrapper.style.display = "flex";
        let top = timeFilter.offsetHeight;
        let right = position.right - calendarWrapper.offsetWidth;
        calendarWrapper.style.top = `${top < 0 ? 0 : top}px`;
        calendarWrapper.style.right = `${right > 0 ? 0 : right}px`;
        mergeState({
          visible: true,
        });
      }
    }
  }

  function handleSelection(range: RangeKeyDict) {
    const value = range.range1 as PredefinedRange | Range;

    const newSelection: [Range] = [
      {
        startDate: value.startDate,
        endDate: value.endDate,
      },
    ];

    if (isPredefinedRange(value)) {
      mergeState({
        selection: newSelection,
        visible: false,
        pickingEnd: false,
      });
      onChange(value);
    } else if (!state.pickingEnd) {
      mergeState({
        selection: newSelection,
        pickingEnd: true,
      });
    } else {
      mergeState({
        selection: newSelection,
        visible: false,
        pickingEnd: false,
      });
      onChange({
        startDate: value.startDate,
        endDate: value.endDate,
        timeframeType: TimeframeType.CustomRange,
      });
    }
  }

  const renderValue = () => {
    const range = ranges.find((r) => r.isSelected(value));
    if (range) {
      return range.label;
    } else if (value.startDate && value.endDate) {
      if (isSameDay(value.startDate, value.endDate)) {
        return format(value.startDate, "d. MMM, yyyy");
      } else if (isSameMonth(value.startDate, value.endDate)) {
        return `${format(value.startDate, "d.")} - ${format(value.endDate, "d. MMM, yyyy")}`;
      } else if (isSameYear(value.startDate, value.endDate)) {
        return `${format(value.startDate, "d. MMM")} - ${format(value.endDate, "d. MMM, yyyy")}`;
      } else {
        return `${format(value.startDate, "d. MMM, yyyy")} - ${format(value.endDate, "d. MMM, yyyy")}`;
      }
    } else {
      return "Time";
    }
  };

  return (
    <div className={clsx(className, "date-range-form")}>
      <div ref={calendarWrapperRef} className="calendar-wrapper" style={{ display: state.visible ? "flex" : "none" }}>
        <DateRangePicker
          ranges={state.selection}
          onChange={handleSelection}
          showMonthAndYearPickers={true}
          showDateDisplay={false}
          showPreview={true}
          weekStartsOn={1}
          maxDate={new Date()}
          minDate={new Date(1990, 1, 1)}
          staticRanges={ranges}
          inputRanges={[]}
        />
      </div>
      <button className="selector" ref={timeButtonRef} onClick={handleButtonClick} type="button">
        <Calendar className="icon-left" />
        <span className="value">{renderValue()}</span>
        <DropdownIcon className="icon-right" />
      </button>
    </div>
  );
}
