// eslint-disable-next-line import/no-internal-modules
import { PDFDocumentProxy } from "../pdf/pdfjs";
import { createContext } from "react";
import LinkService from "./LinkService";

export type DocumentContextType = {
  pdf?: PDFDocumentProxy | false;
  rotate?: 0 | 90 | 180 | 270;
  linkService: LinkService;
  registerPage: (page: number, ref: any) => void;
  unregisterPage: (page: number) => void;
  imageResourcesPath?: string;
  renderMode?: "canvas" | "svg" | "none";
};

export default createContext<DocumentContextType | null>(null);
