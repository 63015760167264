import { Gender } from "../../../../api";
import { Option, Select } from "../../../forms/Select";
import { editorClassName, EditorProps } from "./common";

export function GenderEditor(props: EditorProps) {
  const selectOptions: Option[] = [
    { label: "Male", value: Gender.Male },
    { label: "Female", value: Gender.Female },
    { label: "Other", value: Gender.Other },
  ];

  return (
    <div className={editorClassName(props, "specific-gender")}>
      <h2>{props.description}</h2>
      <div className="input-row single">
        <span className="prefix">Gender is</span>
        <Select
          type="form"
          placeholder="Select gender"
          errorLabel="Gender"
          isMulti={false}
          required={true}
          value={
            typeof props.value.specificGender?.gender === "number" ? props.value.specificGender.gender : Gender.Male
          }
          options={selectOptions}
          onChange={(value) => {
            props.onChange({
              specificGender: {
                gender: typeof value === "number" ? value : Gender.Male,
              },
            });
          }}
          error={props.error?.gender}
        />
      </div>
    </div>
  );
}
