import { AxiosResponse } from "axios";
import { range } from "lodash";
import { MouseEventHandler, Ref, useCallback, useImperativeHandle } from "react";
import { isAxiosResponse } from "../../services/api";
import { DataPage } from "../../services/types";
import Card from "../cards/Card";
import { AddNew } from "../icons/Icons";
import { PaginatedViewApi, usePaginatedView } from "./PaginatedView";
import { Pagination } from "./Pagination";

import "./PaginatedCards.scss";

export type PaginatedCardsApi = PaginatedViewApi;

type DataSourceReturn<TModel> = Promise<AxiosResponse<DataPage<TModel>>> | Promise<DataPage<TModel>> | DataPage<TModel>;

type PaginatedCardsProps<TModel> = {
  apiRef?: Ref<PaginatedCardsApi>;
  pageSize?: number;
  onAddNew?: MouseEventHandler;
  dataSource: (offset: number, limit: number) => DataSourceReturn<TModel>;
  loadedCardFactory: (model: TModel, index: number) => JSX.Element;
  loadingCardFactory: (index: number) => JSX.Element;
  minLoadingTimeMs?: number;
  hidePagination?: boolean;
  hideAddNew?: boolean;
  forceShowProgress?: boolean;
  onTotalCountChange?: (count: number) => void;
};

function getNmPages(totalCount: number, pageSize: number, hideAddNew: boolean) {
  let rest = totalCount - pageSize;
  if (!hideAddNew) rest += 1;
  if (rest <= 0) return 1;
  return 1 + Math.ceil(rest / pageSize);
}

function getLimitSkip(page: number, pageSize: number, hideAddNew: boolean) {
  let limit = pageSize;
  if (page === 0 && !hideAddNew) {
    limit -= 1;
  }
  let skip = page * pageSize;
  if (page !== 0 && !hideAddNew) {
    skip -= 1;
  }
  return { limit, skip };
}

export function PaginatedCards<TModel>(props: PaginatedCardsProps<TModel>) {
  const pageSize = props.pageSize || 6;
  const hideAddNew = props.hideAddNew || false;
  const dataSource = props.dataSource;

  const loadCallback = useCallback(
    async (page: number) => {
      const { limit, skip } = getLimitSkip(page, pageSize, hideAddNew);
      const result = await dataSource(skip, limit);
      const data = isAxiosResponse<DataPage<TModel>>(result) ? result.data : result;
      return data;
    },
    [dataSource, pageSize, hideAddNew]
  );

  const { state, refreshPage, loadPage, reset } = usePaginatedView({
    load: loadCallback,
    forceShowProgress: props.forceShowProgress,
    minLoadingTimeMs: props.minLoadingTimeMs,
    onTotalCountChange: props.onTotalCountChange,
  });

  const { limit, skip } = getLimitSkip(state.currentPage, pageSize, hideAddNew);
  const pageCount = getNmPages(state.totalCount, pageSize, hideAddNew);
  const showAddNewCard = !hideAddNew && state.currentPage === 0;

  const renderCards = () => {
    if (state.loading) {
      return range(0, limit).map(props.loadingCardFactory);
    } else {
      return state.items.map((c, i) => {
        return props.loadedCardFactory(c, skip + i);
      });
    }
  };

  useImperativeHandle(
    props.apiRef,
    () => {
      return {
        refreshPage,
        loadPage,
        reset,
      };
    },
    [refreshPage, loadPage, reset]
  );

  return (
    <section className="paginated-cards">
      <div className="cards">
        {showAddNewCard && (
          <Card className="add-new" onClick={props.onAddNew}>
            <AddNew />
          </Card>
        )}
        {renderCards()}
      </div>
      {!props.hidePagination && (
        <footer>
          <Pagination currentPage={state.currentPage} onPageChange={loadPage} pageCount={pageCount} />
        </footer>
      )}
    </section>
  );
}
