import { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Redirect, useHistory, useRouteMatch } from "react-router";
import { toast as alert } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  CampaignsApi,
  MessageModel,
  MessageSort,
  MessageStatus,
  MessageView,
  MessageViewDataPage,
  MessagesApi,
  MessagesApiGetRequest,
  OfferDraftApi,
} from "../api";
import Card from "../app/cards/Card";
import { MessageCard } from "../app/cards/MessageCard";
import { Option, OptionValueType, Select } from "../app/forms/Select";
import { Download } from "../app/icons/Icons";
import EditMessage from "../app/modals/EditMessage";
import { Modal, useModal } from "../app/modals/Modal";
import { Result } from "../app/modals/utils";
import { AlertContent, ServerUnavailable } from "../app/widgets/Alerts";
import Loading from "../app/widgets/Loading";
import { PageHeader } from "../app/widgets/PageHeaders";
import { PaginatedCards, PaginatedCardsApi } from "../app/widgets/PaginatedCards";
import { YearMonthPicker } from "../app/widgets/YearMonthPicker";
import { confirmDialog } from "../services/alerts";
import { isHttpOk } from "../services/api";
import { stringFromDate } from "../services/date";
import { useCachedInstance, useStateEx } from "../services/hooks";
import { ServiceResult } from "../services/types";
import useBreakpoint from "../services/useBreakpoint";
import { getApiConfig } from "../state/configuration";
import { useGlobalSearch } from "../state/globalSearch";

import "./Messages.scss";

const sortOptions = [
  { label: "Sort: Title Asc", value: MessageSort.TitleAsc },
  { label: "Sort: Title Desc", value: MessageSort.TitleDesc },
  { label: "Sort: Newest", value: MessageSort.Newest },
  { label: "Sort: Oldest", value: MessageSort.Oldest },
];

const statusOptions = [
  { label: "Status: All", value: -1 },
  { label: "Status: Live", value: MessageStatus.Live },
  { label: "Status: Scheduled", value: MessageStatus.Scheduled },
  { label: "Status: Expired", value: MessageStatus.Expired },
  { label: "Status: Draft", value: -2 },
];

enum FilterOption {
  All = -1,
  Drafts = -2,
}

type MessageStatusFilter = MessageStatus | FilterOption;

interface EditModalState {
  id: number | null;
  draftId?: number | null;
  model: MessageModel | null;
}

interface State {
  totalCount: number;
  campaignId: number;
  campaignOptions: Option[];
  sortMode: MessageSort;
  filterStatus: MessageStatusFilter;
  filterFrom: Date | null;
  filterTo: Date | null;
}

const breakpoints = { "columns-1": 0, "columns-2": 1200, "columns-3": 2000 };

export default function Messages(props: { openNew: boolean }) {
  const route = useRouteMatch<{ campaignId: string }>();
  const history = useHistory();
  const { search } = useGlobalSearch();
  const editModal = useModal<EditModalState>();
  const { openModal: openEditModal, closeModal: closeEditModal } = editModal;
  const breakpoint = useBreakpoint(breakpoints).breakpoint;

  const campaignIdParam = useMemo(() => {
    if (route.params.campaignId === undefined) {
      return undefined;
    } else {
      const campaignId = parseInt(route.params.campaignId, 10);
      if (isNaN(campaignId)) {
        alert.error(<AlertContent message="Selected Campaign could not be found" />);
        return null;
      }
      return campaignId;
    }
  }, [route.params.campaignId]);

  const { state, mergeState } = useStateEx<State>({
    totalCount: 0,
    campaignId: campaignIdParam ?? 0,
    campaignOptions: [],
    sortMode: MessageSort.Newest,
    filterStatus: -1,
    filterFrom: null,
    filterTo: null,
  });
  const pageApi = useRef<PaginatedCardsApi>(null);
  const pageSize = breakpoint === "columns-3" ? 9 : breakpoint === "columns-2" ? 6 : 4;

  useEffect(() => {
    (async () => {
      const config = getApiConfig();
      try {
        const campaigns = await new CampaignsApi(config).getForLookup();
        const campaignOptions =
          campaigns.data?.map((c) => ({
            label: c.name!,
            value: c.id,
          })) || [];
        campaignOptions.splice(0, 0, { label: "All Campaigns", value: 0 });

        mergeState({
          campaignOptions: campaignOptions,
        });
      } catch (error) {
        alert.error(<ServerUnavailable message="Unable to fetch campaign list! Please check your connection." />);
      }
    })();
  }, [mergeState]);

  useEffect(() => {
    if (props.openNew) {
      const handle = window.setTimeout(() => {
        openEditModal({
          id: null,
          model: null,
        });
      }, 100);
      return () => {
        window.clearTimeout(handle);
      };
    }
  }, [props.openNew, mergeState, openEditModal]);

  const [formModified, setFormModified] = useState<boolean>(false);
  const changed = () => setFormModified(true);

  const isMessageStatus = (val: MessageStatusFilter): val is MessageStatus => {
    return val !== FilterOption.All && val !== FilterOption.Drafts;
  };
  const status = isMessageStatus(state.filterStatus) ? state.filterStatus : undefined;
  const filters = useCachedInstance<MessagesApiGetRequest>({
    includeInactive: false,
    search: search,
    sort: state.sortMode,
    campaignIds: state.campaignId ? [state.campaignId] : undefined,
    status: status,
    validFrom: stringFromDate(state.filterFrom, false) || undefined,
    validTo: stringFromDate(state.filterTo, false) || undefined,
  });

  const loadData = useCallback(
    async (offset: number, limit: number) => {
      const config = getApiConfig();
      const requestParams = {
        skip: offset,
        limit: limit,
        ...filters,
      };

      let data: AxiosResponse<MessageViewDataPage>;
      if (state.filterStatus === FilterOption.Drafts) {
        const api = new OfferDraftApi(config);
        data = await api.getDrafts(requestParams);
      } else {
        const api = new MessagesApi(config);
        data = await api.get(requestParams);
      }

      window.setTimeout(() => ReactTooltip.rebuild(), 1000);
      return data;
    },
    [filters, state.filterStatus]
  );

  const editMessage = async (messageId: number, draftId?: number) => {
    try {
      const config = getApiConfig();
      const api = new MessagesApi(config);
      const response = await api.getOne({
        messageId: messageId,
        draftId: draftId,
      });
      if (response.status === 200) {
        openEditModal({ model: response.data, id: response.data.id, draftId: response.data.draftId });
      } else if (response.status === 404) {
        alert.error(<AlertContent message="Message not found." />);
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }
  };

  const newMessage = () => {
    openEditModal({
      id: null,
      model: null,
    });
  };

  const setStatusFilter = (value: OptionValueType | null) => {
    if (typeof value === "number") {
      mergeState({ filterStatus: value });
    }
  };

  const setCampaignFilter = (value: OptionValueType | null) => {
    if (typeof value === "number") {
      mergeState({ campaignId: value });
      if (pageApi.current) pageApi.current.reset();

      const path = value === 0 ? "/messages" : `/campaign/${value}/messages`;
      history.replace(path);
    }
  };

  const setSortMode = (value: OptionValueType | null) => {
    if (typeof value === "number") {
      mergeState({ sortMode: value });
    }
  };

  const saveMessage = async (model: MessageModel) => {
    if (editModal.state === undefined) return Result.Invalid();

    let message: string;
    let result: AxiosResponse<ServiceResult<{}>>;
    const config = getApiConfig();
    const api = new MessagesApi(config);

    try {
      if (!editModal.state.id) {
        message = `Message '${model.translations![0].title}' created.`;
        result = await api.create({
          messageModel: model,
          draftId: editModal.state.draftId || undefined,
        });
      } else {
        message = `Message '${model.translations![0].title}' saved.`;
        result = await api.update({
          messageId: editModal.state.id,
          messageModel: model,
          draftId: editModal.state.draftId || undefined,
        });
      }

      if (isHttpOk(result)) {
        closeModalAndReset();
        alert.success(<AlertContent message={message} />);
        if (pageApi.current) pageApi.current.refreshPage();
        return Result.Ok;
      } else {
        return Result.Invalid(result);
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }

    return Result.Invalid();
  };

  const saveDraft = async (model: MessageModel) => {
    if (editModal.state === undefined) return Result.Invalid();

    let message: string;
    let result: AxiosResponse<ServiceResult<{}>>;
    const config = getApiConfig();
    const api = new OfferDraftApi(config);

    const draft = {
      id: editModal.state.draftId,
      offerId: editModal.state.id,
      message: model,
    };
    message = draft.offerId ? `Draft for Message '${model.translations![0].title}' saved.` : `Unpublished draft saved.`;

    try {
      const params = {
        offerDraftModel: draft,
      };
      result = draft.id ? await api.updateDraft(params) : await api.createDraft(params);
      if (isHttpOk(result)) {
        closeModalAndReset();
        alert.success(<AlertContent message={message} />);
        if (pageApi.current) pageApi.current.refreshPage();
        return Result.Ok;
      } else {
        return Result.Invalid(result);
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }

    return Result.Invalid();
  };

  const discardDraft = async () => {
    const draftId = editModal?.state?.draftId;
    if (draftId) {
      let result: AxiosResponse<ServiceResult<{}>>;
      const config = getApiConfig();
      const api = new OfferDraftApi(config);
      try {
        result = await api.deleteDraft({ draftId: draftId });
        if (isHttpOk(result)) {
          closeModalAndReset();
          alert.success(<AlertContent message={"Draft successfully discarded."} />);
          if (pageApi.current) pageApi.current.refreshPage();
          return Result.Ok;
        } else {
          return Result.Invalid(result);
        }
      } catch (error) {
        alert.error(<ServerUnavailable diagnosticError={error} />);
      }
    }
    return Result.Invalid();
  };

  const handleDelete = async (messageId?: number, draftId?: number, messageTitle?: string) => {
    const onConfirm = () => deleteMessage(messageId, draftId, messageTitle);
    confirmDialog("Delete this message?", onConfirm);
  };

  const deleteMessage = async (messageId?: number, draftId?: number, messageTitle?: string) => {
    if (!messageId && !draftId) return Result.Invalid();

    try {
      const config = getApiConfig();
      let result: AxiosResponse<ServiceResult<any>> | undefined;

      if (messageId) {
        const api = new MessagesApi(config);
        result = await api._delete({
          messageId: messageId,
        });
      }
      if (draftId) {
        const api = new OfferDraftApi(config);
        const draftResult = await api.deleteDraft({ draftId: draftId });
        if (!messageId) result = draftResult;
      }
      if (result && isHttpOk(result)) {
        const messageOrDraft = messageId ? "message" : "draft";
        const message = `Deleted ${messageOrDraft}: ${messageTitle}`; // model is a proxy, so when we set it to null later this would be lost
        alert.success(<AlertContent message={message} />);
        if (pageApi.current) pageApi.current.refreshPage();
        return Result.Ok;
      } else {
        return Result.Invalid(result);
      }
    } catch (error) {
      alert.error(<ServerUnavailable diagnosticError={error} />);
    }

    return Result.Invalid();
  };

  const handleCancel = () => {
    if (formModified) confirmDialog("Form has unsaved changes. Discard all?", closeModalAndReset);
    else closeModalAndReset();
  };

  const closeModalAndReset = () => {
    setFormModified(false);
    closeEditModal();
  };

  const loadingCardFactory = (index: number) => {
    return (
      <Card className="message-card loading" key={index}>
        <Loading />
      </Card>
    );
  };

  const cardFactory = (item: MessageView) => {
    const draftId = item.draftId || undefined;
    const duplicateMessage = async () => {
      try {
        const config = getApiConfig();
        const api = new MessagesApi(config);
        const response = await api.getOne({
          messageId: item.id,
          draftId: draftId,
        });
        const model = {
          ...response.data,
          id: undefined,
          name: "Duplicated from " + (response.data.translations![0]?.title || "draft"),
        };
        openEditModal({ model: model, id: null });
      } catch (error) {
        alert.error(<ServerUnavailable />);
      }
    };

    return (
      <MessageCard
        key={item.id || Math.random()}
        {...item}
        highlightText={search}
        onClickDuplicate={duplicateMessage}
        onClickEdit={() => editMessage(item.id, draftId)}
        onClickDelete={() => handleDelete(item.id, draftId, item.campaignTitle ?? "")}
      />
    );
  };

  if (campaignIdParam === null) {
    return <Redirect to="/campaigns" />;
  } else {
    return (
      <div className={`page-content messages ` + breakpoint}>
        <Modal onRequestClose={handleCancel}>
          <EditMessage
            initial={editModal.state?.model || null}
            onSave={saveMessage}
            onCancel={handleCancel}
            onChange={changed}
            onSaveDraft={saveDraft}
            onDiscardDraft={discardDraft}
            existingMessageId={editModal.state?.id ?? undefined}
            existingDraftId={editModal.state?.draftId ?? undefined}
            initialCampaignId={state.campaignId}
          />
        </Modal>
        <PageHeader title="Messages" subTitle={`Found ${state.totalCount} messages`}>
          <div className="toolbar">
            <button onClick={newMessage} type="button" className="new">
              + New Message
            </button>
            <Select
              type="filter"
              required={true}
              className="campaign-filter"
              value={state.campaignId}
              onChange={setCampaignFilter}
              options={state.campaignOptions}
            />
            <YearMonthPicker
              onChange={(from, to) => {
                mergeState({
                  filterFrom: from,
                  filterTo: to,
                });
              }}
            />
            <Select
              type="filter"
              required={true}
              value={state.filterStatus}
              onChange={setStatusFilter}
              options={statusOptions}
            />
            <Select type="filter" required={true} value={state.sortMode} onChange={setSortMode} options={sortOptions} />
            <button className="download">
              <Download />
            </button>
          </div>
        </PageHeader>
        <PaginatedCards
          apiRef={pageApi}
          minLoadingTimeMs={200}
          hideAddNew={true}
          pageSize={pageSize}
          loadedCardFactory={cardFactory}
          loadingCardFactory={loadingCardFactory}
          dataSource={loadData}
          onTotalCountChange={(count) => mergeState({ totalCount: count })}
        />
      </div>
    );
  }
}
