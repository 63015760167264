import { format } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { toast as alert } from "react-toastify";
import {
  CustomerFilter,
  CustomerSort,
  CustomerSummaryStatistic,
  CustomerSummaryView,
  CustomersApi,
  SortDirection,
  TimeframeType,
} from "../../api";
import { isHttpOk } from "../../services/api";
import { useMoneyFormatter } from "../../services/format";
import { useLocalizedText } from "../../services/localization";
import { toCsvValue } from "../../services/string";
import { generateSortableHeader, resetSortHeaders, toggleSortForHeader } from "../../services/table";
import { getApiConfig } from "../../state/configuration";
import { useDeploymentConfig } from "../../state/deployment";
import { useFilterDescriptionText, useIsSingleStore, useTimeLocationFilters } from "../../state/globalFilters";
import { useGlobalSearch } from "../../state/globalSearch";
import Card from "../cards/Card";
import { AlertContent } from "../widgets/Alerts";
import { Avatar } from "../widgets/Avatar";
import { highlight } from "../widgets/HighlightedString";
import { Header, PaginatedTable } from "../widgets/PaginatedTable";

import "./CustomerDataTable.scss";

type CustomerMetricOption = {
  label: string;
  value: CustomerSummaryStatistic;
};

export type CustomerDataTableProps = {
  visitorsOnly?: boolean;
  hideZeros?: boolean;
  selectedFilter?: CustomerFilter;
  selectedStatistic?: CustomerSummaryStatistic;
  customOptions?: CustomerMetricOption[];
};

export function CustomerDataTable(props: CustomerDataTableProps) {
  const { config } = useDeploymentConfig();
  const storeWord = config.translations.store.singular;
  const hasPurchaseHistories = !config.disablePurchaseHistories;

  const statisticOptions = props.customOptions || [
    {
      label: "Touchpoints",
      value: CustomerSummaryStatistic.NmOfTouchpoints,
    },
    {
      label: `${storeWord} Visits`,
      value: CustomerSummaryStatistic.NmOfStoreVisits,
    },
    {
      label: "Beacon Contacts",
      value: CustomerSummaryStatistic.NmOfBeaconContacts,
    },
    {
      label: "Transactions",
      value: CustomerSummaryStatistic.Transactions,
    },
  ];
  if (!props.customOptions && hasPurchaseHistories) {
    statisticOptions.splice(0, 0, {
      label: "Revenue",
      value: CustomerSummaryStatistic.TotalPurchaseAmount,
    });
  }

  const texts = useLocalizedText();
  const { search } = useGlobalSearch();
  const filters = useTimeLocationFilters();
  const { formatMoney } = useMoneyFormatter();
  const route = useRouteMatch<{ statistic: string }>();
  const filterTexts = useFilterDescriptionText();
  const filterTextPeriod = filterTexts.period;
  const locationFilterText = !useIsSingleStore() && filterTexts.locations !== "All Stores" ? filterTexts.locations : "";
  const customerFilter = props.selectedFilter;
  const [userCount, setUserCount] = useState<number>(0);
  const [statisticTotal, setStatisticTotal] = useState<number>(0);
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const columnTexts = texts.customersTable.items;
  const defaultStatHeader = hasPurchaseHistories ? columnTexts.totalRevenue : columnTexts.nmTouchPoints;
  const [headers, setHeaders] = useState<Header[]>([
    generateSortableHeader(columnTexts.fullName),
    generateSortableHeader(columnTexts.email),
    generateSortableHeader(columnTexts.loyaltyNumber),
    generateSortableHeader(defaultStatHeader),
  ]);

  const toggleSort = (index: number) => setHeaders((prev) => toggleSortForHeader(prev, index));
  const resetSort = useCallback(() => {
    setHeaders((prev) => resetSortHeaders(prev));
  }, [setHeaders]);

  const getStatisticTexts = useCallback(
    (statistic: CustomerSummaryStatistic) => {
      switch (statistic) {
        case CustomerSummaryStatistic.NmOfTouchpoints:
          return columnTexts.nmTouchPoints;
        case CustomerSummaryStatistic.NmOfStoreVisits:
          return columnTexts.nmStoreVisits;
        case CustomerSummaryStatistic.NmOfBeaconContacts:
          return columnTexts.nmBeaconVisits;
        case CustomerSummaryStatistic.Transactions:
          return columnTexts.transactions;
        default:
          return columnTexts.totalRevenue;
      }
    },
    [columnTexts]
  );

  const updateStatisticHeader = useCallback(
    (statistic: CustomerSummaryStatistic) => {
      const newStatisticHeader = generateSortableHeader(getStatisticTexts(statistic));
      newStatisticHeader.sortDirection = SortDirection.Descending;
      resetSort();
      setHeaders((prev) => [...prev.slice(0, -1), newStatisticHeader]);
    },
    [getStatisticTexts, resetSort]
  );

  const selectedStat = useMemo(() => {
    if (route.params.statistic !== undefined) {
      const value = parseInt(route.params.statistic, 10);
      if (isNaN(value) || !Object.values(CustomerSummaryStatistic).includes(value)) {
        alert.error(<AlertContent message="Selected Statistic is invalid" />);
        return null;
      }
      const statistic = value as CustomerSummaryStatistic;
      updateStatisticHeader(statistic);
      return statistic;
    } else if (props.selectedStatistic) {
      updateStatisticHeader(props.selectedStatistic);
      return props.selectedStatistic;
    } else {
      return undefined;
    }
  }, [route.params.statistic, updateStatisticHeader, props.selectedStatistic]);

  const defaultValue = selectedStat ?? statisticOptions[0].value;
  const [statistic, setStatistic] = useState(defaultValue);

  const sortedHeader = headers.find((h) => h.sortable && h.sortDirection !== SortDirection.None);
  const sortDirection = sortedHeader?.sortDirection ?? (selectedStat ? SortDirection.Descending : undefined);
  const statisticTitle = getStatisticTexts(statistic).title;
  const sortTitle = sortedHeader?.title || statisticTitle;

  const sortColumn = (() => {
    switch (sortTitle) {
      case columnTexts.fullName.title:
        return CustomerSort.Name;
      case columnTexts.email.title:
        return CustomerSort.Email;
      case columnTexts.loyaltyNumber.title:
        return CustomerSort.LoyaltyNumber;
      default:
        return CustomerSort.Statistic;
    }
  })();

  const showOnlyVisitors = props.visitorsOnly !== undefined ? props.visitorsOnly : !customerFilter;

  const loadExportData = useCallback(async () => {
    const config = getApiConfig();
    const api = new CustomersApi(config);
    var result = api.getCustomerDataCsv({
      skip: 0,
      limit: 0,
      search: search,
      visitorsOnly: showOnlyVisitors,
      sort: sortColumn,
      hideZeros: props.hideZeros,
      sortDirection: sortDirection,
      customerFilter: customerFilter,
      customerSummaryStatistic: statistic,
      ...filters,
    });

    const data = await result;
    if (isHttpOk(data)) {
      return data.data.items || [];
    } else {
      alert.error(<AlertContent message="Failed to load CSV data" />);
      return [];
    }
  }, [customerFilter, filters, search, sortColumn, sortDirection, statistic, showOnlyVisitors, props.hideZeros]);

  async function downloadCsv() {
    setIsExporting(true);
    try {
      const data = await loadExportData();
      const csvHeaders = ["Id", "Name", "Email", "Picture Url", "Loyalty Number", `${statisticTitle}`].join(",");
      let rows = data.map((row) =>
        [
          toCsvValue(row.id),
          toCsvValue(row.name),
          toCsvValue(row.email),
          toCsvValue(row.pictureUrl),
          toCsvValue(row.loyaltyNumber),
          row.statisticValue,
        ].join(",")
      );
      rows = [csvHeaders, ...rows];
      const csv = rows.join("\n");
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `customer-data-with-${selectedStatistic}-${format(Date.now(), "yy.MM.dd-HH:mm")}.csv`;
      link.click();
    } catch {
      alert.error(<AlertContent message="Unexpected error processing export data" />);
    } finally {
      setIsExporting(false);
    }
  }

  const loadData = useCallback(
    async (skip: number, limit: number) => {
      const config = getApiConfig();
      const api = new CustomersApi(config);
      var result = await api.getAll({
        skip,
        limit: limit,
        search: search,
        visitorsOnly: showOnlyVisitors,
        sort: sortColumn,
        hideZeros: props.hideZeros,
        sortDirection: sortDirection,
        customerFilter: customerFilter,
        customerSummaryStatistic: statistic,
        ...filters,
      });

      if (isHttpOk(result)) {
        const data = result.data;
        setUserCount(data.customerSummary?.count || 0);
        setStatisticTotal(data.sumOfStatistics);
        return data.customerSummary!;
      } else {
        alert.error(<AlertContent message="Failed to load customer data" />);
        const empty = { items: [], count: 0 };
        return empty;
      }
    },
    [search, sortColumn, sortDirection, customerFilter, statistic, filters, showOnlyVisitors, props.hideZeros]
  );

  const renderRow = (item: CustomerSummaryView) => {
    return [
      <Link to={`/customer/${item.id}/details`}>
        <Avatar name={item.name!} image={item.pictureUrl} />
        <span className="name">{highlight(item.name, search)}</span>
      </Link>,
      highlight(item.email, search),
      item.loyaltyNumber,
      formatStatisticValue(item.statisticValue),
    ];
  };

  const formatStatisticValue = (value: number) =>
    statistic === CustomerSummaryStatistic.TotalPurchaseAmount ? formatMoney(value) : value;

  const changeStatistic = (stringValue: string) => {
    const value = parseInt(stringValue, 10);
    if (!isNaN(value)) {
      setStatistic(value);
      window.history.pushState(null, "", `/customers/${value}`);
      updateStatisticHeader(value as CustomerSummaryStatistic);
    }
  };

  const statisticDropdown =
    statisticOptions.length > 1 ? (
      <select value={statistic} onChange={(e) => changeStatistic(e.target.value)}>
        {statisticOptions.map((option, i) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    ) : null;

  const getFilteredTitle = (filter: CustomerFilter) => {
    switch (filter) {
      case CustomerFilter.New:
        return "New Customers";
      case CustomerFilter.Loyal:
        return "Loyal Customers";
      case CustomerFilter.MostActive:
        return "Most Active Customers";
      case CustomerFilter.Vip:
        return "VIP Customers";
    }
  };

  const userOrCustomer = hasPurchaseHistories ? `Customers` : `Users`;
  const title = customerFilter ? getFilteredTitle(customerFilter) : userOrCustomer;
  var description = "";
  if (filters.rangeType !== TimeframeType.AllTime) {
    description = (customerFilter ? "" : "Visiting ") + filterTextPeriod;
  }
  if (locationFilterText) {
    if (description) description += " - ";
    description += locationFilterText;
  }
  if (filters.rangeType === TimeframeType.AllTime && !description) {
    description = `All ${userOrCustomer}`;
  }

  const selectedStatistic = statisticTitle.toLowerCase();

  return (
    <Card className="customer-data-table">
      <PaginatedTable
        mainHeader={
          <section>
            <header>
              <div>
                <h1>{title}</h1>
                <p className="description">{description}</p>
              </div>
              {statisticDropdown}
            </header>
            <section className="export-and-totals">
              <button className="secondary export-data-btn" disabled={isExporting} onClick={downloadCsv}>
                {isExporting ? "Exporting..." : "Export Data"}
              </button>
              <div className="total-card">
                <p>{userCount} users</p>
                <p>
                  Total {selectedStatistic}: {formatStatisticValue(statisticTotal)}
                </p>
              </div>
            </section>
          </section>
        }
        initialPageSize={10}
        minLoadingTimeMs={200}
        tableClassName="with-image"
        tableHeaders={headers}
        tableDataSource={loadData}
        tableRowFactory={renderRow}
        onHeaderClick={toggleSort}
      />
    </Card>
  );
}
