// eslint-disable-next-line import/no-internal-modules
import { PDFDocumentProxy, RenderParameters } from "pdfjs-dist/legacy/build/pdf";
import { Rectangle } from "../services/useRectangleSelector";

// eslint-disable-next-line import/no-internal-modules
export * from "pdfjs-dist/legacy/build/pdf";

function relativeToAbsolute(relative: Rectangle, view: { width: number; height: number }) {
  return {
    left: (Math.min(relative.left, relative.right) / 100) * view.width,
    right: (Math.max(relative.left, relative.right) / 100) * view.width,
    top: (Math.min(relative.top, relative.bottom) / 100) * view.height,
    bottom: (Math.max(relative.top, relative.bottom) / 100) * view.height,
  };
}

function fitBBox(box: Rectangle, maxWidth: number, maxHeight: number) {
  const selectionWidth = box.right - box.left;
  const selectionHeight = box.bottom - box.top;
  const scaleWidth = maxWidth / selectionWidth;
  const scaleHeight = maxHeight / selectionHeight;
  const scale = Math.min(scaleWidth, scaleHeight);
  return {
    scale: scale,
    box: {
      left: box.left * scale,
      right: box.right * scale,
      top: box.top * scale,
      bottom: box.bottom * scale,
    },
  };
}

type RenderOptions = {
  pageNm: number;
  selection: Rectangle;
  maxWidth: number;
  maxHeight: number;
  asDataString: boolean;
};

export async function renderToImage(pdf: PDFDocumentProxy, options: RenderOptions): Promise<string | Blob> {
  const page = await pdf.getPage(options.pageNm);
  const fullView = page.getViewport({ scale: 1 });

  const clipBox = relativeToAbsolute(options.selection, fullView);
  const { scale, box: scaledBox } = fitBBox(clipBox, options.maxWidth, options.maxHeight);

  const clippedView = fullView.clone({ scale: scale, offsetX: -scaledBox.left, offsetY: -scaledBox.top });
  clippedView.width = scaledBox.right - scaledBox.left;
  clippedView.height = scaledBox.bottom - scaledBox.top;

  const canvas = document.createElement("canvas");
  const context = canvas!.getContext("2d")!;
  const renderContext: RenderParameters = {
    viewport: clippedView,
    canvasContext: context,
    renderInteractiveForms: false,
    includeAnnotationStorage: false,
  };
  canvas.width = renderContext.viewport.width;
  canvas.height = renderContext.viewport.height;
  const result = page.render(renderContext);

  await result.promise;

  return new Promise<string | Blob>((resolve, reject) => {
    try {
      if (options.asDataString) {
        const data = canvas.toDataURL("image/png");
        // release canvas resources
        canvas.width = 0;
        canvas.height = 0;
        canvas.remove();
        resolve(data);
      } else {
        canvas.toBlob((blob) => {
          // release canvas resources
          canvas.width = 0;
          canvas.height = 0;
          canvas.remove();
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Received null blob!"));
          }
        }, "image/png");
      }
    } catch (err) {
      reject(err);
    }
  });
}
