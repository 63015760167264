import { FieldErrors } from "react-hook-form";
import { PurchaseAction, PurchaseCheck } from "../../../../../api";
import { safeParse } from "../../../../../services/number";
import { Select } from "../../../../forms/Select";

import "./PurchaseCheckInput.scss";

export function PurchaseCheckInput(props: {
  prefix: string;
  currency: string;
  value?: PurchaseCheck;
  onChange: (value: PurchaseCheck) => void;
  error?: FieldErrors<any>;
}) {
  const purchaseActions = [
    { label: "Specific Product(s)", value: PurchaseAction.SpecificProduct },
    { label: "Nothing", value: PurchaseAction.HasNotPurchased },
    { label: "with Total Over", value: PurchaseAction.OrderWithTotalAmount },
    { label: "with Discount Over", value: PurchaseAction.OrderWithDiscountAmount },
  ];

  const selectedType =
    typeof props.value?.purchaseAction === "number" ? props.value.purchaseAction : PurchaseAction.SpecificProduct;
  const selectedAmount = props.value?.amount || 1;
  const hideAmountInput =
    selectedType === PurchaseAction.SpecificProduct || selectedType === PurchaseAction.HasNotPurchased;
  const errorClasses = hideAmountInput ? "error" : "error single-input";

  var amountClassName = "form-input amount";
  const firstClassName = "first";

  const amountError = props.error && props.error["purchaseCheck.amount"];

  return (
    <div className="input-row double purchase-check">
      <span className="prefix">{props.prefix}</span>
      <div className={firstClassName}>
        <Select
          type="form"
          className="frequency"
          required={true}
          options={purchaseActions}
          value={selectedType}
          onChange={(value) => {
            props.onChange({
              amount: selectedAmount,
              purchaseAction: value as PurchaseAction,
            });
          }}
        />
      </div>
      <div className="second">
        {!hideAmountInput && (
          <div className="input-row">
            <label className={amountClassName}>
              <input
                placeholder={"#"}
                type="number"
                value={selectedAmount}
                onChange={(e) => {
                  const amount = safeParse(e.target.value);
                  props.onChange({
                    amount: amount || 1,
                    purchaseAction: selectedType,
                  });
                }}
              />
              {amountError ? <div className={errorClasses}>{amountError.message}</div> : null}
            </label>
            <span className="postfix">{props.currency}</span>
          </div>
        )}
      </div>
    </div>
  );
}
