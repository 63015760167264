import { max } from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { StoreFloorModel } from "../../../api";
import { Select } from "../../../app/forms/Select";
import { MapWithMarkers, Marker } from "../../../app/widgets/MapWithMarkers";
import { useStateEx } from "../../../services/hooks";
import { getTooltip } from "../../../services/tooltip";

interface BeaconInfo {
  id: string;
  name: string;
  areaName: string;
  nmVisits: number;
  existingFloorPlacement: string;
}

export type MapWithBeaconsProps = {
  model: StoreFloorModel;
  allBeacons: readonly BeaconInfo[];
  onChanged: (model: StoreFloorModel) => void;
};

export function MapWithBeacons(props: MapWithBeaconsProps) {
  const { state, mergeState } = useStateEx<{
    x: number;
    y: number;
    initialBeaconId: string | null;
    currentBeaconId: string | null;
    visible: boolean;
    editing: boolean;
  }>({ x: 50, y: 50, initialBeaconId: null, currentBeaconId: null, visible: false, editing: false });
  const popupRef = useRef<HTMLDivElement>(null);
  const layoutRef = useRef<HTMLDivElement>(null);
  const maxNmVisit = max(props.allBeacons.map((b) => b.nmVisits)) || 0;

  function getColor(nmVisits?: number): Marker["color"] {
    if (!nmVisits) return "red";
    if (nmVisits < maxNmVisit * 0.3) return "red";
    if (nmVisits < maxNmVisit * 0.7) return "yellow";
    return "green";
  }

  const placeNewBeacon = ({ x, y }: { x: number; y: number }) => {
    if (state.visible) {
      mergeState({ visible: false });
    } else if (props.allBeacons.length > 0) {
      mergeState({ x: x, y: y, initialBeaconId: null, currentBeaconId: null, visible: true, editing: false });
    }
  };

  const beacons = props.model.beacons || [];

  const getMarkers = () => {
    const markers: Marker[] = beacons.map<Marker>((beacon) => {
      const nmVisits = props.allBeacons.find((i) => i.id === beacon.id)?.nmVisits || 0;
      return {
        x: beacon.x,
        y: beacon.y,
        color: getColor(nmVisits),
        item: beacon,
        tooltip: getTooltip(`${nmVisits} visits`, beacon.name || ""),
        htmlId: "beacon-" + beacon.id,
        onClick: () => {
          if (state.visible) {
            mergeState({ visible: false });
          } else if (props.allBeacons.length > 0) {
            mergeState({
              x: beacon.x!,
              y: beacon.y!,
              initialBeaconId: beacon.id,
              currentBeaconId: beacon.id,
              visible: true,
              editing: true,
            });
          }
        },
      };
    });

    if (state.visible && !state.editing) {
      markers.push({
        x: state.x,
        y: state.y,
        color: "lightblue",
        htmlId: "new-beacon",
      });
    }

    return markers;
  };

  const positionPopup = useCallback(() => {
    if (!popupRef.current) return;
    if (!layoutRef.current) return;

    const beaconId = state.initialBeaconId ? "beacon-" + state.initialBeaconId : "new-beacon";
    const beaconsHtmlEl = document.getElementById(beaconId);
    if (!beaconsHtmlEl) return;

    const layoutRect = layoutRef.current.getBoundingClientRect();
    const beaconRect = beaconsHtmlEl.getBoundingClientRect();
    popupRef.current.style.display = "";
    popupRef.current.style.top = beaconRect.top - layoutRect.top + "px";
    popupRef.current.style.left = beaconRect.left - layoutRect.left + "px";
  }, [state.initialBeaconId]);

  const hidePopup = useCallback(() => {
    if (!popupRef.current) return;
    popupRef.current.style.display = "none";
  }, []);

  useEffect(() => {
    if (state.visible) {
      positionPopup();
    } else {
      hidePopup();
    }
  }, [state.visible, hidePopup, positionPopup]);

  const updateBeacon = async () => {
    if (state.currentBeaconId === null) {
      return;
    }

    let newBeacons = beacons.slice();
    newBeacons = beacons.filter((b) => b.id !== state.initialBeaconId);
    newBeacons = beacons.filter((b) => b.id !== state.currentBeaconId);
    newBeacons.push({
      id: state.currentBeaconId,
      x: state.x,
      y: state.y,
    });
    mergeState({
      initialBeaconId: null,
      currentBeaconId: null,
      visible: false,
    });
    props.onChanged({
      ...props.model,
      beacons: newBeacons,
    });
  };

  const clearBeacon = async () => {
    if (state.initialBeaconId === null) {
      return;
    }
    const newBeacons = beacons.filter((b) => b.id !== state.initialBeaconId);
    mergeState({
      initialBeaconId: null,
      currentBeaconId: null,
      visible: false,
    });
    props.onChanged({
      ...props.model,
      beacons: newBeacons,
    });
  };

  const getOptions = () => {
    return props.allBeacons.map((b) => ({
      label:
        b.existingFloorPlacement.length > 0 ? `${b.name || ""} (placed on ${b.existingFloorPlacement})` : b.name || "",
      value: b.id || "",
      description: b.areaName || "",
    }));
  };

  const isDisabled = !state.visible || state.currentBeaconId === null;

  return (
    <div className="map-with-beacons" ref={layoutRef} style={{ position: "relative" }}>
      <MapWithMarkers
        tooltip={getTooltip(
          props.allBeacons.length > 0
            ? "Please click on the layout to place beacons."
            : "Before placing beacons, please create some for this store using the beacon list."
        )}
        scale={2}
        map={props.model.imageUrl || undefined}
        markers={getMarkers()}
        onClick={placeNewBeacon}
      />
      {state.visible && props.allBeacons.length > 0 ? (
        <div ref={popupRef} style={{ display: "none" }} className="beacon-popup">
          <section>
            <header>
              <h1>{!!state.initialBeaconId ? "Edit beacon" : "Place beacon"}</h1>
            </header>
          </section>
          <form>
            <div className="form-row">
              <Select
                type="form"
                value={state.currentBeaconId}
                isMulti={false}
                required={true}
                placeholder="Select beacon"
                options={getOptions()}
                onChange={(value) => {
                  if (value) {
                    mergeState({ currentBeaconId: value.toString() });
                  }
                }}
              />
            </div>
          </form>
          <div className="buttons">
            <button disabled={isDisabled} type="button" className="primary" onClick={updateBeacon}>
              Save
            </button>
            <button
              type="button"
              className="primary"
              onClick={() => {
                mergeState({ visible: false });
              }}
            >
              Cancel
            </button>
            {state.editing ? (
              <button type="button" className="primary delete" onClick={clearBeacon}>
                Delete
              </button>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}
