import { format } from "date-fns";
import { Frequency, TouchpointsStatisticsView } from "../../api";
import Card from "../../app/cards/Card";
import { Graph } from "../../app/widgets/Graph";

export function TouchpointGraph(props: { tooltip: string; touchpointsView: TouchpointsStatisticsView }) {
  const { statistics, frequency } = props.touchpointsView;
  const nmOfDatapoints = statistics?.length ?? 0;

  const dateFormat = frequency !== Frequency.Hourly ? "dd.MM.yyyy" : nmOfDatapoints <= 24 ? "ha" : "haa d/M";

  const chartData = statistics?.map((item) => {
    return { x: format(new Date(item.date), dateFormat), y: item.total };
  });

  var manualDateTicks: string[] | undefined;

  // If more than 3 months of Daily data, set ticks to first day of month
  const useManualDateTicks = nmOfDatapoints > 90 && frequency === Frequency.Daily;
  if (useManualDateTicks) {
    manualDateTicks = statistics
      ?.filter((item) => new Date(item.date).getDate() === 1)
      .map((item) => format(new Date(item.date), dateFormat));
  }

  const title = "App Touchpoints";

  return (
    <Card className="graph">
      <Graph
        title={title}
        data={chartData || []}
        titleTooltip={props.tooltip}
        manualTicks={manualDateTicks}
        yAxisName="Touchpoints"
      ></Graph>
    </Card>
  );
}
