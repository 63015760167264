import { useLayoutEffect, useMemo, useState, useCallback, useDebugValue } from "react";

export type Config = Record<string, number>;

export type Breakpoint<C extends Config> = {
  breakpoint: keyof C;
  maxWidth?: number | null;
  minWidth: C[keyof C];
};

export type MediaQuery<C extends Config> = {
  breakpoint: keyof C;
  maxWidth: number | null;
  minWidth: C[keyof C];
  query: string;
};

/**
 * Create media query objects
 * @param breakpoints the list of configured breakpoint names and their pixel values
 */
const createMediaQueries = (breakpoints: Config): MediaQuery<Config>[] => {
  const sortedBreakpoints = Object.keys(breakpoints).sort((a, b) => breakpoints[b] - breakpoints[a]);

  return sortedBreakpoints.map((breakpoint, index) => {
    let query = "";
    const minWidth = breakpoints[breakpoint];
    const nextBreakpoint = sortedBreakpoints[index - 1] as string | undefined;
    const maxWidth = nextBreakpoint ? breakpoints[nextBreakpoint] : null;

    if (minWidth >= 0) {
      query = `(min-width: ${minWidth}px)`;
    }

    if (maxWidth !== null) {
      if (query) {
        query += " and ";
      }
      query += `(max-width: ${maxWidth - 1}px)`;
    }

    const mediaQuery: MediaQuery<Config> = {
      breakpoint,
      maxWidth: maxWidth ? maxWidth - 1 : null,
      minWidth,
      query,
    };

    return mediaQuery;
  });
};

const EMPTY_BREAKPOINT = {
  breakpoint: undefined,
  minWidth: undefined,
  maxWidth: undefined,
} as const;

/**
 * A React hook to use the current responsive breakpoint.
 * Will listen to changes using the window.matchMedia API.
 * @param {*} config the list of configured breakpoint names and their pixel values
 * @param {*} [defaultBreakpoint] the optional default breakpoint
 * @param {*} [hydrateInitial] whether to return the default breakpoint on first render. Set to `false` if the real breakpoint should be returned instead. Only applies to the browser, not server-side.
 *
 * @example
 * const breakpoints = { mobile: 0, tablet: 768, desktop: 1280 }
 * ...
 * const result = useBreakpoint(breakpoints)
 * // { breakpoint: string; minWidth: number; maxWidth: number | null } | { breakpoint: undefined; minWidth: undefined; maxWidth: undefined }
 *
 * @example <caption>With default value</caption>
 * const breakpoints = { mobile: 0, tablet: 768, desktop: 1280 }
 * ...
 * const result = useBreakpoint(breakpoints, 'mobile')
 * // breakpoint: { breakpoint: string; minWidth: number; maxWidth: number | null }
 *
 * @example <caption>With default value, but not hydrated. This means the breakpoint might be different on the initial render.</caption>
 * const breakpoints = { mobile: 0, tablet: 768, desktop: 1280 }
 * ...
 * const result = useBreakpoint(breakpoints, 'mobile', false)
 * // breakpoint: { breakpoint: string; minWidth: number; maxWidth: number | null }
 */
function useBreakpoint<C extends Config>(config: C): Breakpoint<C> | typeof EMPTY_BREAKPOINT;
function useBreakpoint<C extends Config>(config: C, defaultBreakpoint: keyof C, hydrateInitial: boolean): Breakpoint<C>;
function useBreakpoint<C extends Config>(config: C, defaultBreakpoint?: keyof C, hydrateInitial?: boolean) {
  /** Memoize list of calculated media queries from config */
  const mediaQueries = useMemo(() => createMediaQueries(config), [config]);

  /** Get initial breakpoint value */
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint<C> | typeof EMPTY_BREAKPOINT>(() => {
    /** Loop through all media queries */
    for (const { query, ...breakpoint } of mediaQueries) {
      /**
       * If we're in the browser and there's no default value,
       * try to match actual breakpoint. If the default value
       * should not be hydrated, use the actual breakpoint.
       */
      if (typeof window !== "undefined" && !(defaultBreakpoint && hydrateInitial)) {
        const mediaQuery = window.matchMedia(query);
        if (mediaQuery.matches) {
          return breakpoint as Breakpoint<C>;
        }
      } else if (breakpoint.breakpoint === defaultBreakpoint) {
        /** Otherwise, try to match default value */
        return breakpoint as Breakpoint<C>;
      }
    }

    return EMPTY_BREAKPOINT;
  });

  /** If there's a match, update the current breakpoint */
  const updateBreakpoint = useCallback(
    ({ matches }: MediaQueryList | MediaQueryListEvent, breakpoint: Breakpoint<C>) => {
      if (matches) {
        setCurrentBreakpoint((current) => {
          if (current.breakpoint === breakpoint.breakpoint) {
            return current;
          }
          return breakpoint;
        });
      }
    },
    []
  );

  useLayoutEffect(() => {
    const subscribers = mediaQueries.map(({ query, ...breakpoint }) => {
      const list = window.matchMedia(query);
      updateBreakpoint(list, breakpoint as Breakpoint<C>);

      const handleChange = (event: MediaQueryListEvent) => {
        updateBreakpoint(event, breakpoint as Breakpoint<C>);
      };

      list.addEventListener("change", handleChange);

      return () => list.removeEventListener("change", handleChange);
    });

    return () => subscribers.forEach((unsubscribe) => unsubscribe());
  }, [mediaQueries, updateBreakpoint]);

  useDebugValue(currentBreakpoint, (c) =>
    typeof c.breakpoint === "string"
      ? `${c.breakpoint} (${c.minWidth} ≤ x${c.maxWidth ? ` < ${c.maxWidth + 1}` : ""})`
      : ""
  );

  return currentBreakpoint;
}

export default useBreakpoint;
