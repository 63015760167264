import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";
import Analytics from "../pages/Analytics";
import AppTouchpoints from "../pages/AppTouchpoints";
import Beacons from "../pages/Beacons";
import BlogPosts from "../pages/BlogPosts";
import Campaigns from "../pages/Campaigns";
import { ChangeLog } from "../pages/ChangeLog";
import CustomerData from "../pages/CustomerData";
import { CustomerDetails } from "../pages/CustomerDetails";
import { CustomerSegments } from "../pages/CustomerSegments";
import FeaturedItems from "../pages/FeaturedItems";
import { HealthCheck } from "../pages/HealthCheck";
import LocationOverview from "../pages/LocationOverview";
import Messages from "../pages/Messages";
import ProductCategories from "../pages/ProductCategories";
import Products from "../pages/Products";
import PushNotificationCampaigns from "../pages/PushNotificationCampaigns";
import RevenueStatistics from "../pages/RevenueStatistics";
import Stores from "../pages/Stores";
import VisitorStatistics from "../pages/VisitorStatistics";
import { ContactTracking } from "../pages/contact-tracking/ContactTracking";
import { CustomerUtils } from "../pages/customer-utils/CustomerUtils";
import Debug from "../pages/debug/DebugSelect";
import Heatmap from "../pages/heatmap/Heatmap";
import { ImportData } from "../pages/import-data/ImportData";
import Login from "../pages/login/Login";
import { CustomerAppNotice } from "../pages/login/forms/CustomerAppNotice";
import { ForgotPasswordForm } from "../pages/login/forms/ForgotPasswordForm";
import { LoginForm } from "../pages/login/forms/LoginForm";
import { ResetPasswordForm } from "../pages/login/forms/ResetPasswordForm";
import { QRSample } from "../pages/poc/QRSample";
import UserManagement from "../pages/poc/UserManagement";
import { useAccessControl, useAuthentication } from "../state/authentication";
import { useDeploymentConfig } from "../state/deployment";
import { useGlobalSearch } from "../state/globalSearch";
import { useModal } from "./modals/Modal";
import Header from "./widgets/Header";
import { Sidebar, useSidebar } from "./widgets/Sidebar";

// eslint-disable-next-line import/no-internal-modules
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import "./styling/Collapsible.scss";
import "./styling/Forms.scss";
import "./styling/Reset.scss";
import "./styling/Tables.scss";
import "./styling/Theme.scss";
import "./styling/Tooltips.scss";
import "./styling/UtilityClasses.scss";
import "./styling/bootstrap-limited.scss";

type SidebarType = "push" | "squeeze" | "normal";

const Page: React.FC<{ allowSearch?: boolean; sidebar?: SidebarType }> = (props) => {
  const location = useLocation();
  const { isSidebarOpen, closeSidebar } = useSidebar();
  const { closeAllModals } = useModal();
  const { enableAndClear: enableGlobalSearch } = useGlobalSearch();

  const classNames = ["page-container"];
  classNames.push(`sidebar-${props.sidebar || "normal"}`);
  if (isSidebarOpen === true) {
    classNames.push("sidebar-open");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    ReactTooltip.rebuild();
    closeAllModals();
    closeSidebar();
    enableGlobalSearch(props.allowSearch === true);
  }, [location.pathname, props.allowSearch, closeAllModals, closeSidebar, enableGlobalSearch]);

  const contentClassnames = [
    "content-container",
    "px-0",
    "px-sm-4", //padding x-axis (left and right)
    "pb-1",
    "pb-sm-5", //padding bottom
    "ms-1",
    "ms-sm-5", //margin start (left)
    "me-1",
    "me-sm-4", //margin end (right)
  ];

  return (
    <div className={classNames.join(" ")}>
      <Sidebar />
      <Header />
      <div className={contentClassnames.join(" ")}>{props.children}</div>
    </div>
  );
};

function FullLayout(props: { enableSales: boolean; enableChangeLog: boolean }) {
  const { config } = useDeploymentConfig();
  return (
    <Switch>
      <Route path="/analytics">
        <Page>
          <Analytics />
        </Page>
      </Route>
      <Route path="/analytics-push">
        <Page sidebar={"push"}>
          <Analytics />
        </Page>
      </Route>
      <Route path="/analytics-squeeze">
        <Page sidebar={"squeeze"}>
          <Analytics />
        </Page>
      </Route>
      <Route path="/touchpoints">
        <Page>
          <AppTouchpoints />
        </Page>
      </Route>
      {props.enableSales ? (
        <Route path="/revenue">
          <Page>
            <RevenueStatistics />
          </Page>
        </Route>
      ) : null}
      <Route path="/customers/:statistic">
        <Page allowSearch={true}>
          <CustomerData />
        </Page>
      </Route>
      <Route path="/customers">
        <Page allowSearch={true}>
          <CustomerData />
        </Page>
      </Route>
      <Route path="/health-check">
        <Page>
          <HealthCheck />
        </Page>
      </Route>
      <Route path="/notification">
        <Page>
          <PushNotificationCampaigns />
        </Page>
      </Route>
      <Route path="/visitor-statistics">
        <Page>
          <VisitorStatistics />
        </Page>
      </Route>
      <Route path="/heatmap">
        <Page>
          <Heatmap />
        </Page>
      </Route>
      <Route path="/locations">
        <Page>
          <LocationOverview />
        </Page>
      </Route>
      <Route path="/campaigns">
        <Page allowSearch={true}>
          <Campaigns />
        </Page>
      </Route>
      <Route path="/campaign/:campaignId/messages/new">
        <Page allowSearch={true}>
          <Messages openNew={true} />
        </Page>
      </Route>
      <Route path="/campaign/:campaignId/messages">
        <Page allowSearch={true}>
          <Messages openNew={false} />
        </Page>
      </Route>
      <Route path="/messages/new">
        <Page allowSearch={true}>
          <Messages openNew={true} />
        </Page>
      </Route>
      <Route path="/messages">
        <Page allowSearch={true}>
          <Messages openNew={false} />
        </Page>
      </Route>
      <Route path="/segments">
        <Page allowSearch={true}>
          <CustomerSegments />
        </Page>
      </Route>
      <Route path={`/${config.translations.store.plural.toLowerCase()}`}>
        <Page allowSearch={true}>
          <Stores />
        </Page>
      </Route>
      <Route path="/beacons">
        <Page allowSearch={true}>
          <Beacons />
        </Page>
      </Route>
      <Route path="/customer/:customerId/details">
        <Page allowSearch={false}>
          <CustomerDetails />
        </Page>
      </Route>
      {/* <Route path="/settings">
          <Page>
            <Administration />
          </Page>
        </Route> */}
      <Route path="/debug">
        <Page>
          <Debug />
        </Page>
      </Route>
      <Route path="/import-data">
        <Page>
          <ImportData />
        </Page>
      </Route>
      <Route path="/customer-utilities">
        <Page>
          <CustomerUtils />
        </Page>
      </Route>
      <Route path="/qr-sample">
        <Page>
          <QRSample />
        </Page>
      </Route>
      <Route path="/poc-user-management">
        <Page>
          <UserManagement />
        </Page>
      </Route>
      {props.enableChangeLog ? (
        <Route path="/change-log">
          <Page allowSearch={true}>
            <ChangeLog />
          </Page>
        </Route>
      ) : null}
      <Route path="/contact-tracking">
        <Page>
          <ContactTracking />
        </Page>
      </Route>
      <Route path="/product-categories">
        <Page allowSearch={true}>
          <ProductCategories />
        </Page>
      </Route>
      <Route path="/product-category/:productCategoryId/products/new">
        <Page allowSearch={true}>
          <Products openNew={true} />
        </Page>
      </Route>
      <Route path="/product-category/:productCategoryId/products">
        <Page allowSearch={true}>
          <Products openNew={false} />
        </Page>
      </Route>
      <Route path="/featured-items">
        <Page allowSearch={true}>
          <FeaturedItems />
        </Page>
      </Route>
      <Route path="/blog-posts">
        <Page allowSearch={true}>
          <BlogPosts />
        </Page>
      </Route>
      <Route path="/products">
        <Page allowSearch={true}>
          <Products openNew={false} />
        </Page>
      </Route>
      <Route path="/account/resetpassword" exact>
        <Login forCustomers={false}>
          <ResetPasswordForm forCustomer={false} />
        </Login>
      </Route>
      <Route>
        <Redirect to="/analytics" />
      </Route>
    </Switch>
  );
}

function LoginLayout(props: {}) {
  const attemptedPath = window.location.pathname;

  return (
    <Switch>
      <Route path="/" exact>
        <Login forCustomers={false}>
          <LoginForm attemptedPath={attemptedPath} />
        </Login>
      </Route>
      <Route path="/forgot-password" exact>
        <Login forCustomers={false}>
          <ForgotPasswordForm forCustomer={false} />
        </Login>
      </Route>
      <Route path="/account/resetpassword" exact>
        <Login forCustomers={false}>
          <ResetPasswordForm forCustomer={false} />
        </Login>
      </Route>
      <Route path="/app/forgot-password" exact>
        <Login forCustomers={true}>
          <ForgotPasswordForm forCustomer={true} />
        </Login>
      </Route>
      <Route path="/app/reset-password" exact>
        <Login forCustomers={true}>
          <ResetPasswordForm forCustomer={true} />
        </Login>
      </Route>
      <Route path="/app" exact>
        <Login hideSSL={true} forCustomers={true}>
          <CustomerAppNotice />
        </Login>
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}

export default function App() {
  const auth = useAuthentication();
  const accessControl = useAccessControl();
  const deployment = useDeploymentConfig();

  useEffect(() => {
    document.title = deployment.loading ? "Loading..." : deployment.config.title;
  }, [deployment.loading, deployment.config.title]);

  const layout = () => {
    if (!deployment.loading) {
      return auth.accessToken && accessControl?.suiteAccess ? (
        <FullLayout
          enableSales={!deployment.config.disablePurchaseHistories}
          enableChangeLog={deployment.config.enableChangeLog}
        />
      ) : (
        <LoginLayout />
      );
    } else {
      return null;
    }
  };

  const classnames = ["beaconsmind"];
  if (!deployment.loading) {
    if (deployment.config.cssClass) {
      classnames.push(deployment.config.cssClass);
    }
  }

  return (
    <BrowserRouter>
      <div className={classnames.join(" ")}>
        <ToastContainer autoClose={2000} theme="colored" closeButton={true} position={"top-center"} draggable={false} />
        <ReactTooltip delayShow={500} id="appTooltip" textColor="#4d4f5c" backgroundColor="#invalid" html={true} />
        <main>{layout()}</main>
      </div>
    </BrowserRouter>
  );
}
