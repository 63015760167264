import React, { Component, RefCallback } from "react";
import cx, { ClassValue } from "clsx";

const DEFAULT_CLASS = "react-tabs__tab";

export type TabProps = {
  className?: ClassValue;
  disabledClassName?: ClassValue;
  selectedClassName?: ClassValue;
  disabled?: boolean;
  tabIndex?: number;
  focus?: boolean; // private
  id?: string; // private
  panelId?: string; // private
  selected?: boolean; // private
  tabRef?: RefCallback<HTMLLIElement>; // private
};

export default class Tab extends Component<TabProps> {
  public static tabsRole = "Tab";
  private node: HTMLLIElement | null = null;

  componentDidMount() {
    this.checkFocus();
  }

  componentDidUpdate() {
    this.checkFocus();
  }

  checkFocus() {
    const { selected, focus } = this.props;
    if (selected && focus && this.node) {
      this.node.focus();
    }
  }

  render() {
    const {
      children,
      className,
      disabled,
      disabledClassName,
      focus, // unused
      id,
      panelId,
      selected,
      selectedClassName,
      tabIndex,
      tabRef,
      ...attributes
    } = this.props;

    const classNameOrDefault = className || DEFAULT_CLASS;
    const disabledClassNameOrDefault = disabledClassName || `${classNameOrDefault}--disabled`;
    const selectedClassNameOrDefault = selectedClassName || `${classNameOrDefault}--selected`;

    return (
      <li
        {...attributes}
        className={cx(
          classNameOrDefault,
          selected ? selectedClassNameOrDefault : undefined,
          disabled ? disabledClassNameOrDefault : undefined
        )}
        ref={(node) => {
          this.node = node;
          if (tabRef) tabRef(node);
        }}
        role="tab"
        id={id}
        aria-selected={selected ? "true" : "false"}
        aria-disabled={disabled ? "true" : "false"}
        aria-controls={panelId}
        tabIndex={tabIndex || (selected ? 0 : undefined)}
      >
        {children}
      </li>
    );
  }
}
