import Card from "../../cards/Card";
import { Modal, useModal } from "../../modals/Modal";
import { NotificationsTable } from "./NotificationsTable";

export function TopNotifications() {
  const modalId = "top-notifications-modal";
  const { openModal, closeModal } = useModal(modalId);

  const footer = (
    <div className="show-all" onClick={() => openModal()}>
      Show all
    </div>
  );
  return (
    <>
      <NotificationsTable hidePagination={true} title="Top Push Notifications">
        {footer}
      </NotificationsTable>
      <Modal id={modalId} onRequestClose={closeModal} className="wide-modal">
        <section>
          <Card>
            <NotificationsTable title="All Push Notifications" />
          </Card>
        </section>
      </Modal>
    </>
  );
}
