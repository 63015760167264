import { differenceInYears } from "date-fns";
import { useCallback, useEffect } from "react";
import { Redirect, useRouteMatch } from "react-router";
import { toast as alert } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { CustomerDetailsView, CustomerStatisticsView, CustomersApi, Gender } from "../api";
import Card from "../app/cards/Card";
import StatsCardWithTrend from "../app/cards/StatsCardWithTrend";
import { ServerUnavailable } from "../app/widgets/Alerts";
import Loading from "../app/widgets/Loading";
import { AnalyticsPageHeader } from "../app/widgets/PageHeaders";
import { PaginatedTable } from "../app/widgets/PaginatedTable";
import { isHttpOk } from "../services/api";
import { stringToDate, utcToLocal } from "../services/date";
import { formatDateTime, formatDecimal, formatDurationBetweenDates, useMoneyFormatter } from "../services/format";
import { useStateEx } from "../services/hooks";
import { useLocalizedText } from "../services/localization";
import { isProvided } from "../services/objects";
import { extractInitials } from "../services/string";
import { getImageTooltip } from "../services/tooltip";
import { getApiConfig } from "../state/configuration";
import { useDeploymentConfig } from "../state/deployment";
import { useTimeLocationFilters } from "../state/globalFilters";

import "./CustomerDetails.scss";

interface State {
  missing: boolean;
  details?: CustomerDetailsView;
  statistics?: CustomerStatisticsView;
}

export function CustomerDetails(props: {}) {
  const texts = useLocalizedText();
  const filters = useTimeLocationFilters();
  const { formatMoney } = useMoneyFormatter();
  const { config } = useDeploymentConfig();
  const { state, mergeState } = useStateEx<State>({ missing: false });
  const {
    params: { customerId },
  } = useRouteMatch<{ customerId: string }>();

  const loadPurchaseData = useCallback(
    (offset: number, limit: number) => {
      const config = getApiConfig();
      const api = new CustomersApi(config);
      return api.getDailyPurchases({
        ...filters,
        customerId: customerId,
        skip: offset,
        limit: limit,
      });
    },
    [customerId, filters]
  );

  const loadMessageData = useCallback(
    async (offset: number, limit: number) => {
      const config = getApiConfig();
      const api = new CustomersApi(config);
      const messages = api.getMessages({
        ...filters,
        customerId: customerId,
        skip: offset,
        limit: limit,
      });

      window.setTimeout(() => {
        ReactTooltip.rebuild();
      }, 500);

      return messages;
    },
    [customerId, filters]
  );

  useEffect(() => {
    const config = getApiConfig();
    const api = new CustomersApi(config);
    api
      .get({
        customerId: customerId,
      })
      .then((result) => {
        if (isHttpOk(result) && isProvided(result.data)) {
          mergeState({ details: result.data });
        } else if (result.status === 404) {
          mergeState({ missing: true });
        } else {
          alert.error(<ServerUnavailable />);
        }
      });

    api
      .getStatistics({
        ...filters,
        customerId: customerId,
      })
      .then((result) => {
        if (isHttpOk(result) && isProvided(result.data)) {
          mergeState({ statistics: result.data });
        } else if (result.status === 404) {
          mergeState({ missing: true });
        } else {
          alert.error(<ServerUnavailable />);
        }
      });
  }, [customerId, mergeState, filters]);

  if (state.missing) {
    return <Redirect to="/" />;
  } else if (!state.details) {
    return <Loading />;
  } else {
    const languages = {
      da: "Danish",
      de: "German",
      en: "English",
      fr: "French",
      hu: "Hungarian",
      it: "Italian",
    };
    const language = isProvided(state.details.language)
      ? languages[state.details.language as keyof typeof languages]
      : "";
    const fullName = (state.details.firstName || "") + " " + (state.details.lastName || "");
    const birthDay = stringToDate(state.details.birthDate, false);
    const ageGender = [];
    if (isProvided(birthDay)) {
      ageGender.push(differenceInYears(new Date(), birthDay) + " years old");
    }
    if (state.details.gender === Gender.Male) {
      ageGender.push("male");
    } else if (state.details.gender === Gender.Female) {
      ageGender.push("female");
    }

    const renderAvatar = () => {
      if (state.details?.avatarUrl && state.details.avatarUrl.length > 0) {
        return (
          <div
            className="cover"
            style={{
              backgroundImage: `url('${state.details.avatarUrl}')`,
            }}
          ></div>
        );
      } else {
        const initials = extractInitials(fullName);
        return <div className="initials">{initials}</div>;
      }
    };

    const getTotalVisits = () => {
      const visits = state.statistics?.storeVisits;
      if (!visits) return 0;

      return visits.reduce((partialSum, visit) => partialSum + visit.nmOfVisits, 0);
    };

    return (
      <div className="customer-details page-content">
        <div className="container">
          <AnalyticsPageHeader title={"Customer Details"} />
        </div>
        <div className="container">
          <div className="left">
            <Card className="basic-info">
              {renderAvatar()}
              <div className="name">
                <h1>{fullName}</h1>
              </div>
              <div className="age">{ageGender.join(", ")}</div>

              <div className="fields">
                <div className="f email">
                  <div className="title">Email</div>
                  <div className="value">{state.details.email || "-"}</div>
                </div>
                <div className="f">
                  <div className="title">Country</div>
                  <div className="value">{state.details.country || "-"}</div>
                </div>
                <div className="f">
                  <div className="title">City</div>
                  <div className="value">{state.details.city || "-"}</div>
                </div>
                <div className="f">
                  <div className="title">Zip</div>
                  <div className="value">{state.details.zipCode || "-"}</div>
                </div>
                <div className="f">
                  <div className="title">Street</div>
                  <div className="value">{state.details.street || "-"}</div>
                </div>
                <div className="f">
                  <div className="title">House Number</div>
                  <div className="value">{state.details.houseNumber || "-"}</div>
                </div>
                <div className="f">
                  <div className="title">Language</div>
                  <div className="value">{language}</div>
                </div>
                <div className="f">
                  <div className="title">Phone</div>
                  <div className="value">{state.details.landlinePhone || "-"}</div>
                </div>
                <div className="f">
                  <div className="title">Favorite {config.translations.store.singular}</div>
                  <div className="value">{state.details.favoriteStore?.name || "-"}</div>
                </div>
                <div className="f">
                  <div className="title">Loyalty No.</div>
                  <div className="value">{state.details.externalId || "-"}</div>
                </div>
              </div>
            </Card>
            <Card className="logs">
              <section>
                <header>
                  <h1>Logs</h1>
                </header>
                <div className="fields">
                  <div className="f">
                    <div className="title">Created</div>
                    <div className="value">{state.details.joinDate || "-"}</div>
                  </div>
                  <div className="f">
                    <div className="title">Modified</div>
                    <div className="value">-</div>
                  </div>
                  <div className="f">
                    <div className="title">Disabled push</div>
                    <div className="value">{state.details.disablePushNotifications ? "Yes" : "No"}</div>
                  </div>
                </div>
              </section>
            </Card>
          </div>
          <div className="right">
            <section className="stats">
              <StatsCardWithTrend
                hideDots={true}
                currentValue={formatMoney(state.statistics?.totalRevenue)}
                tooltipText={texts.customerDetailsPage.items.totalRevenue.tooltip}
                label={texts.customerDetailsPage.items.totalRevenue.title}
                color="green"
              />
              <StatsCardWithTrend
                hideDots={true}
                currentValue={formatDecimal(state.statistics?.nmSales, 0)}
                tooltipText={texts.customerDetailsPage.items.sales.tooltip}
                label={texts.customerDetailsPage.items.sales.title}
                color="orange"
              />
              <StatsCardWithTrend
                hideDots={true}
                currentValue={formatDecimal(state.statistics?.avgQuantityPerSale, 2)}
                tooltipText={texts.customerDetailsPage.items.productsPerBasket.tooltip}
                label={texts.customerDetailsPage.items.productsPerBasket.title}
                color="pink"
              />
              <StatsCardWithTrend
                hideDots={true}
                currentValue={formatDecimal(getTotalVisits(), 0)}
                tooltipText={texts.customerDetailsPage.items.storeVisits.tooltip}
                label={texts.customerDetailsPage.items.storeVisits.title}
                color="golden"
              />
            </section>

            {!config.disablePurchaseHistories && (
              <Card>
                <PaginatedTable
                  mainHeader="Purchase History"
                  className="customer-purchase-history"
                  tableHeaders={[
                    { title: "DATE" },
                    { title: "NM PURCHASES" },
                    { title: "QUANTITY" },
                    { title: "REVENUE" },
                  ]}
                  tableDataSource={loadPurchaseData}
                  tableRowFactory={(model) => {
                    return [model.label, model.nmPurchases, model.quantity, formatMoney(model.revenue)];
                  }}
                  minLoadingTimeMs={200}
                  hidePagination={false}
                  initialPageSize={5}
                />
              </Card>
            )}

            <Card>
              <PaginatedTable
                mainHeader="Messages"
                className="message-history"
                tableHeaders={[
                  texts.customerDetailsPage.items.name,
                  texts.customerDetailsPage.items.sent,
                  texts.customerDetailsPage.items.received,
                  texts.customerDetailsPage.items.read,
                  texts.customerDetailsPage.items.clicked,
                  texts.customerDetailsPage.items.redeemed,
                ]}
                tableDataSource={loadMessageData}
                tableRowFactory={(item) => {
                  return [
                    <span
                      className="message-preview"
                      data-for="appTooltip"
                      data-tip={getImageTooltip(
                        item.imageUrl || "",
                        item.offerRichText || "",
                        item.offerTitle || undefined
                      )}
                    >
                      {item.thumbnailUrl && <img className="image" alt="thumbnail" src={item.thumbnailUrl} />}
                      <span className="label">{item.label || `[${item.id}] ${item.offerTitle ?? ""}`}</span>;
                    </span>,
                    <span title={formatDateTime(utcToLocal(item.utcSent))}>
                      {formatDateTime(utcToLocal(item.utcSent))}
                    </span>,
                    <span title={formatDateTime(utcToLocal(item.utcReceived))}>
                      {formatDurationBetweenDates(item.utcSent, item.utcReceived)}
                    </span>,
                    <span title={formatDateTime(utcToLocal(item.utcRead))}>
                      {formatDurationBetweenDates(item.utcSent, item.utcRead)}
                    </span>,
                    <span title={formatDateTime(utcToLocal(item.utcClicked))}>
                      {formatDurationBetweenDates(item.utcSent, item.utcClicked)}
                    </span>,
                    <span title={formatDateTime(utcToLocal(item.utcRedeemed))}>
                      {formatDurationBetweenDates(item.utcSent, item.utcRedeemed)}
                    </span>,
                  ];
                }}
                minLoadingTimeMs={200}
                hidePagination={false}
                initialPageSize={5}
              />
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
