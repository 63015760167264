import { Cell, Label, Pie, PieChart } from "recharts";
import { AgeGroupView } from "../../api";
import Card from "../../app/cards/Card";
import { All, Blue, Red, White, Yellow } from "../../app/widgets/Circles";
import { formatDecimal } from "../../services/format";
import { Word } from "../../services/localization";
import { isProvided } from "../../services/objects";

import "./Demographics.scss";

export function AgeChart({ itemTitle, ages }: { itemTitle: Word; ages: AgeGroupView[] }) {
  const items = ages.map((group, index) => {
    const isKnown = isProvided(group.yearsFrom) && isProvided(group.yearsTo);
    const description = isKnown ? `${group.yearsFrom}-${group.yearsTo} years` : "Unknown";
    const circle = isKnown ? All[index % All.length] : White;
    return {
      circle,
      description,
      value: group.men + group.women + group.other,
    };
  });

  return (
    <Card className="age-chart">
      <PieChart className="pie-chart" width={220} height={250}>
        <Pie
          data={items}
          cx={105}
          cy={100}
          innerRadius={60}
          outerRadius={100}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          {items.map((item, index) => (
            <Cell key={`cell-${index}`} fill={item.circle.color} />
          ))}
          <Label
            value="Age"
            position="center"
            style={{
              fontWeight: "bold",
              fontSize: "18px",
            }}
          />
        </Pie>
      </PieChart>
      <div className="pie-stats">
        {items.map((item, index) => {
          return (
            <div key={index}>
              <div className={item.circle.class}>
                {item.circle.icon}
                <span>{item.description}</span>
              </div>
              <p>
                {formatDecimal(item.value, 0)} {itemTitle.plural}
              </p>
            </div>
          );
        })}
      </div>
    </Card>
  );
}

export function GenderChart({
  metricTitle,
  genders,
}: {
  metricTitle: Word;
  genders?: { men: number; women: number; other: number };
}) {
  return (
    <Card className="gender-chart">
      <PieChart className="pie-chart" width={220} height={265}>
        <Pie
          data={[{ value: genders?.men }, { value: genders?.women }, { value: genders?.other }]}
          cx={105}
          cy={100}
          innerRadius={60}
          outerRadius={100}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          <Cell fill={Blue.color} />
          <Cell fill={Red.color} />
          <Cell fill={Yellow.color} />
          <Label
            value="Gender"
            position="center"
            style={{
              fontWeight: "bold",
              fontSize: "18px",
            }}
          />
        </Pie>
      </PieChart>
      <div className="pie-stats">
        <div>
          <div className={Blue.class}>
            {Blue.icon}
            <span>Men</span>
          </div>
          <p>
            {formatDecimal(genders?.men, 0) || "-"} {metricTitle.plural}
          </p>
        </div>
        <div>
          <div className={Red.class}>
            {Red.icon}
            <span>Women</span>
          </div>
          <p>
            {formatDecimal(genders?.women, 0) || "-"} {metricTitle.plural}
          </p>
        </div>
        <div>
          <div className={Yellow.class}>
            {Yellow.icon}
            <span>Unknown</span>
          </div>
          <p>
            {formatDecimal(genders?.other, 0) || "-"} {metricTitle.plural}
          </p>
        </div>
      </div>
    </Card>
  );
}
