import { TimeSpanRange } from "../../../../api";
import { editorClassName, EditorProps } from "./common";
import { LocationInput } from "./inputs/LocationInput";
import { TimeSpanRangeInput } from "./inputs/TimeSpanRangeInput";

export function SpecificBeaconContactEditor(props: EditorProps) {
  const customGeneralOptions = [
    { label: "today", value: TimeSpanRange.Today },
    { label: "yesterday", value: TimeSpanRange.Yesterday },
    { label: "this month", value: TimeSpanRange.ThisMonth },
    { label: "last month", value: TimeSpanRange.LastMonth },
  ];

  const selectedLocations = props.value.specificBeaconContact?.locations || [];
  const selectedTimerange = props.value.specificBeaconContact?.time || undefined;

  return (
    <div className={editorClassName(props, "specific-beacon-contact")}>
      <h2>{props.description}</h2>
      <TimeSpanRangeInput
        prefix="Contact was"
        rangeSemantics={false}
        customGeneralOptions={customGeneralOptions}
        includeOptionAny={true}
        includeOptionNever={true}
        includeRelativeGroup={true}
        includeFixedGroup={true}
        value={selectedTimerange}
        onChange={(value) =>
          props.onChange({
            specificBeaconContact: {
              locations: selectedLocations,
              time: value,
            },
          })
        }
        error={props.error}
      />
      <LocationInput
        includeBeacons={true}
        error={props.error?.locations}
        value={selectedLocations}
        onChange={(value) => {
          props.onChange({
            specificBeaconContact: {
              locations: value,
              time: selectedTimerange,
            },
          });
        }}
      />
    </div>
  );
}
